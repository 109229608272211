import React, { useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Dropdown } from './dropdown'

import useCachedGetData from 'Hooks/useCachedGetData'

const LocationUnitDropdown = ({ value, onChange, ...props }) => {
  const [isLoading, units] = useCachedGetData('/rapi/units', [])
  const [isShowAll, setIsShowAll] = useState(true)
  const dropdownRef = useRef()

  const filteredUnits = useMemo(() => {
    const items = units.filter((unit) => unit.is_favorite)
    if (!isShowAll) {
      items.push({ isShowAll: true, label: '' })
      if (value && units.length > 0 && !items.some((unit) => unit.value === value)) {
        setIsShowAll(true)
      }
    } else {
      items.push(...units.filter((unit) => !unit.is_favorite))
    }
    return items
  }, [units, isShowAll, value])

  const handleShowAll = (event) => {
    setIsShowAll(true)
    event.preventDefault()
    event.stopPropagation()
    // Prevents primereact dropdown close
    if (dropdownRef.current) {
      dropdownRef.current.panelClick()
    }
  }

  const itemTemplate = (item) =>
    item.isShowAll ? (
      <div onClick={handleShowAll}>
        <span className="pi pi-angle-double-down"/>
        <FormattedMessage id="common.show_all"/>
      </div>
    ) : (
      item.label
    )

  return (
    <div>
      <Dropdown
        filter={isShowAll}
        filterInputAutoFocus={false}
        value={value}
        options={filteredUnits}
        disabled={isLoading}
        itemTemplate={itemTemplate}
        ref={dropdownRef}
        onChange={(e) => {
          onChange(
            e,
            units.find((unit) => unit.value === e.target.value)
          )
        }}
        {...props}
      />
    </div>
  )
}

export default LocationUnitDropdown
