import React, { useContext, useEffect, useRef, useState } from 'react'

import { ProgressSpinner } from 'primereact/progressspinner'

import { Button, ModalActionsFooter } from 'Components/readonly'
import { Dropdown, SelectButton } from 'Components/input'
import { FlexColumn, FlexRow, Section, Spacer } from 'Components/layout'
import useGetData from 'Hooks/useGetData'
import { AuthContext, GrowlContext } from 'Context'
import { FormattedMessage, injectIntl } from 'react-intl'
import ChemicalPreviousSdsView from 'Components/combined/chemicalPreviousSdsView'

const DownloadProductSDBDialog = ({ modalController, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [previousOrActive, setPreviousOrActive] = useState('active')
  const [selectedLanguage, setSelectedLanguage] = useState(false)
  const { userLanguage3 } = useContext(AuthContext)
  const { displayError } = useContext(GrowlContext)
  const [isLoadingSDBList, sdbList] = useGetData(`/rapi/chemicals/${modalController.data.productId}/sds`)
  let languageList = useRef()

  const previousOrActiveOptions = [
    { value: 'active', label: intl.formatMessage({ id: 'chemicalsds.current_version' }) },
    { value: 'previous', label: intl.formatMessage({ id: 'chemicalsds.previous_versions' }) }
  ]

  useEffect(() => {
    if (!sdbList) {
      return
    }

    languageList.current = sdbList.map((sdb) => {
      return { label: intl.formatMessage({ id: 'lang.' + sdb.language }), value: sdb.language }
    })
    const sdsUserLanguage = languageList.current.find((l) => l.value === userLanguage3)
    setSelectedLanguage(sdsUserLanguage ? sdsUserLanguage.value : languageList.current[0].value)
  }, [languageList, sdbList, intl, userLanguage3])

  const downloadSDB = async (event) => {
    setIsLoading(true)
    try {
      window.open(`/rapi/chemicals/${modalController.data.productId}/sds/${selectedLanguage}`)
      event.preventDefault()
      event.stopPropagation()

      modalController.close()
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Section>
        <SelectButton
          options={previousOrActiveOptions}
          value={previousOrActive}
          onChange={(e) => {
            setPreviousOrActive(e.target.value)
          }}
        />
      </Section>
      {previousOrActive === 'active' && (
        <>
          <FlexRow>
            {(isLoadingSDBList && <ProgressSpinner/>) || (
              <Section>
                <FlexColumn>
                  <p>
                    <label htmlFor={modalController.data.productId}>
                      <FormattedMessage id="sds.select_language"/>
                    </label>
                  </p>
                  <Dropdown
                    id={modalController.data.productId}
                    options={languageList.current}
                    onChange={(e) => {
                      setSelectedLanguage(e.value)
                    }}
                    value={selectedLanguage}
                    appendTo={document.body}
                  />
                </FlexColumn>
              </Section>
            )}
          </FlexRow>
          <ModalActionsFooter>
            <Spacer/>
            <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
            <Button primary label={intl.formatMessage({ id: 'sds.download' })} onClick={downloadSDB}
                    isLoading={isLoading}/>
          </ModalActionsFooter>
        </>
      )}
      {previousOrActive === 'previous' && (
        <>
          <Section>
            <ChemicalPreviousSdsView productId={modalController.data.productId} previousOnly={true}
                                     showDownloadButtons={true}/>
          </Section>
          <ModalActionsFooter>
            <Spacer/>
            <Button label={intl.formatMessage({ id: 'common.close' })} onClick={modalController.close}/>
          </ModalActionsFooter>
        </>
      )}
    </>
  )
}

export default injectIntl(DownloadProductSDBDialog)
