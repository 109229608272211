import React from 'react'

export const RenderList = ({ id, list, itemTemplate }) => {
  return (
    <>
      {list && list.length === 0 && '-'}
      {list.length > 0 && (
        <ul>
          {list.map((item, index) => (
            <li id={id + '-' + index} key={item.id ? item.id : index}>
              {(typeof itemTemplate === 'function' && itemTemplate(item)) || itemTemplate}
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
