import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Button, LoadingOverlay } from '../readonly'
import { TextInput } from '../input'
import colors from '../colors.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { AutoComplete } from '../input/autoComplete'

const Add = styled.div`
  display: flex;

  > ${TextInput} {
    input {
      width: 200px;
    }
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 150px;
    margin-left: 10px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 640px) {
    display: block;
    ${AutoComplete} {
      width: 100%;
      display: block;
      margin-bottom: 8px;
    }

    ${Button}${Button}${Button} {
      width: 100%;
      display: block;
      margin: 0;
    }
  }
`

const Entry = styled.div`
  display: flex;
  align-items: center;

  > div {
    flex: 1 0 1%;
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 30px;
    height: 30px;
    margin-bottom: 0;
  }

  padding: 10px 20px;
`

const EntryContainer = styled.div`
  &.edit {
    background-color: ${colors.darkerBackgroundColor};
    padding: 10px;
  }
`

const Entries = styled.div`
  margin-top: 10px;

  ${EntryContainer}:nth-child(odd) {
    background-color: ${colors.lightBackground};
  }
`

const HygieneThresholdValuesTable = styled.table`
  width: 100%;
  padding: 0px 15px 10px;

  td,
  th {
    border-collapse: collapse;
    text-align: left;
    padding: 5px;

    &.type {
      width: 20%;
    }

    &.path {
      width: 45%;
    }

    &.value {
      width: 35%;
    }
  }
`

export const GeneralHygieneThresholdPickList = ({ id, value, list, language, onChange, isLoading }) => {
  const intl = useIntl()
  const [selectedValue, setSelectedValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  value = value || []

  const add = () => {
    const newValue = [...value, selectedValue]
    onChange({ target: { value: newValue } })
    setSelectedValue('')
  }

  const remove = (index) => {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange({ target: { value: newValue } })
  }

  const onKeyUp = (event) => {
    if (event.key === 'Enter' && selectedValue && hasValue()) {
      add()
    }
  }

  const hasValue = () => {
    let existsInValue = list.filter((item) => {
      return item === selectedValue
    })
    return list && existsInValue.length === 1
  }

  const onSelectedValueChanged = (event) => {
    setSelectedValue(event.value)
  }

  const onSearchFieldChange = (value) => {
    if (value === '') {
      setSelectedValue(null)
    } else {
      setSelectedValue(value)
    }
  }

  const suggestList = (event) => {
    setSuggestions(
      list.filter((c) =>
        getLabel(c)
          .toLowerCase()
          .includes(event.query.toLowerCase())
      )
    )
  }

  const getLabel = (entry) => {
    if (!_.isObject(entry)) {
      return entry
    }
    return (
      entry.names.find((name) => name.language === language).name ||
      entry.names.find((name) => name.language === 'en').name ||
      '<No translation found>'
    )
  }

  const getFieldLabel = () => {
    if (list) {
      return 'names.' + _.findIndex(list[0].names, { language: language }) + '.name'
    }
    return ''
  }

  return (
    <div id={id}>
      <LoadingOverlay isLoading={isLoading}>
        <Add>
          <AutoComplete
            placeholder={intl.formatMessage({ id: 'chemicals.form.hygiene_thresholds.general_placeholder' })}
            completeMethod={suggestList.bind(this)}
            suggestions={suggestions}
            field={getFieldLabel()}
            value={selectedValue}
            onSelect={(event) => onSelectedValueChanged(event)}
            onUnselect={() => setSelectedValue(null)}
            onChange={(e) => onSearchFieldChange(e.value)}
            onKeyUp={onKeyUp}
          />
          <Button label={intl.formatMessage({ id: 'common.add' })} disabled={isLoading || !selectedValue || !hasValue()}
                  onClick={add}/>
        </Add>
      </LoadingOverlay>
      <Entries>
        {value &&
          value.map((entry, index) => {
            return (
              <EntryContainer key={index}>
                <Entry>
                  <div>{getLabel(entry)}</div>
                  <Button icon="pi pi-trash" onClick={() => remove(index)}/>
                </Entry>
                <HygieneThresholdValuesTable>
                  <thead>
                  <tr>
                    <th className="type">
                      <FormattedMessage id={'hygiene-thresholds.type'}/>
                    </th>
                    <th className="path">
                      <FormattedMessage id={'hygiene-thresholds.exposure_path'}/>
                    </th>
                    <th className="value">
                      <FormattedMessage id={'hygiene-thresholds.value'}/>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {entry.values.map((ht, i) => (
                    <tr key={i}>
                      <td>
                        <FormattedMessage id={'hygiene-thresholds.' + ht.type}/>
                      </td>
                      <td>
                        <FormattedMessage id={'hygiene-thresholds.' + ht.exposure_path}/>
                      </td>
                      <td>
                        {ht.value} <FormattedMessage id={'hygiene-thresholds.' + ht.unit}/>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </HygieneThresholdValuesTable>
              </EntryContainer>
            )
          })}
      </Entries>
    </div>
  )
}
