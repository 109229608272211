import React from 'react'
import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Button, Divider, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 20px 0;
  }
`

const VariantWrapper = styled.div`
  display: flex;
  margin: 10px 0;

  > div {
    flex: 0 1 auto;
  }

  > div:first-child {
    flex: 1 1 auto;
  }
`

const DeleteIngredientDialog = ({ modalController, onSuccess }) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = React.useState(false)
  const { displayError, displaySuccess } = React.useContext(GrowlContext)

  const ingredient = modalController.data.ingredient

  const deleteIngredient = async () => {
    try {
      setIsLoading(true)
      await axios.delete('/rapi/ingredients/' + ingredient.id)
      displaySuccess(intl.formatMessage({ id: 'admin.ingredients.ingredient_deleted' }))
      onSuccess(ingredient)
      modalController.close()
    } catch (e) {
      displayError(e)
    }
    setIsLoading(false)
  }

  return (
    <Wrapper>
      <div>{ingredient.names_sv}</div>
      <div>{ingredient.names_en}</div>
      <div>{ingredient.names_no}</div>
      <div>{ingredient.names_dk}</div>
      <div>
        <FormattedMessage id="chemicals.form.ingredient.cas_id"/>: {ingredient.cas_id}
      </div>
      <div>
        <FormattedMessage id="chemicals.form.ingredient.eg_id"/>: {ingredient.eg_id}
      </div>
      <div>
        <FormattedMessage id="chemicals.form.ingredient.index_id"/>: {ingredient.index_id}
      </div>
      <div>
        <FormattedMessage id="chemicals.form.ingredient.reach_id"/>: {ingredient.reach_id}
      </div>
      <p>
        <FormattedMessage id="admin.ingredients.ingredient_unused"/>
      </p>
      <h3>
        <FormattedMessage id="admin.ingredients.variants"/>
      </h3>
      {ingredient.variants
        .map((variant) => (
          <VariantWrapper>
            <div>
              <div>{variant.hazard_categories.map((c) => c.abbreviation).join(', ')}</div>
              <div>{variant.hazard_statements.map((s) => s.code).join(', ')}</div>
            </div>
            <div>{variant.usage_count > 0 ? variant.usage_count :
              <FormattedMessage id="admin.ingredients.not_used"/>}</div>
          </VariantWrapper>
        ))
        .map((item, i) => [i > 0 && <Divider/>, item])}
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary label={intl.formatMessage({ id: 'common.delete' })} onClick={deleteIngredient}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Wrapper>
  )
}

export default DeleteIngredientDialog
