import React, { useContext, useState } from 'react'
import { Field, Form } from 'Components/form'
import { TextInput } from 'Components/input'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { GrowlContext } from 'Context'

const url = '/rapi/admin/ingredientfilter'

export default ({ modalController, onSuccess }) => {
  const intl = useIntl()
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const [isLoading, setIsLoading] = useState(false)
  const filter = modalController.data

  const onSubmit = async (formData) => {
    setIsLoading(true)
    try {
      let result = await axios.post(url + '/' + filter.id, formData)
      onSuccess(result.data.filter_id)
      displaySuccess(result.data.message)
      setIsLoading(false)
      modalController.close()
    } catch (e) {
      displayError(e)
      setIsLoading(false)
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <Field id="cas_id" label={intl.formatMessage({ id: 'chemicals.form.ingredient.cas_id' })} Component={TextInput}
             required={true}/>
      <Field id="eg_id" label={intl.formatMessage({ id: 'chemicals.form.ingredient.eg_id' })} Component={TextInput}
             required={true}/>
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Form>
  )
}
