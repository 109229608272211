import React, { createContext, useState } from 'react'
import { Field } from './field'
import _ from 'lodash'
import isValid from 'date-fns/is_valid'

const createArrayIfSingleElement = (a) => {
  return [].concat(a || [])
}

export const FormContext = createContext()

export const isValidEmail = (value) => {
  if (typeof value !== 'string') {
    return false
  }

  // Source of regexp: https://emailregex.com/
  // eslint-disable-next-line
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
}

export const FormContextProvider = (props) => {
  const { children } = props
  const [initialFormData, setInitialFormData] = useState(_.cloneDeep(props.initialFormData))
  const [formData, internalSetFormData] = useState(props.initialFormData)
  const [validationErrors, setValidationErrors] = useState({})

  const getFieldValue = (id) => {
    return _.get(formData, id)
  }

  const setFieldValue = (id, value) => {
    if (id.indexOf('[]') !== -1) {
      internalSetFormData((formData2) => ({ ...formData2, [id]: value }))
    } else {
      if (_.isFunction(value)) {
        internalSetFormData((formData2) => {
          const newValue = value(_.get(formData2, id))
          return _.setWith({ ...formData2 }, id, newValue, Object)
        })
      } else {
        internalSetFormData((formData2) => {
          return _.setWith({ ...formData2 }, id, value, Object)
        })
      }
    }
  }

  const getValidationError = (id) => {
    return validationErrors[id]
  }

  const validateForm = (formChildren) => {
    const checkValidationRecurse = (children) => {
      createArrayIfSingleElement(children).forEach((child) => {
        if (!child) {
          return false
        }

        if (child.type && child.type.name === Field.name) {
          let invalid = false
          const propId = child.props.propId || child.props.id

          const isMissingRequiredValue = () => {
            // If required is a string lookup in formdata to determine if this field is currently required
            const isRequired = _.isString(child.props.required) ? _.get(formData, child.props.required) : child.props.required

            if (isRequired) {
              const value = _.get(formData, propId)
              return (!value && value !== false) || (_.isArray(value) && value.length === 0)
            }

            return false
          }

          if (isMissingRequiredValue()) {
            invalid = true
          } else if (child.props.validation) {
            if (child.props.validation === 'nonEmptyStringOrNull') {
              const value = _.get(formData, propId)
              if (value !== null && (typeof value !== 'string' || value.length === 0)) {
                invalid = true
              }
            } else if (child.props.validation === 'date') {
              const date = _.get(formData, propId)
              if (date) {
                if (!(date instanceof Date) || !isValid(date)) {
                  invalid = true
                }
              }
            } else if (child.props.validation === 'email') {
              const value = _.get(formData, propId)
              if (!isValidEmail(value)) {
                invalid = true
              }
            } else if (child.props.validation === 'location') {
              if (
                _.get(formData, propId).some((location) => !location.location_id || !location.amount) ||
                _.uniqBy(_.get(formData, propId), (v) => v.location_id).length !== _.get(formData, propId).length
              ) {
                invalid = true
              }
            } else {
              const validationResult = child.props.validation(_.get(formData, propId), formData)
              if ((_.isArray(validationResult) && !_.isEmpty(validationResult)) || (!_.isArray(validationResult) && validationResult)) {
                invalid = validationResult
              }
            }
          }

          if (invalid) {
            errors[child.props.id] = invalid
          } else if (errors[child.props.id]) {
            delete errors[child.props.id]
          }
        }

        if (child.props && child.props.children) {
          checkValidationRecurse(child.props.children)
        }
      })
    }

    let errors = { ...validationErrors }
    checkValidationRecurse(formChildren || children)
    setValidationErrors(errors)
    return errors
  }

  const anyValidationFailed = (children) => {
    if (children && !_.isEmpty(validationErrors)) {
      let hasAnyValidationFailed = (children) => {
        return createArrayIfSingleElement(children).some((child) => {
          return (
            child &&
            ((child.type && child.type.name === Field.name && validationErrors[child.props.id]) ||
              (child.props && child.props.children && hasAnyValidationFailed(child.props.children)))
          )
        })
      }
      return hasAnyValidationFailed(children)
    } else {
      return !_.isEmpty(validationErrors)
    }
  }

  const setFormData = (data, options = {}) => {
    internalSetFormData(data)
    setValidationErrors({})
    if (options.clearFormChanges) {
      setInitialFormData(_.cloneDeep(data))
    }
  }

  const getFormChanges = () => {
    return difference(formData, initialFormData, ['id'])
  }

  const clearFormChanges = () => {
    setInitialFormData(_.cloneDeep(props.initialFormData))
  }

  const hasFormChanges = () => {
    console.debug('hasFormChanges', !_.isEqual(initialFormData, formData), initialFormData, formData)
    return !_.isEqual(initialFormData, formData)
  }

  const formContext = {
    formData,
    setFormData,
    getFieldValue,
    setFieldValue,
    anyValidationFailed,
    getValidationError,
    setValidationErrors,
    validateForm,
    clearFormChanges,
    hasFormChanges,
    getFormChanges
  }

  return <FormContext.Provider value={formContext}>{children}</FormContext.Provider>
}

export const { Consumer } = FormContext

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object     Object compared
 * @param  {Object} base       Object to compare with
 * @param  {Array}  alwaysKeep Array of keys to always keep
 * @return {Object}            Return a new object who represent the diff
 */
function difference(object, base, alwaysKeep = []) {
  function changes(object, base) {
    let arrayIndexCounter = 0
    return _.transform(object, function (result, value, key) {
      if (alwaysKeep.includes(key) || !_.isEqual(value, base[key])) {
        let resultKey = _.isArray(base) ? arrayIndexCounter++ : key
        result[resultKey] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }

  return changes(object, base)
}
