import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { PageConstraint, PageHeader } from 'Components/layout'
import { TabView } from 'Components/readonly'
import { TabPanel } from 'primereact/tabview'
import { CodeList } from './CodeList'
import { PictoList } from './PictoList'

export const CodesSymbolsPage = () => {
  const intl = useIntl()

  return (
    <PageConstraint>
      <PageHeader>
        <h1>
          <FormattedMessage id="codes.title"/>
        </h1>
      </PageHeader>

      <TabView>
        <TabPanel header={intl.formatMessage({ id: 'codes.code-list' })}>
          <CodeList/>
        </TabPanel>
        <TabPanel header={intl.formatMessage({ id: 'codes.picto-list' })}>
          <PictoList/>
        </TabPanel>
      </TabView>
    </PageConstraint>
  )
}
