import React from 'react'
import {
  riskcircle_bg,
  riskcircle_pie_blue,
  riskcircle_pie_green,
  riskcircle_pie_orange,
  riskcircle_pie_red,
  riskcircle_pie_yellow,
  riskcircle_value_blue,
  riskcircle_value_green,
  riskcircle_value_orange,
  riskcircle_value_red,
  riskcircle_value_yellow
} from './assets'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

const width = 170
const height = 150

const Wrapper = styled.div`
  position: relative;
  top: 15px;
  left: 10px;
  width: ${width}px;
  height: ${height}px;

  .bg {
    background-repeat: no-repeat;
    width: ${width}px;
    height: ${height}px;
    position: absolute;
    background-image: url(${riskcircle_bg});

    &.static .pie {
      transition-property: none;
      transition-duration: 0;
    }
  }

  .pie {
    background-repeat: no-repeat;
    width: ${width}px;
    height: ${height}px;
    top: 6px;
    left: 8px;
    position: absolute;
    transition-property: opacity;
    transition-duration: 0.5s;
    opacity: 0.5;

    &.highlight {
      opacity: 1;
    }

    &.green {
      background-image: url(${riskcircle_pie_green});
    }

    &.blue {
      background-image: url(${riskcircle_pie_blue});
    }

    &.yellow {
      background-image: url(${riskcircle_pie_yellow});
    }

    &.orange {
      background-image: url(${riskcircle_pie_orange});
    }

    &.red {
      background-image: url(${riskcircle_pie_red});
    }
  }

  > .value {
    position: absolute;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 107px;
    height: ${height}px;
    top: 30px;
    left: 30px;
    padding-top: 20px;

    &.green {
      background-image: url(${riskcircle_value_green});
    }

    &.blue {
      background-image: url(${riskcircle_value_blue});
    }

    &.yellow {
      background-image: url(${riskcircle_value_yellow});
    }

    &.orange {
      background-image: url(${riskcircle_value_orange});
    }

    &.red {
      background-image: url(${riskcircle_value_red});
    }

    & .value {
      font-size: 2em;
      display: block;
    }

    & .constant {
      display: block;
      font-size: 14px;
    }
  }

  .riskfactorsteps {
    span {
      position: absolute;
      font-size: 10px;

      &:nth-of-type(1) {
        top: 60px;
        left: -5px;
      }

      &:nth-of-type(2) {
        top: 16px;
        left: 12px;
      }

      &:nth-of-type(3) {
        top: -7px;
        left: 56px;
      }

      &:nth-of-type(4) {
        top: -7px;
        left: 105px;
      }

      &:nth-of-type(5) {
        top: 16px;
        left: 143px;
      }

      &:nth-of-type(6) {
        top: 60px;
        left: 165px;
      }
    }
  }
`

export const RiskfactorVisualization = styled(({ value, className, is_static }) => {
  const intl = useIntl()
  const maxRiskFaktor = 1
  const constants = [
    intl.formatMessage({ id: 'riskassessment.risk_level.very_low' }),
    intl.formatMessage({ id: 'riskassessment.risk_level.low' }),
    intl.formatMessage({ id: 'riskassessment.risk_level.medium' }),
    intl.formatMessage({ id: 'riskassessment.risk_level.high' }),
    intl.formatMessage({ id: 'riskassessment.risk_level.very_high' })
  ]
  const colors = ['blue', 'green', 'yellow', 'orange', 'red']
  const getRiskFactor = (value) => {
    let risk_value = parseFloat(value)
    return Math.round(Math.min(risk_value * 4, 4))
  }
  const getRiskStep = (step) => {
    return Math.round((maxRiskFaktor / 5) * step * 10) / 10
  }
  return (
    <Wrapper className={className}>
      <span className={'bg ' + (is_static ? 'static' : '')}>
        <span className={'pie green ' + (colors[getRiskFactor(value)] === 'green' ? 'highlight' : '')}/>
        <span className={'pie blue ' + (colors[getRiskFactor(value)] === 'blue' ? 'highlight' : '')}/>
        <span className={'pie yellow ' + (colors[getRiskFactor(value)] === 'yellow' ? 'highlight' : '')}/>
        <span className={'pie orange ' + (colors[getRiskFactor(value)] === 'orange' ? 'highlight' : '')}/>
        <span className={'pie red ' + (colors[getRiskFactor(value)] === 'red' ? 'highlight' : '')}/>
      </span>
      <span className={'value ' + colors[getRiskFactor(value)]}>
        <span className="value">{parseFloat(value).toFixed(2)}</span>
        <span className="constant">{constants[getRiskFactor(value)]}</span>
      </span>
      <span className="riskfactorsteps">
        {[0, 1, 2, 3, 4, 5].map((i) => (
          <span key={i}>{getRiskStep(i)}</span>
        ))}
      </span>
    </Wrapper>
  )
})``

export const CalculateRiskfactor = (probability, severity) => {
  return Math.min(probability * (severity * 2), 1)
}
