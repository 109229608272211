import React, { createContext, useContext, useMemo, useState } from 'react'

import useGetData from 'Hooks/useGetData'
import { AuthContext } from 'Context'
import { transformRiskListToViewModel } from '../components/riskPageSupport'

export const RiskAssessmentContext = createContext({
  riskList: []
})

export const RiskAssessmentContextProvider = (props) => {
  const { children } = props
  const { organisation } = useContext(AuthContext)
  const [restRiskListIsLoading, restRiskList, setRestRiskList] = useGetData('/rapi/riskassessments', [], [organisation])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [filterState, setFilterState] = useState('ALL')
  const [first, setFirst] = useState(0)

  const riskListViewModel = useMemo(() => {
    return transformRiskListToViewModel(restRiskList, filterState)
  }, [restRiskList, filterState])

  const updateRiskAssessments = (updatedRiskAssessments) => {
    setRestRiskList(
      restRiskList.map((product) =>
        product.id !== selectedProduct.id ? product : { ...product, risk_assessments: updatedRiskAssessments }
      )
    )
    setFirst(0)
  }

  const riskAssessmentContext = {
    first,
    setFirst,
    filterState,
    setFilterState,
    selectedProduct,
    setSelectedProduct,
    restRiskList,
    riskListViewModel,
    restRiskListIsLoading,
    updateRiskAssessments
  }

  return <RiskAssessmentContext.Provider value={riskAssessmentContext}>{children}</RiskAssessmentContext.Provider>
}
