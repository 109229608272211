import React from 'react'
import { FormattedMessage } from 'react-intl'

import { RichTextDisplay } from 'Components/readonly'
import { Section, TextContainer } from 'Components/layout'

export default ({ product }) => {
  // Don't show info for preliminary products
  if (product.status === 'PRELIMINARY') {
    return (
      <Section>
        <FormattedMessage id="chemicals.no-preliminary-info"/>
      </Section>
    )
  }

  return (
    <TextContainer>
      <h3>
        <FormattedMessage id="chemicals.form.suitable_extinguishing_media"/>
      </h3>
      <p id="suitable_extinguishing_media">
        <RichTextDisplay value={product.suitable_extinguishing_media}/>
      </p>

      <h3>
        <FormattedMessage id="chemicals.form.unsuitable_extinguishing_media"/>
      </h3>
      <p id="unsuitable_extinguishing_media">
        <RichTextDisplay value={product.unsuitable_extinguishing_media}/>
      </p>

      <h3>
        <FormattedMessage id="chemicals.form.special_hazards_in_case_of_fire"/>
      </h3>
      <p id="special_hazards_in_case_of_fire">
        <RichTextDisplay value={product.special_hazards_in_case_of_fire}/>
      </p>

      <h3>
        <FormattedMessage id="chemicals.view.advice_for_firefighters"/>
      </h3>
      <p id="advice_for_firefighters">
        <RichTextDisplay value={product.advice_for_firefighters}/>
      </p>
    </TextContainer>
  )
}
