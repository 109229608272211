import React, { useContext, useState } from 'react'
import axios from 'axios'

import { Field, Form } from 'Components/form'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'

import { injectIntl } from 'react-intl'
import { LocationTree } from 'Components/input'

const MoveAllLocationDialog = ({ modalController, onSuccess, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)

  const { product, location } = modalController.data

  const onSubmit = async (formData) => {
    setIsLoading(true)
    try {
      const response = await axios.post('/rapi/chemicals/movelocation', {
        product_id: product.id,
        location_id: location.id,
        new_location: formData.location
      })
      setIsLoading(false)
      onSuccess(response.data)
      displaySuccess(intl.formatMessage({ id: 'chemicals.product-updated' }))
      modalController.close()
    } catch (error) {
      displayError(error)
      setIsLoading(false)
    }
  }

  return (
    product && (
      <Form onSubmit={onSubmit}>
        <div style={{ padding: '10px' }}>
          <Field
            id="location"
            Component={LocationTree}
            appendToBody={true}
            label={intl.formatMessage({ id: 'locations.choose_new_location' })}
          />
        </div>
        <ModalActionsFooter>
          <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => modalController.close()}/>
          <Button primary submit label={intl.formatMessage({ id: 'common.move' })} isLoading={isLoading}/>
        </ModalActionsFooter>
      </Form>
    )
  )
}

export default injectIntl(MoveAllLocationDialog)
