import React from 'react'
import styled from 'styled-components'
import { MultiSelect as MS } from 'primereact/multiselect'
import colors from '../colors.scss'
import { useIntl } from 'react-intl'

export const MultiSelect = styled(({ ...props }) => {
  const intl = useIntl()

  return <MS selectedItemsLabel={intl.formatMessage({ id: 'common.items_selected' })} {...props} />
})`
  &&& {
    background-color: ${(props) => (props.dark ? colors.invert : colors.white)};
    border-radius: 20px;
    border: 2px solid ${(props) => (props.dark ? colors.invert : colors.white)};
    padding: 0 20px;
    min-width: 200px;

    .p-multiselect-trigger {
      background-color: transparent;
    }

    &:hover:not(.p-disabled) {
      border-color: ${(props) => (props.dark ? colors.invert : colors.white)};
    }

    &.p-focus {
      box-shadow: none;
      border-color: ${colors.primary};
    }

    .p-multiselect-panel {
      border-radius: 4px;
      border: none;

      .p-multiselect-header {
        border: none;
        background-color: ${colors.white};

        .p-multiselect-filter-container .p-inputtext {
          background-color: ${colors.white};
          color: ${colors.defaultInteractText};
        }

        .p-checkbox-box.p-component {
          background-color: ${colors.white};

          &.p-highlight {
            background-color: ${colors.primary};
          }
        }
      }

      .p-multiselect-items {
        .p-multiselect-item {
          color: ${colors.defaultInteractText};

          label {
            cursor: pointer;
          }

          &:not(.p-highlight):not(.p-disabled):hover {
            background-color: ${colors.white};
            color: ${colors.defaultInteractText};
          }

          &.p-highlight {
            background-color: ${colors.white};
            color: ${colors.defaultInteractText};
          }

          .p-checkbox-box.p-component {
            background-color: ${colors.white};

            &.p-highlight {
              background-color: ${colors.primary};
            }
          }
        }
      }
    }

    .p-multiselect-label {
      background-color: ${(props) => (props.dark ? colors.invert : colors.white)};
      color: ${(props) => (props.dark ? colors.white : colors.defaultInteractText)};
      white-space: nowrap;
      margin: 0;
      min-height: 33px;
      max-width: 600px;
    }

    @media screen and (max-width: 640px) {
      max-width: 250px;
    }
  }
`
