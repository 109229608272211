import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import useCrudData from 'Hooks/useCrudData.js'
import useModalController from 'Hooks/useModalController.js'
import { Button, Modal, ToolPanel } from 'Components/readonly'

import inventoryDialog from './inventoryDialog'
import { InventoryList } from './inventoryList.js'
import { AuthContext } from 'Context'
import { HasCapability } from 'Components/capabilities'

const ActiveInventoryListTab = () => {
  const intl = useIntl()

  const { organisation } = useContext(AuthContext)

  const [inventoryStartModalController] = useModalController({ title: intl.formatMessage({ id: 'inventory.new' }) })

  // eslint-disable-next-line
  const [activeInventoryListLoading, activeInventoryList, createList, readList, updateList, deleteList] = useCrudData(
    '/rapi/inventorying?filter=IN_PROGRESS,PLANNED,WAITING_FOR_APPROVAL',
    [],
    [organisation]
  )

  return (
    <>
      <Modal
        modalController={inventoryStartModalController}
        ContentComponent={inventoryDialog}
        createList={createList}
        updateList={updateList}
      />

      <ToolPanel>
        <HasCapability capability="INVENTORYING_START">
          <Button
            id="new-inventorying-button"
            icon="pi pi-plus"
            primary
            label={intl.formatMessage({ id: 'inventory.new' })}
            onClick={() => {
              inventoryStartModalController.open()
            }}
          />
        </HasCapability>
      </ToolPanel>

      <InventoryList
        isLoading={activeInventoryListLoading}
        list={activeInventoryList}
        inventoryStartModalController={inventoryStartModalController}
        allowEdit={true}
        updateList={updateList}
        deleteList={deleteList}
        detailUrl="/inventory/"
      />
    </>
  )
}

export default ActiveInventoryListTab
