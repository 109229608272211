import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import colors from 'Components/colors.scss'

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    padding: 0 5px 5px 5px;
  }

  td {
    padding: 5px;
    border: 1px solid ${colors.lightBorder};
  }
`

export default ({ airPollution }) => {
  return (
    airPollution && (
      <Table>
        <thead>
        <tr>
          <th className="type">
            <FormattedMessage id={'chemicals.ingredient_single'}/>
          </th>
          <th className="type">
            <FormattedMessage id={'hygiene-thresholds.type'}/>
          </th>
          <th className="path">
            <FormattedMessage id={'hygiene-thresholds.exposure_path'}/>
          </th>
          <th className="value">
            <FormattedMessage id={'hygiene-thresholds.limit'}/>
          </th>
          <th className="value">
            <FormattedMessage id={'riskassessment.measured_or_calculated_value'}/>
          </th>
        </tr>
        </thead>
        <tbody>
        {airPollution.map((a) => (
          <React.Fragment key={a.id}>
            <tr>
              <td>{a.name}</td>
              <td>
                <FormattedMessage id={'hygiene-thresholds.' + a.type}/>
              </td>
              <td>{a.exposure_path ? <FormattedMessage id={'hygiene-thresholds.' + a.exposure_path}/> : '-'}</td>
              <td>
                {a.threshold_value} <FormattedMessage id={'hygiene-thresholds.' + a.threshold_unit}/>
              </td>
              <td>
                {a.value ? (
                  <>
                    {a.value} <FormattedMessage id={'hygiene-thresholds.' + a.unit}/>
                  </>
                ) : (
                  '-'
                )}
              </td>
            </tr>
          </React.Fragment>
        ))}
        </tbody>
      </Table>
    )
  )
}
