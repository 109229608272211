import React, { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import { Field, Form } from 'Components/form'
import { RadioButtonGroup, RichTextArea, TextInput } from 'Components/input'
import { Button, Panel, ToolPanel } from 'Components/readonly'
import { FormattedMessage, useIntl } from 'react-intl'
import { Spacer } from 'Components/layout'
import { GrowlContext } from 'Context'
import OrganisationFilter from './orgFilter'

const FormPanel = styled(Panel)`
  padding: 0 20px 20px 20px;

  ${Field} {
    margin-top: 25px;
    margin-bottom: 10px;
  }
`

export const MessageForm = ({ setMessageSidebarVisible, setSelectedMessage, message, onSuccess }) => {
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)

  const userTypeOptions = [
    { value: 'all', label: intl.formatMessage({ id: 'common.all' }) },
    { value: 'admin', label: intl.formatMessage({ id: 'orgmessages.admin-super' }) },
    { value: 'super', label: intl.formatMessage({ id: 'orgmessages.super-only' }) }
  ]

  const isEditMode = !!message
  const initialMessage = message
    ? message
    : {
      organisation_filter: {
        organisations: [],
        products: []
      },
      usertype: 'all',
      heading: '',
      message: ''
    }

  const handleSubmit = async (data) => {
    setIsLoading(true)
    try {
      const result = await axios.post('/rapi/admin/organisationmessages', data)
      displaySuccess(result.data.message)
      onSuccess(result.data.organisation_message)
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <h2>{isEditMode ? intl.formatMessage({ id: 'orgmessages.edit' }) : intl.formatMessage({ id: 'orgmessages.new' })} </h2>

      <Form onSubmit={handleSubmit}>
        {!isEditMode && (
          <FormPanel lighter>
            <h3>
              <FormattedMessage id="orgmessages.orgfilter"/>
            </h3>
            <Field
              id="organisation_filter"
              Component={OrganisationFilter}
              required={true}
              showLabel={false}
              initialValue={initialMessage.organisation_filter}
            />
          </FormPanel>
        )}
        <FormPanel lighter>
          <h3>
            <FormattedMessage id="common.message"/>
          </h3>
          {!isEditMode && (
            <Field
              id="usertype"
              label={intl.formatMessage({ id: 'orgmessages.usertype' })}
              Component={RadioButtonGroup}
              options={userTypeOptions}
              initialValue={initialMessage.usertype}
              required={true}
            />
          )}
          <Field
            id="heading"
            label={intl.formatMessage({ id: 'common.heading' })}
            Component={TextInput}
            initialValue={initialMessage.heading}
            required={true}
          />
          <Field
            id="message"
            label={intl.formatMessage({ id: 'common.message' })}
            Component={RichTextArea}
            initialValue={initialMessage.message}
            required={true}
            minRows={3}
          />
        </FormPanel>

        <ToolPanel>
          <Spacer/>
          <Button
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={() => {
              setMessageSidebarVisible(false)
              setSelectedMessage(null)
            }}
          />
          <Button primary submit isLoading={isLoading} label={intl.formatMessage({ id: 'common.send' })}/>
        </ToolPanel>
      </Form>
    </>
  )
}
