import React from 'react'
import { injectIntl } from 'react-intl'

import { Field, FieldGroup, Form } from 'Components/form'
import { TextInput } from 'Components/input'
import { Button, ModalActionsFooter } from 'Components/readonly'

const LocationDialog = ({ modalController, onSuccess, intl, model }) => {
  const onSubmit = async (formData) => {
    onSuccess(formData.name)
    modalController.close()
  }

  return (
    <Form onSubmit={onSubmit}>
      <FieldGroup>
        <Field
          id="name"
          className="modal-onshow-focus"
          label={intl.formatMessage({ id: 'common.location' })}
          Component={TextInput}
          initialValue={model.name}
        />
      </FieldGroup>
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}/>
      </ModalActionsFooter>
    </Form>
  )
}

export default injectIntl(LocationDialog)
