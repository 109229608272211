import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { Column } from 'primereact/column'
import { FormattedMessage, useIntl } from 'react-intl'

import useCrudData from 'Hooks/useCrudData'
import { AuthContext } from 'Context'
import { Button, SearchInput } from 'Components/input'
import { checkCircleBody, DataTable, dateBody, ToolPanel } from 'Components/readonly'
import { Spacer } from 'Components/layout'
import SdsDetail from './sdsDetail'
import { HasCapability } from 'Components/capabilities'
import { preserveNewlinesColumnBody } from 'Utils/newLines'
import { globalSearch } from 'Utils/search'
import linkConfig from 'Config/app-external-links'
import SdsStatus from './components/sdsStatus'
import SdsPublicSwitch from './components/sdsPublicSwitch'

export const SDS_TABLE_TYPE = Object.freeze({
  INTERNAL: { kind: 'internal', api: '' },
  EXTERNAL: { kind: 'external', api: 'External' },
  INACTIVE: { kind: 'inactive', api: 'Inactive' },
  PUBLIC: { kind: '', api: 'Public' }
})

export const SDS_COLUMNS = Object.freeze({
  status: 'status',
  productName: 'productName',
  id: 'id',
  countryName: 'countryName',
  langName: 'langName',
  issueDate: 'issueDate',
  isPublic: 'isPublic',
  labeling: 'labeling',
  download: 'download'
})

const SdsTable = ({ children, type, hideColumns, forceUpdate }) => {
  const intl = useIntl()

  let sdsKind = 'internal'
  if (type === SDS_TABLE_TYPE.EXTERNAL) {
    sdsKind = 'external'
  } else if (type === SDS_TABLE_TYPE.INACTIVE) {
    sdsKind = 'inactive'
  }

  const kind = sdsKind

  // Need to refetch and rerender when organisation changes
  const { organisation } = useContext(AuthContext)
  const [globalFilter, setGlobalFilter] = useState('')
  const [isLoading, sds, createSds, readSds, updateSds, deleteSds] = useCrudData('/rapi/sds/list' + type.api, [], [organisation])
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(localStorage.hasOwnProperty('numSdsRows') ? parseInt(localStorage.getItem('numSdsRows')) : 20)
  const [showDetailId, setShowDetailId] = useState(null)

  useEffect(() => {
    localStorage.setItem("numSdsRows", rows);
  }, [rows]);

  const filteredSds = React.useMemo(() => {
    return globalSearch([...sds], globalFilter, ['articleNumber', 'productName', 'countryName', 'langName', 'id']).map((sds) => ({
      ...sds,
      // Has external labeling or internal
      calcHasLabeling: (sds.labeling && sds.labeling.length > 0) || sds.labelActive
    }))
  }, [globalFilter, sds])

  const download = (event, sds) => {
    window.open('/rapi/sds/pdfDownload?' + kind + '[' + sds.id + ']=1')
    event.preventDefault()
    event.stopPropagation()
  }

  const downloadAll = () => {
    window.open('/rapi/sds/pdfDownloadAll?kind=' + kind)
  }

  const downloadBody = (row) => {
    return (
      <Button
        primary
        icon="pi pi-download"
        onClick={(event) => {
          download(event, row)
        }}
      />
    )
  }

  const publicBody = (row) => <SdsPublicSwitch kind={kind} sds={row} updateSds={updateSds}/>

  const columns = []
  if (!_.includes(hideColumns, SDS_COLUMNS.status))
    columns.push(
      <Column
        key="status"
        field="status"
        header={intl.formatMessage({ id: 'common.status' })}
        sortable={true}
        body={(row) => <SdsStatus sds={row}/>}
        style={{ width: '1%' }}
      />
    )
  if (!_.includes(hideColumns, SDS_COLUMNS.articleNumber))
    columns.push(
      <Column
        key="articleNumber"
        field="articleNumber"
        header={intl.formatMessage({ id: 'chemicals.article_id' })}
        sortable={true}
        style={{ width: '1%' }}
      />
    )
  if (!_.includes(hideColumns, SDS_COLUMNS.productName))
    columns.push(
      <Column
        key="productName"
        field="productName"
        header={intl.formatMessage({ id: 'chemicals.product' })}
        body={preserveNewlinesColumnBody}
        sortable={true}
      />
    )
  if (!_.includes(hideColumns, SDS_COLUMNS.id))
    columns.push(<Column key="id" field="id" header={intl.formatMessage({ id: 'sds.id' })} sortable={true}
                         style={{ width: '1%' }}/>)
  if (!_.includes(hideColumns, SDS_COLUMNS.countryName))
    columns.push(
      <Column
        key="countryName"
        field="countryName"
        header={intl.formatMessage({ id: 'common.country' })}
        sortable={true}
        style={{ width: '1%' }}
      />
    )
  if (!_.includes(hideColumns, SDS_COLUMNS.langName))
    columns.push(
      <Column
        key="langName"
        field="langName"
        header={intl.formatMessage({ id: 'common.language' })}
        sortable={true}
        style={{ width: '1%' }}
      />
    )
  if (!_.includes(hideColumns, SDS_COLUMNS.issueDate))
    columns.push(
      <Column
        key="issueDate"
        field="issueDate"
        header={intl.formatMessage({ id: 'sds.issue-date' })}
        sortable={true}
        body={dateBody}
        style={{ width: '1%' }}
      />
    )
  if (!_.includes(hideColumns, SDS_COLUMNS.isPublic) && organisation.weblink > 0)
    columns.push(
      <Column
        key="isPublic"
        field="isPublic"
        header={intl.formatMessage({ id: 'sds.public' })}
        sortable={true}
        body={publicBody}
        style={{ width: '1%' }}
      />
    )
  if (!_.includes(hideColumns, SDS_COLUMNS.labeling))
    columns.push(
      <Column
        key="labeling"
        field="calcHasLabeling"
        body={checkCircleBody}
        header={intl.formatMessage({ id: 'labeling.product-labeling' })}
        sortable={true}
        style={{ width: '1%', textAlign: 'center' }}
      />
    )
  if (!_.includes(hideColumns, SDS_COLUMNS.download))
    columns.push(
      <Column
        key="download"
        header={intl.formatMessage({ id: 'common.download' })}
        body={downloadBody}
        style={{ width: '1%', textAlign: 'center' }}
      />
    )

  const showDetailSds = readSds(showDetailId)

  return (
    <div id="SdsTable">
      <ToolPanel>
        <SearchInput
          value={globalFilter}
          onChange={(event) => {
            setGlobalFilter(event.target.value)
            setFirst(0)
          }}
        />
        <Spacer/>
        <HasCapability capability="PLACE_ORDER">
          <span>
            <i className="pi pi-envelope" style={{ fontSize: '28px', verticalAlign: 'middle' }}></i>{' '}
            <a href={`mailto:${linkConfig.emailOrder}`} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="sds.order_new_sds"/>
            </a>
          </span>
        </HasCapability>
        {children && <HasCapability capability="UPLOAD_DOCUMENT">{children({ createSds })}</HasCapability>}
        <Button primary disabled={!filteredSds.length} label={intl.formatMessage({ id: 'common.download_all' })}
                icon="pi pi-download"
                onClick={downloadAll}/>
      </ToolPanel>
      <DataTable
        className={'flextable' + (type !== SDS_TABLE_TYPE.INACTIVE ? ' clickable-rows' : ' disabled-rows')}
        value={filteredSds}
        paginator={true}
        rows={rows}
        rowsPerPageOptions={[20, 50, 100]}
        loading={isLoading}
        sortField="status"
        sortOrder={-1}
        onRowClick={(event) => {
          if (type !== SDS_TABLE_TYPE.INACTIVE) setShowDetailId(event.data.id)
        }}
        first={first}
        onPage={(e) => {
          setFirst(e.first)
          setRows(e.rows)
        }}>
        {columns}
      </DataTable>
      <SdsDetail
        sds={showDetailSds}
        kind={kind}
        isOpen={showDetailSds != null}
        close={() => {
          setShowDetailId(null)
        }}
        publicBody={publicBody}
        onSdsArchived={(value) => {
          forceUpdate(value)
          deleteSds(value)
        }}
        onSdsUpdated={(value) => {
          forceUpdate(value)
          updateSds(value)
        }}
      />
    </div>
  )
}

SdsTable.defaultProps = {
  hideColumns: []
}

export default SdsTable
