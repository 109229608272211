import React from 'react'
import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Button, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'
import { YesNoString } from 'Utils/strings'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;

  > div {
    flex: 1 1 auto;
  }

  > div:not(:last-child) {
    margin: 10px 0;
  }
`

const VariantWrapper = styled.div`
  margin: 20px 0;
`

const DeleteIngredientVariantDialog = ({ modalController, onSuccess }) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = React.useState(false)
  const { displayError, displaySuccess } = React.useContext(GrowlContext)

  const ingredient = modalController.data.ingredient
  const variant = modalController.data.variant

  const deleteIngredientVariant = async () => {
    try {
      setIsLoading(true)
      await axios.delete('/rapi/ingredients/variant/' + variant.id)
      displaySuccess(intl.formatMessage({ id: 'admin.ingredients.variant_deleted' }))
      onSuccess(ingredient, variant)
      modalController.close()
    } catch (e) {
      displayError(e)
    }
    setIsLoading(false)
  }

  return (
    <Wrapper>
      <Content>
        <div>
          <h3>
            <FormattedMessage id="chemicals.ingredient_single"/>
          </h3>
          <div>{ingredient.names_sv}</div>
          <div>{ingredient.names_en}</div>
          <div>{ingredient.names_no}</div>
          <div>{ingredient.names_dk}</div>
          <div>
            <FormattedMessage id="chemicals.form.ingredient.cas_id"/>: {ingredient.cas_id}
          </div>
          <div>
            <FormattedMessage id="chemicals.form.ingredient.eg_id"/>: {ingredient.eg_id}
          </div>
          <div>
            <FormattedMessage id="chemicals.form.ingredient.index_id"/>: {ingredient.index_id}
          </div>
          <div>
            <FormattedMessage id="chemicals.form.ingredient.reach_id"/>: {ingredient.reach_id}
          </div>
          <VariantWrapper>
            <div>
              <FormattedMessage id="admin.ingredients.incorrect"/>: <YesNoString
              value={variant.classification_incorrect}/>
            </div>
            <div>
              <FormattedMessage id="chemicals.form.ingredient.listed_candidate"/>: <YesNoString
              value={variant.listed_candidate}/>
            </div>
            <div>
              <FormattedMessage id="chemicals.form.ingredient.listed_prio"/>: <YesNoString value={variant.listed_prio}/>
            </div>
          </VariantWrapper>
        </div>
        <div>
          <h3>
            <FormattedMessage id="chemicals.form.ingredient.classification"/>
          </h3>
          <div>
            <div>{variant.hazard_categories.map((c) => c.abbreviation).join(', ')}</div>
            <div>{variant.hazard_statements.map((s) => s.code).join(', ')}</div>
          </div>
        </div>
      </Content>
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary label={intl.formatMessage({ id: 'common.delete' })} onClick={deleteIngredientVariant}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Wrapper>
  )
}

export default DeleteIngredientVariantDialog
