import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { PreserveNewlines } from 'Utils/newLines'
import { format, parse } from 'date-fns'

const StyledTable = styled.table.attrs({ className: 'marginBottom' })`
  td {
    padding: 2px;

    &:first-child {
      font-weight: 600;
    }
  }
`
export const SdsDetailTable = ({ sds }) => (
  <StyledTable>
    <tbody>
    <tr>
      <td>
        <FormattedMessage id="sds.id"/>
      </td>
      <td>{sds.id}</td>
    </tr>
    <tr>
      <td>
        <FormattedMessage id="sds.article-number"/>
      </td>
      <td>{sds.articleNumber}</td>
    </tr>
    <tr>
      <td>
        <FormattedMessage id="sds.product-name"/>
      </td>
      <td>
        <PreserveNewlines text={sds.productName}/>
      </td>
    </tr>
    <tr>
      <td>
        <FormattedMessage id="common.country"/>
      </td>
      <td>{sds.countryName}</td>
    </tr>
    <tr>
      <td>
        <FormattedMessage id="common.language"/>
      </td>
      <td>{sds.langName}</td>
    </tr>
    <tr>
      <td>
        <FormattedMessage id="sds.version"/>
      </td>
      <td>{sds.versionNumber}</td>
    </tr>
    <tr>
      <td>
        <FormattedMessage id="sds.issue-date"/>
      </td>
      <td>{format(parse(sds.issueDate), 'YYYY-MM-DD')}</td>
    </tr>
    {sds.changed && (
      <tr>
        <td>
          <FormattedMessage id="common.changed"/>
        </td>
        <td>{format(parse(sds.changed), 'YYYY-MM-DD')}</td>
      </tr>
    )}
    </tbody>
  </StyledTable>
)
