import React, { useContext, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { format, lastDayOfMonth, parse, startOfMonth, subMonths } from 'date-fns'
import { Chart } from 'primereact/chart'
import { AuthContext } from 'Context'

import useGetData from 'Hooks/useGetData'
import { Section, Spacer } from 'Components/layout'
import { Button, Column, DataTable, ToolPanel } from 'Components/readonly'
import { DateRangePicker } from 'Components/input/dateRangePicker'
import colors from 'Components/colors.scss'

export const ReportProducts = () => {
  const intl = useIntl()

  const dataTableRef = useRef()
  const { activeMarket } = useContext(AuthContext)
  const [fromDate, setFromDate] = useState(startOfMonth(subMonths(new Date(), 1)))
  const [toDate, setToDate] = useState(lastDayOfMonth(subMonths(new Date(), 1)))
  const [isLoading, customersProducts] = useGetData(
    '/rapi/admin/report-customers-products?start=' + format(parse(fromDate), 'YYYY-MM-DD') + '&end=' + format(parse(toDate), 'YYYY-MM-DD'),
    [],
    [fromDate, toDate]
  )
  const [chartData, setChartData] = useState(null)

  const chartOptions = {
    legend: {
      display: true,
      labels: {
        fontColor: colors.white
      }
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            displayFormats: {
              millisecond: 'MMM DD HH:mm:ss',
              second: 'MMM DD HH:mm:ss',
              minute: 'MMM DD HH:mm:ss',
              hour: 'MMM DD HH:mm',
              day: 'MMM DD',
              week: 'YYYY-MM-DD',
              month: 'YYYY-MM-DD',
              quarter: 'YYYY-MM-DD',
              year: 'YYYY-MM-DD'
            }
          },
          ticks: {
            fontColor: colors.white
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: colors.white,
            precision: 0
          }
        }
      ]
    }
  }

  const handleRowClick = (e) => {
    const data = e.data.chemicals
    setChartData({
      datasets: [
        {
          label: e.data.organisation.name,
          data: Object.keys(data).map((time) => {
            return {
              x: parse(time),
              y: data[time]
            }
          }),
          fill: false,
          backgroundColor: colors.primary,
          borderColor: colors.primary,
          lineTension: 0
        }
      ]
    })
  }

  const exportCSV = () => {
    if (dataTableRef.current) {
      dataTableRef.current.exportCSV()
    }
  }

  return (
    <>
      <ToolPanel>
        <DateRangePicker toDate={toDate} fromDate={fromDate} onFromDateChange={setFromDate} onToDateChange={setToDate}/>
        <Spacer/>
        <Button icon="pi pi-file-excel" label={intl.formatMessage({ id: 'admin.export_csv' })} onClick={exportCSV}/>
      </ToolPanel>
      <Section>
        <DataTable
          ref={dataTableRef}
          className="flextable clickable-rows"
          value={customersProducts}
          paginator={true}
          rows={10}
          loading={isLoading}
          onRowClick={handleRowClick}>
          <Column field="organisation.name" header={intl.formatMessage({ id: 'common.name' })} sortable={true}
                  exportable={true}/>
          <Column
            field="organisation.address.Org_No"
            header={intl.formatMessage({ id: 'organisation.org-id' })}
            sortable={true}
            exportable={true}
          />
          <Column
            field="max_chemicals"
            header={intl.formatMessage({ id: 'admin.max_count' }) + ' ' + intl.formatMessage({ id: 'lang.' + activeMarket })}
            sortable={true}
            exportable={true}
          />
        </DataTable>
      </Section>
      {chartData && (
        <Section>
          <Chart type="line" data={chartData} options={chartOptions}/>
        </Section>
      )}
    </>
  )
}
