import React from 'react'
import { FormattedMessage } from 'react-intl'
import { format, parse } from 'date-fns'
import styled from 'styled-components'

import { FlexColumn, FlexRow, LineHeading, Section } from 'Components/layout'
import { Divider } from 'Components/readonly'
import { InventoryStatusBadge } from '../inventoryStatusBadge'

export const InventoryDetailHeaderMain = ({ inventory }) => (
  <>
    <h1>
      <FormattedMessage id="inventory.title"/>
    </h1>{' '}
    <Divider/>
    <Section>
      <InventoryStatusBadge status={inventory.status}/>
    </Section>
    {inventory.locations.map((location) => location.location).join(', ')}
    <br/>
    <LineHeading>
      <FormattedMessage id="inventory.start-date"/>:
    </LineHeading>
    <span id="start_at"> {format(parse(inventory.start_at), 'YYYY-MM-DD')}</span>
    <br/>
    <LineHeading>
      <FormattedMessage id="common.responsible"/>:
    </LineHeading>
    <span id="responsible_user_name"> {inventory.responsible_user_name}</span>
    <br/>
    <LineHeading>
      <FormattedMessage id="common.created"/>:
    </LineHeading>
    <span id="created">
      {' '}
      {inventory.created_at} - {inventory.created_by_user_name}
    </span>
  </>
)

export const DetailHeader = styled(FlexRow)`
  flex-wrap: wrap;

  ${FlexColumn} {
    min-width: 300px;
  }
`
