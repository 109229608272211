export default {
  // External links
  kemriskWebsite: 'https://www.kemrisk.se/',
  kemriskSdsModule: 'https://www.kemrisk.se/produkt/sakerhetsdatablad/',
  kemriskNews: 'https://www.kemrisk.se/info/nyheter-och-artiklar/',
  newsFeed: 'https://www.kemrisk.se/wp-json/wp/v2/posts?_embed&per_page=3&categories_exclude=938',
  defaultNewsFeaturedImage: 'https://www.kemrisk.se/wp-content/themes/kemrisk/images/info/thumb--nyheter-och-artiklar.jpg',

  // E-mail
  emailOrder: 'orderinkommande@kemrisk.se',
  emailSupport: 'support@kemrisk.se',
  emailInfo: 'info@kemrisk.se'
}
