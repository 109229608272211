import React, { useContext, useMemo, useState, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import { Field, Form } from 'Components/form';
import { FileUpload, MarketLanguageDropdown } from 'Components/input';

import { Button, InfoPanel, ModalActionsFooter } from 'Components/readonly';
import { FlexRowCenterHorizontal, Section, Spacer } from 'Components/layout';
import { AuthContext, GrowlContext } from 'Context';
import { injectIntl } from 'react-intl';
import useGetData from 'Hooks/useGetData';

const Content = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;

  ${Field} {
    margin-bottom: 10px;
  }

  > ${Section} {
    margin-right: 10px;
    flex: 1 1 auto;
  }
`;

const ReplaceProductSDBDialog = ({ modalController, intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { userLanguage3, defaultMarketLanguage } = useContext(AuthContext);
  const { displaySuccess, displayError } = useContext(GrowlContext);
  const [sdsListLoading, sdsList] = useGetData('/rapi/chemicals/' + modalController.data.productId + '/sdsForReview');
  const [selectedLanguage, setSelectedLanguage] = useState(userLanguage3);
  const replaceFileInputRef = useRef(null);

  const onSubmit = async (sendData) => {
    if (modalController.data.isAutoApproveSds) {
        sendData.append('is-auto-activate-sds', true)
      }
  
      setIsLoading(true)
  
      try {
        const result = await axios.post(`/rapi/chemicals/${modalController.data.productId}/sds/replace`, sendData)
        if (modalController.data.updateSdsList) {
          modalController.data.updateSdsList(result.data.sdsList)
        }
        displaySuccess(result.data.message)
        setIsLoading(false)
        modalController.close()
      } catch (error) {
        displayError(error)
        setIsLoading(false)
      }
  };

  const infoMessage = useMemo(() => {
    if (sdsList) {
      // Preliminary message has higher priorioty
      if (sdsList[selectedLanguage] && sdsList[selectedLanguage].PRELIMINARY) {
        return 'chemicals.preliminary_sds_exists'
      } else if (sdsList[selectedLanguage] && sdsList[selectedLanguage].ACTIVE) {
        if (modalController.data.isAutoApproveSds === true) {
          return 'chemicals.active_sds_exists'
        } else {
          return 'chemicals.active_sds_exists_to_be_approved'
        }
      }

      return 'chemicals.new_sds_must_be_approved'
    }
    return null
  }, [modalController.data.isAutoApproveSds, sdsList, selectedLanguage])

  const validateFile = (value) => {
    if (value.some((file) => !file.deleted && file.file.type !== 'application/pdf')) {
      return { id: 'validation.upload_requires_pdf' }
    }
  }

  return (
    <Form onSubmit={onSubmit} useFormData={true}>
      <Content>
        <Section>
          <Field
            id="language"
            initialValue={defaultMarketLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            className="flex-auto-width"
            label={intl.formatMessage({ id: 'common.language' })}
            productExists={true}
            Component={MarketLanguageDropdown}
            required={true}
            style={{ maxWidth: '200px' }}
          />
          <Field
            id="sds"
            text={intl.formatMessage({ id: 'sds.replace_sdb' })}
            Component={FileUpload}
            required={true}
            validation={validateFile}
          />
        </Section>

        <FlexRowCenterHorizontal>
          <InfoPanel messageid={infoMessage}/>
        </FlexRowCenterHorizontal>
      </Content>
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button
          submit
          primary
          icon="pi pi-save"
          label={intl.formatMessage({ id: 'common.save' })}
          isLoading={isLoading}
          disabled={sdsListLoading || infoMessage === 'chemicals.preliminary_sds_exists'}
        />
      </ModalActionsFooter>
    </Form>
  );
};

export default injectIntl(ReplaceProductSDBDialog);