import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { H3Line, LineHeading, Section, TextContainer } from 'Components/layout'
import { Column, DataTable, RichTextDisplay } from 'Components/readonly'
import styled from 'styled-components'

const GloveTable = styled(DataTable)`
  &&& {
    &.p-datatable .p-datatable-wrapper {
      min-height: auto;
    }

    table {
      width: auto;
    }
  }
`

export default ({ product }) => {
  const intl = useIntl()

  // Don't show info for preliminary products
  if (product.status === 'PRELIMINARY') {
    return (
      <Section>
        <FormattedMessage id="chemicals.no-preliminary-info"/>
      </Section>
    )
  }

  return (
    <>
      <TextContainer>
        <H3Line>
          <FormattedMessage id="chemicals.view.accidental_release"/>
        </H3Line>
        <h4>
          <FormattedMessage id="chemicals.view.accidental_release_personal_precautions"/>
        </h4>
        <p>
          <RichTextDisplay id="accidental_release_personal_precautions"
                           value={product.accidental_release_personal_precautions}/>
        </p>
        <h4>
          <FormattedMessage id="chemicals.view.accidental_release_environmental_precuations"/>
        </h4>
        <p>
          <RichTextDisplay id="accidental_release_environmental_precuations"
                           value={product.accidental_release_environmental_precuations}/>
        </p>
        <h4>
          <FormattedMessage id="chemicals.accidental_release_methods_materials2"/>
        </h4>
        <p>
          <RichTextDisplay id="accidental_release_methods_and_material"
                           value={product.accidental_release_methods_and_material}/>
        </p>
        <h4>
          <FormattedMessage id="chemicals.accidental_release_reference_to_other_sections2"/>
        </h4>
        <p>
          <RichTextDisplay
            id="accidental_release_reference_to_other_sections"
            value={product.accidental_release_reference_to_other_sections}
          />
        </p>

        <H3Line>
          <FormattedMessage id="chemicals.form.dnel.exposure_dnel"/>
        </H3Line>

        {product.chemical_ingredients.length === 0 ? '-' : <FormattedMessage id="chemicals.see_chapter_8_in_safety_sheet" />}
        <H3Line>
          <FormattedMessage id="chemicals.form.pnec.pnec"/>
        </H3Line>
        {product.chemical_ingredients.length === 0 ? '-': <FormattedMessage id="chemicals.see_chapter_8_in_safety_sheet" />}
        <H3Line>
          <FormattedMessage id="chemicals.view.limitation_of_exposure"/>
        </H3Line>
        <p id="limitation_of_exposure">
          <RichTextDisplay value={product.limitation_of_exposure}/>
        </p>
        <h4>
          <FormattedMessage id="chemicals.view.suitable_technical_control_measures"/>
        </h4>
        <p>
          <RichTextDisplay id="suitable_technical_control_measures"
                           value={product.suitable_technical_control_measures}/>
        </p>
      </TextContainer>
      <Section>
        <h4>
          <FormattedMessage id="chemicals.form.gloves"/>
        </h4>
        <GloveTable value={product.gloves} className="flextable">
          <Column
            key="material"
            field="material"
            header={intl.formatMessage({ id: 'chemicals.material' })}
            body={(row, data) => {
              return <span id={'gloves-' + data.rowIndex + '-material'}>{row.material}</span>
            }}
          />
          <Column
            key="material"
            field="material"
            header={intl.formatMessage({ id: 'chemicals.form.gloves_thickness' })}
            body={(row, data) => <span
              id={'gloves-' + data.rowIndex + '-thickness'}>{parseFloat(row.thickness).toFixed(2)}</span>}
          />
          <Column
            key="material"
            field="material"
            header={intl.formatMessage({ id: 'chemicals.form.gloves_breakthrough_time' })}
            body={(row, data) => <span
              id={'gloves-' + data.rowIndex + '-breakthrough_time'}>{row.breakthrough_time}</span>}
          />
        </GloveTable>
      </Section>

      <TextContainer>
        <h4>
          <FormattedMessage id="chemicals.form.eye_face_protection"/>
        </h4>
        <p>
          <RichTextDisplay id="eye_face_protection" value={product.eye_face_protection}/>
        </p>
        <h4>
          <FormattedMessage id="chemicals.form.skin_protection"/>
        </h4>
        <p>
          <RichTextDisplay id="skin_protection" value={product.skin_protection}/>
        </p>
        <h4>
          <FormattedMessage id="chemicals.form.respiratory_protection"/>
        </h4>
        <p>
          <RichTextDisplay id="respiratory_protection" value={product.respiratory_protection}/>
        </p>
        <h4>
          <FormattedMessage id="chemicals.view.limitation_of_environmental_exposure"/>
        </h4>
        <p>
          <RichTextDisplay id="limitation_of_environmental_exposure"
                           value={product.limitation_of_environmental_exposure}/>
        </p>
      </TextContainer>
    </>
  )
}
