import React from 'react'

const lineHeight = 20

export default ({ text, y, width, updateTextHeight, ...props }) => {
  const [tspans, setTspans] = React.useState([])

  const measureRefCallback = React.useCallback(
    (node) => {
      if (node) {
        const tspanNode = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
        const textNode = document.createTextNode('')
        const tspans = []
        let line = []

        tspanNode.appendChild(textNode)
        node.appendChild(tspanNode)

        text.split(/[_-\s]+/).forEach((word) => {
          line.push(word)
          textNode.data = line.join(' ')

          if (tspanNode.getComputedTextLength() > width) {
            // Remove last word since we exceeded max width
            // If a single word exceeds max width keep it
            if (line.length > 1) {
              line.pop()
            }
            tspans.push(
              <tspan x="0" y={y} dy={lineHeight * tspans.length + 'px'}>
                {line.join(' ')}
              </tspan>
            )
            // Begin a new row
            if (line.length > 1) {
              line = [word]
            } else {
              line = []
            }
          }
        })

        // Add last line
        tspans.push(
          <tspan x="0" y={y} dy={lineHeight * tspans.length + 'px'}>
            {line.join(' ')}
          </tspan>
        )

        setTspans(tspans)
        node.removeChild(tspanNode)

        if (updateTextHeight) {
          updateTextHeight(lineHeight * tspans.length)
        }
      }
    },
    [text, y, width, updateTextHeight]
  )

  return (
    <text ref={measureRefCallback} y={y} width={width} {...props}>
      {tspans}
    </text>
  )
}
