import React from 'react'
import { FormattedMessage } from 'react-intl'

export const LocationInventoryHeader = ({ chemical }) => {
  return (
    <>
      <h3>{chemical.name}</h3>
      <h4>
        {chemical.supplier} <FormattedMessage id="chemicals.article_id"/>: {chemical.article_number}
      </h4>
    </>
  )
}
