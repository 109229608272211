import React from 'react'
import styled from 'styled-components'

const InfoBanner = styled.div`
  width: 100%;
  display: flex;
  height: 70px;
  flex-direction: row;
  align-items: center;
  background-color: #ffba01;

  @media screen and (max-width: 640px) {
    height: auto;
  }
`

const InfoBannerText = styled.p`
  max-width: 800px;
  margin-top: 10px;
  color: #000000;
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;

  @media screen and (max-width: 640px) {
    padding: 10px;
  }
`

export const Banner = ({ children }) => {
  return (
    <InfoBanner>
      <InfoBannerText>
        {children}
      </InfoBannerText>
    </InfoBanner>
  )
}
