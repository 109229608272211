import axios from 'axios'
import { CategoryPickListHazard } from 'Components/combined/categoryPickListHazard'
import { ChemicalDnelRow } from 'Components/combined/chemicalDnelRow'
import { ChemicalIngredientList } from 'Components/combined/chemicalIngredientList'
import { ChemicalPnecRow } from 'Components/combined/chemicalPnecRow'
import { ChemicalPropList } from 'Components/combined/chemicalPropList'
import { CodeMultiselectPickList } from 'Components/combined/codeMultiselectPickList'
import { GeneralHygieneThresholdPickList } from 'Components/combined/generalHygieneThresholdPickList'
import { GlovesList } from 'Components/combined/glovesList'
import { Field, FieldToggler, FormContext, FormInContext } from 'Components/form'
import { CheckboxGroup,CheckboxGroupSimple, Dropdown, PictogramButtonGroup, RadioButtonGroup, RichTextArea, Switch, TextInput } from 'Components/input'
import { Panel } from 'Components/readonly'
import { GrowlContext } from 'Context'
import useCachedGetData from 'Hooks/useCachedGetData'
import * as React from 'react'
import { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import SdsFilterSelector from './sdsFilterSelector'
import { HygieneThresholdsList } from 'Components/combined/hygieneThresholdsList'

const StyledPanel = styled(Panel)`
  padding: 0 20px 20px 20px;

  ${Field} {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  > label {
    display: inline-block;
    color: #fefefe;
  }
`

const ProductEditForm = React.forwardRef(({ productId, lang }, ref) => {
  const intl = useIntl()
  const { displayError } = useContext(GrowlContext)
  const [hazardCategoriesLoading, hazardCategories] = useCachedGetData('/rapi/chemicals/hazardcategories')
  const [hazardStatementsLoading, hazardStatements] = useCachedGetData('/rapi/chemicals/hazardstatements')
  const [precautionaryStatementsLoading, precautionaryStatements] = useCachedGetData('/rapi/chemicals/precautionarystatements')
  const [generalHygieneThresholdsLoading, generalHygieneThresholds] = useCachedGetData('/rapi/chemicals/generalhygienethreholds')
  const [filterTypesLoading, filterTypes] = useCachedGetData('/rapi/admin/ingredientfilter/types', {})
  const [isLoadingChemicalSDSFilters, chemicalSDSFilters] = useCachedGetData('/rapi/admin/chemicalsdsfilter/grouped', [])
  const [, reasonNotTrustworthyPhrases] = useCachedGetData('/rapi/admin/reasonnottrustworthyphrases/' + lang, [])

  const { setFieldValue } = useContext(FormContext)

  // Transform data for GroupedCheckboxGroup options
  const chemicalSDSFilterOptions = React.useMemo(() => {
    const result = {}
    Object.keys(chemicalSDSFilters).forEach((type) => {
      result[type] = chemicalSDSFilters[type]
        .map((filter) => ({
          label: filter.name,
          value: filter.id,
          is_binding: filter.is_binding,
          reason_not_trustworthy_phrase_id: filter.reason_not_trustworthy_phrase_id,
          enabled: filter.enabled
        }))
        .filter((sdsFilter) => sdsFilter.enabled)
    })
    return result
  }, [chemicalSDSFilters])

  const signalWordOptions = [
    { value: 'hazard', label: intl.formatMessage({ id: 'chemicals.form.hazard' }) },
    { value: 'warning', label: intl.formatMessage({ id: 'chemicals.form.warning' }) },
    { value: 'not_applicable', label: intl.formatMessage({ id: 'chemicals.form.not_applicable' }) }
  ]

  const packingGroupOptions = [
    {
      value: 'not_applicable',
      label: intl.formatMessage({ id: 'chemicals.form.packing_group_options.not_applicable' })
    },
    { value: 'i', label: intl.formatMessage({ id: 'chemicals.form.packing_group_options.i' }) },
    { value: 'ii', label: intl.formatMessage({ id: 'chemicals.form.packing_group_options.ii' }) },
    { value: 'iii', label: intl.formatMessage({ id: 'chemicals.form.packing_group_options.iii' }) }
  ]

  const formRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    getChildren: (properties) => formRef.current.getChildren(properties)
  }))

  const ProductTextField = (id, options = {}) => {
    return (
      <Field
        id={lang + '.' + id}
        headingLevel={options.headingLevel}
        label={intl.formatMessage({ id: 'chemicals.form.' + id })}
        Component={TextInput}
        required={options.required}
      />
    )
  }

  const ProductTextArea = (id, options = {}) => {
    return (
      <Field
        id={lang + '.' + id}
        label={intl.formatMessage({ id: 'chemicals.form.' + id })}
        headingLevel={options.headingLevel}
        placeholder={options.placeholder ? intl.formatMessage({ id: 'chemicals.form.' + id + '_placeholder' }) : null}
        Component={RichTextArea}
        maxLength="65535"
        minRows={3}
        maxRows={10}
      />
    )
  }

  const validateIngredientList = (value, formData) => {
    const isIngredientValid = (ingredient) =>
      ingredient.ingredient_variant.ingredient.names.length > 0 &&
      ingredient.ingredient_variant.ingredient.names[0].name &&
      ingredient.ingredient_variant.ingredient.names[1].name &&
      ingredient.ingredient_variant.ingredient.names[2].name &&
      ingredient.ingredient_variant.ingredient.names[3].name

    return value.map(isIngredientValid).filter((result) => !result).length > 0 ? { id: 'chemicals.all_ingredients_must_have_name' } : null
  }

  const validateDnelList = (value) => {
    const isDnelValid = (dnel) => {
      const errors = []
      if (!dnel.frequency)
        errors.push({
          id: 'validation.required',
          values: { attribute: 'chemicals.form.dnel.frequency' }
        })
      if (!dnel.path) errors.push({ id: 'validation.required', values: { attribute: 'chemicals.form.dnel.path' } })
      if (!dnel.target)
        errors.push({
          id: 'validation.required',
          values: { attribute: 'chemicals.form.dnel.target_audience' }
        })
      if (!dnel.type) errors.push({ id: 'validation.required', values: { attribute: 'chemicals.form.dnel.type' } })
      if (!dnel.value || String(dnel.value).trim().length === 0)
        errors.push({ id: 'validation.required', values: { attribute: 'chemicals.form.dnel.value' } })
      return errors
    }
    return value.map((ingredient) => ingredient.dnel.map(isDnelValid)).flat(2)
  }

  const validatePnecList = (value) => {
    const isPnecValid = (pnec) => {
      const errors = []
      if (!pnec.exposure_type)
        errors.push({
          id: 'validation.required',
          values: { attribute: 'chemicals.form.pnec.exposure_type' }
        })
      if (!pnec.value || String(pnec.value).trim().length === 0)
        errors.push({ id: 'validation.required', values: { attribute: 'chemicals.form.pnec.value' } })
      return errors
    }
    return value.map((ingredient) => ingredient.pnec.map(isPnecValid)).flat(2)
  }

  const validateGloveList = (value, formData) => {
    const isNonEmptyString = (v) => typeof v === 'string' && v.length > 0
    const isNonEmptyNumber = (v) => (typeof v === 'string' && v.length > 0 && !Number(v).NaN) || typeof v === 'number'

    // Thickness and breakthrough_time uses a key input filter in addition
    const isGloveValid = (glove) =>
      isNonEmptyString(glove.material) && isNonEmptyNumber(glove.thickness) && isNonEmptyNumber(glove.breakthrough_time)

    return value.map(isGloveValid).filter((result) => !result).length > 0 ? { id: 'chemicals.form.no_gloves_field_may_be_empty' } : null
  }

  const validateHazards = (value, formData) => {
    if (!formData.has_classification) {
      return null
    }

    if (value.hazard_categories.length === 0) {
      return { id: 'chemicals.form.hazard_category_required' }
    }
  }

  const validateSignalWord = (value, formData) => {
    if (!formData.has_classification) {
      return null
    }

    if (!value) {
      return { id: 'chemicals.form.signal_word_required' }
    }
  }

  const bindingFilters = React.useMemo(() => {
    // Collect binding filter
    const bindingFilters = []
    Object.keys(chemicalSDSFilters).forEach((type) => {
      bindingFilters.push(...chemicalSDSFilters[type].filter((filter) => filter.is_binding).filter((filter) => filter.enabled).map((filter) => filter.id))
    })
    return bindingFilters
  }, [chemicalSDSFilters])

  const validateReasonNotTrustworth = (value, formData) => {
    // Remove checked filters
    const missingBindingFilters = bindingFilters.filter((filterId) => !formData.sds_filters.includes(filterId))

    return !value && missingBindingFilters.length > 0 ? { id: 'chemicals.sds-filter-not-trustworthy' } : null
  }

  const handlePrefillDnelPnec = async (ingredient) => {
    // Prefill dnel / pnec
    try {
      const prefillResult = await axios('/rapi/ingredients/variant/' + ingredient.ingredient_variant.id + '/prefilldnelpnec')
      if (prefillResult.data) {
        setFieldValue('chemical_ingredients', (oldIngredients) => {
          const updatedIngredients = [...oldIngredients]
          const updatedIngredient = updatedIngredients.find((i) => i.ingredient_variant.id === ingredient.ingredient_variant.id)
          if (updatedIngredient) {
            if (prefillResult.data.dnel) {
              updatedIngredient.dnel = updatedIngredient.dnel.concat(prefillResult.data.dnel)
            }
            if (prefillResult.data.pnec) {
              updatedIngredient.pnec = updatedIngredient.pnec.concat(prefillResult.data.pnec)
            }
          }
          return updatedIngredients
        })
      }
    } catch (e) {
      displayError(e)
    }
  }

  const showSDSFilterSelector = () => {
    return Object.values(chemicalSDSFilterOptions).some((arr) => arr.length > 0)
  }

  return (
    <FormInContext ref={formRef} id={'product-edit-form-' + lang}>
      <StyledPanel lighter>
        <h2>
          <FormattedMessage id="chemicals.form.part1" />
        </h2>
        <h3>
          <FormattedMessage id="chemicals.form.part1-1" />
        </h3>
        {ProductTextField('name', { required: true })}
        {ProductTextField('article_number')}

        <h3>
          <FormattedMessage id="chemicals.form.part1-2" />
        </h3>
        {ProductTextArea('identified_uses')}

        <h3>
          <FormattedMessage id="chemicals.form.part1-3" />
        </h3>
        {ProductTextField('supplier', { required: true })}
      </StyledPanel>
      <StyledPanel lighter>
        <Field
          id={'has_classification'}
          label={intl.formatMessage({ id: 'chemicals.form.has_classification' })}
          Component={Switch}
          onLabel={intl.formatMessage({ id: 'common.yes' })}
          offLabel={intl.formatMessage({ id: 'common.no' })}
        />
      </StyledPanel>
      <FieldToggler show="has_classification">
        <StyledPanel lighter>
          <h2>
            <FormattedMessage id="chemicals.form.part2" />
          </h2>
          <Field
            id={'hazards'}
            label={intl.formatMessage({ id: 'chemicals.form.classification_marking' })}
            placeholder={intl.formatMessage({ id: 'chemicals.form.classification_marking_placeholder' })}
            Component={CategoryPickListHazard}
            categoryList={hazardCategories}
            codeList={hazardStatements}
            isLoading={hazardCategoriesLoading}
            language={lang}
            validation={validateHazards}
          />
        </StyledPanel>
        <StyledPanel lighter>
          <Field
            id={'hazard_pictograms'}
            label={intl.formatMessage({ id: 'chemicals.form.hazard_pictograms' })}
            Component={PictogramButtonGroup}
            property="code"
          />
        </StyledPanel>
        <StyledPanel lighter>
          <Field
            id={'signal_word'}
            label={intl.formatMessage({ id: 'chemicals.form.signal_word' })}
            Component={RadioButtonGroup}
            options={signalWordOptions}
            validation={validateSignalWord}
          />
        </StyledPanel>
      </FieldToggler>
      <StyledPanel lighter>
        <Field
          id={lang + '.precautionary_statements'}
          label={intl.formatMessage({ id: 'chemicals.form.precautionary_statements' })}
          Component={CodeMultiselectPickList}
          codeList={precautionaryStatements}
          isLoading={precautionaryStatementsLoading}
          language={lang}
        />
      </StyledPanel>
      <StyledPanel lighter>{ProductTextArea('supplemental_hazard_information', true)}</StyledPanel>
      <StyledPanel lighter>{ProductTextArea('other_hazards', { headingLevel: 3 })}</StyledPanel>
      <StyledPanel lighter>
        <h2>
          <FormattedMessage id="chemicals.form.part3" />
        </h2>
        <Field
          id={'chemical_ingredients'}
          label={intl.formatMessage({ id: 'chemicals.form.ingredients' })}
          onAddExisting={handlePrefillDnelPnec}
          Component={ChemicalIngredientList}
          hazardCategoriesList={hazardCategories}
          hazardCategoriesIsLoading={hazardCategoriesLoading}
          hazardStatementsList={hazardStatements}
          hazardStatementsIsLoading={hazardStatementsLoading}
          filterTypes={filterTypes}
          filterTypesIsLoading={filterTypesLoading}
          productId={productId}
          validation={(value, formData) => validateIngredientList(value, formData)}
          lang={lang}
        />
      </StyledPanel>
      <StyledPanel lighter>
        <h2>
          <FormattedMessage id="chemicals.form.part4" />
        </h2>
        <h3>
          <FormattedMessage id="chemicals.form.part4-1" />
        </h3>
        {ProductTextArea('first_aid_general')}
        {ProductTextArea('first_aid_inhalation')}
        {ProductTextArea('first_aid_eye_contact')}
        {ProductTextArea('first_aid_skin_contact')}
        {ProductTextArea('first_aid_ingestion')}
      </StyledPanel>
      <StyledPanel lighter>
        <h3>{intl.formatMessage({ id: 'chemicals.form.symptom' })}</h3>
        {ProductTextArea('symptom_general')}
        {ProductTextArea('symptom_inhalation')}
        {ProductTextArea('symptom_eye_contact')}
        {ProductTextArea('symptom_skin_contact')}
        {ProductTextArea('symptom_consumption')}
      </StyledPanel>
      <StyledPanel lighter>{ProductTextArea('immediate_medical_attention', { headingLevel: 3 })}</StyledPanel>
      <StyledPanel lighter>
        <h2>
          <FormattedMessage id="chemicals.form.part5" />
        </h2>
        <h3>
          <FormattedMessage id="chemicals.form.part5-1" />
        </h3>
        {ProductTextArea('suitable_extinguishing_media')}
        {ProductTextArea('unsuitable_extinguishing_media')}
        <h3>
          <FormattedMessage id="chemicals.form.part5-2" />
        </h3>
        {ProductTextArea('special_hazards_in_case_of_fire')}
        {ProductTextArea('advice_for_firefighters', { headingLevel: 3 })}
      </StyledPanel>
      <StyledPanel lighter>
        <h2>{intl.formatMessage({ id: 'chemicals.form.accidental_release' })}</h2>
        {ProductTextArea('accidental_release_personal_precautions', { headingLevel: 3 })}
        {ProductTextArea('accidental_release_environmental_precuations', { headingLevel: 3 })}
        {ProductTextArea('accidental_release_methods_and_material', { headingLevel: 3 })}
        {ProductTextArea('accidental_release_reference_to_other_sections', { headingLevel: 3, placeholder: true })}
      </StyledPanel>
      <StyledPanel lighter>
        <h2>
          <FormattedMessage id="chemicals.form.part8" />
        </h2>
        <h3>
          <FormattedMessage id="chemicals.form.part8-1" />
        </h3>
        <h4>
          <FormattedMessage id="chemicals.form.part8-1-1" />
        </h4>
        <Field
          id="chemical_ingredients"
          label={intl.formatMessage({ id: 'hygiene-thresholds.title' })}
          Component={HygieneThresholdsList}
          isLoading={false}
          language={lang}
          productId={productId}
        />
        <Field
          id="general_hygiene_thresholds"
          label={intl.formatMessage({ id: 'chemicals.form.hygiene_thresholds.general' })}
          Component={GeneralHygieneThresholdPickList}
          list={generalHygieneThresholds}
          isLoading={generalHygieneThresholdsLoading}
          language={lang}
        />
      </StyledPanel>
      {/* <StyledPanel lighter>
        <Field
          id={lang + '.chemical_ingredients.dnel'}
          propId="chemical_ingredients"
          label={intl.formatMessage({ id: 'chemicals.form.dnel.exposure_dnel' })}
          Component={ChemicalPropList}
          RowComponent={ChemicalDnelRow}
          componentProp="dnel"
          validation={(value) => validateDnelList(value)}
          lang={lang}
        />
      </StyledPanel>
      <StyledPanel lighter>
        <Field
          id={lang + '.chemical_ingredients.pnec'}
          propId="chemical_ingredients"
          label={intl.formatMessage({ id: 'chemicals.form.pnec.pnec' })}
          Component={ChemicalPropList}
          RowComponent={ChemicalPnecRow}
          componentProp="pnec"
          validation={(value) => validatePnecList(value)}
          lang={lang}
        />
      </StyledPanel> */}
      <StyledPanel lighter>
        {ProductTextArea('limitation_of_exposure', { headingLevel: 3 })}
        {ProductTextArea('suitable_technical_control_measures', { headingLevel: 4 })}

        <h4>
          <FormattedMessage id="chemicals.form.part8-2-2" />
        </h4>
        {ProductTextArea('eye_face_protection')}
        {ProductTextArea('skin_protection')}
        <Field id={lang + '.gloves'} Component={GlovesList} validation={validateGloveList} />
        {ProductTextArea('respiratory_protection')}
        {ProductTextArea('limitation_of_environmental_exposure', { headingLevel: 4 })}
      </StyledPanel>
      <StyledPanel lighter>
        <h2>
          <FormattedMessage id="chemicals.form.part13" />
        </h2>
        <Field id={lang + '.waste_information'} Component={RichTextArea} maxLength="65535" minRows={3} maxRows={10} />
      </StyledPanel>
      <StyledPanel lighter>
        <h2>
          <FormattedMessage id="chemicals.form.part14" />
        </h2>
        {ProductTextField('un_number', { headingLevel: 3 })}
        {ProductTextArea('transport_information', { headingLevel: 3 })}

        <Field
          id="packing_group"
          label={intl.formatMessage({ id: 'chemicals.form.packing_group' })}
          headingLevel={3}
          Component={Dropdown}
          options={packingGroupOptions}
        />
      </StyledPanel>
      <StyledPanel>
        {showSDSFilterSelector() && (
          <Field
            id="sds_filters"
            label={intl.formatMessage({ id: 'chemicals.sds-filter-satisfies' })}
            Component={SdsFilterSelector}
            options={chemicalSDSFilterOptions}
            bindingFilters={bindingFilters}
            isLoading={isLoadingChemicalSDSFilters}
            productId={productId}
          />
        )}
        <Field
          id={lang + '.reason_not_trustworthy_phrases'}
          initialValue={[]}
          label={null} // Hidden for now
          placeholder={intl.formatMessage({ id: 'chemicals.form.reason_not_trustworthy' })}
          Component={CheckboxGroupSimple}
          fieldOnChange={true}
          options={reasonNotTrustworthyPhrases}
          isToggleInverted={true}
          validation={validateReasonNotTrustworth}
        />
      </StyledPanel>
    </FormInContext>
  )
})

export default ProductEditForm
