import { FormContext } from 'Components/form'
import { GroupedCheckboxGroupSimple } from 'Components/input/checkboxGroupSimple'
import { LoadingOverlay } from 'Components/readonly'
import axios from 'axios'
import React from 'react'

const SdsFilterSelector = ({ isLoading, value, onChange, bindingFilters, productId, ...props }) => {
  const { getFieldValue, setFieldValue } = React.useContext(FormContext)
  const [status, setStatus] = React.useState(null);


  const internalValue = value.map((filterId) => ({
    value: filterId
  }))

  React.useEffect(() => {
    updateReason(value)
    // Only run once when filters loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bindingFilters, status])

  React.useEffect(() => {
    const fetchStatus = async () => {
      if(productId) {
        try {
          const result = await axios.get(`/rapi/chemicals/${productId}/status`);
          if(result.data) {
            setStatus(result.data);
          }
        } catch (error) {
          console.error('Failed to fetch product status', error);
          // Optionally set some state here to show an error message
        }
      }
    };
    fetchStatus();
  }, [productId]);
  

  const getFilter = (filterId) => {
    const keys = Object.keys(props.options)
    for (let i = 0; i < keys.length; i++) {
      const type = keys[i]
      const filter = props.options[type].find((filter) => filter.value === filterId)
      if (filter) {
        return filter
      }
    }
    return null
  }

  const updateReason = (newValue) => {
    if (bindingFilters) {
      const satisfiedBindingFilters = bindingFilters.filter((filterId) => newValue.includes(filterId))
      const missingBindingFilters = bindingFilters.filter((filterId) => !newValue.includes(filterId))

      let reasonPhrasesEn = getFieldValue('en.reason_not_trustworthy_phrases')
      let reasonPhrasesSv = getFieldValue('sv.reason_not_trustworthy_phrases')
      let reasonPhrasesNo = getFieldValue('no.reason_not_trustworthy_phrases')
      let reasonPhrasesDk = getFieldValue('dk.reason_not_trustworthy_phrases')

      const enReasonWasNull = reasonPhrasesEn === null
      const svReasonWasNull = reasonPhrasesSv === null
      const noReasonWasNull = reasonPhrasesNo === null
      const dkReasonWasNull = reasonPhrasesDk === null

      satisfiedBindingFilters.forEach((filterId) => {
        const filter = getFilter(filterId)
        // Remove this phrase
        if (filter && filter.reason_not_trustworthy_phrase_id) {
          if (reasonPhrasesEn) {
            reasonPhrasesEn = reasonPhrasesEn.filter((phrase) => phrase !== filter.reason_not_trustworthy_phrase_id)
          }
          if (reasonPhrasesSv) {
            reasonPhrasesSv = reasonPhrasesSv.filter((phrase) => phrase !== filter.reason_not_trustworthy_phrase_id)
          }
          if (reasonPhrasesNo) {
            reasonPhrasesNo = reasonPhrasesNo.filter((phrase) => phrase !== filter.reason_not_trustworthy_phrase_id)
          }
          if (reasonPhrasesDk) {
            reasonPhrasesDk = reasonPhrasesDk.filter((phrase) => phrase !== filter.reason_not_trustworthy_phrase_id)
          }
        }
      })

      missingBindingFilters.forEach((filterId) => {
        const filter = getFilter(filterId)
        // Add this phrase
        if (filter && filter.reason_not_trustworthy_phrase_id) {
          if (!reasonPhrasesEn) {
            reasonPhrasesEn = []
          }
          if (!reasonPhrasesEn.includes(filter.reason_not_trustworthy_phrase_id)) {
            reasonPhrasesEn.push(filter.reason_not_trustworthy_phrase_id)
          }
          if (!reasonPhrasesSv) {
            reasonPhrasesSv = []
          }
          if (!reasonPhrasesSv.includes(filter.reason_not_trustworthy_phrase_id)) {
            reasonPhrasesSv.push(filter.reason_not_trustworthy_phrase_id)
          }
          if (!reasonPhrasesNo) {
            reasonPhrasesNo = []
          }
          if (!reasonPhrasesNo.includes(filter.reason_not_trustworthy_phrase_id)) {
            reasonPhrasesNo.push(filter.reason_not_trustworthy_phrase_id)
          }
          if (!reasonPhrasesDk) {
            reasonPhrasesDk = []
          }
          if (!reasonPhrasesDk.includes(filter.reason_not_trustworthy_phrase_id)) {
            reasonPhrasesDk.push(filter.reason_not_trustworthy_phrase_id)
          }
        }
      })

      // Restore null if empty
      if (enReasonWasNull && reasonPhrasesEn === []) {
        reasonPhrasesEn = null
      }
      if (svReasonWasNull && reasonPhrasesSv === []) {
        reasonPhrasesSv = null
      }
      if (noReasonWasNull && reasonPhrasesNo === []) {
        reasonPhrasesNo = null
      }
      if (dkReasonWasNull && reasonPhrasesDk === []) {
        reasonPhrasesDk = null
      }

      
      if(status){
        setFieldValue('en.reason_not_trustworthy_phrases', reasonPhrasesEn)
        setFieldValue('sv.reason_not_trustworthy_phrases', reasonPhrasesSv)
        setFieldValue('no.reason_not_trustworthy_phrases', reasonPhrasesNo)
        setFieldValue('dk.reason_not_trustworthy_phrases', reasonPhrasesDk)
      }
    }
  }

  const internalOnChange = (selected) => {
    const newValue = selected.map((filter) => filter.value)
    onChange({ target: { value: newValue } })
    updateReason(newValue)
  }

  return (
    <LoadingOverlay isLoading={isLoading}>
      <GroupedCheckboxGroupSimple value={internalValue} onChange={internalOnChange} {...props} />
    </LoadingOverlay>
  )
}

export default SdsFilterSelector
