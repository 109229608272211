import { HasCapability } from 'Components/capabilities'
import colors from 'Components/colors.scss'
import { Section, Spacer } from 'Components/layout'
import { Button, Divider, Panel, ProtectiveGearDisplay, RichTextDisplay, RiskfactorVisualization, ToolPanel } from 'Components/readonly'
import { CalculateRiskfactor } from 'Components/readonly/riskfactor_visualization'
import { format } from 'date-fns'
import useToggleItems from 'Hooks/useToggleItems'
import * as React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'
import AirPollutionDisplay from './airPollutionDisplay'
import SafetySheetLink from './SafetySheetLink'

const Wrapper = styled.div`
  ${Panel} {
    margin-bottom: 10px;
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .risk-value {
    flex: 0 0 250px;
    border-right: 30px solid transparent;
  }

  .risk-protection {
    flex: 0 1 280px;
    border-right: 30px solid transparent;
  }

  .risk-description {
    flex: 1 1 350px;
    border-right: 30px solid transparent;
  }

  .risk-info {
    flex: 0 0 280px;
  }
`

const RiskfactorVisualizationWrapper = styled.div`
  display: block;
  margin-bottom: 10px;

  ${RiskfactorVisualization} {
    margin-left: auto;
    margin-right: auto;
  }
`

const Header = styled.div`
  &&& {
    display: flex;
    padding: 10px 0 5px 0;

    h3 {
      flex: 1 1 auto;
      padding: 0;
    }

    a {
      flex: 0 0 auto;
      color: ${colors.white};
    }

    button {
      margin: 0;
    }
  }
`

const SDSPrintButton = styled(Button)`
  &&&& {
    .p-button-text {
      padding-right: 0;
    }
  }
`

const Download = styled(Section)`
  > div {
    padding: 5px;
  }
`

const RiskAssessmentPreview = ({
  intl,
  product,
  riskAssessment,
  selectedSds,
  horizontal,
  archiveRiskAssessment,
  setSelectedRiskAssessment
}) => {
  const usageFrequencyOptions = {
    0: intl.formatMessage({ id: 'riskassessment.usage_frequency_options.daily' }),
    1: intl.formatMessage({ id: 'riskassessment.usage_frequency_options.weekly' }),
    2: intl.formatMessage({ id: 'riskassessment.usage_frequency_options.monthly' }),
    3: intl.formatMessage({ id: 'riskassessment.usage_frequency_options.yearly' })
  }

  const usageDurationOptions = {
    0: '<' + intl.formatMessage({ id: 'riskassessment.usage_duration_options.1m' }),
    1: intl.formatMessage({ id: 'riskassessment.usage_duration_options.5-10m' }),
    2: intl.formatMessage({ id: 'riskassessment.usage_duration_options.30-60m' }),
    3: intl.formatMessage({ id: 'riskassessment.usage_duration_options.1-2h' }),
    4: intl.formatMessage({ id: 'riskassessment.usage_duration_options.24h' })
  }

  const capialize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

  const renderUsageFrequency = () => {
    // We can't use !riskAssessment.usage_frequency since "0" is an accepted value for the usage frequency
    if (riskAssessment.usage_frequency === NaN || riskAssessment.usage_frequency === null || riskAssessment.usage_frequency === undefined) {
      return <FormattedMessage id="common.not_relevant" />
    }
    return <span>{usageFrequencyOptions[riskAssessment.usage_frequency]}</span>
  }

  const renderUsage = () => {
    // We can't use !riskAssessment.usage_duration since "0" is an accepted value for the usage duration
    if (riskAssessment.usage_duration === NaN || riskAssessment.usage_duration === null || riskAssessment.usage_duration === undefined) {
      return <FormattedMessage id="common.not_relevant" />
    }
    console.log({ usageDurationOptions, dur: riskAssessment.usage_duration })
    return <span>{capialize(usageDurationOptions[riskAssessment.usage_duration].toLowerCase())}</span>
  }

  const renderContact = () => {
    let contact = []
    if (riskAssessment.contact_inhalation) {
      contact.push(intl.formatMessage({ id: 'riskassessment.contact_inhalation' }))
    }
    if (riskAssessment.contact_eyes) {
      contact.push(intl.formatMessage({ id: 'riskassessment.contact_eyes' }))
    }
    if (riskAssessment.contact_skin) {
      contact.push(intl.formatMessage({ id: 'riskassessment.contact_skin' }))
    }
    if (riskAssessment.contact_ingestion) {
      contact.push(intl.formatMessage({ id: 'riskassessment.contact_ingestion' }))
    }
    return capialize(contact.join(', ').toLowerCase())
  }

  const renderEducation = () => {
    if (riskAssessment.education === null) {
      return <FormattedMessage id="riskassessment.preview.no_education_needed" />
    }

    if (riskAssessment.education) {
      return riskAssessment.education
    }

    return <FormattedMessage id="riskassessment.preview.education_is_needed" />
  }

  let initialToggleState = {}
  initialToggleState[riskAssessment.id] = true
  const [toggleRiskAssState, toggleRiskAssessment] = useToggleItems(initialToggleState)

  return (
    <Wrapper>
      {horizontal ? (
        <Panel>
          <Header>
            <h3>
              {intl.formatMessage({ id: 'riskassessment.preview.applies_to' }) + ' ' + riskAssessment.applies_to}
              {riskAssessment.location && <> {riskAssessment.location.name}</>}
              {riskAssessment.archived_at && (
                <span style={{ color: colors.accent, paddingLeft: '10px' }}>
                  <FormattedMessage id="common.archived2" />
                </span>
              )}
            </h3>
            <SafetySheetLink riskAssessment={riskAssessment} />
            <Button
              style={{ marginLeft: '20px' }}
              link
              icon={'pi pi-chevron-' + (toggleRiskAssState[riskAssessment.id] ? 'up' : 'down')}
              onClick={() => toggleRiskAssessment(riskAssessment.id)}
            />
          </Header>
          {toggleRiskAssState[riskAssessment.id] && (
            <>
              <Divider />
              <FlexRow>
                <div className="risk-value">
                  <RiskfactorVisualizationWrapper>
                    <h4>
                      <FormattedMessage id="riskassessment.preview.calculated_risk" />
                    </h4>
                    <RiskfactorVisualization value={riskAssessment.risk_value} is_static={true} />
                  </RiskfactorVisualizationWrapper>
                </div>
                <div className="risk-protection">
                  <h4>
                    <FormattedMessage id="riskassessment.preview.protection" />
                  </h4>
                  <ProtectiveGearDisplay value={riskAssessment.protection} />
                </div>
                <div className="risk-description">
                  <h4>
                    <FormattedMessage id="riskassessment.preview.description" />
                  </h4>
                  <RichTextDisplay value={riskAssessment.work_methods} />
                  <h4>
                    <FormattedMessage id="riskassessment.environment" />
                  </h4>
                  {riskAssessment.environment}
                  <h4>
                    <FormattedMessage id="riskassessment.risk_factors" />
                  </h4>
                  {riskAssessment.risk_factors}
                  <h4>
                    <FormattedMessage id="riskassessment.usage_frequency" />
                  </h4>
                  {renderUsageFrequency()}
                  <h4>
                    <FormattedMessage id="riskassessment.usage_duration" />
                  </h4>
                  {renderUsage()}
                  <h4>
                    <FormattedMessage id="riskassessment.contact" />
                  </h4>
                  {renderContact()}
                  {riskAssessment.form && (
                    <>
                      <h4>
                        <FormattedMessage id="riskassessment.form" />
                      </h4>
                      <FormattedMessage id={'riskassessment.form_options.' + riskAssessment.form} />
                    </>
                  )}
                  <h4>
                    <FormattedMessage id="riskassessment.yearly_consumption" />
                  </h4>
                  {riskAssessment.yearly_consumption}
                </div>
                <div className="risk-info">
                  <h4>
                    <FormattedMessage id="riskassessment.preview.compiled_by" />
                  </h4>
                  {riskAssessment.participants}

                  <h4>
                    <FormattedMessage id="riskassessment.preview.education" />
                  </h4>
                  {renderEducation()}

                  {(riskAssessment.waste_code ||
                    riskAssessment.waste_comment ||
                    riskAssessment.waste_dangerous ||
                    riskAssessment.waste_disposal) && (
                    <>
                      {riskAssessment.waste_code && (
                        <>
                          <h4>
                            <FormattedMessage id="riskassessment.waste_code" />
                          </h4>
                          {riskAssessment.waste_code.join(', ')}
                        </>
                      )}
                      {riskAssessment.waste_comment && (
                        <>
                          <h4>
                            <FormattedMessage id="common.comment" />
                          </h4>
                          <RichTextDisplay value={riskAssessment.waste_comment} style={{ wordBreak: 'break-word' }} />
                        </>
                      )}
                      <h4>
                        <FormattedMessage id="riskassessment.waste_dangerous" />
                      </h4>
                      {riskAssessment.waste_dangerous ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />}
                      {riskAssessment.waste_disposal && (
                        <>
                          <h4>
                            <FormattedMessage id="riskassessment.waste_disposal_description" />
                          </h4>
                          <RichTextDisplay value={riskAssessment.waste_disposal} />
                        </>
                      )}
                    </>
                  )}

                  <ActionPlan riskAssessment={riskAssessment} />

                  {riskAssessment.comment && (
                    <>
                      <h4>
                        <FormattedMessage id="riskassessment.preview.comment" />
                      </h4>
                      <RichTextDisplay value={riskAssessment.comment} style={{ wordBreak: 'break-word' }} />
                    </>
                  )}

                  <h4>
                    <FormattedMessage id="riskassessment.approved_by_name" />
                  </h4>
                  <RichTextDisplay value={riskAssessment.approved_by_name} />
                  <br />
                  <RichTextDisplay value={riskAssessment.approved_by_role} />

                  <h4>
                    <FormattedMessage id="riskassessment.created_updated" />
                  </h4>
                  <p>
                    {format(riskAssessment.updated_at, 'YYYY-MM-DD')} <FormattedMessage id="common.by" /> {riskAssessment.created_by}
                  </p>
                </div>
              </FlexRow>
              <Divider />
              <ToolPanel>
                <Spacer />
                <HasCapability capability="CHEMICAL_MANAGE_RISK_ASSESSMENTS">
                  <Button
                    label={intl.formatMessage({ id: 'common.archive' })}
                    icon="pi pi-folder"
                    onClick={() => archiveRiskAssessment(riskAssessment)}
                  />
                  <Button
                    primary
                    label={intl.formatMessage({ id: 'common.edit' })}
                    icon="pi pi-pencil"
                    onClick={() => setSelectedRiskAssessment(riskAssessment)}
                  />
                </HasCapability>
              </ToolPanel>
            </>
          )}
        </Panel>
      ) : (
        <>
          {<h2>{intl.formatMessage({ id: 'riskassessment.preview.heading' }, { name: product.name })}</h2>}
          {riskAssessment.archived_at && (
            <Panel lighter>
              <h4>
                <FormattedMessage id="common.archived2" />
              </h4>
              {riskAssessment.archived_at}
            </Panel>
          )}
          <RiskfactorVisualizationWrapper>
            <h4>
              <FormattedMessage id="riskassessment.preview.calculated_risk" />
            </h4>
            <RiskfactorVisualization
              value={CalculateRiskfactor(riskAssessment.harmful_probability, riskAssessment.harmful_severity)}
              is_static={true}
            />
          </RiskfactorVisualizationWrapper>
          <Download>
            <SafetySheetLink riskAssessment={riskAssessment} />
            <HasCapability capability="CHEMICAL_PRINT">
              <div style={{ textAlign: 'right' }}>
                <SDSPrintButton
                  link
                  label={intl.formatMessage({ id: 'sds.sds-title' })}
                  icon="pi pi-download"
                  onClick={() => window.open(`/rapi/chemicals/sds/${riskAssessment.sds ? riskAssessment.sds.id : selectedSds.id}/download`)}
                />
              </div>
            </HasCapability>
          </Download>
          <Panel lighter>
            <h4>
              <FormattedMessage id="riskassessment.preview.protection" />
            </h4>
            <ProtectiveGearDisplay value={riskAssessment.protection} />
            <h4>
              <FormattedMessage id="riskassessment.preview.description" />
            </h4>
            <RichTextDisplay value={riskAssessment.work_methods} />
            <h4>
              <FormattedMessage id="riskassessment.environment" />
            </h4>
            {riskAssessment.environment}
            <h4>
              <FormattedMessage id="riskassessment.risk_factors" />
            </h4>
            {riskAssessment.risk_factors}
            <h4>
              <FormattedMessage id="riskassessment.usage_frequency" />
            </h4>
            {renderUsageFrequency()}
            <h4>
              <FormattedMessage id="riskassessment.usage_duration" />
            </h4>
            {renderUsage()}
            <h4>
              <FormattedMessage id="riskassessment.contact" />
            </h4>
            {renderContact()}
            {riskAssessment.form && (
              <>
                <h4>
                  <FormattedMessage id="riskassessment.form" />
                </h4>
                <FormattedMessage id={'riskassessment.form_options.' + riskAssessment.form} />
              </>
            )}
            <h4>
              <FormattedMessage id="riskassessment.yearly_consumption" />
            </h4>
            {riskAssessment.yearly_consumption}
          </Panel>

          <Panel lighter>
            {riskAssessment.location && (
              <>
                <h4>
                  <FormattedMessage id="common.location" />
                </h4>
                {riskAssessment.location.name}
              </>
            )}
            <h4>
              <FormattedMessage id="riskassessment.preview.applies_to" />
            </h4>
            {riskAssessment.applies_to}
            <h4>
              <FormattedMessage id="riskassessment.preview.compiled_by" />
            </h4>
            {riskAssessment.participants}
          </Panel>

          {riskAssessment.air_pollution && riskAssessment.air_pollution.length > 0 && (
            <Panel lighter>
              <h4>
                <FormattedMessage id="riskassessment.air_pollution" />
              </h4>
              <AirPollutionDisplay airPollution={riskAssessment.air_pollution} />
            </Panel>
          )}

          {(riskAssessment.waste_code ||
            riskAssessment.waste_comment ||
            riskAssessment.waste_dangerous ||
            riskAssessment.waste_disposal) && (
            <Panel lighter>
              {riskAssessment.waste_code && (
                <>
                  <h4>
                    <FormattedMessage id="riskassessment.waste_code" />
                  </h4>
                  {riskAssessment.waste_code.join(', ')}
                </>
              )}
              {riskAssessment.waste_comment && (
                <>
                  <h4>
                    <FormattedMessage id="common.comment" />
                  </h4>
                  <RichTextDisplay value={riskAssessment.waste_comment} style={{ wordBreak: 'break-word' }} />
                </>
              )}
              <h4>
                <FormattedMessage id="riskassessment.waste_dangerous" />
              </h4>
              {riskAssessment.waste_dangerous ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />}
              {riskAssessment.waste_disposal && (
                <>
                  <h4>
                    <FormattedMessage id="riskassessment.waste_disposal_description" />
                  </h4>
                  <RichTextDisplay value={riskAssessment.waste_disposal} />
                </>
              )}
            </Panel>
          )}

          <Panel lighter>
            <ActionPlan riskAssessment={riskAssessment} />
          </Panel>

          <Panel lighter>
            <h4>
              <FormattedMessage id="riskassessment.preview.education" />
            </h4>
            {renderEducation()}
            {riskAssessment.comment && (
              <h4>
                <h4>
                  <FormattedMessage id="riskassessment.preview.comment" />
                </h4>
                <RichTextDisplay value={riskAssessment.comment} style={{ wordBreak: 'break-word' }} />
              </h4>
            )}
            <h4>
              <FormattedMessage id="riskassessment.approved_by_name" />
            </h4>
            <RichTextDisplay value={riskAssessment.approved_by_name} />
            <br />
            <RichTextDisplay value={riskAssessment.approved_by_role} />
            <h4>
              <FormattedMessage id="riskassessment.created_updated" />
            </h4>
            {format(riskAssessment.updated_at, 'YYYY-MM-DD')} <FormattedMessage id="common.by" /> {riskAssessment.created_by}
          </Panel>
        </>
      )}
    </Wrapper>
  )
}

export default injectIntl(RiskAssessmentPreview)

const ActionPlan = ({ riskAssessment }) => (
  <>
    <h4>
      <FormattedMessage id="riskassessment.action_plan_required" />
    </h4>
    {riskAssessment.action_plan_required ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />}
    {riskAssessment.action_plan_required && (
      <>
        <h4>
          <FormattedMessage id="riskassessment.action_plan_description" />
        </h4>
        <RichTextDisplay value={riskAssessment.action_plan_description} />
        <h4>
          <FormattedMessage id="riskassessment.action_plan_date" />
        </h4>
        {format(riskAssessment.action_plan_date, 'YYYY-MM-DD')}
        <h4>
          <FormattedMessage id="riskassessment.action_plan_responsible_user" />
        </h4>
        {riskAssessment.action_plan_responsible_user?.name}
      </>
    )}
  </>
)
