import React, { useState } from 'react'
import { Field, FieldGroup, Form } from 'Components/form'
import { TextInput } from 'Components/input'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { injectIntl } from 'react-intl'
import { PickList } from 'Components/combined/pickList.js'
import axios from 'axios'
import _ from 'lodash'

const EditFilterDialog = ({ modalController, onSuccess, intl, model }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (formData) => {
    setIsLoading(true)
    formData.filter_type = model.filter_type
    try {
      let result
      if (model.id !== undefined) {
        result = await axios.post('/rapi/admin/filter/' + model.id + '/edit', formData)
      } else {
        result = await axios.post('/rapi/admin/filter/add', formData)
      }
      onSuccess(result.data)
      setIsLoading(false)
      modalController.close()
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  const validateList = (value, formData) => {
    if (_.isEmpty(value)) {
      return true
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <FieldGroup>
        <Field id="label_sv" label={intl.formatMessage({ id: 'common.label_sv' })} Component={TextInput}
               initialValue={model.label_sv}/>
        <Field id="label_en" label={intl.formatMessage({ id: 'common.label_en' })} Component={TextInput}
               initialValue={model.label_en}/>
        <Field id="label_no" label={intl.formatMessage({ id: 'common.label_no' })} Component={TextInput}
               initialValue={model.label_no}/>
        <Field id="label_dk" label={intl.formatMessage({ id: 'common.label_dk' })} Component={TextInput}
               initialValue={model.label_dk}/>
        <Field
          id="hazard_statements"
          label={intl.formatMessage({ id: model.listLabel })}
          Component={PickList}
          uppercaseOnly={false}
          list={model.list}
          isLoading={model.listLoading}
          initialValue={model.value}
          validation={validateList}
        />
      </FieldGroup>
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Form>
  )
}

export default injectIntl(EditFilterDialog)
