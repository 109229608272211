import * as React from 'react'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { addMonths, compareAsc, format, parse, startOfMonth } from 'date-fns'
import { useIntl } from 'react-intl'

import { AuthContext } from 'Context'
import { MonthRangePicker, SearchInput } from 'Components/input'
import { Badge, Button, checkCircleBody, Column, DataTable, Modal, ToolPanel } from 'Components/readonly'
import { FlexRowCenterResponsive, Section, Spacer } from 'Components/layout'
import { preserveNewlinesColumnBody } from 'Utils/newLines'
import { globalSearch } from 'Utils/search'
import useCrudData from 'Hooks/useCrudData'
import SdsStatus from 'Pages/sds/components/sdsStatus'
import useModalController from 'Hooks/useModalController'
import EditFilterModalContent from './editFilterModalContent'
import { SplitToggleButtonWithModal } from './components/splitToggleButtonWithModal'
import modalContentInfo from 'Components/combined/modalContentComponents/modalContentInfo'
import RevisionDetail from 'Pages/revisionMaintenance/revisionDetail'

const RevisionSubscriptionUpcomingTable = ({ admin, isLoading, sds, readSds, updateSds, deleteSds }) => {
  const intl = useIntl()
  const { dateLocaleDateFns } = useContext(AuthContext)
  const [globalFilter, setGlobalFilter] = useState('')
  const minFromDate = startOfMonth(new Date())
  const [fromDate, setFromDate] = useState(minFromDate)
  const [toDate, setToDate] = useState(addMonths(minFromDate, 12))
  const [showDetailId, setShowDetailId] = useState(null)
  const dataTableRef = useRef()
  const [first, setFirst] = useState(0)

  const [editFilterModalController] = useModalController({ title: intl.formatMessage({ id: 'sds.revision-subscription.create-filter' }) })

  const { organisation } = React.useContext(AuthContext)
  const [isLoadingFilters, filters, createFilter, , updateFilter] = useCrudData(
    '/rapi/revisionsubscription/list',
    [],
    [organisation],
    (filters) =>
      filters.map((filter) => ({
        ...filter,
        applied_from: parse(filter.applied_from),
        revise_by: parse(filter.revise_by),
        binding_from: parse(filter.binding_from)
      }))
  )
  const [filterIdsChecked, setFilterIdsChecked] = useState({})

  const handleExport = () => {
    window.open('/rapi/admin/revisionsubscription/export')
  }

  const revisionStatusBody = (row, column) => {
    if (row.revisionAction) {
      if (row.revisionAction.action === 'disable') return <Badge fill faded
                                                                 label={intl.formatMessage({ id: 'sds.cancel-revision' })}/>
      if (row.revisionAction.action === 'revision')
        return <Badge fill accent label={intl.formatMessage({ id: 'sds.revised-with-marks' })}/>
    }
    return <Badge fill label={intl.formatMessage({ id: 'sds.revise' })}/>
  }

  const showDetailSds = readSds(showDetailId)

  const filtersChecked = useMemo(
    () =>
      Object.values(filterIdsChecked).filter((v) => v).length === 0
        ? filters
        : filters.filter((filter) => filterIdsChecked[filter.id] === true),
    [filterIdsChecked, filters]
  )

  const filtersCheckedAndMatchingDateperiod = useMemo(
    () => filtersChecked.filter((filter) => (!fromDate || filter.revise_by >= fromDate) && (!toDate || filter.applied_from < toDate)),
    [filtersChecked, fromDate, toDate]
  )

  const getReviseBy = useCallback(
    (sds) => {
      const matchingFilters = filters
        .filter((filter) => filter.sds_ids.includes(sds.id))
        .map((filter) => filter.revise_by)
        .sort(compareAsc)
      return matchingFilters.length === 0 ? null : matchingFilters[0]
    },
    [filters]
  )

  /* Calculates revisionperiod with selected filters, if several filters match select the earliest period */
  const getRevisionPeriod = useCallback(
    (sds) => {
      const matchingFilters = filtersCheckedAndMatchingDateperiod
        .filter((filter) => filter.sds_ids.includes(sds.id))
        .sort((a, b) => compareAsc(a.applied_from, b.applied_from))
      return matchingFilters.length === 0 ? null : matchingFilters[0]
    },
    [filtersCheckedAndMatchingDateperiod]
  )

  const filteredSds = useMemo(() => {
    const searchablePropsList = ['productName', 'countryName', 'langName']
    if (admin) {
      searchablePropsList.unshift('organisation.name')
    }
    let filteredList = globalSearch([...sds], globalFilter, searchablePropsList)
    return filteredList.filter((sds) => {
      const revisionPeriod = getRevisionPeriod(sds)
      if (!revisionPeriod) {
        return false
      }
      return true
    }).map((sds) => ({
      ...sds,
      // Has external labeling or internal
      calcHasLabeling: (sds.labeling && sds.labeling.length > 0) || sds.labelActive
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sds, globalFilter, getRevisionPeriod])

  const onFromDateChange = (value) => {
    if (value) {
      setFromDate(value)
    }
  }

  const onToDateChange = (value) => {
    if (value) {
      setToDate(value)
    }
  }

  const renderRevisionDatePeriod = (applied_from, revise_by) => {
    return (
      format(applied_from, 'MMM YYYY', { locale: dateLocaleDateFns }) + ' - ' + format(revise_by, 'MMM YYYY', { locale: dateLocaleDateFns })
    )
  }

  const revisionPeriodBody = (row) => {
    const revisionPeriod = getRevisionPeriod(row)
    return renderRevisionDatePeriod(revisionPeriod.applied_from, revisionPeriod.revise_by)
  }

  const columns = [
    <Column
      field="status"
      header={intl.formatMessage({ id: 'common.status' })}
      body={(row) => <SdsStatus sds={row}/>}
      sortable={true}
      style={{ width: '1%' }}
    />,
    <Column
      field="productName"
      header={intl.formatMessage({ id: 'chemicals.product' })}
      body={preserveNewlinesColumnBody}
      sortable={true}
    />,
    <Column field="id" header={intl.formatMessage({ id: 'sds.id' })} sortable={true} style={{ width: '1%' }}/>,
    <Column header={intl.formatMessage({ id: 'sds.revision-period' })} sortable={true} body={revisionPeriodBody}
            style={{ width: '1%' }}/>,
    <Column
      field="revisionAction.action"
      header={intl.formatMessage({ id: 'sds.revision-subscription.revision-status' })}
      sortable={true}
      body={revisionStatusBody}
      style={{ width: '1%' }}
    />,
    <Column field="countryName" header={intl.formatMessage({ id: 'common.country' })} sortable={true}
            style={{ width: '1%' }}/>,
    <Column field="langName" header={intl.formatMessage({ id: 'common.language' })} sortable={true}
            style={{ width: '1%' }}/>,
    <Column
      field="calcHasLabeling"
      body={checkCircleBody}
      header={intl.formatMessage({ id: 'labeling.product-labeling' })}
      sortable={true}
      style={{ width: '1%', textAlign: 'center' }}
    />
  ]

  if (admin) {
    columns.unshift(<Column field="organisation.name" header={intl.formatMessage({ id: 'admin.customer' })}
                            sortable={true}/>)
  }

  const [filterInfoModalController] = useModalController()

  const filterModalContent = (filter) => ({
    title: filter.name,
    data: [
      intl.formatMessage({ id: 'sds.revision-subscription.applied-from' }) + ': ' + format(filter.applied_from, 'YYYY-MM-DD'),
      intl.formatMessage({ id: 'sds.revision-subscription.binding-from' }) + ': ' + format(filter.binding_from, 'YYYY-MM-DD'),
      intl.formatMessage({ id: 'sds.revision-subscription.revise-by' }) + ': ' + format(filter.revise_by, 'YYYY-MM-DD'),
      filter.description
    ]
  })

  return (
    <>
      <Modal
        modalController={editFilterModalController}
        ContentComponent={EditFilterModalContent}
        onCreate={createFilter}
        onUpdate={updateFilter}
      />
      <Modal modalController={filterInfoModalController} ContentComponent={modalContentInfo}/>

      <Section>
        <ToolPanel>
          {filters.map((filter) => (
            <SplitToggleButtonWithModal
              onLabel={filter.name}
              offLabel={filter.name}
              checked={filterIdsChecked[filter.id]}
              icon={admin ? 'pi pi-pencil' : 'pi pi-info'}
              onClickRight={() =>
                admin ? editFilterModalController.open({ data: filter }) : filterInfoModalController.open(filterModalContent(filter))
              }
              onChange={(e) => setFilterIdsChecked((oldValue) => ({ ...oldValue, [filter.id]: e.value }))}
            />
          ))}
          {admin && (
            <Button
              primary
              icon="pi pi-plus"
              label={intl.formatMessage({ id: 'sds.revision-subscription.create-filter' })}
              onClick={editFilterModalController.open}
            />
          )}
        </ToolPanel>
      </Section>
      <ToolPanel>
        <SearchInput
          value={globalFilter}
          onChange={(event) => {
            setGlobalFilter(event.target.value)
            setFirst(0)
          }}
        />
        <Spacer/>
        <FlexRowCenterResponsive>
          <MonthRangePicker
            toDate={toDate}
            fromDate={fromDate}
            minFromDate={minFromDate}
            onFromDateChange={onFromDateChange}
            onToDateChange={onToDateChange}
          />
        </FlexRowCenterResponsive>
        {admin &&
          <Button icon="pi pi-file-excel" label={intl.formatMessage({ id: 'common.export' })} onClick={handleExport}/>}
      </ToolPanel>
      <DataTable
        ref={dataTableRef}
        className={'flextable ' + (!admin ? 'clickable-rows' : '')}
        value={filteredSds}
        paginator={true}
        rows={10}
        first={first}
        onPage={(e) => setFirst(e.first)}
        loading={isLoading || isLoadingFilters}
        onRowClick={(event) => !admin && setShowDetailId(event.data.id)}
        responsive={true}>
        {columns}
      </DataTable>
      <RevisionDetail
        sds={showDetailSds}
        reviseBy={showDetailSds && getReviseBy(showDetailSds)}
        kind="internal"
        isOpen={showDetailSds != null}
        close={() => {
          setShowDetailId(null)
        }}
        updateSds={updateSds}
        deleteSds={deleteSds}
      />
    </>
  )
}

export default RevisionSubscriptionUpcomingTable
