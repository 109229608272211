import React from 'react'
import { injectIntl } from 'react-intl'
import { Button } from 'Components/input'
import styled from 'styled-components'
import { DataTable, Modal, Panel } from 'Components/readonly'
import AddLocationDialog from '../dialogs/addLocationDialog'
import DeleteProductFromLocationDialog from '../dialogs/deleteProductFromLocationDialog'
import MoveProductLocationDialog from '../dialogs/moveProductLocationDialog'
import EditProductForLocationDialog from '../dialogs/editProductForLocationDialog'

import useModalController from 'Hooks/useModalController'
import { getLocationDisplayName } from 'Utils/location'
import { HasCapability } from 'Components/capabilities'
import { Column } from 'primereact/column'

const Locations = styled.div`
  display: flex;
  flex-direction: column;

  ${Panel} {
    margin-bottom: 8px;
  }
`

const LocationHead = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;

  h2 {
    flex: 1 1 200px;
  }

  div {
    ${Button} {
      margin-left: 8px;
    }

    @media screen and (max-width: 767px) {
      ${Button} {
        width: 100%;
      }
    }
  }
`

const LocationPanel = ({ intl, product, setProduct }) => {
  const [addLocationModalCtrl] = useModalController({ title: intl.formatMessage({ id: 'chemicals.add_product_to_list' }) })

  const [deleteProductFromLocationModalCtrl] = useModalController({
    title: intl.formatMessage({ id: 'chemicals.remove_all_from_location' })
  })
  const [moveAllLocationModalCtrl] = useModalController({ title: intl.formatMessage({ id: 'chemicals.move_all_from_location' }) })
  const [editProductForLocationModalCtrl] = useModalController({ title: intl.formatMessage({ id: 'common.edit' }) })

  const openAddLocationDialog = () => {
    addLocationModalCtrl.open({ data: product })
  }

  const updateProduct = (updatedProduct) => {
    if (updatedProduct) {
      setProduct(updatedProduct)
    }
  }

  const packageBody = (row, column) => {
    if (row.is_bulk) {
      return intl.formatMessage({ id: 'chemicals.bulk' }) + ', ' + row.amount + ' ' + row.unit.label
    } else {
      return row.packaging_size + ' ' + row.unit.label
    }
  }

  const amountBody = (row, column) => {
    if (row.is_bulk) {
      return '-'
    } else {
      return row[column.field]
    }
  }

  const removeAll = (location) => {
    deleteProductFromLocationModalCtrl.open({ data: { location: location.location, product } })
  }

  const moveAll = (location) => {
    moveAllLocationModalCtrl.open({ data: { location: location.location, product } })
  }
  const edit = (location) => {
    editProductForLocationModalCtrl.open({
      data: {
        location: location.location,
        product,
        inventory: location.inventory
      }
    })
  }

  return (
    <div>
      <Modal modalController={addLocationModalCtrl} ContentComponent={AddLocationDialog} onSuccess={updateProduct}/>
      <Modal
        modalController={deleteProductFromLocationModalCtrl}
        ContentComponent={DeleteProductFromLocationDialog}
        onSuccess={updateProduct}
      />
      <Modal modalController={moveAllLocationModalCtrl} ContentComponent={MoveProductLocationDialog}
             onSuccess={updateProduct}/>
      <Modal modalController={editProductForLocationModalCtrl} ContentComponent={EditProductForLocationDialog}
             onSuccess={updateProduct}/>
      <Locations>
        {product &&
          product.locations.map((location, index) => {
            return (
              <Panel key={index}>
                <LocationHead>
                  <h2 id={'locations-' + index}>{getLocationDisplayName(location.location)}</h2>
                  <div>
                    <HasCapability capability="CHEMICAL_REMOVE">
                      <Button
                        invert
                        label={intl.formatMessage({ id: 'chemicals.remove_all_from_location' })}
                        icon="pi pi-trash"
                        onClick={() => removeAll(location)}
                        disabled={product.locations.length === 1}
                      />
                    </HasCapability>
                    <HasCapability capability="CHEMICAL_ADD && CHEMICAL_REMOVE">
                      <Button
                        invert
                        label={intl.formatMessage({ id: 'chemicals.move_all_from_location' })}
                        icon="pi pi-reply"
                        onClick={() => moveAll(location)}
                      />
                    </HasCapability>
                    <HasCapability capability="CHEMICAL_ADD">
                      <Button
                        primary
                        label={intl.formatMessage({ id: 'common.edit' })}
                        icon="pi pi-pencil"
                        onClick={() => edit(location)}
                      />
                    </HasCapability>
                  </div>
                </LocationHead>
                <DataTable value={location.inventory} className="flextable" id={'inventory-' + index}>
                  <Column body={packageBody} header={intl.formatMessage({ id: 'chemicals.package' })}
                          style={{ width: '40%' }}/>
                  <Column
                    field="amount_opened"
                    body={amountBody}
                    header={intl.formatMessage({ id: 'chemicals.opened' })}
                    style={{ width: '30%' }}
                  />
                  <Column
                    field="amount"
                    body={amountBody}
                    header={intl.formatMessage({ id: 'chemicals.unopened' })}
                    style={{ width: '30%' }}
                  />
                </DataTable>
              </Panel>
            )
          })}
      </Locations>
      <HasCapability capability="CHEMICAL_ADD">
        <Button
          icon="pi pi-plus-circle"
          primary
          label={intl.formatMessage({ id: 'chemicals.add_location' })}
          onClick={openAddLocationDialog}
        />
      </HasCapability>
    </div>
  )
}

export default injectIntl(LocationPanel)
