import React from 'react'
import styled from 'styled-components'

import { Panel } from 'Components/readonly'
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl'
import { Button } from './button'
import { Section } from 'Components/layout'

const Wrapper = styled.div`
  padding: 20px;
`

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Panel} {
    flex: 0 0 auto;
    width: 400px;
    padding: 20px;
    display: flex;

    i {
      flex: 0 0 40px;
    }

    div {
      flex: 1 1 auto;

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`

const PdfDisplayError = ({ pdfUrl }) => {
  const intl = useIntl()

  return (
    <Wrapper>
      <ErrorWrapper>
        <Panel>
          <div>
            <Section>
              <FormattedMessage tagName="div" id="common.pdf_display_error"/>
            </Section>
            {pdfUrl && (
              <div>
                <Button
                  icon="pi pi-download"
                  invert
                  onClick={() => (window.location.href = pdfUrl)}
                  label={intl.formatMessage({ id: 'common.download' })}
                />
              </div>
            )}
            <FormattedHTMLMessage tagName="div" id="common.pdf_display_error2"/>
          </div>
        </Panel>
      </ErrorWrapper>
    </Wrapper>
  )
}

export default PdfDisplayError
