import React from 'react'

import { AuthContext } from 'Context'

export const useCapability = (capability) => {
  const { user } = React.useContext(AuthContext)

  const capabilities = capability.split('&&')

  let anyCapabilityFound = true
  for (const capability of capabilities) {
    if (!user.capabilities.includes(capability.trim())) {
      anyCapabilityFound = false
      break
    }
  }

  return user.isSuperAdmin || anyCapabilityFound
}

export const HasCapability = ({ capability, yes = null, no = null, children = null }) => {
  return useCapability(capability) ? yes || children : no || null
}

export const useRole = (service, role) => {
  const { user } = React.useContext(AuthContext)

  return !role || user.user.roleValues[service] === role || user.isSuperAdmin
}

export const useService = (service, role) => {
  const { organisation } = React.useContext(AuthContext)

  return useRole(service, role) && organisation['has_' + service]
}

export const HasService = ({ service, serviceRole, yes = null, no = null, children = null }) => {
  return useService(service, serviceRole) ? yes || children : no || null
}
