import React from 'react'
import styled from 'styled-components'
import { TextInput } from '../input'
import { useIntl } from 'react-intl'

const Field = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > div {
    width: 150px;
    margin-right: 10px;

    > label {
      display: inline-block;
      margin-bottom: 2px;
    }
  }

  > span {
    margin-top: 17px;
    width: 100px;
  }
`

const EditProductAmountRow = ({ id, value, onChange }) => {
  const intl = useIntl()

  const setAmount = (input) => {
    let v = { ...value }
    v.amount = input
    onChange({ target: { value: v } })
  }

  const setOpened = (input) => {
    let v = { ...value }
    v.amount_opened = input
    onChange({ target: { value: v } })
  }

  return (
    <Field>
      {value.is_bulk ? (
        <>
          <span>{intl.formatMessage({ id: 'chemicals.bulk' })}</span>
          <div>
            <span>{intl.formatMessage({ id: 'chemicals.amount' })}</span>
            <TextInput
              keyfilter="pnum"
              type="number"
              value={value.amount}
              onChange={(e) => {
                setAmount(e.target.value)
              }}
            />
          </div>
          <span>{value.unit.label}</span>
        </>
      ) : (
        <>
          <span>{value.packaging_size + ' ' + value.unit.label}</span>
          <div>
            <label>{intl.formatMessage({ id: 'chemicals.opened' })}</label>
            <TextInput
              keyfilter="pnum"
              type="number"
              value={value.amount_opened}
              onChange={(e) => {
                setOpened(e.target.value)
              }}
            />
          </div>
          <div>
            <label>{intl.formatMessage({ id: 'chemicals.unopened' })}</label>
            <TextInput
              keyfilter="pnum"
              type="number"
              value={value.amount}
              onChange={(e) => {
                setAmount(e.target.value)
              }}
            />
          </div>
        </>
      )}
    </Field>
  )
}

export default EditProductAmountRow
