import React, { useContext, useState } from 'react'
import axios from 'axios'

import { GrowlContext } from 'Context'
import { Field, FieldGroup, Form } from 'Components/form'
import { Button, MultiSelect, RichTextArea, TextInput } from 'Components/input'
import { Section, Spacer } from 'Components/layout'
import { ModalActionsFooter } from 'Components/readonly'

import { useIntl } from 'react-intl'

const url = '/rapi/admin/faq'

const EditFaqContentDialog = ({ modalController, onUpdate, onCreate }) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)

  const faq = modalController.data
  const isNew = faq === null

  const moduleOptions = [
    { value: 'sds', label: intl.formatMessage({ id: 'sds.module' }) },
    { value: 'chemical_list', label: intl.formatMessage({ id: 'chemicals.module' }) }
  ]

  const organisationKindOptions = [
    { value: 'subscription', label: intl.formatMessage({ id: 'organisation.kind_subscription' }) },
    { value: 'piece', label: intl.formatMessage({ id: 'organisation.kind_piece' }) },
    { value: 'maintenance', label: intl.formatMessage({ id: 'organisation.kind_maintenance' }) }
  ]

  const onSubmit = async (contentData) => {
    setIsLoading(true)

    let result = null
    try {
      if (isNew) {
        result = await axios.post(url, contentData)
        onCreate(result.data.faq)
      } else {
        result = await axios.put(url + '/' + faq.id, contentData)
        onUpdate(result.data.faq)
      }
    } catch (error) {
      displayError(error)
      setIsLoading(false)
      return
    }

    displaySuccess(result.data.message)
    modalController.close()
    setIsLoading(false)
  }

  return (
    <Form onSubmit={onSubmit}>
      <Section>
        <FieldGroup>
          <Field
            id="modules"
            label={intl.formatMessage({ id: 'common.module' })}
            Component={MultiSelect}
            options={moduleOptions}
            initialValue={(faq && faq.modules) || []}
          />
          <Field
            id="organisation_kind"
            label={intl.formatMessage({ id: 'organisation.kind' })}
            Component={MultiSelect}
            options={organisationKindOptions}
            initialValue={(faq && faq.organisation_kind) || []}
          />
          <Field
            id="language_variants.sv.question"
            className="modal-onshow-focus"
            label={intl.formatMessage({ id: 'admin.faq.question_sv' })}
            Component={TextInput}
            rows="3"
            maxLength="115"
            required={true}
            initialValue={(faq && faq.language_variants && faq.language_variants.sv && faq.language_variants.sv.question) || ''}
          />
          <Field
            id="language_variants.sv.answer"
            label={intl.formatMessage({ id: 'admin.faq.answer_sv' })}
            Component={RichTextArea}
            maxLength="115"
            rows="3"
            required={true}
            initialValue={(faq && faq.language_variants && faq.language_variants.sv && faq.language_variants.sv.answer) || ''}
          />
          <Field
            id="language_variants.en.question"
            label={intl.formatMessage({ id: 'admin.faq.question_en' })}
            Component={TextInput}
            rows="3"
            maxLength="115"
            required={true}
            initialValue={(faq && faq.language_variants && faq.language_variants.en && faq.language_variants.en.question) || ''}
          />
          <Field
            id="language_variants.en.answer"
            label={intl.formatMessage({ id: 'admin.faq.answer_en' })}
            Component={RichTextArea}
            maxLength="115"
            rows="3"
            required={true}
            initialValue={(faq && faq.language_variants && faq.language_variants.en && faq.language_variants.en.answer) || ''}
          />
          <Field
            id="language_variants.no.question"
            label={intl.formatMessage({ id: 'admin.faq.question_no' })}
            Component={TextInput}
            rows="3"
            maxLength="115"
            required={true}
            initialValue={(faq && faq.language_variants && faq.language_variants.no && faq.language_variants.no.question) || ''}
          />
          <Field
            id="language_variants.no.answer"
            label={intl.formatMessage({ id: 'admin.faq.answer_no' })}
            Component={RichTextArea}
            maxLength="115"
            rows="3"
            required={true}
            initialValue={(faq && faq.language_variants && faq.language_variants.no && faq.language_variants.no.answer) || ''}
          />
          <Field
            id="language_variants.dk.question"
            label={intl.formatMessage({ id: 'admin.faq.question_dk' })}
            Component={TextInput}
            rows="3"
            maxLength="115"
            required={true}
            initialValue={(faq && faq.language_variants && faq.language_variants.dk && faq.language_variants.dk.question) || ''}
          />
          <Field
            id="language_variants.dk.answer"
            label={intl.formatMessage({ id: 'admin.faq.answer_dk' })}
            Component={RichTextArea}
            maxLength="115"
            rows="3"
            required={true}
            initialValue={(faq && faq.language_variants && faq.language_variants.dk && faq.language_variants.dk.answer) || ''}
          />
        </FieldGroup>
      </Section>
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Form>
  )
}

export default EditFaqContentDialog
