import styled from 'styled-components'

export const QrPageConstraint = styled.div`

  padding: 2rem 3.75rem 6.75rem 3.75rem;
  overflow: auto;
  height: 100vh;

  && {
    #logo {
      margin: 0;
    }

    .wrapper {
      max-width: 1512px;
      margin: auto;
    }

    article.wrapper {
      background-color: #323240;
    }

    .header {
      padding: 1.875rem;
    }

    .flex {
      display: flex;
    }

    .align-items-center {
      align-items: center;
    }

    .header h1 {
      font-size: 2.375rem;
      line-height: 1.2em;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    .tab-nav {
      overflow: hidden;
      border-radius: 50px;
      display: flex;
      margin-left: auto;
    }

    .tab-nav a {
      padding: 13px 20px;
      font-size: 0.938rem;
      cursor: pointer;
      text-decoration: none;
      background-color: #1D2134;
    }

    .tab-nav a.active {
      background-color: #3BB377;
    }
  }

  // Add a media query for max-width
  @media (max-width: 768px) {
    padding: 0 0 2rem 0;

    && {
      .header h1 {
        font-size: 1.5rem;
        margin-bottom: 1rem !important;
      }

      .header.flex {
        flex-direction: column;
      }

      .tab-nav {
        margin: 1rem 0 0 0;
      }

      .tab-nav a {
        font-size: 0.875rem; // Adjust font size for smaller screens
        padding: 10px 15px; // Adjust padding for smaller screens
      }

    }
  }
`;
