import axios from 'axios'
import { GrowlContext } from 'Context'
import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { DemoPanel, StartPageButton } from './startPageStyle'

const Message = styled.div`
  height: 37.72px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const RequestModuleDemo = ({ module }) => {
  const intl = useIntl()
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(false)

  const requestDemo = async () => {
    setIsLoading(true)
    try {
      const result = await axios.post('/rapi/start/requestDemo/' + module)
      displaySuccess(result.data.message)
      setMessage(result.data.message)
    } catch (e) {
      displayError(e)
    }
    setIsLoading(false)
  }

  return (
    <DemoPanel>
      <h3>
        <FormattedMessage id="welcome.request_demo_heading"
                          values={{ module: intl.formatMessage({ id: 'chemicals.module' }) }}/>
      </h3>
      {message ? (
        <Message>{message}</Message>
      ) : (
        <StartPageButton
          label={intl.formatMessage({ id: 'welcome.request_demo_button' })}
          icon={'pi ' + (isLoading ? 'pi-spinner pi-spin' : 'pi-clock')}
          iconPos="right"
          disabled={isLoading}
          onClick={requestDemo}
        />
      )}
    </DemoPanel>
  )
}

export default RequestModuleDemo
