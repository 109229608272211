import React, { useContext, useEffect, useMemo, useState } from 'react'
import { hot } from 'react-hot-loader'
import { Route, Switch, useLocation } from 'react-router-dom'
import axios from 'axios'
import qs from 'qs'
import { IntlProvider } from 'react-intl'

import LoginPage from 'Pages/login/loginPage'
import SessionEndedPage from 'Pages/sessionEndedPage'
import LoggedOutPage from 'Pages/loggedOutPage'
import MaintenancePage from 'Pages/maintenancePage'
import PublicSdsListPage from 'Pages/publicSdsList'
import QrCodesPage from 'Pages/userArea/QrCodesPage'
import ErrorPage from 'Pages/error'
import AppLayout from 'Pages/appLayout'
import TestPanel from 'Components/readonly/testPanel'
import { AuthContext, GrowlContext } from 'Context'
import AxiosInteceptor from 'Components/axiosInterceptor'
import ComponentShowcase from 'Pages/dev/componentShowcase'
import flattenMessages from 'Utils/flattenMessages'
import ApproveUserPage from 'Pages/approveUserPage'
import ChartJs from 'chart.js'

ChartJs.pluginService.register({
  afterDraw: function (chart) {
    var centerConfig = chart.config.options.elements.center
    if (centerConfig && typeof centerConfig.afterDraw === 'function') {
      centerConfig.afterDraw(chart)
    }
  },
  beforeDraw: function (chart) {
    var centerConfig = chart.config.options.elements.center
    if (centerConfig && typeof centerConfig.beforeDraw === 'function') {
      centerConfig.beforeDraw(chart)
    }
  }
})

const App = () => {
  const { isLoading, user } = useContext(AuthContext)
  const [messages, setMessages] = useState()
  const [error, setError] = useState(false)
  const {
    setServerErrorText,
    translationsLoaded,
    setTranslationsLoaded,
    translations,
    setTranslations
  } = useContext(GrowlContext)

  const location = useLocation()

  // Pick default locale from the browser
  const locale = useMemo(
    () =>
      // Language is sent as a query param on sds iframe page (publicSdsListPage). This setting should have priority over all others.
      (
        qs.parse(location.search, { ignoreQueryPrefix: true }).language ||
        (user && user.user && user.user.language) ||
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage ||
        'en-US'
      ).substring(0, 2), // pick out the language part ie 'sv'
    [location.search, user]
  )

  document.documentElement.lang = locale

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        let result;
        if (translationsLoaded) {
          result = translations
        } else {
          result = await axios.get('/rapi/translations')
          setTranslations(result)
          setTranslationsLoaded(true)
        }

        const messages = result.data[locale] || result.data['en']
        setMessages(flattenMessages(messages))

        // The GrowlProvider can not access IntlProvider. Giving the GrowlProvider translated strings here.
        setServerErrorText({
          title: messages.error.server_error_title,
          message: messages.error.server_error_message
        })
      } catch (error) {
        setError(error.response ? error.response.status : true)
      }
    }
    if (!isLoading) {
      loadTranslations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessages, setServerErrorText, locale, isLoading])

  if (error) {
    console.log('error', error)
    return <MaintenancePage locale={locale} error={error}/>
  }

  if (isLoading || !messages) {
    return <div>Loading...</div>
  }

  return (
    <IntlProvider key={locale} locale={locale} messages={messages}>
      <AxiosInteceptor/>
      {process.env.NODE_ENV !== 'production' && <TestPanel/>}
      <Switch>
        <Route path={'/componentShowcase'} component={ComponentShowcase}/>
        <Route path="/error" component={ErrorPage}/>
        <Route path="/user-area/qr/:uuid" component={QrCodesPage}/>
        <Route path="/wl/:token/iframe" component={PublicSdsListPage}/>
        <Route path="/sessionEnded" component={SessionEndedPage}/>
        <Route path="/loggedOut" component={LoggedOutPage}/>
        <Route path="/maintenance" render={(props) => <MaintenancePage {...props} locale={locale} error={503}/>}/>
        <Route path="/approveuser/:userid/:token" component={ApproveUserPage}/>
        <Route component={user.isAuthenticated ? AppLayout : LoginPage}/>
      </Switch>
    </IntlProvider>
  )
}

export default hot(module)(App)
