import { HasCapability } from 'Components/capabilities'
import colors from 'Components/colors.scss'
import { Button } from 'Components/input'
import { FlexColumn, FlexColumnCenter, SmallText, Spacer } from 'Components/layout'
import { Badge } from 'Components/readonly'
import { format, parse } from 'date-fns'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { getLocationDisplayName } from 'Utils/location'
import { EmptyRiskAssessment } from './riskAssessmentForm'
import { riskLevelColorLookupTable } from './riskPageSupport'
import { ActionBar, MainFlexRow, ProductTitle, RiskRowWrapper, SubContainer, SubRow } from './riskRowStyle'
import RiskStatusBadgeList from './riskStatusBadgeList'
import SafetySheetLink from './SafetySheetLink'

const RiskRow = ({
                   row,
                   toggleState,
                   toggleView,
                   setSelectedProduct,
                   setSelectedRiskAssessment,
                   setRiskSidebarVisible,
                   setEditSidebarVisible,
                   onArchiveRiskAssessment,
                   history,
                   intl
                 }) => {
  const newRiskAssessment = (event) => {
    setSelectedRiskAssessment(EmptyRiskAssessment)
    setSelectedProduct(row)
    setEditSidebarVisible(true)
    event.stopPropagation()
  }

  const editProduct = (event, product, riskAssessment) => {
    setSelectedRiskAssessment(riskAssessment.original)
    setSelectedProduct(product)
    setEditSidebarVisible(true)
    event.stopPropagation()
  }

  const showRiskAssessment = (event, product, riskAssessment) => {
    setSelectedRiskAssessment(riskAssessment.original)
    setSelectedProduct(product)
    setRiskSidebarVisible(true)
    event.stopPropagation()
  }

  const archiveRiskAssessment = (event, row, riskAssessment) => {
    setSelectedProduct(row)
    onArchiveRiskAssessment(riskAssessment)
    event.stopPropagation()
  }

  const formatDate = (date) => {
    try {
      return format(parse(date), 'YYYY-MM-DD')
    } catch (e) {
    }
  }

  return (
    <RiskRowWrapper style={{ cursor: row.riskAssessments.length > 0 ? 'pointer' : 'auto' }}>
      <MainFlexRow>
        <div>
          <ProductTitle>
            <span
              className="link"
              onClick={() => {
                history.push('/product/' + row.id)
              }}>
              {row.name}
            </span>
          </ProductTitle>
          <div style={{ maxWidth: '75%' }}>
            <span>
              <FormattedMessage id="common.locations"/>:{' '}
            </span>
            {row.locations &&
              row.locations.map((location, index) => {
                return getLocationDisplayName(location.location) + (index !== row.locations.length - 1 ? '; ' : '')
              })}
          </div>
          <div className="md-hide">
            <RiskStatusBadgeList row={row}/>
          </div>
        </div>
        <Spacer/>
        {!toggleState[row.id] && (
          <div className="md-show badge-container">
            <RiskStatusBadgeList row={row}/>
          </div>
        )}
        <ActionBar>
          <HasCapability capability="CHEMICAL_MANAGE_RISK_ASSESSMENTS">
            <Button
              className="md-show"
              link
              primary
              icon="pi pi-plus"
              onClick={newRiskAssessment}
              label={intl.formatMessage({ id: 'chemicals.new_risk_assessment' })}
            />
            <Button className="md-hide" link primary icon="pi pi-plus" onClick={newRiskAssessment}/>
          </HasCapability>
          <div style={{ visibility: row.riskAssessments.length > 0 ? 'visible' : 'hidden' }}>
            {toggleState[row.id] ? (
              <Button icon="pi pi-chevron-up" onClick={() => toggleView(row.id)}/>
            ) : (
              <Button icon="pi pi-chevron-down" onClick={() => toggleView(row.id)}/>
            )}
          </div>
        </ActionBar>
      </MainFlexRow>
      {toggleState[row.id] && (
        <SubContainer>
          {row.riskAssessments.map((riskAssessment, index) => (
            <SubRow key={index}>
              <FlexColumn>
                <SmallText style={{ paddingBottom: '10px' }}>
                  <FormattedMessage id="riskassessment.riskassessment"/>
                </SmallText>
                <div>{riskAssessment.name}</div>
              </FlexColumn>
              <Spacer/>
              <FlexColumnCenter>
                {(riskAssessment.status === 'ACTIVE' && (
                  <Badge
                    className="riskStatus"
                    faded
                    dotColor={riskLevelColorLookupTable[riskAssessment.riskValue]}
                    textColor={colors.lightestBackground}
                    label={intl.formatMessage({ id: 'riskassessment.risk_level.' + riskAssessment.risk })}
                  />
                )) || (
                  <Badge
                    className="riskStatus"
                    label={intl.formatMessage({ id: 'common.draft' })}
                    textColor={colors.lightBackground}
                    color={colors.lightestBackground}
                  />
                )}
              </FlexColumnCenter>
              <FlexColumnCenter>{formatDate(riskAssessment.created_at)}</FlexColumnCenter>
              <FlexColumnCenter>
                <SafetySheetLink riskAssessment={riskAssessment.original}/>
              </FlexColumnCenter>
              {/*IE FLEX FIX */}
              <ActionBar style={{ flex: 'none !important' }}>
                <HasCapability capability="CHEMICAL_MANAGE_RISK_ASSESSMENTS">
                  <Button
                    icon="pi pi-folder"
                    label={intl.formatMessage({ id: 'common.archive' })}
                    onClick={(event) => archiveRiskAssessment(event, row, riskAssessment)}
                  />
                  <Button
                    icon="pi pi-pencil"
                    label={intl.formatMessage({ id: 'common.edit' })}
                    onClick={(event) => editProduct(event, row, riskAssessment)}
                  />
                </HasCapability>
                <Button
                  primary
                  icon="pi pi-arrow-circle-right"
                  label={intl.formatMessage({ id: 'common.show' })}
                  onClick={(event) => showRiskAssessment(event, row, riskAssessment)}
                />
              </ActionBar>
            </SubRow>
          ))}
        </SubContainer>
      )}
    </RiskRowWrapper>
  )
}

export default withRouter(injectIntl(RiskRow))
