import axios from 'axios'
import colors from 'Components/colors.scss'
import { LongerSection, Section, Spacer } from 'Components/layout'
import {
  arrayCommaBody,
  Button,
  Column,
  DataTable,
  Expander,
  ExpanderTab,
  InfoPanel,
  LoadingSpinner,
  ToolPanel
} from 'Components/readonly'
import { AuthContext, GrowlContext } from 'Context'
import { format } from 'date-fns'
import useGetData from 'Hooks/useGetData'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

const PreviewWrapper = styled.div`
  .exists {
    color: ${colors.white} !important;
  }

  .import {
    color: ${colors.primary} !important;
  }

  .missing {
    color: ${colors.validationFailedFieldMarker} !important;
  }
`

export default ({ data, closePreview }) => {
  const intl = useIntl()
  const { displaySuccess, displayError } = useContext(GrowlContext)
  const { user, organisation } = useContext(AuthContext)

  const [progress, setProgress] = useState({})
  const [isLocationsLoading, locations] = useGetData('/rapi/locations/flat', [], [user, organisation])
  const [isTagsLoading, tags] = useGetData('/rapi/tags', [], [user, organisation])
  //  const [isInventoryLoading, inventory] = useGetData('/rapi/chemicals/organisation/import/listinventory', [], [user, organisation])

  const [isImporting, setIsImporting] = useState(false)

  const isLoading = isLocationsLoading || isTagsLoading

  const locationsToImport = data.locations.filter((location) => locations.find((l) => l.name === location) === undefined)
  const tagsToImport = data.tags.filter((tag) => tags.find((t) => t.name === tag) === undefined)

  const isProductToBeImported = useCallback((product) => product.id || data.importSds[product.productIndex].length > 0, [data])

  const productsToImport = data.products
    // Remove products with no name matching in product DB and no sds selected for import
    .filter((product, index) => isProductToBeImported(product))

  // Existing products for counting
  const existingProductsToImport = data.products.filter((product, index) => product.id)

  // New products - Collect products without id
  const newProducts = data.products.filter((product, index) => !product.id && data.importSds[product.productIndex].length > 0)

  // Filter out unchecked products from table data
  const previewTableData = useMemo(() => {
    const filteredTableData = data.table.filter((tableEntry) => {
      const matchingProducts = data.products.filter((product) => product.name === tableEntry.name)
      if (matchingProducts.length > 0) {
        const product = matchingProducts[0]
        return isProductToBeImported(product)
      }

      return false
    })

    return filteredTableData
  }, [data, isProductToBeImported])

  const displayImportProduct = (product) => {
    return (
      <div key={product.name} className={newProducts.find((p) => p.name === product.name) ? 'import' : undefined}>
        {product.name}
      </div>
    )
  }

  const displayImportLocation = (location) => {
    return (
      <div key={location} className={locations.find((l) => l.name === location) ? 'exists' : 'import'}>
        {location}
      </div>
    )
  }

  const displayImportTag = (tag) => {
    return (
      <div key={tag} className={tags.find((t) => t.name === tag) ? 'exists' : 'import'}>
        {tag}
      </div>
    )
  }

  const productsHeader = (
    <>
      {existingProductsToImport.length + newProducts.length} <FormattedMessage id="common.total"/>
      {newProducts.length > 0 && (
        <>
          {' '}
          (
          <span className="import">
            <FormattedMessage id="common.x-new" values={{ new: newProducts.length }}/>
          </span>
          )
        </>
      )}
    </>
  )
  const locationsHeader = (
    <>
      {data.locations.length} <FormattedMessage id="common.total"/>
      {locationsToImport.length > 0 && (
        <>
          {' '}
          (
          <span className="import">
            {locationsToImport.length} <FormattedMessage id="common.new"/>
          </span>
          )
        </>
      )}
    </>
  )
  const tagsHeader = (
    <>
      {data.tags.length} <FormattedMessage id="common.total"/>
      {tagsToImport.length > 0 && (
        <>
          {' '}
          (
          <span className="import">
            {tagsToImport.length} <FormattedMessage id="common.new"/>
          </span>
          )
        </>
      )}
    </>
  )

  const doImport = async () => {
    setIsImporting(true)
    const incCurrentProgress = () => {
      setProgress((oldProgress) => ({ ...oldProgress, current: oldProgress.current + 1 }))
    }
    setProgress({
      total: productsToImport.length + 1,
      current: 0
    })
    try {
      var formData = new FormData()
      formData.append('locations', JSON.stringify(locationsToImport))
      formData.append('tags', JSON.stringify(tagsToImport))
      await axios.post('/rapi/chemicals/organisation/import', formData)
      incCurrentProgress()

      for (let index = 0; index < productsToImport.length; index++) {
        const product = productsToImport[index]

        formData = new FormData()

        const productImportSds = []
        let uploadIndex = 0
        // eslint-disable-next-line no-loop-func
        data.importSds[product.productIndex].forEach((importSds) => {
          if (!importSds.deleted) {
            productImportSds.push(uploadIndex++)
            formData.append('import_sds[]', importSds.file)
            formData.append(
              'import_sds[].extra',
              JSON.stringify({
                language: importSds.extra.language,
                sds_date: importSds.extra.date ? format(importSds.extra.date, 'YYYY-MM-DD') : null
              })
            )
          }
        })

        formData.append(
          'products',
          JSON.stringify([
            {
              ...product,
              import_sds_index: productImportSds
            }
          ])
        )

        await axios.post('/rapi/chemicals/organisation/import', formData)
        incCurrentProgress()
      }

      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms))
      }

      await sleep(500)
      await axios.post('/rapi/chemicals/organisation/sendImportNotification', { numProducts: productsToImport.length })
      displaySuccess(intl.formatMessage({ id: 'chemicals.import.success' }))
      setIsImporting(false)
      closePreview()
    } catch (error) {
      displayError(error)
      setIsImporting(false)
    }
  }

  return (
    <PreviewWrapper>
      <h2>
        <FormattedMessage id="chemicals.import.preview"/>
      </h2>
      <LoadingSpinner isLoading={isLoading}>
        <LongerSection style={{ maxWidth: '400px' }}>
          <h3>
            <FormattedMessage id="chemicals.products"/>
          </h3>
          <Expander>
            <ExpanderTab header={productsHeader}>{productsToImport.map(displayImportProduct)}</ExpanderTab>
          </Expander>
          <h3>
            <FormattedMessage id="common.locations"/>
          </h3>
          <Expander>
            <ExpanderTab header={locationsHeader}>{data.locations.map(displayImportLocation)}</ExpanderTab>
          </Expander>
          <h3>
            <FormattedMessage id="chemicals.tags"/>
          </h3>
          <Expander>
            <ExpanderTab header={tagsHeader}>{data.tags.map(displayImportTag)}</ExpanderTab>
          </Expander>
        </LongerSection>

        <Section>
          <InfoPanel messageid="chemicals.import.overwrite_warning"/>
        </Section>

        <Section>
          <DataTable
            className="flextable"
            value={previewTableData}
            paginator={true}
            rows={10}
            rowClassName={(row) => {
              const willImport = newProducts.find((p) => {
                return p.name === row.name
              })
              return {
                import: willImport
              }
            }}>
            <Column field="name" header={intl.formatMessage({ id: 'chemicals.product' })} sortable={true}/>
            <Column field="supplier" header={intl.formatMessage({ id: 'common.supplier' })} sortable={true}/>
            <Column field="location" header={intl.formatMessage({ id: 'common.location' })} sortable={true}/>
            <Column field="bulk" header={intl.formatMessage({ id: 'chemicals.bulk' })} sortable={true}/>
            <Column field="amount" header={intl.formatMessage({ id: 'chemicals.unopened' })} sortable={true}/>
            <Column field="amount_opened" header={intl.formatMessage({ id: 'chemicals.opened' })} sortable={true}/>
            <Column field="packaging_size" header={intl.formatMessage({ id: 'chemicals.size' })} sortable={true}/>
            <Column field="unit" header={intl.formatMessage({ id: 'chemicals.unit' })} sortable={true}/>
            <Column field="article_number" header={intl.formatMessage({ id: 'chemicals.article_id' })} sortable={true}/>
            <Column
              field="organisation_article_number"
              header={intl.formatMessage({ id: 'chemicals.organisation_article_number' })}
              sortable={true}
            />
            <Column field="organisation_synonym" header={intl.formatMessage({ id: 'chemicals.organisation_synonym' })}
                    sortable={true}/>
            <Column field="tags" body={arrayCommaBody} header={intl.formatMessage({ id: 'chemicals.tags' })}
                    sortable={true}/>
          </DataTable>
        </Section>
      </LoadingSpinner>
      <ToolPanel>
        <Spacer/>
        {!isImporting && <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={closePreview}/>}
        {isImporting && (
          <span>
            <FormattedMessage id="chemicals.import.wait"/>
            {progress.current} / {progress.total}
          </span>
        )}
        <Button primary label={intl.formatMessage({ id: 'chemicals.import.title' })} onClick={doImport}
                isLoading={isImporting}/>
      </ToolPanel>
    </PreviewWrapper>
  )
}
