import React, { useContext, useState } from 'react'
import axios from 'axios'
import sha1 from 'js-sha1'

import { Dialog } from 'primereact/dialog'

import styled from 'styled-components'

import { Field, FieldGroup, Form } from 'Components/form'
import { FileUpload, MarketLanguageDropdown, TextInput } from 'Components/input'
import { Button, InfoPanel, ModalActionsFooter } from 'Components/readonly'
import { AuthContext, GrowlContext } from 'Context'
import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import LocationsWithPackages, {
  emptyLocationRow,
  validateLocationsWithPackages
} from 'Components/combined/locationsWithPackages'
import Packages, { emptyPackageRow } from 'Components/combined/packages'
import { HasService } from 'Components/capabilities'
import _ from 'lodash'
import colors from 'Components/colors.scss'
import validateInventory from 'Utils/validateInventory'
import { FlexRowCenterHorizontal } from 'Components/layout'

const Foot = styled.div`
  display: flex;
  flex-direction: column;
`

const List = styled.div`
  background-color: ${colors.darkerBackgroundColor};
  padding: 0 8px;
  margin: 4px 0 8px;

  > div {
    display: flex;
    border-bottom: 1px solid ${colors.dividerGray};
    padding: 8px 0;

    &:last-of-type {
      border-bottom: none;
    }

    > div {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }

    > button {
      flex: 0 0 50px;
    }
  }
`

const NewProductDialog = ({
                            isOpen,
                            setIsOpen,
                            intl,
                            isShowLocationsAndPackages = false,
                            isShowPackages = false,
                            createChemical
                          }) => {
  const { organisation } = useContext(AuthContext)
  const { displaySuccess, displayError } = useContext(GrowlContext)
  const [isLoading, setIsLoading] = useState(false)
  const [duplicateProducts, setDuplicateProducts] = useState([])

  const [savedSendData, setSavedSendData] = useState(null)
  const [savedFormData, setSavedFormData] = useState(null)

  const getFileHash = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = async function (event) {
        const hash = sha1(reader.result)
        resolve(hash)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const onSubmit = async (sendData, formData) => {
    if (!isShowLocationsAndPackages) {
      sendData.append('locations', JSON.stringify([]))
      formData['locations'] = []
    }

    // Disable button and show spinner
    setIsLoading(true)

    // Clear duplicate products array
    setDuplicateProducts([])

    // Read files and calculate hashes
    const hashes = await Promise.all(
      formData['sds[]'].map(async (file) => {
        if (!file.deleted && file.file) {
          return await getFileHash(file.file)
        }
      })
    )

    // Send hashes to server and check for matches
    // If a match is found close this dialog and display duplicate product dialog
    try {
      const result = await axios.post('rapi/chemicals/hash', hashes)
      if (result.data.length > 0) {
        setDuplicateProducts(result.data)
        setSavedSendData(sendData)
        setSavedFormData(formData)
        setIsLoading(false)
        setIsOpen(false)
        return
      }
    } catch (error) {
      displayError(error)
      setIsLoading(false)
      return
    }

    // Send the data to server, creating a new chemical
    try {
      const result = await axios.post('/rapi/chemicals', sendData)
      if (isShowPackages) {
        createChemical(result.data.chemical, formData.packages)
        // No displaySuccess for inventorying register new product
      } else if (createChemical) {
        displaySuccess(result.data.message)
        if (createChemical) {
          createChemical(result.data.chemical)
        }
      }
      setIsOpen(false)
    } catch (error) {
      displayError(error)
    }

    setIsLoading(false)
  }

  const createDuplicateProduct = async () => {
    setIsLoading(true)
    // Send the data to server, creating a new chemical
    try {
      const result = await axios.post('/rapi/chemicals', savedSendData)
      setDuplicateProducts([])
      if (isShowPackages) {
        createChemical(result.data.chemical, savedFormData.packages)
      } else {
        displaySuccess(result.data.message)
        if (createChemical) {
          createChemical(result.data.chemical)
        }
      }
    } catch (error) {
      displayError(error)
    }

    setIsLoading(false)
  }

  const addExistingProduct = async (product) => {
    if (isShowPackages) {
      createChemical(product, savedFormData.packages)
      setDuplicateProducts([])
    } else {
      try {
        let formData = {}
        formData.product_id = product.id
        formData.locations = savedFormData.locations
        const result = await axios.post('/rapi/chemicals/add', formData)
        setDuplicateProducts([])
        displaySuccess(result.data.message)
      } catch (error) {
        displayError(error)
      }
    }
  }

  const validateFile = (value) => {
    if (value.some((file) => !file.deleted && file.file.type !== 'application/pdf')) {
      return { id: 'validation.upload_requires_pdf' }
    }

    if (
      value.filter((file) => {
        return !file.deleted && !file.extra
      }).length > 0
    ) {
      return { id: 'chemicals.language_required' }
    }

    // Detect language duplicates
    const languages = value.filter((file) => !file.deleted).map((file) => file.extra)
    if (languages.some((language, i) => languages.indexOf(language) !== i)) {
      return { id: 'validation.distinct', values: { attribute: 'common.language' } }
    }
  }

  // This condition will reset form data when the modals are closed
  return (
    (isOpen || duplicateProducts.length > 0) && (
      <>
        <Dialog
          header={intl.formatMessage({ id: 'chemicals.product_already_exists' })}
          modal={true}
          appendTo={document.body}
          visible={duplicateProducts.length > 0}
          onHide={() => setDuplicateProducts([])}>
          <HasService
            service="chemical_list"
            no={
              <>
                <p>
                  <FormattedHTMLMessage id="chemicals.product_already_exists_dialog_text_admin"/>
                </p>
                <List>
                  {duplicateProducts.map((product) => (
                    <div key={product.id}>
                      <div>
                        <span>{product.name}</span>
                        <span>{product.supplier}</span>
                      </div>
                    </div>
                  ))}
                </List>
              </>
            }
            yes={
              <>
                <p>
                  <FormattedHTMLMessage id="chemicals.product_already_exists_dialog_text"/>
                </p>
                {_.filter(duplicateProducts, (c) => !c.organisation_chemical).length > 0 && (
                  <div>
                    <FormattedHTMLMessage id="chemicals.product_already_exists_addable"/>
                  </div>
                )}
                <List>
                  {_.filter(duplicateProducts, (c) => !c.organisation_chemical).map((product) => (
                    <div key={product.id}>
                      <div>
                        <span>{product.name}</span>
                        <span>{product.supplier}</span>
                      </div>
                      <Button
                        link
                        primary
                        icon="pi pi-plus-circle"
                        label={intl.formatMessage({ id: 'common.add' })}
                        onClick={() => addExistingProduct(product)}
                      />
                    </div>
                  ))}
                </List>
                {_.filter(duplicateProducts, (c) => c.organisation_chemical).length > 0 && (
                  <div>
                    <FormattedHTMLMessage id="chemicals.product_already_exists_added"/>
                  </div>
                )}
                <List>
                  {_.filter(duplicateProducts, (c) => c.organisation_chemical).map((product) => (
                    <div key={product.id}>
                      <div>
                        <span>{product.name}</span>
                        <span>{product.supplier}</span>
                      </div>
                    </div>
                  ))}
                </List>
              </>
            }
          />
          <Foot>
            <ModalActionsFooter>
              <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => setDuplicateProducts([])}/>
              <Button
                label={intl.formatMessage({ id: 'chemicals.register_product' })}
                isLoading={isLoading}
                primary
                onClick={createDuplicateProduct}
              />
            </ModalActionsFooter>
          </Foot>
        </Dialog>
        <Dialog
          header={intl.formatMessage({ id: 'chemicals.register_product' })}
          modal={true}
          visible={isOpen}
          appendTo={document.body}
          onHide={() => setIsOpen(false)}>
          <Form onSubmit={onSubmit} useFormData={true}>
            <FieldGroup>
              <Field id="name" label={intl.formatMessage({ id: 'chemicals.product' })} Component={TextInput}
                     required={true}/>
              <Field id="supplier" label={intl.formatMessage({ id: 'common.supplier' })} Component={TextInput}
                     required={true}/>
              {organisation.enable_chemical_list_article_numbers && (
                <Field
                  id="organisation_article_number"
                  Component={TextInput}
                  label={intl.formatMessage({ id: 'chemicals.organisation_article_number' })}
                />
              )}
              {organisation.enable_chemical_list_synonyms && (
                <Field
                  id="organisation_synonym"
                  Component={TextInput}
                  label={intl.formatMessage({ id: 'chemicals.organisation_synonym' })}
                />
              )}

              <Field
                id="sds[]"
                text={intl.formatMessage({ id: 'sds.upload_sdb' })}
                Component={FileUpload}
                multiple={true}
                required={true}
                FileRowComponent={MarketLanguageDropdown}
                validation={validateFile}
              />
              {isShowLocationsAndPackages && (
                <>
                  <Field
                    id="locations"
                    Component={LocationsWithPackages}
                    hasErrorHandling={true}
                    validation={validateLocationsWithPackages}
                    initialValue={[{ ...emptyLocationRow }]}
                  />
                </>
              )}
              {isShowPackages && (
                <>
                  <Field
                    id="packages"
                    Component={Packages}
                    hasErrorHandling={true}
                    validation={validateInventory}
                    initialValue={[{ ...emptyPackageRow }]}
                  />
                </>
              )}
            </FieldGroup>
            <FlexRowCenterHorizontal>
              <InfoPanel messageid="chemicals.new-product-info"/>
            </FlexRowCenterHorizontal>
            <ModalActionsFooter>
              <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => setIsOpen(false)}/>
              <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                      isLoading={isLoading}/>
            </ModalActionsFooter>
          </Form>
        </Dialog>
      </>
    )
  )
}

export default injectIntl(NewProductDialog)
