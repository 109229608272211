import React, { useContext, useState } from 'react'
import { AuthContext } from 'Context'
import { FormattedHTMLMessage, useIntl } from 'react-intl'
import { CheckboxHTMLLabel } from 'Components/input'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { Spacer } from 'Components/layout'
import { useHistory } from 'react-router-dom'

export const TermsModalContent = ({ modalController }) => {
  const intl = useIntl()
  const history = useHistory()
  const { user, logoutAction, setPolicyConsentAction } = useContext(AuthContext)

  const [policy, setPolicy] = useState(false)
  const [terms, setTerms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onLogout = () => {
    logoutAction()
    history.push('/loggedOut')
  }

  const onContinue = async () => {
    setIsLoading(true)
    if (modalController.policyUrl) {
      await setPolicyConsentAction('integrity_policy', user.latest_consents.integrity_policy.version)
    }
    if (modalController.termsUrl) {
      await setPolicyConsentAction('user_terms', user.latest_consents.user_terms.version)
    }
    setIsLoading(false)
    modalController.close()
  }

  return (
    <>
      <p>
        <FormattedHTMLMessage id="auth.terms-modal-text"/>
      </p>

      {modalController.termsUrl && (
        <p>
          <CheckboxHTMLLabel
            id="checkbox-terms"
            labelId="auth.check-terms"
            labelValues={{ url: modalController.termsUrl }}
            value={terms}
            onChange={(event) => {
              setTerms(event.checked)
            }}
          />
        </p>
      )}
      {modalController.policyUrl && (
        <p>
          <CheckboxHTMLLabel
            id="checkbox-policy"
            labelId="auth.check-policy"
            labelValues={{ url: modalController.policyUrl }}
            value={policy}
            onChange={(event) => {
              setPolicy(event.checked)
            }}
          />
        </p>
      )}

      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'auth.logout' })} onClick={onLogout}/>
        <Button
          id="button-submit-terms"
          submit
          primary
          disabled={(modalController.policyUrl && !policy) || (modalController.termsUrl && !terms)}
          icon="pi pi-arrow-right"
          label={intl.formatMessage({ id: 'common.continue' })}
          onClick={onContinue}
          isLoading={isLoading}
        />
      </ModalActionsFooter>
    </>
  )
}
