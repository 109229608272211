import React, { useContext, useState } from 'react'
import axios from 'axios'

import { Field, Form } from 'Components/form'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { TableMarkings } from 'Components/readonly/markings'
import styled from 'styled-components'
import { AuthContext, GrowlContext } from 'Context'

import { FormattedMessage, injectIntl } from 'react-intl'
import LocationsWithPackages, {
  emptyLocationRow,
  validateLocationsWithPackages
} from 'Components/combined/locationsWithPackages'
import { TextInput } from 'Components/input'

const Detail = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 99.99%;

  strong {
    flex: 0 1 120px;
  }

  > span {
    flex: 1 1 300px;
  }
`
const Container = styled.div`
  padding: 20px;
`

const AddProductDialog = ({ modalController, intl, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displaySuccess, displayError } = useContext(GrowlContext)
  const { organisation } = useContext(AuthContext)

  const product = modalController.data

  const onSubmit = async (formData) => {
    setIsLoading(true)
    formData.product_id = product.id
    try {
      const response = await axios.post('/rapi/chemicals/add', formData)

      displaySuccess(response.data.message)
      setIsLoading(false)
      modalController.close()

      onSuccess(product.id, response.data.locations)
    } catch (error) {
      displayError(error)
      setIsLoading(false)
    }
  }

  return (
    product && (
      <Container>
        <Form onSubmit={onSubmit}>
          <Detail>
            <strong>
              <FormattedMessage id="chemicals.product"/>
            </strong>
            <span>{product.name}</span>
          </Detail>
          <Detail>
            <strong>
              <FormattedMessage id="common.supplier"/>
            </strong>
            <span>{product.supplier}</span>
          </Detail>
          <Detail>
            <strong>
              <FormattedMessage id="common.marking"/>
            </strong>
            <span>{TableMarkings(product)}</span>
          </Detail>
          {organisation.enable_chemical_list_article_numbers && (
            <Field
              id="organisation_article_number"
              Component={TextInput}
              label={intl.formatMessage({ id: 'chemicals.organisation_article_number' })}
            />
          )}
          {organisation.enable_chemical_list_synonyms && (
            <Field id="organisation_synonym" Component={TextInput}
                   label={intl.formatMessage({ id: 'chemicals.organisation_synonym' })}/>
          )}
          <Field
            id="locations"
            Component={LocationsWithPackages}
            hasErrorHandling={true}
            validation={validateLocationsWithPackages}
            initialValue={[{ ...emptyLocationRow }]}
          />
          <ModalActionsFooter>
            <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => modalController.close()}/>
            <Button id="add-product-button" primary submit label={intl.formatMessage({ id: 'common.add' })}
                    isLoading={isLoading}/>
          </ModalActionsFooter>
        </Form>
      </Container>
    )
  )
}

export default injectIntl(AddProductDialog)
