import React from 'react'
import { TabPanel } from 'primereact/tabview'
import { ProgressSpinner } from 'primereact/progressspinner'
import { FormattedMessage, injectIntl } from 'react-intl'

import { TabView } from 'Components/readonly'
import RevisionTable from './revisionTable'
import { PageConstraint, PageHeader } from 'Components/layout'
import useCrudData from 'Hooks/useCrudData'
import { AuthContext } from 'Context'

const RevisionPage = injectIntl(({ intl }) => {
  // Need to refetch and rerender when organisation changes
  const { organisation } = React.useContext(AuthContext)
  const [isLoading, sds, , readSds, updateSds, deleteSds] = useCrudData('/rapi/revision/list', [], [organisation])
  const sdsByExpired = React.useMemo(() => {
    const upcoming = [],
      revision = []
    sds.forEach((s) => {
      if (s.revisionActionTimeHasExpired) {
        revision.push(s)
      } else {
        upcoming.push(s)
      }
    })
    return {
      upcoming: upcoming,
      revision: revision
    }
  }, [sds])

  return isLoading ? (
    <ProgressSpinner/>
  ) : (
    <PageConstraint>
      <PageHeader>
        <h1>
          <FormattedMessage id="sds.revision-of-sds"/>
        </h1>
      </PageHeader>
      <TabView>
        <TabPanel header={intl.formatMessage({ id: 'sds.upcoming-revisions' })}>
          <p>
            <FormattedMessage id="sds.upcoming-revisions-text"/>
          </p>
          <RevisionTable
            view="UPCOMING"
            isLoading={isLoading}
            sds={sdsByExpired.upcoming}
            readSds={readSds}
            updateSds={updateSds}
            deleteSds={deleteSds}
          />
        </TabPanel>
        <TabPanel leftIcon="pi pi-lock" header={intl.formatMessage({ id: 'sds.under_revision' })}>
          <p>
            <FormattedMessage id="sds.under-revision-text"/>
          </p>
          <RevisionTable view="REVISION" isLoading={isLoading} sds={sdsByExpired.revision} readSds={readSds}
                         updateSds={updateSds}/>
        </TabPanel>
        {/* <TabPanel header={intl.formatMessage({ id: 'sds.active_kemrisk_sds' })} >
          <RevisionTable view="ALL" isLoading={isLoading} sds={sds} readSds={readSds} updateSds={updateSds} />
        </TabPanel> */}
      </TabView>
    </PageConstraint>
  )
})

export default RevisionPage
