import React from 'react'

import { Dropdown } from 'Components/input'

export const supportedUILanguages = [
  { label: 'Svenska', value: 'sv' },
  { label: 'Norsk', value: 'no' },
  { label: 'Dansk', value: 'dk' },
  { label: 'English', value: 'en' }
]

const UiLanguageDropdown = (props) => {
  return <Dropdown options={supportedUILanguages} {...props} />
}

export default UiLanguageDropdown
