import * as React from 'react'
import styled from 'styled-components'

import { SelectButton } from '../input'
import colors from '../colors.scss'

const Left = styled.div`
  margin-right: 10px;
  flex: 1 1 ${(props) => props.flexBasis || '825px'};
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1200px) {
    margin-right: 0;
  }
`
const Right = styled.div`
  flex: 1 1 ${(props) => props.flexBasis || '550px'};
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (max-width: 640px) {
    overflow-y: scroll;
  }
`

const ResponsiveViewToggle = styled.div`
  margin-right: 10px;
  display: none;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  border-top: 1px solid ${colors.dividerGray};
  padding-top: 15px;

  @media screen and (max-width: 1200px) {
    ${Right} {
      display: ${(props) => (props.responsiveView === 'right' ? 'flex' : 'none')};
    }

    ${Left} {
      display: ${(props) => (props.responsiveView === 'left' ? 'flex' : 'none')};
    }

    ${ResponsiveViewToggle} {
      display: flex;
      align-self: flex-end;
      margin-bottom: 10px;
      flex: 0 0 auto;
    }
  }
  @media screen and (max-width: 640px) {
    ${ResponsiveViewToggle} {
      display: none;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  @media screen and (max-width: 640px) {
    overflow-y: scroll;
    display: block;
    ${Left} {
      display: block;
    }

    ${Right} {
      display: block;
      overflow: hidden;
    }
  }
`

const TwoColumn = React.forwardRef(({ viewOptions, leftContent, rightContent, leftFlexBasis, rightFlexBasis }, ref) => {
  const [responsiveView, setResponsiveView] = React.useState('left')
  const rightRef = React.useRef(null)
  const contentRef = React.useRef(null)

  React.useImperativeHandle(ref, () => ({
    getRightColumn: () => {
      if (window.innerWidth > 640) {
        return rightRef.current
      } else {
        return contentRef.current
      }
    }
  }))

  return (
    <Wrapper responsiveView={responsiveView}>
      <ResponsiveViewToggle>
        <SelectButton
          options={viewOptions}
          value={responsiveView}
          onChange={(e) => {
            if (e.value) {
              setResponsiveView(e.value)
            }
          }}
        />
      </ResponsiveViewToggle>
      <Content ref={contentRef}>
        <Left flexBasis={leftFlexBasis}>{leftContent}</Left>
        <Right ref={rightRef} flexBasis={rightFlexBasis}>
          {rightContent}
        </Right>
      </Content>
    </Wrapper>
  )
})

export default TwoColumn
