import React, { useContext, useState } from 'react'
import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { Button, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'
import { Spacer } from 'Components/layout'

const DeleteProductForLocationDialog = ({ modalController, onSuccess }) => {
  const intl = useIntl()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)

  const { location, product } = modalController.data

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const response = await axios.post('/rapi/chemicals/removefromlocation', {
        product_id: product.id,
        location_id: location.id
      })
      setIsLoading(false)
      onSuccess(response.data)
      displaySuccess(intl.formatMessage({ id: 'chemicals.product-updated' }))
      modalController.close()
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        history.replace('/chemicalList')
      } else {
        displayError(error)
        setIsLoading(false)
      }
    }
  }

  return (
    product &&
    location && (
      <>
        <p>
          <FormattedMessage id="chemicals.product"/>: <strong>{product.name}</strong>
          <br/>
          <FormattedMessage id="common.location"/>: <strong>{location.name}</strong>
        </p>
        <p>
          <FormattedMessage id="chemicals.remove_all_from_location-content"/>
        </p>
        <ModalActionsFooter>
          <Spacer/>
          <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => modalController.close()}/>
          <Button primary onClick={onSubmit} label={intl.formatMessage({ id: 'common.delete' })} isLoading={isLoading}/>
        </ModalActionsFooter>
      </>
    )
  )
}

export default DeleteProductForLocationDialog
