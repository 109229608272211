import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

import { Button, Panel } from '../readonly'
import { Dropdown, TextInput } from '../input'

const Row = styled(Panel)`
  display: flex;
  margin-top: 10px;
  padding: 5px;

  > .exposure-type {
    flex: 1 1 1%;
    margin-right: 10px;
    overflow: hidden;
  }

  > .exposure-value {
    flex: 0 0 120px;
  }

  > .exposure-action {
    flex: 0 0 30px;
    margin-top: 25px;

    > button {
      margin-bottom: 0;
    }
  }

  > div {
    div:first-of-type {
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 640px) {
    display: block;
    position: relative;
    padding: 5px 10px;
    > div {
      width: 85%;
      margin-bottom: 8px;
    }

    ${Button} {
      position: absolute;
      top: 30px;
      right: 7px;
    }
  }
`

export const ChemicalPnecRow = injectIntl(({ id, value, update, remove, intl }) => {
  const exposureTypeOptions = [
    { value: 'freshwater', label: intl.formatMessage({ id: 'chemicals.form.pnec.freshwater' }) },
    {
      value: 'sediment_in_freshwater',
      label: intl.formatMessage({ id: 'chemicals.form.pnec.sediment_in_freshwater' })
    },
    { value: 'sea_water', label: intl.formatMessage({ id: 'chemicals.form.pnec.sea_water' }) },
    { value: 'sediment_in_sea_water', label: intl.formatMessage({ id: 'chemicals.form.pnec.sediment_in_sea_water' }) },
    { value: 'food_chain', label: intl.formatMessage({ id: 'chemicals.form.pnec.food_chain' }) },
    {
      value: 'microorganisms_in_waste_water_treatment',
      label: intl.formatMessage({ id: 'chemicals.form.pnec.microorganisms_in_waste_water_treatment' })
    },
    { value: 'soil_farming', label: intl.formatMessage({ id: 'chemicals.form.pnec.soil_farming' }) },
    { value: 'air', label: intl.formatMessage({ id: 'chemicals.form.pnec.air' }) },
    { value: 'intermittent', label: intl.formatMessage({ id: 'chemicals.form.pnec.intermittent' }) }
  ]

  return (
    <Row id={id}>
      <div className="exposure-type">
        <div>
          <FormattedMessage id="chemicals.form.pnec.exposure_type"/>
        </div>
        <Dropdown
          id={id + '-exposure_type'}
          value={value.exposure_type}
          onChange={(e) => update('exposure_type', e)}
          options={exposureTypeOptions}
          appendTo={document.body}
        />
      </div>
      <div className="exposure-value">
        <div>
          <FormattedMessage id="chemicals.form.pnec.value"/>
        </div>
        <TextInput id={id + '-value'} value={value.value} onChange={(e) => update('value', e)}/>
      </div>
      <div className="exposure-action">
        <Button icon="pi pi-trash" onClick={remove}/>
      </div>
    </Row>
  )
})
