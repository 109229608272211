import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import axios from 'axios'

import {
  checkCircleBody,
  Column,
  commaSeparatedBody,
  DataTableWithSearch,
  datetimeBody,
  LoadingSpinner,
  Modal
} from 'Components/readonly'
import { LongerSection } from 'Components/layout'
import { Button, SelectButton } from 'Components/input'
import useModalController from 'Hooks/useModalController'
import AddIngredientFilterIdDialog from './addIngredientFilterIdDialog'
import useGetData from 'Hooks/useGetData'
import { AuthContext, GrowlContext } from 'Context'

const url = '/rapi/admin/ingredientfilter'

export default () => {
  const intl = useIntl()
  const { userLanguage } = useContext(AuthContext)
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const [isLoading, ingredientFilters, setIngredientFilters] = useGetData(url, [])
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [addIngredientFilterIdModalController] = useModalController({ title: intl.formatMessage({ id: 'common.add' }) })

  const filterOptions = useMemo(() => {
    const options = ingredientFilters.map((filter) => {
      let lbl = filter.label_en
      if (userLanguage === 'sv') {
        lbl = filter.label_sv
      } else if (userLanguage === 'no') {
        lbl = filter.label_no
      } else if (userLanguage === 'dk') {
        lbl = filter.label_dk
      }

      return {
        label: lbl,
        value: filter.filter_type
      }
    })
    return options
  }, [ingredientFilters, userLanguage])

  useEffect(() => {
    // Update selected filter, if no filter was selected or if selected no longer exists select the first.
    setSelectedFilter((selectedFilter) => {
      if (ingredientFilters.length > 0) {
        const filter = selectedFilter && ingredientFilters.find((filter) => filter.filter_type === selectedFilter.filter_type)
        if (filter) {
          return filter
        } else {
          return ingredientFilters[0]
        }
      } else {
        return null
      }
    })
  }, [ingredientFilters])

  const selectFilter = (filterType) => {
    if (filterType) {
      setSelectedFilter(ingredientFilters.find((filter) => filter.filter_type === filterType))
    }
  }

  const updateFilter = (updatedFilter) => {
    const newFilters = [...ingredientFilters]
    const index = newFilters.findIndex((filter) => filter.filter_type === selectedFilter.filter_type)
    if (index >= 0) {
      newFilters[index] = updatedFilter
    }
    setIngredientFilters(newFilters)
  }

  const addIngredientFilterId = () => {
    addIngredientFilterIdModalController.open({ data: selectedFilter })
  }

  const addIngredientFilterIdSuccessHandler = (data) => {
    const newFilter = { ...selectedFilter }
    newFilter.ingredients.push(data)
    updateFilter(newFilter)
  }

  const remove = (row) => {
    return !row.flagged && <Button link primary icon="pi pi-trash" onClick={() => removeRow(row)}/>
  }

  const removeRow = async (row) => {
    try {
      let result = await axios.delete(url + '/' + row.id)
      // Update the selected filter
      const newFilter = { ...selectedFilter }
      newFilter.ingredients = newFilter.ingredients.filter((entry) => entry.id !== row.id)
      // Replace the update filter in ingredientFilters list
      updateFilter(newFilter)
      displaySuccess(result.data.message)
    } catch (e) {
      displayError(e)
    }
  }

  return (
    <>
      <Modal
        modalController={addIngredientFilterIdModalController}
        ContentComponent={AddIngredientFilterIdDialog}
        onSuccess={addIngredientFilterIdSuccessHandler}
      />
      <LoadingSpinner isLoading={isLoading}>
        <LongerSection>
          <SelectButton
            options={filterOptions}
            value={selectedFilter && selectedFilter.filter_type}
            onChange={(e) => selectFilter(e.target.value)}
          />
        </LongerSection>
        <DataTableWithSearch
          value={selectedFilter && selectedFilter.ingredients}
          paginator={true}
          rows={15}
          tools={<Button primary icon="pi pi-plus" onClick={addIngredientFilterId}
                         label={intl.formatMessage({ id: 'common.add' })}/>}>
          <Column
            field="names"
            header={intl.formatMessage({ id: 'chemicals.form.ingredient.name' })}
            body={commaSeparatedBody}
            sortable={true}
          />
          <Column field="cas_id" header={intl.formatMessage({ id: 'chemicals.form.ingredient.cas_id' })}
                  sortable={true}/>
          <Column field="eg_id" header={intl.formatMessage({ id: 'chemicals.form.ingredient.eg_id' })} sortable={true}/>
          <Column field="flagged" header={intl.formatMessage({ id: 'common.flagged' })} body={checkCircleBody}
                  sortable={true}/>
          <Column field="updated_at" header={intl.formatMessage({ id: 'common.updated' })} body={datetimeBody}
                  sortable={true}/>
          <Column body={remove} style={{ width: '80px' }} header={intl.formatMessage({ id: 'common.delete' })}/>
        </DataTableWithSearch>
      </LoadingSpinner>
    </>
  )
}
