import React from 'react'
import { Dialog } from 'primereact/dialog'

import { Button, ModalActionsFooter } from 'Components/readonly'
import { Spacer, TextContainer } from 'Components/layout'
import { FormattedMessage, injectIntl } from 'react-intl'

const CloseProductDialog = ({intl, isOpen, onAccept, onDecline }) => {
  return (
    <Dialog
      header={intl.formatMessage({ id: 'chemicals.close_editing' })}
      modal={true}
      visible={isOpen}
      onHide={onAccept}
      appendTo={document.body}>
      <TextContainer>
        <p>
          <FormattedMessage id="chemicals.close_editing_text" />
        </p>
      </TextContainer>
      <ModalActionsFooter>
        <Spacer />
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={onDecline} />
        <Button primary onClick={onAccept} label={intl.formatMessage({ id: 'common.finish' })} />
      </ModalActionsFooter>
    </Dialog>
  )
}

export default injectIntl(CloseProductDialog)
