import React from 'react'
import styled from 'styled-components'

import colors from '../colors.scss'

const Wrapper = styled.div`
  position: relative;
  min-height: 30px;
`
const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.isLoading ? '#00000080' : 'transparent')};
  z-index: ${(props) => (props.isLoading ? 1 : -1)};
  transition: background-color z-index .5s;
  border-radius: 20px;
`
const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => (props.isLoading ? colors.darkGrey : 'transparent')};
  transition: color .5s;
`

export const LoadingOverlay = ({ children, isLoading }) => {
  return <Wrapper>
    <SpinnerWrapper isLoading={isLoading}><Spinner isLoading={isLoading}><i
      className="pi pi-spinner pi-spin"/></Spinner></SpinnerWrapper>
    <div>{children}</div>
  </Wrapper>
}
