import React from 'react'
import styled from 'styled-components'
import colors from 'Components/colors.scss'
import { FormattedMessage } from 'react-intl'
import { FlexRowCenter } from 'Components/layout'

export const InventoryStartLockMessage = styled(({ ...props }) => {
  return (
    <FlexRowCenter {...props}>
      <i className="pi pi-exclamation-triangle" style={{ fontSize: '3em' }}/>
      <div style={{ marginLeft: '20px' }}>
        <FormattedMessage id="inventory.start-lock-warning"/>
      </div>
    </FlexRowCenter>
  )
})`
  background-color: ${colors.accentFaded};
  color: ${colors.white};
  padding: 5px 15px;
`
