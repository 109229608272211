import React from 'react'
import { LabeledPictos } from 'Components/readonly/markings'
import styled from 'styled-components'

const PictoContainer = styled.div`
  max-width: 800px;
`

export const PictoList = () => {
  return (
    <PictoContainer>
      <LabeledPictos/>
    </PictoContainer>
  )
}
