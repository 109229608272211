import { Checkbox as CB } from 'primereact/checkbox'
import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import colors from '../colors.scss'

const StyledCheckbox = styled(CB)`
  &&& {
    .p-checkbox-box  {
      background-color: ${colors.white};
      border-color: ${colors.white};
      height: 17px;
      width: 17px;

      &.p-focus {
        box-shadow: 0 0 0 0.2em ${colors.primary};
      }

      &.p-highlight {
        color: #000;

        &:not(.p-disabled):hover {
          background-color: ${colors.primary};
        }
      }

      & .p-checkbox-icon {
        font-size: 15px;
      }
    }
  }
`

const CheckboxWrapper = styled.div`
  &&& {
    padding: ${(props) => (props.horizontal && '10px 20px 0 0') || '4px 0'};
    display: ${(props) => (props.horizontal && 'inline-flex') || 'flex'};
    align-items: center;

    label {
      cursor: pointer;
    }

    .p-checkbox {
      flex: 0 0 20px;
    }

  }
`

const makeid = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}


export const CheckboxGroupSimple = ({ id, isLoading, options, onChange, value, fieldOnChange, ...props }) => {
  const handleChange = (e) => {
    window.scrollTo(0, 0) // IOS Hack: When going from input element the window doesn't scroll back to top.
    let selected = [...(value || [])];
    if (e.checked) {
      selected.push(e.value)
    } else {
      let index
      if (fieldOnChange) {
        index = selected.findIndex((s) => s.toString() === e.value.value.toString())
      } else {
        selected.forEach((s, i) => {
          if (s.value.toString() === e.value.value.toString()) {
            index = i
          }
        })
      }
      selected.splice(index, 1)
    }

    if (fieldOnChange) {
      onChange({ target: { value: selected.map((item) => item.value || item) } })
    } else {
      onChange(selected)
    }
  }

  const isSelected = (cb) => {
    if (!value) {
      return false
    }

    if (fieldOnChange) {
      return value.includes(cb.value)
    }

    let selected = false
    value.forEach((s, i) => {
      if (s.value.toString() === cb.value.toString()) {
        selected = true
      }
    })
    return selected
  }

  return options.map((cb, i) => {
    let idA = makeid(5)
    return (
      <CheckboxWrapper {...props} key={i} index={i}>
        <div id={`item-${id}-${i}`} className="checkbox-item" data-index={i}>
          <StyledCheckbox inputId={idA} onChange={handleChange} value={cb} checked={isSelected(cb)}/>
          <label htmlFor={idA} className="p-checkbox-label">
            {cb.label}
          </label>
        </div>
      </CheckboxWrapper>
    )
  })
}
export const GroupedCheckboxGroupSimple = ({ options, ...props }) => {
  return Object.keys(options).map((group) => (
    <div key={group}>
      <FormattedMessage id={'chemicals.sds-filter-' + group}/>
      <CheckboxGroupSimple options={options[group]} {...props} />
    </div>
  ))
}
