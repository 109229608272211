import React from 'react'
import { useIntl } from 'react-intl'
import { Badge } from 'Components/readonly'

export default ({ sds }) => {
  const intl = useIntl()
  return !sds.active ? (
    <Badge fill faded label={intl.formatMessage({ id: 'sds.is_archived' })}/>
  ) : sds.status === 'done' ? (
    <Badge fill accent label={intl.formatMessage({ id: 'sds.is_active' })}/>
  ) : sds.status === 'notDone' ? (
    <Badge fill danger label={intl.formatMessage({ id: 'sds.not_approved' })}/>
  ) : (
    <Badge fill label={intl.formatMessage({ id: 'common.is_unknown' })}/>
  )
}
