import * as React from 'react'
import axios from 'axios'
import { FormattedMessage, injectIntl } from 'react-intl'
import _ from 'lodash'

import { GrowlContext } from 'Context'
import { Button } from 'Components/readonly'
import { SidebarHeader, Spacer } from 'Components/layout'
import { setLocked } from "../../../Utils/functions";

const ProductSdsRevisionSidebarContent = injectIntl(({
                                                       intl,
                                                       close,
                                                       productId,
                                                       updateProduct,
                                                       reviewState,
                                                       isLocked
                                                     }) => {
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [isSaving, setIsSaving] = React.useState(false)

  const saveReviews = async () => {
    setIsSaving(true)

    const requestData = {
      sds: reviewState
    }

    try {
      const response = await axios.put('/rapi/chemicals/' + productId + '/sds/review', requestData)
      updateProduct(response.data.chemical)
      displaySuccess(response.data.message)
    } catch (error) {
      displayError(error)
    }
    setIsSaving(false)
  }

  const stateNotSet = () => {
    return !_.isEmpty(
      _.filter(reviewState, (item) => {
        return item.status === 'none'
      })
    )
  }

  return (
    <SidebarHeader>
      <h2>
        <FormattedMessage id="chemicalsds.review_heading"/>
      </h2>
      <Spacer/>
      <div>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={close}/>
        {isLocked ? <Button onClick={() => setLocked(productId, 0).then(() => {
            displaySuccess(intl.formatMessage({ id: 'chemicals.unlocked' }))
            setTimeout(() => {
              window.location.reload(false);
            }, 1500);
          }).catch(error => {
            displayError(error)
          })} id="proceed-button" disabled label="Lås upp låst produkt" icon="pi pi-unlock"
                            className="button-text-md-hide"/>
          : <Button
            primary
            label={intl.formatMessage({ id: 'chemicalsds.save_goto_productinformation' })}
            onClick={saveReviews}
            isLoading={isSaving}
            disabled={stateNotSet()}
          />
        }
      </div>
    </SidebarHeader>
  )
})

export default ProductSdsRevisionSidebarContent
