import colors from 'Components/colors.scss'
import ErrorBoundaryLogOnly from 'Components/errorBoundaryLogOnly'
import { Button } from 'Components/input'
import { FlexColumn, FlexRowCenter, Spacer } from 'Components/layout'
import { Avatar, Divider, LoadingSpinner } from 'Components/readonly'
import NewsFeed from 'Components/readonly/newsFeed'
import linkConfig from 'Config/app-external-links'
import { AuthContext } from 'Context'
import { isPast, parse } from 'date-fns'
import useGetData from 'Hooks/useGetData'
import React, { useContext, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import AlertIcon from './components/assets/ico-alert.svg'
import { ReactComponent as InfoIcon } from './components/assets/ico-info.svg'
import SupportIcon from './components/assets/ico-support.svg'
import { NotificationSidebar } from './components/notificationSidebar'
import StartChemModule from './components/startChemModule'
import StartChemModuleDisabled from './components/startChemModuleDisabled'
import StartChemModuleNotAuthorized from './components/startChemModuleNotAuthorized'
import {
  ActionCard,
  ActionCardContainer,
  Bell,
  News,
  NewsContainer,
  NotificationButtonWrapper,
  ServiceContainer,
  Services,
  ServicesContainer,
  SupportCard,
  Wrapper
} from './components/startPageStyle'
import StartSDSModule from './components/startSDSModule'
import StartSDSModuleDisabled from './components/startSDSModuleDisabled'
import StartSDSModuleNotAuthorized from './components/startSDSModuleNotAuthorized'

const ColoredInfoIcon = styled(InfoIcon)`
  fill: white;
  width: 26px;
  height: 26px;
  margin-right: 15px;
`

const StartNotificationButton = styled(Button)`
  &&&& {
    background: linear-gradient(90deg, ${colors.purpleGradient1}, ${colors.purpleGradient2});
  }
`

const ExpireContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StartPage = injectIntl(({ history, intl }) => {
  const { user, organisation } = useContext(AuthContext)
  const [showNotificationSidebar, setShowNotificationSidebar] = useState(false)

  // es-lint-disable-next-line no-unused-vars
  const [isLoadingStartData, startData, setStartData] = useGetData(
    '/rapi/start',
    { content: '', sdsToApprove: 0, organisationMessages: [] },
    [organisation]
  )
  const services = []

  const sdsService = (
    <ExpireContainer>
      <ServiceContainer key="sds">
        {(organisation.has_sds &&
          (user.user.roleValues.sds === 'not_authorized' ? <StartSDSModuleNotAuthorized/> : <StartSDSModule/>)) || (
          <StartSDSModuleDisabled/>
        )}
      </ServiceContainer>
      {organisation.sds_end_date && isPast(parse(organisation.sds_end_date)) && (
        <div>
          <FormattedMessage id="welcome.module-expired"/>
        </div>
      )}
    </ExpireContainer>
  )

  const chemicalListService = (
    <ExpireContainer>
      <ServiceContainer key="chemical-list">
        {(organisation.has_chemical_list &&
          (user.user.roleValues.chemical_list === 'not_authorized' ? <StartChemModuleNotAuthorized/> :
            <StartChemModule/>)) || (
          <StartChemModuleDisabled/>
        )}
      </ServiceContainer>
      {organisation.chemical_list_end_date && isPast(parse(organisation.chemical_list_end_date)) && (
        <div>
          <FormattedMessage id="welcome.module-expired"/>
        </div>
      )}
    </ExpireContainer>
  )

  if (organisation.has_chemical_list && !organisation.has_sds) {
    services.push(chemicalListService)
    services.push(sdsService)
  } else {
    services.push(sdsService)
    services.push(chemicalListService)
  }

  const deleteMessage = (deletedMessage) => {
    setStartData((startData) => {
      return {
        ...startData,
        organisationMessages: startData.organisationMessages.filter((message) => message.id !== deletedMessage.id)
      }
    })
  }

  return (
    <Wrapper id="Wrapper">
      <NotificationSidebar
        sdsToApprove={startData.sdsToApprove}
        revisionSdsToApprove={startData.revisionSdsToApprove}
        messages={startData.organisationMessages}
        onDelete={deleteMessage}
        showNotificationSidebar={showNotificationSidebar}
        setShowNotificationSidebar={setShowNotificationSidebar}
      />
      <ServicesContainer>
        <Services>
          <ActionCardContainer>
            <ActionCard>
              <Avatar src={user.user.avatar} name={user.user.name}/>
              <h1>{user.user.name}</h1>
              <strong style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                <FormattedMessage id="welcome.my-kemrisk"/>
              </strong>
              <p>{startData.welcome}</p>
              <Spacer/>
              <NotificationButtonWrapper>
                {(isLoadingStartData && <LoadingSpinner/>) || (
                  <StartNotificationButton
                    invert
                    fill
                    label={intl.formatMessage({ id: 'welcome.notifications' })}
                    onClick={() => setShowNotificationSidebar(true)}>
                    {(startData.sdsToApprove > 0 ||
                      startData.revisionSdsToApprove > 0 ||
                      (startData.organisationMessages && startData.organisationMessages.length > 0)) && (
                      <Bell>
                        <img src={AlertIcon} alt="alert"/>
                      </Bell>
                    )}
                  </StartNotificationButton>
                )}
              </NotificationButtonWrapper>
            </ActionCard>
            <SupportCard>
              <FlexColumn>
                <FlexRowCenter>
                  <img src={SupportIcon} alt="support"/>
                  <a href={`mailto: ${linkConfig.emailSupport}`} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="welcome.support"/>
                  </a>
                </FlexRowCenter>
                <FlexRowCenter>
                  <ColoredInfoIcon/>
                  <a href={`mailto:${linkConfig.emailInfo}`} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="common.questions"/>
                  </a>
                </FlexRowCenter>{' '}
              </FlexColumn>
            </SupportCard>
          </ActionCardContainer>
          {services.map((service) => {
            return service
          })}
        </Services>
      </ServicesContainer>
      <ErrorBoundaryLogOnly>
        <News>
          <NewsContainer>
            <h2>
              <FormattedMessage id="welcome.latest-news"/>
            </h2>
            <p>{startData.news}</p>
            <Divider/>
            <NewsFeed/>
            <div className="moreNewsButton">
              <a className="external-link" href={linkConfig.kemriskNews} target="_blank" rel="noopener noreferrer">
                <span>{intl.formatMessage({ id: 'welcome.more-news' })}</span>
                <i className="pi pi-window-maximize"></i>
              </a>
            </div>
          </NewsContainer>
        </News>
      </ErrorBoundaryLogOnly>
    </Wrapper>
  )
})

export default withRouter(StartPage)
