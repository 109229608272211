import * as React from 'react'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { AuthContext } from 'Context'
import { SearchInput } from 'Components/input'
import { checkCircleBody, Column, DataTable, dateBody, ToolPanel } from 'Components/readonly'
import { preserveNewlinesColumnBody } from 'Utils/newLines'
import { globalSearch } from 'Utils/search'
import useCrudData from 'Hooks/useCrudData'
import SdsStatus from 'Pages/sds/components/sdsStatus'
import SdsDetail from 'Pages/sds/sdsDetail'
import SdsPublicSwitch from 'Pages/sds/components/sdsPublicSwitch'

const RevisionSubscriptionCompletedTable = ({ admin }) => {
  const intl = useIntl()
  const [globalFilter, setGlobalFilter] = useState('')
  const [showDetailId, setShowDetailId] = useState(null)
  const [first, setFirst] = useState(0)

  const { organisation } = React.useContext(AuthContext)
  const url = '/rapi/' + (admin ? 'admin/' : '') + 'revisionsubscription/listcompletedsds'
  const [isLoading, sds, , readSds, updateSds] = useCrudData(url, [], [organisation])

  const showDetailSds = readSds(showDetailId)

  const filteredSds = useMemo(
    () =>
      globalSearch([...sds], globalFilter, ['productName', 'countryName', 'langName']).map((sds) => ({
        ...sds,
        // Has external labeling or internal
        calcHasLabeling: (sds.labeling && sds.labeling.length > 0) || sds.labelActive
      })),
    [globalFilter, sds]
  )

  const publicBody = (row) => <SdsPublicSwitch kind="internal" sds={row} updateSds={updateSds}/>

  const columns = [
    <Column
      field="status"
      header={intl.formatMessage({ id: 'common.status' })}
      body={(row) => <SdsStatus sds={row}/>}
      sortable={true}
      style={{ width: '1%', whiteSpace: 'nowrap' }}
    />,
    <Column
      field="binding_from"
      body={dateBody}
      header={intl.formatMessage({ id: 'sds.revision-subscription.binding-from' })}
      sortable={true}
      style={{ width: '1%', textAlign: 'center' }}
    />,
    <Column
      field="productName"
      header={intl.formatMessage({ id: 'chemicals.product' })}
      body={preserveNewlinesColumnBody}
      sortable={true}
    />,
    <Column field="id" header={intl.formatMessage({ id: 'sds.id' })} sortable={true} style={{ width: '110px' }}/>,
    <Column
      field="countryName"
      header={intl.formatMessage({ id: 'common.country' })}
      sortable={true}
      style={{ width: '1%', whiteSpace: 'nowrap' }}
    />,
    <Column
      field="langName"
      header={intl.formatMessage({ id: 'common.language' })}
      sortable={true}
      style={{ width: '1%', whiteSpace: 'nowrap' }}
    />,
    <Column
      field="calcHasLabeling"
      body={checkCircleBody}
      header={intl.formatMessage({ id: 'labeling.product-labeling' })}
      sortable={true}
      style={{ width: '1%', textAlign: 'center' }}
    />
  ]

  if (organisation.weblink > 0) {
    columns.push(
      <Column
        key="isPublic"
        field="isPublic"
        header={intl.formatMessage({ id: 'sds.public' })}
        sortable={true}
        body={publicBody}
        style={{ width: '1%' }}
      />
    )
  }

  if (admin) {
    columns.unshift(<Column field="organisation.name" header={intl.formatMessage({ id: 'admin.customer' })}
                            sortable={true}/>)
  }

  return (
    <>
      <ToolPanel>
        <SearchInput
          value={globalFilter}
          onChange={(event) => {
            setGlobalFilter(event.target.value)
            setFirst(0)
          }}
        />
      </ToolPanel>
      <DataTable
        className={'flextable clickable-rows'}
        value={filteredSds}
        paginator={true}
        rows={10}
        first={first}
        onPage={(e) => setFirst(e.first)}
        loading={isLoading}
        onRowClick={(event) => setShowDetailId(event.data.id)}
        responsive={true}>
        {columns}
      </DataTable>

      <SdsDetail
        sds={showDetailSds}
        kind="internal"
        isOpen={showDetailSds != null}
        close={() => {
          setShowDetailId(null)
        }}
        onSdsUpdated={(value) => {
          updateSds(value)
        }}
        publicBody={publicBody}
      />
    </>
  )
}

export default RevisionSubscriptionCompletedTable
