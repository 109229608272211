import React, { useContext } from 'react'
import axios from 'axios'
import { useIntl } from 'react-intl'

import { useCapability } from 'Components/capabilities'
import { Switch } from 'Components/input'
import { GrowlContext } from 'Context'

export default ({ kind, sds, updateSds }) => {
  const intl = useIntl()
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const hasManageSdsCapability = useCapability('MANAGE_SDS')

  const onChangeIsPublic = async (event) => {
    event.originalEvent.preventDefault()
    event.originalEvent.stopPropagation()

    updateSds({ ...sds, isPublic: event.value })

    try {
      await axios.post('/rapi/sds/setIsPublic', {
        kind: kind,
        id: sds.id,
        public: event.value
      })
      displaySuccess(intl.formatMessage({ id: 'common.saved' }))
    } catch (error) {
      displayError(error)
    }
  }

  if (sds.status === 'notDone' && !sds.isPublic) {
    return intl.formatMessage({ id: 'common.no' })
  }

  return (
    <Switch
      value={sds.isPublic}
      onLabel={intl.formatMessage({ id: 'common.yes' })}
      offLabel={intl.formatMessage({ id: 'common.no' })}
      disabled={!hasManageSdsCapability}
      onChange={onChangeIsPublic}
    />
  )
}
