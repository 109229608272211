import React from 'react'
import styled from 'styled-components'
import colors from '../colors.scss'
import { FormattedHTMLMessage } from 'react-intl'

const panelColors = {
  default: colors.lightBackgroundColor,
  dark: colors.darkerBackgroundColor,
  lighter: colors.lighterBackgroundColor
}

const ToolWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (min-width: 640px) {
    justify-content: ${props => props.spaceBetween ? 'space-between' : 'initial'};
  }

  > :not(.link-button) {
    width: 100%;
    margin-bottom: 10px;

    @media screen and (min-width: 640px) {
      width: auto;
      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  @media screen and (max-width: 640px) {
    & > .link-button {
      margin: 20px 0 0 0;
    }
  }
`

export const Panel = styled(({ children, dark, lighter, ...props }) => <div {...props}>{children}</div>)`
  padding: 0 25px 10px 20px;
  background-color: ${(props) => (props.dark ? panelColors['dark'] : props.lighter ? panelColors['lighter'] : panelColors['default'])};
  width: 100%;
  display: block;
`

export const ToolPanel = styled(({ children, className, ...props }) => {
  return (
    <ToolWrapper className={className} {...props}>
      {children}
    </ToolWrapper>
  )
})``

export const InfoPanel = styled(({ className, messageid }) => {
  return (
    messageid && (
      <Panel className={className}>
        <i className="pi pi-info"/> <FormattedHTMLMessage id={messageid}/>
      </Panel>
    )
  )
})`
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 500px;

  i {
    margin-right: 10px;
    font-size: 30px;
  }
`
