import React from 'react'
import { injectIntl } from 'react-intl'
import { Badge } from 'Components/readonly'

const RiskStatusBadgeList = ({ row, intl }) => {
  return (
    <>
      {row.riskStatus.active > 0 &&
        <Badge accent label={`${intl.formatMessage({ id: 'common.active' })} (${row.riskStatus.active})`}/>}
      {row.riskStatus.started > 0 &&
        <Badge faded label={`${intl.formatMessage({ id: 'common.draft' })} (${row.riskStatus.started})`}/>}
      {row.riskStatus.active === 0 && row.riskStatus.started === 0 &&
        <Badge danger label={intl.formatMessage({ id: 'common.missing' })}/>}
    </>
  )
}

export default injectIntl(RiskStatusBadgeList)
