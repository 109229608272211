import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import colors from '../colors.scss'
import { Checkbox } from './checkboxGroup'
import { injectIntl } from 'react-intl'

const TRACK_TOTAL_MARGIN_X = 10
const TRACK_SIDE_MARGIN_X = TRACK_TOTAL_MARGIN_X / 2
const THUMB_WIDTH = 20
const THUMB_RADIUS = THUMB_WIDTH / 2

const Thumb = styled.div``

const Track = styled.div``

const Margin = styled.div`
  margin-left: ${TRACK_SIDE_MARGIN_X}px;
`

const Wrapper = styled.div`
  height: 70px;
  margin-top: 18px;
  user-select: none;

  ${Thumb} {
    position: absolute;
    width: ${THUMB_WIDTH}px;
    height: 20px;
    border-radius: 10px;
    top: -8px;
    background-color: ${colors.accent};
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.349019607843137);
    cursor: ew-resize;

    &:hover {
      filter: brightness(110%);
    }
  }

  ${Track} {
    position: relative;
    margin-top: 10px;
    width: calc(100% - ${TRACK_TOTAL_MARGIN_X}%);
    margin-left: ${TRACK_SIDE_MARGIN_X}%;
    height: 4px;
    background-color: ${colors.lightBackgroundColor};
    cursor: pointer;
  }

  &.disabled {
    ${Track} {
      cursor: default;
    }

    ${Thumb} {
      background-color: ${colors.lightBackgroundColor};
      cursor: default;

      &:hover {
        filter: none;
      }
    }
  }
`

const TrackOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 5px;
  cursor: pointer;
`

const TrackOption = styled.div`
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  padding: 0 2px;
`

export const StepSlider = injectIntl(({ onChange, options, value, nullable, intl }) => {
  const trackRef = useRef(null)
  const thumbRef = useRef(null)
  const [nullCheckboxValue, setNullCheckboxValue] = useState(false)

  useEffect(() => {
    let left = parseFloat(value) * trackRef.current.getBoundingClientRect().width
    left = constrainToBounds(left)
    left -= THUMB_RADIUS
    thumbRef.current.style.left = left + 'px'
  }, [value, options.length])

  const constrainToBounds = (x) => {
    let xResult = x

    let trackWidth = trackRef.current.getBoundingClientRect().width
    if (x < 0) {
      xResult = 0
    } else if (x > trackWidth) {
      xResult = trackWidth
    }
    return xResult
  }

  const updateThumbPosition = (x) => {
    if (nullCheckboxValue) {
      return
    }
    var trackPos = trackRef.current.getBoundingClientRect()
    let left = x - trackPos.left
    left = constrainToBounds(left)
    left -= THUMB_RADIUS
    thumbRef.current.style.left = left + 'px'
  }

  const updateValueFromThumbPosition = (clientX) => {
    if (nullCheckboxValue) {
      return
    }

    var trackPos = trackRef.current.getBoundingClientRect()
    let left = clientX - trackPos.left
    left = constrainToBounds(left)
    let hey = left / trackPos.width
    onChange({ target: { value: Math.max(0, Math.min(hey, 1)) + '' } })
  }

  const moveThumbMouse = (e) => {
    updateThumbPosition(e.clientX)
    e.preventDefault()
  }

  const moveThumbTouch = (e) => {
    updateThumbPosition(e.changedTouches[0].pageX)
    e.preventDefault()
  }

  const endMove = (e) => {
    document.removeEventListener('mousemove', moveThumbMouse)
    document.removeEventListener('mouseup', endMove)
    updateThumbPosition(e.clientX)
    updateValueFromThumbPosition(e.clientX)
  }

  const handleTouchEnd = (e) => {
    document.removeEventListener('touchmove', moveThumbTouch)
    updateValueFromThumbPosition(e.changedTouches[0].pageX)
  }

  const handleMouseDown = (e) => {
    updateThumbPosition(e.clientX)
    document.addEventListener('mousemove', moveThumbMouse)
    document.addEventListener('mouseup', endMove)
  }

  const handleTouchStart = (e) => {
    document.addEventListener('touchmove', moveThumbTouch)
  }

  const toggleNull = () => {
    if (nullCheckboxValue === true) {
      setNullCheckboxValue(false)
      onChange({ target: { value: '0' } })
    } else {
      setNullCheckboxValue(true)
      onChange({ target: { value: null } })
      updateThumbPosition(0)
    }
  }

  return (
    <div>
      <Wrapper
        onClick={endMove}
        className={nullCheckboxValue === true ? 'disabled' : ''}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}>
        <Track ref={trackRef}>
          <Thumb ref={thumbRef} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}
                 onTouchEnd={handleTouchEnd}/>
        </Track>
        <TrackOptionWrapper>
          {options.map((o, i) => (
            <TrackOption key={i}>
              {o.label.split('\\n').map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    {item}
                    <br/>
                  </React.Fragment>
                )
              })}
            </TrackOption>
          ))}
        </TrackOptionWrapper>
      </Wrapper>
      {nullable && (
        <Margin>
          <Checkbox onChange={() => toggleNull()} value={nullCheckboxValue}
                    label={intl.formatMessage({ id: 'common.not_relevant' })}/>
        </Margin>
      )}
    </div>
  )
})
