export const insertDynamicPrecautionaryContent = (baseText, dynamicContent) => insertDynamicContent(baseText, dynamicContent, /(\[.*?\])/g)

export const insertDynamicHazardContent = (baseText, dynamicContent) => insertDynamicContent(baseText, dynamicContent, /(<.*?>)/g)

const insertDynamicContent = (baseText, dynamicContent, regexp) => {
  const dynamicSpotList = baseText.match(regexp)
  if (dynamicSpotList === null) {
    return baseText
  }

  if (!dynamicContent) {
    // Remove all placeholders
    for (let i in dynamicSpotList) {
      baseText = baseText.replace(dynamicSpotList[i], '')
    }
    return baseText
  }

  for (let i in dynamicSpotList) {
    if (typeof dynamicContent[i] === 'string') {
      baseText = baseText.replace(dynamicSpotList[i], dynamicContent[i])
    } else {
      baseText = baseText.replace(dynamicSpotList[i], dynamicContent[i].filter((value) => value !== null).join('/'))
    }
  }

  return baseText
}
