import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'

import useGetData from 'Hooks/useGetData'
import { FormContext, FormContextProvider, FormInContext } from 'Components/form'
import { BackLinkButton, Divider } from 'Components/readonly'
import { FlexColumn, PageConstraint, PageHeader } from 'Components/layout'
import { ProgressSpinner } from 'primereact/progressspinner'
import { AuthContext, GrowlContext } from 'Context'
import { useIntl } from 'react-intl'
import { InventoryDetailLocations } from './components/InventoryDetailLocations'
import { InventoryDetailHeader } from './components/header/inventoryDetailHeader'
import { InventoryDetailControls } from './components/header/inventoryDetailControls'
import { DetailHeader } from './components/header'

const DetailWrapper = styled.div`
  h1 {
    padding: 0;
    margin: 0;
  }

  > h3 {
    padding: 0;
    margin: 0 0 15px 0;
  }
`

const InventoryDetailForm = ({ inventory, setInventory, addProduct }) => {
  const intl = useIntl()
  const [isSaving, setIsSaving] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const { formData, getFormChanges, hasFormChanges, setFormData } = useContext(FormContext)
  const [isShowLocationNotDone, setShowLocationNotDone] = useState(false)

  const validate = () => {
    const isValid = formData.locations.every((location) => location.is_done)
    setShowLocationNotDone(!isValid)
    return isValid
  }

  const save = async (data) => {
    if (!hasFormChanges()) {
      displaySuccess(intl.formatMessage({ id: 'common.no_changes' }))
      return true
    }

    setIsSaving(true)
    try {
      const result = await axios.put('/rapi/inventorying/' + data.id, getFormChanges())
      setIsSaving(false)
      setInventory(result.data.data)
      setFormData(result.data.data, { clearFormChanges: true })
      displaySuccess(intl.formatMessage({ id: 'inventory.saved' }))
      return true
    } catch (error) {
      displayError(error)
      setIsSaving(false)
    }
    return false
  }

  const validateAndSave = async () => {
    if (!validate()) {
      return false
    }

    if (hasFormChanges()) {
      return await save(formData)
    }

    return true
  }

  return (
    <FormInContext onSubmit={save}>
      <div>
        <DetailHeader>
          <FlexColumn>
            <InventoryDetailHeader inventory={inventory}/>
            <InventoryDetailControls
              inventory={inventory}
              setInventory={setInventory}
              save={validateAndSave}
              isSaving={isSaving}
              isShowLocationNotDone={isShowLocationNotDone}
              variant="top"
            />
          </FlexColumn>
        </DetailHeader>
        <Divider/>
        <InventoryDetailLocations
          inventoryLocations={inventory.locations}
          addProduct={addProduct}
          validateAllLocations={() => isShowLocationNotDone && validate()}
        />
        <InventoryDetailControls
          inventory={inventory}
          setInventory={setInventory}
          save={validateAndSave}
          isSaving={isSaving}
          isShowLocationNotDone={isShowLocationNotDone}
          variant="bottom"
        />
      </div>
    </FormInContext>
  )
}

export const InventoryDetailPage = () => {
  const { organisation } = React.useContext(AuthContext)
  //const { displayError, displaySuccess } = React.useContext(GrowlContext)

  const intl = useIntl()
  const params = useParams()
  const history = useHistory()
  const [isLoading, inventory, setInventory] = useGetData('/rapi/inventorying/' + params.id, null, [organisation]) // eslint-disable-line

  const addProduct = (addToLocation, product, packages) => {
    const transformedPackages = packages.map((p) => {
      return { ...p, to_be_archived: false }
    })

    const locationIndex = inventory.locations.findIndex((location) => location.id === addToLocation.id)
    // Comparing string and int sometimes so == is needed!
    const chemicalIndex = inventory.locations[locationIndex].chemicals.findIndex((chemical) => chemical.id == product.id) // eslint-disable-line
    if (chemicalIndex === -1) {
      const chemical = {
        id: product.id,
        name: product.name,
        supplier: product.supplier,
        article_number: product.article_number,
        inventory: transformedPackages
      }
      const updatedLocations = [...inventory.locations]
      const updatedChemicals = [...updatedLocations[locationIndex].chemicals, chemical]
      updatedLocations[locationIndex].chemicals = updatedChemicals
      const updatedInventory = { ...inventory, locations: updatedLocations }
      setInventory(updatedInventory)
    } else {
      // Remove rows with identical packaging types
      const filteredPackages = transformedPackages.filter(
        (p1) =>
          !inventory.locations[locationIndex].chemicals[chemicalIndex].inventory.some(
            (p2) => p1.is_bulk === p2.is_bulk && p1.unit.id === p2.unit.id && (p1.is_bulk || p1.packaging_size === p2.packaging_size)
          )
      )
      if (filteredPackages.length > 0) {
        const updatedLocations = [...inventory.locations]
        const updatedChemicals = [...updatedLocations[locationIndex].chemicals]
        updatedLocations[locationIndex].chemicals = updatedChemicals
        const updatedPackages = [...updatedChemicals[chemicalIndex].inventory].concat(filteredPackages)
        updatedChemicals[chemicalIndex].inventory = updatedPackages
        const updatedInventory = { ...inventory, locations: updatedLocations }
        setInventory(updatedInventory)
      }
    }
  }

  return (
    <PageConstraint>
      <DetailWrapper>
        {isLoading ? (
          <PageHeader>
            <p>
              <BackLinkButton
                link
                icon="pi pi-arrow-left"
                label={intl.formatMessage({ id: 'inventory.inventoryings' })}
                onClick={history.goBack}
              />
            </p>
            <ProgressSpinner/>
          </PageHeader>
        ) : (
          inventory && (
            <>
              <PageHeader>
                <p>
                  <BackLinkButton
                    link
                    icon="pi pi-arrow-left"
                    label={intl.formatMessage({ id: 'inventory.inventoryings' })}
                    onClick={history.goBack}
                  />
                </p>
                <FormContextProvider initialFormData={inventory}>
                  <InventoryDetailForm inventory={inventory} setInventory={setInventory} addProduct={addProduct}/>
                </FormContextProvider>
              </PageHeader>
            </>
          )
        )}
      </DetailWrapper>
    </PageConstraint>
  )
}
