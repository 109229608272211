import React from 'react'

import { Dropdown } from 'Components/input'
import { injectIntl } from 'react-intl'

const RoleDropdown = ({ intl, ...props }) => {
  const supportedRoles = [
    { label: intl.formatMessage({ id: 'capabilities.admin' }), value: 'admin' },
    { label: intl.formatMessage({ id: 'capabilities.admin_light' }), value: 'admin_light' },
    { label: intl.formatMessage({ id: 'capabilities.user' }), value: 'user' },
    { label: intl.formatMessage({ id: 'capabilities.not_authorized' }), value: 'not_authorized' },
  ]

  return <Dropdown options={supportedRoles} {...props} />
}

export default injectIntl(RoleDropdown)
