import axios from 'axios'
import colors from 'Components/colors.scss'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import LoginContainer from './login/loginContainer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconSuccess = styled.span`
  &&& {
    font-size: 60px;
    color: ${colors.primary};
  }
`

const IconError = styled.span`
  &&& {
    font-size: 60px;
    color: ${colors.red};
  }
`

export default ({ match }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const approveUser = async () => {
      try {
        await axios.post('/rapi/user/approve/', { id: match.params.userid, token: match.params.token })
        setSuccess(true)
      } catch (error) {
        setError(error.response && error.response.data ? error.response.data.message : error.message)
      }
      setIsLoading(false)
    }
    setSuccess(false)
    setError(null)
    approveUser()
  }, [match.params.token, match.params.userid])

  return (
    <LoginContainer>
      <Container>
        {isLoading && (
          <>
            <h1>
              <FormattedMessage id="auth.approve_user"/>
            </h1>
            <ProgressSpinner/>
          </>
        )}
        {success && (
          <>
            <h1>
              <FormattedMessage id="auth.approve_user_done"/>
            </h1>
            <IconSuccess className="pi pi-check"/>
          </>
        )}
        {error && (
          <>
            <h1>
              <FormattedMessage id="auth.approve_user_error"/>
            </h1>
            <IconError className="pi pi-times"/>
            {error}
          </>
        )}
      </Container>
    </LoginContainer>
  )
}
