import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import colors from '../colors.scss'
import { injectIntl } from 'react-intl'

export const numericKeyfilter = /[\d\\.,]/

export const HiddenInput = (value) => {
  return <input type="hidden" value={value}/>
}

export const TextInput = styled(
  forwardRef(({ ...props }, ref) => {
    const internalRef = useRef(null)

    useImperativeHandle(ref, () => ({
      getCurrent: () => {
        return internalRef.current
      }
    }))

    return <InputText maxLength={props.maxLength || '512'} {...props} ref={internalRef}/>
  })
)`
  &&& {
    width: 100%;
    border-radius: 20px;
    padding: 7px 15px;
    min-width: 0; // https://stackoverflow.com/questions/43314921/strange-input-widths-in-firefox-vs-chrome

    background-color: ${(props) => (props.theme === 'light' && colors.lightWhite) || colors.white};
    border: 2px solid ${(props) => (props.theme === 'light' && colors.lightWhite) || colors.white};
    color: ${(props) => (props.theme === 'light' && colors.black) || colors.black};

    &:hover:not(.p-disabled) {
      border-color: ${colors.primary};
    }

    &.ui-state-error {
      border-color: ${colors.validationFailedFieldMarker};
    }

    &:focus {
      box-shadow: none;
      border-color: ${colors.primary};
    }
  }
`

export const UncontrolledTextInput = styled(({ ...props }) => {
  return <InputText maxLength={props.maxLength || '255'} {...props} />
})`
  &&& {
    width: 100%;
    border-radius: 20px;
    padding: 7px 15px;
    min-width: 0; // https://stackoverflow.com/questions/43314921/strange-input-widths-in-firefox-vs-chrome

    background-color: ${(props) => (props.theme === 'light' && colors.lightWhite) || colors.white};
    border: 2px solid ${(props) => (props.theme === 'light' && colors.lightWhite) || colors.white};
    color: ${(props) => (props.theme === 'light' && colors.black) || colors.black};

    &:hover:not(.p-disabled) {
      border-color: #fff;
    }

    &.ui-state-error {
      border-color: ${colors.validationFailedFieldMarker};
    }

    &:focus {
      box-shadow: none;
      border-color: ${colors.primary};
    }
  }
`

export const TextareaInput = styled(
  forwardRef(({ ...props }, ref) => {
    const internalRef = useRef(null)

    useImperativeHandle(ref, () => ({
      getCurrent: () => {
        return internalRef.current
      }
    }))

    return <InputTextarea maxLength={props.maxLength || '255'} {...props} ref={internalRef}/>
  })
)`
  &&& {
    width: 100%;
    background-color: ${colors.white};
    color: #000;
    border-radius: 20px;
    padding: 10px 20px;
    border: 2px solid #fff;

    &:hover:not(.p-disabled) {
      border-color: #fff;
    }

    &.ui-state-error {
      border-color: ${colors.validationFailedFieldMarker};
    }

    &:focus {
      box-shadow: none;
      border-color: ${colors.primary};
    }
  }
`

const SearchDiv = styled.div`
  position: relative;
  display: inline-block;

  ${TextInput}${TextInput}${TextInput} {
    padding: 8px 35px 7px 15px;
    width: 100%;
  }

  i {
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 1.75em;
    color: ${colors.dividerGray};
    transition: 0.1s color;
  }

  i:hover {
    color: ${colors.primary};
  }

  @media screen and (max-width: 640px) {
    display: block;
  }
`

export const SearchInput = injectIntl(({ onChange, value, className, intl, ...props }) => {
  return (
    <SearchDiv className="search-container">
      <TextInput
        className="search-input"
        maxLength={props.maxLength || '255'}
        placeholder={intl.formatMessage({ id: 'common.find' })}
        onChange={onChange}
        value={value}
        {...props}
      />
      <i className="pi pi-search"/>
    </SearchDiv>
  )
})

export const SearchInputClickable = injectIntl(({ onChange, onClick, value, className, intl, ...props }) => {
  return (
    <SearchDiv className="search-container">
      <TextInput
        className="search-input"
        maxLength={props.maxLength || '255'}
        placeholder={intl.formatMessage({ id: 'common.find' })}
        onChange={onChange}
        value={value}
        {...props}
      />

      <i className="pi pi-search clickable" onClick={onClick}/>
    </SearchDiv>
  )
})

const lineHeight = 19
const StyledTextarea = styled.textarea`
  resize: none;
  font-size: 16px;
  line-height: ${lineHeight}px;
  overflow: auto;
  height: auto;
  color: ${colors.black};
  width: 100%;
  background-color: ${colors.white};
  border-radius: 20px;
  border: 2px solid ${colors.white};
  padding: 10px 20px;

  &:hover:not(.p-disabled) {
    border-color: ${colors.white};
  }

  &.ui-state-error {
    border-color: ${colors.validationFailedFieldMarker};
  }

  &:focus {
    box-shadow: none;
    border-color: ${colors.primary};
    outline: none;
  }
`

export const ExpandingTextArea = ({ placeholder, minRows = 2, maxRows, onChange, value, ...rest }) => {
  const internalRef = useRef(null)
  const handleChange = (event) => {
    //Propagate new current value to parent component
    onChange(event)
  }

  useEffect(() => {
    const recalculateRows = (target) => {
      target.rows = minRows // reset number of rows in textarea

      const currentRows = ~~(target.scrollHeight / lineHeight) - 1

      const rows = Math.max(currentRows < maxRows ? currentRows : maxRows, minRows)

      target.rows = rows
    }
    if (internalRef.current && value) {
      recalculateRows(internalRef.current)
    }
  }, [value, maxRows, minRows])

  return <StyledTextarea rows={minRows} value={value} placeholder={placeholder} onChange={handleChange}
                         ref={internalRef} {...rest} />
}
