import React from 'react'
import styled from 'styled-components'

import colors from '../colors.scss'

const FieldGroupContainer = styled.div`
  color: ${colors.white};
  padding: 30px;
  display: flex;
  flex: 1 0 auto;

  > * {
    margin-right: 60px;

    &:last-child {
      margin: 0;
    }
  }
`

const FieldGroupColumn = styled.div`
  flex: 1 1 auto;

  > * {
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }
  }
`

export const FieldGroup = styled(({ className, children, columns }) => {
  if (!columns) {
    columns = 1
  }

  children = React.Children.toArray(children)

  const fieldgroupColumns = []
  const size = Math.ceil(children.length / columns)
  let start = 0
  for (var i = 0; i < columns; i++) {
    const end = start + size >= children.length ? children.length : start + size
    fieldgroupColumns.push(<FieldGroupColumn key={start}>{children.slice(start, end)}</FieldGroupColumn>)
    start = end
  }
  return <FieldGroupContainer className={className}>{fieldgroupColumns}</FieldGroupContainer>
})``
