import React, { useContext, useState } from 'react'
import axios from 'axios'

import { GrowlContext } from 'Context'
import { Field, FieldGroup, Form } from 'Components/form'
import { Button, Dropdown, TextareaInput } from 'Components/input'
import { Section, Spacer } from 'Components/layout'
import { ModalActionsFooter } from 'Components/readonly'

import { useIntl } from 'react-intl'

const url = '/rapi/admin/startpage'

const EditStartPageContentDialog = ({ modalController, onUpdate, onCreate }) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)

  const content = modalController.data

  const isNewContent = content === null

  const onKeyPress = (event) => {
    var code = event.keyCode || event.which
    if (code === 13) {
      //13 is the enter keycode
      onSubmit()
    }
  }

  const onSubmit = async (contentData) => {
    setIsLoading(true)

    let result = null
    try {
      if (isNewContent) {
        result = await axios.post(url, contentData)
        onCreate(result.data.content)
      } else {
        result = await axios.put(url + '/' + content.id, contentData)
        onUpdate(result.data.content)
      }
    } catch (error) {
      displayError(error)
      setIsLoading(false)
      return
    }

    displaySuccess(result.data.message)
    modalController.close()
    setIsLoading(false)
  }

  const typeOptions = [
    { value: 'news', label: 'Nyhetsinledning' },
    { value: 'welcome', label: 'Välkomsttext' }
  ]

  return (
    <Form onSubmit={onSubmit}>
      <Section>
        <FieldGroup>
          <Field
            id="type"
            label={intl.formatMessage({ id: 'common.type' })}
            Component={Dropdown}
            options={typeOptions}
            initialValue={(content && content.type) || null}
            required={true}
          />
          <Field
            id="text_sv"
            className="modal-onshow-focus"
            label={intl.formatMessage({ id: 'admin.content_sv' })}
            Component={TextareaInput}
            maxLength="170"
            rows="3"
            onKeyPress={onKeyPress}
            required={true}
            initialValue={(content && content.text_sv) || ''}
          />
          <Field
            id="text_en"
            label={intl.formatMessage({ id: 'admin.content_en' })}
            Component={TextareaInput}
            rows="3"
            maxLength="170"
            onKeyPress={onKeyPress}
            required={true}
            initialValue={(content && content.text_en) || ''}
          />
          <Field
            id="text_no"
            label={intl.formatMessage({ id: 'admin.content_no' })}
            Component={TextareaInput}
            rows="3"
            maxLength="170"
            onKeyPress={onKeyPress}
            required={true}
            initialValue={(content && content.text_no) || ''}
          />
          <Field
            id="text_dk"
            label={intl.formatMessage({ id: 'admin.content_dk' })}
            Component={TextareaInput}
            rows="3"
            maxLength="170"
            onKeyPress={onKeyPress}
            required={true}
            initialValue={(content && content.text_dk) || ''}
          />
        </FieldGroup>
      </Section>
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Form>
  )
}

export default EditStartPageContentDialog
