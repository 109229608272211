import React from 'react'

import { CenterInfoPanel } from 'Components/layout'
import { Button, Panel } from 'Components/readonly'

const getTexts = (locale, error) => {
  if (error === 503) {
    if (locale === 'sv') {
      return {
        title: 'Systemet är just nu nere för underhåll.',
        link: 'Försök igen'
      }
    } else {
      return {
        title: 'Something went wrong.',
        link: 'Try again'
      }
    }
  } else {
    if (locale === 'sv') {
      return {
        title: 'Något gick fel.',
        link: 'Försök igen'
      }
    } else {
      return {
        title: 'Something went wrong.',
        link: 'Try again'
      }
    }
  }
}

const MaintenancePage = ({ locale, error }) => {

  const texts = getTexts(locale, error)

  return (
    <CenterInfoPanel>
      <Panel>
        <h3>{texts.title}</h3>
        <p>{texts.text}</p>
        <Button label={texts.link} onClick={() => window.location.href = '/'}/>
      </Panel>
    </CenterInfoPanel>
  )
}

export default MaintenancePage
