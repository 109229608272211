import React, { useContext, useMemo, useState } from 'react'
import Axios from 'axios'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Column } from 'primereact/column'
import { format, parse } from 'date-fns'

import { FlexRowCenterResponsive, Spacer } from 'Components/layout'
import { Button, DataTable, Modal } from 'Components/readonly'

import { InventoryStatusBadge } from './inventoryStatusBadge.js'
import { useCapability } from 'Components/capabilities.js'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import { GrowlContext } from 'Context/index.js'
import useModalController from 'Hooks/useModalController.js'

const InventoryDataTable = styled(DataTable)`
  &.p-datatable .p-datatable-tbody > tr > td .p-row-toggler {
    width: 20px;
    margin: 0 10px 0 0;
  }

  .p-rowgroup-header-name {
    display: inline-block;
    width: calc(100% - 30px);
    vertical-align: middle;
  }
`

const InventoryColumnContainer = styled.div`
  display: inline-block;
  width: 100%;

  @media screen and (max-width: 640px) {
    button {
      flex: 1 0 100%;
    }
  }
`
const InventoryColumnContent = styled(FlexRowCenterResponsive)`
  @media screen and (max-width: 640px) {
    > *:not(:last-child) {
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 640px) {
    > *:not(:last-child) {
      margin-right: 10px;
    }

    button {
      margin-bottom: 0 !important;
    }
  }
`

const ToolPanelRight = styled.div`
  &&& {
    white-space: nowrap;

    > * {
      margin-left: 10px;
    }

    @media screen and (max-width: 640px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      white-space: normal;

      > * {
        margin: 10px 0 0 0;
      }
    }
  }
`

export const InventoryList = ({
                                isLoading,
                                allowEdit,
                                list,
                                updateList,
                                deleteList,
                                inventoryStartModalController,
                                detailUrl
                              }) => {
  const intl = useIntl()
  const { displaySuccess } = useContext(GrowlContext)
  const [deleteInventoryModalController] = useModalController({ title: intl.formatMessage({ id: 'inventory.delete' }) })
  const [expandedRows, setExpandedRows] = useState(null)

  // Transform list to view
  const renderList = useMemo(() => {
    const activeInventoryLocations = []
    list &&
    list.forEach((inventory) => {
      inventory.inventorying_locations &&
      inventory.inventorying_locations.forEach((inventoryLocation) => {
        activeInventoryLocations.push({
          id: inventory.id,
          composite: format(parse(inventory.start_at), 'YYYY-MM-DD') + inventory.id,
          start_date: format(parse(inventory.start_at), 'YYYY-MM-DD'),
          responsible: inventory.responsible_user_name,
          responsible_user_id: inventory.responsible_user_id,
          status: inventory.status,
          location: inventoryLocation.location.name,
          locations: inventory.inventorying_locations,
          product_amount: inventory.status === 'PLANNED' ? inventoryLocation.org_chemicals_count : inventoryLocation.chemicals_count,
          completed: inventoryLocation.is_done ? intl.formatMessage({ id: 'common.yes' }) : intl.formatMessage({ id: 'common.no' })
        })
      })
    })

    return activeInventoryLocations
  }, [list, intl])

  const history = useHistory()
  const manageTagsCapability = useCapability('CHEMICAL_MANAGE_TAGS')

  const deleteInventorying = async (row) => {
    let result = null
    result = await Axios.delete('/rapi/inventorying/' + row.id)
    displaySuccess(result.data.message)
    deleteList(row)
  }

  const inventoryMainBody = (row) => {
    return (
      <InventoryColumnContainer>
        <InventoryColumnContent id={'inventorying-id-' + row.id}>
          <span>{row.start_date}</span>
          <InventoryStatusBadge status={row.status}/>
          <span>
            {' '}
            <strong>
              <FormattedMessage id="common.responsible"/>:
            </strong>{' '}
            {row.responsible}
          </span>
          <Spacer/>
          <ToolPanelRight>
            {allowEdit && row.status === 'PLANNED' && manageTagsCapability && (
              <>
                <Button
                  icon="pi pi-trash"
                  label={intl.formatMessage({ id: 'common.delete' })}
                  onClick={() => {
                    deleteInventoryModalController.open({ data: row })
                  }}
                />
                <Button
                  icon="pi pi-pencil"
                  label={intl.formatMessage({ id: 'common.edit' })}
                  onClick={(event) => {
                    inventoryStartModalController.open({
                      title: intl.formatMessage({ id: 'inventory.edit' }),
                      data: row
                    })
                  }}
                />
              </>
            )}
            {row.status !== 'PLANNED' && (
              <Button
                primary
                icon="pi pi-arrow-circle-right"
                onClick={() => onRowClick(row)}
                label={intl.formatMessage({ id: 'common.show' })}
              />
            )}
          </ToolPanelRight>
        </InventoryColumnContent>
      </InventoryColumnContainer>
    )
  }

  const onRowClick = (row) => {
    if (row.status !== 'PLANNED') {
      history.push(detailUrl + row.id)
    }
  }

  const columns = [
    <Column key="composite" field="startdate" header={intl.formatMessage({ id: 'inventory.start-date' })}
            style={{ width: '40%' }}/>,
    <Column key="location" field="location" header={intl.formatMessage({ id: 'common.location' })}/>,
    <Column
      key="product_amount"
      field="product_amount"
      header={intl.formatMessage({ id: 'inventory.product-amount' })}
      style={{ width: '1%' }}
    />,
    <Column key="completed" field="completed" header={intl.formatMessage({ id: 'inventory.done' })}
            style={{ width: '25%' }}/>
  ]

  return (
    <>
      <Modal
        modalController={deleteInventoryModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="inventory.delete-planned-confirm-text"
        confirmTextId="common.delete"
        icon="pi pi-trash"
        onConfirm={deleteInventorying}
      />

      <InventoryDataTable
        className="flextable"
        value={renderList}
        sortField="composite"
        sortOrder={1}
        groupField="composite"
        loading={isLoading}
        rowGroupMode="subheader"
        rowGroupHeaderTemplate={inventoryMainBody}
        rowGroupFooterTemplate={() => {
        }}
        expandableRowGroups={true}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowClassName={(row) => {
          return { disabled: row.status === 'PLANNED' }
        }}>
        {columns}
      </InventoryDataTable>
    </>
  )
}
