import React, { useEffect, useRef } from 'react'
import { TextInput } from 'Components/input'

export const InlineTextInput = ({ value, onChange, onBlur, onKeyDown, ...props }) => {
  const textFieldRef = useRef()

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.getCurrent().element.select()
    }
  }, [textFieldRef])

  return <TextInput ref={textFieldRef} type="text" value={value} onChange={onChange} onBlur={onBlur}
                    onKeyDown={onKeyDown}/>
}
