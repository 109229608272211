import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import axios from 'axios'
import ObjectUtils from 'primereact/components/utils/ObjectUtils'

import useGetData from 'Hooks/useGetData'
import { Button, Column, DataTable, dateBody, Modal } from 'Components/readonly'
import modalContentConfirm from './modalContentComponents/modalContentConfirm'
import useModalController from 'Hooks/useModalController'
import { GrowlContext } from 'Context'

const ChemicalPreviousSdsView = ({ productId, previousOnly, showDeleteButtons, showDownloadButtons, onSelect }) => {
  const intl = useIntl()
  const [deleteSdsModalController] = useModalController({ title: intl.formatMessage({ id: 'common.delete' }) })
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const [sdsListLoading, sdsList, setSdsList] = useGetData('/rapi/chemicals/' + productId + '/sds/all', [], [], (sdsList) => {
    if (previousOnly) {
      sdsList = sdsList.filter((sds) => sds.status === 'INACTIVE')
    }
    return sdsList.sort((data1, data2) => sdsDateSortFn(data1, data2, { field: 'sds_date', order: -1 }))
  })

  const langBody = (row, column) => row[column.field] && intl.formatMessage({ id: 'lang.' + row[column.field] })

  const reasonBody = (row, column) => {
    const sdsReasonLabels = {
      ISSUED: intl.formatMessage({ id: 'chemicalsds.ISSUED' }),
      REVISED: intl.formatMessage({ id: 'chemicalsds.REVISED' }),
      CHANGED: intl.formatMessage({ id: 'chemicalsds.CHANGED' })
    }
    const value = row[column.field]
    return value && sdsReasonLabels[value] ? sdsReasonLabels[value] : ''
  }

  const sdsDateSortFn = (data1, data2, event) => {
    const value1 = ObjectUtils.resolveFieldData(data1, event.field);
    const value2 = ObjectUtils.resolveFieldData(data2, event.field);
    let result = null;
    if (value1 == null && value2 != null) {
      result = 1;
    } else if (value1 != null && value2 == null) {
      result = -1;
    } else if (value1 == null && value2 == null) {
      result = 0;
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0
    }
    return result * event.order
  }

  const sdsDateSort = (event) => {
    return [...sdsList].sort((data1, data2) => sdsDateSortFn(data1, data2, event))
  }

  const columns = [
    <Column key="status" field="status" header={intl.formatMessage({ id: 'common.status' })} sortable={true}/>,
    <Column key="language" field="language" header={intl.formatMessage({ id: 'common.language' })} body={langBody}
            sortable={true}/>,
    <Column key="reason" field="reason" header={intl.formatMessage({ id: 'chemicalsds.reason' })} body={reasonBody}
            sortable={true}/>,
    <Column key="sds_date" field="sds_date" header={intl.formatMessage({ id: 'common.date' })} body={dateBody}
            sortable={true} sortFunction={sdsDateSort}/>
  ]

  if (showDownloadButtons) {
    const getSdsUrl = (row) => `/rapi/chemicals/sds/${row.id}/download`
    const downloadBody = (row) => <Button primary icon="pi pi-download" onClick={() => window.open(getSdsUrl(row))}/>
    columns.push(<Column key="download" header={intl.formatMessage({ id: 'sds.download' })} body={downloadBody}
                         style={{ width: '10%' }}/>)
  }

  if (showDeleteButtons) {
    const deleteBody = (row) => (
      <>
        {row.status !== 'ACTIVE' &&
          <Button primary icon="pi pi-trash" onClick={() => deleteSdsModalController.open({ data: row.id })}/>}
      </>
    )
    columns.push(<Column key="delete" header={intl.formatMessage({ id: 'common.delete' })} body={deleteBody}
                         style={{ width: '10%' }}/>)
  }

  const handleDelete = async (sdsId) => {
    try {
      await axios.put('/rapi/chemicals/sds/' + sdsId + '/reject')
      displaySuccess(intl.formatMessage({ id: 'sds.sds-deleted' }))
      setSdsList((list) => list.filter((s) => s.id !== sdsId))
    } catch (error) {
      displayError(error)
    }
  }

  return (
    <>
      <Modal
        modalController={deleteSdsModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="sds.remove-sds"
        confirmTextId="common.delete"
        onConfirm={handleDelete}
      />
      <DataTable
        className={classNames('flextable', { 'clickable-rows': onSelect })}
        value={sdsList}
        paginator={true}
        rows={10}
        loading={sdsListLoading}
        onRowClick={(row) => {
          if (onSelect) {
            onSelect(row.data)
          }
        }}>
        {columns}
      </DataTable>
    </>
  )
}

export default ChemicalPreviousSdsView
