import React from 'react'
import { SelectButton as PrimeSelectButton } from 'primereact/selectbutton'
import colors from '../colors.scss'

import styled from 'styled-components'

export const SelectButton = styled(({ ...props }) => {
  return <PrimeSelectButton {...props} />
})`
  &&& {
    display: flex;

    & .p-button {
      display: flex;
      justify-content: center;

      .p-button-text {
        align-self: center;
        @media screen and (max-width: 641px) {
          font-size: 12px;
        }
      }

      padding: 2px 18px;

      &:first-child {
        border-radius: 20px 0 0 20px;
      }

      &:last-child {
        border-radius: 0 20px 20px 0;
      }

      &:only-child {
        border-radius: 20px;
      }

      background-color: ${(props) => (props.theme === 'light' && colors.lightWhite) || colors.lighterBackgroundColor};
      border-color: ${(props) => (props.theme === 'light' && colors.lightWhite) || colors.lighterBackgroundColor};
      color: ${(props) => (props.theme === 'light' && colors.lightBlack) || colors.text};

      &:focus,
      &.p-focus {
        box-shadow: none;
        border: 1px solid ${colors.lightestBackground} !important;
      }

      &:not(.p-highlight):hover {
        background-color: ${(props) => (props.theme === 'light' && colors.lightHover) || colors.white};
        border-color: ${(props) => (props.theme === 'light' && colors.lightHover) || colors.white};
        color: ${(props) => (props.theme === 'light' && colors.lightBlack) || colors.lightBlack};
      }

      &.p-highlight {
        &,
        &:hover:not(.p-disabled) {
          background-color: ${colors.primary};
          border-color: ${colors.primary};
          color: ${colors.white};
        }
      }

      .p-button-text {
        padding: 7px 0;
      }
    }

    @media screen and (min-width: 641px) {
      && .p-button {
        min-width: 120px;
      }
    }
  }
`
