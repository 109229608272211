import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Button } from '../readonly'
import colors from '../colors.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import useGetData from 'Hooks/useGetData'

const Entry = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;

  > div {
    flex: 1 0 1%;
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 30px;
    height: 30px;
    margin-bottom: 0;
  }

  padding: 10px 20px;
`

const EntryContainer = styled.div`
  &.edit {
    background-color: ${colors.darkerBackgroundColor};
    padding: 10px;
  }
`

const Entries = styled.div`
  margin-top: 10px;

  ${EntryContainer}:nth-child(odd) {
    background-color: ${colors.lightBackground};
  }
`

const HygieneThresholdValuesTable = styled.table`
  width: 100%;
  padding: 0px 15px 10px;

  td,
  th {
    border-collapse: collapse;
    text-align: left;
    padding: 5px;

    &.type {
      width: 15%;
    }

    &.path {
      width: 25%;
    }
    &.ppm,
    &.mg_m3 {
      width: 10%;
    }
    &.other {
      width: 40%;
    }
  }
`

export const HygieneThresholdsList = ({ id, value, list, language, productId, onChange, isLoading }) => {

  const [hglisLoading, hygieneThresholdsListFromAPI] = useGetData(
    '/rapi/ingredienthygienethresholds?lang=' + language + "&productId=" + productId + '&chemical_ingredients=' + (value || []).map((ing) => ing.ingredient_variant.id),
    [],
    [],
    undefined,
    false
  )
  const [hygieneThresholdsList, setHygieneThresholdsList] = useState(null)

  const transformThresholds = (list) => {
    return Object.values(
      list.reduce((acc, item) => {
        const { cas_id, name, ...rest } = item
        if (!acc[cas_id]) {
          acc[cas_id] = { cas_id, name, hygieneThresholds: [] }
        }
        acc[cas_id].hygieneThresholds.push(item)
        return acc
      }, {})
    )
  }

  useEffect(() => {
    if(hygieneThresholdsListFromAPI && hygieneThresholdsListFromAPI.length > 0) {
      setHygieneThresholdsList(transformThresholds(hygieneThresholdsListFromAPI))
    } else {
      //console.log(hygieneThresholdsListFromAPI);
    }
  }, [hygieneThresholdsListFromAPI])

  if (hglisLoading) {
    return (
      <>
        <FormattedMessage id={'hygiene-thresholds.loadingList'} />
      </>
    )
  }

  return (
    <div id={id}>
      <Entries>
        {hygieneThresholdsList &&
          hygieneThresholdsList.map((entry, index) => {
            if (!entry.hygieneThresholds || entry.hygieneThresholds === 0) return null
            return (
              <EntryContainer key={index}>
                <Entry>
                  <div>{`${entry.name} (${entry.cas_id})`}</div>
                </Entry>
                <HygieneThresholdValuesTable>
                  <thead>
                    <tr>
                      <th className="path">
                        <FormattedMessage id={'hygiene-thresholds.exposure_path'} />
                      </th>
                      <th className="type">
                        <FormattedMessage id={'common.type'} />
                      </th>
                      <th className="ppm">
                        <FormattedMessage id={'hygiene-thresholds.ppm'} />
                      </th>
                      <th className="mg_m3">
                        <FormattedMessage id={'hygiene-thresholds.mg_m3'} />
                      </th>
                      <th className="other">
                        <FormattedMessage id={'hygiene-thresholds.other-unit'} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {entry.hygieneThresholds && entry.hygieneThresholds.length > 0 && (
                      <>
                        {entry.hygieneThresholds.map((threshold) => (
                          <tr key={`${entry.type}-${entry.cas_id}-${entry.name}`}>
                            <>
                              <td className="path">
                                <FormattedMessage id={'hygiene-thresholds.' + threshold.exposure_path} />
                              </td>
                              <td className="type">
                                <FormattedMessage id={'hygiene-thresholds.' + threshold.type} />
                              </td>
                              <td className="ppm">{threshold.values.ppm ?? '-'}</td>
                              <td className="mg_m3">{threshold.values.mg_m3 ?? '-'}</td>

                              <td className="other">
                                {Object.keys(threshold.values.other).length === 0 ? "-" : Object.keys(threshold.values.other).reduce((acc, key, index, array) => {
                                  const span = (
                                    <span>
                                      {threshold.values.other[key]} <FormattedMessage id={`hygiene-thresholds.${key}`} />
                                    </span>
                                  )
                                  // Add a separating slash for trailing items
                                  if (index < array.length - 1) {
                                    acc.push(span, ' / ')
                                  } else {
                                    acc.push(span)
                                  }
                                  return acc
                                }, [])}
                              </td>
                            </>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </HygieneThresholdValuesTable>
              </EntryContainer>
            )
          })}
      </Entries>
    </div>
  )
}
