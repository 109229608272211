import React, { useEffect, useState, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from "axios";
import useGetData from "Hooks/useGetData";
import { PageConstraint, PageHeader, TableContainer } from 'Components/layout'
import { Button, SearchInput } from "Components/input";
import { Modal, ToolPanel, DataTable, Column } from "Components/readonly";
import { globalSearch } from 'Utils/search'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import CreateQrModal from './components/CreateQrModal'
import ReadQrModal from './components/ReadQrModal'
import useModalController from 'Hooks/useModalController'

const QrCodesPage = () => {
  const intl = useIntl()
  const [isLoading, qrCodeList, setQrCodeList] = useGetData('/rapi/qrCodes', [], [])
  const [rows, setRows] = useState(localStorage.hasOwnProperty('numChemicalRows') ? parseInt(localStorage.getItem('numChemicalRows')) : 5)
  const [modalControllerEditQrCode] = useModalController({title: intl.formatMessage({id: 'qr-codes.title-single'})})
  const [modalControllerReadQrCode] = useModalController({title: intl.formatMessage({id: 'qr-codes.title-single'})})
  const [selectedData, setSelectedData] = useState({});
  const [hideProductModalController] = useModalController({title: intl.formatMessage({id: 'common.delete'})})
  const [inputFilterLoc, setInputFilterLoc] = useState('');
  const [inputFilterProd, setInputFilterProd] = useState('');
  const [filteredList, setFilteredList] = useState({});
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);


  /**
   * Change the number of total table row that will be showed in table list
   */
  useEffect(() => {
    localStorage.setItem("numChemicalRows", rows);
  }, [rows]);

  /**
   * Will filter structure of the get data for table list
   * @param search
   * @param list
   * @returns {*[]}
   */
  const filterList = useCallback((list, search) => {
    let data = [];
    if (typeof list === "object") list.forEach((row, key) => {
      let products = [];
      row.pivot.forEach((obj) => products.push(obj?.sds?.[0]?.user_language_variant?.[0]?.name ?? "NaN"));
      data.push({
        increment: (key + 1),
        id: row.id,
        location_id: row?.location?.id,
        location_name: (row.location_name ?? intl.formatMessage({id: 'qr-codes.no-location'})),
        location: (row.location_name ?? ""),
        products: products,
        productSearch: products.join(","),
        productIds: row.products,
        created_at: row.created_at
      });
    });

    // Search filter the list
    if (typeof search === "string" && search.length > 0) {
      data = globalSearch([...data], search, ['location_name', 'productSearch']);
    }
    return data;
  }, []);

  /**
   * Delete QR code from the active select choices
   * @returns {Promise<void>}
   */
  const deleteQrCode = async (row) => {
    try {
      const response = await axios.delete('/rapi/qrCodes/' + row.id);
      setQrCodeList(response.data);
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  /**
   * Create the product column
   * @param row
   * @returns {JSX.Element|string}
   */
  const productColumn = (row) => {
    if ((row.products.length > 0)) {
      return <>
        <div className="flex">
          <span className="truncate">{row.products.slice(0, 8).join(", ")}</span>
          {row.products.length > 1 && (
            <span>({row.products.length})</span>
          )}
        </div>
      </>
    }
    return intl.formatMessage({id: 'qr-codes.all-products'});
  }

  /**
   * Will open modal for edit QR-Code
   * @param event
   */
  const editQrCode = (event) => {
    setSelectedData(event.data)
    modalControllerEditQrCode.open()
  }

  /**
   * Will create a button that will open the QR-code page
   * @param row
   * @returns {JSX.Element}
   */
  const visitQrCodePageBtn = (row) => {
    const qrUrl = (publicUrl.origin ?? publicUrl.href) + '#/user-area/qr/' + row.id;
    return <a href={qrUrl} target="_blank" rel="noopener noreferrer">
      <Button
        icon="pi pi-external-link"
        style={{margin: 0}}
        label={intl.formatMessage({id: 'common.visit'})}
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    </a>
  };

  /**
   * Will create an open edit modal button for table column
   * @param row
   * @returns {JSX.Element}
   */
  const showQrCodeModalBtn = (row) => (
    <Button
      primary
      style={{margin: 0}}
      icon="pi pi-arrow-circle-right p-c p-button-icon-left"
      label={intl.formatMessage({id: 'common.show'})}
      onClick={(event) => {
        event.stopPropagation();
        setSelectedData(row)
        modalControllerReadQrCode.open()
      }}
    />
  );

  /**
   * Will create a delete button for table column
   * @param row
   * @returns {JSX.Element}
   */
  const deleteQrCodeBtn = (row) => (
    <Button
      primary
      icon="pi pi-trash"
      onClick={(event) => {
        event.stopPropagation();
        hideProductModalController.open({data: row});
      }}
    />
  );


  /**
   * The table component
   * @param listData
   * @param children
   * @returns {JSX.Element}
   */
  const GetTableComponent = ({listData, children}) => (
    <TableContainer className="fix-ie-table-width">
      {qrCodeList && (
        <DataTable
          responsive={true}
          className="clickable-rows"
          value={listData}
          loading={isLoading}
          rows={rows}
          paginator={true}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          onRowClick={editQrCode}
          onPage={(e) => {
            setRows(e.rows)
          }}
        >
          {children}

          <Column style={{width: '10%'}} key="created_at" field="created_at"
                  header={intl.formatMessage({id: 'common.created'})} sortable={true}/>
          <Column
            style={{width: '10%', textAlign: "center"}}
            key="qr_code"
            field="qr_code"
            body={showQrCodeModalBtn}
            header={""}
            sortable={false}
          />
          <Column
            style={{width: '10%', textAlign: "center"}}
            key="visit_qr_code_page"
            field="visit_qr_code_page"
            body={visitQrCodePageBtn}
            header={""}
            sortable={false}
          />
          <Column
            style={{width: '10%', textAlign: "center"}}
            key="delete"
            field="delete"
            body={deleteQrCodeBtn}
            sortable={false}
          />
        </DataTable>
      )}
    </TableContainer>
  );

  /**
   * Update list on changes
   */
  useEffect(() => {
    setFilteredList({
      location: filterList(qrCodeList.location, inputFilterLoc),
      products: filterList(qrCodeList.products, inputFilterProd)
    });
  }, [qrCodeList, inputFilterLoc, inputFilterProd]);

  return (
    <>
      <h1>
        <FormattedMessage id="qr-codes.title"/>
      </h1>
      <PageConstraint>
        <Modal
          modalController={modalControllerEditQrCode}
          modalData={selectedData}
          setQrCodeList={setQrCodeList}
          ContentComponent={CreateQrModal}
        />
        <Modal
          modalController={modalControllerReadQrCode}
          modalData={selectedData}
          ContentComponent={ReadQrModal}
        />
        <Modal
          modalController={hideProductModalController}
          ContentComponent={modalContentConfirm}
          contentTextId="qr-codes.confirm-delete"
          confirmTextId="qr-codes.confirm"
          onConfirm={deleteQrCode}
        />
        <PageHeader>
          <h1>{intl.formatMessage({id: 'common.location'})}</h1>
          <ToolPanel>
            <SearchInput value={inputFilterLoc} onChange={(event) => {
              setInputFilterLoc(event.target.value)
            }}/>
            <Button
              primary
              icon="pi pi-plus p-c p-button-icon-left"
              label={intl.formatMessage({id: 'qr-codes.create-qr-code'})}
              onClick={() => {
                // Pass in a empty location_id to tell modal that I want to create QR from location
                setSelectedData({location_id: 0});
                modalControllerEditQrCode.open()
              }}
            />
          </ToolPanel>
        </PageHeader>
        <GetTableComponent listData={filteredList.location}>
          <Column style={{width: '40%'}}
                  key="location_name"
                  field="location_name"
                  header={intl.formatMessage({id: 'common.location'})}
                  sortable={true}
          />
        </GetTableComponent>
      </PageConstraint>

      <PageConstraint>
        <PageHeader>
          <h1>{intl.formatMessage({id: 'chemicals.product'})}</h1>
          <ToolPanel>
            <SearchInput value={inputFilterProd} onChange={(event) => {
              setInputFilterProd(event.target.value)
            }}/>
            <Button
              primary
              icon="pi pi-plus p-c p-button-icon-left"
              label={intl.formatMessage({id: 'qr-codes.create-qr-code'})}
              onClick={() => {
                // Pass in empty object to tell modal that I want to create QR from products
                setSelectedData({})
                modalControllerEditQrCode.open()
              }}
            />
          </ToolPanel>
        </PageHeader>
        <GetTableComponent listData={filteredList.products}>
          <Column
            style={{width: '40%'}}
            key="products" field="products"
            header={intl.formatMessage({id: 'chemicals.product'})} sortable={true}
            body={productColumn}
          />
        </GetTableComponent>
      </PageConstraint>
    </>
  );
};

export default QrCodesPage;
