import React from 'react'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { format, parse } from 'date-fns'

import { Datepicker, RichTextArea, TextInput } from 'Components/input'
import { Field, FieldGroup, Form } from 'Components/form'
import { GrowlContext } from 'Context'
import { Section, Spacer } from 'Components/layout'
import { Button, ModalActionsFooter } from 'Components/readonly'

export default ({ modalController, onCreate, onUpdate }) => {
  const intl = useIntl()
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [isLoading, setIsLoading] = React.useState(false)

  const filter = modalController.data || {
    name: '',
    applied_from: '',
    revise_by: '',
    binding_from: '',
    description: '',
    viewname: ''
  }

  const handleSubmit = async (data) => {
    setIsLoading(true)
    try {
      data.applied_from = format(data.applied_from, 'YYYY-MM-DD')
      data.revise_by = format(data.revise_by, 'YYYY-MM-DD')
      data.binding_from = format(data.binding_from, 'YYYY-MM-DD')
      let result = null
      if (filter.id) {
        result = await axios.put('/rapi/admin/revisionsubscription/' + filter.id, data)
        result.data.data.applied_from = parse(filter.applied_from)
        result.data.data.revise_by = parse(filter.revise_by)
        result.data.data.binding_from = parse(filter.binding_from)
        onUpdate(result.data.data)
      } else {
        result = await axios.post('/rapi/admin/revisionsubscription', data)
        onCreate(result.data.data)
      }
      displaySuccess(result.data.message)
      modalController.close()
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  const nameLabel = intl.formatMessage({ id: 'common.name' })
  const appliedFromLabel = intl.formatMessage({ id: 'sds.revision-subscription.applied-from' })
  const reviseByLabel = intl.formatMessage({ id: 'sds.revision-subscription.revise-by' })
  const bindingFromLabel = intl.formatMessage({ id: 'sds.revision-subscription.binding-from' })
  const descriptionLabel = intl.formatMessage({ id: 'common.description' })
  const viewnameLabel = intl.formatMessage({ id: 'sds.revision-subscription.viewname' })

  return (
    <Form onSubmit={handleSubmit}>
      <Section>
        <FieldGroup>
          <Field id="name" Component={TextInput} initialValue={filter.name} label={nameLabel}/>
          <Field id="applied_from" Component={Datepicker} initialValue={filter.applied_from} label={appliedFromLabel}/>
          <Field id="revise_by" Component={Datepicker} initialValue={filter.revise_by} label={reviseByLabel}/>
          <Field id="binding_from" Component={Datepicker} initialValue={filter.binding_from} label={bindingFromLabel}/>
          <Field id="description" Component={RichTextArea} initialValue={filter.description} minRows={5}
                 label={descriptionLabel}/>
          <Field id="viewname" Component={TextInput} initialValue={filter.viewname} label={viewnameLabel}/>
        </FieldGroup>
      </Section>
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Form>
  )
}
