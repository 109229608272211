import React, { useContext, useState } from 'react'
import axios from 'axios'

import { Field, Form } from 'Components/form'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'

import { injectIntl } from 'react-intl'
import Packages from 'Components/combined/packages'
import validateInventory from 'Utils/validateInventory'

const EditProductForLocationDialog = ({ modalController, onSuccess, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displaySuccess, displayError } = useContext(GrowlContext)

  const { product, location, inventory } = modalController.data

  const onSubmit = async (formData) => {
    setIsLoading(true)
    try {
      const response = await axios.post('/rapi/chemicals/updateforlocation', {
        product_id: product.id,
        location_id: location.id,
        inventory: formData.inventory.map((inv) => {
          if (inv.amount_opened === '') {
            inv.amount_opened = '0'
          }
          if (inv.amount === '') {
            inv.amount = '0'
          }

          return { ...inv, unit: undefined }
        })
      })

      setIsLoading(false)
      onSuccess(response.data)
      displaySuccess(intl.formatMessage({ id: 'chemicals.product-updated' }))
      modalController.close()
    } catch (error) {
      displayError(error)
      setIsLoading(false)
    }
  }

  return (
    product && (
      <Form onSubmit={onSubmit}>
        <div style={{ padding: '10px' }}>
          <Field
            id="inventory"
            Component={Packages}
            label={intl.formatMessage({ id: 'chemicals.packages' })}
            initialValue={inventory}
            validation={validateInventory}
            hasErrorHandling={true}
          />
        </div>
        <ModalActionsFooter>
          <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => modalController.close()}/>
          <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                  isLoading={isLoading}/>
        </ModalActionsFooter>
      </Form>
    )
  )
}

export default injectIntl(EditProductForLocationDialog)
