import { useContext } from 'react'
import { useUID } from 'react-uid';

import { CacheContext } from 'Context'

const useCachedGetData = (url, initialValue, observe) => {
  const { getCachedItem, setValue } = useContext(CacheContext)

  const requestId = useUID();

  const item = getCachedItem(requestId, url, initialValue, observe)

  const updateValue = (value) => {
    setValue(url, value)
  }

  return [item.isLoading, item.value, updateValue]
}

export default useCachedGetData
