import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from '../readonly'
import { TextInput } from '../input'
import colors from '../colors.scss'
import { insertDynamicHazardContent } from 'Utils/statements'

const Entry = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1 0 1%;
  }

  > ${TextInput} {
    flex: 0 0 100px;
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 30px;
    height: 30px;
  }

  margin-bottom: 10px;
`
const EntryInput = styled.div`
  div {
    display: block;
    margin-top: 10px;
  }
`

const EntryContainer = styled.div`
  &.edit {
    background-color: ${colors.darkerBackgroundColor};
    padding: 10px;
  }

  margin-bottom: 10px;
`

export const HazardStatementEdit = ({ onChange, value }) => {
  const [descriptionObject, setDescription] = useState({})
  const [editOpen, setEditOpen] = useState(false)

  const edit = (entry) => {
    if (editOpen) {
      setEditOpen(false)
    } else {
      if (!descriptionObject[entry.code]) {
        let matches = [...entry.original_description.match(/<(.*?)>/g)]
        let gatheredPlaceholders = []
        let placeholderValue = []
        if (entry.placeholder) {
          placeholderValue = JSON.parse(entry.placeholder)
        }
        matches.forEach((m, index) => {
          gatheredPlaceholders.push(m)
          if (!placeholderValue[index]) {
            placeholderValue[index] = ''
          }
        })
        setDescription({
          ...descriptionObject,
          [entry.code]: { placeholderList: gatheredPlaceholders, placeholders: placeholderValue }
        })
      }
      setEditOpen(true)
    }
  }

  const isEditable = (description) => {
    if (!description) {
      return false
    }
    let match = description.match(/<(.*?)>/g)
    if (match) {
      return true
    }
    return false
  }

  const setPlaceholderValue = (value, code, index) => {
    let placeholder = descriptionObject[code]
    placeholder.placeholders[index] = value
    setDescription({ ...descriptionObject, [code]: placeholder })
    internalOnChange()
  }

  const internalOnChange = () => {
    let newValue = { ...value }
    newValue.placeholder = JSON.stringify(descriptionObject[value.code].placeholders)
    newValue.phrase = insertDynamicHazardContent(value.original_description, descriptionObject[value.code].placeholders)
    onChange({ target: { value: newValue } })
  }

  return (
    <div>
      <EntryContainer className={editOpen ? 'edit' : ''}>
        <Entry>
          <div>
            <strong>{value.code}</strong>
            <br/>
            {insertDynamicHazardContent(value.original_description, value.placeholder && JSON.parse(value.placeholder))}
          </div>
          {isEditable(value.original_description) && <Button icon="pi pi-pencil" onClick={() => edit(value)}/>}
        </Entry>
        {editOpen && (
          <EntryInput>
            {descriptionObject[value.code].placeholderList.map((desc, i) => {
              return (
                <div key={i}>
                  <TextInput
                    placeholder={desc}
                    value={descriptionObject[value.code].placeholders[i]}
                    onChange={(event) => setPlaceholderValue(event.target.value, value.code, i)}
                  />
                </div>
              )
            })}
          </EntryInput>
        )}
      </EntryContainer>
    </div>
  )
}
