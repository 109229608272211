import { FlexColumn } from 'Components/layout'
import { Divider } from 'Components/readonly'
import RiskAssessmentStatsChart from 'Pages/chemicalRisk/components/riskAssessmentStatsChart'
import { RiskAssessmentContextProvider } from 'Pages/chemicalRisk/context/riskAssessmentContext'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import IconChemicalManagement from './assets/ico-cm.svg'
import { AbsoluteAnchor, ChartPanel, Service, ServiceIcon, ServicePanel, StartChartContainer } from './startPageStyle'

const Wrapper = styled(FlexColumn)`
  height: 100%;
`

export default () => {
  return (
    <Wrapper>
      <Service>
        <a href="/#/chemicalList">
          <ServicePanel>
            <AbsoluteAnchor>
              <ServiceIcon>
                <img src={IconChemicalManagement} alt="Chemical management"/>
              </ServiceIcon>
              <span className="service-type">EASY</span>
              <h2>
                <FormattedMessage id="chemicals.module"/>
              </h2>
              <span className="service-author">by KemRisk</span>
            </AbsoluteAnchor>
          </ServicePanel>
        </a>
      </Service>
      <ChartPanel dark>
        <h3>
          <FormattedMessage id="chemicals.riskassessments"/>
        </h3>
        <Divider/>
        <StartChartContainer>
          <RiskAssessmentContextProvider>
            <RiskAssessmentStatsChart animate size="160"/>
          </RiskAssessmentContextProvider>
        </StartChartContainer>
      </ChartPanel>
    </Wrapper>
  )
}
