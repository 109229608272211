import axios from 'axios'
import colors from 'Components/colors.scss'
import { TextInput } from 'Components/input'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > label {
  }
`

const IconSuccess = styled.span`
  &&& {
    font-size: 20px;
    color: ${colors.primary};
  }
`

const IconError = styled.span`
  &&& {
    font-size: 20px;
    color: ${colors.red};
  }
`

const ChemicalIngredientIdInput = ({ ingredient, index, prop, update }) => {
  const intl = useIntl()
  const [found, setFound] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const searchRequestTokenRef = useRef()

  const htmlId = 'ingredient_' + index + prop.replace(/\./g, '-')
  const type = prop.substring(prop.lastIndexOf('.') + 1)
  const value = _.get(ingredient, prop, '')

  const onBlur = async (event) => {
    const searchString = event.target.value
    if (searchString) {
      setIsLoading(true)
      if (searchRequestTokenRef.current) {
        searchRequestTokenRef.current.cancel('Aborted by new search')
      }
      searchRequestTokenRef.current = axios.CancelToken.source()
      try {
        const result = await axios.post(
          '/rapi/ingredients/search',
          { searchString, type, like: false },
          { cancelToken: searchRequestTokenRef.current.token }
        )
        setFound(result.data)
        searchRequestTokenRef.current = null
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      }
      setIsLoading(false)
    } else {
      setFound(null)
    }
  }

  return (
    <div>
      <label htmlFor={htmlId}>
        <FormattedMessage id={'chemicals.form.' + prop.replace(/ingredient_variant\./, '')}/>
      </label>
      <Wrapper>
        <TextInput id={htmlId} value={value} onChange={(e) => update(index, prop, e)} onBlur={onBlur}/>
        {isLoading ? (
          <i className="pi pi-spinner pi-spin"/>
        ) : (
          value &&
          found != null &&
          (found.length > 0 ? (
            <>
              <IconError>
                <i className="pi pi-exclamation-triangle"/>
              </IconError>{' '}
              <div>
                <FormattedMessage
                  id="chemicals.form.ingredient.id_found"
                  values={{ id: intl.formatMessage({ id: 'chemicals.form.ingredient.' + type }) }}
                />
              </div>
            </>
          ) : (
            <IconSuccess>
              <i className="pi pi-check" title={intl.formatMessage({ id: 'chemicals.form.ingredient.id_not_found' })}/>
            </IconSuccess>
          ))
        )}
      </Wrapper>
    </div>
  )
}

export default ChemicalIngredientIdInput
