import React, { useContext } from 'react'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { AuthContext, GrowlContext } from 'Context'
import useModalController from 'Hooks/useModalController'
import { FieldValidationMessages } from 'Components/form'
import { Button, InfoPanel, Modal, ToolPanel } from 'Components/readonly'
import { Spacer } from 'Components/layout'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import LocationSelectDialog from 'Components/combined/modalContentComponents/locationSelectDialog'
import approveModalContent from '../approveModalContent'
import markReadyModalContent from '../markReadyModalContent'
import { HasCapability } from 'Components/capabilities'

export const InventoryDetailControls = ({
                                          inventory,
                                          save,
                                          setInventory,
                                          isSaving,
                                          isShowLocationNotDone,
                                          variant
                                        }) => {
  const intl = useIntl()
  const history = useHistory()
  const { user } = useContext(AuthContext)
  const { displaySuccess } = useContext(GrowlContext)

  const [deleteInventoryModalController] = useModalController({ title: intl.formatMessage({ id: 'inventory.delete' }) })
  const [printFormModalController] = useModalController({ title: intl.formatMessage({ id: 'inventory.print-form' }) })
  const [markReadyModalController] = useModalController({ title: intl.formatMessage({ id: 'inventory.send-for-approval' }) })
  const [approveModalController] = useModalController({ title: intl.formatMessage({ id: 'inventory.approve-inventory-title' }) })

  variant = variant ? variant : 'top'

  const deleteInventorying = async (id) => {
    let result = null
    result = await Axios.delete('/rapi/inventorying/' + inventory.id)
    displaySuccess(result.data.message)
    history.goBack()
  }

  const handleMarkReady = async () => {
    if (await save()) {
      markReadyModalController.open({ data: inventory })
    }
  }

  const handleApprove = async () => {
    if (await save()) {
      approveModalController.open({ data: inventory })
    }
  }

  const onApproveSuccess = async () => {
    history.replace('/inventory/done/' + inventory.id)
  }

  const controlList = {
    approve:
      user.isSuperAdmin || inventory.created_by === user.user.id ? (
        <Button primary icon="pi pi-arrow-circle-right" label={intl.formatMessage({ id: 'inventory.approve' })}
                onClick={handleApprove}/>
      ) : (
        <Button
          primary
          icon="pi pi-arrow-circle-right"
          label={intl.formatMessage({ id: 'inventory.mark-waiting-for-approval' })}
          onClick={handleMarkReady}
          disabled={inventory.status !== 'IN_PROGRESS' || !inventory.other_locations_all_done}
        />
      ),
    save: <Button key="save" submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save-draft' })}
                  isLoading={isSaving}/>,
    delete: (user.isSuperAdmin || inventory.created_by === user.user.id) && (
      <Button
        key="delete"
        invert
        label={intl.formatMessage({ id: 'inventory.delete' })}
        icon="pi pi-folder"
        onClick={() => deleteInventoryModalController.open()}
      />
    ),
    spacer: <Spacer/>,
    print: (
      <Button
        key="print"
        icon="pi pi-file-pdf"
        label={intl.formatMessage({ id: 'inventory.print-form' })}
        onClick={() => printFormModalController.open()}
      />
    )
  }

  const variantControls = {
    top: [controlList['approve'], controlList['save'], controlList['delete'], controlList['spacer'], controlList['print']],
    bottom: [controlList['spacer'], controlList['save'], controlList['approve']]
  }

  const controls = variantControls[variant]

  return (
    <>
      <Modal
        modalController={deleteInventoryModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="inventory.delete-confirm-text"
        confirmTextId="common.delete"
        icon="pi pi-trash"
        onConfirm={deleteInventorying}
      />
      <Modal
        modalController={printFormModalController}
        ContentComponent={LocationSelectDialog}
        confirmButtonIcon="pi pi-file-pdf"
        confirmButtonText={intl.formatMessage({ id: 'inventory.print-form' })}
        requestUrl={'/rapi/inventorying/' + inventory.id + '/pdf'}
        locationsUrl={'/rapi/inventorying/' + inventory.id + '/locations'}
      />
      <Modal modalController={markReadyModalController} ContentComponent={markReadyModalContent}
             onSuccess={setInventory}/>
      <Modal modalController={approveModalController} ContentComponent={approveModalContent}
             onSuccess={onApproveSuccess}/>

      <ToolPanel>{controls}</ToolPanel>
      {isShowLocationNotDone && <FieldValidationMessages messages={{ id: 'inventory.location_not_done' }}/>}
      <HasCapability
        capability="INVENTORYING_START"
        no={
          <>
            {!inventory.other_locations_all_done && <InfoPanel messageid="inventory.other-locations-not-done"/>}
            {inventory.status === 'WAITING_FOR_APPROVAL' && <InfoPanel messageid="inventory.waiting-for-approval"/>}
          </>
        }
      />
    </>
  )
}
