import axios from 'axios'
import ChemicalSdsView from 'Components/combined/chemicalSdsView'
import { FormContext, FormContextProvider } from 'Components/form/formContext'
import TwoColumn from 'Components/layout/twoColumn'
import { Button, ResponsiveSidebar } from 'Components/readonly'
import { CalculateRiskfactor } from 'Components/readonly/riskfactor_visualization'
import { GrowlContext } from 'Context'
import { format } from 'date-fns'
import _ from 'lodash'
import * as React from 'react'
import { injectIntl } from 'react-intl'
import RiskAssessmentForm from './riskAssessmentForm'
import RiskAssessmentPreview from './riskAssessmentPreview'

const RiskAssessmentSidebarInContext = injectIntl(({ product, updateRiskAssessments, isOpen, close, intl }) => {
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [isSaving, setIsSaving] = React.useState(false)
  const { formData, setFormData, validateForm } = React.useContext(FormContext)
  const formRef = React.useRef()
  const twoColumnRef = React.useRef()
  const [selectedSds, setSelectedSds] = React.useState(null)
  const [previewData, setPreviewData] = React.useState(null)

  const saveDraft = async () => {
    formData.risk_value = CalculateRiskfactor(formData.harmful_probability, formData.harmful_severity) + ''
    setIsSaving(true)
    try {
      const result = await axios.post('/rapi/chemicals/sds/' + selectedSds.id + '/riskassessment/draft', formData)
      if (updateRiskAssessments) {
        updateRiskAssessments(result.data.riskAssessments)
      }
      setFormData(_.find(result.data.riskAssessments, (riskAssessment) => riskAssessment.id === result.data.updatedId))
      displaySuccess(result.data.message)
    } catch (error) {
      displayError(error)
    }
    setIsSaving(false)
  }

  const save = async () => {
    formData.risk_value = CalculateRiskfactor(formData.harmful_probability, formData.harmful_severity) + ''
    formData.action_plan_date = formData.action_plan_date ? format(formData.action_plan_date, 'YYYY-MM-DD') : null
    setIsSaving(true)
    try {
      const result = await axios.post('/rapi/chemicals/sds/' + selectedSds.id + '/riskassessment', formData)
      if (updateRiskAssessments) {
        updateRiskAssessments(result.data.riskAssessments)
      }
      setFormData(_.find(result.data.riskAssessments, (riskAssessment) => riskAssessment.id === result.data.updatedId))
      displaySuccess(result.data.message)
      setIsSaving(false)
      closePreview()
      close()
    } catch (error) {
      setIsSaving(false)
      displayError(error)
    }
  }

  const gotoPreview = () => {
    const errors = validateForm(formRef.current.getChildren())
    if (!_.isEmpty(errors)) {
      twoColumnRef.current.getRightColumn().scrollTop = 0
      return
    }
    setPreviewData(formData)
  }

  const closePreview = () => {
    setPreviewData(null)
  }

  const viewOptions = [
    { label: intl.formatMessage({ id: 'riskassessment.sds' }), value: 'left' },
    { label: intl.formatMessage({ id: 'riskassessment.riskassessment' }), value: 'right' }
  ]

  return (
    <ResponsiveSidebar
      blockScroll={true}
      position="right"
      visible={isOpen}
      onHide={close}
      iconsTemplate={() =>
        previewData ? (
          <>
            <Button
              icon="pi pi-arrow-left"
              label={intl.formatMessage({ id: 'common.back' })}
              onClick={closePreview}
              className="button-text-md-hide"
            />
            <Button
              primary
              isLoading={isSaving}
              icon="pi pi-check"
              label={intl.formatMessage({ id: 'common.save' })}
              onClick={save}
              className="button-text-md-hide"
            />
          </>
        ) : (
          <>
            <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={close} className="md-show"/>
            <Button
              isLoading={isSaving}
              icon="pi pi-save"
              label={intl.formatMessage({ id: 'common.save-draft' })}
              onClick={saveDraft}
              className="button-text-md-hide"
            />
            <Button
              primary
              icon="pi pi-arrow-right"
              label={intl.formatMessage({ id: 'common.verify' })}
              onClick={gotoPreview}
              className="button-text-md-hide"
            />
          </>
        )
      }>
      <TwoColumn
        viewOptions={viewOptions}
        leftContent={isOpen && <ChemicalSdsView productId={product.id} setSelectedSds={setSelectedSds}/>}
        rightContent={
          isOpen &&
          (previewData ? (
            <RiskAssessmentPreview product={product} riskAssessment={previewData} selectedSds={selectedSds}/>
          ) : (
            <RiskAssessmentForm intl={intl} ref={formRef} product={product}/>
          ))
        }
        ref={twoColumnRef}
      />
    </ResponsiveSidebar>
  )
})

const RiskAssessmentSidebar = ({ product, selectedRiskAssessment, ...props }) => {
  return (
    props.isOpen &&
    product &&
    selectedRiskAssessment && (
      <FormContextProvider initialFormData={selectedRiskAssessment}>
        <RiskAssessmentSidebarInContext product={product} selectedRiskAssessment={selectedRiskAssessment} {...props} />
      </FormContextProvider>
    )
  )
}

export default RiskAssessmentSidebar
