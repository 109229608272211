import React, { useRef } from 'react'
import { useIntl } from 'react-intl'

import { TextInput } from '../input'
import { Button } from '../readonly'

export const TextWithCopyButton = ({ value, disabled }) => {
  const textRef = useRef(null)
  const intl = useIntl()

  const handleCopyText = (e) => {
    textRef.current.getCurrent().element.select()
    document.execCommand('copy')
    e.target.parentElement.focus()
  }

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-8">
        <TextInput className="p-col-12" disabled={disabled} readOnly ref={textRef} value={value}/>
      </div>
      <div className="p-col-12 p-md-4">
        <Button primary label={intl.formatMessage({ id: 'sds.copy_weblink' })} disabled={disabled}
                onClick={handleCopyText}/>
      </div>
    </div>
  )
}
