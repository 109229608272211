import axios from 'axios'
import ChemicalSdsView from 'Components/combined/chemicalSdsView'
import TwoColumn from 'Components/layout/twoColumn'
import { Button, Column, DataTable } from 'Components/readonly'
import { GrowlContext } from 'Context'
import useGetData from 'Hooks/useGetData'
import ObjectUtils from 'primereact/components/utils/ObjectUtils'
import { ProgressSpinner } from 'primereact/progressspinner'
import * as React from 'react'
import { useIntl } from 'react-intl'

const ProductSdsPreliminarySidebarContent = ({ productId, close, updateProduct }) => {
  const intl = useIntl()
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [similarProductsLoading, similarProducts] = useGetData(`/rapi/chemicals/${productId}/similar`, [], [productId])
  const [updatedSimilarProducts, setUpdatedSimilarProducts] = React.useState(similarProducts)

  const moveToExistingProduct = async (moveToProduct) => {
    try {
      const response = await axios.post(`/rapi/chemicals/${productId}/movesds`, {
        move_to_product_id: moveToProduct.id
      })
      updateProduct(response.data.chemical)
      displaySuccess(response.data.message)
      close()
    } catch (error) {
      displayError(error)
    }
  }

  const percentBody = (row, column) => <b>{Math.round(ObjectUtils.resolveFieldData(row, column.field)) + '%'}</b>

  const moveActionBody = (row) => (
    <Button
      link
      primary
      icon="pi pi-plus-circle"
      label={intl.formatMessage({ id: 'common.move' })}
      onClick={() => moveToExistingProduct(row)}
    />
  )

  return similarProductsLoading ? (
    <ProgressSpinner/>
  ) : (
    <TwoColumn
      leftContent={<ChemicalSdsView productId={productId} editView={false} setUpdatedSimilarProducts={setUpdatedSimilarProducts}/>}
      rightContent={
        <div>
          <h3>Följande produkter har liknande namn</h3>
          <p>
            Om den här nyregistrerade produkten redan finns i databasen kan den slås ihop. Välj då Flytta för att flytta
            detta SDB till den
            existerande produkten.
          </p>
          <p>
            Vid flytt kommer denna preliminära produkt att tas bort. Den kommer även arkiveras hos alla kunder som har
            den. Den valda
            produkten kommer ersätta den arkiverade produkten hos kunderna och läggas till med respektive platser och
            mängder.
          </p>
          <p>Produkten finns för närvarande aktiv hos {updatedSimilarProducts.active_count ?? similarProducts.active_count} kund(er).</p>
          <DataTable className="flextable" value={updatedSimilarProducts.matching ?? similarProducts.matching} loading={similarProductsLoading}>
            <Column field="id" header="Id"/>
            <Column field="name" header="Produktnamn"/>
            <Column field="supplier" header="Leverantör"/>
            <Column field="language" header="Språk"/>
            <Column field="market" header="Marknad"/>
            <Column field="score" header="Matchning" body={percentBody}/>
            <Column body={moveActionBody}/>
          </DataTable>
        </div>
      }
    />
  )
}

export default ProductSdsPreliminarySidebarContent
