import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Divider, ToolPanel } from 'Components/readonly'

import { FieldError, FlexRow, FlexRowCenter, Section, Spacer } from 'Components/layout'
import { AuthContext } from 'Context'
import { Field, Form } from 'Components/form'
import { Checkbox, Datepicker, FileUpload, MarketLanguageDropdown } from 'Components/input'
import ImportPreview from './importPreview'
import styled from 'styled-components'

const NoMarginForm = styled(Form)`
  & > * {
    margin-bottom: 0;
  }
`

const FileRowComponent = ({ value, onChange }) => {
  const internalOnChange = (e, prop) => {
    const newValue = { ...value, [prop]: e.target.value }
    onChange({ target: { value: newValue } })
  }

  return (
    <FlexRow>
      <MarketLanguageDropdown value={value.language} onChange={(e) => internalOnChange(e, 'language')}/>
      <Datepicker value={value.date} onChange={(e) => internalOnChange(e, 'date')}/>
    </FlexRow>
  )
}

export default ({ data, closePreview, setSdsForImportProduct }) => {
  const intl = useIntl()
  const { userLanguage3 } = useContext(AuthContext)
  const [isDone, setIsDone] = useState(false)
  const [errors, setErrors] = useState({})
  const newProducts = useMemo(() => data.products.filter((product) => product.id === undefined), [data.products])
  const existingProducts = useMemo(() => data.products.filter((product) => product.id !== undefined), [data.products])

  const gotoNextStep = () => {
    setIsDone(true)
  }

  useEffect(() => {
    if (newProducts.length === 0 && existingProducts.length === 0) {
      gotoNextStep()
    }
  })

  const [checkedProductsForSdsUpload, setCheckedProductsForSdsUpload] = useState(() => {
    const initialCheckedProducts = {}
    newProducts.forEach((product) => {
      initialCheckedProducts[product.productIndex] = true
    })
    return initialCheckedProducts
  })

  const SelectSds = ({ products }) => {

    return products.map((product, index) => (
      <Section key={index}>
        <div style={{ position: 'relative' }}>
          {errors[product.productIndex] && <FieldError/>}


          <div>
            <Checkbox
              label={product.name}
              description={
                <>
                  <FormattedMessage id="common.supplier" />
                  {`: ${product.supplier}`}
                </>
              }
              value={checkedProductsForSdsUpload[product.productIndex]}
              onChange={(e) => {
                if (!e.target.checked) {
                  setSdsForImportProduct(product.productIndex, [])
                }
                setCheckedProductsForSdsUpload({
                  ...checkedProductsForSdsUpload,
                  [product.productIndex]: e.target.checked
                })
              }}
            />
          </div>
          {checkedProductsForSdsUpload[product.productIndex] && (
            <FlexRowCenter style={{ marginLeft: '25px' }}>
              <FileUpload
                id={'register_sds_' + product.productIndex}
                text={intl.formatMessage({ id: 'sds.upload_sdb' })}
                multiple={true}
                FileRowComponent={checkedProductsForSdsUpload[product.productIndex] && FileRowComponent}
                intialExtraValue={{ language: userLanguage3 }}
                value={data.importSds[product.productIndex]}
                onChange={(e) => setSdsForImportProduct(product.productIndex, e.target.value)}
              />
            </FlexRowCenter>
          )}
        </div>
        <Divider/>
      </Section>
    ))
  }

  const validateFiles = () => {
    let collectedErrors = {}
    let firstError = null

    data.products.forEach((product, index) => {
      // for all checked products,

      if (checkedProductsForSdsUpload[product.productIndex]) {
        // check if file and language is set correctly
        if (
          data.importSds[product.productIndex].length === 0 ||
          data.importSds[product.productIndex].filter((file) => {
            return !file.deleted && !file.extra.language
          }).length > 0
        ) {
          collectedErrors[product.productIndex] = true
          // if not return validation error
          if (!firstError) {
            firstError = { id: 'chemicals.language_required' }
          }
        }

        // Detect language duplicates
        const languages = data.importSds[product.productIndex].filter((file) => !file.deleted).map((file) => file.extra.language)
        if (data.importSds[product.productIndex].length === 0 || languages.some((language, i) => languages.indexOf(language) !== i)) {
          collectedErrors[product.productIndex] = true
          if (!firstError) {
            firstError = { id: 'validation.distinct', values: { attribute: 'common.language' } }
          }
        }
      }
    })

    setErrors(collectedErrors)
    return firstError
  }

  const onSubmit = () => {
    gotoNextStep()
  }

  return isDone ? (
    <ImportPreview data={data} closePreview={closePreview}/>
  ) : (
    <>
      <h2>
        <FormattedMessage id="chemicals.import.upload-sds"/>
      </h2>
      <NoMarginForm onSubmit={onSubmit} validation>
        {newProducts.length > 0 && (
          <p>
            <FormattedMessage id="chemicals.import.upload-sds-text"/>
          </p>
        )}
        <Field
          id="new-product"
          Component={SelectSds}
          products={newProducts}
          hasErrorHandling={true}
          validation={() => validateFiles(newProducts)}
        />
        {existingProducts.length > 0 && (
          <p>
            <FormattedMessage id="chemicals.import.upload-sds-existing-text"/>
          </p>
        )}
        <Field
          id="existing-product"
          Component={SelectSds}
          products={existingProducts}
          hasErrorHandling={true}
          validation={() => validateFiles(existingProducts)}
        />
        <ToolPanel>
          <Spacer/>
          <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={closePreview}/>
          <Button primary submit label={intl.formatMessage({ id: 'common.continue' })}/>
        </ToolPanel>
      </NoMarginForm>
    </>
  )
}
