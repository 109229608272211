import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import colors from 'Components/colors.scss'
import { Badge } from 'Components/readonly'

const statusBadgeConfig = {
  PLANNED: { backgroundColor: 'lightBackgroundColor' },
  IN_PROGRESS: { backgroundColor: 'red' },
  WAITING_FOR_APPROVAL: { backgroundColor: 'accent' },
  DONE: { backgroundColor: 'primary' }
}

const StyledBadge = styled(Badge)`
  padding: 3px 20px;
`

export const InventoryStatusBadge = ({ status }) => {
  const intl = useIntl()

  return (
    <StyledBadge
      label={intl.formatMessage({ id: 'inventory.' + status.toLowerCase() })}
      textColor={colors.white}
      color={colors[statusBadgeConfig[status].backgroundColor]}
      style={{ width: '150px' }}
    />
  )
}
