import React, { useContext, useRef, useState } from 'react'
import { UserDialogContent, UserDialogFooter } from './userDialogContent'
import { Modal } from 'Components/readonly'
import axios from 'axios'
import { format, isDate } from 'date-fns'
import _ from 'lodash'
import { GrowlContext } from 'Context'

const convertDates = (obj) => {
  return _.mapValues(obj, (item) => {
    if (isDate(item)) {
      return format(item, 'YYYY-MM-DD')
    }
    return item
  })
}

export const UserDialog = ({ modalController, onUpdate, onCreate }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const formRef = useRef()

  const createUser = async (formData) => {
    let result = null
    try {
      result = await axios.post('/rapi/register', convertDates(formData))
    } catch (error) {
      displayError(error)
      return {error:true}
    }

    onCreate(convertDates(result.data.user))
    displaySuccess(result.data.message)
  }

  const updateUser = async (formData, selectedUser, isProfileMode) => {
    let result = null

    try {
      result = await axios.put('/rapi/user/' + selectedUser.id + (isProfileMode ? '/profile' : ''), convertDates(formData))
    } catch (error) {
      displayError(error)
      return {error:true}
    }

    onUpdate(convertDates(result.data.user))
    displaySuccess(result.data.message)
  }

  const reloadPage = () => {
    setTimeout(() => {
      setIsLoading(false)
      window.location.reload(false)
      modalController.close()
    }, 2500)
  }

  const onSubmit = async (formData, userMarkets, selectedUser, isNewUser, isProfileMode) => {
    setIsLoading(true)

    // First update inventory replacement if any
    if (formData.replace_super_user && formData.replace_super_user !== 'NOT_NEEDED') {
      try {
        await axios.put('/rapi/inventorying/replacecreatedby', {
          old_user_id: selectedUser.id,
          new_user_id: formData.replace_super_user
        })
      } catch (error) {
        displayError(error)
        setIsLoading(false)
        return
      }
    }

    formData['userMarkets'] = userMarkets
    let error = false;
    if (isNewUser) {
      error = !!(await createUser(formData))?.error
    } else {
      error = !!(await updateUser(formData, selectedUser, isProfileMode))?.error
    }
    if(!error){
      reloadPage()
    } else {
      setIsLoading(false)
    }
  }

  const onClickSave = () => {
    formRef.current.submit()
  }

  return (
    <Modal
      formRef={formRef}
      modalController={modalController}
      ContentComponent={UserDialogContent}
      FooterComponent={UserDialogFooter}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      onSubmit={onSubmit}
      onClickSave={onClickSave}
    />
  )
}
