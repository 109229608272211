import React from 'react'
import styled from 'styled-components'
import colors from '../colors.scss'

const badgeColors = {
  accent: colors.accent,
  default: colors.defaultInteract,
  faded: colors.disabledInteract,
  danger: colors.red
}

const Wrapper = styled.span`
  padding: 3px 28px;
  border-radius: 15px;
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.color};
  width: ${(props) => props.width};
  border: 2px solid ${(props) => props.color};
  font-size: 14px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  position: relative;

  @media screen and (max-width: 768px) {
    width: auto;
  }

  i {
    color: ${colors.disabledWhite};
    position: absolute;
    right: 7px;
    top: 3px;
  }

  &.clickable:hover {
    i {
      color: ${colors.white};
    }

    border-color: ${colors.white};
  }

  &.clickable {
    cursor: pointer;
  }
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-left: -15px;
  margin-right: 5px;
  background-color: ${(props) => props.color};
`

const BadgeTemplate = ({
                         className,
                         label,
                         color,
                         accent,
                         faded,
                         danger,
                         fill,
                         textColor,
                         dotColor,
                         onClick,
                         ...props
                       }) => {
  const bg = color
    ? color
    : accent
      ? badgeColors['accent']
      : faded
        ? badgeColors['faded']
        : danger
          ? badgeColors['danger']
          : badgeColors['default']

  const text = textColor ? textColor : !accent && !faded && !danger ? '#000' : '#fff'

  const fillStyle = fill ? '100%' : 'auto'

  return (
    <Wrapper
      className={className + (onClick ? ' clickable' : '')}
      color={bg}
      textColor={text}
      width={fillStyle}
      onClick={onClick}
      {...props}>
      {dotColor && <Dot color={dotColor}/>}
      {label}
      {onClick && <i className="pi pi-times"/>}
    </Wrapper>
  )
}

export const Badge = styled(BadgeTemplate)``
