import React, { useContext, useState } from 'react'
import { Field, FieldValidationMessages, Form } from 'Components/form'
import { Button, Checkbox, TextInput } from 'Components/input'
import axios from 'axios'
import { AuthContext } from 'Context'
import { Link } from 'react-router-dom'
import { FormContext } from 'Components/form/formContext'
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl'
import { Panel } from 'Components/readonly'
import colors from 'Components/colors.scss'
import useGetData from 'Hooks/useGetData'

import styled from 'styled-components'

const Wrapper = styled.div`
  .forgotten {
    text-align: center;
    font-size: 14px;

    a {
      color: ${colors.text};

      &:hover {
        color: ${colors.white};
      }
    }
  }
`

const TermsPanel = styled(Panel)`
  margin-top: 20px;
  padding-top: 10px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;

  a {
    color: ${colors.accent};
  }
`

const LoginForm = () => {
  const intl = useIntl()
  const { loginUser } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [latestConsentsLoading, latestConsents] = useGetData('/rapi/lastestConsents/' + document.documentElement.lang, {})

  const loginAction = (formData) => {
    if (isLoading) {
      return
    }
    login(formData)
  }

  const login = async (formData) => {
    setIsLoading(true)
    setError(null)
    try {
      const result = await axios.post('/rapi/login', formData)
      loginUser(result.data)
    } catch (error) {
      if (error && error.response) {
        if (error.response.status === 401) {
          setError(intl.formatMessage({ id: 'auth.failed' }))
        } else if (error.response.data && error.response.data.errors && Object.values(error.response.data.errors).length > 0) {
          setError(Object.values(error.response.data.errors).flat())
        } else if (error.response.data && error.response.data.message) {
          setError(error.response.data.message)
        } else {
          setError(intl.formatMessage({ id: 'common.error_occured' }))
        }
      } else {
        setError(intl.formatMessage({ id: 'common.error_occured' }))
      }
      setIsLoading(false)
    }
  }

  return (
    <Wrapper>
      <h1>
        <FormattedMessage id="welcome.my-kemrisk"/>
      </h1>
      <Form onSubmit={loginAction}>
        <Field id="email" placeholder={intl.formatMessage({ id: 'user.email' })} Component={TextInput} required={true}
               tabIndex="0"/>
        <Field
          id="password"
          placeholder={intl.formatMessage({ id: 'auth.password' })}
          type="password"
          Component={TextInput}
          required={true}
          tabIndex="0"
        />
        <Field
          id="remember"
          Component={Checkbox}
          label={intl.formatMessage({ id: 'auth.remember' })}
          initialValue={false}
          showLabel={false}
        />
        <Button submit label={intl.formatMessage({ id: 'auth.login' })} primary tabIndex="0" isLoading={isLoading}/>
        <FormContext.Consumer>
          {(formContext) => (
            <div className="forgotten">
              <Link to={{ pathname: '/reset', state: { email: formContext.formData.email } }} tabIndex="0">
                <FormattedMessage id="auth.forgot"/>
              </Link>
            </div>
          )}
        </FormContext.Consumer>
        <FieldValidationMessages messages={error}/>
      </Form>
      <TermsPanel>
        <FormattedMessage id="welcome.need-login-credentials"/>
        <br/>
        <br/>
        {!latestConsentsLoading && latestConsents.user_terms && latestConsents.integrity_policy && (
          <>
            <FormattedHTMLMessage id="auth.terms" values={{ url: latestConsents.user_terms.url }}/> |{' '}
            <FormattedHTMLMessage id="auth.policy" values={{ url: latestConsents.integrity_policy.url }}/>
          </>
        )}
      </TermsPanel>
    </Wrapper>
  )
}

export default LoginForm
