import React, { useContext, useRef, useState } from 'react'
import { Menu } from 'primereact/menu'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { Button } from '../input'

import { AuthContext } from 'Context'

const TestContainer = styled.div`
  background-color: #000000;
  border: 1px dashed red;
  padding: 5px;
`

const TestPanel = (props) => {
  const { loginUser } = useContext(AuthContext)
  const [hidden, setHidden] = useState(false)
  const { user, changeOrganistionAction } = useContext(AuthContext)

  const organisationsMenu =
    user.organisations &&
    user.organisations.map((org) => ({
      label: org.name,
      command: () => {
        changeOrganistionAction(org.id)
      }
    }))

  const menuRef = useRef(null)

  const loginRoot = async () => {
    try {
      const result = await axios.post('/rapi/login', {
        email: 'root@consid.se',
        password: 'consid'
      })
      loginUser(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  const login = async (user) => {
    try {
      const result = await axios.post('/rapi/login', {
        email: user + '@example.org',
        password: 'secret'
      })
      loginUser(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  const hide = () => {
    setHidden(true)
  }

  if (hidden) {
    return null
  }

  return (
    <TestContainer>
      ** Testpanel **
      <Button link onClick={loginRoot} label="Logga in root"/>
      <Button link onClick={() => login('super1')} label="Logga in super user"/>
      <Button link onClick={() => login('admin1')} label="Logga in admin user"/>
      <Button link onClick={() => login('admina')} label="Logga in admin user A"/>
      <Button link onClick={() => login('adminb')} label="Logga in admin user B"/>
      <Button link onClick={() => login('user1')} label="Logga in reader"/>
      <Button link onClick={() => login('usera')} label="Logga in reader A"/>
      <Button link onClick={() => login('userb')} label="Logga in reader B"/>
      {user.handlesMultipleOrganisations && (
        <>
          <Menu model={organisationsMenu} popup={true} ref={menuRef}/>
          <Button link label="Byt organisation" onClick={(event) => menuRef.current.toggle(event)}/>
        </>
      )}
      <Button link onClick={hide} label="Dölj testpanel"/>
      <Link to="/componentShowcase">Komponent showcase</Link>
    </TestContainer>
  )
}

export default TestPanel
