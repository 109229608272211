import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import colors from '../colors.scss'

const Wrapper = styled.div`
  background-color: #404b5f;
  color: #646c7c;
  border: 1px solid ${colors.darkBackground};
  border-radius: ${(props) => (props.size === 'sm' && '20px') || '100px'};
  width: ${(props) => (props.size === 'sm' && '40px') || '130px'};
  height: ${(props) => (props.size === 'sm' && '40px') || '130px'};
  line-height: ${(props) => (props.size === 'sm' && '40px') || '130px'};
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  span {
  }

  font-size: ${(props) => (props.size === 'sm' && '22px') || '80px'};

  img {
    width: 100%;
    height: 100%;
    border-radius: ${(props) => (props.size === 'sm' && '20px') || '100px'};
  }
`

export const Avatar = styled(({ className, size, src, name }) => {
  const initials = React.useMemo(() => {
    const n = _.trim(name).toUpperCase()
    if (n === '') {
      return null
    }

    const i = n.lastIndexOf(' ')
    if (i === -1) {
      return n[0]
    }

    return n[0] + n[i + 1] // i+1 must be valid since string is trimmed and a space was found at i
  }, [name])

  return src || initials ? (
    <Wrapper className={className} size={size}>
      {src ? <img src={src} alt="Avatar"/> : <span>{initials}</span>}
    </Wrapper>
  ) : null
})``
