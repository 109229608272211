import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import styled from 'styled-components'
import colors from '../colors.scss'

export const Expander = styled(({ ...props }) => <Accordion multiple={true} {...props} />)`
  &&& {
    .p-accordion-header {
      background-color: ${colors.lightBackgroundColor};
      border-radius: 2px;

      a {
        background-color: transparent;
        display: flex;
        flex-direction: row-reverse;
        border: none;

        .p-accordion-toggle-icon {
          flex: 0 1 2%;
        }

        > span {
          flex: 1 0 1%;
        }

        &:focus {
          box-shadow: none;
        }

        &:hover {
          text-decoration: none;
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover a {
        background-color: transparent;
        border: none;
        color: #fefefe;
        text-decoration: none;

        .p-accordion-toggle-icon {
          color: #fefefe;
        }
      }
    }

    && .p-highlight a {
      background-color: ${colors.lightBackgroundColor};
      border: 0;
    }

    .p-accordion-content {
      border: none;
      background-color: ${colors.darkBackgroundFaded};
      padding: 10px;

      > div {
        display: flex;
      }
    }
  }
`

export const ExpanderTab = ({ ...props }) => <AccordionTab {...props} />
