import styled from 'styled-components'

import { FlexRow } from 'Components/layout'
import { Badge } from 'Components/readonly'
import colors from 'Components/colors.scss'

export const RiskRowWrapper = styled.div`
  flex: 1 1 1px;

  @media screen and (min-width: 992px) {
    flex: 1 0 1px;
  }
`

export const ProductTitle = styled.div`
  &&& {
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 5px;

    .p-button-text {
      padding: 0;
    }

    .link {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export const MainFlexRow = styled(FlexRow)`
  align-items: center;
  padding: 10px 20px 10px 20px;

  ${Badge} {
    margin-left: 5px;
    min-width: 135px;
  }

  @media screen and (max-width: 640px) {
    ${Badge} {
      min-width: auto;
    }
  }

  @media screen and (max-width: 992px) {
    ${Badge} {
      margin: 5px 5px 0 0;
    }
  }

  .badge-container {
    flex: 0 0 135px;

    ${Badge} {
      &:nth-child(2) {
        margin-top: 5px;
      }
    }
  }
`

export const SubContainer = styled.div`
  padding: 0 65px 0 40px;
  background-color: ${colors.darkerPanel};

  @media screen and (max-width: 640px) {
    padding: 0 10px 0 30px;
  }
`

export const ActionBar = styled.div`
  &&& {
    display: flex;
    flex: 0 0 220px;
    border-left: 40px solid transparent;

    button {
      margin: 0 0 0 10px;
    }

    @media screen and (max-width: 640px) {
      flex: 1 0 auto;
      margin-left: 20px;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 992px) {
      justify-content: flex-end;
      margin-left: 0;
    }
  }
`
export const SubRow = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0 15px 0;

    & .riskStatus {
      display: inline-block;
      min-width: 133px;
    }

    @media screen and (max-width: 640px) {
      ${ActionBar} {
        padding-top: 10px;

        button {
          margin: 0 0 5px 0;
          flex: 1 0 100%;
        }
      }
    }

    @media screen and (max-width: 992px) {
      flex-wrap: wrap;

      ${ActionBar} {
        display: flex;
        padding-top: 10px;
        width: 100%;
        text-align: right;
      }
    }

    :not(:last-child) {
      border-bottom: 1px solid ${colors.lightLine};
    }
  }
`
