import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { TabPanel } from 'primereact/tabview'

import { ChartPageContainer, PageConstraint, PageHeader } from 'Components/layout'
import { TabView } from 'Components/readonly'

import { EmptyRiskAssessment } from './components/riskAssessmentForm'
import RiskAssessmentViewSidebar from './components/riskAssessmentViewSidebar'
import RiskAssessmentStatsChart from './components/riskAssessmentStatsChart'
import CurrentRiskAssessmentList from './components/currentRiskAssessmentList'
import ArchivedRiskAssessmentList from './components/archivedRiskAssessmentList'

import { RiskAssessmentContextProvider } from './context/riskAssessmentContext'
import { useLocation } from 'react-router-dom'

const RiskAssessmentPage = ({ intl }) => {
  // Risk assessment data for both sidebars
  const [selectedRiskAssessment, setSelectedRiskAssessment] = useState(EmptyRiskAssessment)
  // Risk assessment view sidebar open state
  const [riskSidebarVisible, setRiskSidebarVisible] = useState(false)

  const location = useLocation()

  return (
    <RiskAssessmentContextProvider>
      <PageConstraint>
        <RiskAssessmentViewSidebar
          riskAssessment={selectedRiskAssessment}
          riskSidebarVisible={riskSidebarVisible}
          setRiskSidebarVisible={setRiskSidebarVisible}
        />

        <PageHeader>
          <h1>
            <FormattedMessage id="chemicals.riskassessment"/>
          </h1>

          <ChartPageContainer>
            <RiskAssessmentStatsChart/>
          </ChartPageContainer>
        </PageHeader>

        <TabView activeIndex={location.state && location.state.productName ? 1 : 0}>
          <TabPanel header={intl.formatMessage({ id: 'common.active' })}>
            <CurrentRiskAssessmentList
              selectedRiskAssessment={selectedRiskAssessment}
              setSelectedRiskAssessment={setSelectedRiskAssessment}
              setRiskSidebarVisible={setRiskSidebarVisible}
            />
          </TabPanel>
          <TabPanel header={intl.formatMessage({ id: 'sds.archived' })}>
            <ArchivedRiskAssessmentList
              initialSearch={location.state && location.state.productName}
              selectedRiskAssessment={selectedRiskAssessment}
              setSelectedRiskAssessment={setSelectedRiskAssessment}
              setRiskSidebarVisible={setRiskSidebarVisible}
            />
          </TabPanel>
        </TabView>
      </PageConstraint>
    </RiskAssessmentContextProvider>
  )
}

export default injectIntl(RiskAssessmentPage)
