import React, { useContext, useRef } from 'react'
import { useIntl } from 'react-intl'

import { arrayCommaBody, Button, Column, DataTable, ToolPanel } from 'Components/readonly'
import useGetData from 'Hooks/useGetData'
import { AuthContext } from 'Context'

export default () => {
  const intl = useIntl()
  const { organisation } = useContext(AuthContext)
  const [isLoading, data] = useGetData('/rapi/chemicals/organisation/export', [], [organisation])
  const dataTableRef = useRef()

  const exportCSV = () => {
    if (dataTableRef.current) {
      dataTableRef.current.exportCSV()
    }
  }

  return (
    <>
      <ToolPanel>
        <Button primary icon="pi pi-file-excel" label={intl.formatMessage({ id: 'admin.export_csv' })}
                onClick={exportCSV}/>
      </ToolPanel>
      <DataTable
        ref={dataTableRef}
        className="flextable"
        value={data}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[10, 100, 1000]}
        loading={isLoading}>
        <Column field="name" header={intl.formatMessage({ id: 'chemicals.product' })} sortable={true}/>
        <Column field="supplier" header={intl.formatMessage({ id: 'common.supplier' })} sortable={true}/>
        <Column field="location" header={intl.formatMessage({ id: 'common.location' })} sortable={true}/>
        <Column field="market" header={intl.formatMessage({ id: 'common.market' })}/>
        <Column field="bulk" header={intl.formatMessage({ id: 'chemicals.bulk' })} sortable={true}/>
        <Column field="amount" header={intl.formatMessage({ id: 'chemicals.unopened' })} sortable={true}/>
        <Column field="amount_opened" header={intl.formatMessage({ id: 'chemicals.opened' })} sortable={true}/>
        <Column field="packaging_size" header={intl.formatMessage({ id: 'chemicals.size' })} sortable={true}/>
        <Column field="unit" header={intl.formatMessage({ id: 'chemicals.unit' })} sortable={true}/>
        <Column field="article_number" header={intl.formatMessage({ id: 'chemicals.article_id' })} sortable={true}/>
        <Column
          field="organisation_article_number"
          header={intl.formatMessage({ id: 'chemicals.organisation_article_number' })}
          sortable={true}
        />
        <Column field="organisation_synonym" header={intl.formatMessage({ id: 'chemicals.organisation_synonym' })}
                sortable={true}/>
        <Column field="tags" body={arrayCommaBody} header={intl.formatMessage({ id: 'chemicals.tags' })}
                sortable={true}/>
      </DataTable>
    </>
  )
}
