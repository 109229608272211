import React, { useContext, useState } from 'react'

import axios from 'axios'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'

import { GrowlContext } from 'Context'
import { TextareaInput } from 'Components/input'
import { Button } from 'Components/readonly'

const HorizontalButtons = styled.div`
  padding-top: 10px;
`

const ExternalSdsCommentInput = ({ intl, sds, onSdsUpdated }) => {
  const { displaySuccess, displayError } = useContext(GrowlContext)
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false)
  const [commentText, setCommentText] = useState(sds.comment)

  const onSubmitComment = async () => {
    setIsLoading(true)
    try {
      const result = await axios.post(`/rapi/sds/${sds.id}/comment/external`, { comment: commentText })
      onSdsUpdated({ ...sds, comment: commentText })
      displaySuccess(result.data.message)
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <TextareaInput
        rows={5}
        cols={30}
        value={commentText}
        placeholder={intl.formatMessage({ id: 'placeholders.about-this-sds' })}
        onChange={(e) => setCommentText(e.target.value)}
        autoResize={true}
        disabled={isLoading}
      />

      <HorizontalButtons>
        <Button primary icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })} onClick={onSubmitComment}
                isLoading={isLoading} disabled={commentText === sds.comment}/>
      </HorizontalButtons>
    </>
  )
}

export default injectIntl(ExternalSdsCommentInput)
