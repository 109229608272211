import * as React from 'react'
import RiskAssessmentPreview from './riskAssessmentPreview'
import { SidebarScrollingContainer } from 'Components/layout'
import { Sidebar } from 'Components/readonly'

import styled from 'styled-components'
import { RiskAssessmentContext } from '../context/riskAssessmentContext'

const ViewSidebar = styled(Sidebar)`
  width: 40%;

  @media screen and (max-width: 992px) {
    width: 90%;
  }
`

const RiskAssessmentViewSidebar = ({ riskAssessment, riskSidebarVisible, setRiskSidebarVisible, ...props }) => {
  const { selectedProduct } = React.useContext(RiskAssessmentContext)

  return (
    <ViewSidebar
      className="risk-assessment-view"
      blockScroll={true}
      visible={riskSidebarVisible}
      position="right"
      onHide={(e) => setRiskSidebarVisible(false)}>
      {selectedProduct && (
        <SidebarScrollingContainer>
          <RiskAssessmentPreview product={selectedProduct} riskAssessment={riskAssessment}/>
        </SidebarScrollingContainer>
      )}
    </ViewSidebar>
  )
}

export default RiskAssessmentViewSidebar
