import React, { useContext } from 'react'
import styled from 'styled-components'
import { Datepicker } from './date'
import { AuthContext } from 'Context'
import { FormattedMessage } from 'react-intl'

const Label = styled.div`
  display: flex;
  flex-direction: column;
`

export const DateRangePicker = ({ minFromDate, fromDate, toDate, onToDateChange, onFromDateChange, ...props }) => {
  const { dateLocalePrime } = useContext(AuthContext)

  return (
    <>
      <span style={{ paddingRight: '10px' }}>
        <FormattedMessage id="sds.show-time-period"/>
      </span>
      <Label>
        <Datepicker
          locale={dateLocalePrime}
          value={fromDate}
          dateFormat="d MM yy"
          onChange={(e) => onFromDateChange(e.value)}
          minDate={minFromDate}
          maxDate={toDate}
          readOnlyInput={true}
        />
      </Label>
      <span style={{ padding: '0 10px' }}>-</span>
      <Label>
        <Datepicker
          locale={dateLocalePrime}
          dateFormat="d MM yy"
          value={toDate}
          onChange={(e) => onToDateChange(e.value)}
          minDate={fromDate}
          readOnlyInput={true}
        />
      </Label>
    </>
  )
}
