import React, { useContext } from 'react'
import { Column } from 'primereact/column'
import { Button } from 'Components/input'
import { AuthContext } from 'Context'
import useCrudData from 'Hooks/useCrudData'

import { DataTable, Modal, ToolPanel } from 'Components/readonly'
import { injectIntl } from 'react-intl'
import { GrowlContext } from 'Context/growlContext'
import useModalController from 'Hooks/useModalController'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import axios from 'axios'
import tagDialog from './tagDialog'
import { FlexRow } from 'Components/layout'
import { HasCapability, useCapability } from 'Components/capabilities'

const SettingsTagsPage = ({ intl }) => {
  const { organisation } = useContext(AuthContext)
  const { displaySuccess } = useContext(GrowlContext)

  const [tagModalController] = useModalController({ title: intl.formatMessage({ id: 'chemicals.tags.edit_tag' }) })
  const [deleteTagModalController] = useModalController({ title: intl.formatMessage({ id: 'chemicals.tags.delete_tag' }) })

  // eslint-disable-next-line
  const [isLoading, tagList, createData, readData, updateData, deleteData] = useCrudData('/rapi/tags', [], [organisation])

  const editBody = (row) => (
    <FlexRow key={row}>
      <Button
        primary
        icon="pi pi-pencil"
        onClick={(event) => {
          tagModalController.open({ data: row })
        }}
      />
    </FlexRow>
  )

  const deleteBody = (row) => (
    <FlexRow key={row}>
      <Button
        primary
        icon="pi pi-trash"
        onClick={() => {
          deleteTagModalController.open({ data: row })
        }}
      />
    </FlexRow>
  )

  const deleteTag = async (tag) => {
    let result = null
    result = await axios.delete('/rapi/tags/' + tag.id)
    displaySuccess(result.data.message)
    deleteData(tag)
  }

  const columns = [<Column key="name" field="name" header={intl.formatMessage({ id: 'chemicals.tags.tag' })}
                           sortable={true}/>]
  if (useCapability('CHEMICAL_MANAGE_TAGS')) {
    columns.push(
      <Column key="edit" header={intl.formatMessage({ id: 'common.edit' })} body={editBody}
              style={{ width: '1%', textAlign: 'center' }}/>
    )
    columns.push(
      <Column
        key="delete"
        header={intl.formatMessage({ id: 'common.delete' })}
        body={deleteBody}
        style={{ width: '1%', textAlign: 'center' }}
      />
    )
  }

  return (
    <>
      <Modal
        modalController={tagModalController}
        ContentComponent={tagDialog}
        onUpdate={updateData}
        onCreate={createData}
        tagList={tagList}
      />
      <Modal
        modalController={deleteTagModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="chemicals.tags.delete_tag_text"
        confirmTextId="common.delete"
        onConfirm={deleteTag}
      />
      <ToolPanel>
        <HasCapability capability="CHEMICAL_MANAGE_TAGS">
          <Button
            primary
            label={intl.formatMessage({ id: 'chemicals.tags.new_tag' })}
            icon="pi pi-plus"
            onClick={() => {
              tagModalController.open({ title: intl.formatMessage({ id: 'chemicals.tags.new_tag' }) })
            }}
          />
        </HasCapability>
      </ToolPanel>
      <DataTable className="flextable" value={tagList} paginator={true} rows={10} loading={isLoading}
                 onRowClick={(event) => {
                 }}>
        {columns}
      </DataTable>
    </>
  )
}

export default injectIntl(SettingsTagsPage)
