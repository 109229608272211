import React, { useContext, useMemo, useState } from 'react'

import { Field, FieldValidationMessages, FormContext } from 'Components/form'
import { Button, Divider, InfoPanel, Panel, ToolPanel } from 'Components/readonly'
import { FlexRowCenter, Section, Spacer } from 'Components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import { SelectButton, Switch } from 'Components/input'
import InventoryDetailLocationInventory, { isNewProduct } from './inventoryDetailLocationInventory'

export default ({ inventoryLocation, locationIndex, onAddProduct, validateAllLocations }) => {
  const intl = useIntl()
  const { getFieldValue, setFieldValue } = useContext(FormContext)
  const [locationValidationMessages, setLocationValidationMessages] = useState([])

  // Inventorying Change handling
  const inventoryingChange = getFieldValue(`locations[${locationIndex}].inventorying_change`)

  const inventoryingChangeOptions = [
    { label: intl.formatMessage({ id: 'inventory.with-change' }), value: 'with_change' },
    { label: intl.formatMessage({ id: 'inventory.without-change' }), value: 'without_change' },
    { label: intl.formatMessage({ id: 'inventory.archive-all' }), value: 'archive_all' }
  ]

  const updateLocationValidation = (inventoryLocation, locationIndex) => {
    // Value may not be empty. Zero is allowed.
    const isEmpty = (v) => v === null || v === undefined || v === ''
    if (
      inventoryingChange === 'with_change' &&
      // If is_bulk only amount is used
      inventoryLocation.chemicals.some((chemical) =>
        chemical.inventory.some((i) => !i.to_be_archived && (isEmpty(i.amount) || (!i.is_bulk && isEmpty(i.amount_opened))))
      )
    ) {
      setLocationValidationMessages([{ id: 'inventory.amounts-missing-for-location' }])
      setFieldValue(`locations[${locationIndex}].is_done`, false)
    } else {
      setLocationValidationMessages([])
    }
  }

  const handleIsDoneChanged = (e, inventoryLocation, locationIndex) => {
    if (e.target.value) {
      updateLocationValidation(inventoryLocation, locationIndex)
      validateAllLocations()
    }
  }

  // Filter chemicals based on inventoryingchange. New products are only visible when inventoryingchange is == 'with_change'
  const [locationHasNewProducts, setLocationHasNewProducts] = useState(false)

  const isChemicalNew = (chemical) => {
    return chemical.inventory.filter((item) => isNewProduct(item)).length > 0
  }

  const filteredInventoryLocationChemicals = useMemo(() => {
    let chemicals = []
    inventoryLocation.chemicals.forEach((chemical) => {
      if (inventoryingChange === 'with_change') {
        chemicals.push(chemical)
      } else {
        if (isChemicalNew(chemical)) {
          setLocationHasNewProducts(true)
        } else {
          chemicals.push(chemical)
        }
      }
    })
    return chemicals
  }, [inventoryLocation.chemicals, setLocationHasNewProducts, inventoryingChange])

  return (
    <div>
      <Section>
        <h2>{inventoryLocation.location}</h2>
        <ToolPanel>
          {inventoryLocation.chemicals.length > 0 && (
            <>
              <span>
                <FormattedMessage id="inventory.products-at-location"/>
              </span>
              <Field id={`locations[${locationIndex}].inventorying_change`} Component={SelectButton}
                     options={inventoryingChangeOptions}/>
            </>
          )}
          <Spacer/>
          {inventoryingChange === 'with_change' && (
            <Button
              primary
              link
              icon="pi pi-plus"
              label={intl.formatMessage({ id: 'chemicals.add_product_to_list' })}
              onClick={onAddProduct}
              style={{ width: 'auto' }}
              disabled={getFieldValue(`locations[${locationIndex}].is_done`)}
            />
          )}
        </ToolPanel>
      </Section>
      <Section>
        {locationHasNewProducts && inventoryingChange !== 'with_change' &&
          <InfoPanel messageid="inventory.new-products-hidden"/>}
      </Section>
      <Section>
        {filteredInventoryLocationChemicals.length === 0 && (
          <Panel>
            <div style={{ paddingTop: '10px' }}>
              <FormattedMessage id="inventory.no-products-at-location"/>
            </div>
          </Panel>
        )}

        {filteredInventoryLocationChemicals.length > 0 && (
          <Panel>
            {filteredInventoryLocationChemicals.map((chemical, chemicalIndex) => {
              return (
                <InventoryDetailLocationInventory
                  key={chemicalIndex}
                  done={getFieldValue(`locations[${locationIndex}].is_done`)}
                  inventoryingChange={inventoryingChange}
                  chemical={chemical}
                  inventoryProp={`locations[${locationIndex}].chemicals[${chemicalIndex}].inventory`}
                  showValidationErrors={locationValidationMessages.length > 0}
                  updateValidation={() =>
                    locationValidationMessages.length > 0 && updateLocationValidation(inventoryLocation, locationIndex)
                  }
                />
              )
            })}
          </Panel>
        )}
      </Section>
      <ToolPanel>
        <h3>
          <FlexRowCenter>
            <FormattedMessage id="inventory.is-complete-for-location"/>
            <Field
              style={{ marginLeft: '10px' }}
              id={`locations[${locationIndex}].is_done`}
              Component={Switch}
              onLabel={intl.formatMessage({ id: 'common.yes' })}
              offLabel={intl.formatMessage({ id: 'common.no' })}
              light="true"
              onChange={(e) => handleIsDoneChanged(e, inventoryLocation, locationIndex)}
            />
          </FlexRowCenter>
        </h3>{' '}
      </ToolPanel>
      <FieldValidationMessages messages={locationValidationMessages}/>
      <Divider/>
    </div>
  )
}
