import React from 'react'
import styled from 'styled-components'

const StyledG = styled.g`
  fill: #6b6b6b;

  &:hover {
    fill: #3bb377;
    cursor: pointer;
  }

  .clickarea {
    fill: none;
    pointer-events: all;
  }
`

export default ({ children, x, y, onClick }) => {
  return (
    <StyledG transform={'translate(' + x + ',' + y + ')'} onClick={onClick}>
      <rect className="clickarea" x="0" y="0" width="24" height="24"/>
      {children}
    </StyledG>
  )
}
