import React from 'react'

import { Button, ModalActionsFooter, RichTextDisplay } from '../../readonly'
import { FlexColumn, FlexRow, Spacer } from '../../layout'
import { useIntl } from 'react-intl'

export default ({ modalController }) => {
  const intl = useIntl()

  const contentText = modalController.data

  return (
    <>
      <FlexRow>
        <FlexColumn>
          {Array.isArray(contentText) &&
            contentText.map((text) => (
              <p>
                <RichTextDisplay value={text}/>
              </p>
            ))}
        </FlexColumn>
      </FlexRow>
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.close' })} onClick={modalController.close}/>
      </ModalActionsFooter>
    </>
  )
}
