import React, { useContext, useState } from 'react'
import axios from 'axios'
import { Dialog } from 'primereact/dialog'

import { Button, ModalActionsFooter } from 'Components/readonly'
import { Spacer, TextContainer } from 'Components/layout'
import { GrowlContext } from 'Context'
import { FormattedMessage, injectIntl } from 'react-intl'

const ArchiveProductDialog = ({ isOpen, productId, close, deleteProduct, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displayError } = useContext(GrowlContext)

  const remove = async () => {
    setIsLoading(true)
    try {
      await axios.put('/rapi/chemicals/remove/' + productId)
      if (deleteProduct) {
        deleteProduct({ id: productId })
      }
      close(true)
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  return (
    <Dialog
      header={intl.formatMessage({ id: 'chemicals.archive_product' })}
      modal={true}
      visible={isOpen}
      onHide={close}
      appendTo={document.body}>
      <TextContainer>
        <p>
          <FormattedMessage id="chemicals.archive_dialog_text"/>
        </p>
      </TextContainer>
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => close(false)}/>
        <Button primary onClick={remove} icon="pi pi-trash" label={intl.formatMessage({ id: 'common.delete' })}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Dialog>
  )
}

export default injectIntl(ArchiveProductDialog)
