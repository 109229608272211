import { Dropdown } from 'Components/input'
import { Section, Spacer } from 'Components/layout'
import { Button, Column, DataTable, ToolPanel } from 'Components/readonly'
import useGetData from 'Hooks/useGetData'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

const StyledToolPanel = styled(ToolPanel)`
  ${Dropdown}${Dropdown}${Dropdown} {
    width: auto;
  }
`

export const ReportOrganisationSdsCount = () => {
  const intl = useIntl()

  const dataTableRef = useRef()
  const [isLoading, organisationSdsCounts] = useGetData('/rapi/admin/report-organisation-sds-count', {})
  const [selectedDate, setSelectedDate] = useState(null)

  const dateOptions = React.useMemo(() => {
    const dates = Object.keys(organisationSdsCounts).sort().reverse()
    setSelectedDate(dates.length > 0 ? dates[0] : null)
    return dates
  }, [organisationSdsCounts])

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value)
  }

  const exportCSV = () => {
    if (dataTableRef.current) {
      dataTableRef.current.exportCSV()
    }
  }

  return (
    <>
      <StyledToolPanel>
        <Dropdown options={dateOptions} value={selectedDate} onChange={handleDateChange}/>
        <Spacer/>
        <Button icon="pi pi-file-excel" label={intl.formatMessage({ id: 'admin.export_csv' })} onClick={exportCSV}/>
      </StyledToolPanel>
      <Section>
        <DataTable
          ref={dataTableRef}
          className="flextable clickable-rows"
          value={selectedDate && organisationSdsCounts[selectedDate]}
          paginator={true}
          rows={10}
          loading={isLoading}>
          <Column field="organisation_name" header={intl.formatMessage({ id: 'common.name' })} sortable={true}
                  exportable={true}/>
          <Column field="organisation_number" header={intl.formatMessage({ id: 'organisation.org-id' })} sortable={true}
                  exportable={true}/>
          <Column field="count" header={intl.formatMessage({ id: 'admin.count' })} sortable={true} exportable={true}/>
        </DataTable>
      </Section>
    </>
  )
}
