import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button, LoadingOverlay } from '../readonly'
import { AutoComplete, TextInput } from '../input'
import colors from '../colors.scss'
import { HazardStatementEdit } from './hazardStatementEdit'
import { insertDynamicHazardContent } from 'Utils/statements'

const Add = styled.div`
  display: flex;

  > ${AutoComplete} {
    input {
      width: 200px;
    }
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 150px;
    margin-left: 10px;
    margin-bottom: 0;
  }
`

const Entry = styled.div`
  display: flex;
  align-items: center;

  > * {
    flex: 1 0 1%;
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 30px;
    height: 30px;
    margin: 0;
  }

  ${TextInput} {
    margin-left: 5px;
    flex: 0 0 70px;
  }

  margin-bottom: 10px;
`

const EntryContainer = styled.div`
  &.edit {
    background-color: ${colors.darkerBackgroundColor};
    padding: 10px;
  }

  margin-top: 10px;
`

const CodeDiv = styled.div`
  .code {
    &:after {
      content: ', ';
    }

    &:last-of-type {
      &:after {
        content: '';
      }
    }
  }
`

export const CategoryPickListHazard = ({ id, categoryList, codeList, language, onChange, value, isLoading }) => {
  const intl = useIntl()
  const [newCategory, setNewCategory] = useState('')
  const [categorySuggestions, setCategorySuggestions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)

  value = value || []

  const suggestCategories = (event) => {
    setCategorySuggestions(
      categoryList.filter(
        (c) =>
          c.abbreviation.toLowerCase().startsWith(event.query.toLowerCase()) ||
          (c.hazard_statement_code && c.hazard_statement_code.toLowerCase().includes(event.query.toLowerCase()))
      )
    )
  }

  const generateStatement = (code, lang) => {
    const desc = codeList[selectedCategory.hazard_statement_code][lang]
    return {
      code: code,
      description: desc,
      original_description: desc,
      phrase: insertDynamicHazardContent(desc)
    }
  }

  const add = () => {
    const newCategories = { ...value }
    if (newCategories.ingredient) {
      if (_.findIndex(newCategories.hazard_statements, { code: selectedCategory.hazard_statement_code }) === -1) {
        _.set(newCategories, 'hazard_statements', [
          ...value.hazard_statements,
          generateStatement(selectedCategory.hazard_statement_code, 'sv')
        ])
      }
    } else if (_.findIndex(newCategories.hazard_statements.sv, { code: selectedCategory.hazard_statement_code }) === -1) {
      _.set(newCategories, 'hazard_statements.sv', [
        ...value.hazard_statements.sv,
        generateStatement(selectedCategory.hazard_statement_code, 'sv')
      ])
      _.set(newCategories, 'hazard_statements.en', [
        ...value.hazard_statements.en,
        generateStatement(selectedCategory.hazard_statement_code, 'en')
      ])
      value.hazard_statements.no && _.set(newCategories, 'hazard_statements.no', [
        ...value.hazard_statements.no,
        generateStatement(selectedCategory.hazard_statement_code, 'no')
      ])
      value.hazard_statements.dk && _.set(newCategories, 'hazard_statements.dk', [
        ...value.hazard_statements.dk,
        generateStatement(selectedCategory.hazard_statement_code, 'dk')
      ])
    }
    _.set(newCategories, 'hazard_categories', [...value.hazard_categories, selectedCategory])
    onChange({ target: { value: newCategories } })
    setNewCategory('')
    setSelectedCategory(null)
  }

  const onSearchFieldChange = (value) => {
    if (value === '') {
      setSelectedCategory(null)
    }

    setNewCategory(value)
  }

  const remove = (index) => {
    const newCategories = { ...value }
    const code = newCategories.hazard_categories[index].hazard_statement_code
    if (newCategories.hazard_categories.filter((c) => c.hazard_statement_code === code).length === 1) {
      if (newCategories.ingredient) {
        const codeIndex = _.findIndex(newCategories.hazard_statements, { code: code })
        newCategories.hazard_statements.splice(codeIndex, 1)
      } else {
        const codeIndex = _.findIndex(newCategories.hazard_statements.sv, { code: code })
        newCategories.hazard_statements.sv.splice(codeIndex, 1)
        newCategories.hazard_statements.en.splice(codeIndex, 1)
        if (Array.isArray(newCategories.hazard_statements.no)) {
          newCategories.hazard_statements.no.splice(codeIndex, 1);
        }
        
        if (Array.isArray(newCategories.hazard_statements.dk)) {
          newCategories.hazard_statements.dk.splice(codeIndex, 1);
        }
      }
    }
    newCategories.hazard_categories.splice(index, 1)
    onChange({ target: { value: newCategories } })
  }

  const setMFactor = (index, event) => {
    const newItem = { ...value.hazard_categories[index], m_factor: event.target.value }
    const newHazards = { ...value }
    newHazards.hazard_categories.splice(index, 1, newItem)
    onChange({ target: { value: newHazards } })
  }

  const onKeyUp = (event) => {
    if (event.key === 'Enter' && selectedCategory) {
      add()
    }
    setSelectedCategory(null)
  }

  const editStatement = (statement, index) => {
    const newCategories = { ...value }
    _.set(newCategories, 'hazard_statements[' + language + '][' + index + ']', statement.target.value)
    onChange({ target: { value: newCategories } })
  }

  const categoryItemTemplate = (item) => {
    let num = 0
    if (language === 'en') {
      num = 1
    } else if (language === 'no') {
      num = 2
    } else if (language === 'dk') {
      num = 3
    }

    return (
      <>
        <span>{item.abbreviation}</span>
        {item.hazard_statement_code && <span> {item.hazard_statement_code}</span>}
        <br/>

        <span>{item.names[num].name}</span>
      </>
    )
  }

  return (
    <div id={id}>
      <LoadingOverlay isLoading={isLoading}>
        <Add>
          <AutoComplete
            placeholder={intl.formatMessage({ id: 'chemicals.form.classification_marking_placeholder' })}
            value={newCategory}
            onChange={(e) => onSearchFieldChange(e.value)}
            field="abbreviation"
            suggestions={categorySuggestions}
            completeMethod={suggestCategories.bind(this)}
            onSelect={(event) => setSelectedCategory(event.value)}
            onUnselect={() => setSelectedCategory(null)}
            onKeyUp={onKeyUp}
            itemTemplate={categoryItemTemplate}
          />
          <Button label={intl.formatMessage({ id: 'common.add' })} disabled={isLoading || !selectedCategory}
                  onClick={add}/>
        </Add>
      </LoadingOverlay>
      {value &&
        value.hazard_categories.map((entry, index) => {
          return (
            <EntryContainer key={index} id={id + '-' + index}>
              <Entry>
                <div>{entry.abbreviation}</div>
                {entry.has_m_factor && (
                  <>
                    <FormattedMessage id="chemicals.form.m_factor"/>
                    <TextInput id={id + '-' + index + '-m_factor'} value={entry.m_factor}
                               onChange={(e) => setMFactor(index, e)}/>
                  </>
                )}
                <Button icon="pi pi-trash" onClick={() => remove(index)}/>
              </Entry>
            </EntryContainer>
          )
        })}
      {value && value.hazard_statements[language] && value.hazard_statements[language].length > 0 && (
        <h3>{intl.formatMessage({ id: 'chemicals.form.danger_markings' })}</h3>
      )}
      {value && value.hazard_statements[language] ? (
        _.uniqBy(value.hazard_statements[language], 'code').map((entry, index) => {
          return <HazardStatementEdit key={index} value={entry}
                                      onChange={(statement) => editStatement(statement, index)}/>
        })
      ) : (
        <CodeDiv>
          {_.uniqBy(value.hazard_statements, 'code').map((entry, index) => {
            return (
              <span key={entry.code + '.' + index} className="code">
                {entry.code}
              </span>
            )
          })}
        </CodeDiv>
      )}
    </div>
  )
}
