import React, { useContext, useMemo } from 'react'

import colors from 'Components/colors.scss'
import { ChartContainer, ChartLegend, ChartLegendCircle, ChartLegendRow, StyledChart } from 'Components/layout'
import { useIntl } from 'react-intl'

import { RiskAssessmentContext } from '../context/riskAssessmentContext'
import { LoadingSpinner } from 'Components/readonly'

const chartBeforeDraw = (chart) => {
  var width = chart.chart.width,
    height = chart.chart.height,
    ctx = chart.chart.ctx

  const scale = chart.chart.width / 236
  const textScale = chart.chart.width > 200 ? scale * 0.6 : scale

  var centerConfig = chart.config.options.elements.center
  var color = centerConfig.color || colors.black

  ctx.save()
  // Draw circle background
  ctx.beginPath()
  ctx.arc(width / 2, height / 2, 59 * scale, 0, 2 * Math.PI)
  ctx.fillStyle = colors.darkBackground
  ctx.fill()

  // Draw shadow with stroke offcanvas and shadow moved into canvas with offset
  ctx.shadowBlur = 9
  ctx.shadowOffsetX = 4 + 1000
  ctx.shadowOffsetY = 4
  ctx.shadowColor = colors.chartShadow
  ctx.beginPath()
  ctx.arc(-1000 + width / 2, height / 2, 59 * scale, 0, 2 * Math.PI)
  ctx.lineWidth = 4
  ctx.stroke()
  ctx.restore()

  // Remove shadow outside circle
  ctx.arc(width / 2, height / 2, 59 * scale, 0, 2 * Math.PI)
  ctx.globalCompositeOperation = 'destination-in'
  ctx.fill()
  ctx.globalCompositeOperation = 'source-over'

  var fontSize = (textScale * (height / 50)).toFixed(2)
  ctx.font = textScale * fontSize + 'em Roboto'
  ctx.textBaseline = 'middle'
  ctx.fillStyle = color
  ctx.textAlign = 'center'

  var textX = width / 2,
    textY = height / 2

  ctx.fillText(centerConfig.textRiskAssessmentPresentCount, textX, textY - 10)
  ctx.font = scale * 1.25 + 'em Roboto'
  ctx.fillText(centerConfig.textRiskAssessmentTotalCount, textX, textY + 20)
}

const InternalChart = React.memo(({ stats, animationTime, size }) => {
  const intl = useIntl()

  const data = {
    labels: [
      intl.formatMessage({ id: 'riskassessment.no-riskassessment' }),
      intl.formatMessage({ id: 'riskassessment.has-riskassessment' })
    ],
    datasets: [
      {
        data: [stats.missing, stats.present],
        borderWidth: 0,
        backgroundColor: [colors.red, colors.accent],
        hoverBackgroundColor: [colors.red, colors.accent]
      }
    ]
  }

  const options = {
    legend: {
      display: false,
      position: 'right'
    },
    elements: {
      center: {
        beforeDraw: chartBeforeDraw,
        textRiskAssessmentPresentCount: stats.present,
        textRiskAssessmentTotalCount: '/' + (stats.present + stats.missing),
        color: colors.white
      }
    },
    animation: {
      duration: animationTime
    },
    tooltips: {
      enabled: false
    }
  }

  const legendRow = (i) => {
    return (
      <ChartLegendRow fontSize={size < 200 ? '14px' : '16px'}>
        <ChartLegendCircle color={data.datasets[0].backgroundColor[i]}/>
        {data.labels[i]} ({data.datasets[0].data[i] ? data.datasets[0].data[i] : 0})
      </ChartLegendRow>
    )
  }
  return (
    <ChartContainer>
      <StyledChart type="doughnut" data={data} options={options} width={size + 'px'} height={size + 'px'}/>
      <ChartLegend>
        {legendRow(0)}
        {legendRow(1)}
      </ChartLegend>
    </ChartContainer>
  )
})

const RiskAssessmentStatsChart = ({ size, animate }) => {
  size = !size ? 236 : size
  const animationTime = !animate ? 0 : 1000

  const { restRiskList, restRiskListIsLoading } = useContext(RiskAssessmentContext)
  const stats = useMemo(() => {
    const present = restRiskList.filter((r) => r.risk_assessments.filter((ra) => ra.status === 'ACTIVE').length > 0).length
    const missing = restRiskList.length - present
    return { present, missing }
  }, [restRiskList])

  return (
    <LoadingSpinner isLoading={restRiskListIsLoading} animate>
      <InternalChart stats={stats} animationTime={animationTime} size={size}/>
    </LoadingSpinner>
  )
}

export default RiskAssessmentStatsChart
