import React, { useEffect, useState } from 'react'
import { Button as PrimeButton } from 'primereact/button'
import colors from '../colors.scss'

import styled from 'styled-components'

const DELAY_TIMEOUT = 300

export const Button = styled(({ primary, accent, submit, link, invert, icon, fill, disabled, isLoading, ...props }) => {
  const [isLoadingDelayed, setIsLoadingDelayed] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingDelayed(isLoading)
    }, DELAY_TIMEOUT)
    return () => clearTimeout(timer)
  }, [isLoading])

  return (
    <PrimeButton
      {...props}
      type={submit ? 'submit' : 'button'}
      disabled={disabled || isLoadingDelayed}
      icon={isLoadingDelayed ? 'pi pi-spinner pi-spin' : icon}
    />
  )
})`
  &&& {
    flex: ${(props) => (props.fill ? '1 1 auto' : '0 0 auto')};
    white-space: nowrap;
    margin-bottom: ${(props) => ((!props.label || props.link) && '0') || '10px'};
    background-color: ${(props) =>
  ((!props.label || props.link) && 'transparent') ||
  (props.primary && colors.primary) ||
  (props.accent && colors.accent) ||
  (props.invert && colors.invert) ||
  colors.defaultInteract};
    color: ${(props) =>
  (props.link && ((props.primary && colors.primary) || (props.accent && colors.accent) || colors.defaultInteract)) ||
  (props.primary && colors.white) ||
  (props.accent && colors.white) ||
  (props.invert && colors.white) ||
  colors.defaultInteractText};
    height: ${(props) => (props.link && 'auto') || 'auto'};
    border: 1px solid ${(props) =>
  ((!props.label || props.link) && 'transparent') ||
  (props.primary && colors.primary) ||
  (props.accent && colors.accent) ||
  (props.invert && colors.invert) ||
  colors.defaultInteract};
    border-radius: ${(props) => (props.link && '0') || '20px'};
    padding: ${(props) => ((!props.label || props.link) && '0') || '0.24em 13px'};

    &:focus,
    &.p-focus {
      box-shadow: none;
      border: 1px solid ${(props) => ((!props.label || props.link) && 'transparent') || colors.lightestBackground} !important;
    }

    & .pi {
      font-size: ${(props) => (!props.label && '28px') || '26px'};
      color: ${(props) =>
  !props.label &&
  ((props.primary && colors.primary) ||
    (props.accent && colors.accent) ||
    (props.invert && colors.invert) ||
    colors.defaultInteract)};

      &.pi-trash {
        font-size: ${(props) => (!props.label && '26px') || '24px'};
      }
    }

    &:hover:not(.p-disabled) {
      background-color: ${(props) =>
  ((!props.label || props.link) && 'transparent') ||
  (props.primary && colors.primary) ||
  (props.accent && colors.accent) ||
  (props.invert && colors.invert) ||
  colors.defaultInteract};
      color: ${(props) =>
  (props.link && ((props.primary && colors.primary) || (props.accent && colors.accent) || colors.defaultInteract)) ||
  (props.primary && colors.white) ||
  (props.accent && colors.white) ||
  (props.invert && colors.white) ||
  colors.defaultInteractText};
      filter: brightness(110%);
      border: 1px solid ${(props) =>
  ((!props.label || props.link) && 'transparent') ||
  (props.primary && colors.primary) ||
  (props.accent && colors.accent) ||
  (props.invert && colors.invert) ||
  colors.defaultInteract};

      .p-button-text {
        text-decoration: ${(props) => (props.link && 'underline') || 'none'};
      }
    }

    &.p-button-text-icon-right .p-button-text {
      padding: 0.429em 2em 0.429em 1em;
    }

    .p-button-text {
      padding: ${(props) => (props.link && '0 .5em') || '0.429em 1em'};
    }

    &.p-button-text-icon-left .p-button-text {
      padding: ${(props) => (props.link && '0 .5em 0 2em') || '0.429em 1em 0.429em 2em'};
    }

    .p-button-icon-left {
      left: ${(props) => (props.link && '0') || '0.5em'};
    }

    &.p-button-icon-only .p-button-icon-left {
      left: 50%;
    }
  }
`

export const BackLinkButton = styled(Button)`
  &&& {
    span.p-button-text {
      padding: 0;
    }
  }
`
