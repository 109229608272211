import React, { useContext, useState } from 'react'
import axios from 'axios'

import { injectIntl } from 'react-intl'

import { Field, FieldGroup, Form } from 'Components/form'
import { Button, Datepicker, FileUpload } from 'Components/input'
import { ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'
import styled from 'styled-components'

const SubmitButton = styled(Button)`
  width: 140px;
  align-self: flex-end;
`

const UploadLabelingDialog = ({ modalController, intl }) => {
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      data.append('sds-id', modalController.data.sdsId)
      let result
      if (modalController.data.replaceLabeling) {
        result = await axios.post('/rapi/labeling/replace/' + modalController.data.replaceLabeling.id, data)
      } else {
        result = await axios.post('/rapi/labeling/upload', data)
      }
      if (modalController.data.updateLabeling) {
        modalController.data.updateLabeling(result.data.labeling)
      }
      setIsLoading(false)
      displaySuccess(result.data.message)
      modalController.close()
    } catch (error) {
      displayError(error)
      setIsLoading(false)
    }
  }

  return (
    <Form onSubmit={onSubmit} useFormData={true}>
      <FieldGroup>
        <Field id="date" label={intl.formatMessage({ id: 'labeling.date' })} Component={Datepicker} required={true}/>
        <Field
          id="file"
          label={intl.formatMessage({ id: 'labeling.document' })}
          Component={FileUpload}
          required={true}
          text={intl.formatMessage({ id: 'labeling.document_placeholder' })}
        />
      </FieldGroup>
      <ModalActionsFooter>
        <SubmitButton primary submit label={intl.formatMessage({ id: 'labeling.upload' })} isLoading={isLoading}/>
      </ModalActionsFooter>
    </Form>
  )
}

export default injectIntl(UploadLabelingDialog)
