import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { ButtonBar } from 'Components/layout'
import { Button } from 'Components/input'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return this.props.error ? (
        this.props.error
      ) : (
        <>
          <h2>
            <FormattedMessage id="error.react_error"/>
          </h2>
          <ButtonBar>
            <Button label={this.props.intl.formatMessage({ id: 'error.retry' })}
                    onClick={() => this.setState({ hasError: false })}/>
          </ButtonBar>
        </>
      )
    }

    return this.props.children
  }
}

export default injectIntl(ErrorBoundary)
