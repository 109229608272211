import axios from 'axios'
import { Field, Form } from 'Components/form'
import { Button, TextInput } from 'Components/input'
import { Panel } from 'Components/readonly'
import { AuthContext, GrowlContext } from 'Context'
import React, { useContext, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'
import { validatePasswordUtil } from 'Utils/validatePasswordUtil'

const PasswordPanel = styled(Panel)`
  padding: 20px;

  ul {
    margin: 0;
  }
`

const PasswordResetForm = ({ history, match, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const { loginUser } = useContext(AuthContext)
  const { displayError } = useContext(GrowlContext)

  const loginAction = (formData) => {
    if (isLoading) {
      return
    }
    reset(formData)
  }

  const reset = async (formData) => {
    setIsLoading(true)
    try {
      const result = await axios.post('/rapi/password/reset/' + intl.locale, formData)
      if (result.data.message) {
        setMessage(result.data.message)
      } else {
        loginUser(result.data)
        history.push('/')
      }
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  const validatePassword = (field, formData) => {
    return validatePasswordUtil(intl, field, formData.password_confirmation)
  }

  return (
    <>
      <h1>{match.params.email ? <FormattedMessage id="auth.create_password"/> :
        <FormattedMessage id="auth.reset_password"/>}</h1>
      {message ? (
        <>
          <PasswordPanel>
            <p>
              <FormattedMessage id="auth.changed"/>
            </p>
            <p>
              <FormattedMessage id={message}/>
            </p>
          </PasswordPanel>
          <br/>
          <Button primary label={intl.formatMessage({ id: 'auth.login' })} onClick={() => history.push('/')}/>
        </>
      ) : (
        <Form onSubmit={loginAction}>
          <Field
            id="email"
            placeholder={intl.formatMessage({ id: 'user.email' })}
            Component={TextInput}
            required={true}
            initialValue={match.params.email}
          />
          <PasswordPanel>
            <FormattedMessage id="auth.password"/>
            <ul>
              <li>
                <FormattedMessage id="passwords.length"/>
              </li>
              <li>
                <FormattedMessage id="passwords.uppercase"/>
              </li>
              <li>
                <FormattedMessage id="passwords.lowercase"/>
              </li>
              <li>
                <FormattedMessage id="passwords.symbol"/>
              </li>
            </ul>
          </PasswordPanel>
          <Field
            id="password"
            placeholder={intl.formatMessage({ id: 'auth.password' })}
            Component={TextInput}
            required={true}
            type={'password'}
            validation={validatePassword}
          />
          <Field
            id="password_confirmation"
            placeholder={intl.formatMessage({ id: 'auth.confirm_password' })}
            Component={TextInput}
            required={true}
            type={'password'}
          />
          <Field id="token" Component={TextInput} type={'hidden'} initialValue={match.params.token}/>
          <br/>
          <Button
            submit
            label={
              match.params.email ? intl.formatMessage({ id: 'auth.create_password' }) : intl.formatMessage({ id: 'auth.reset_password' })
            }
            primary
          />
        </Form>
      )}
    </>
  )
}

export default injectIntl(PasswordResetForm)
