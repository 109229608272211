import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import useGetData from "Hooks/useGetData";
import axios from 'axios'
import { Spacer } from 'Components/layout'
import { AuthContext } from "Context";
import { Button, ModalActionsFooter } from 'Components/readonly'
import { Field, Form } from 'Components/form'
import { Tree} from 'Components/input'
import { transformLocations } from 'Utils/location'
import { IconError, Wrapper } from './styles'

const CreateQrModal = ({modalController, modalData, setQrCodeList, type}) => {
  const locationId = modalData?.location_id ?? '';
  const {organisation} = useContext(AuthContext)
  const [currentLocation, setCurrentLocation] = useState(locationId)
  const [currentSds, setCurrentSds] = useState(modalData?.productIds ?? [])
  const [put] = useState((modalData?.productIds ?? (locationId > 0)) !== false)
  const [sdsUrl, setSdsUrl] = useState('/rapi/qrCodes/sds?location=' + locationId);
  const [orgLocationsLoading, locationData] = useGetData("/rapi/locations", null, [organisation])
  const [isSdsLoading, sds] = useGetData(sdsUrl, null, [currentLocation])
  const [error, setError] = useState(false)
  const intl = useIntl()

  /**
   * Will set active location (single) and filter the product list
   * @param event
   */
  const selectLocation = (event) => {
    const value = parseInt((event.target.value === currentLocation) ? 0 : event.target.value);
    setCurrentLocation(value);
    setCurrentSds([]);
    setSdsUrl('/rapi/qrCodes/sds?location=' + value);
  };

  /**
   * Will set active SDS/products (multiple)
   * @param event
   */
  const selectSds = (event) => {
    setCurrentSds(event.target.value);
  };

  /**
   * Store/update QR code from the active select choices
   * @returns {Promise<void>}
   */
  const submit = async () => {
    try {
      const response = await axios({
        method: put ? 'put' : 'post',
        url: '/rapi/qrCodes',
        data: {
          id: (modalData?.id ?? 0),
          location_id: (currentLocation ?? ''),
          sds: currentSds
        }
      });
      setQrCodeList(response.data);
      modalController.close();
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  /**
   * Transform Sds data object so that the Tree component can read it.
   * @param sds
   * @returns {any[]}
   */
  const transformSds = function (sds) {
    let sdsSelectData = [];
    if (!isSdsLoading) {
      sds.forEach(function (row) {
        sdsSelectData.push({
          key: row.chemical.id,
          label: (row.chemical.language_variants[0].name ?? "Chemical " + row.chemical.id)
        });
      });
    }
    return sdsSelectData;
  }

  return (
    <>
      <Form onSubmit={submit}>
        <Wrapper>
          {locationId !== "" && locationId >= 0 && (
            <div id="location-filter" className="filter-holder">
              <h2>{intl.formatMessage({id: 'common.location'})}</h2>
              <Field
                id="location_ids"
                Component={Tree}
                addSelectIcon={true}
                label={intl.formatMessage({id: 'qr-codes.modal-choose-place'})}
                required={false}
                isLoading={orgLocationsLoading}
                options={transformLocations(locationData ?? [])}
                multiselectAll={false}
                multiselect={false}
                toggleItem={true}
                onChange={selectLocation}
                value={currentLocation}
              />
            </div>
          )}

          {locationId === "" && (
            // Products has built in multi-select support because it might
            // be something they want to explore in the future.
            <div id="sds-filter" className="filter-holder">
              <h2>{intl.formatMessage({id: 'chemicals.product'})}</h2>
              <Field
                id="sds_ids"
                Component={Tree}
                addSelectIcon={true}
                label={intl.formatMessage({id: 'qr-codes.modal-choose-product'})}
                required={false}
                isLoading={isSdsLoading}
                options={transformSds(sds ?? [])}
                multiselectAll={false}
                multiselect={false} // Has multi-select support
                onChange={selectSds}
                value={currentSds}
              />
            </div>
          )}
        </Wrapper>
      </Form>
      <ModalActionsFooter>
        {error && (
          <>
            <IconError className="pi pi-times"/>
            {error}
          </>
        )}
        <Spacer/>
        <Button label={intl.formatMessage({id: 'common.cancel'})} onClick={() => modalController.close()}/>
        <Button
          primary
          label={modalData.id ? intl.formatMessage({id: 'common.save'}) : intl.formatMessage({id: 'qr-codes.create-qr-code'})}
          onClick={(e) => {
            submit(e)
          }}
        />
      </ModalActionsFooter>
    </>
  );
};

export default CreateQrModal;
