import React, { useEffect, useRef, useState } from 'react'

import * as pdfjs from 'pdfjs-dist'
import styled from 'styled-components'
import { ProgressSpinner } from 'primereact/progressspinner'
import { FormattedMessage } from 'react-intl'

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'

const PdfContainer = styled.div`
  overflow-y: auto;
  height: 100%;

  ${ProgressSpinner} {
    margin-left: auto;
    margin-right: auto;
  }

  .canvas-container > div {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    position: relative;

    .page {
      margin-left: auto;
      margin-right: auto;
      top: 0;
    }

    .text-layer {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      opacity: 0.2;
      line-height: 1;
    }

    .text-layer > span {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
    }
  }
`

export const PdfViewer = ({ src, isTextSelect }) => {
  const canvasContainerRef = useRef(null)
  const [pdf, setPdf] = useState(null)
  const [maxPage, setMaxPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchPdf = async () => {
      const pdfDone = (pdf) => {
        setMaxPage(pdf.numPages)
        setPdf(pdf)
        setIsLoading(false)
      }

      if (src instanceof File) {
        var fileReader = new FileReader()
        fileReader.onerror = setError
        fileReader.onload = () => {
          const typedarray = new Uint8Array(fileReader.result)
          const loadingTask = pdfjs.getDocument(typedarray)
          loadingTask.promise
            .then((pdf) => {
              pdfDone(pdf)
            })
            .catch((ex) => {
              setError(ex)
            })
        }
        fileReader.readAsArrayBuffer(src)
      } else {
        const loadingTask = pdfjs.getDocument(src)
        const pdf = await loadingTask.promise
        pdfDone(pdf)
      }
    }

    try {
      fetchPdf()
    } catch (e) {
      setError(e)
    }
    setIsLoading(true)
  }, [src])

  return (
    <PdfContainer>
      {error ? (
        <FormattedMessage id="common.pdfjs_error"/>
      ) : isLoading ? (
        <ProgressSpinner/>
      ) : (
        <div className="canvas-container" ref={canvasContainerRef}>
          {Array(maxPage)
            .fill()
            .map((i, index) => (
              <PdfPage key={index} pdf={pdf} pageNumber={index + 1} isTextSelect={isTextSelect}/>
            ))}
        </div>
      )}
    </PdfContainer>
  )
}

const PdfPage = ({ pdf, pageNumber, isTextSelect }) => {
  const canvasRef = useRef(null)
  const textLayerDivRef = useRef(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const renderPage = async () => {
      const page = await pdf.getPage(pageNumber)
      const scale = 1
      const viewport = page.getViewport({ scale: scale })
      const canvas = canvasRef.current

      if (canvas) {
        const context = canvas.getContext('2d')
        canvas.height = Math.round(viewport.height)
        canvas.width = Math.round(viewport.width)

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
          enableWebGL: true
        }
        const renderTask = page.render(renderContext)
        await renderTask.promise

        canvas.style.height = Math.round(viewport.height) + 'px'
        canvas.style.width = Math.round(viewport.width) + 'px'
        const textLayerDiv = textLayerDivRef.current
        if (textLayerDiv) {
          textLayerDiv.style.height = Math.round(viewport.height) + 'px'
          textLayerDiv.style.width = Math.round(viewport.width) + 'px'
          textLayerDiv.style.top = canvas.getBoundingClientRect().top
          textLayerDiv.style.left = canvas.getBoundingClientRect().left

          const textContent = await page.getTextContent()
          const renderTextTask = pdfjs.renderTextLayer({
            textContent: textContent,
            container: textLayerDiv,
            viewport: viewport,
            textDivs: []
          })
          await renderTextTask.promise
        }
      }
    }
    if (pdf) {
      try {
        setError(null)
        renderPage()
      } catch (e) {
        setError(e)
      }
    }
  }, [pdf, pageNumber])
  return (
    <div>
      {error ? (
        <FormattedMessage id="common.pdfjs_error"/>
      ) : (
        <>
          <canvas className="page" ref={canvasRef} width="100%" height="100%"/>
          {isTextSelect && <div className="text-layer" ref={textLayerDivRef}></div>}
        </>
      )}
    </div>
  )
}
