import React from 'react'

import { CenterInfoPanel } from 'Components/layout'
import { Panel } from 'Components/readonly'

const SessionEndedPage = () => {
  return (
    <CenterInfoPanel>
      <Panel>
        <h3>Sessionen har gått ut.</h3>
        <p>
          <a href="/">Logga in igen</a>
        </p>
      </Panel>
    </CenterInfoPanel>
  )
}

export default SessionEndedPage
