import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import axios from 'axios'

import { Button } from 'Components/input'
import { InfoPanel, ModalActionsFooter } from 'Components/readonly'
import { FlexRowCenterHorizontal, Section, Spacer } from 'Components/layout'
import { GrowlContext } from 'Context'

export default ({ modalController, onSuccess }) => {
  const intl = useIntl()
  const inventory = modalController.data
  const [isLoading, setIsLoading] = useState(false)
  const { displayError } = useContext(GrowlContext)

  const send = async () => {
    setIsLoading(true)
    try {
      const response = await axios.post('/rapi/inventorying/' + inventory.id + '/approve')
      onSuccess(response.data.data)
      setIsLoading(false)
      modalController.close()
    } catch (error) {
      displayError(error)
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Section>
        <FlexRowCenterHorizontal>
          <InfoPanel messageid="inventory.approve-inventory-text"/>
        </FlexRowCenterHorizontal>
      </Section>
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button
          primary
          icon="pi pi-arrow-circle-right"
          label={intl.formatMessage({ id: 'inventory.approve' })}
          onClick={send}
          isLoading={isLoading}
        />
      </ModalActionsFooter>
    </div>
  )
}
