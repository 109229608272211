import { FlexColumn, FlexRow } from 'Components/layout'
import { Tooltip } from 'Components/readonly'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import IconChemicalManagementInactive from './assets/ico-cm-inactive.svg'
import RequestModuleDemo from './requestModuleDemo'
import { AbsoluteAnchor, DisabledInfoIcon, InfoButton, Service, ServiceIcon, ServicePanel } from './startPageStyle'

const FlexRowClickThrough = styled(FlexRow)`
  pointer-events: none;
`

const Wrapper = styled(FlexColumn)`
  height: 100%;
`

const StartChemModuleDisabled = ({ requestDemo }) => {
  const intl = useIntl()

  return (
    <Wrapper>
      <Service>
        <ServicePanel disabled>
          <Tooltip content={intl.formatMessage({ id: 'welcome.service-info' })}>
            <InfoButton link label={<DisabledInfoIcon/>}/>
          </Tooltip>

          <FlexRowClickThrough>
            <AbsoluteAnchor>
              <ServiceIcon>
                <img src={IconChemicalManagementInactive} alt="Chemical management"/>
              </ServiceIcon>
              <span className="service-type">EASY</span>
              <h2>
                <FormattedMessage id="chemicals.module"/>
              </h2>
              <span className="service-author">by KemRisk</span>
            </AbsoluteAnchor>
          </FlexRowClickThrough>
        </ServicePanel>
      </Service>
      <RequestModuleDemo module="chemical_list"/>
    </Wrapper>
  )
}

export default StartChemModuleDisabled
