import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as adminPages from './admin'
import { SettingsPage } from './settings/settingsPage'
import StartPage from './start/startPage'
import SdsPage from './sds/sdsPage'
import SdsIframePage from './sds/sdsIframePage'
import RevisionPage from './revisionMaintenance/revisionPage'
import RevisionSubscriptionPage from './revisionSubscription/revisionSubscriptionPage'
import * as chemicalListPages from './chemicalList'
import LocationsPage from './locations'
import RiskAssessmentPage from './chemicalRisk/riskAssessmentPage'
import AuthorizedRoute from 'Components/authorizedRoute'
import ErrorBoundaryRoute from 'Components/errorBoundaryRoute'
import HygieneThresholdsPage from './hygieneThresholds/hygieneThresholdPage'
import QrCodesPage from './qrCodes/QrCodesPage'
import * as inventoryPages from './inventory'
import * as faqPages from './faq'
import * as codePages from './codesSymbols'
import { TermsModal } from './termsModal'

const AppRouter = ({ admin }) => {
  return (
    <>
      <TermsModal/>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/start"/>}/>
        <ErrorBoundaryRoute path="/settings/:tab?" component={SettingsPage}/>
        <ErrorBoundaryRoute path="/start" component={admin ? adminPages.StartPage : StartPage}/>
        <AuthorizedRoute authorize="organisation.has_sds" path="/sds" component={SdsPage}/>
        <AuthorizedRoute
          authorize="organisation.has_sds && organisation.weblink && capabilities.manage_sds"
          path="/sdsIframe"
          component={SdsIframePage}
        />
        <AuthorizedRoute
          authorize="organisation.has_sds_revision && capabilities.manage_revisions"
          path="/sdsRevisionMaintenance"
          component={RevisionPage}
        />
        <AuthorizedRoute
          authorize="organisation.has_sds_revision_subscription && capabilities.manage_revisions"
          path="/sdsRevisionSubscription/:mode(active|done)"
          component={RevisionSubscriptionPage}
        />
        <AuthorizedRoute
          authorize="organisation.has_chemical_list"
          checkMarketAccess={true}
          path="/chemicalList"
          component={chemicalListPages.ChemicalListPage}
        />
        <AuthorizedRoute
          authorize="organisation.has_chemical_list"
          checkMarketAccess={true}
          path="/addProduct"
          component={chemicalListPages.AddProductPage}
        />
        <AuthorizedRoute
          authorize="organisation.has_chemical_list"
          isRedirectOnOrgChange={true}
          isRedirectOnMarketChange={true}
          path="/product/:id"
          component={chemicalListPages.ProductPage}
        />
        <AuthorizedRoute authorize="organisation.has_chemical_list" checkMarketAccess={true} path="/locations"
                         component={LocationsPage}/>
        <AuthorizedRoute
          authorize="organisation.has_chemical_list"
          checkMarketAccess={true}
          path="/chemicalRisk"
          component={RiskAssessmentPage}
        />
        <AuthorizedRoute
          authorize="organisation.has_chemical_list"
          checkMarketAccess={true}
          path="/hygieneThresholds"
          component={HygieneThresholdsPage}
        />
        <AuthorizedRoute
          authorize="organisation.has_inventorying"
          checkMarketAccess={true}
          exact
          path="/inventory/:mode(active|done)"
          component={inventoryPages.InventoryPage}
        />
        <AuthorizedRoute
          authorize="organisation.qr_enabled"
          checkMarketAccess={true}
          path="/qrCodes"
          component={QrCodesPage}
        />
        <AuthorizedRoute
          exact
          authorize="organisation.has_inventorying"
          isRedirectOnMarketChange={true}
          isRedirectOnOrgChange={true}
          checkMarketAccess={true}
          path="/inventory/done/:id"
          component={inventoryPages.InventoryDoneDetailPage}
        />
        <AuthorizedRoute
          authorize="organisation.has_inventorying"
          isRedirectOnOrgChange={true}
          isRedirectOnMarketChange={true}
          checkMarketAccess={true}
          path="/inventory/:id"
          component={inventoryPages.InventoryDetailPage}
        />
        <Route path="/faq" component={faqPages.FaqPage}/>
        <Route path="/codes" component={codePages.CodesSymbolsPage}/>

        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/editStartPage"
                         component={adminPages.EditStartPagePage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/messages"
                         component={adminPages.OrgMessagesPage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/faq" component={adminPages.EditFaqPage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/settings/:tab?" component={SettingsPage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/products"
                         component={adminPages.ProductsPage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/organisations"
                         component={adminPages.OrganisationsPage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/filter" component={adminPages.FilterPage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/ingredients"
                         component={adminPages.IngredientsPage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/log" component={adminPages.LogPage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/strayUsers"
                         component={adminPages.StrayUsersPage}/>
        <AuthorizedRoute authorize="user.actingAsSuperAdmin" path="/admin/reports" component={adminPages.ReportsPage}/>
        <AuthorizedRoute
          authorize="user.actingAsSuperAdmin"
          path="/admin/sdsRevisionSubscription/:mode(active|done)"
          component={RevisionSubscriptionPage}
          componentProps={{ admin: true }}
        />
      </Switch>
    </>
  )
}

export default AppRouter
