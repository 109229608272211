import { useContext, useEffect, useState } from 'react'
import axios from 'axios'

import { GrowlContext } from 'Context'

const useGetData = (url, initialValue, observe, transform, debounce = false) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(initialValue)
  const { displayError } = useContext(GrowlContext)

  useEffect(
    () => {
      const source = axios.CancelToken.source()
      const fetchData = async () => {
        setIsLoading(true)
        try {
          const result = await axios(url, { cancelToken: source.token })
          if (transform) {
            setData(transform(result.data))
          } else if (result.data.data) {
            // Laravel resources wrapped in data
            setData(result.data.data)
          } else {
            setData(result.data)
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            return
          }
          setData(initialValue)
          displayError(error)
        }
        setIsLoading(false)
      }

      if (url && !debounce) {
        fetchData()
      }

      return function () {
        source.cancel('Cancelling in cleanup')
      }
    }, // eslint-disable-next-line
    observe ? [url].concat(observe) : [url]
  )

  return [isLoading, data, setData]
}

export default useGetData
