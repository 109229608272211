import React from 'react'

import { Dropdown } from 'Components/input'
import { useIntl } from 'react-intl'

const ThemeDropdown = (props) => {
  const intl = useIntl()

  const themeTypes = [
    { label: intl.formatMessage({ id: 'common.dark' }), value: 'dark' },
    { label: intl.formatMessage({ id: 'common.light' }), value: 'light' }
  ]

  return <Dropdown options={themeTypes} {...props} />
}

export default ThemeDropdown
