import styled from 'styled-components'
import colors from '../colors.scss'

import { TabView as PrimeTabView } from 'primereact/tabview'

const tabConfig = {
  topBorderHeight: '5px',
  tabWidth: '190px',
  tabHeight: '70px'
}
export const TabView = styled(PrimeTabView)`
  &&& {
    position: relative;

    & .p-tabview-nav {
      margin: 0;
      display: flex;
      overflow: hidden;
      overflow-x: auto;

      @media (max-width: 640px) {
        overflow-x: scroll;
      }

      &:after {
        width: 40px;
        height: 70px;
        background: linear-gradient(90deg, rgba(65, 64, 74, 0) 0%, rgba(65, 64, 74, 1) 100%);
        right: 0;
        position: absolute;
      }
    }

    /* Tab headers */

    & .p-tabview-nav li {
      white-space: normal;

      /* focus effect */

      a:not(.p-disabled):focus {
          /*box-shadow: 0 0 0 1px ${colors.primary}; uncomment for focus shadow effect */
        box-shadow: none;
      }

      /* tab text */

      .p-tabview-title {
        color: ${colors.text};
        white-space: initial;
        font-size: 15px;
      }

      .mark .p-tabview-title::after {
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${colors.red};
        display: inline-block;
        margin-left: 10px;
      }

      /* non-selected list item */

      :not(.p-highlight):not(.p-disabled):hover a {
        border: none;
        background-color: ${colors.darkBackgroundFaded};
        border-top: ${tabConfig.topBorderHeight} solid ${colors.darkBackgroundFaded};

        color: white;
      }

      a {
        border: none;
        border-radius: 0;
        border-top: ${tabConfig.topBorderHeight} solid rgb(112, 111, 121);
        background-color: rgb(112, 111, 121);
        width: ${tabConfig.tabWidth};
        height: ${tabConfig.tabHeight};
        transition: background-color 0.3s, border-color 0.3s;

        @media screen and (max-width: 768px) {
          .p-tabview-title {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            font-size: 14px;
          }
        }

        @media screen and (min-width: 1600px) {
          width: 220px;
        }

        &:hover {
          border: none;
          background-color: transparent;
          border-top: ${tabConfig.topBorderHeight} solid ${colors.lightBackgroundColor};
        }
      }

      /* selected tab */

      &.p-highlight {
        border: none;
        border-radius: 0;
        background-color: transparent;

        &:hover {
          border: none;
          border-radius: 0;

          a {
            border: none;
            background-color: ${colors.darkBackgroundFaded};
            border-top: ${tabConfig.topBorderHeight} solid ${colors.primary};
          }
        }

        a {
          border: none;
          background-color: ${colors.darkBackgroundFaded};
          border-top: ${tabConfig.topBorderHeight} solid ${colors.primary};
        }
      }

      &.error {
        a,
        a:hover {
          border-color: ${colors.validationFailedFieldMarker};
        }
      }

      &.hidden {
        display: none;
      }
    }

    /* Tab content */

    & .p-tabview-panels {
      border: none;
      border-radius: 0;
      background-color: ${colors.darkBackgroundFaded};
      padding-top: 20px;
    }
  }
`
