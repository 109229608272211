import React, { useState } from 'react'
import axios from 'axios'
import { PageConstraint, PageHeader } from 'Components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import { Column } from 'primereact/column'
import { arrayCountBody, Button, DataTable, dateBody, Modal, richTextBody } from 'Components/readonly'
import useModalController from 'Hooks/useModalController'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import { GrowlContext } from 'Context'
import { MessageSidebar } from './messageSidebar'
import useCrudData from 'Hooks/useCrudData'

const url = '/rapi/admin/organisationmessages'

export const OrgMessagesPage = () => {
  const intl = useIntl()
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [deleteModalController] = useModalController({ title: intl.formatMessage({ id: 'common.delete' }) })
  const [messageSidebarVisible, setMessageSidebarVisible] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [isLoadingMessages, messages, addMessage, , , deleteMessage] = useCrudData(url)
  const [expandedRows, setExpandedRows] = useState([])

  const deleteAction = async (data) => {
    try {
      const result = await axios.delete(url + '/' + data.id)
      displaySuccess(result.data.message)
      deleteMessage(data)
    } catch (error) {
      displayError(error)
    }
  }

  const deleteBody = (row) => (
    <Button
      primary
      icon="pi pi-trash"
      onClick={() => {
        deleteModalController.open({ data: row })
      }}
    />
  )

  const handleSuccess = (message) => {
    addMessage(message)
    setMessageSidebarVisible(false)
  }

  const rowExpansionTemplate = (row) => (
    <ol>
      {row.organisations.map((organisation) => (
        <li>{organisation.name}</li>
      ))}
    </ol>
  )

  return (
    <PageConstraint>
      <MessageSidebar
        message={selectedMessage}
        setSelectedMessage={setSelectedMessage}
        messageSidebarVisible={messageSidebarVisible}
        setMessageSidebarVisible={setMessageSidebarVisible}
        onSuccess={handleSuccess}
      />
      <Modal
        modalController={deleteModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="orgmessages.delete_message_text"
        confirmTextId="common.delete"
        onConfirm={deleteAction}
      />
      <PageHeader>
        <h1>
          <FormattedMessage id="orgmessages.title"/>
        </h1>
        <Button
          primary
          label={intl.formatMessage({ id: 'orgmessages.new' })}
          icon="pi pi-plus"
          onClick={() => {
            setMessageSidebarVisible(true)
          }}
        />
      </PageHeader>
      <DataTable
        className="flextable"
        value={messages}
        paginator={true}
        rows={10}
        sortField="created_at"
        sortOrder={-1}
        loading={isLoadingMessages}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="id">
        <Column expander={true} style={{ width: '2em' }}/>
        <Column field="created_at" body={dateBody} header={intl.formatMessage({ id: 'common.sent' })} sortable={true}/>
        <Column field="organisations" body={arrayCountBody} header={intl.formatMessage({ id: 'admin.customers' })}/>
        <Column field="users_count" header={intl.formatMessage({ id: 'common.user' })}/>
        <Column field="users_dismissed_count" header={intl.formatMessage({ id: 'orgmessages.dismissed' })}/>
        <Column field="heading" header={intl.formatMessage({ id: 'common.heading' })}/>
        <Column field="message" body={richTextBody} header={intl.formatMessage({ id: 'common.message' })}/>
        <Column header={intl.formatMessage({ id: 'common.delete' })} body={deleteBody} style={{ width: '5%' }}/>
      </DataTable>
    </PageConstraint>
  )
}
