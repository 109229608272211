import { useState } from 'react'

export default (options) => {
  /**
   * Controller for dialogs. Use with Modal component to create a modal
   * You can pass an options object to this hook or the open function to customize title and send data to the modal
   *
   * open: Open dialog. You can pass an options object to customize title and send data to modal
   * close: Close dialog
   *
   * options: {
   *  title: Modal title (props.modalController.title in content component),
   *  data: Custom data to modal. (props.modalController.data in content component)
   * }
   */
  const [modalState, setModalState] = useState({
    isOpen: false,
    open: (options) => {
      // options: {title, data}
      setModalState({ ...modalState, isOpen: true, ...options })
    },
    close: () => {
      setModalState((prevState) => {
        if (prevState.onClose) prevState.onClose()
        return { ...prevState, isOpen: false, data: null }
      })
    },
    title: (options && options.title) || 'Default title',
    data: (options && options.data) || null
  })

  return [modalState]
}
