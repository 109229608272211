import React, { useContext, useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import { AuthContext } from 'Context'

import { Column } from 'primereact/column'

import { Section, Spacer, ThinHeaderDataTable } from 'Components/layout'
import { SearchInput } from 'Components/input'
import useGetData from 'Hooks/useGetData'

import { TableControls } from './chemicalRiskPageStyle'

import { format, lastDayOfMonth, parse, startOfMonth, subMonths } from 'date-fns'
import { DateRangePicker } from 'Components/input/dateRangePicker'
import { RiskAssessmentContext } from '../context/riskAssessmentContext'
import { globalSearch } from 'Utils/search'

const ArchivedRiskAssessmentList = ({
                                      initialSearch,
                                      selectedRiskAssessment,
                                      setSelectedRiskAssessment,
                                      setRiskSidebarVisible,
                                      intl
                                    }) => {
  // List
  const [inputFilter, setInputFilter] = useState(initialSearch || '')
  const [first, setFirst] = useState(0)

  const { setSelectedProduct } = useContext(RiskAssessmentContext)

  const { organisation } = useContext(AuthContext)
  const [restRiskListIsLoading, restRiskList] = useGetData('/rapi/riskassessments/archived', [], [organisation])

  const handleInputFilter = (event) => {
    setInputFilter(event.target.value)
    setFirst(0)
  }

  const [fromDate, setFromDate] = useState(initialSearch ? null : startOfMonth(subMonths(new Date(), 6)))
  const [toDate, setToDate] = useState(initialSearch ? null : lastDayOfMonth(new Date()))

  const filteredArchive = useMemo(() => {
    setFirst(0)
    let filteredList = globalSearch([...restRiskList], inputFilter, ['applies_to', 'chemical.name', 'created_at', 'archived_at'])
    // If no date filter set return list without filtering
    if (!fromDate && !toDate) {
      return filteredList
    }
    const fromMonth = fromDate ? format(fromDate, 'YYYY-MM-DD') : null
    const toMonth = toDate ? format(toDate, 'YYYY-MM-DD') : null

    return filteredList.filter((riskassessment) => {
      const riskassessmentMonth = format(parse(riskassessment.archived_at), 'YYYY-MM-DD')
      if (fromMonth && riskassessmentMonth.localeCompare(fromMonth) < 0) {
        return false
      }
      if (toMonth && riskassessmentMonth.localeCompare(toMonth) > 0) {
        return false
      }
      return true
    })
  }, [restRiskList, fromDate, toDate, inputFilter])

  const onFromDateChange = (value) => {
    if (value) {
      setFromDate(value)
    }
  }

  const onToDateChange = (value) => {
    if (value) {
      setToDate(value)
    }
  }

  const showRiskAssessment = (row) => {
    const riskAssessment = row.data
    setSelectedRiskAssessment(riskAssessment)
    setSelectedProduct(riskAssessment.chemical)
    setRiskSidebarVisible(true)
    //riskAssessment.originalEvent.stopPropagation()
  }

  return (
    <>
      <Section>
        <TableControls>
          <SearchInput value={inputFilter} onChange={handleInputFilter}/>
          <Spacer/>
          <DateRangePicker toDate={toDate} fromDate={fromDate} onFromDateChange={onFromDateChange}
                           onToDateChange={onToDateChange}/>
        </TableControls>
      </Section>

      <ThinHeaderDataTable
        className="clickable-rows"
        value={filteredArchive}
        paginator={true}
        rows={10}
        first={first}
        onPage={(e) => setFirst(e.first)}
        onRowClick={(row) => showRiskAssessment(row)}
        loading={restRiskListIsLoading}>
        <Column key="applies_to" field="applies_to" header={intl.formatMessage({ id: 'riskassessment.applies_to' })}
                sortable={true}/>
        <Column key="product" field="chemical.name" header={intl.formatMessage({ id: 'chemicals.product' })}
                sortable={true}/>
        <Column key="created_at" field="created_at" header={intl.formatMessage({ id: 'common.created' })}
                sortable={true}/>
        <Column key="archived_at" field="archived_at" header={intl.formatMessage({ id: 'common.archived2' })}
                sortable={true}/>
      </ThinHeaderDataTable>
    </>
  )
}

export default injectIntl(ArchivedRiskAssessmentList)
