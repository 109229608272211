import React from 'react'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { useIntl } from 'react-intl'
import { Spacer } from 'Components/layout'

const HygieneDetailsDialog = ({ modalController }) => {
  const ingredient = modalController.data
  const intl = useIntl()
  return (
    <>
      <h2>{intl.formatMessage({ id: 'common.location' })}</h2>
      <ul>
        {ingredient.locations.map((location, i) => (
          <li key={'l' + i}>{location.name}</li>
        ))}
      </ul>

      <h2>{intl.formatMessage({ id: 'chemicals.products' })}</h2>
      {ingredient.products.length > 0 && (
        <ul>
          {ingredient.products.map((product, i) => (
            <li key={'p' + i}>{product}</li>
          ))}
        </ul>
      )}
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.close' })} onClick={() => modalController.close()}/>
      </ModalActionsFooter>
    </>
  )
}

export default HygieneDetailsDialog
