import React, { useContext, useMemo, useState } from 'react'
import axios from 'axios'

import { Field, Form } from 'Components/form'
import { Button, Divider, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'

import { injectIntl } from 'react-intl'
import { LocationTree } from 'Components/input'
import Packages from 'Components/combined/packages'
import _ from 'lodash'

const AddLocationDialog = ({ modalController, onSuccess, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displayError } = useContext(GrowlContext)

  const product = modalController.data

  const onSubmit = async (formData) => {
    setIsLoading(true)
    try {
      let inv = formData.inventory.filter((inv) => inv.amount_opened !== '' || inv.amount !== '')
      inv = inv.map((inv) => {
        if (inv.amount_opened === '') {
          inv.amount_opened = '0'
        }
        if (inv.amount === '') {
          inv.amount = '0'
        }
        return inv
      })

      let addLocationObject = { product_id: product.id, location_id: formData.location, inventory: inv }
      const response = await axios.post('/rapi/chemicals/addtolocation', addLocationObject)
      setIsLoading(false)
      onSuccess(response.data)
      modalController.close()
    } catch (error) {
      displayError(error)
      setIsLoading(false)
    }
  }

  const inventory = useMemo(() => {
    let p = []
    product.locations.forEach((location) => {
      location.inventory.forEach((inventory) => {
        let pack = {
          is_bulk: inventory.is_bulk,
          unit: inventory.unit,
          unit_id: inventory.unit_id,
          packaging_size: inventory.packaging_size,
          id: inventory.id,
          amount_opened: '',
          amount: '',
          is_suggestion: true
        }
        if (!_.find(p, { packaging_size: pack.packaging_size, unit_id: pack.unit_id })) {
          p.push(pack)
        }
      })
    })
    return p
  }, [product])

  const validateInventory = (value, formData) => {
    if (_.isEmpty(value)) {
      return true
    }
    let errors = []
    value.forEach((v, i) => {
      let e = { index: i }
      let hasErrors = false
      if (!v.is_suggestion) {
        if (!v.is_bulk && v.packaging_size === '') {
          e.packaging_size = true
          hasErrors = true
        }
        if (v.unit_id === '') {
          e.unit_id = true
          hasErrors = true
        }
        if (v.amount === '') {
          e.amount = true
          hasErrors = true
        }
        if (hasErrors) {
          errors.push(e)
        }
      }
    })

    if (errors.length > 0) {
      return errors
    }

    return !value.some((v) => v.amount || v.amount_opened)
  }

  return (
    product &&
    inventory && (
      <Form onSubmit={onSubmit}>
        <div style={{ padding: '10px' }}>
          <Field
            id="location"
            Component={LocationTree}
            appendToBody={true}
            label={intl.formatMessage({ id: 'locations.choose_location' })}
            required={true}
          />
          <Divider/>
          <Field
            id="inventory"
            Component={Packages}
            label={intl.formatMessage({ id: 'chemicals.packages' })}
            initialValue={inventory}
            validation={validateInventory}
            hasErrorHandling={true}
          />
        </div>
        <ModalActionsFooter>
          <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => modalController.close()}/>
          <Button primary submit label={intl.formatMessage({ id: 'common.add' })} isLoading={isLoading}/>
        </ModalActionsFooter>
      </Form>
    )
  )
}

export default injectIntl(AddLocationDialog)
