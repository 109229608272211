import React, { useContext } from 'react'
import styled from 'styled-components'
import colors from 'Components/colors.scss'
import { FormattedMessage } from 'react-intl'

import useGetData from 'Hooks/useGetData'
import { AuthContext } from 'Context'

export const InventoryLockMessage = styled(({ ...props }) => {
  const { user, organisation } = useContext(AuthContext)
  const [isLoading, lockedLocations] = useGetData('/rapi/inventorying/locationslockedforinventorying', [], [user, organisation]) // eslint-disable-line

  let { markets } = lockedLocations
  const appliesToUserMarket = markets?.some(item => user?.user?.active_market.includes(item));

  return (
    lockedLocations?.locations?.length > 0 && appliesToUserMarket ? (
      <div {...props}>
        <FormattedMessage id="inventory.lock-warning"/>
      </div>
    ) : null
  )
})`
  background-color: ${colors.accent};
  color: ${colors.white};
  padding: 5px 25px;
`
