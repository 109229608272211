import React from 'react'

import { Divider, Panel } from 'Components/readonly'
import { Section } from 'Components/layout'
import { FormattedMessage } from 'react-intl'
import InventoryDoneDetailLocationInventory from './inventoryDoneDetailLocationInventory'

export const InventoryDoneDetailLocations = ({ inventoryLocations, addProduct }) => {
  return (
    <>
      {inventoryLocations.map((inventoryLocation, locationIndex) => (
        <div key={inventoryLocation.id}>
          <Section>
            <h2>{inventoryLocation.location}</h2>
          </Section>
          <Section>
            <Panel>
              {inventoryLocation.chemicals.length === 0 && (
                <div style={{ paddingTop: '10px' }}>
                  <FormattedMessage id="inventory.no-products-at-location"/>
                </div>
              )}
              {inventoryLocation.chemicals.length > 0 &&
                inventoryLocation.chemicals.map((chemical, chemicalIndex) => (
                  <InventoryDoneDetailLocationInventory
                    key={chemicalIndex}
                    inventoryingChange={inventoryLocation.inventorying_change}
                    chemical={chemical}
                    inventoryProp={chemical.inventory}
                  />
                ))}
            </Panel>
          </Section>

          <Divider/>
        </div>
      ))}
    </>
  )
}
