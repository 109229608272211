import React from 'react'
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from 'sanitize-html'
import styled from 'styled-components'

import colors from '../colors.scss'
import { FormattedMessage } from 'react-intl'
import { RelativeAnchor } from 'Components/layout'

const lineHeight = 19

const Placeholder = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  color: ${colors.disabledWhite};
  pointer-events: none;
`

const StyledContentEditable = styled(ContentEditable)`
  line-height: ${lineHeight}px;
  overflow: auto;
  min-height: ${(props) => lineHeight * props.minRows}px;
  max-height: ${(props) => lineHeight * props.maxRows}px;

  width: 100%;
  border-radius: 20px;
  padding: 7px 15px;

  background-color: ${(props) => (props.theme === 'light' && colors.lightWhite) || colors.white};
  border: 2px solid ${(props) => (props.theme === 'light' && colors.lightWhite) || colors.white};
  color: ${(props) => (props.theme === 'light' && colors.black) || colors.black};

  &:hover:not(.p-disabled) {
    border-color: ${colors.primary};
  }

  &.ui-state-error {
    border-color: ${colors.validationFailedFieldMarker};
  }

  &:focus {
    box-shadow: none;
    border-color: ${colors.primary};
    outline: none;
  }
`

const sanitizeConf = {
  allowedTags: ['b', 'i', 'em', 'u', 'strong', 'sub', 'sup', 'div', 'br'],
  allowedAttributes: {}
}

export const RichTextArea = ({
  id,
  className,
  value,
  onChange,
  onKeyDown,
  disabled,
  placeholder,
  minRows = 2,
  maxRows = 10,
  maxLength = Infinity
}) => {
  const onChangeInternal = (e) => {
    const sanitized = sanitizeHtml(e.target.value, sanitizeConf).replace(/<br \/>/g, '<br>')
    onChange({ target: { value: sanitized.substring(0, maxLength) } })
  }

  document.execCommand('defaultParagraphSeparator', false, 'div')

  const handleKeyDown = (e) => {
    if (e.altKey) {
      if (e.key === '+') {
        document.execCommand('styleWithCSS', false, false)
        document.execCommand('superscript', false)
      } else if (e.key === '-') {
        document.execCommand('styleWithCSS', false, false)
        document.execCommand('subscript', false)
      }
    }

    if ((e.ctrlKey || e.metaKey) && e.key === 'b') {
      document.execCommand('bold', false)
      e.stopPropagation()
    }
    if ((e.ctrlKey || e.metaKey) && e.key === 'i') {
      document.execCommand('italic', false)
      e.stopPropagation()
    }
    if ((e.ctrlKey || e.metaKey) && e.key === 'u') {
      document.execCommand('underline', false)
      e.stopPropagation()
    }

    if ((e.ctrlKey || e.metaKey) && !['a', 'c', 'v', 'x', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
      e.preventDefault()
    }

    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  return (
    <RelativeAnchor>
      {!value && placeholder && (
        <Placeholder>
          <FormattedMessage id={placeholder} />
        </Placeholder>
      )}
      <StyledContentEditable
        className={className}
        minRows={minRows}
        maxRows={maxRows}
        id={id}
        disabled={disabled}
        html={value}
        onChange={onChangeInternal}
        onKeyDown={handleKeyDown}
        maxLength={maxLength}
      />
    </RelativeAnchor>
  )
}
