import * as React from 'react'
import axios from 'axios'

import { GrowlContext } from 'Context'
import { RadioButtonGroup } from 'Components/input'
import { Column, DataTableWithSearch } from 'Components/readonly'
import { FormattedMessage, useIntl } from 'react-intl'
import { PickListAutocomplete } from 'Components/combined/pickListAutocomplete'
import { Section } from 'Components/layout'
import useGetData from 'Hooks/useGetData'
import styled from 'styled-components'

const OrgFilterStyle = styled.div`
  padding: 0;

  ${Section} {
    margin-bottom: 10px;

    > label {
      color: #fefefe;
      margin-bottom: 5px;
    }
  }

  h4 {
    padding-top: 0;
  }
`

const emptyFilter = {
  module: null,
  kind: null,
  products: []
}

export default ({ value, onChange }) => {
  const intl = useIntl()
  const { displayError } = React.useContext(GrowlContext)
  const [isLoading, setIsLoading] = React.useState(false)
  const [organisations, setOrganisations] = React.useState([])
  const [filter, setFilter] = React.useState(emptyFilter)

  const [isProductsListLoading, productsList] = useGetData('/rapi/admin/organisationmessages/products')

  const moduleOptions = [
    { value: null, label: intl.formatMessage({ id: 'common.doesntmatter' }) },
    { value: 'sds', label: intl.formatMessage({ id: 'sds.module' }) },
    { value: 'chemical_list', label: intl.formatMessage({ id: 'chemicals.module' }) }
  ]

  const kindOptions = [
    { value: null, label: intl.formatMessage({ id: 'common.doesntmatter' }) },
    { value: 'piece', label: intl.formatMessage({ id: 'organisation.kind_piece' }) },
    { value: 'maintenance', label: intl.formatMessage({ id: 'organisation.kind_maintenance' }) },
    { value: 'subscription', label: intl.formatMessage({ id: 'organisation.kind_subscription' }) }
  ]

  React.useEffect(() => {
    updateOrganisations(emptyFilter)
    // Only run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateOrganisations = async (newFilter) => {
    setIsLoading(true)
    try {
      const result = await axios.post('/rapi/admin/organisationmessages/filterorganisations', newFilter)
      setOrganisations(result.data)
      handleChange(
        newFilter.module,
        result.data.filter((organisation) => value.organisations.includes(organisation.id)),
        newFilter.products
      )
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  const changeOrganisationsFilter = (prop, filterValue) => {
    const newFilter = { ...filter, [prop]: filterValue }
    setFilter(newFilter)
    updateOrganisations(newFilter)

    if (prop === 'products') {
      handleChange(newFilter.module, value.organisations, newFilter.products)
    }
  }

  const handleChange = (module, organisations, products) => {
    const newValue = {
      module: module,
      organisations: organisations.map((organisation) => organisation.id),
      products: products.map((product) => product.value)
    }
    onChange({
      target: {
        value: newValue
      }
    })
  }

  return (
    <OrgFilterStyle>
      <Section>
        <label>
          <FormattedMessage id="orgmessages.orgs-with-module"/>
        </label>
        <RadioButtonGroup
          id="module"
          options={moduleOptions}
          value={filter.module}
          onChange={(e) => changeOrganisationsFilter('module', e.target.value)}
        />
      </Section>

      {(filter.module === null || filter.module === 'sds') && (
        <Section>
          <label>
            <FormattedMessage id="organisation.kind"/>
          </label>
          <RadioButtonGroup
            id="kind"
            options={kindOptions}
            value={filter.kind}
            onChange={(e) => changeOrganisationsFilter('kind', e.target.value)}
          />
        </Section>
      )}
      {(filter.module === null || filter.module === 'chemical_list') && (
        <Section>
          <label>
            <FormattedMessage id="chemicals.orgs-with-any-products"/>
          </label>
          <PickListAutocomplete
            id="products"
            uppercaseOnly={false}
            list={productsList}
            isLoading={isProductsListLoading}
            value={filter.products}
            onChange={(e) => changeOrganisationsFilter('products', e.target.value)}
          />
        </Section>
      )}
      <h4>
        <FormattedMessage id="orgmessages.select-orgs"/>
      </h4>
      <Section>
        <DataTableWithSearch
          className="flextable"
          value={organisations}
          paginator={true}
          rows={10}
          loading={isLoading}
          selection={organisations.filter((o) => value.organisations.includes(o.id))}
          onSelectionChange={(e) => handleChange(filter.module, e.value, filter.products)}>
          <Column selectionMode="multiple" style={{ width: '2em' }}/>
          <Column field="name" header={intl.formatMessage({ id: 'common.name' })}/>
        </DataTableWithSearch>
      </Section>
      {value && value.organisations && organisations && (
        <FormattedMessage
          id="orgmessages.selected-orgs-count"
          values={{ selected: value.organisations.length, total: organisations.length }}
        />
      )}
    </OrgFilterStyle>
  )
}
