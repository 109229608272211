import { HasCapability, useCapability } from 'Components/capabilities'
import colors from 'Components/colors.scss'
import { Modal } from 'Components/readonly'
import useDebounce from 'Hooks/useDebounce'
import useModalController from 'Hooks/useModalController'
import useNodeSize from 'Hooks/useNodeSize'
import React, { useEffect, useRef, useState } from 'react'
import { injectIntl } from 'react-intl'
import { animated, config, Spring } from 'react-spring/renderprops'
import styled from 'styled-components'
import { ReactComponent as FlaskSolidIcon } from './flask-solid.svg'
import LocationDialog from './locationDialog'
import { ReactComponent as PlusCircleIcon } from './plus-circle.svg'
import SvgButton from './svgButton'
import { ReactComponent as TrashIcon } from './trash.svg'
import ReactDOM from 'react-dom'

const MeasureNodeSize = styled.span`
  position: absolute;
  visibility: hidden;
  font-size: 12px;
`

const InputWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 2px;

  input {
    background-color: #1a1c2d;
    border: 2px solid #fff;
    border-radius: 10px;
    color: #c9c9c9;
    text-align: center;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    font-size: 12px;

    &:hover {
      border-color: #fff;
    }

    &:focus {
      box-shadow: none;
      border-color: ${colors.primary};
      outline: none;
    }
  }
`
const EditWrapper = styled.svg`
  &&& {
    cursor: pointer;

    rect {
      fill: #1a1c2d;
      stroke: #fff;
      stroke-width: 2px;
    }

    text {
      font-size: 12px;
    }
  }
`

const PrimeIcon = styled.tspan`
  font-family: 'primeicons';
`

const ChemicalCount = styled.g`
  &&& {
    fill: #ffffff;

    text {
      fill: #ffffff;
    }

    * {
      pointer-events: none;
    }
  }
`

// Borrowed from Modernizr (https://github.com/Modernizr/Modernizr/commit/ee836f083f29a9e634df731400027c24630a75f3)
const toStringFn = {}.toString
const hasSvgForeignObject =
  !!document.createElementNS &&
  /SVGForeignObject/.test(toStringFn.call(document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')))

const Location = ({ intl, location, parent, addLocation, removeLocation, updateLocationName, history, panZoom }) => {
  const [name, setName] = useState(location.name)
  const debouncedName = useDebounce(name, 500)

  const [modalController] = useModalController()
  const hasChemicalManageLocationsCapability = useCapability('CHEMICAL_MANAGE_LOCATIONS')

  useEffect(() => {
    if (!hasSvgForeignObject) {
      return
    }

    if (debouncedName !== location.name) {
      updateLocationName(parent, location, debouncedName)
    }
  }, [debouncedName, parent, location, updateLocationName])

  useEffect(() => {
    if (!hasSvgForeignObject && location.temporary) {
      openDialog(location)
    }
    // Only run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const internalUpdate = (value) => {
    setName(value)
  }

  const goToChemicallist = (location) => {
    let expandedKeys = []
    const getParents = (loc, k) => {
      if (loc.parent_location_id) {
        k.push(parseInt(loc.parent_location_id))
        getParents(loc.parent, k)
      }
    }

    getParents(location, expandedKeys)
    let locationFilter = {
      locationNames: [location.name],
      selectedKeys: [location.id],
      expandedKeys: expandedKeys
    }
    history.push({
      pathname: '/chemicalList',
      state: { location: locationFilter }
    })
  }

  const openDialog = (location) => {
    if (hasChemicalManageLocationsCapability) {
      modalController.open({ title: intl.formatMessage({ id: 'common.edit' }) })
    } else {
      goToChemicallist(location)
    }
  }

  const locationDialogSuccessHandler = (newName) => {
    updateLocationName(parent, location, newName)
  }

  const anyChildrenHasCount = (location) => location.totalChemicalCount > 0 || location.totalUserCount > 0

  const measureNameRef = useRef(null)
  const nameSize = useNodeSize(measureNameRef)
  const editWrapperSize = Math.max(location.r * 2 - 20, nameSize ? nameSize.width : 0)
  const editWrapperWidth = editWrapperSize + 20
  const editWrapperX = -editWrapperWidth / 2
  const textXSpecial = editWrapperWidth / 2

  return (
    <>
      <Modal
        modalController={modalController}
        ContentComponent={LocationDialog}
        onSuccess={locationDialogSuccessHandler}
        model={location}
      />
      {ReactDOM.createPortal(<MeasureNodeSize ref={measureNameRef}>{location.name}</MeasureNodeSize>, document.body)}

      <Spring native config={config.default} from={{ xy: [location.oldX, location.oldY] }}
              to={{ xy: [location.x, location.y] }}>
        {(props) => (
          <>
            <animated.g transform={props.xy.interpolate((x, y) => `translate(${x} ${y})`)}>
              <circle
                cx="0"
                cy="0"
                r={location.r}
                stroke={location.color}
                onClick={() => goToChemicallist(location)}
                style={{ cursor: 'pointer' }}
              />

              <ChemicalCount transform="translate(0, -50)">
                <FlaskSolidIcon x="-9" width="16" height="16"/>
                <text
                  x="0"
                  y="34"
                  textAnchor="middle"
                  fontSize="1.2em"
                  style={{ cursor: 'pointer' }}
                  onClick={() => goToChemicallist(location)}>
                  {location.count}
                  {location.totalChemicalCount > 0 &&
                    location.totalChemicalCount !== location.count &&
                    ' (' + location.totalChemicalCount + ')'}
                </text>
              </ChemicalCount>

              {location.userCount > 0 && (
                <text
                  x="0"
                  y="48"
                  textAnchor="middle"
                  fontSize="1.2em"
                  style={{ cursor: 'pointer' }}
                  onClick={() => goToChemicallist(location)}>
                  <PrimeIcon dy="2">&#xe941;</PrimeIcon>
                  <tspan dx="8" dy="-3">
                    {location.userCount}
                  </tspan>
                </text>
              )}

              {hasSvgForeignObject && hasChemicalManageLocationsCapability ? (
                <foreignObject x={editWrapperX} y="-4" width={editWrapperWidth} height="30">
                  <InputWrapper xmlns="http://www.w3.org/1999/xhtml">
                    <input
                      autoFocus={location.focus}
                      className="p-component"
                      type="text"
                      value={name}
                      onChange={(e) => internalUpdate(e.target.value)}
                      placeholder={intl.formatMessage({ id: 'locations.name_placeholder' })}
                      onFocus={(e) => {
                        if (panZoom) {
                          panZoom.disablePan()
                          panZoom.disableDblClickZoom()
                        }
                      }}
                      onBlur={(e) => {
                        if (panZoom) {
                          panZoom.enablePan()
                          panZoom.enableDblClickZoom()
                        }
                      }}
                    />
                  </InputWrapper>
                </foreignObject>
              ) : (
                <EditWrapper x={editWrapperX} y="0" width={editWrapperWidth} height="24"
                             onClick={() => openDialog(location)}>
                  <rect x="1" y="1" width={editWrapperWidth - 2} height="22" rx="8" ry="8"/>
                  <text x={textXSpecial} y="17" textAnchor="middle">
                    {location.name}
                  </text>
                </EditWrapper>
              )}

              <HasCapability capability="CHEMICAL_MANAGE_LOCATIONS">
                {!location.temporary && (
                  <SvgButton x={location.r - 16} y={-location.r - 8} onClick={(e) => addLocation(location)}>
                    <PlusCircleIcon width="24" height="24"/>
                  </SvgButton>
                )}

                {!anyChildrenHasCount(location) && (
                  <SvgButton x={location.r + 4} y={-location.r - 8} onClick={() => removeLocation(parent, location)}>
                    <TrashIcon width="24" height="24"/>
                  </SvgButton>
                )}
              </HasCapability>
            </animated.g>

            {location.children &&
              location.children.map((l) => (
                <Location
                  intl={intl}
                  key={l.reactKey}
                  location={l}
                  parent={location}
                  addLocation={addLocation}
                  removeLocation={removeLocation}
                  updateLocationName={updateLocationName}
                  history={history}
                  panZoom={panZoom}
                />
              ))}
          </>
        )}
      </Spring>
    </>
  )
}

export default injectIntl(Location)
