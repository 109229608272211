import React from 'react'

import { Section } from 'Components/layout'
import { InventoryDetailHeaderMain } from '.'

export const InventoryDetailHeader = ({ inventory }) => {
  return (
    <Section>
      <InventoryDetailHeaderMain inventory={inventory}/>
    </Section>
  )
}
