import React, { useState } from 'react'
import { Button, Modal, ToolPanel } from 'Components/readonly'
import useModalController from 'Hooks/useModalController'
import { Column } from 'primereact/column'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import useCrudData from 'Hooks/useCrudData'
import AddProductDialog from './dialogs/addProductDialog'
import NewProductDialog from './dialogs/newProductDialog'
import { TableMarkings } from 'Components/readonly/markings'
import { FormattedMessage, injectIntl } from 'react-intl'
import { AuthContext } from 'Context'
import { ThinHeaderDataTable } from 'Components/layout'
import { filter } from 'lodash'

const PageContainer = styled.div`
  display: block;
`

const FilterTableContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Page = ({ inputFilter, intl }) => {
  const { activeMarket, organisation } = React.useContext(AuthContext)
  // eslint-disable-next-line
  const [addProductModalCtrl] = useModalController({ title: intl.formatMessage({ id: 'chemicals.add_product_to_list' }) })
  const [isNewProductDlgOpen, setNewProductDlgOpen] = useState(false)
  const [first, setFirst] = useState(0)
  const [page, setPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [sort, setSort] = useState({ field: 'id', order: -1 })

  const getUrlParams = (page, inputFilter) => {
    let filters = {
      candidate: filter.candidate,
      status: 'ACTIVE',
    }
    let possibleFilters = [
      'he',
      'pd',
      'prio',
      'listingsList',
      'tagList',
      'remarkList',
      'location',
    ];

    // eslint-disable-next-line
    _.each(possibleFilters, (filterProperty) => {
      if (filter[filterProperty] !== undefined && filter[filterProperty].length > 0) {
        // eslint-disable-next-line
        filters[filterProperty] = _.map(filter[filterProperty], 'value');
      }
    })

    let urlParams = {
      page: page,
      filters: filters,
      numItems: 10,
      order_by: sort.field,
      order_direction: sort.order,
    };

    if (inputFilter) {
      urlParams.search = inputFilter
    }

    if (filters.length > 0) {
      urlParams.filters = filters;
    }

    urlParams.filters = JSON.stringify(urlParams.filters);

    return new URLSearchParams(urlParams).toString();
  }

  const [isLoading, chemicals, createChemical, readChemical, updateChemicals] = useCrudData(
    '/rapi/chemicals' +
    ('?' + getUrlParams(page, inputFilter)),
    [],
    checks(),
    (data) => {
      return data
    }
  )

  const marketBody = (row, column) => {
    return intl.formatMessage({ id: 'lang.' + activeMarket })
  }

  const addBody = (row) =>
    row.locations && row.locations.length > 0 ? (
      <FormattedMessage id="chemicals.product_already_added"/>
    ) : (
      <Button
        primary
        icon="pi pi-plus-circle"
        onClick={(event) => {
          addProductModalCtrl.open({ data: row })
        }}
      />
    )

  const onAddProduct = (productId, newLocations) => {
    setReload(!reload);
  }

  function checks() {
    return [organisation, inputFilter, page, reload]
  }

  return (
    <PageContainer>
      <Modal modalController={addProductModalCtrl} ContentComponent={AddProductDialog} onSuccess={onAddProduct}/>

      <NewProductDialog
        isOpen={isNewProductDlgOpen}
        setIsOpen={setNewProductDlgOpen}
        createChemical={onAddProduct}
        isShowLocationsAndPackages={true}
      />

      <FilterTableContainer>
        <ToolPanel>
          <p>
            <FormattedMessage id="chemicals.product_available"/>
          </p>{' '}
          <Button primary label={intl.formatMessage({ id: 'chemicals.register_product' })}
                  onClick={() => setNewProductDlgOpen(true)}/>
        </ToolPanel>
        <ThinHeaderDataTable
          className="flextable"
          value={chemicals.data}
          paginator={true}
          rows={10}
          loading={isLoading}
          first={first}
          lazy={true}
          totalRecords={chemicals.total ? chemicals.total : 0}
          onPage={(e) => {
            setFirst(e.first)
            setPage(e.page + 1)
          }}
          sortField={sort.field}
          sortOrder={sort.order}
          onSort={(e) => {
            setSort({ field: e.sortField, order: e.sortOrder })
          }}>
          <Column
            key="article_number"
            field="article_number"
            header={intl.formatMessage({ id: 'chemicals.article_id' })}
            sortable={true}
          />
          <Column key="name" field="name" header={intl.formatMessage({ id: 'chemicals.product' })} sortable={true}/>
          <Column key="supplier" field="supplier" header={intl.formatMessage({ id: 'common.supplier' })}
                  sortable={true}/>
          <Column
            key="market"
            field="market"
            style={{ width: '130px' }}
            body={marketBody}
            header={intl.formatMessage({ id: 'common.market' })}
            sortable={true}
          />
          <Column
            style={{ width: '100px !important' }}
            body={TableMarkings}
            header={intl.formatMessage({ id: 'common.marking' })}
            sortable={false}
          />
          <Column header={intl.formatMessage({ id: 'common.add' })} sortable={false} body={addBody}/>
        </ThinHeaderDataTable>
      </FilterTableContainer>
    </PageContainer>
  )
}

export const AddProductPage = withRouter(injectIntl(Page))
