import styled from 'styled-components'
import { Dropdown as DD } from 'primereact/dropdown'
import colors from '../colors.scss'

export const Dropdown = styled(DD)`
  &&& {
    background-color: ${(props) => (props.theme === 'dark' ? colors.darkPanel : colors.white)};
    border: 2px solid ${(props) => (props.theme === 'dark' ? colors.darkPanel : colors.white)};
    border-radius: 20px;
    padding: 0 30px 0 20px;
    width: 100%;

    &:hover:not(.p-disabled) {
      border-color: #fff;
    }

    &.p-focus {
      box-shadow: none;
      border-color: ${colors.primary};
    }

    /* Button contents */

    .p-dropdown-label {
      background-color: transparent;
      color: ${(props) => (props.theme === 'dark' ? colors.white : colors.invert)};
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      text-overflow: ellipsis;
    }

    .p-dropdown-trigger {
      background-color: transparent;
      top: 2px;
      color: ${colors.midGrey};
    }

    &.ui-state-error {
      border-color: ${colors.validationFailedFieldMarker};
    }

    /* List DOES NOT WORK WITH appentTo=body */

    .p-dropdown-panel {
      background-color: ${(props) => (props.theme === 'dark' ? colors.darkPanel : colors.white)};
      border-radius: 4px;
      border: none;
      overflow: hidden;

      .p-dropdown-filter-container {
        display: block;
      }

      .p-dropdown-items {
        .p-dropdown-item {
          background-color: ${(props) => (props.theme === 'dark' ? colors.darkBackground : colors.white)};
          color: ${(props) => (props.theme === 'dark' ? colors.white : colors.invert)};

          &:hover:not(.p-disabled) {
            background-color: ${(props) => (props.theme === 'dark' ? colors.primaryFaded : colors.primaryFaded)};
            color: ${colors.white};
          }

          &.p-highlight {
            background-color: ${colors.primary};
            color: ${colors.white};
          }
        }
      }
    }
  }
`
