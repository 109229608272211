import React, { useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'

const sanitizeConf = {
  allowedTags: ['b', 'i', 'em', 'u', 'strong', 'sub', 'sup', 'div', 'br'],
  allowedAttributes: {}
}

export const RichTextDisplay = ({ value, ...props }) => {
  let sanitized = useMemo(() => sanitizeHtml(value, sanitizeConf), [value])

  if (typeof sanitized === 'undefined' || sanitized === null || sanitized === 'null') {
    sanitized = '-'
  }

  return <span dangerouslySetInnerHTML={{ __html: sanitized }} {...props} />
}
