import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, LoadingOverlay } from '../readonly'
import { MultiSelect, TextInput } from '../input'
import colors from '../colors.scss'
import { insertDynamicPrecautionaryContent } from 'Utils/statements'
import { injectIntl } from 'react-intl'

const Add = styled.div`
  display: flex;

  > ${TextInput} {
    flex: 1 0 1%;
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 150px;
    margin-left: 10px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 640px) {
    display: block;
    ${TextInput} {
      width: 100%;
      display: block;
      margin-bottom: 8px;
    }

    ${Button} {
      width: 100%;
      display: block;
      margin: 0;
    }
  }
`

const Entry = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1 0 1%;
    word-break: break-word;
  }

  > ${TextInput} {
    flex: 0 0 100px;
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 30px;
    height: 30px;
  }

  margin-bottom: 10px;
`
const EntryInput = styled.div`
  > div,
  > input {
    display: block;
    margin-top: 10px;
  }
`

const EntryContainer = styled.div`
  &.edit {
    background-color: ${colors.darkerBackgroundColor};
    padding: 10px;
  }

  margin-top: 10px;
`

const isFreeTextPlaceholder = (placeholder) => placeholder === '...' || placeholder === '…'

export const CodeMultiselectPickList = injectIntl(({ id, codeList, onChange, value, isLoading, intl, language }) => {
  const [newCode, setNewCode] = useState('')
  const [descriptionObject, setDescription] = useState([])
  const [editOpen, setEditOpen] = useState({})

  const normaliseCode = (code) => typeof code === 'string' && code.replace(/\s/g, '').toLowerCase()

  const onNewCodeChanged = (event) => {
    setNewCode(event.target.value.toUpperCase())
  }

  const onNewCodeKeyDown = (event) => {
    if (event.key === 'Enter' && isCodePossibleToAdd()) {
      addCode()
    }
  }

  const addCode = () => {
    const newCodes = [...value, findCodeObjectByCode(newCode)]
    onChange({ target: { value: newCodes } })
    setNewCode('')
  }

  const findCodeObjectByCode = (code) => {
    const codeNormalised = normaliseCode(code)

    for (let availableCode in codeList) {
      if (normaliseCode(availableCode) === codeNormalised) {
        return {
          code: availableCode,
          description: codeList[availableCode][language],
          original_description: codeList[availableCode][language]
        }
      }
    }

    return null
  }

  const removeCode = (index, code) => {
    const newCodes = [...value]
    newCodes.splice(index, 1)
    setEditOpen({ ...editOpen, [code]: false })
    let newDescriptionObject = descriptionObject
    delete newDescriptionObject[code]
    setDescription({ ...newDescriptionObject })
    onChange({ target: { value: newCodes } })
  }

  const toggleOpen = (index, entry) => {
    if (editOpen[entry.code]) {
      setEditOpen({ ...editOpen, [entry.code]: false })
      return
    }

    if (!descriptionObject[entry.code]) {
      let ms = []
      let textValues = []
      let placeholderValue
      if (entry.placeholder) {
        placeholderValue = JSON.parse(entry.placeholder)
      }

      ;[...entry.original_description.matchAll(/\[(.*?)\]/g)].forEach((m, mindex) => {
        let gatheredPlaceholders = []
        m[1].split('/').forEach((v, i) => {
          let textValue = getDynamicPhraseTextFromPlaceholder(placeholderValue, mindex, i)
          if (isFreeTextPlaceholder(v)) {
            textValues[mindex] = []
            textValues[mindex][i] = textValue
          }
          gatheredPlaceholders.push({
            label: v,
            value: isFreeTextPlaceholder(v) ? textValue : v,
            index: i,
            code: entry.code,
            placeholderIndex: mindex
          })
        })
        ms.push(gatheredPlaceholders)
      })

      let extractedPlaceHolder = extractPlaceholderValue(placeholderValue)
      setDescription({
        ...descriptionObject,
        [entry.code]: {
          placeholderList: ms,
          textValues: textValues,
          placeholders: extractedPlaceHolder.length > 0 ? extractedPlaceHolder : new Array(ms.length)
        }
      })
    }

    setEditOpen({ ...editOpen, [entry.code]: true })
  }

  const extractPlaceholderValue = (val) => {
    let ph = []
    if (val) {
      val.forEach((outerValue) => {
        let phv = []
        outerValue.forEach((v) => {
          if (v) {
            phv.push(v)
          }
        })
        ph.push(phv)
      })
    }
    return ph
  }

  const getDynamicPhraseTextFromPlaceholder = (placeholder, index, i) => {
    let textValue = '...'
    if (placeholder && placeholder[index] && placeholder[index][i]) {
      textValue = placeholder[index][i]
    }
    return textValue
  }

  const isCodeDescriptionDynamic = (description) => /\[.*\]/g.test(description)

  const isCodePossibleToAdd = () => {
    // If the list of available codes is missing:
    if (!codeList) {
      return false
    }

    const newCodeCleaned = normaliseCode(newCode)

    // If the code is already added to the product:
    if (value.some((code) => normaliseCode(code.code) === newCodeCleaned)) {
      return false
    }

    // Is the code is available among the existing codes.
    return findCodeObjectByCode(newCodeCleaned) !== null
  }

  const setDynamicPhraseText = (textvalue, code, index, placeholderIndex) => {
    let desc = descriptionObject[code]
    desc.textValues[placeholderIndex][index] = textvalue
    setDescription({ ...descriptionObject, [code]: desc })
    internalOnChange()
  }

  const getDynamicPhraseText = (placeholderIndex, index, code) => {
    return descriptionObject[code].textValues[placeholderIndex][index]
  }

  const internalOnChange = () => {
    let newValue = [...value]
    newValue.forEach((c, i) => {
      let placeholders
      if (descriptionObject[c.code]) {
        placeholders = replaceTextValues(c.code)
        c.placeholder = JSON.stringify(placeholders)
      }
      c.phrase = insertDynamicPrecautionaryContent(c.original_description, placeholders)
    })
    onChange({ target: { value: newValue } })
  }

  const replaceTextValues = (code) => {
    let descObj = JSON.parse(JSON.stringify(descriptionObject[code]))
    let themPlaceholders = []
    descObj.placeholders.forEach((ph, phIndex) => {
      let innerPH = []
      if (ph) {
        ph.forEach((p, index) => {
          descObj.placeholderList[phIndex].forEach((pli) => {
            if (pli.value === p) {
              if (isFreeTextPlaceholder(p) || isFreeTextPlaceholder(pli.label)) {
                let tv = descObj.textValues[phIndex][pli.index]
                innerPH[pli.index] = tv
              } else {
                innerPH[pli.index] = p
              }
            }
          })
        })
      } else {
        if (descObj.textValues[phIndex]) {
          innerPH[0] = descObj.textValues[phIndex][0]
        }
      }
      themPlaceholders.push(innerPH)
    })
    return themPlaceholders
  }

  const updateSelection = (event, code, placeholderIndex) => {
    let newDescriptionObject = descriptionObject[code]
    newDescriptionObject.placeholders[placeholderIndex] = event.value
    setDescription({ ...descriptionObject, [code]: newDescriptionObject })
    internalOnChange()
  }

  const renderMultiSelectOption = (option) => {
    if (!isFreeTextPlaceholder(option.label)) {
      return <div>{option.label}</div>
    }

    return (
      <TextInput
        value={getDynamicPhraseText(option.placeholderIndex, option.index, option.code)}
        onChange={(e) => setDynamicPhraseText(e.target.value, option.code, option.index, option.placeholderIndex)}
        onKeyDown={(e) => e.stopPropagation()}
      />
    )
  }

  return (
    <div id={id}>
      <LoadingOverlay isLoading={isLoading}>
        <Add>
          <TextInput
            value={newCode}
            onChange={onNewCodeChanged}
            onKeyDown={onNewCodeKeyDown}
            placeholder={intl.formatMessage({ id: 'chemicals.form.precautionary_statements_placeholder' })}
          />
          <Button label={intl.formatMessage({ id: 'common.add' })} disabled={isLoading || !isCodePossibleToAdd()}
                  onClick={addCode}/>
        </Add>
      </LoadingOverlay>

      {value &&
        value.map((entry, index) => {
          return (
            <EntryContainer key={index} className={editOpen[entry.code] ? 'edit' : ''}>
              <Entry>
                <div>
                  <strong>{entry.code}</strong>
                  <br/>
                  {insertDynamicPrecautionaryContent(entry.original_description, entry.placeholder && JSON.parse(entry.placeholder))}
                </div>
                {isCodeDescriptionDynamic(entry.original_description) && (
                  <Button icon="pi pi-pencil" onClick={() => toggleOpen(index, entry)}/>
                )}
                <Button icon="pi pi-trash" onClick={() => removeCode(index, entry.code)}/>
              </Entry>

              {editOpen[entry.code] && (
                <EntryInput>
                  {descriptionObject[entry.code].placeholderList.map((ms, i) => {
                    if (ms.length === 1 && isFreeTextPlaceholder(ms[0].value)) {
                      return (
                        <TextInput
                          key={i}
                          value={descriptionObject[entry.code].textValues[i][0]}
                          onChange={(e) => setDynamicPhraseText(e.target.value, entry.code, 0, i)}
                        />
                      )
                    } else {
                      return (
                        <MultiSelect
                          key={i}
                          options={ms}
                          itemTemplate={renderMultiSelectOption}
                          value={descriptionObject[entry.code].placeholders[i]}
                          onChange={(event) => updateSelection(event, entry.code, i)}
                        />
                      )
                    }
                  })}
                </EntryInput>
              )}
            </EntryContainer>
          )
        })}
    </div>
  )
})
