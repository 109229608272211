import { Button, TextInput } from 'Components/input'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

export const keyfilter = /[\d ]/

const Wrapper = styled.div`
  ${TextInput}${TextInput}${TextInput} {
    max-width: 92px;
  }
`

export const wasteCodeValidation = (value) => {
  if (value && value.some((code) => code && !/^[0-9]{2} [0-9]{2} [0-9]{2}$/.test(code))) {
    return { id: 'riskassessment.waste_code_validation_failed' }
  }
}

export default ({ value, onChange }) => {
  const intl = useIntl()

  const codeList = value && value.length > 0 ? value : ['']

  const onChangeInternal = (index, event) => {
    // Parse and reassemble string in 3 groups of 2 digits
    const digitGroups = /^([0-9]{0,2}) ?([0-9]{0,2}) ?([0-9]{0,2})$/.exec(event.target.value.trim())

    const formattedCode = digitGroups
      ? digitGroups.slice(1).reduce((result, value) => {
        if (value) {
          if (result.length > 0) {
            result += ' '
          }
          result += value
        }
        return result
      }, '')
      : value
        ? value
        : null

    // Create a new array
    const newValue = [...codeList]
    newValue[index] = formattedCode
    onChange({ target: { value: newValue } })
  }

  const addWastecode = () => {
    const newValue = [...codeList]
    newValue.push('')
    onChange({ target: { value: newValue } })
  }

  const removeWastecode = (index) => {
    const newValue = [...codeList]
    newValue.splice(index, 1)
    onChange({ target: { value: newValue } })
  }

  return (
    <Wrapper>
      {codeList.map((code, index) => (
        <>
          <TextInput maxLength="8" value={code} keyfilter="pint" onChange={(e) => onChangeInternal(index, e)}/>
          {index !== codeList.length - 1 && (
            <Button
              primary
              icon="pi pi-minus"
              onClick={() => removeWastecode(index)}
              tooltip={intl.formatMessage({ id: 'common.delete' })}
            />
          )}
        </>
      ))}
      <Button primary icon="pi pi-plus" onClick={() => addWastecode()}
              tooltip={intl.formatMessage({ id: 'common.add' })}/>
    </Wrapper>
  )
}
