import React, { useEffect, useState } from 'react'
import useGetData from 'Hooks/useGetData'
import { Column } from 'primereact/column'
import { DataTable, ToolPanel } from 'Components/readonly'
import { PageConstraint, PageHeader } from 'Components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import { SearchInput } from 'Components/input'

export const LogPage = () => {
  const [isLoading, logs] = useGetData('/rapi/admin/log', [])
  const intl = useIntl()
  const [globalFilter, setGlobalFilter] = useState('')
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(localStorage.hasOwnProperty('numLogRows') ? parseInt(localStorage.getItem('numLogRows')) : 20)

  useEffect(() => {
    localStorage.setItem("numLogRows", rows);
  }, [rows]);

  return (
    <PageConstraint>
      <PageHeader>
        <h1>
          <FormattedMessage id="log.admin-log"/>
        </h1>
      </PageHeader>
      <ToolPanel>
        <SearchInput
          value={globalFilter}
          onChange={(event) => {
            setGlobalFilter(event.target.value)
            setFirst(0)
          }}
        />
      </ToolPanel>
      <DataTable
        className="flextable"
        value={logs}
        paginator={true}
        loading={isLoading}
        rows={rows}
        rowsPerPageOptions={[5, 20, 100]}
        globalFilter={globalFilter}
        first={first}
        onPage={(e) => {
          setFirst(e.first)
          setRows(e.rows)
        }}>
        <Column
          field="created_at"
          header={intl.formatMessage({ id: 'common.when' })}
          sortable={false}
          style={{ width: '1%', whiteSpace: 'nowrap' }}
        />
        <Column field="whom" header={intl.formatMessage({ id: 'common.who' })} sortable={false}
                style={{ width: '1%' }}/>
        <Column field="message" header={intl.formatMessage({ id: 'common.what' })} sortable={false}/>
      </DataTable>
    </PageConstraint>
  )
}
