import React from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'

import useGetData from 'Hooks/useGetData'
import { BackLinkButton, Divider } from 'Components/readonly'
import { FlexColumn, PageConstraint, PageHeader } from 'Components/layout'
import { ProgressSpinner } from 'primereact/progressspinner'
import { AuthContext } from 'Context'
import { useIntl } from 'react-intl'
import { InventoryDoneDetailLocations } from './components/InventoryDoneDetailLocations'
import { InventoryDoneDetailHeader } from './components/header/inventoryDoneDetailHeader'
import { DetailHeader } from './components/header'

const DetailWrapper = styled.div`
  h1 {
    padding: 0;
    margin: 0;
  }

  > h3 {
    padding: 0;
    margin: 0 0 15px 0;
  }
`

export const InventoryDoneDetailPage = () => {
  const intl = useIntl()
  const params = useParams()
  const history = useHistory()

  const { organisation } = React.useContext(AuthContext)
  const [isLoading, inventory, setInventory] = useGetData('/rapi/inventorying/' + params.id, null, [organisation]) // eslint-disable-line

  const backToList = () => history.push('/inventory/done')

  return (
    <PageConstraint>
      <DetailWrapper>
        {isLoading ? (
          <PageHeader>
            <p>
              <BackLinkButton
                link
                icon="pi pi-arrow-left"
                label={intl.formatMessage({ id: 'inventory.inventoryings' })}
                onClick={backToList}
              />
            </p>
            <ProgressSpinner/>
          </PageHeader>
        ) : (
          inventory && (
            <>
              <PageHeader>
                <p>
                  <BackLinkButton
                    link
                    icon="pi pi-arrow-left"
                    label={intl.formatMessage({ id: 'inventory.inventoryings' })}
                    onClick={backToList}
                  />
                </p>
                <DetailHeader>
                  <FlexColumn>
                    <InventoryDoneDetailHeader inventory={inventory} setInventory={setInventory}/>
                  </FlexColumn>
                </DetailHeader>
                <Divider/>
                <InventoryDoneDetailLocations inventoryLocations={inventory.locations}/>
              </PageHeader>
            </>
          )
        )}
      </DetailWrapper>
    </PageConstraint>
  )
}
