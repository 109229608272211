import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'

import useCrudData from 'Hooks/useCrudData'
import { AuthContext, GrowlContext } from 'Context'
import { SearchInput, Switch } from 'Components/input'
import { LongerSection, Section } from 'Components/layout'
import { Column, DataTable, ToolPanel } from 'Components/readonly'
import { useLocation } from 'react-router-dom'
import { InlineTextInput } from './inlineTextInput'

const SettingsArticleNumbersAndSynonymsPanel = () => {
  const intl = useIntl()
  const location = useLocation()
  const [globalFilter, setGlobalFilter] = useState(location.state && location.state.productName)
  const { organisation, setOrganisationProps } = useContext(AuthContext)
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const [rows, setRows] = useState(
    localStorage.hasOwnProperty('numChemicalSynonymRows') ? parseInt(localStorage.getItem('numChemicalSynonymRows')) : 10
  )
  const [page, setPage] = useState(1)
  const [first, setFirst] = useState(0)

  useEffect(() => {
    localStorage.setItem('numChemicalSynonymRows', rows)
  }, [rows])

  const getUrlParams = (page, rows, globalFilter) => {
    let urlParams = {
      page: page,
      numItems: rows,
      order_by: 'created_at',
      order_direction: '-1'
    }

    if (globalFilter) {
      urlParams.search = globalFilter
    }

    return new URLSearchParams(urlParams).toString()
  }

  const [productListLoading, productList, createData, readData, updateDatas, deleteData, setProductList] = useCrudData(
    '/rapi/chemicals/organisation' + ('?' + getUrlParams(page, rows, globalFilter)),
    [],
    [organisation.id],
    (data) => {
      return data
    },
    globalFilter?.length == 1 || globalFilter?.length == 2 // Add debounce functionality to avoid dissappearing content connected to MySQL 3 character search limit
  )
  const [enableArticleNumbers, setEnableArticleNumbers] = useState(organisation.enable_chemical_list_article_numbers)
  const [enableSynonyms, setEnableSynonyms] = useState(organisation.enable_chemical_list_synonyms)
  const changedRef = useRef(false)

  useEffect(() => {
    setEnableArticleNumbers(organisation.enable_chemical_list_article_numbers)
    setEnableSynonyms(organisation.enable_chemical_list_synonyms)
  }, [organisation.enable_chemical_list_article_numbers, organisation.enable_chemical_list_synonyms])

  const saveOrgSettings = async (data) => {
    try {
      const result = await axios.post('/rapi/chemicals/articlenumbersandsynonyms', data)
      setOrganisationProps(data)
      displaySuccess(result.data.message)
    } catch (error) {
      displayError(error)
    }
  }

  const saveTableChange = async (rowIndex, field) => {
    if (!changedRef.current) {
      return
    }
    changedRef.current = false
    try {
      const request = {
        changes: [
          {
            id: productList.data[rowIndex].id,
            [field]: productList.data[rowIndex][field]
          }
        ]
      }
      const result = await axios.post('/rapi/chemicals/articlenumbersandsynonyms', request)
      displaySuccess(result.data.message)
    } catch (error) {
      displayError(error)
    }
  }

  /* Cell Editing */
  const onEditorValueChange = (props, value) => {
    changedRef.current = true
    let updatedProductList = [...props.value]
    updatedProductList[props.rowIndex][props.field] = value
    setProductList({...productList, data: updatedProductList})
  }

  const onKeyDown = (e, props, field) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      saveTableChange(props.rowIndex, field)
    }
  }

  const inputTextEditor = (props, field) => (
    <InlineTextInput
      value={props.rowData[field]}
      onChange={(e) => onEditorValueChange(props, e.target.value)}
      onBlur={(e) => saveTableChange(props.rowIndex, field)}
      onKeyDown={(e) => onKeyDown(e, props, field)}
    />
  )

  const columns = [
    <Column key="name" field="name" header={intl.formatMessage({ id: 'chemicals.product' })} sortable={true} />,
    <Column key="supplier" field="supplier" header={intl.formatMessage({ id: 'common.supplier' })} sortable={true} />,
    <Column key="article_number" field="article_number" header={intl.formatMessage({ id: 'chemicals.article_id' })} sortable={true} />
  ]

  if (enableArticleNumbers) {
    columns.push(
      <Column
        key="organisation_article_number"
        field="organisation_article_number"
        header={intl.formatMessage({ id: 'chemicals.organisation_article_number' })}
        sortable={false}
        editor={(props) => inputTextEditor(props, 'organisation_article_number')}
        style={{ height: '60px' }}
      />
    )
  }
  if (enableSynonyms) {
    columns.push(
      <Column
        key="organisation_synonym"
        field="organisation_synonym"
        header={intl.formatMessage({ id: 'chemicals.organisation_synonym' })}
        sortable={false}
        editor={(props) => inputTextEditor(props, 'organisation_synonym')}
        style={{ height: '60px' }}
      />
    )
  }

  return (
    <>
      <Section>
        <div>
          <label>{intl.formatMessage({ id: 'chemicals.activate_article_numbers' })}</label>
        </div>
        <Switch
          id="enable_chemical_list_article_numbers"
          onLabel={intl.formatMessage({ id: 'common.yes' })}
          offLabel={intl.formatMessage({ id: 'common.no' })}
          value={enableArticleNumbers}
          onChange={(e) => {
            setEnableArticleNumbers(e.target.value)
            saveOrgSettings({ enable_chemical_list_article_numbers: e.target.value })
          }}
        />
      </Section>
      <LongerSection>
        <div>
          <label>{intl.formatMessage({ id: 'chemicals.activate_synonyms' })}</label>
        </div>
        <Switch
          id="enable_chemical_list_synonyms"
          onLabel={intl.formatMessage({ id: 'common.yes' })}
          offLabel={intl.formatMessage({ id: 'common.no' })}
          value={enableSynonyms}
          onChange={(e) => {
            setEnableSynonyms(e.target.value)
            saveOrgSettings({ enable_chemical_list_synonyms: e.target.value })
          }}
        />
      </LongerSection>

      {(enableArticleNumbers || enableSynonyms) && (
        <Section>
          <p>
            {enableArticleNumbers && enableSynonyms && <FormattedMessage id="chemicals.edit_article_number_and_synonym" />}
            {enableArticleNumbers && !enableSynonyms && <FormattedMessage id="chemicals.edit_article_number" />}
            {!enableArticleNumbers && enableSynonyms && <FormattedMessage id="chemicals.edit_synonym" />}
          </p>
          <ToolPanel>
            <SearchInput
              value={globalFilter}
              onChange={(event) => {
                setGlobalFilter(event.target.value)
                setFirst(0)
                setPage(1)
              }}
            />
          </ToolPanel>
          <DataTable
            className={'flextable'}
            loading={productListLoading}
            value={productList.data}
            paginator={true}
            rows={rows}
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRecords={productList.total ? productList.total : 0}
            lazy={true}
            first={first}
            onPage={(e) => {
              setFirst(e.first)
              setRows(e.rows)
              setPage(e.page + 1)
            }}>
            {columns}
          </DataTable>
        </Section>
      )}
    </>
  )
}

export default SettingsArticleNumbersAndSynonymsPanel
