import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormContext } from 'Components/form/formContext'
import { Button } from 'Components/readonly'
import { SidebarHeader, Spacer } from 'Components/layout'
import { setLocked } from '../../../Utils/functions'
import CloseProductEditDialog from 'Pages/chemicalList/dialogs/closeProductEditDialog'

const ProductEditSidebarHeader = ({ close, save, isSaving, isLocked }) => {
  const { formData, setFieldValue } = useContext(FormContext)
  const [showAbortDialog, setShowAbortDialog] = useState(false)

  const closeDialog = () => {
    setShowAbortDialog(false)
  }

  const closeProductEdit = () => {
    setShowAbortDialog(false)
    setLocked(formData.id, 0)
    close();
  }

  const openDialog = () => {
    setShowAbortDialog(true)
  }

  return (
    <>
      <SidebarHeader>
        <h2>
          <FormattedMessage id="chemicals.register_product_heading" />
        </h2>
        <Spacer />
        <div>
          <Button
            label="Avbryt"
            onClick={() => {
              if (!showAbortDialog) {
                openDialog()
              }
            }}
            icon="pi pi-times"
            className="button-text-md-hide"
          />
          {isLocked ? (
            <Button id="proceed-button" disabled label="Produkt låst för redigering" icon="pi pi-lock" className="button-text-md-hide" />
          ) : (
            formData.status === 'PRELIMINARY' && (
              <Button
                isLoading={isSaving}
                icon="pi pi-save"
                label="Spara som preliminär"
                onClick={() => save(true)}
                className="button-text-md-hide"
              />
            )
          )}
          {!isLocked && (
            <Button
              primary
              isLoading={isSaving}
              label="Spara som aktiv"
              icon="pi pi-check"
              onClick={() => save(false)}
              className="button-text-md-hide"
            />
          )}
        </div>
      </SidebarHeader>
      {showAbortDialog && <CloseProductEditDialog isOpen={showAbortDialog} onDecline={closeDialog} onAccept={closeProductEdit} />}
    </>
  )
}

export default ProductEditSidebarHeader
