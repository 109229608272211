import React, { useContext, useMemo, useState } from 'react'
import _ from 'lodash'
import { format, parse } from 'date-fns'
import axios from 'axios'
import styled from 'styled-components'

import { AuthContext, GrowlContext } from 'Context'
import { Field, Form } from 'Components/form'
import { FileUpload, HiddenInput, RadioButtonGroup, TextareaInput, TextInput } from 'Components/input'
import { Button, Panel, SdsDetailTable, SdsDownloadButton, SdsSidebar } from 'Components/readonly'
import { FormattedMessage, useIntl } from 'react-intl'
import SdsArchiveDialog from 'Components/combined/sdsArchiveDialog'
import { HasCapability } from 'Components/capabilities'
import { ButtonBar } from 'Components/layout'

const Wrapper = styled.div`
  ${Panel} {
    margin-bottom: 20px;
  }

  ${Button} {
    align-self: flex-start;
  }

  .hidden {
    display: none;
  }

  ${RadioButtonGroup} {
    margin-bottom: 10px;
  }
`

const filterAttachments = (attachments, kind) => {
  const filtered = attachments
    .filter((attachment) => {
      return attachment.kind === kind
    })
    .map((attachment) => {
      return {
        id: attachment.id,
        file: {
          name: attachment.filename
        },
        deleted: false
      }
    })
  if (filtered.length === 0) {
    return null
  }
  return filtered
}

// This component is now used by both revisionMaintenance and revisionSubscription pages
const RevisionDetailView = ({ isOpen, close, kind, sds, reviseBy, updateSds, deleteSds }) => {
  const intl = useIntl()
  const { dateLocaleDateFns } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)

  const saveRevisionAction = async () => {
    setIsLoading(true)
    try {
      const data = { changes: { [sds.id]: { action: sdsAction } } }
      const result = await axios.post('/rapi/revision', data)
      const updatedSds = result.data.data.find((s) => sds.id === s.id)
      if (updatedSds) {
        setSdsAction(updatedSds.revisionAction ? updatedSds.revisionAction.action : null)
      }
      updateSds(result.data.data)
      displaySuccess(intl.formatMessage({ id: 'common.saved' }))
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  const submitRevision = async (data, formData, setFormData) => {
    setIsLoading(true)
    try {
      const result = await axios.post('/rapi/revision', data)
      updateSds(result.data.data)

      let newFormData = formData
      _.forEach(result.data.data, (updatedSds) => {
        if (updatedSds.id === sds.id) {
          const newLogos = updatedSds.revisionAction ? filterAttachments(updatedSds.revisionAction.attachments, 'logo') : null
          const newRecipes = updatedSds.revisionAction ? filterAttachments(updatedSds.revisionAction.attachments, 'recipe') : null
          newFormData = {
            ...newFormData,
            [`files[${sds.id}][logo][]`]: newLogos,
            [`files[${sds.id}][recipe][]`]: newRecipes
          }
        }
      })
      setFormData(newFormData)
      displaySuccess(intl.formatMessage({ id: 'common.saved' }))
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  const filesToFormDataConverter = (key, files, sendData) => {
    if (_.isArray(files)) {
      _.forEach(files, (file) => {
        if (!file.deleted && !file.id) {
          sendData.append(key, file.file)
        } else if (file.deleted && file.id) {
          sendData.append(`fileDeletionIds[${sds.id}][]`, file.id)
        }
      })
    }
  }

  const logoList = useMemo(() => {
    if (sds.revisionAction == null) return null
    return filterAttachments(sds.revisionAction.attachments, 'logo')
  }, [sds])

  const recipeList = useMemo(() => {
    if (sds.revisionAction == null) return null
    return filterAttachments(sds.revisionAction.attachments, 'recipe')
  }, [sds])

  const toFormDataConverters = {
    [`files[${sds.id}][logo][]`]: filesToFormDataConverter,
    [`files[${sds.id}][recipe][]`]: filesToFormDataConverter
  }

  const [sdsAction, setSdsAction] = useState(sds.revisionAction ? sds.revisionAction.action : null)

  const revisionActionOptions = [
    {
      value: null,
      label: intl.formatMessage({ id: 'sds.revise' })
    },
    {
      value: 'revision',
      label: intl.formatMessage({ id: 'sds.order-changes' })
    }
  ]

  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)
  const onArchiveDone = () => {
    deleteSds(sds)
    setArchiveDialogOpen(false)
    displaySuccess(kind === 'external' ? intl.formatMessage({ id: 'sds.sds-deleted' }) : intl.formatMessage({ id: 'sds.sds-archived' }))
    close()
  }

  const disableIfFiles = (fileList) => {
    return fileList && fileList.filter((file) => !file.deleted).length > 0
  }

  return (
    <Wrapper>
      <SdsArchiveDialog kind={kind} sds={sds} isOpen={archiveDialogOpen} setIsOpen={setArchiveDialogOpen}
                        onArchiveDone={onArchiveDone}/>
      <Panel lighter>
        <h2>
          <FormattedMessage id="sds.information"/>
        </h2>
        <SdsDetailTable sds={sds}/>
        <ButtonBar>
          <SdsDownloadButton primary kind={kind} sds={sds}/>
          <HasCapability capability="MANAGE_SDS">
            <Button
              label={kind === 'external' ? intl.formatMessage({ id: 'common.delete' }) : intl.formatMessage({ id: 'common.archive' })}
              icon="pi pi-folder"
              onClick={() => setArchiveDialogOpen(true)}
            />
          </HasCapability>
        </ButtonBar>
      </Panel>

      <Panel lighter>
        <h2>
          <FormattedMessage id="sds.revision-of-sds"/>
        </h2>

        <RadioButtonGroup value={sdsAction} onChange={(o) => setSdsAction(o.value)} options={revisionActionOptions}/>

        {sdsAction === 'revision' && (
          <>
            <h3>
              <FormattedMessage id="sds.order-changes"/>
            </h3>
            <p>
              <FormattedMessage id="sds.changes-will-apply-with-revision"/> (
              {format(parse(reviseBy), 'MMMM YYYY', { locale: dateLocaleDateFns })})
            </p>
            <Form resetAfterSubmit={true} useFormData={true} onSubmit={submitRevision}
                  toFormDataConverters={toFormDataConverters}>
              <Field className="hidden" id={`changes[${sds.id}][action]`} Component={HiddenInput}
                     initialValue="revision"/>

              <Field
                id={`changes[${sds.id}][product-name]`}
                label={intl.formatMessage({ id: 'sds.product-name' })}
                toggle={true}
                Component={TextInput}
                initialValue={sds.revisionAction ? sds.revisionAction.productName : null}
              />
              <Field
                id={`changes[${sds.id}][article-number]`}
                label={intl.formatMessage({ id: 'sds.article-number' })}
                toggle={true}
                Component={TextInput}
                initialValue={sds.revisionAction ? sds.revisionAction.articleNumber : null}
              />
              <Field
                id={`changes[${sds.id}][contact-information]`}
                label={intl.formatMessage({ id: 'sds.change-contact-information' })}
                toggle={true}
                Component={TextareaInput}
                initialValue={sds.revisionAction ? sds.revisionAction.contactInformation : null}
              />
              <Field
                id={`files[${sds.id}][logo][]`}
                label={intl.formatMessage({ id: 'sds.new-logotype' })}
                toggle={true}
                toggleDisabled={disableIfFiles}
                text={intl.formatMessage({ id: 'common.select-file' })}
                Component={FileUpload}
                accept="image/*"
                multiple={true}
                initialValue={logoList}
                maxSize={2000}
              />
              <Field
                id={`files[${sds.id}][recipe][]`}
                label={intl.formatMessage({ id: 'sds.new-recipe' })}
                toggle={true}
                toggleDisabled={disableIfFiles}
                text={intl.formatMessage({ id: 'common.select-file' })}
                Component={FileUpload}
                accept="*"
                multiple={true}
                initialValue={recipeList}
              />
              <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                      isLoading={isLoading}/>
            </Form>
          </>
        )}

        {sdsAction !== 'revision' && (
          <Button
            primary
            icon="pi pi-save"
            label={intl.formatMessage({ id: 'common.save' })}
            isLoading={isLoading}
            onClick={saveRevisionAction}
          />
        )}
      </Panel>
    </Wrapper>
  )
}

const RevisionDetail = ({ ...props }) => {
  return <SdsSidebar {...props} Component={RevisionDetailView}/>
}

export default RevisionDetail
