import * as React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { HasCapability } from 'Components/capabilities'

const Wrapper = styled.div`
  padding-left: 20px;

  @media screen and (max-width: 640px) {
    a {
      text-decoration: underline;
    }
  }
`

const SafetySheetLink = ({ className, riskAssessment, product }) => {
  const showLink = !riskAssessment || riskAssessment.status === 'ACTIVE'


  const url = '/rapi/riskassessments/safetysheet/' + (riskAssessment ? riskAssessment.id : 'chemical/' + product.id);

  return (
    <Wrapper className={className}>
      <HasCapability capability="CHEMICAL_PRINT">
        {showLink && (
          <div style={{ textAlign: 'right', whiteSpace: 'nowrap', verticalAlign: 'bottom' }}>
            <i className="pi pi-download" style={{ verticalAlign: 'middle', fontSize: '28px' }}></i>{' '}
            <a href={url} rel="noopener noreferrer" target="_blank">
              <FormattedMessage id="safetysheet.safety_sheet"/>
            </a>
          </div>
        )}
      </HasCapability>
    </Wrapper>
  )
}

export default styled(SafetySheetLink)``
