import * as React from 'react'
import styled from 'styled-components'

import { ResponsiveSidebar } from 'Components/readonly'
import TwoColumn from '../layout/twoColumn'
import { injectIntl } from 'react-intl'
import PdfDisplayError from './pdfDisplayError'

const LeftContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  object {
    width: 100%;
    flex: 1 1 auto;
  }

  @media screen and (max-width: 640px) {
    object {
      display: none;
    }
  }
`

const RightContent = styled.div``

export const SdsSidebar = injectIntl(
  ({
     kind,
     sds,
     isOpen,
     close,
     intl,
     Component,
     isAnyModalOpen,
     ...props
   }: {
    kind: string,
    sds: Object,
    isOpen: boolean,
    close: Function
  }) => {
    const viewOptions = [
      { label: intl.formatMessage({ id: 'sds.show-sds' }), value: 'left' },
      { label: intl.formatMessage({ id: 'common.manage' }), value: 'right' }
    ]

    const isIE = /*@cc_on!@*/ false || !!document.documentMode
    const ieIframeFix = isIE && isAnyModalOpen ? { display: 'none' } : {}

    const pdfUrl = isOpen && '/rapi/sds/pdfFile?' + kind + '[' + sds.id + ']=1'

    return (
      <ResponsiveSidebar id="ResponsiveSidebar" blockScroll={true} position="right" visible={isOpen} onHide={close}>
        {isOpen && (
          <TwoColumn
            viewOptions={viewOptions}
            leftContent={
              <LeftContent id="LeftContent">
                <object style={ieIframeFix} type="application/pdf" data={pdfUrl}>
                  <PdfDisplayError pdfUrl={pdfUrl}/>
                </object>
              </LeftContent>
            }
            rightContent={
              <RightContent id="RightContent">
                <Component kind={kind} sds={sds} isOpen={isOpen} close={close} {...props} />
              </RightContent>
            }
          />
        )}
      </ResponsiveSidebar>
    )
  }
)
