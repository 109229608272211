import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { format, parse } from 'date-fns'
import axios from 'axios'

import { Button } from 'Components/input'
import { ModalActionsFooter, Panel } from 'Components/readonly'
import { LineHeading, Section, Spacer } from 'Components/layout'
import { GrowlContext } from 'Context'

export default ({ modalController, onSuccess }) => {
  const intl = useIntl()
  const inventory = modalController.data
  const [isLoading, setIsLoading] = useState(false)
  const { displayError } = useContext(GrowlContext)

  const send = async () => {
    setIsLoading(true)
    try {
      const response = await axios.post('/rapi/inventorying/' + inventory.id + '/sendforapproval')
      onSuccess(response.data.data)
      setIsLoading(false)
      modalController.close()
    } catch (error) {
      displayError(error)
      setIsLoading(false)
    }
  }

  return (
    <div>
      <p>
        <FormattedMessage id="inventory.send-for-approval-dialog-text"/>
      </p>
      <Section>
        <Panel>
          <h3>{inventory.locations.map((location) => location.location).join(', ')}</h3>
          <br/>
          <LineHeading>Super User: </LineHeading>
          {inventory.created_by_user_name}
          <br/>
          <LineHeading>
            <FormattedMessage id="inventory.start-date"/>:
          </LineHeading>
          <span id="start_at"> {format(parse(inventory.start_at), 'YYYY-MM-DD')}</span>
        </Panel>
      </Section>
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button
          primary
          icon="pi pi-arrow-circle-right"
          label={intl.formatMessage({ id: 'inventory.send-for-approval' })}
          onClick={send}
          isLoading={isLoading}
        />
      </ModalActionsFooter>
    </div>
  )
}
