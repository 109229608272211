import React, { useState } from 'react'
import { Button, Sidebar } from 'Components/readonly'
import styled from 'styled-components'

const Wrapper = styled.div`
  &&& {
    .p-button {
      margin-bottom: 0;
    }

    @media screen and (max-width: 640px) {
      .p-button {
        width: 100%;
      }
    }
  }
`

const MobileScroll = styled.div`
  overflow-y: auto;

  @media screen and (max-width: 640px) {
    overflow-y: scroll;
  }

  h2 {
    padding-top: 0;
    margin-left: 10px;
  }
`

export const SidebarWrapperPage = styled(({ children }) => <div className="xl-show">{children}</div>)`
  flex: 1 0 1px;
`

export const SidebarWrapperMobile = ({ children, icon, buttonText }) => {
  const [sidePanelRightVisible, setSidePanelRightVisible] = useState(false)

  return (
    <>
      <Wrapper className="xl-hide">
        <Button icon={icon} label={buttonText} onClick={(e) => setSidePanelRightVisible(true)}/>
        <Sidebar blockScroll={true} visible={sidePanelRightVisible} position="right"
                 onHide={(e) => setSidePanelRightVisible(false)}>
          <MobileScroll>{children}</MobileScroll>
        </Sidebar>
      </Wrapper>
    </>
  )
}
