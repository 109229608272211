import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, LoadingOverlay } from '../readonly'
import { TextInput } from '../input'
import colors from '../colors.scss'
import { useIntl } from 'react-intl'

const Add = styled.div`
  display: flex;

  > ${TextInput} {
    input {
      width: 200px;
    }
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 150px;
    margin-left: 10px;
    margin-bottom: 0;
  }
`

const Entry = styled.div`
  display: flex;
  align-items: center;

  > div {
    flex: 1 0 1%;
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 30px;
    height: 30px;
    margin-bottom: 0;
  }

  padding: 7px 15px;
`

const EntryContainer = styled.div`
  &.edit {
    background-color: ${colors.darkerBackgroundColor};
    padding: 10px;
  }
`

const Entries = styled.div`
  margin-top: 10px;

  ${EntryContainer}:nth-child(odd) {
    background-color: ${colors.lightBackground};
  }
`

export const PickList = ({ list, onChange, value, isLoading, field, uppercaseOnly }) => {
  const [selectedValue, setSelectedValue] = useState('')
  const intl = useIntl()

  const configUppercaseOnly = typeof uppercaseOnly !== 'undefined' ? uppercaseOnly : true

  const add = () => {
    const newValue = [...value, selectedValue]
    onChange({ target: { value: newValue } })
    setSelectedValue('')
  }

  const remove = (index) => {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange({ target: { value: newValue } })
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && selectedValue && hasValue()) {
      add()
      event.preventDefault()
    }
  }

  const onSelectedValueChanged = (event) => {
    setSelectedValue(configUppercaseOnly ? event.target.value.toUpperCase() : event.target.value)
  }

  const hasValue = () => {
    let existsInValue =
      (value &&
        value.filter((code) => {
          return code === selectedValue
        })) ||
      []
    return list && !!list[selectedValue] && existsInValue.length === 0
  }

  return (
    <div>
      <LoadingOverlay isLoading={isLoading}>
        <Add>
          <TextInput value={selectedValue} onChange={onSelectedValueChanged} onKeyDown={onKeyDown}/>
          <Button label={intl.formatMessage({ id: 'common.add' })} disabled={isLoading || !hasValue()} onClick={add}/>
        </Add>
      </LoadingOverlay>
      <Entries>
        {value &&
          value.map((entry, index) => {
            return (
              <EntryContainer key={index}>
                <Entry>
                  <div>{entry}</div>
                  <Button icon="pi pi-trash" onClick={() => remove(index)}/>
                </Entry>
              </EntryContainer>
            )
          })}
      </Entries>
    </div>
  )
}
