import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TransitionGroup } from 'react-transition-group'
import { Panel } from 'Components/readonly'
import colors from 'Components/colors.scss'
import { ChartLegendCircle, FlexRow, SidebarScrollingContainer } from 'Components/layout'

import { SidebarWrapper, SmallLeftSidebar } from './startPageStyle'
import { DynamicMessage } from './dynamicMessage'
import styled from 'styled-components'

const TightSidebarScrollingContainer = styled(SidebarScrollingContainer)`
  padding: 0;
`

export const NotificationSidebar = ({
                                      sdsToApprove,
                                      revisionSdsToApprove,
                                      messages,
                                      showNotificationSidebar,
                                      setShowNotificationSidebar,
                                      onDelete
                                    }) => (
  <SmallLeftSidebar
    id="ResponsiveSidebar"
    blockScroll={true}
    position="left"
    visible={showNotificationSidebar}
    onHide={() => {
      setShowNotificationSidebar(false)
    }}>
    {showNotificationSidebar && (
      <TightSidebarScrollingContainer>
        <SidebarWrapper>
          <Panel dark>
            <h2>
              <FormattedMessage id="welcome.notifications"/>
            </h2>
          </Panel>

          {sdsToApprove > 0 && (
            <Panel style={{ paddingTop: '10px' }} dark>
              <FlexRow>
                <ChartLegendCircle color={colors.red}/>
                <a href="/#/sds">
                  <FormattedMessage id="welcome.sds-to-approve" values={{ sdstoapprove: sdsToApprove }}/>
                </a>
              </FlexRow>
            </Panel>
          )}

          {revisionSdsToApprove > 0 && (
            <Panel style={{ paddingTop: '10px' }} dark>
              <FlexRow>
                <ChartLegendCircle color={colors.red}/>
                <a href="/#/sdsRevisionSubscription/done">
                  <FormattedMessage id="welcome.sds-revised-to-approve"
                                    values={{ revisionsdstoapprove: revisionSdsToApprove }}/>
                </a>
              </FlexRow>
            </Panel>
          )}

          {messages.map((message) => (
            <TransitionGroup key={message.id}>
              <DynamicMessage message={message} onDelete={onDelete}/>
            </TransitionGroup>
          ))}

          {sdsToApprove === 0 && revisionSdsToApprove === 0 && messages.length === 0 && (
            <Panel style={{ paddingTop: '10px' }} dark>
              <FormattedMessage id="welcome.no-notifications"/>
            </Panel>
          )}
        </SidebarWrapper>
      </TightSidebarScrollingContainer>
    )}
  </SmallLeftSidebar>
)
