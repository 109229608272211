import React from 'react'
import { useIntl } from 'react-intl'

import { Modal, PdfViewer } from 'Components/readonly'
import styled from 'styled-components'

import useModalController from 'Hooks/useModalController'
import ErrorBoundary from 'Components/errorBoundary'

const ScrollWrapper = styled.div`
  height: calc(1vh * 90 - 88px); /* IE11 */
  height: calc(var(--vh, 1vh) * 90 - 88px);
`

const PdfPreviewContent = ({ modalController }) => {
  const intl = useIntl()
  return (
    <ErrorBoundary error={intl.formatMessage({ id: 'common.pdfjs_error' })}>
      <ScrollWrapper>{modalController.data && <PdfViewer src={modalController.data}/>}</ScrollWrapper>
    </ErrorBoundary>
  )
}

const PdfPreviewModal = styled(Modal)`
  &&& {
    overflow: hidden;
    max-height: none;
  }
`

export const PdfPreviewWindow = ({ file, onClose }) => {
  const intl = useIntl()

  const [modalController] = useModalController()

  React.useEffect(() => {
    if (file) {
      modalController.open({
        onClose: onClose,
        data: file,
        title: intl.formatMessage({ id: 'common.previewpdf' }, { name: file.name })
      })
    } else if (modalController.isOpen) modalController.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return <PdfPreviewModal modalController={modalController} ContentComponent={PdfPreviewContent}/>
}
