export const validatePasswordUtil = (intl, password, password_confirmation) => {
  const errors = []
  if (password !== password_confirmation) {
    errors.push(intl.formatMessage({ id: 'passwords.mismatch' }))
  }
  if (password.length < 8) {
    errors.push(intl.formatMessage({ id: 'passwords.length' }))
  }
  if (!/[a-z]/.test(password)) {
    errors.push(intl.formatMessage({ id: 'passwords.lowercase' }))
  }
  if (!/[A-Z]/.test(password)) {
    errors.push(intl.formatMessage({ id: 'passwords.uppercase' }))
  }
  if (!/[0-9,\W]/.test(password)) {
    errors.push(intl.formatMessage({ id: 'passwords.symbol' }))
  }
  return errors
}
