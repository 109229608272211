import React from 'react'
import styled from 'styled-components'
import { RadioButton } from 'primereact/radiobutton'
import colors from '../colors.scss'

const makeid = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const RadioButtonGroup = styled(({ className, id, options, value, onChange }) => {
  let mid = makeid(5)

  const internalOnChange = (event) => {
    window.scrollTo(0, 0) // IOS Hack: When going from input element the window doesn't scroll back to top.
    onChange(event)
  }

  return (
    <div id={id}>
      {options.map((option) => {
        return (
          <div key={option.value} className={className} data-value={option.value}>
            <RadioButton
              inputId={mid + id + '-' + option.value}
              value={option.value}
              name={id}
              onChange={internalOnChange}
              checked={value === option.value}
            />
            <label htmlFor={mid + id + '-' + option.value}>{option.label}</label>
          </div>
        )
      })}
    </div>
  )
})`
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  & .p-radiobutton {
    margin-right: 5px;

    .p-radiobutton-box.p-component {
      background-color: ${colors.disabledWhite};
    }
  }

  label {
    cursor: pointer;
  }
`
