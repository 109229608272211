import React, { useState } from 'react'

import { Modal } from 'Components/readonly'
import { useIntl } from 'react-intl'
import useModalController from 'Hooks/useModalController'
import InventoryDetailLocation from './inventoryDetailLocation'
import findProductModalContent from './findProductModalContent'
import addProductModalContent from './addProductModalContent'
import NewProductDialog from 'Pages/chemicalList/dialogs/newProductDialog'

export const InventoryDetailLocations = ({ inventoryLocations, addProduct, validateAllLocations }) => {
  const intl = useIntl()
  const [findProductModalController] = useModalController({ title: intl.formatMessage({ id: 'inventory.find-product-title' }) })
  const [addProductModalController] = useModalController({ title: intl.formatMessage({ id: 'inventory.add-product-title' }) })
  const [isNewProductDlgOpen, setNewProductDlgOpen] = useState(false)

  const openAddProductModal = (location, product) => {
    addProductModalController.open({ data: { location, product } })
  }

  // useRef is not nice, could rewrite NewProductDialog with useModalController and send the location to the dialog as data? Yes.
  const newProductLocation = React.useRef()

  const openNewProductModal = (location) => {
    newProductLocation.current = location
    setNewProductDlgOpen(true)
  }

  const newProduct = (product, packages) => {
    addProduct(newProductLocation.current, product, packages)
    setNewProductDlgOpen(false)
  }

  return (
    <>
      <Modal
        modalController={findProductModalController}
        ContentComponent={findProductModalContent}
        onAddProduct={openAddProductModal}
        onRegisterNewProduct={openNewProductModal}
      />
      <Modal modalController={addProductModalController} ContentComponent={addProductModalContent}
             onAddProduct={addProduct}/>
      {/* Reusing dialog from chemicalList newProductDialog */}
      <NewProductDialog isOpen={isNewProductDlgOpen} setIsOpen={setNewProductDlgOpen} isShowPackages={true}
                        createChemical={newProduct}/>
      {inventoryLocations.map((inventoryLocation, locationIndex) => (
        <InventoryDetailLocation
          key={inventoryLocation.id}
          inventoryLocation={inventoryLocation}
          locationIndex={locationIndex}
          onAddProduct={() => findProductModalController.open({ data: inventoryLocation })}
          validateAllLocations={validateAllLocations}
        />
      ))}
    </>
  )
}
