import Hammer from 'hammerjs'

const colors = ['#FF9999', '#9966CC', '#3399CC', '#00CC99', '#FF6600']

export const layout = (root, l, index, parent) => {
  l.parent = parent

  l.focus = false

  // Locations are not saved until a name has been entered, before that happens id contains a temporary id.
  // When the location is saved id is updated to the database id.
  // The react key for locations needs to be stable or <input> will lose focus.
  if (!l.reactKey) {
    l.reactKey = l.id
  }

  l.oldX = l.x ? l.x : 500
  l.oldY = l.y ? l.y : 500

  l.depth = parent.depth ? parent.depth + 1 : 1
  l.depthIndex = index
  l.side = parent.side

  l.x = 500 + 50 * l.side + 200 * l.side * l.depth

  if (l.depthIndex === 0) {
    // First sibling
    l.y = parent.y
    l.y -= (200 * (parent.leafCount - l.leafCount)) / 2
    if (l.leafCount === 0) {
      l.y += 100
    }
  } else {
    // Get sibling before
    const s = parent.children[l.depthIndex - 1]
    l.y = s.y
    if (s.leafCount > 1) {
      l.y += (200 * (s.leafCount + 1)) / 2
    }
    if (l.leafCount > 1) {
      l.y += (200 * (l.leafCount - 1)) / 2
    }
    if (s.leafCount === 0 || s.leafCount === 1) {
      l.y += 200
    }
  }

  l.r = 60 // + (l.count ? 50 * Math.min(l.count / 20, 1) : 0)

  if (l.oldX == null) l.oldX = l.x
  if (l.oldY == null) l.oldY = l.y

  // Prepare count for addition
  l.count = parseInt(l.count)
  l.userCount = parseInt(l.userCount)
  // recurse to children
  if (l.children) {
    l.children = l.children.map((c, index) => layout(root, c, index, l))
    l.totalChemicalCount = l.children.reduce((total, cl) => (total += cl.totalChemicalCount), l.count)
    l.totalUserCount = l.children.reduce((total, cl) => (total += cl.totalUserCount), l.userCount)
  } else {
    l.totalChemicalCount = l.count
    l.totalUserCount = l.userCount
  }

  root.maxX = Math.max(root.maxX, l.x + l.r)
  root.maxY = Math.max(root.maxY, l.y + l.r)
  root.minX = Math.min(root.minX, l.x - l.r)
  root.minY = Math.min(root.minY, l.y - l.r)

  // select color
  if (!l.temporary) {
    l.color = colors[l.id % colors.length]
  } else {
    l.color = 'transparent'
  }

  return l
}

export const leafCount = (l) => {
  l.leafCount = 0
  if (l.children) {
    for (const c of l.children) {
      if (!c.children || c.children.length === 0) {
        c.leafCount = 0
        l.leafCount++
      } else {
        l.leafCount += leafCount(c)
      }
    }
  }
  return l.leafCount
}

// From svg-pan-zoom mobile demo. Modified to add momentum
export const touchEventHandler = (panMomentum, setPanMomentum, stopPanMomentum) => {
  return {
    haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
    init: function (options) {
      var instance = options.instance,
        initialScale = 1,
        pannedX = 0,
        pannedY = 0

      // Init Hammer
      // Listen only for pointer and touch events
      this.hammer = new Hammer(options.svgElement, {
        inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
      })

      // Enable pinch
      this.hammer.get('pinch').set({ enable: true })

      // Handle double tap
      this.hammer.on('doubletap', function (ev) {
        instance.zoomIn()
      })

      // Handle pan
      this.hammer.on('panstart panmove', function (ev) {
        // On pan start reset panned variables
        if (ev.type === 'panstart') {
          pannedX = 0
          pannedY = 0
          stopPanMomentum(true)
        }

        // Pan only the difference
        instance.panBy({ x: ev.deltaX - pannedX, y: ev.deltaY - pannedY })
        pannedX = ev.deltaX
        pannedY = ev.deltaY
      })

      this.hammer.on('panend', (ev) => {
        setPanMomentum({
          from: { x: ev.velocityX * 10, y: ev.velocityY * 10 },
          to: { x: 0, y: 0 },
          immediate: false,
          reset: true,
          onFrame: (v) => {
            instance.panBy(v)
          }
        })
      })

      // Handle pinch
      this.hammer.on('pinchstart pinchmove', function (ev) {
        // On pinch start remember initial zoom
        if (ev.type === 'pinchstart') {
          initialScale = instance.getZoom()
          instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y })
        }

        instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y })
      })

      // Prevent moving the page on some devices when panning over SVG
      options.svgElement.addEventListener('touchmove', function (e) {
        e.preventDefault()
      })
    },

    destroy: function () {
      this.hammer.destroy()
    }
  }
}
