import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { FlexRow } from 'Components/layout'
import { AbsoluteAnchor, DisabledInfoIcon, InfoButton, Service, ServiceIcon, ServicePanel } from './startPageStyle'

import IconSDSManagementInactive from './assets/ico-sdsm-inactive.svg'
import { Tooltip } from 'Components/readonly'

const FlexRowClickThrough = styled(FlexRow)`
  pointer-events: none;
`

const StartSDSModuleDisabled = () => {
  const intl = useIntl()

  return (
    <Service>
      <ServicePanel disabled>
        <Tooltip content={intl.formatMessage({ id: 'welcome.service-info' })}>
          <InfoButton link label={<DisabledInfoIcon/>}/>
        </Tooltip>

        <FlexRowClickThrough>
          <AbsoluteAnchor>
            <ServiceIcon>
              <img src={IconSDSManagementInactive} alt="SDS management"/>
            </ServiceIcon>
            <span className="service-type">SOFT</span>
            <h2>
              <FormattedMessage id="sds.module"/>
            </h2>
            <span className="service-author">by KemRisk</span>
          </AbsoluteAnchor>
        </FlexRowClickThrough>
      </ServicePanel>
    </Service>
  )
}

export default StartSDSModuleDisabled
