import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'

import { Button } from '../readonly'
import { TextInput } from '../input'
import colors from '../colors.scss'
import { numericKeyfilter } from 'Components/input/textInput'

const Container = styled.div`
  background-color: ${colors.lightBackgroundColor};
  padding: 10px 10px 12px;
  display: flex;
  align-items: end;
  margin-bottom: 10px;

  > div > div {
    margin-bottom: 5px;
  }

  .material {
    flex: 3 1 1%;
    border-right: 8px solid transparent;
  }

  .thickness {
    flex: 2 1 1%;
    border-right: 8px solid transparent;
  }

  .break {
    flex: 3 1 1%;
    border-right: 8px solid transparent;
  }

  .button {
    flex: 0 0 30px;
  }

  @media screen and (max-width: 640px) {
    display: block;
    padding-right: 40px;
    position: relative;
    > div {
      margin-bottom: 8px;
    }

    .button {
      position: absolute;
      top: 5px;
      right: 2px;
    }
  }
`

export const GlovesList = injectIntl(({ id, onChange, value, intl }) => {
  const add = () => {
    const glove = { material: '', thickness: '', breakthrough_time: '' }
    onChange({ target: { value: [...value, glove] } })
  }

  const remove = (index) => {
    const newList = [...value]
    newList.splice(index, 1)
    onChange({ target: { value: newList } })
  }

  const updateInteger = (index, prop, newValue) => update(index, prop, newValue === '' || isNaN(newValue) ? '' : +newValue)

  const updateNumeric = (e, index, prop, newValue) => {
    newValue = newValue.replace(',', '.')
    if (newValue === '' || !isNaN(newValue)) {
      update(index, prop, newValue)
    }
  }

  const update = (index, key, newValue) => {
    const newItem = { ...value[index], [key]: newValue }
    const newList = [...value]
    newList.splice(index, 1, newItem)
    onChange({ target: { value: newList } })
  }

  return (
    <div id={id}>
      <div>
        <label>{intl.formatMessage({ id: 'chemicals.form.gloves' })}</label> <Button icon="pi pi-plus-circle"
                                                                                     onClick={add}/>
      </div>
      {value &&
        value.map((entry, index) => {
          return (
            <Container key={index}>
              <div className="material">
                <div>{intl.formatMessage({ id: 'chemicals.form.gloves_material' })}</div>
                <TextInput value={entry.material} onChange={(e) => update(index, 'material', e.target.value)}/>
              </div>
              <div className="thickness">
                <div>{intl.formatMessage({ id: 'chemicals.form.gloves_thickness' })}</div>
                <TextInput
                  value={entry.thickness}
                  onChange={(e) => updateNumeric(e, index, 'thickness', e.target.value)}
                  keyfilter={numericKeyfilter}
                />
              </div>
              <div className="break">
                <div>{intl.formatMessage({ id: 'chemicals.form.gloves_breakthrough_time' })}</div>
                <TextInput
                  value={entry.breakthrough_time}
                  onChange={(e) => updateInteger(index, 'breakthrough_time', e.target.value)}
                  keyfilter="pint"
                  type="number"
                />
              </div>
              <div className="button">
                <Button icon="pi pi-trash" onClick={() => remove(index)}/>
              </div>
            </Container>
          )
        })}
    </div>
  )
})
