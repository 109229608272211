import React, { useState } from 'react'
import { TabView, ToolPanel } from 'Components/readonly'
import { TabPanel } from 'primereact/tabview'
import { SearchInput } from 'Components/input'
import { withRouter } from 'react-router-dom'
import { PageConstraint, PageHeader } from 'Components/layout'
import { FormattedMessage, useIntl } from 'react-intl'

import { ChemicalList } from './chemicalList'
import { AddProductPage } from './addProductPage'
import { HasCapability } from 'Components/capabilities'
import { InventoryLockMessage } from 'Pages/inventory/components/inventoryLockMessage'

export const ChemicalListPage = withRouter(({ history, match }) => {
  const intl = useIntl()

  const [inputFilter, setInputFilter] = useState('')
  const [he, setHe] = useState([])
  const [pd, setPd] = useState([])
  const [prio, setPrio] = useState([])
  const [candidate, setCandidate] = useState(false)
  const [locations, setLocations] = useState(
    history.location.state && history.location.state.location ? history.location.state.location : []
  )
  const [tagList, setTagList] = useState([])
  const [remarkList, setRemarkList] = useState([])
  const [listingsList, setListingsList] = useState([])
  const [productCount, setProductCount] = useState(null)

  // Used by the datatable, but needs to be manipulated for search
  const [first, setFirst] = useState(0)
  const [page, setPage] = useState(1)

  const filter = {
    he,
    setHe,
    pd,
    setPd,
    prio,
    setPrio,
    candidate,
    setCandidate,
    handleSetCandidate,
    locations,
    setLocations,
    tagList,
    setTagList,
    remarkList,
    setRemarkList,
    listingsList,
    setListingsList
  }

  const handleInputFilter = (event) => {
    setPage(1);
    setFirst(0);
    setInputFilter(event.target.value)
  }

  function handleSetCandidate(value) {
    typeof value == 'boolean' ? setCandidate(value) : setCandidate(false)
  }

  return (
    <>
      <InventoryLockMessage />
      <PageConstraint>
        <PageHeader>
          <h1>
            <FormattedMessage id="chemicals.chemical_list" />
          </h1>
          <ToolPanel>
            <SearchInput value={inputFilter} onChange={handleInputFilter} />
            <div style={{ paddingBottom: '16px' }} />
          </ToolPanel>
        </PageHeader>
        <HasCapability
          capability="CHEMICAL_ADD"
          yes={
            <TabView>
              <TabPanel
                header={intl.formatMessage({ id: 'chemicals.our_chemicals' }) + (productCount === null ? '' : ' (' + productCount + ')')}>
                <ChemicalList
                  filter={filter}
                  history={history}
                  inputFilter={inputFilter}
                  passProductCount={setProductCount}
                  defaultSortOrder={'created_at'}
                  first={first}
                  setFirst={setFirst}
                  page={page}
                  setPage={setPage}
                />
              </TabPanel>
              <TabPanel header={intl.formatMessage({ id: 'chemicals.add_product' })}>
                <AddProductPage inputFilter={inputFilter} />
              </TabPanel>
              <TabPanel header={intl.formatMessage({ id: 'common.archived3' })}>
                <ChemicalList
                  filter={filter}
                  history={history}
                  inputFilter={inputFilter}
                  passProductCount={setProductCount}
                  archive
                  defaultSortOrder={'organisation_removed_at'}
                  first={first}
                  setFirst={setFirst}
                  page={page}
                  setPage={setPage}
                />
              </TabPanel>
            </TabView>
          }
          no={
            <ChemicalList
              filter={filter}
              history={history}
              inputFilter={inputFilter}
              passProductCount={setProductCount}
              defaultSortOrder={'created_at'}
              first={first}
              setFirst={setFirst}
              page={page}
              setPage={setPage}
            />
          }
        />
      </PageConstraint>
    </>
  )
})
