import React, { useContext } from 'react'
import styled from 'styled-components'
import { Calendar } from 'primereact/calendar'
import colors from '../colors.scss'
import { AuthContext } from 'Context'

export const DP = styled(Calendar)`
  &&& {
    &.ui-state-error input {
      border-color: ${colors.validationFailedFieldMarker};
    }

    input {
      background-color: ${colors.white};
      color: #000;
      border-radius: 20px;
      border-right: 0 none;
      padding: 7px 15px;

      &:hover:not(.p-disabled) {
        border-color: ${colors.primary};
      }

      &:focus {
        box-shadow: none;
        border-color: ${colors.primary};
      }
    }

    .p-monthpicker .p-monthpicker-month {
      padding: 0.3em 0.5em;

      &:not(.p-highlight):hover {
        border-radius: 15px;
      }

      &.p-highlight {
        border-radius: 15px;
        background-color: ${colors.primary};
      }
    }

    .p-datepicker table td > span.p-highlight {
      background-color: ${colors.primary};
    }

    .p-datepicker-today > span {
      background-color: ${colors.accentFaded};
    }

    .p-calendar-button {
      margin-left: -2.357em;
      border-radius: 0 20px 20px 0;
    }

    .p-datepicker-trigger.p-button {
      background-color: ${colors.primary};

      &:hover:not(.p-disabled) {
        filter: brightness(110%);
      }
    }

    /* IE11 hack for height */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: 90%;
    }
  }
`

export const Datepicker = ({ dateFormat, ...props }) => {
  const { dateLocalePrime } = useContext(AuthContext)

  return (
    <DP
      showWeek={true}
      showIcon={true}
      monthNavigator={true}
      yearNavigator={true}
      yearRange="2010:2030"
      dateFormat={dateFormat || 'yy-mm-dd'}
      locale={dateLocalePrime}
      appendTo={document.body}
      {...props}
    />
  )
}

export const Monthpicker = ({ ...props }) => {
  const { dateLocalePrime } = useContext(AuthContext)

  return (
    <DP
      showWeek={false}
      showIcon={true}
      dateFormat="MM yy"
      view="month"
      yearNavigator={true}
      yearRange="2010:2030"
      locale={dateLocalePrime}
      {...props}
    />
  )
}
