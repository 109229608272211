import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Column } from 'primereact/column'

import { Section } from 'Components/layout'
import { DataTable } from 'Components/readonly'
import { LocationInventoryHeader } from './locationInventoryHeader'

export default ({ chemical, inventoryingChange, inventoryProp }) => {
  const intl = useIntl()

  // Packaging table bodies
  const packingBody = (row) =>
    row.is_bulk ? intl.formatMessage({ id: 'chemicals.bulk' }) + ', ' + row.unit.label : row.packaging_size + ' ' + row.unit.label

  const updateAmountBody = (row) => {
    if (inventoryingChange === 'archive_all' || row.to_be_archived) {
      return (
        <span>
          <FormattedMessage id="inventory.will-be-archived"/>
        </span>
      )
    }

    return row.is_bulk
      ? row.amount + ' ' + row.unit.label
      : row.amount_opened +
      ' ' +
      intl.formatMessage({ id: 'chemicals.opened-lc' }) +
      ', ' +
      row.amount +
      ' ' +
      intl.formatMessage({ id: 'chemicals.unopened-lc' })
  }

  const archiveBody = (data, row) => {
    return (
      <>
        {row.to_be_archived || inventoryingChange === 'archive_all' ? (
          <FormattedMessage id="common.yes"/>
        ) : (
          <FormattedMessage id="common.no"/>
        )}
      </>
    )
  }

  return (
    <Section key={chemical.id}>
      <LocationInventoryHeader chemical={chemical}/>
      <DataTable className="flextable" value={chemical.inventory} paginator={false}>
        <Column header={intl.formatMessage({ id: 'inventory.packaging' })} body={packingBody}/>
        <Column header={intl.formatMessage({ id: 'inventory.update-amount' })} body={updateAmountBody}
                style={{ width: '250px' }}/>
        <Column header={intl.formatMessage({ id: 'common.archive' })} body={archiveBody} style={{ width: '100px' }}/>
      </DataTable>
    </Section>
  )
}
