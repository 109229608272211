import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, LoadingOverlay } from '../readonly'
import { TextInput } from '../input'
import colors from '../colors.scss'
import { useIntl } from 'react-intl'
import { AutoComplete } from '../input/autoComplete'

const Add = styled.div`
  display: flex;

  > ${TextInput} {
    input {
      width: 200px;
    }
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 150px;
    margin-left: 10px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 640px) {
    display: block;
    ${AutoComplete} {
      width: 100%;
      display: block;
      margin-bottom: 8px;
    }

    ${Button}${Button}${Button} {
      width: 100%;
      display: block;
      margin: 0;
    }
  }
`

const Entry = styled.div`
  display: flex;
  align-items: center;

  > div {
    flex: 1 0 1%;
  }

  > ${Button}${Button}${Button} {
    flex: 0 0 30px;
    height: 30px;
    margin-bottom: 0;
  }

  padding: 10px 20px;
`

const EntryContainer = styled.div`
  &.edit {
    background-color: ${colors.darkerBackgroundColor};
    padding: 10px;
  }
`

const Entries = styled.div`
  margin-top: 10px;

  ${EntryContainer}:nth-child(odd) {
    background-color: ${colors.lightBackground};
  }
`

export const PickListAutocomplete = ({ id, value, list, language, onChange, isLoading }) => {
  const intl = useIntl()
  const [selectedValue, setSelectedValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  value = value || []

  const add = () => {
    const newValue = [...value, selectedValue]
    onChange({ target: { value: newValue } })
    setSelectedValue('')
  }

  const remove = (index) => {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange({ target: { value: newValue } })
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter' && selectedValue && hasValue()) {
      add()
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const hasValue = () => {
    let existsInValue = list.filter((item) => {
      return item === selectedValue
    })
    return list && existsInValue.length === 1
  }

  const onSelectedValueChanged = (event) => {
    setSelectedValue(event.value)
  }

  const onSearchFieldChange = (value) => {
    if (value === '') {
      setSelectedValue(null)
    } else {
      setSelectedValue(value)
    }
  }

  const suggestList = (event) => {
    setSuggestions(list.filter((entry) => entry.label.toLowerCase().includes(event.query.toLowerCase())))
  }

  return (
    <div id={id}>
      <LoadingOverlay isLoading={isLoading}>
        <Add>
          <AutoComplete
            completeMethod={suggestList.bind(this)}
            suggestions={suggestions}
            field="label"
            value={selectedValue}
            onSelect={(event) => onSelectedValueChanged(event)}
            onUnselect={() => setSelectedValue(null)}
            onChange={(e) => onSearchFieldChange(e.value)}
            onKeyPress={onKeyPress}
          />
          <Button label={intl.formatMessage({ id: 'common.add' })} disabled={isLoading || !selectedValue || !hasValue()}
                  onClick={add}/>
        </Add>
      </LoadingOverlay>
      <Entries>
        {value &&
          value.map((entry, index) => {
            return (
              <EntryContainer key={index}>
                <Entry>
                  <div>{entry.label}</div>
                  <Button icon="pi pi-trash" onClick={() => remove(index)}/>
                </Entry>
              </EntryContainer>
            )
          })}
      </Entries>
    </div>
  )
}
