import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TabView } from 'Components/readonly'
import { TabPanel } from 'primereact/tabview'
import { ReportRoles } from './components/reportRoles'
import { ReportProducts } from './components/reportProducts'
import { ReportOrganisationSdsCount } from './components/reportOrganisationSdsCount'
import { PageConstraint, PageHeader } from 'Components/layout'

export const ReportsPage = () => {
  const intl = useIntl()

  return (
    <PageConstraint>
      <PageHeader>
        <h1>
          <FormattedMessage id="admin.reports"/>
        </h1>
      </PageHeader>
      <TabView>
        <TabPanel header={intl.formatMessage({ id: 'admin.report_roles' })}>
          <ReportRoles/>
        </TabPanel>
        <TabPanel header={intl.formatMessage({ id: 'admin.report_products' })}>
          <ReportProducts/>
        </TabPanel>
        <TabPanel header={intl.formatMessage({ id: 'admin.report_subscription_sds_counts' })}>
          <ReportOrganisationSdsCount/>
        </TabPanel>
      </TabView>
    </PageConstraint>
  )
}
