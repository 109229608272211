import _ from 'lodash'

export const includes = (prop, s) => {
  return prop && (prop + '').toLowerCase().includes(s.toLowerCase())
}

export const globalSearch = (list, search, matchProps, matchFunctions) => {
  let filtered = list
  search
    .split(' ')
    .filter((s) => !!s)
    .forEach((s) => {
      filtered = filtered.filter(
        (item) =>
          (matchProps && matchProps.length > 0 && matchProps.some((prop) => includes(_.get(item, prop), s))) ||
          (matchFunctions && matchFunctions.length > 0 && matchFunctions.some((func) => func(item, s)))
      )
    })

  return filtered
}
