import React, { useContext } from 'react'
import styled from 'styled-components'

import GHS01 from 'Components/readonly/assets/explos.svg'
import GHS02 from 'Components/readonly/assets/flamme.svg'
import GHS03 from 'Components/readonly/assets/rondflam.svg'
import GHS04 from 'Components/readonly/assets/bottle.svg'
import GHS05 from 'Components/readonly/assets/acid.svg'
import GHS06 from 'Components/readonly/assets/skull.svg'
import GHS07 from 'Components/readonly/assets/exclam.svg'
import GHS08 from 'Components/readonly/assets/silhouette.svg'
import GHS09 from 'Components/readonly/assets/pollu.svg'
import { AuthContext } from 'Context'

const pictoList = { GHS01, GHS02, GHS03, GHS04, GHS05, GHS06, GHS07, GHS08, GHS09 }

export const pictoLabels = {
  GHS01: { sv: 'Explosiv', en: 'Explosive' },
  GHS02: { sv: 'Brandfarlig', en: 'Flammable' },
  GHS03: { sv: 'Oxiderande', en: 'Oxidising' },
  GHS04: { sv: 'Gas under tryck', en: 'Gas under pressure' },
  GHS05: { sv: 'Frätande', en: 'Corrosive' },
  GHS06: { sv: 'Giftig', en: 'Toxic' },
  GHS07: { sv: 'Skadlig', en: 'Harmful' },
  GHS08: { sv: 'Hälsofarlig', en: 'Health hazard' },
  GHS09: { sv: 'Miljöfarlig', en: 'Environmental hazard' }
}

const Picto = styled.span`
  height: 80px;
  width: 80px;
  margin-right: 2px;
  background-size: 100%;
  display: inline-block;
  background-image: url(${(props) => pictoList[props.picto]});
  background-repeat: no-repeat;
`

const TablePicto = styled(Picto)`
  height: 45px;
  width: 45px;
`

const PictoContainer = styled.div`
  display: inline-block;
  text-align: center;
  margin: 20px;
`

export const LabeledPictos = () => {
  const { user } = useContext(AuthContext)

  return (
    <>
      {Object.keys(pictoList).map((pictoCode) => (
        <PictoContainer>
          <Picto picto={pictoCode}/>
          <div>
            <strong>{pictoLabels[pictoCode][user.user.language]}</strong>
          </div>
          <div>{pictoCode}</div>
        </PictoContainer>
      ))}
    </>
  )
}

export const Markings = (row) =>
  row.hazard_pictograms ? row.hazard_pictograms.map((picto, index) => <Picto picto={picto.code} key={index}/>) : ''

export const TableMarkings = (row) =>
  row.hazard_pictograms ? row.hazard_pictograms.map((picto, index) => <TablePicto picto={picto.code} key={index}/>) : ''
