import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import format from 'date-fns/format'

import { FormContext, FormContextProvider } from './formContext.js'
import ValidationFailedMessage from './validationFailedMessage'
import { Field } from './field.js'

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 20px;
  }
`

const createArrayIfSingleElement = (a) => {
  return [].concat(a || [])
}

const filesToFormDataConverter = (key, files, sendData) => {
  if (_.isArray(files)) {
    _.forEach(files, (file) => {
      if (!file.deleted && !file.id) {
        sendData.append(key, file.file)
        if (file.extra) {
          sendData.append(key + '.extra', file.extra)
        }
      }
    })
  }
}

const getChildren = (children, properties) => {
  let returnChildren = []
  const getChildrenRecurse = (children) => {
    createArrayIfSingleElement(children).forEach((child) => {
      if (child && child.type && child.type.name === Field.name && properties.some((id) => id === child.props.id)) {
        returnChildren.push(child)
      }
      if (child && child.props && child.props.children) {
        getChildrenRecurse(child.props.children)
      }
    })
  }
  getChildrenRecurse(children)
  return returnChildren
}

export const FormInContext = forwardRef(({
                                           id,
                                           className,
                                           children,
                                           onSubmit,
                                           useFormData,
                                           toFormDataConverters
                                         }, ref) => {
  const { formData, setFormData, anyValidationFailed, validateForm } = useContext(FormContext)
  const formRef = useRef()

  useImperativeHandle(ref, () => ({
    getChildren: (properties) => {
      if (properties) {
        return getChildren(children, properties)
      }
      return children
    },
    submit: handleSubmit
  }))

  const handleSubmit = (event) => {
    if (event && event.target !== formRef.current) {
      console.warn('Ignoring submit event targetting another form element')
      return
    }

    event && event.preventDefault()

    const validationErrors = validateForm(children)

    if (_.isEmpty(validationErrors) && onSubmit) {
      // FormData is needed to send file uploads
      if (useFormData) {
        const sendData = new FormData()
        _.forEach(formData, (value, key) => {
          if (value === false) {
            value = 0
          }
          if (value === true) {
            value = 1
          }
          if (value === null) {
            value = ''
          }
          if (value instanceof Date) {
            value = format(value, 'YYYY-MM-DD')
          }

          if (toFormDataConverters && toFormDataConverters[key]) {
            toFormDataConverters[key](key, value, sendData)
          }
          // Detect if this is a fileupload component
          else if (_.isArray(value) && value.length > 0 && _.has(value[0], 'file')) {
            filesToFormDataConverter(key, value, sendData)
          } else if (value instanceof Blob) {
            sendData.append(key, value)
          } else if (_.isArray(value) || _.isObject(value)) {
            sendData.append(key, JSON.stringify(value))
          } else {
            sendData.append(key, value)
          }
        })
        onSubmit(sendData, formData, setFormData)
      } else {
        onSubmit(formData, formData, setFormData)
      }
    }
  }

  return (
    <StyledForm className={className} onSubmit={handleSubmit} id={id} ref={formRef}>
      {anyValidationFailed(children) && <ValidationFailedMessage/>}
      {children}
    </StyledForm>
  )
})

export const Form = forwardRef(({ className, children, onSubmit, useFormData, toFormDataConverters }, ref) => {
  const initialFormData = {}

  const createInitialFormData = (children) => {
    createArrayIfSingleElement(children).forEach((child) => {
      if (!child) {
        return false
      }
      if (child.type && child.type.name === Field.name) {
        const id = child.props.id
        const setValue = child.props.initialValue !== undefined ? child.props.initialValue : ''
        if (id.indexOf('[]') !== -1) {
          initialFormData[id] = setValue
        } else {
          _.setWith(initialFormData, id, setValue, Object)
        }
      }

      if (child.props && child.props.children) {
        createInitialFormData(child.props.children)
      }
    })
  }

  if (!children) {
    console.warn('<Form> without children')
  } else {
    createInitialFormData(children)
  }

  return (
    <FormContextProvider initialFormData={initialFormData}>
      <FormInContext
        className={className}
        ref={ref}
        onSubmit={onSubmit}
        useFormData={useFormData}
        toFormDataConverters={toFormDataConverters}>
        {children}
      </FormInContext>
    </FormContextProvider>
  )
})
