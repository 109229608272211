import React, { createContext, useContext, useRef, useState } from 'react'
import axios from 'axios'

import { GrowlContext } from './'

export const CacheContext = createContext({
  getCachedItem: () => {
  },
  setValue: () => {
  }
})

export const CacheContextProvider = ({ children }) => {
  const { displayError } = useContext(GrowlContext)
  const [cache, setCache] = useState({})
  const cacheRef = useRef(cache)

  // requestId is used to track which requests have failed, preventing a loop
  const getCachedItem = (requestId, url, initialValue, observe) => {
    //console.debug('getCachedItem requestId',requestId)
    // Use the ref to catch several fetches during one render cycle
    let cachedItem = cacheRef.current[url]
    if (cachedItem && (!cachedItem.error || cachedItem.requestIdsFailed.includes(requestId))) {
      // Check that no observed value has changed
      if (!observe || observe.every((value, index) => Object.is(value, cachedItem.observe[index]))) {
        return cachedItem
      }
      //console.debug('Observed value has changed, refetching', url)
    }

    cachedItem = {
      isLoading: true,
      value: initialValue,
      error: null,
      requestIdsFailed: cachedItem ? cachedItem.requestIdsFailed : [],
      observe: observe
    }
    cacheRef.current[url] = cachedItem

    fetchData(requestId, url)

    return cachedItem
  }

  const setValue = (url, data) => {
    cacheRef.current[url] = {
      ...cacheRef.current[url],
      isLoading: false,
      value: data,
      error: null
    }
    // Update state to rerender components
    setCache({ ...cacheRef.current })
  }

  const setError = (requestId, url, error) => {
    const newRequestIdsFailed = cacheRef.current[url].requestIdsFailed
    if (!newRequestIdsFailed.includes(requestId)) {
      newRequestIdsFailed.push(requestId)
    }
    cacheRef.current[url] = {
      ...cacheRef.current[url],
      isLoading: false,
      error: error,
      requestIdsFailed: newRequestIdsFailed,
    }
    setCache({ ...cacheRef.current })
  }

  const fetchData = async (requestId, url) => {
    //    console.debug('cacheContext fetchData', url)
    try {
      const cachedItem = cacheRef.current[url]
      const result = await axios(url)
      if (cachedItem && cachedItem.transform) {
        setValue(url, cachedItem.transform(result.data))
      } else if (result.data.data) {
        // Laravel resources wrapped in data
        setValue(url, result.data.data)
      } else {
        setValue(url, result.data)
      }
    } catch (error) {
      setError(requestId, url, error)
      displayError(error)
    }
  }

  const cacheContext = {
    getCachedItem,
    setValue
  }

  return <CacheContext.Provider value={cacheContext}>{children}</CacheContext.Provider>
}
