import React from 'react'
import { TabPanel } from 'primereact/tabview'
import { FormattedMessage, useIntl } from 'react-intl'

import { LoadingSpinner, TabView } from 'Components/readonly'
import RevisionSubscriptionUpcomingTable from './revisionSubscriptionUpcomingTable'
import { LongerSection, PageConstraint, PageHeader } from 'Components/layout'
import useCrudData from 'Hooks/useCrudData'
import { AuthContext } from 'Context'
import RevisionSubscriptionCompletedTable from './revisionSubscriptionCompletedTable'
import { useHistory, useParams } from 'react-router-dom'

const RevisionSubscriptionPage = ({ admin }) => {
  const intl = useIntl()
  // Need to refetch and rerender when organisation changes
  const { organisation } = React.useContext(AuthContext)
  const url = '/rapi/' + (admin ? 'admin/' : '') + 'revisionsubscription/listsds'
  const [isLoading, sds, , readSds, updateSds, deleteSds] = useCrudData(url, [], [organisation])

  const history = useHistory()
  const params = useParams()

  const activeIndex = params.mode === 'active' ? 0 : 1

  const selectTab = (index) => {
    if (index === 0) {
      history.push('active')
    } else {
      history.push('done')
    }
  }

  return (
    <PageConstraint>
      <PageHeader>
        <h1>
          <FormattedMessage id="sds.revision-of-sds"/>
        </h1>
      </PageHeader>
      <LoadingSpinner isLoading={isLoading}>
        <TabView activeIndex={activeIndex} onTabChange={(e) => selectTab(e.index)}>
          <TabPanel header={intl.formatMessage({ id: 'sds.upcoming-revisions' })}>
            <LongerSection>
              <FormattedMessage id="sds.revision-subscription.upcoming-instruction"/>
            </LongerSection>
            <RevisionSubscriptionUpcomingTable
              admin={admin}
              isLoading={isLoading}
              sds={sds}
              readSds={readSds}
              updateSds={updateSds}
              deleteSds={deleteSds}
            />
          </TabPanel>
          <TabPanel header={intl.formatMessage({ id: 'sds.completed-revisions' })}>
            <LongerSection>
              <FormattedMessage id="sds.revision-subscription.completed-instruction"/>
            </LongerSection>
            <RevisionSubscriptionCompletedTable admin={admin}/>
          </TabPanel>
        </TabView>
      </LoadingSpinner>
    </PageConstraint>
  )
}

export default RevisionSubscriptionPage
