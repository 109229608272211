import _ from 'lodash'

import useGetData from './useGetData'

const useCrudData = (url, initialValue, observe, transform, debounce = false) => {
  const [isLoading, data, setData] = useGetData(url, initialValue, observe, transform, debounce)

  const createData = (newData) => {
    setData([...data, newData])
  }

  const readData = (id) => {
    if (id == null) return null
    const matches = data.filter((s) => s.id === id)
    if (matches.length === 0) return null
    return matches[0]
  }

  const updateData = (updatedData) => {
    setData(data.map((s) => (s.id === updatedData.id ? { ...updatedData } : s)))
  }

  const updateDatas = (inData) => {
    if (_.isArray(inData)) {
      inData.forEach(updateData)
    } else {
      updateData(inData)
    }
  }

  const deleteData = (deletedData) => {
    setData(data.filter((s) => s.id !== deletedData.id))
  }

  return [isLoading, data, createData, readData, updateDatas, deleteData, setData]
}

export default useCrudData
