import React from 'react'
import LoginForm from './loginForm'
import PasswordReset from './password_reset'
import PasswordResetForm from './password_reset_form'
import { HashRouter, Route, Switch } from 'react-router-dom'
import LoginContainer from './loginContainer'

const LoginPage = () => {
  return (
    <LoginContainer>
      <HashRouter>
        <Switch>
          <Route path={'/resetform/:token/:email?'} component={PasswordResetForm}/>
          <Route path={'/reset'} component={PasswordReset}/>
          <Route component={LoginForm}/>
        </Switch>
      </HashRouter>
    </LoginContainer>
  )
}

export default LoginPage
