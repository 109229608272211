import _ from 'lodash'

export default (value) => {
  if (_.isEmpty(value)) {
    return true
  }
  let errors = []
  value.forEach((v, i) => {
    let e = { index: i }
    let hasErrors = false
    if (!v.is_bulk && v.packaging_size === '') {
      e.packaging_size = true
      hasErrors = true
    }
    if (!v.unit_id) {
      e.unit_id = true
      hasErrors = true
    }

    if (hasErrors) {
      errors.push(e)
    }
  })

  if (errors.length > 0) {
    return errors
  }
}
