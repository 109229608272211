import React, { useContext, useState } from 'react'
import axios from 'axios'

import { Dialog } from 'primereact/dialog'
import { Button, InfoPanel, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Section, Spacer } from 'Components/layout'
import { PreserveNewlines } from 'Utils/newLines'

const SdsArchiveDialog = ({ kind, sds, isOpen, setIsOpen, onArchiveDone, intl }) => {
  const { displayError } = useContext(GrowlContext)
  const [isLoading, setIsLoading] = useState(false)

  const archive = async () => {
    setIsLoading(true)
    try {
      await axios.post('/rapi/sds/deactivate', {
        kind: kind,
        id: sds.id
      })
      setIsLoading(false)
      onArchiveDone()
    } catch (error) {
      displayError(error)
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      header={kind === 'external' ? intl.formatMessage({ id: 'sds.remove-sds' }) : intl.formatMessage({ id: 'sds.archive-sds' })}
      visible={isOpen}
      modal={true}
      onHide={() => setIsOpen(false)}
      appendTo={document.body}>
      <Section>
        <table>
          <tbody>
          <tr>
            <td>
              <FormattedMessage id="sds.id"/>
            </td>
            <td>{sds.id}</td>
          </tr>
          <tr>
            <td>
              <FormattedMessage id="sds.article-number"/>
            </td>
            <td>{sds.articleNumber}</td>
          </tr>
          <tr>
            <td>
              <FormattedMessage id="sds.product-name"/>
            </td>
            <td>
              <PreserveNewlines text={sds.productName}/>
            </td>
          </tr>
          <tr>
            <td>
              <FormattedMessage id="common.country"/>
            </td>
            <td>{sds.countryName}</td>
          </tr>
          <tr>
            <td>
              <FormattedMessage id="common.language"/>
            </td>
            <td>{sds.langName}</td>
          </tr>
          </tbody>
        </table>
      </Section>
      <Section>
        <InfoPanel messageid="sds.archive-warning"/>
      </Section>

      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => setIsOpen(false)}/>
        <Button
          primary
          icon="pi pi-folder"
          label={kind === 'external' ? intl.formatMessage({ id: 'common.delete' }) : intl.formatMessage({ id: 'common.archive' })}
          onClick={archive}
          isLoading={isLoading}
        />
      </ModalActionsFooter>
    </Dialog>
  )
}

export default injectIntl(SdsArchiveDialog)
