import React from 'react'
import styled from 'styled-components'
import { SplitToggleButton } from './splitToggleButton'

export const SplitToggleButtonWithModal = styled(({ onClickRight, icon, ...props }) => {
  return (
    <>
      <SplitToggleButton {...props} icon={icon} onClickRight={onClickRight}/>
    </>
  )
})``
