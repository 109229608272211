import React from 'react'
import { ChartContainer, ChartLegend, ChartLegendCircle, ChartLegendRow, StyledChart } from 'Components/layout'
import { useIntl } from 'react-intl'
import colors from 'Components/colors.scss'
import { LoadingSpinner } from 'Components/readonly'

const chartBeforeDraw = (chart, label) => {
  var width = chart.chart.width,
    height = chart.chart.height,
    ctx = chart.chart.ctx

  const scale = chart.chart.width / 236
  const textScale = chart.chart.width > 200 ? scale * 0.6 : scale

  var centerConfig = chart.config.options.elements.center
  var color = centerConfig.color || colors.black

  ctx.save()
  // Draw circle background
  ctx.beginPath()
  ctx.arc(width / 2, height / 2, 59 * scale, 0, 2 * Math.PI)
  ctx.fillStyle = colors.darkBackground
  ctx.fill()

  // Draw shadow with stroke offcanvas and shadow moved into canvas with offset
  ctx.shadowBlur = 9
  ctx.shadowOffsetX = 4 + 1000
  ctx.shadowOffsetY = 4
  ctx.shadowColor = colors.chartShadow
  ctx.beginPath()
  ctx.arc(-1000 + width / 2, height / 2, 59 * scale, 0, 2 * Math.PI)
  ctx.lineWidth = 4
  ctx.stroke()
  ctx.restore()

  // Remove shadow outside circle
  ctx.arc(width / 2, height / 2, 59 * scale, 0, 2 * Math.PI)
  ctx.globalCompositeOperation = 'destination-in'
  ctx.fill()
  ctx.globalCompositeOperation = 'source-over'

  var fontSize = (textScale * (height / 50)).toFixed(2)
  ctx.font = textScale * fontSize + 'em Roboto'
  ctx.textBaseline = 'middle'
  ctx.fillStyle = color
  ctx.textAlign = 'center'

  var text = centerConfig.text,
    textX = width / 2,
    textY = height / 2

  ctx.fillText(text, textX, textY - 10)
  ctx.font = scale * 1.25 + 'em Roboto'
  ctx.fillText(label, textX, textY + 20)
}

const SdsStatsChart = React.memo(({ size, animate, stats, isLoading }) => {
  const intl = useIntl()

  size = !size ? 236 : size
  const animationTime = !animate ? 0 : 1000

  const data = {
    labels: [
      intl.formatMessage({ id: 'sds.to_be_approved' }),
      intl.formatMessage({ id: 'sds.active' })
    ],
    datasets: [
      {
        data: [
          stats.approveCount,
          stats.activeCount - (stats.approveCount ? stats.approveCount : 0) - (stats.revisionCount ? stats.revisionCount : 0)
        ],
        borderWidth: 0,
        backgroundColor: [colors.red, colors.accent, colors.chartGray],
        hoverBackgroundColor: [colors.red, colors.accent, colors.chartGray]
      }
    ]
  }

  const options = {
    legend: {
      display: false,
      position: 'right'
    },
    elements: {
      center: {
        beforeDraw: (chart) => chartBeforeDraw(chart, intl.formatMessage({ id: 'common.total' })),
        text: !stats.activeCount ? '0' : stats.activeCount,
        color: colors.white
      }
    },
    animation: {
      duration: animationTime
    },
    tooltips: {
      enabled: false
    }
  }

  const legendRow = (i) => {
    return (
      <ChartLegendRow fontSize={size < 200 ? '14px' : '16px'}>
        <ChartLegendCircle color={data.datasets[0].backgroundColor[i]}/>
        {data.labels[i]} ({data.datasets[0].data[i] ? data.datasets[0].data[i] : 0})
      </ChartLegendRow>
    )
  }

  return (
    <LoadingSpinner isLoading={isLoading} animate>
      <ChartContainer>
        <StyledChart type="doughnut" data={data} options={options} width={size + 'px'} height={size + 'px'}/>
        <ChartLegend>
          {legendRow(0)}
          {legendRow(1)}
        </ChartLegend>
      </ChartContainer>
    </LoadingSpinner>
  )
})

export default SdsStatsChart
