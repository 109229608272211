import React from 'react'
import axios from 'axios'
import { FlexRow, PageConstraint, PageHeader } from 'Components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import { Column } from 'primereact/column'

import useCrudData from 'Hooks/useCrudData'
import { Button, DataTable, Modal } from 'Components/readonly'
import useModalController from 'Hooks/useModalController'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import { GrowlContext } from 'Context'
import EditStartPageContentDialog from './components/editStartPageContentDialog'

const url = '/rapi/admin/startpage'

export const EditStartPagePage = () => {
  const intl = useIntl()
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  // eslint-disable-next-line no-unused-vars
  const [isLoading, contents, createContent, readContent, updateContent, deleteContent] = useCrudData(url, [])
  const [deleteContentModalController] = useModalController({ title: intl.formatMessage({ id: 'common.delete' }) })
  const [editContentModalController] = useModalController({ title: intl.formatMessage({ id: 'common.edit' }) })

  const types = {
    news: 'Nyhetsinledning',
    welcome: 'Välkomsttext'
  }

  const deleteAction = async (content) => {
    try {
      const result = await axios.delete(url + '/' + content.id)
      displaySuccess(result.data.message)
      deleteContent(content)
    } catch (error) {
      displayError(error)
    }
  }

  const typeBody = (row, column) => row[column.field] && types[row[column.field]]

  const changeBody = (row) => (
    <FlexRow key={row}>
      <Button
        primary
        icon="pi pi-pencil"
        onClick={() => {
          editContentModalController.open({ data: row })
        }}
      />
      <Button
        primary
        icon="pi pi-trash"
        onClick={() => {
          deleteContentModalController.open({ data: row })
        }}
      />
    </FlexRow>
  )

  return (
    <PageConstraint>
      <Modal
        modalController={editContentModalController}
        ContentComponent={EditStartPageContentDialog}
        onCreate={createContent}
        onUpdate={updateContent}
      />
      <Modal
        modalController={deleteContentModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="admin.delete_content_text"
        confirmTextId="common.delete"
        onConfirm={deleteAction}
      />
      <PageHeader>
        <h1>
          <FormattedMessage id="admin.start_page_content"/>
        </h1>
        <Button
          primary
          label={intl.formatMessage({ id: 'admin.create_content' })}
          icon="pi pi-plus"
          onClick={() => {
            editContentModalController.open({ title: intl.formatMessage({ id: 'common.create' }) })
          }}
        />
      </PageHeader>
      <DataTable className="flextable" value={contents} paginator={true} rows={10} loading={isLoading}>
        <Column field="text_sv" header={intl.formatMessage({ id: 'admin.content_sv' })} sortable={true}/>
        <Column field="text_en" header={intl.formatMessage({ id: 'admin.content_en' })} sortable={true}/>
        <Column field="text_no" header={intl.formatMessage({ id: 'admin.content_no' })} sortable={true}/>
        <Column field="text_dk" header={intl.formatMessage({ id: 'admin.content_dk' })} sortable={true}/>
        <Column field="type" header={intl.formatMessage({ id: 'common.type' })} sortable={true} body={typeBody}/>
        <Column field="created_at" header={intl.formatMessage({ id: 'common.created' })} sortable={true}
                style={{ width: '180px' }}/>
        <Column field="updated_at" header={intl.formatMessage({ id: 'common.updated' })} sortable={true}
                style={{ width: '180px' }}/>
        <Column
          header={intl.formatMessage({ id: 'common.edit' }) + '/' + intl.formatMessage({ id: 'common.delete' })}
          body={changeBody}
          style={{ width: '1%' }}
        />
      </DataTable>
    </PageConstraint>
  )
}
