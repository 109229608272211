import React, { useContext } from 'react'
import { Button } from 'Components/input'
import { AuthContext } from 'Context'
import styled from 'styled-components'

import { Avatar, Modal, Panel, ToolPanel } from 'Components/readonly'
import { FlexRowResponsive } from 'Components/layout'
import { UserDialog } from './userDialog'
import PasswordDialog from './passwordDialog'
import useModalController from 'Hooks/useModalController'
import { FormattedMessage, useIntl } from 'react-intl'

const ProfileLayout = styled(FlexRowResponsive)`
  > * {
    flex: 1 1 0%;
  }

  ${Avatar} {
    margin: 20px 0 0 0;
  }
`

export const SettingsMyProfilePanel = () => {
  const intl = useIntl()
  const { user, organisation, updateUserProfile } = useContext(AuthContext)

  const [userModalController] = useModalController({ title: intl.formatMessage({ id: 'user.edit_user' }) })
  const [passwordModalController] = useModalController({ title: intl.formatMessage({ id: 'user.edit_password' }) })

  const updateProfile = (data) => {
    updateUserProfile(data)
  }

  return (
    <>
      <UserDialog modalController={userModalController} onUpdate={updateProfile}/>
      <Modal modalController={passwordModalController} ContentComponent={PasswordDialog}/>

      <ToolPanel>
        <Button
          primary
          icon="pi pi-pencil"
          label={intl.formatMessage({ id: 'common.edit' })}
          onClick={() => {
            userModalController.open({ data: { user: user.user, organisation } })
          }}
        />
        <Button
          label={intl.formatMessage({ id: 'user.edit_password' })}
          onClick={() => {
            passwordModalController.open({ data: user.user })
          }}
        />
      </ToolPanel>

      <Panel>
        <ProfileLayout>
          <div>
            <h2>
              <FormattedMessage id="common.name"/>
            </h2>
            <p>
              <FormattedMessage id="user.ur_name_is"/> <strong>{user.user.name}</strong>
            </p>
            <h2>
              <FormattedMessage id="user.email"/>
            </h2>
            <p>{user.user.email}</p>
            <h2>
              <FormattedMessage id="user.phone"/>
            </h2>
            <p>{user.user.phone || '-'}</p>
            <h2>
              <FormattedMessage id="user.role"/>
            </h2>
            <p>
              <FormattedMessage id="user.ur_role_is"/>
              {organisation.has_sds && (
                <div>
                  <FormattedMessage id="sds.module"/>: <strong>{user.user.roles.sds}</strong>
                </div>
              )}
              {organisation.has_chemical_list && (
                <div>
                  <FormattedMessage id="chemicals.module"/>: <strong>{user.user.roles.chemical_list}</strong>
                </div>
              )}
            </p>
            <h2>
              <FormattedMessage id="user.language"/>
            </h2>
            <p>{user.user.langName}</p>
          </div>
          <div>
            <h2>
              <FormattedMessage id="user.avatar"/>
            </h2>
            <Avatar src={user.user.avatar} name={user.user.name}/>
          </div>
        </ProfileLayout>
      </Panel>
    </>
  )
}
