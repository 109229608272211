import React, { useContext, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'

import { GrowlContext } from 'Context'
import { Field, FieldGroup, Form } from 'Components/form'
import { Button, TextInput } from 'Components/input'
import { FlexRow } from 'Components/layout'
import { ModalActionsFooter } from 'Components/readonly'
import { validatePasswordUtil } from 'Utils/validatePasswordUtil'

const DialogWrapper = styled.div``

const PasswordDialog = ({ modalController, onUpdate, onCreate, onDelete, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)

  const user = modalController.data

  const changePassword = async (formData) => {
    const password = {
      'current-password': formData.oldPassword,
      password: formData.newPassword,
      password_confirmation: formData.newPasswordConfirm,
      email: user.email
    }

    try {
      const result = await axios.post('/rapi/password/change', password)
      displaySuccess(result.data.message)
      modalController.close()
    } catch (error) {
      displayError(error)

    }
  }

  const onSubmit = (formData) => {
    setIsLoading(true)
    changePassword(formData)
    setIsLoading(false)
  }

  const validatePassword = (field, formData) => {
    return validatePasswordUtil(intl, field, formData.newPasswordConfirm)
  }

  return (
    <DialogWrapper>
      <Form onSubmit={onSubmit}>
        <FlexRow>
          <FieldGroup columns="1">
            <Field
              id="oldPassword"
              label={intl.formatMessage({ id: 'auth.current_password' })}
              focus="true"
              Component={TextInput}
              required={true}
              type="password"
            />
            <Field
              id="newPassword"
              label={intl.formatMessage({ id: 'auth.new_password' })}
              Component={TextInput}
              required={true}
              validation={validatePassword}
              type="password"
            />
            <Field
              id="newPasswordConfirm"
              label={intl.formatMessage({ id: 'auth.new_password_confirm' })}
              Component={TextInput}
              required={true}
              type="password"
            />
          </FieldGroup>
        </FlexRow>

        <ModalActionsFooter>
          <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
          <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                  isLoading={isLoading}/>
        </ModalActionsFooter>
      </Form>
    </DialogWrapper>
  )
}

export default injectIntl(PasswordDialog)
