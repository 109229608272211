import React from 'react'
import { animated, config, Spring } from 'react-spring/renderprops'

const LocationLine = ({ location }) => {
  return (
    <Spring
      native
      config={config.default}
      from={{ x: location.oldX, y: location.oldY, px: location.parent.oldX, py: location.parent.oldY }}
      to={{ x: location.x, y: location.y, px: location.parent.x, py: location.parent.y }}>
      {(props) => (
        <>
          <animated.line x1={props.px} y1={props.py} x2={props.x} y2={props.y}/>
          {location.children && location.children.map((l) => <LocationLine key={l.id} location={l}/>)}
        </>
      )}
    </Spring>
  )
}

export default LocationLine
