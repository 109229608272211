import React, { useContext, useState } from 'react'

import { Button, ModalActionsFooter } from '../../readonly'
import { FlexColumn, FlexRow, Spacer, TextContainer } from '../../layout'
import { FormattedHTMLMessage, useIntl } from 'react-intl'
import { GrowlContext } from 'Context'

export default ({ modalController, contentTextId, confirmTextId, onConfirm, icon }) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const { displayError } = useContext(GrowlContext)

  const onConfirmClick = async () => {
    setIsLoading(true)
    try {
      await onConfirm(modalController.data)
      modalController.close()
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <FlexRow>
        <FlexColumn>
          <TextContainer>
            <p>
              {(modalController.data && <FormattedHTMLMessage id={contentTextId} values={modalController.data}/>) || (
                <FormattedHTMLMessage id={contentTextId}/>
              )}
            </p>
          </TextContainer>
        </FlexColumn>
      </FlexRow>
      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary icon={icon} label={intl.formatMessage({ id: confirmTextId })} onClick={onConfirmClick}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </>
  )
}
