import * as React from 'react'
import styled from 'styled-components'
import { SidebarScrollingContainer } from 'Components/layout'
import { Sidebar } from 'Components/readonly'
import { MessageForm } from './messageForm'

const MessageFormSidebar = styled(Sidebar)`
  width: 40%;

  @media screen and (max-width: 992px) {
    width: 90%;
  }
`

export const MessageSidebar = ({
                                 message,
                                 setSelectedMessage,
                                 messageSidebarVisible,
                                 setMessageSidebarVisible,
                                 onSuccess,
                                 ...props
                               }) => {
  return (
    <MessageFormSidebar
      className="message-form-sidebar"
      blockScroll={true}
      visible={messageSidebarVisible}
      position="right"
      onHide={(e) => {
        setMessageSidebarVisible(false)
        setSelectedMessage(null)
      }}>
      <SidebarScrollingContainer>
        {messageSidebarVisible && (
          <MessageForm
            message={message}
            setSelectedMessage={setSelectedMessage}
            onSuccess={onSuccess}
            setMessageSidebarVisible={setMessageSidebarVisible}
          />
        )}
      </SidebarScrollingContainer>
    </MessageFormSidebar>
  )
}
