import React from 'react'
import { Button } from '../input'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import NewPackageRow from './newPackageRow'
import EditProductAmountRow from './editProductAmountRow'
import styled from 'styled-components'
import { FieldError } from 'Components/layout'

export const emptyPackageRow = {
  is_bulk: false,
  unit_id: '',
  packaging_size: '',
  amount: '',
  amount_opened: ''
}

const Container = styled.div`
  position: relative;
`

const Packages = ({ id, value, onChange, product, validationError }) => {
  const intl = useIntl()

  const addPackage = () => {
    let v = [...value]
    v.push(emptyPackageRow)
    onChange({ target: { value: v } })
  }

  const updatePackage = (indexOfUpdatedPackage, event) => {
    let v = [...value]
    let updatedPackage = event.target.value
    v[indexOfUpdatedPackage] = updatedPackage
    onChange({ target: { value: v } })
  }

  const getValidationErrors = (index) => {
    if (_.isArray(validationError)) {
      return _.find(validationError, { index: index })
    }
  }

  const removePackage = (index) => {
    let v = [...value]
    v.splice(index, 1)
    onChange({ target: { value: v } })
  }

  return (
    <Container>
      {validationError && !_.isArray(validationError) && <FieldError/>}
      {value.map((p, index) =>
        p.is_suggestion ? (
          <EditProductAmountRow key={index} value={p} onChange={(e) => updatePackage(index, e)}/>
        ) : (
          <NewPackageRow id={id + '-' + index} key={index} value={p} onChange={(e) => updatePackage(index, e)}
                         validationError={getValidationErrors(index)} remove={() => removePackage(index)}
                         disableRemove={value.length === 1}/>
        )
      )}

      <div>
        <Button
          link
          primary
          label={intl.formatMessage({ id: 'chemicals.add_package' })}
          onClick={() => addPackage()}
          icon="pi pi-plus-circle"
        />
      </div>
    </Container>
  )
}

export default Packages
