export default {
  firstDayOfWeek: 1,
  dayNames: ["söndag", "måndag", "tisdag", "onsdag", "torsdag", "fredag", "lördag"],
  dayNamesShort: ["sön", "mån", "tis", "ons", "tor", "fre", "lör"],
  dayNamesMin: ["sö", "må", "ti", "on", "to", "fr", "lö"],
  monthNames: ["januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"],
  monthNamesShort: ["jan.", "feb.", "mars", "apr.", "maj", "juni", "juli", "aug.", "sep.", "okt.", "nov.", "dec."],
  today: "Idag",
  clear: "Rensa",
  weekHeader: "V"
}
