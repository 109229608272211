import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Field, Form } from 'Components/form'
import { Button, TextInput } from 'Components/input'
import { GrowlContext } from 'Context'
import { FormattedMessage, injectIntl } from 'react-intl'
import axios from 'axios'
import colors from 'Components/colors.scss'

const CancelLink = styled.div`
  text-align: center;
  font-size: 14px;

  a {
    color: ${colors.text};

    &:hover {
      color: ${colors.white};
    }
  }
`

const PasswordReset = ({ history, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)

  const loginAction = (formData) => {
    if (isLoading) {
      return
    }
    sendResetMail(formData)
  }

  const sendResetMail = async (formData) => {
    setIsLoading(true)
    try {
      const result = await axios.post('/rapi/password/email/' + intl.locale, formData)
      if (result.data.success) {
        displaySuccess(intl.formatMessage({ id: 'passwords.sent' }))
      } else {
        displayError(intl.formatMessage({ id: 'passwords.user' }))
      }
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <h1>
        <FormattedMessage id="auth.reset_password"/>
      </h1>
      <Form onSubmit={loginAction}>
        <Field
          id="email"
          placeholder={intl.formatMessage({ id: 'user.email' })}
          Component={TextInput}
          required={true}
          initialValue={history && history.location.state ? history.location.state.email : ''}
        />
        <br/>
        <Button submit label={intl.formatMessage({ id: 'passwords.send_reset_link' })} primary/>
        <CancelLink>
          <Link to="/" tabIndex="0">
            <FormattedMessage id="common.cancel"/>
          </Link>
        </CancelLink>
      </Form>
    </>
  )
}

export default injectIntl(PasswordReset)
