import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Axios from 'axios'
import colors from '../colors.scss'
import linkConfig from 'Config/app-external-links'

const Wrapper = styled.div`
  padding: 0 20px 5px 20px;
`

const Img = styled.div`
  height: 50px;
  width: 50px;
  background-size: auto 50px;
  border-radius: 50%;
  flex: 0 0 50px;
`

const Feed = styled.div`
  display: flex;
  padding: 20px 8px 25px;
  border-bottom: 1px solid ${colors.dividerDark};
  cursor: pointer;

  .text {
    flex: 0 1 350px;
    margin-left: 20px;
    color: ${colors.lightHover};
    font-size: 13px;

    h3 {
      padding-top: 0;
      font-size: 15px;
      font-weight: bold;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${colors.darkBackgroundFaded};
  }
`

const NewsFeed = () => {
  const [feed, setFeed] = useState([])
  const [feedLoading, setFeedLoading] = useState(true)

  useEffect(() => {
    const getFeed = async () => {
      try {
        const result = await Axios.get(linkConfig.newsFeed)
        setFeed(result.data)
        setFeedLoading(false)
      } catch (error) {
        setFeedLoading(false)
      }
    }
    setFeedLoading(true)
    getFeed()
  }, [setFeedLoading])

  return (
    <Wrapper>
      {!feedLoading &&
        feed.map((f, i) => (
          <Feed key={i} onClick={() => window.open(f.link, '_blank')}>
            {
              <Img
                style={{
                  backgroundImage:
                    'url(' +
                    (f._embedded['wp:featuredmedia']
                      ? f._embedded['wp:featuredmedia'][0].source_url
                      : linkConfig.defaultNewsFeaturedImage) +
                    ')'
                }}
              />
            }
            <div className="text">
              <h3 dangerouslySetInnerHTML={{ __html: f.title.rendered }}/>
              <p dangerouslySetInnerHTML={{ __html: f.excerpt.rendered }}/>
            </div>
          </Feed>
        ))}
    </Wrapper>
  )
}

export default NewsFeed
