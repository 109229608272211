import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { AuthContext } from 'Context'
import useGetData from 'Hooks/useGetData'
import useModalController from 'Hooks/useModalController'
import { FilterTableContainer, PageConstraint, PageHeader, TableContainer } from 'Components/layout'
import { Button, Column, DataTable, Modal, ToolPanel } from 'Components/readonly'
import { SearchInput } from 'Components/input'
import { SidebarWrapperMobile, SidebarWrapperPage } from 'Components/combined/sidebarWrapper'
import { DataView } from 'primereact/dataview'
import colors from 'Components/colors.scss'

import HygieneDetailsDialog from './components/hygieneDetailsDialog'
import HygieneThresholdFilter from './components/hygieneThresholdFilter'
import HygieneThresholdsFilter, { filterList } from './components/hygieneThresholdFilter'
import { shadeColor } from 'Utils/color'
import LocationSelectDialog from 'Components/combined/modalContentComponents/locationSelectDialog'

const darkerBgOdd = shadeColor(colors.darkerBackgroundColor, 20)

const CellDiv = styled.div`
  padding: 0.429em 20px 0.429em 0;
  white-space: nowrap;
  border-bottom: 1px solid #0f0f13;

  &:last-of-type {
    border-bottom: none;
  }
`

const NoBreakDiv = styled.div`
  white-space: nowrap;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ProductDiv = styled.div`
  display: list-item;
`

const MultiDataTable = styled(DataTable)`
  &&& {
    tr > th:not(:first-child).subTableColumn,
    tr > td:not(:first-child).subTableColumn {
      padding: 0;
    }

    .right-pad .p-column-title {
      padding-right: 20px;
    }
  }
`

const MobileList = styled.div`
  .p-dataview-content.p-dataview-content {
    padding: 0;
  }

  .p-grid {
    margin: 0;

    > div {
      padding: 4px 4px 4px 15px;
      background-color: ${darkerBgOdd};
    }

    > div:nth-child(even) {
      background-color: ${colors.darkerBackgroundColor};
    }

    h3 {
      margin-bottom: 8px;
    }

    .section {
      margin-bottom: 8px;

      label {
        font-weight: bold;
        display: block;
      }
    }

    table {
      font-size: 13px;
      border-collapse: collapse;

      th {
        text-align: left;
      }
    }

    ${NoBreakDiv} {
      max-width: 90%;
    }
  }
`

const HygieneThresholdsPage = () => {
  const intl = useIntl()
  const { organisation } = React.useContext(AuthContext)
  const [isLoading, hygieneThresholdsList] = useGetData('/rapi/hygienethresholds', [], [organisation])
  const [modalControllerReport] = useModalController({ title: intl.formatMessage({ id: 'hygiene-thresholds.create-report' }) })

  const [modalController] = useModalController()

  const [filteredList, setFilteredList] = useState([])
  const [inputFilter, setInputFilter] = useState('')
  const [locations, setLocations] = useState([])
  const filter = { locations, setLocations }

  const [first, setFirst] = useState(0)
  const handleInputFilter = (event) => {
    setInputFilter(event.target.value)
  }

  useEffect(() => {
    setFilteredList(filterList(hygieneThresholdsList, inputFilter, filter.locations))
    setFirst(0)
  }, [hygieneThresholdsList, filter.locations, inputFilter])

  const LocationsBody = (row, column) => {
    let locations = row[column.field].map((l, i) => (
      <NoBreakDiv key={l.id}>
        {l.name}
        {i !== row[column.field].length - 1 && ', '}
      </NoBreakDiv>
    ))
    if (locations.length > 4) {
      locations.splice(4)
      locations.push(<div key={5}>...</div>)
    }
    return locations
  }
  const CommaBody = (row, column) => {
    let products = row[column.field].map((p, i) => (
      <ProductDiv key={i}>
        {p}
        {i !== row[column.field].length - 1 && ', '}
      </ProductDiv>
    ))
    if (products.length > 4) {
      products.splice(4)
      products.push(<div key={5}>...</div>)
    }
    return products
  }
  const ExposureBody = (row, column) => {
    return (
      <>
        {row.hygiene_thresholds.map((h, i) => (
          <CellDiv
            key={i}>{h.exposure_path ? intl.formatMessage({ id: 'hygiene-thresholds.' + h.exposure_path }) : '-'}</CellDiv>
        ))}
      </>
    )
  }
  const TypeBody = (row, column) => {
    return (
      <>
        {row.hygiene_thresholds.map((h, i) => (
          <CellDiv key={i}>{intl.formatMessage({ id: 'hygiene-thresholds.' + h.type })}</CellDiv>
        ))}
      </>
    )
  }
  const PPMBody = (row, column) => {
    return (
      <>
        {row.hygiene_thresholds.map((h, i) => (
          <CellDiv key={i}>{h.ppm}</CellDiv>
        ))}
      </>
    )
  }
  const MGMBody = (row, column) => {
    return (
      <>
        {row.hygiene_thresholds.map((h, i) => (
          <CellDiv key={i}>{h.mg_m3}</CellDiv>
        ))}
      </>
    )
  }
  const OtherBody = (row, column) => {
    return (
      <>
        {row.hygiene_thresholds.map((h, i) => (
          <CellDiv key={i}>{h.other}</CellDiv>
        ))}
      </>
    )
  }

  const openHygieneDetails = (row) => {
    modalController.open({ data: row.data, title: row.data.name })
  }

  const columns = [
    <Column key="name" field="name" header={intl.formatMessage({ id: 'chemicals.ingredient_single' })}
            sortable={true}/>,
    <Column key="cas_id" field="cas_id" header={intl.formatMessage({ id: 'chemicals.form.ingredient.cas_id' })}
            sortable={true} className="subTableColumn"
            style={{ paddingRight: '6px', paddingLeft: '6px' }}/>,
    <Column key="locations" field="locations" body={LocationsBody}
            header={intl.formatMessage({ id: 'common.location' })}
            className="subTableColumn"
            style={{ paddingRight: '6px', paddingLeft: '6px' }}/>,
    <Column
      key="products"
      field="products"
      body={CommaBody}
      header={intl.formatMessage({ id: 'chemicals.products' })}
      style={{ paddingRight: '10px', paddingLeft: '10px' }}
    />,
    <Column
      key="exposure_path"
      field="exposure_path"
      body={ExposureBody}
      header={intl.formatMessage({ id: 'hygiene-thresholds.exposure_path' })}
      className="subTableColumn"
      style={{ paddingRight: '6px', paddingLeft: '6px' }}
    />,
    <Column
      key="type"
      field="type"
      body={TypeBody}
      header={intl.formatMessage({ id: 'hygiene-thresholds.type' })}
      className="subTableColumn"
      style={{ paddingRight: '6px', paddingLeft: '6px' }}
    />,
    <Column
      key="ppm"
      field="ppm"
      body={PPMBody}
      header={intl.formatMessage({ id: 'hygiene-thresholds.ppm' })}
      className="subTableColumn"
      style={{ paddingRight: '6px', paddingLeft: '6px' }}
    />,
    <Column
      key="mgm3"
      field="mgm3"
      body={MGMBody}
      header={intl.formatMessage({ id: 'hygiene-thresholds.mg_m3' })}
      className="subTableColumn"
      style={{ paddingRight: '6px', paddingLeft: '6px' }}
    />,
    <Column
      key="other"
      field="other"
      body={OtherBody}
      header={intl.formatMessage({ id: 'hygiene-thresholds.other-unit' })}
      className="subTableColumn"
      style={{ paddingRight: '6px', paddingLeft: '6px' }}
    />
  ]

  const itemTemplate = (ht, layout) => {
    if (ht) {
      return (
        <div>
          <div>
            <h3>{ht.name}</h3>
          </div>
          <div className="section">
            <label>{intl.formatMessage({ id: 'common.location' })}</label>
            <span>
              {ht.locations.map((l, i) => (
                <NoBreakDiv key={l.id}>{l.name}</NoBreakDiv>
              ))}
            </span>
          </div>
          <div className="section">
            <label>{intl.formatMessage({ id: 'chemicals.products' })}</label>
            <span>
              {ht.products.map((p, i) => (
                <NoBreakDiv key={i}>{p}</NoBreakDiv>
              ))}
            </span>
          </div>
          <table>
            <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'hygiene-thresholds.exposure_path' })}</th>
              <th>{intl.formatMessage({ id: 'hygiene-thresholds.type' })}</th>
              <th>{intl.formatMessage({ id: 'hygiene-thresholds.ppm' })}</th>
              <th>{intl.formatMessage({ id: 'hygiene-thresholds.mg_m3' })}</th>
              <th>{intl.formatMessage({ id: 'hygiene-thresholds.other-unit' })}</th>
            </tr>
            </thead>
            <tbody>
            {ht.hygiene_thresholds.map((e, i) => (
              <tr key={i}>
                <td>{intl.formatMessage({ id: 'hygiene-thresholds.' + e.exposure_path })}</td>
                <td>{intl.formatMessage({ id: 'hygiene-thresholds.' + e.type })}</td>
                <td>{e.ppm}</td>
                <td>{e.mg_m3}</td>
                <td>{e.other}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <PageConstraint>
      <Modal modalController={modalController} ContentComponent={HygieneDetailsDialog}/>
      <Modal
        modalController={modalControllerReport}
        ContentComponent={LocationSelectDialog}
        confirmButtonIcon="pi pi-file-pdf"
        confirmButtonText={intl.formatMessage({ id: 'hygiene-thresholds.create-report' })}
        requestUrl={'/rapi/hygienethresholds/pdf'}
      />
      <PageHeader>
        <h1>
          <FormattedMessage id="hygiene-thresholds.title"/>
        </h1>
        <ToolPanel>
          <SearchInput value={inputFilter} onChange={handleInputFilter}/>
          <SidebarWrapperMobile icon="pi pi-filter" buttonText={intl.formatMessage({ id: 'common.filter' })}>
            <HygieneThresholdsFilter filter={filter} setFirst={setFirst}/>
          </SidebarWrapperMobile>
          <Button
            primary
            icon="pi pi-file-pdf"
            label={intl.formatMessage({ id: 'hygiene-thresholds.create-report' })}
            onClick={() => modalControllerReport.open()}
          />
        </ToolPanel>
      </PageHeader>
      <FilterTableContainer>
        <TableContainer className="md-show fix-ie-table-width">
          <MultiDataTable
            className="flextable clickable-rows"
            loading={isLoading}
            value={filteredList}
            paginator={true}
            rows={10}
            rowGroupMode="rowspan"
            sortField="name"
            sortOrder={1}
            first={first}
            onPage={(e) => setFirst(e.first)}
            onRowClick={openHygieneDetails}>
            {columns}
          </MultiDataTable>
        </TableContainer>
        <MobileList className="md-hide">
          <DataView
            value={filteredList}
            layout="list"
            itemTemplate={itemTemplate}
            paginator={true}
            first={first}
            rows={10}
            onPage={(e) => setFirst(e.first)}></DataView>
        </MobileList>
        <SidebarWrapperPage>
          <HygieneThresholdFilter filter={filter} setFirst={setFirst}/>
        </SidebarWrapperPage>
      </FilterTableContainer>
    </PageConstraint>
  )
}

export default HygieneThresholdsPage
