import React, { useContext, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { matchPath } from 'react-router'
import { useIntl } from 'react-intl'

import { AuthContext, GrowlContext } from 'Context'
import ErrorBoundaryRoute from './errorBoundaryRoute'

const AuthorizedRoute = ({
                           location,
                           history,
                           path,
                           authorize,
                           checkMarketAccess,
                           isRedirectOnOrgChange,
                           isRedirectOnMarketChange,
                           ...props
                         }) => {
  const { organisation, user, activeMarket, changeActiveMarketAction } = useContext(AuthContext)
  const [renderedOrganisation] = useState(organisation)
  const [renderedActiveMarket] = useState(activeMarket)
  const { displayError } = React.useContext(GrowlContext)
  const intl = useIntl()

  const isAuthorized = () => {
    if (isRedirectOnOrgChange && organisation.id !== renderedOrganisation.id) return false
    if (checkMarketAccess && !user.hasMarketAccess) {
      if (user.user.userMarkets && user.user.active_market) {
        if (!user.user.userMarkets.includes(user.user.active_market)) {
          changeActiveMarketAction(user.user.userMarkets[0])
        }
      } else {
        return false
      }
    }

    let authorized = true

    const parts = authorize.split('&&')

    parts.forEach((part) => {
      const check = part.split('.').map((s) => s.trim())

      switch (check[0]) {
        case 'organisation':
          if (!organisation[check[1]]) {
            authorized = false
          }
          if (check[1] === 'has_sds' && user.user.roleValues.sds === 'not_authorized') {
            authorized = false
          }
          if (check[1] === 'has_chemical_list' && user.user.roleValues.chemical_list === 'not_authorized') {
            authorized = false
          }
          if(check[1] === 'qr_enabled' && !organisation.qr_enabled){
            authorized = false
          }
          break
        case 'user':
          if (!user[check[1]]) {
            authorized = false
          }
          break
        case 'capabilities':
          if (!user.isSuperAdmin && !user.capabilities.includes(check[1].toUpperCase())) {
            authorized = false
          }
          break
        default:
          console.error('Unknown isAuthorized check', authorize)
          authorized = false
      }
    })

    return authorized
  }

  const changedMarket = () => {
    if (isRedirectOnMarketChange && activeMarket !== renderedActiveMarket) return true
    return false
  }

  const pathMatches =
    matchPath(location.pathname, {
      path: path
    }) !== null

  if (user.isSuperAdmin && organisation.id !== renderedOrganisation.id) return <Redirect to="/start"/>
  if (pathMatches && isAuthorized() && changedMarket()) return <Redirect to="/chemicalList"/>

  if (pathMatches && !isAuthorized()) {
    if (checkMarketAccess && user.user.userMarkets && !user.user.userMarkets.length) {
      displayError(intl.formatMessage({ id: 'common.no_market' }))
    }
    return <Redirect to="/start"/>
  }

  return <ErrorBoundaryRoute path={path} {...props} />
}

export default withRouter(AuthorizedRoute)
