import React, { useContext } from 'react'
import { PageConstraint, PageHeader } from 'Components/layout'

import { TabPanel } from 'primereact/tabview'
import { TabView } from 'Components/readonly'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

import { SettingsMyProfilePanel } from './components/settingsMyProfilePanel'
import { SettingsUsersPanel } from './components/settingsUsersPanel'
import SettingsTagsPanel from './components/settingsTagsPanel'
import SettingsArticleNumbersAndSynonymsPanel from './components/settingsArticleNumbersAndSynonymsPanel'
import SettingsExportPanel from './components/settingsExportPanel'
import SettingsImportPanel from './components/settingsImportPanel'
import { AuthContext } from 'Context'
import { useCapability } from 'Components/capabilities'

export const SettingsPage = () => {
  const intl = useIntl()
  const hasAddUserCapability = useCapability('ADD_USER')
  const canManageTags = useCapability('CHEMICAL_MANAGE_TAGS')
  const { organisation, user } = useContext(AuthContext)
  const history = useHistory()
  const params = useParams()

  const tabs = [
    <TabPanel key="my_profile" header={intl.formatMessage({ id: 'user.my_profile' })}>
      <SettingsMyProfilePanel/>
    </TabPanel>
  ]
  if (hasAddUserCapability) {
    tabs.push(
      <TabPanel key="user" header={intl.formatMessage({ id: 'common.user' })}>
        <SettingsUsersPanel/>
      </TabPanel>
    )
  }

  if (organisation.has_chemical_list && canManageTags) {
    tabs.push(
      <TabPanel key="manage_tags" header={intl.formatMessage({ id: 'chemicals.tags.manage_tags' })}>
        <SettingsTagsPanel/>
      </TabPanel>
    )
    tabs.push(
      <TabPanel
        key="manage_article_numbers_and_synonyms"
        header={intl.formatMessage({ id: 'chemicals.manage_article_numbers_and_synonyms' })}>
        <SettingsArticleNumbersAndSynonymsPanel/>
      </TabPanel>
    )
    tabs.push(
      <TabPanel key="export" header={intl.formatMessage({ id: 'common.export' })}>
        <SettingsExportPanel/>
      </TabPanel>
    )
    if (user.isSuperAdmin) {
      tabs.push(
        <TabPanel key="import" header={intl.formatMessage({ id: 'chemicals.import.title' })}>
          <SettingsImportPanel/>
        </TabPanel>
      )
    }
  }

  let activeIndex = tabs.findIndex((tab) => tab.key === params.tab)
  if (activeIndex === -1) {
    activeIndex = 0
  }

  const selectTab = (index) => {
    history.push(tabs[index].key)
  }

  return (
    <>
      <PageConstraint>
        <PageHeader>
          <h1>
            <FormattedMessage id="common.settings"/>
          </h1>
        </PageHeader>
        <TabView activeIndex={activeIndex} onTabChange={(e) => selectTab(e.index)}>
          {tabs}
        </TabView>
      </PageConstraint>
    </>
  )
}
