import React, { useContext, useState } from 'react'
import axios from 'axios'

import { GrowlContext } from 'Context'
import { Button, TextInput } from 'Components/input'
import { FlexColumn, Section } from 'Components/layout'
import { ModalActionsFooter } from 'Components/readonly'

import { injectIntl } from 'react-intl'
import { FieldValidationMessages } from 'Components/form'

const TagDialog = ({ modalController, onUpdate, onCreate, tagList, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const [errors, setErrors] = useState([])

  const tag = modalController.data
  const [tagName, setTagName] = useState((tag && tag.name) || '')

  const isNewTag = tag === null

  const onKeyPress = (event) => {
    var code = event.keyCode || event.which
    if (code === 13) {
      //13 is the enter keycode
      onSubmit()
    }
  }

  const onSubmit = async () => {
    if (!isTagNameFilled()) {
      return false
    }

    if (!isTagNameUnique()) {
      setErrors([intl.formatMessage({ id: 'chemicals.tags.duplicate_detected' })])
      return false
    }

    setIsLoading(true)

    let result = null
    try {
      if (isNewTag) {
        result = await axios.post('/rapi/tags', { name: tagName })
        onCreate(result.data.tag)
      } else {
        result = await axios.put('/rapi/tags/' + tag.id, { name: tagName })
        onUpdate({ ...tag, name: tagName })
      }
    } catch (error) {
      displayError(error)
      setIsLoading(false)
      return
    }

    displaySuccess(result.data.message)
    modalController.close()
    setIsLoading(false)
  }

  const isTagNameFilled = () => {
    return tagName.trim() !== ''
  }

  const isTagNameUnique = () => {
    return !tagList.some((tag) => tag.name.toLowerCase() === tagName.toLowerCase())
  }

  const isValidTagName = () => {
    if (!isTagNameFilled()) {
      return false
    }

    return isTagNameUnique()
  }

  return (
    <div>
      <Section>
        <p>
          <label htmlFor="tagName">{intl.formatMessage({ id: 'common.name' })}</label>
        </p>
        <div>
          <TextInput
            className="modal-onshow-focus"
            value={tagName}
            onChange={(e) => {
              setTagName(e.target.value)
              setErrors([])
            }}
            onKeyPress={onKeyPress}
          />
        </div>
        <FieldValidationMessages messages={errors}/>
      </Section>
      <FlexColumn>
        <ModalActionsFooter>
          <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
          <Button
            primary
            icon="pi pi-save"
            label={intl.formatMessage({ id: 'common.save' })}
            disabled={!isValidTagName()}
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </ModalActionsFooter>
      </FlexColumn>
    </div>
  )
}

export default injectIntl(TagDialog)
