import * as React from 'react'
import styled from 'styled-components'
import { TabPanel } from 'primereact/tabview'

import { TabView } from '../readonly'
import TwoColumn from './twoColumn'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;

  ${TabView} {
    &,
    .p-tabview-panels,
    .p-tabview-panel {
      display: flex;
      flex-direction: column;
      flex: 1 1 687px;
    }

    .p-tabview-nav.p-tabview-nav li a {
      background-color: #706f7a;
      border-top-color: #706f7a;
    }
  }
`

const TabbedTwoColumn = ({ tabOptions, selectedTabIndex, onTabChange, ...props }) => (
  <Wrapper>
    <TabView activeIndex={selectedTabIndex} onTabChange={onTabChange}>
      {tabOptions.map((option, index) => (
        <TabPanel key={index} header={option.text} rightIcon={option.icon}>
          <TwoColumn {...props} leftFlexBasis="687px" rightFlexBasis="687px"/>
        </TabPanel>
      ))}
    </TabView>
  </Wrapper>
)

export default TabbedTwoColumn
