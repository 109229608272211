import React from 'react'
import _ from 'lodash'

import { FormContext } from 'Components/form/formContext'

export const FieldToggler = ({ children, show, hide }) => {

  const { getFieldValue } = React.useContext(FormContext)

  if (_.isString(show)) {
    show = getFieldValue(show)
  }

  if (_.isString(hide)) {
    show = !getFieldValue(hide)
  }

  return (show && <>{children}</>) || null
}

FieldToggler.defaultProps = {
  show: true
}
