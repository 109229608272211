import React, { useContext, useState } from 'react'

import axios from 'axios'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'

import { GrowlContext } from 'Context'
import { TextareaInput } from 'Components/input'
import { Button } from 'Components/readonly'

const HorizontalButtons = styled.div`
  display: flex;
  padding-top: 10px;

  button {
    flex: 1 1 1px;
  }
`

const CommentInput = ({ intl, sdsId, onCommentSubmitted }) => {
  const { displaySuccess, displayError } = useContext(GrowlContext)
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false)
  const [commentText, setCommentText] = useState('')

  const onSubmitComment = async () => {
    setIsLoading(true)
    try {
      const result = await axios.post(`/rapi/sds/${sdsId}/comment/customer`, { comment: commentText })
      onCommentSubmitted(result.data.customerComment)
      setCommentText('')
      displaySuccess(intl.formatMessage({ id: 'sds.comment_sent_to_kemrisk' }))
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <TextareaInput
        placeholder={intl.formatMessage({ id: 'common.write-comment' })}
        rows={5}
        cols={30}
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        autoResize={true}
      />

      <HorizontalButtons>
        <Button primary label={intl.formatMessage({ id: 'common.send' })} onClick={onSubmitComment}
                isLoading={isLoading}/>
      </HorizontalButtons>
    </>
  )
}

export default injectIntl(CommentInput)
