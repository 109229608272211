import React, { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import enDateLocalePrime from 'Config/date-locale-en.js'
import svDateLocalePrime from 'Config/date-locale-sv.js'
import dkDateLocalePrime from 'Config/date-locale-da.js'
import enDateLocaleDateFns from 'date-fns/locale/en'
import svDateLocaleDateFns from 'date-fns/locale/sv'
import dkDateLocaleDateFns from 'date-fns/locale/da'

import { GrowlContext } from './'

export const Context = createContext({
  user: undefined,
  isLoading: undefined,
  organisation: undefined,
  setUser: undefined,
  changeActiveMarketAction: undefined,
  changeOrganistionAction: undefined,
  setLanguageAction: undefined,
  setPolicyConsentAction: undefined,
  logoutAction: undefined,
  activeMarket: undefined
})

export const Provider = (props) => {
  const { children } = props
  const [isLoading, setIsLoading] = useState(true)
  const [userAndOrganisation, setUserAndOrganisation] = useState({
    user: { isAuthenticated: false },
    organisation: null
  })
  const { displayError } = useContext(GrowlContext)
  const [activeMarket, setActiveMarket] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const result = await axios('/rapi/user')
        loginUser(result.data)
      } catch (error) {
      }
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const loginUser = (responseData) => {
    const { currentOrganisation, ...userData } = responseData
    const newUser = { ...userData, isAuthenticated: true }
    const newOrganisation = currentOrganisation ? currentOrganisation : responseData.organisations[0]
    setUserAndOrganisation({ user: newUser, organisation: newOrganisation })
    setActiveMarket(newUser.user.active_market)
  }

  const setUnauthenticated = () => {
    setUserAndOrganisation({
      user: { isAuthenticated: false },
      organisation: null
    })
    setActiveMarket(null)
  }

  const logoutAction = async () => {
    try {
      await axios.post('/rapi/logout')
      setUnauthenticated()
    } catch (error) {
    }
  }

  const changeOrganistionAction = async (id) => {
    try {
      const result = await axios.post('/rapi/admin/act-as', { organisation: id })
      const newUser = { ...result.data, isAuthenticated: true }
      setUserAndOrganisation({ user: newUser, organisation: result.data.currentOrganisation })
      setActiveMarket(newUser.user.active_market)
    } catch (error) {
      displayError(error)
    }
  }

  const setOrganisationProps = (props) => {
    setUserAndOrganisation({
      user: userAndOrganisation.user,
      organisation: { ...userAndOrganisation.organisation, ...props }
    })
    setActiveMarket(userAndOrganisation.user.user.active_market)
  }

  const changeActiveMarketAction = async (countryId) => {
    try {
      const result = await axios.post('/rapi/setActiveMarket', {
        countryId
      })
      loginUser(result.data)
    } catch (error) {
      displayError(error)
    }
  }

  const setLanguageAction = async (langValue) => {
    try {
      const result = await axios.post('/rapi/locale', {
        locale: langValue
      })
      loginUser(result.data)
    } catch (error) {
      displayError(error)
    }
  }

  const setPolicyConsentAction = async (consent, version) => {
    try {
      const result = await axios.post('/rapi/consent', {
        consent,
        version
      })
      loginUser(result.data)
    } catch (error) {
      displayError(error)
    }
  }

  const updateUserProfile = (data) => {
    const newProfileData = {
      name: data.name,
      phone: data.phone,
      langName: data.langName,
      language: data.language,
      avatar: data.avatar
    }
    setUserAndOrganisation({
      user: { ...userAndOrganisation.user, user: { ...userAndOrganisation.user.user, ...newProfileData } },
      organisation: userAndOrganisation.organisation
    })
  }

  const langDictionary = {
    sv: 'SWE',
    en: 'ENG',
    da: 'DEN',
    '': 'ENG',
    null: 'ENG',
    undefined: 'ENG'
  }

  const localesDictionary = {
    sv: svDateLocalePrime,
    en: enDateLocalePrime,
    da: dkDateLocalePrime,
    '': enDateLocalePrime,
    null: enDateLocalePrime,
    undefined: enDateLocalePrime
  }

  const defaultLangDictionary = {
    SV: 'SWE',
    SE: 'SWE',
    NO: 'NOR',
    DK: 'DAN',
    null: 'SWE'
  }

  const localesDateDictionary = {
    sv: enDateLocaleDateFns,
    en: svDateLocaleDateFns,
    da: dkDateLocaleDateFns,
    '': enDateLocaleDateFns,
    null: enDateLocaleDateFns,
    undefined: enDateLocaleDateFns
  }

  const userLanguage = userAndOrganisation.user && userAndOrganisation.user.user && userAndOrganisation.user.user.language
  const userLanguage3 = langDictionary[userLanguage]
  const defaultMarketLanguage = defaultLangDictionary[activeMarket]
  const usersContext = {
    user: userAndOrganisation.user,
    loginUser,
    isLoading,
    organisation: userAndOrganisation.organisation,
    changeActiveMarketAction,
    changeOrganistionAction,
    setOrganisationProps,
    setLanguageAction,
    setPolicyConsentAction,
    logoutAction,
    setUnauthenticated,
    updateUserProfile,
    dateLocalePrime: localesDictionary[userLanguage],
    dateLocaleDateFns: localesDateDictionary[userLanguage],
    userLanguage: userLanguage,
    userLanguage3: userLanguage3,
    activeMarket,
    defaultMarketLanguage
  }

  return <Context.Provider value={usersContext}>{children}</Context.Provider>
}

export const { Consumer } = Context
