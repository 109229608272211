import React from 'react'
import ReactDOM from 'react-dom'
import { Sidebar as PrimeSidebar } from 'primereact/sidebar'
import styled from 'styled-components'

import { Button } from './'
import colors from '../colors.scss'
import { injectIntl } from 'react-intl'

const IconsContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  padding: 10px 10px 0 10px;

  && ${Button} {
    margin-bottom: 10px;
    margin-left: 10px;
  }
`

const Fill = styled.div`
  flex: 1 1 auto;
`

const SidebarWrapper = styled(PrimeSidebar)`
  &&& {
    background-color: ${colors.overlayBackgroundColor};
    display: flex;
    flex-flow: column;
    -webkit-overflow-scrolling: touch;
  }
`

const SidebarComponent = ({ iconsTemplate, showCloseIcon = true, intl, ...props }) => {
  return ReactDOM.createPortal(
    <SidebarWrapper
      showCloseIcon={false}
      {...props}
      iconsTemplate={() => (
        <>
          <IconsContainer>
            {showCloseIcon && (
              <>
                <Button
                  className="sidebar-close-button"
                  link
                  label={intl.formatMessage({ id: 'common.close' })}
                  icon="pi pi-times"
                  onClick={props.onHide}
                />
                <Fill/>
              </>
            )}
            {iconsTemplate && iconsTemplate()}
          </IconsContainer>
        </>
      )}
    />,
    document.body
  )
}

export const Sidebar = injectIntl(SidebarComponent)

export const ResponsiveSidebar = styled(Sidebar)`
  width: 90%;
  max-width: 1800px;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`
