import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import colors from 'Components/colors.scss'

import { FormContext } from './formContext'
import { FieldValidationMessages } from './fieldValidationMessages'
import { Checkbox, Switch } from '../input'
import { FieldError } from '../layout'

const FieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  > label {
    margin-bottom: 5px;
    color: #fefefe;
  }

  > h3 {
    padding-top: 0;
  }
  .tooLong {
    color: ${colors.riskHigh}; 
  }
`

export const Field = styled(
  injectIntl(
    ({
      intl,
      className,
      id,
      propId,
      label,
      showLabel,
      headingLevel,
      Component,
      required,
      validation,
      toggle,
      toggleComponentLabel,
      toggleDisabled,
      isToggleInverted,
      onChange,
      hasErrorHandling,
      useCharacterCounter,
      counterPositionRightBottom,
      ...props
    }) => {
      const { formData, setFieldValue, getValidationError } = useContext(FormContext)
      const [characterCount, setCharacterCount] = useState(value?.length || 0)
      const focus = props.focus

      // Some field shares the same backend property, in this case propId should be set. Id should be unique.
      propId = propId || id
      const isChecked = isToggleInverted ? _.get(formData, propId) === null : _.get(formData, propId) !== null

      useEffect(() => {
        if (focus) {
          document.getElementById(id).focus()
        }
      }, [id, focus])

      const cleanId = React.useMemo(() => {
        return id.replace(/\./g, '-')
      }, [id])

      const onFieldChange = (event) => {
        if (_.has(event.target, 'checked') && !event.target.value) {
          setFieldValue(propId, !!event.target.checked)
        } else {
          setFieldValue(propId, event.target.value)
        }

        if (onChange) {
          onChange(event)
        }
      }

      const onFieldToggle = (event) => {
        // Checkbox uses ".checked", while Switch uses ".value".
        const isChecked = event.checked || event.value

        const isExpanded = isToggleInverted ? !isChecked : isChecked
        if (isExpanded) {
          setFieldValue(propId, props.initialValue ? props.initialValue : '')
        } else {
          setFieldValue(propId, null)
        }
      }

      const validationError = getValidationError(propId)

      const isShow = (() => {
        // If the field visibility is NOT to be togglable:
        if (!toggle) {
          // Always show the field.
          return true
        }

        return isToggleInverted ? !isChecked : isChecked
      })()

      // If headinglevel is set we replace the label with a hX-tag
      const Heading = `h${headingLevel}`
      const headingText = label
      if (headingLevel > 0) {
        label = false
      }

      const value = _.get(formData, propId)

      const isToggleDisabled = toggle && toggleDisabled && toggleDisabled(value)

      useEffect(() => {
        if(useCharacterCounter) {
          setCharacterCount(value?.length || 0)
        }
      }, [value])

      return (
        <FieldContainer className={className}>
          {validationError && !hasErrorHandling && <FieldError />}

          {/* Label */}
          {!headingLevel && (!toggle || toggle === 'switch') && showLabel !== false && (
            <label htmlFor={cleanId}>
              {headingText}
              {counterPositionRightBottom ? null : useCharacterCounter ? (
                <span>
                  {' '}
                  (<span className={`${characterCount > 255 ? 'tooLong' : ''}`}> {characterCount} </span> / 255 )
                </span>
              ) : null}
            </label>
          )}

          {/* Optional heading instead of label */}
          {headingLevel > 0 && <Heading>{headingText}</Heading>}

          <div>
            {/* Switch toggle field */}
            {toggle && (
              <div>
                <div className={(isShow && 'marginBottom') || ''}>
                  {toggle === 'switch' ? (
                    <Switch
                      disabled={isToggleDisabled}
                      value={isChecked}
                      onChange={onFieldToggle}
                      onLabel={intl.formatMessage({ id: 'common.yes' })}
                      offLabel={intl.formatMessage({ id: 'common.no' })}
                    />
                  ) : (
                    <Checkbox disabled={isToggleDisabled} label={label} value={isChecked} onChange={onFieldToggle} />
                  )}
                </div>
                {isShow && toggleComponentLabel && (
                  <div style={{ paddingBottom: '5px' }}>
                    <label htmlFor={cleanId}>{toggleComponentLabel}</label>
                  </div>
                )}
              </div>
            )}

            {/* Main component */}
            {isShow && (
              <Component
                id={cleanId}
                name={cleanId}
                value={value}
                className={validationError && !hasErrorHandling ? 'ui-state-error' : ''}
                validationError={validationError}
                onChange={onFieldChange}
                label={label}
                {...props}
              />
            )}

            {counterPositionRightBottom && useCharacterCounter ? (
                            <div style={{textAlign: 'right'}}><span>
                              {' '}
                              (<span className={`${characterCount > 255 ? 'tooLong' : ''}`}> {characterCount} </span> / 255 )
                            </span></div>
                          ) : null}


            {!hasErrorHandling && <FieldValidationMessages messages={validationError} />}
          </div>
        </FieldContainer>
      )
    }
  )
)``
// name is used in form.js createInitialFormData
Field.name = 'Field'
