import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ProgressSpinner } from 'primereact/progressspinner'
import { CSSTransition } from 'react-transition-group'

const DELAY_TIMEOUT = 500

const SpinnerWrapper = styled.div`
  position: relative;
  min-height: 105px;
`

const SpinnerWrapper2 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Hidden = styled.div`
  visibility: hidden;
`

export const LoadingSpinner = ({ isLoading, animate, children }) => {
  const [isLoadingDelayed, setIsLoadingDelayed] = useState(false)

  if (isLoading === undefined) {
    isLoading = true
  }

  useEffect(() => {
    setIsLoadingDelayed(false)
    const timer = setTimeout(() => {
      setIsLoadingDelayed(isLoading)
    }, DELAY_TIMEOUT)
    return () => clearTimeout(timer)
  }, [isLoading])

  return isLoading || isLoadingDelayed ? (
    <SpinnerWrapper>
      <Hidden>{children}</Hidden>
      {isLoadingDelayed && (
        <SpinnerWrapper2>
          <ProgressSpinner/>
        </SpinnerWrapper2>
      )}
    </SpinnerWrapper>
  ) : (
    <>
      {animate && (
        <CSSTransition in={!isLoading} timeout={200} classNames="fade" unmountOnExit={true} appear={true}>
          <div>{children}</div>
        </CSSTransition>
      )}
      {!animate && <div>{children}</div>}
    </>
  )
}
