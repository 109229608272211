import React from 'react'
import { TabPanel } from 'primereact/tabview'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

import { PageConstraint, PageHeader } from 'Components/layout'
import { TabView } from 'Components/readonly'
import { InventoryLockMessage } from './components/inventoryLockMessage.js'
import ActiveInventoryListTab from './components/activeInventoryListTab.js'
import DoneInventoryListTab from './components/doneInventoryListTab.js'

export const InventoryPage = () => {
  const intl = useIntl()
  const history = useHistory()
  const params = useParams()

  const activeIndex = params.mode === 'active' ? 0 : 1

  const selectTab = (index) => {
    if (index === 0) {
      history.push('/inventory/active')
    } else {
      history.push('/inventory/done')
    }
  }

  return (
    <>
      <InventoryLockMessage/>
      <PageConstraint>
        <PageHeader>
          <h1>
            <FormattedMessage id="inventory.title"/>
          </h1>
        </PageHeader>

        <TabView activeIndex={activeIndex} onTabChange={(e) => selectTab(e.index)}>
          <TabPanel header={intl.formatMessage({ id: 'inventory.active' })}>
            <ActiveInventoryListTab/>
          </TabPanel>
          <TabPanel header={intl.formatMessage({ id: 'inventory.completed' })}>
            <DoneInventoryListTab/>
          </TabPanel>
        </TabView>
      </PageConstraint>
    </>
  )
}
