export default {
  firstDayOfWeek: 1,
  dayNames: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
  dayNamesShort: ["søn", "man", "tis", "ons", "tor", "fre", "lør"],
  dayNamesMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
  monthNames: ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"],
  monthNamesShort: ["jan", "feb", "mar", "apr", "maj", "juni", "juli", "aug", "sep", "okt", "nov", "dec"],
  today: "I dag",
  clear: "Rense",
  weekHeader: "V"
}
