import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { PageConstraint, PageHeader, TextContainer } from 'Components/layout'
import useCachedGetData from 'Hooks/useCachedGetData'
import { Accordion, AccordionTab, LoadingSpinner, RichTextDisplay, ToolPanel } from 'Components/readonly'
import { SearchInput } from 'Components/input'
import { globalSearch } from 'Utils/search'
import { AuthContext } from 'Context'

export const FaqPage = () => {
  const { organisation } = useContext(AuthContext)
  const [isLoading, faqList] = useCachedGetData('/rapi/faqs', [], [organisation])

  const [filteredList, setFilteredList] = useState([])
  const [inputFilter, setInputFilter] = useState('')

  useEffect(() => {
    setFilteredList(globalSearch(faqList, inputFilter, ['user_language_question', 'user_language_answer']))
  }, [faqList, inputFilter])

  const handleInputFilter = (event) => {
    setInputFilter(event.target.value)
  }

  return (
    <PageConstraint>
      <PageHeader>
        <h1>
          <FormattedMessage id="faq.title"/>
        </h1>
        <LoadingSpinner isLoading={isLoading}>
          <ToolPanel>
            <SearchInput value={inputFilter} onChange={handleInputFilter}/>
            <div style={{ paddingBottom: '16px' }}/>
          </ToolPanel>

          <Accordion>
            {filteredList.map((faq) => (
              <AccordionTab header={faq.user_language_question}>
                <TextContainer key={faq.id}>
                  <RichTextDisplay value={faq.user_language_answer}/>
                </TextContainer>
              </AccordionTab>
            ))}
          </Accordion>
        </LoadingSpinner>
      </PageHeader>
    </PageConstraint>
  )
}
