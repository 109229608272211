import styled from 'styled-components'
import colors from '../colors.scss'

const ServicePanel = styled.div`
  background-color: ${colors.darkBackground};
  padding: 40px;
  position: relative;
  padding: 180px 60px 60px;
  transition: box-shadow 0.3s;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.349019607843137);

  &:hover {
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.349019607843137);
  }

  h1,
  h2 {
    text-align: center;
  }

  > .panel-badge {
    position: absolute;
    top: -20px;
    left: calc(50% - 78px);
  }

  img {
    height: 170px;
    width: 157px;
    filter: drop-shadow(2px 2px 4px #00000050);
  }
`
export default ServicePanel
