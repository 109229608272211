import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import colors from '../colors.scss'
import { TextInput } from './textInput'

const VolumeChangerContainer = styled.div`
  display: flex;
  align-items: center;

  .pi {
    color: ${colors.primary};
    font-size: 1.75em;
    cursor: pointer;

    &:hover {
      filter: brightness(110%);
    }
  }

  .amount {
    color: ${colors.text};
    font-size: 1.5em;
    margin: 0 10px;
  }

  .unit {
    color: ${colors.text};
    font-size: 1.15em;
    margin: 0 10px;
    font-weight: bold;
  }

  ${TextInput}${TextInput}${TextInput} {
    margin: 0 7px;
  }
`

export const VolumeChanger = ({ id, value, readOnly, onChange, unit }) => {
  const [amount, setAmount] = useState(0)
  const [edit, setEdit] = useState(false)
  const textRef = useRef()

  useEffect(() => {
    setAmount(parseInt(value))
  }, [value])

  const reduce = () => {
    if (amount !== 0) {
      setAmount(amount - 1)
      onChange(amount - 1)
    }
  }

  const add = () => {
    setAmount(amount + 1)
    onChange(amount + 1)
  }

  const editField = () => {
    if (!readOnly) {
      setEdit(true)
    }
  }

  const inputBlur = () => {
    setEdit(false)
  }

  useEffect(() => {
    if (edit) {
      textRef.current.getCurrent().element.focus()
    }
  })

  return (
    <VolumeChangerContainer id={id}>
      {!readOnly && <span className="pi pi-minus-circle" onClick={reduce}/>}
      {edit ? (
        <TextInput ref={textRef} value={amount} onBlur={inputBlur} onChange={(e) => setAmount(e.target.value)}
                   keyfilter="pint" type="number"/>
      ) : (
        <span className="amount" onClick={editField}>
          {amount}
        </span>
      )}
      {!readOnly && <span className="pi pi-plus-circle" onClick={add}/>}
      <span className="unit">{unit}</span>
    </VolumeChangerContainer>
  )
}
