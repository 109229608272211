import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { RichTextDisplay } from 'Components/readonly'
import { Section, TextContainer } from 'Components/layout'
import { TextDisplay } from 'Components/readonly/textDisplay'

export default ({ product }) => {
  const intl = useIntl()

  // Don't show info for preliminary products
  if (product.status === 'PRELIMINARY') {
    return (
      <Section>
        <FormattedMessage id="chemicals.no-preliminary-info"/>
      </Section>
    )
  }

  const packingGroupOptions = {
    not_applicable: intl.formatMessage({ id: 'chemicals.form.packing_group_options.not_applicable' }),
    i: intl.formatMessage({ id: 'chemicals.form.packing_group_options.i' }),
    ii: intl.formatMessage({ id: 'chemicals.form.packing_group_options.ii' }),
    iii: intl.formatMessage({ id: 'chemicals.form.packing_group_options.iii' })
  }

  return (
    <TextContainer>
      <h3>
        <FormattedMessage id="chemicals.form.waste_information"/>
      </h3>
      <p id="waste_information">
        <RichTextDisplay value={product.waste_information}/>
      </p>

      <h3>
        <FormattedMessage id="chemicals.transport_information2"/>
      </h3>
      <p id="transport_information">
        <RichTextDisplay value={product.transport_information}/>
      </p>

      <h3>
        <FormattedMessage id="chemicals.view.un_number"/>
      </h3>
      <p id="un_number">
        <TextDisplay value={product.un_number}/>
      </p>

      <h3>
        <FormattedMessage id="chemicals.view.packing_group"/>
      </h3>
      <p id="packing_group">{packingGroupOptions[product.packing_group]}</p>
    </TextContainer>
  )
}
