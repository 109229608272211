import React, { useRef } from 'react'
import styled from 'styled-components'

import RCTooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import positions from 'positions'

const placementsMap = {
  tc: 'top center',
  bc: 'bottom center',
  cl: 'center right',
  cr: 'center left',
};

const placeArrow = (tooltipEl, align, el) => {
  const arrowEl = tooltipEl.querySelector('.rc-tooltip-arrow');
  const targetEl = el.trigger.getRootDomNode();

  const position = positions(arrowEl, placementsMap[align.points[0]], targetEl, placementsMap[align.points[1]]);

  if (align.points[0] === 'tc' || align.points[0] === 'bc') {
    arrowEl.style.top = '';
    arrowEl.style.left = `${position.left + 5}px`; // 5 to counteract .rc-tooltip-placement-bottom .rc-tooltip-arrow { margin-left: -5px }
  } else {
    arrowEl.style.top = `${position.top}px`;
    arrowEl.style.left = '';
  }
}

export const Tooltip = styled(({ children, content, ...props }) => {
  const el = useRef()
  return <RCTooltip
    {...props}
    ref={el}
    placement="bottom"
    mouseEnterDelay={0}
    mouseLeaveDelay={0}
    destroyTooltipOnHide={false}
    trigger="hover"
    onPopupAlign={(node, align) => {
      placeArrow(node, align, el.current)
    }}
    onVisibleChange={() => {
    }}
    overlay={content}
    align={{
      offset: [0, 0]
    }}
    transitionName="fade">
    {children}
  </RCTooltip>
})``
