import colors from 'Components/colors.scss'

const riskLevelLookupTable = ['very_low', 'low', 'medium', 'high', 'very_high']
export const riskLevelColorLookupTable = [colors.riskLowest, colors.riskLow, colors.riskMid, colors.riskHigh, colors.riskHighest]

export const transformRiskListToViewModel = (productList, filterState) => {
  // Compile a list of products with a list of risk assessments for each
  const resultList = []

  if (productList) {
    productList.reduce((result, product) => {
      return transformProductToViewModel(result, product, filterState)
    }, resultList)
  }
  return resultList
}

const transformProductToViewModel = (result, product, filterState) => {
  const riskAssessmentVMCollection = transformRiskAssessmentToViewModel(product.id, product.risk_assessments, filterState)

  // Finally filter products depending on riskasses matching the filter
  let include =
    filterState === 'ALL' ||
    riskAssessmentVMCollection.riskAssessmentVMs.length > 0 ||
    (filterState === 'MISSING' && product.risk_assessments.length === 0)

  if (include) {
    result.push({
      id: product.id,
      name: product.name,
      locations: product.locations,
      riskStatus: riskAssessmentVMCollection.riskStatus,
      riskAssessments: riskAssessmentVMCollection.riskAssessmentVMs
    })
  }
  return result
}

export const transformRiskAssessmentToViewModel = (productId, riskAssessments, filterState) => {
  // List all risk assessments from all SDSes for this product
  let riskStatus = {
    active: 0,
    started: 0
  }

  const riskAssessmentVMs = riskAssessments.reduce((result, riskAssessment) => {
    // Filter out riskasses according to filter state
    if (filterState === 'ALL' || riskAssessment.status === filterState) {
      if (riskAssessment.status === 'DRAFT') {
        riskStatus.started++
      }
      if (riskAssessment.status === 'ACTIVE') {
        riskStatus.active++
      }

      const riskValue = Math.round(riskAssessment.risk_value * 4)
      result.push({
        id: productId + '-' + riskAssessment.id,
        name: riskAssessment.applies_to,
        created_at: riskAssessment.created_at,
        risk: riskLevelLookupTable[riskValue],
        riskValue: riskValue,
        original: riskAssessment,
        status: riskAssessment.status
      })
    }

    return result
  }, [])

  return {
    riskStatus,
    riskAssessmentVMs
  }
}
