import React, { useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { transformLocations } from 'Utils/location'
import useGetData from 'Hooks/useGetData'
import { AuthContext } from 'Context'

import { Button, ModalActionsFooter } from 'Components/readonly'
import { Section, Spacer } from 'Components/layout'
import { RadioButtonGroup, Tree } from 'Components/input'
import { FieldValidationMessages } from 'Components/form'

const LocationSelectDialog = ({
                                modalController,
                                confirmButtonIcon,
                                confirmButtonText,
                                locationsUrl = '/rapi/locations',
                                requestUrl
                              }) => {
  const intl = useIntl()

  const { organisation } = useContext(AuthContext)
  const [orgLocationsLoading, orgLocations] = useGetData(locationsUrl, null, [organisation])

  const MODE_ALL = 'ALL'
  const MODE_SELECTED = 'SELECTED'
  const locationSelectionModeOptions = [
    { value: MODE_ALL, label: intl.formatMessage({ id: 'locations.all_available_locations' }) },
    { value: MODE_SELECTED, label: intl.formatMessage({ id: 'locations.only_selected_locations' }) }
  ]
  const [locationSelectionMode, setLocationSelectionMode] = useState(MODE_ALL)
  const [selectedLocations, setSelectedLocations] = useState({ selectedKeys: [], locationNames: [] })
  const [showValidation, setShowValidation] = useState(false)

  const locationOptions = useMemo(() => !orgLocationsLoading && selectedLocations && transformLocations(orgLocations), [
    orgLocations,
    orgLocationsLoading,
    selectedLocations
  ])

  const handleLocationFilter = (value) => {
    let locationNames = []
    const getLocationName = (o, k) => {
      o.forEach((l) => {
        if (k + '' === l.key + '') {
          locationNames.push(l.label)
        }
        if (l.children && l.children.length > 0) {
          return getLocationName(l.children, k)
        }
      })
    }

    value.forEach((k) => {
      getLocationName(locationOptions, k)
    })
    setSelectedLocations({ ...selectedLocations, selectedKeys: [...value], locationNames: locationNames })

    setShowValidation(false)
  }

  const onChangeLocationSelectionMode = (mode) => {
    setLocationSelectionMode(mode)
    if (mode === MODE_ALL) {
      handleLocationFilter([])
    }
  }

  const isValid = () => locationSelectionMode === MODE_ALL || selectedLocations.selectedKeys.length > 0

  return (
    <>
      <Section>
        <RadioButtonGroup
          value={locationSelectionMode}
          onChange={(o) => onChangeLocationSelectionMode(o.value)}
          options={locationSelectionModeOptions}
        />
      </Section>
      <Section>
        {locationSelectionMode === MODE_SELECTED && (<>
          <Tree
            isLoading={orgLocationsLoading}
            options={transformLocations(orgLocationsLoading ? [] : orgLocations)}
            onChange={(e) => {
              handleLocationFilter(e.target.value)
            }}
            value={selectedLocations.selectedKeys}
            multiselect={true}
            expandedKeys={selectedLocations.expandedKeys}
          />
          {showValidation && <FieldValidationMessages messages={{ id: 'locations.choose_locations_validation' }}/>}
        </>)}
      </Section>

      <ModalActionsFooter>
        <Spacer/>
        <Button
          label={intl.formatMessage({ id: 'common.cancel' })}
          onClick={() => {
            modalController.close()
          }}
        />
        <Button
          primary
          icon={confirmButtonIcon}
          label={confirmButtonText}
          onClick={() => {
            if (isValid()) {
              let selectedLocationsUrlParams = ''
              if (locationSelectionMode === MODE_SELECTED && selectedLocations.selectedKeys.length > 0) {
                selectedLocationsUrlParams = '?locations=' + selectedLocations.selectedKeys.join(',')
              }

              window.open(requestUrl + selectedLocationsUrlParams)
              modalController.close()
            } else {
              setShowValidation(true)
            }
          }}
        />
      </ModalActionsFooter>
    </>
  )
}

export default LocationSelectDialog
