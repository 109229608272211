import React from 'react'
import styled from 'styled-components'

import { Panel } from 'Components/readonly'
import { FormattedMessage } from 'react-intl'
import { TextContainer } from 'Components/layout'
import { listCaps } from './roleHelpPanel'

const RoleHelpPanelShowAll = (props) => {
  return (
    <Panel className={props.className}>
      <TextContainer>
        <h2>
          <FormattedMessage id="capabilities.admin"/>
        </h2>
        <p>
          <FormattedMessage id="capabilities.admin_light"/> +
        </p>
        <p>
          <FormattedMessage id="capabilities.add_user_desc"/>
        </p>
        <h3>
          <FormattedMessage id="chemicals.module"/>
        </h3>
        {listCaps('superUser', 'chemical_management')}
        <h2>
          <FormattedMessage id="capabilities.admin_light"/>
        </h2>
        <p>
          <FormattedMessage id="capabilities.user"/> +
        </p>
        <h3>
          <FormattedMessage id="sds.module"/>
        </h3>
        {listCaps('adminUser', 'sds_management')}
        <h3>
          <FormattedMessage id="chemicals.module"/>
        </h3>
        {listCaps('adminUser', 'chemical_management')}
        <h2>
          <FormattedMessage id="capabilities.user"/>
        </h2>
        <p>
          <FormattedMessage id="capabilities.reader"/>
        </p>
        <h3>
          <FormattedMessage id="sds.module"/>
        </h3>
        {listCaps('reader', 'sds_management')}
        <h3>
          <FormattedMessage id="chemicals.module"/>
        </h3>
        {listCaps('reader', 'chemical_management')}
      </TextContainer>
    </Panel>
  )
}

export default styled(RoleHelpPanelShowAll)``
