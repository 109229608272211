import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Field, Form } from 'Components/form'
import { Button } from 'Components/input'
import Packages, { emptyPackageRow } from 'Components/combined/packages'
import { ModalActionsFooter } from 'Components/readonly'
import { TableMarkings } from 'Components/readonly/markings'
import validateInventory from 'Utils/validateInventory'

const Detail = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 99.99%;

  strong {
    flex: 0 1 120px;
  }

  > span {
    flex: 1 1 300px;
  }
`

export default ({ modalController, onAddProduct }) => {
  const intl = useIntl()
  const { location, product } = modalController.data

  const onSubmit = (formData) => {
    onAddProduct(location, product, formData.packages)
    modalController.close()
  }

  return (
    product && (
      <div>
        <Form onSubmit={onSubmit}>
          <Detail>
            <strong>
              <FormattedMessage id="chemicals.product"/>
            </strong>
            <span>{product.name}</span>
          </Detail>
          <Detail>
            <strong>
              <FormattedMessage id="common.supplier"/>
            </strong>
            <span>{product.supplier}</span>
          </Detail>
          <Detail>
            <strong>
              <FormattedMessage id="common.marking"/>
            </strong>
            <span>{TableMarkings(product)}</span>
          </Detail>
          <Field
            id="packages"
            Component={Packages}
            hasErrorHandling={true}
            validation={validateInventory}
            initialValue={[{ ...emptyPackageRow }]}
          />
          <ModalActionsFooter>
            <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => modalController.close()}/>
            <Button id="add-product-button" submit primary label={intl.formatMessage({ id: 'common.add' })}/>
          </ModalActionsFooter>
        </Form>
      </div>
    )
  )
}
