import React, { useState } from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'

import { Ansiktsmask, Handskar, Klader, Mask, Ogon, Skor } from '../assets'
import GHS05 from '../readonly/assets/acid.svg'
import GHS04 from '../readonly/assets/bottle.svg'
import GHS07 from '../readonly/assets/exclam.svg'
import GHS01 from '../readonly/assets/explos.svg'
import GHS02 from '../readonly/assets/flamme.svg'
import GHS09 from '../readonly/assets/pollu.svg'
import GHS03 from '../readonly/assets/rondflam.svg'
import GHS08 from '../readonly/assets/silhouette.svg'
import GHS06 from '../readonly/assets/skull.svg'

const Button = styled.div`
  display: inline-block;
  width: 120px;
  font-size: 14px;
  padding: 18px;
  cursor: pointer;
  vertical-align: top;
  text-align: center;

  img {
    flex: 0 0 70px;
    width: 70px;
    height: 70px;
    opacity: 0.5;
    padding: 5px;
  }

  @media screen and (min-width: 768px) {
    img:hover:not(.p-disabled) {
      background: #c0c0c0;
      border-radius: 5px;
    }
  }

  span {
    margin-top: 8px;
  }

  &.selected {
    img {
      opacity: 1;
    }

    span {
      font-weight: bold;
      color: #fff;
    }
  }
`

const Container = styled.div``

const GroupContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 360px;
`

export const ProtectiveGearButtonGroup = injectIntl(({ onChange, intl, value }) => {
  const list = [
    { src: Ogon, value: 'eyes', label: intl.formatMessage({ id: 'riskassessment.protection_options.eyes' }) },
    { src: Ansiktsmask, value: 'mask', label: intl.formatMessage({ id: 'riskassessment.protection_options.mask' }) },
    { src: Handskar, value: 'gloves', label: intl.formatMessage({ id: 'riskassessment.protection_options.gloves' }) },
    { src: Skor, value: 'shoes', label: intl.formatMessage({ id: 'riskassessment.protection_options.shoes' }) },
    {
      src: Mask,
      value: 'respiratory',
      label: intl.formatMessage({ id: 'riskassessment.protection_options.respiratory' })
    },
    { src: Klader, value: 'clothes', label: intl.formatMessage({ id: 'riskassessment.protection_options.clothes' }) }
  ]

  return (
    <GroupContainer>
      <SelectButtonGroup onChange={onChange} value={list} showLabel={true} selectedValues={value}/>
    </GroupContainer>
  )
})

export const PictogramButtonGroup = ({ onChange, value, property, ...props }) => {
  const list = [
    { src: GHS01, value: 'GHS01' },
    { src: GHS02, value: 'GHS02' },
    { src: GHS03, value: 'GHS03' },
    { src: GHS04, value: 'GHS04' },
    { src: GHS05, value: 'GHS05' },
    { src: GHS06, value: 'GHS06' },
    { src: GHS07, value: 'GHS07' },
    { src: GHS08, value: 'GHS08' },
    { src: GHS09, value: 'GHS09' }
  ]

  const onValueChange = (values) => {
    if (property) {
      value = []
      values.target.value.forEach((v) => {
        let o = {}
        o[property] = v
        value.push(o)
      })
      onChange({ target: { value: value } })
    } else {
      onChange({ target: { value: values.target.value } })
    }
  }

  let internalValue = value
  if (property && value) {
    internalValue = value.map((v) => {
      return v[property]
    })
  }

  return <SelectButtonGroup onChange={onValueChange} value={list} selectedValues={internalValue} {...props} />
}

export const SelectButtonGroup = ({ id, onChange, value, showLabel, selectedValues }) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedValues ? selectedValues : [])
  const select = (e) => {
    let selected = [...selectedOptions]
    if (e.target.dataset.selected === 'true') {
      selected.splice(selected.indexOf(e.target.dataset.value), 1)
    } else {
      selected.push(e.target.dataset.value)
    }

    setSelectedOptions(selected)
    onChange({ target: { value: selected } })
  }

  return (
    <Container id={id}>
      {value.map((b, i) => (
        <Button key={i} className={selectedOptions.includes(b.value) ? 'selected' : ''}>
          <img src={b.src} alt={b.value} data-value={b.value} data-selected={selectedOptions.includes(b.value)}
               onClick={select}/>
          {showLabel && <span>{b.label}</span>}
        </Button>
      ))}
    </Container>
  )
}
