import axios from 'axios'
import { Section } from 'Components/layout'
import { format, isBefore, startOfTomorrow } from 'date-fns'
import _ from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'
import colors from '../colors.scss'
import { AutoComplete, Switch, SwitchGroup, TextInput } from '../input'
import { Button } from '../readonly'
import { CategoryPickListHazard } from './categoryPickListHazard'
import ChemicalIngredientIdInput from './chemicalIngredientIdInput'

const EntryContainer = styled.div`
  &.edit {
    background-color: ${colors.darkBlue};
    padding: 10px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.overlayBackgroundColor};
    padding-bottom: 10px;
  }

  margin-bottom: 10px;

  h2 {
    font-size: 16px;
    padding: 10px 0 0 0;
  }
`

const Entry = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: flex-start;

  > * {
    flex: 1 0 1%;
  }

  > ${TextInput} {
    flex: 0 0 100px;
  }

  > ${Button} {
    flex: 0 0 30px;
  }

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
    ${TextInput} {
      flex: 0 0 100%;
      margin-top: 8px;
    }
  }
`

const EntryInput = styled.div`
  label {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
  }
`

const Add = styled.div`
  margin-bottom: 20px;

  > ${AutoComplete} {
    width: 100%;
  }
`

const CreateNew = styled.div`
  height: 20px;
  text-align: center;
`

const ItemTemplate = styled.div`
  display: flex;
  width: 420px;

  > div {
    flex: 0 0 210px;
    margin-right: 10px;
  }
`

const Designations = styled.div`
  && ${TextInput} {
    width: 200px;
  }
`

export const ChemicalIngredientList = injectIntl(
  ({
     intl,
     id,
     onAddExisting,
     onChange,
     value,
     hazardStatementsList,
     hazardStatementsIsLoading,
     hazardCategoriesList,
     hazardCategoriesIsLoading,
     filterTypes,
     filterTypesIsLoading,
     productId,
     lang
   }) => {
    const [searchValue, setSearchValue] = useState('')
    const [ingredientSuggestions, setIngredientSuggestions] = useState([])
    const [editOpen, setEditOpen] = useState({})
    const searchRequestTokenRef = useRef()

    const ingredientFilterOptions = useMemo(() => {
      return Object.entries(filterTypes).map(([value, label]) => {
        return {
          value,
          label
        }
      })
    }, [filterTypes])

    const buildIngredientVariant = (casId) => ({
      classification_incorrect: false,
      flagged_for_ingredient_filters: [],
      listed_prio: false,
      ingredient: {
        cas_id: casId || '',
        names: [
          { language: 'en', name: '' },
          { language: 'sv', name: '' },
          { language: 'no', name: '' },
          { language: 'dk', name: '' }
        ]
      },
      hazard_categories: [],
      hazard_statements: []
    })

    const buildIngredient = (ingredientVariant, isBaseInformationEditiable) => ({
      ingredient_variant: ingredientVariant,
      id: null,
      concentration: '',
      dnel: [],
      pnec: [],
      ingredient_variant_id: ingredientVariant.id,
      isBaseInformationEditiable: isBaseInformationEditiable
    })

    const onSearchValueChange = (value) => {
      setSearchValue(value)
    }

    const add = (selected) => {
      // Set default values for new ingredient
      const ingredientVariant = selected
      const newIngredient = buildIngredient(ingredientVariant, false)
      const newIngredients = [...value, newIngredient]
      onChange({ target: { value: newIngredients } })
      if (onAddExisting) {
        onAddExisting(newIngredient)
      }
    }

    const create = () => {
      let lastIndex = value.length
      // Create a new ingredient. Create a new list of ingredients, including the new ingredient.
      const newIngredients = [...value, buildIngredient(buildIngredientVariant(), true)]
      // Update the list of ingredients.
      onChange({ target: { value: newIngredients } })
      // Expand the edit form for the new ingredient.
      setIsEditFormExpanded(lastIndex)
    }

    const remove = (index) => {
      setIsEditFormExpanded(index, false)
      const newIngredients = [...value]
      newIngredients.splice(index, 1)
      onChange({ target: { value: newIngredients } })
    }

    const update = (index, prop, e) => {
      const newValue = e.target.value === false || e.target.value ? e.target.value : null
      const newIngredients = _.set([...value], '[' + index + ']' + prop, newValue)
      onChange({ target: { value: newIngredients } })
    }

    const updateTextInput = (index, prop, e) => {
      const newValue = e.target.value ? e.target.value : undefined
      const newIngredients = _.set([...value], '[' + index + ']' + prop, newValue)
      onChange({ target: { value: newIngredients } })
    }

    const suggestIngredients = async (event) => {
      if (searchRequestTokenRef.current) {
        searchRequestTokenRef.current.cancel('Aborted by new search')
      }
      searchRequestTokenRef.current = axios.CancelToken.source()
      try {
        const result = await axios.post(
          '/rapi/ingredients/search',
          { searchString: event.query, productId: productId },
          { cancelToken: searchRequestTokenRef.current.token }
        )
        result.data.unshift({})
        setIngredientSuggestions(result.data)
        searchRequestTokenRef.current = null
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      }
    }

    const toggleIsEditFormExpanded = (index, ingredient) => {
      if (editOpen[index]) {
        setEditOpen({ ...editOpen, [index]: false })
      } else {
        setEditOpen({ ...editOpen, [index]: true })
      }
    }

    const setIsEditFormExpanded = (index, isEditFormExpanded) => {
      setEditOpen({ ...editOpen, [index]: isEditFormExpanded !== false })
    }

    const getLangIndex = (ingredient, lang) => {
      let names = _.get(ingredient, 'ingredient_variant.ingredient.names')
      for (var i = 0; i < names.length; i++) {
        if (names[i].language === lang) {
          return i
        }
      }
    }

    const IngredientSwitch = (ingredient, index, prop, disabled = false) => {
      const htmlId = 'ingredient_' + index + prop.replace(/\./g, '-')
      const Wrapper = styled.div``
      return (
        <Wrapper>
          <label htmlFor={htmlId}>
            <FormattedMessage id={'chemicals.form.' + prop.replace(/ingredient_variant/, 'ingredient')}/>
          </label>
          <Switch
            id={htmlId}
            value={_.get(ingredient, prop, '')}
            onChange={(e) => update(index, prop, e)}
            onLabel={intl.formatMessage({ id: 'common.yes' })}
            offLabel={intl.formatMessage({ id: 'common.no' })}
            disabled={disabled}
          />
        </Wrapper>
      )
    }

    const isIngredientBaseInformationEditable = (ingredient) => ingredient.isBaseInformationEditiable

    const autoCompleteSelect = (event) => {
      if (event.value.id) {
        add(event.value)
      } else {
        create()
      }
      setSearchValue('')
    }

    return (
      <div id={id}>
        <Add>
          <h3>
            <FormattedMessage id="chemicals.form.ingredients_header"/>
          </h3>
          <AutoComplete
            id={id + '_search'}
            value={searchValue}
            placeholder={intl.formatMessage({ id: 'chemicals.form.ingredients_placeholder' })}
            onChange={(e) => onSearchValueChange(e.value)}
            field="ingredient.cas_id"
            className="newChemicalIngredientAC"
            itemTemplate={(item) =>
              !item.id ? (
                <CreateNew>
                  <FormattedMessage id="chemicals.form.ingredients_create_new_button"/>
                </CreateNew>
              ) : (
                <ItemTemplate>
                  <div>
                    <div>
                      {item.ingredient.names
                        .filter((name) => name.language === lang)
                        .map((name) => name.name)
                        .join(', ')}
                    </div>
                    <div>
                      <FormattedMessage id="chemicals.form.ingredient.cas_id:"/> {item.ingredient.cas_id}
                    </div>
                  </div>
                  <div>
                    <div>{item.hazard_categories.map((hc) => hc.abbreviation).join(', ')}</div>
                    <div>{item.hazard_statements.map((hs) => hs.code).join(', ')}</div>
                  </div>
                </ItemTemplate>
              )
            }
            suggestions={ingredientSuggestions}
            completeMethod={suggestIngredients.bind(this)}
            onSelect={autoCompleteSelect}
          />
        </Add>

        {value &&
          value.map((ingredient, index) => (
            <EntryContainer key={index} className={editOpen[index] ? 'edit' : ''}>
              <Entry>
                {ingredient.ingredient_variant ? (
                  <>
                    {(!editOpen[index] || !isIngredientBaseInformationEditable(ingredient)) && (
                      <div>
                        <div>{ingredient.ingredient_variant.ingredient.names.filter((name) => name.language === lang)[0].name}</div>
                        <div>
                          <FormattedMessage
                            id="chemicals.form.ingredient.cas_id:"/> {ingredient.ingredient_variant.ingredient.cas_id}
                        </div>
                        <div>
                          <FormattedMessage
                            id="chemicals.form.ingredient.eg_id"/>: {ingredient.ingredient_variant.ingredient.eg_id}
                        </div>
                        <div>
                          <FormattedMessage
                            id="chemicals.form.ingredient.index_id"/>: {ingredient.ingredient_variant.ingredient.index_id}
                        </div>
                        <div>
                          <FormattedMessage
                            id="chemicals.form.ingredient.reach_id"/>: {ingredient.ingredient_variant.ingredient.reach_id}
                        </div>
                      </div>
                    )}
                    <div>
                      <div>
                        {ingredient.ingredient_variant.hazard_categories
                          .map((hc) => hc.abbreviation + (hc.m_factor ? ' (M=' + hc.m_factor + ')' : ''))
                          .join(', ')}
                      </div>
                      <div>{ingredient.ingredient_variant.hazard_statements.map((hs) => hs.code).join(', ')}</div>
                      <div>{ingredient.ingredient_variant.m_factor}</div>
                    </div>
                    <Button icon="pi pi-pencil" onClick={() => toggleIsEditFormExpanded(index, ingredient)}/>
                    <Button icon="pi pi-trash" onClick={() => remove(index)}/>
                  </>
                ) : (
                  <div>Missing data</div>
                )}
                <TextInput
                  id={'ingredient_' + index + '-concentration'}
                  value={ingredient.concentration || ''}
                  onChange={(e) => update(index, 'concentration', e)}
                  placeholder={intl.formatMessage({ id: 'chemicals.concentration_percent' })}
                />
              </Entry>
              {editOpen[index] && (
                <EntryInput>
                  {isIngredientBaseInformationEditable(ingredient) &&
                    ['sv', 'en', 'no', 'dk'].map((l, i) => (
                      <div key={l + i}>
                        <label
                          htmlFor={'ingredient_' + index + 'ingredient_variant.ingredient.names-' + getLangIndex(ingredient, l) + '-name'}>
                          <FormattedMessage id={'chemicals.form.ingredient.name'}/> (
                          <FormattedMessage id={'lang.' + l}/>)
                        </label>
                        <TextInput
                          id={'ingredient_' + index + 'ingredient_variant-ingredient-names-' + getLangIndex(ingredient, l) + '-name'}
                          value={_.get(ingredient, 'ingredient_variant.ingredient.names[' + getLangIndex(ingredient, l) + '].name', '')}
                          onChange={(e) =>
                            updateTextInput(index, 'ingredient_variant.ingredient.names[' + getLangIndex(ingredient, l) + '].name', e)
                          }
                          autoFocus={l === 'sv'}
                        />
                      </div>
                    ))}
                  {isIngredientBaseInformationEditable(ingredient) && (
                    <Designations>
                      <ChemicalIngredientIdInput
                        update={update}
                        ingredient={ingredient}
                        index={index}
                        prop="ingredient_variant.ingredient.cas_id"
                      />
                      <ChemicalIngredientIdInput
                        update={update}
                        ingredient={ingredient}
                        index={index}
                        prop="ingredient_variant.ingredient.eg_id"
                      />
                      <ChemicalIngredientIdInput
                        update={update}
                        ingredient={ingredient}
                        index={index}
                        prop="ingredient_variant.ingredient.index_id"
                      />
                      <ChemicalIngredientIdInput
                        update={update}
                        ingredient={ingredient}
                        index={index}
                        prop="ingredient_variant.ingredient.reach_id"
                      />
                    </Designations>
                  )}
                  <Section>
                    <label>
                      {ingredient.ingredient_variant.other_chemicals_count > 0 && (
                        <FormattedMessage
                          id="chemicals.form.ingredient.other_chemicals_count"
                          values={{ count: ingredient.ingredient_variant.other_chemicals_count }}
                        />
                      )}
                    </label>
                  </Section>
                  <Section>
                    <label>
                      <FormattedMessage id="chemicals.form.ingredient.classification"/>
                    </label>
                    <CategoryPickListHazard
                      id={'ingredient_' + index + 'ingredient_variant-classification'}
                      value={ingredient.ingredient_variant}
                      onChange={(e) => update(index, 'ingredient_variant', e)}
                      categoryList={hazardCategoriesList}
                      isLoading={hazardCategoriesIsLoading}
                      codeList={hazardStatementsList}
                      language={lang}
                    />
                  </Section>
                  <Section>
                    {IngredientSwitch(
                      ingredient,
                      index,
                      'ingredient_variant.classification_incorrect',
                      ingredient.ingredient_variant.classification_incorrect_from_date ? isBefore(ingredient.ingredient_variant.classification_incorrect_from_date, startOfTomorrow()): false
                    )}
                    {ingredient.ingredient_variant.classification_incorrect_from_date && (
                      <>
                        <FormattedMessage id="chemicals.form.ingredient.classification_incorrect_from_date"/>{' '}
                        {format(ingredient.ingredient_variant.classification_incorrect_from_date, 'YYYY-MM-DD')}
                      </>
                    )}
                  </Section>
                  <h2>
                    <FormattedMessage id="chemicals.form.ingredient.subject_to"/>
                  </h2>
                  <SwitchGroup
                    id={'ingredient_' + index + 'ingredient_variant-flagged_for_ingredient_filters'}
                    options={ingredientFilterOptions}
                    value={_.get(ingredient, 'ingredient_variant.flagged_for_ingredient_filters', '')}
                    onChange={(e) => update(index, 'ingredient_variant.flagged_for_ingredient_filters', e)}
                    onLabel={intl.formatMessage({ id: 'common.yes' })}
                    offLabel={intl.formatMessage({ id: 'common.no' })}
                  />
                  {IngredientSwitch(ingredient, index, 'ingredient_variant.listed_prio')}
                </EntryInput>
              )}
            </EntryContainer>
          ))}
      </div>
    )
  }
)
