import React, { useState } from 'react'
import { Field, FieldGroup, Form } from 'Components/form'
import { TextInput } from 'Components/input'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { injectIntl } from 'react-intl'
import axios from 'axios'
import _ from 'lodash'
import { CategoryPickListHazard } from 'Components/combined/categoryPickListHazard'
import useCachedGetData from '../../../Hooks/useCachedGetData'

const EditCategoriesFilterDialog = ({ modalController, onSuccess, intl, model }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hazardCategoriesLoading, hazardCategories] = useCachedGetData('/rapi/chemicals/hazardcategories')
  const [, hazardStatements] = useCachedGetData('/rapi/chemicals/hazardstatements')

  const onSubmit = async (formData) => {
    setIsLoading(true)
    formData.filter_type = model.filter_type
    try {
      let result
      if (model.id !== undefined) {
        result = await axios.post('/rapi/admin/filter/category/' + model.id + '/edit', formData)
      } else {
        result = await axios.post('/rapi/admin/filter/category/add', formData)
      }
      onSuccess(result.data)
      setIsLoading(false)
      modalController.close()
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  const validateList = (value, formData) => {
    if (_.isEmpty(value)) {
      return true
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <FieldGroup>
        <Field id="label_sv" label={intl.formatMessage({ id: 'common.label_sv' })} Component={TextInput}
               initialValue={model.label_sv}/>
        <Field id="label_en" label={intl.formatMessage({ id: 'common.label_en' })} Component={TextInput}
               initialValue={model.label_en}/>
        <Field
          id={'hazards'}
          label={intl.formatMessage({ id: 'chemicals.form.classification_marking' })}
          placeholder={intl.formatMessage({ id: 'chemicals.form.classification_marking_placeholder' })}
          Component={CategoryPickListHazard}
          categoryList={hazardCategories}
          codeList={hazardStatements}
          isLoading={hazardCategoriesLoading}
          language={'sv'}
          initialValue={model.hazards}
          validation={validateList}
        />
      </FieldGroup>
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Form>
  )
}

export default injectIntl(EditCategoriesFilterDialog)
