import React, { useMemo } from 'react'

import { FormContext } from 'Components/form/formContext'
import { Dropdown } from 'Components/input'

export default ({ options, isLoading, ...props }) => {
  const { getFieldValue, setFieldValue } = React.useContext(FormContext)

  const selectedLocationIds = getFieldValue('location_ids')
  const selectedUserId = getFieldValue('responsible_user_id')

  const filteredOptions = useMemo(() => {
    return options.filter((user) => {
      if (selectedLocationIds.length === 0 || !user.limitLocations || user.limitLocations.length === 0) {
        return true
      }
      // User must have access to all selected locations to pass
      return selectedLocationIds.filter((locationId) => user.limitLocations.includes(locationId)).length === selectedLocationIds.length
    })
  }, [options, selectedLocationIds])

  if (!isLoading && selectedUserId && !filteredOptions.some((user) => user.value === selectedUserId)) {
    setFieldValue('responsible_user_id', null)
  }

  return <Dropdown options={filteredOptions} {...props} />
}
