import React from 'react'
import styled from 'styled-components'
import { Badge } from './badge'

const Badges = styled.div`
  padding: 10px 0;

  span {
    margin: 5px;
  }

  ${Badge} {
    @media screen and (max-width: 640px) {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
`

export const BadgeList = ({ sources }) => {
  if (sources.length <= 0) {
    return null
  }

  const handleClick = (source, updateFunction, index) => {
    let newList = [...source.value]
    newList.splice(index, 1)
    updateFunction(newList)
  }

  return (
    <Badges>
      {sources.map((s, si) =>
        s.value.map((b, i) => (
          <Badge
            key={si + i}
            label={b.label}
            color="#1D2134"
            textColor="#FFFFFF"
            dotColor={s.color}
            onClick={() => handleClick(s, s.update, i)}
          />
        ))
      )}
    </Badges>
  )
}
