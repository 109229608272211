import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Dropdown } from 'Components/input'
import { FlexColumnCenter } from 'Components/layout'
import useGetData from 'Hooks/useGetData'

export const ReplaceSuperUserDropdown = ({ user, value, onChange }) => {
  const intl = useIntl()

  if (!user) {
    user = {}
    console.error('ReplaceSuperUserDropdown initialized in invalid context - no user')
  }

  const [inventoryingListLoading, inventoryingList] = useGetData('/rapi/inventorying/createdby/' + user.id, [])
  const [superUserChoicesLoading, superUserChoicesList] = useGetData('/rapi/inventorying/createdbychoices', [])

  const superUserOptions = useMemo(() => {
    const superUserOptions = superUserChoicesList
      .filter((superUser) => superUser.id !== user.id)
      .map((choice) => {
        return { label: choice.name, value: choice.id }
      })
    superUserOptions.unshift({ label: intl.formatMessage({ id: 'inventory.select-other-super-user' }), value: null })
    return superUserOptions
  }, [intl, superUserChoicesList, user])

  if (!inventoryingListLoading && inventoryingList.length === 0 && value !== 'NOT_NEEDED') {
    onChange({ target: { value: 'NOT_NEEDED' } })
  }

  return (
    <>
      {inventoryingListLoading ? (
        <FlexColumnCenter>
          <i className="pi pi-spinner pi-spin"/>
        </FlexColumnCenter>
      ) : inventoryingList.length > 0 ? (
        <>
          <label>
            <FormattedMessage id="inventory.select-replacement-super-user-edit"/>
          </label>
          <Dropdown
            label={intl.formatMessage({ id: 'inventory.select-replacement-super-user' })}
            options={superUserOptions}
            disabled={superUserChoicesLoading}
            appendTo={document.body}
            value={value}
            onChange={onChange}
          />
        </>
      ) : null}
    </>
  )
}
