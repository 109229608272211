import React, { useContext, useEffect, useMemo, useState } from 'react'
import useCachedGetData from 'Hooks/useCachedGetData'
import { Column, DataTable, ToolPanel } from 'Components/readonly'
import { SearchInput } from 'Components/input'
import { globalSearch } from 'Utils/search'
import { useIntl } from 'react-intl'
import { AuthContext } from 'Context'

export const CodeList = () => {
  const intl = useIntl()
  const { user } = useContext(AuthContext)

  const [isLoadingH, hList] = useCachedGetData('/rapi/chemicals/hazardstatements', [])
  const [isLoadingP, pList] = useCachedGetData('/rapi/chemicals/precautionarystatements', [])

  const [filteredList, setFilteredList] = useState([])
  const [inputFilter, setInputFilter] = useState('')

  const list = useMemo(() => {
    const codeList = { ...hList, ...pList }
    const codeArray = Object.keys(codeList).map((code) => {
      return { code: code, description: codeList[code][user.user.language] }
    })

    return codeArray
  }, [hList, pList, user])

  useEffect(() => {
    setFilteredList(globalSearch(list, inputFilter, ['code', 'description']))
  }, [list, inputFilter])

  const handleInputFilter = (event) => {
    setInputFilter(event.target.value)
  }

  return (
    <>
      <ToolPanel>
        <SearchInput value={inputFilter} onChange={handleInputFilter}/>
        <div style={{ paddingBottom: '16px' }}/>
      </ToolPanel>

      <DataTable className="flextable" value={filteredList} sortField="code" sortOrder={1}
                 isLoading={isLoadingH || isLoadingP}>
        <Column
          field="code"
          header={intl.formatMessage({ id: 'common.code' })}
          style={{ width: '1%', whiteSpace: 'nowrap' }}
          sortable={true}
        />
        <Column field="description" header={intl.formatMessage({ id: 'common.description' })} sortable={true}/>
      </DataTable>
    </>
  )
}
