export const getLocationDisplayName = (location) => {
  if (!location) return '<unknown>'
  let name = [location.name]
  const getParentName = (location, name) => {
    name.push(location.name)
    if (location.parent_location) {
      getParentName(location.parent_location, name)
    }
  }
  if (location.parent_location) {
    getParentName(location.parent_location, name)
    name.reverse()
    if (name.length <= 3) {
      return name.join(', ')
    }
    if (name.length > 3) {
      let last = name.pop()
      return name[0] + ', ..., ' + name.pop() + ', ' + last
    }
  }

  return location.name
}

export const transformLocations = (locationsResponse) => {
  const createLocation = (location) => {
    let loc = { key: location.id, label: location.name }
    if (location.children) {
      loc.children = location.children.map((c) => {
        return createLocation(c)
      })
    }
    return loc
  }
  let locations = locationsResponse.map((location) => {
    let loc = createLocation(location)
    return loc
  })
  return locations
}
