import * as React from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import _ from 'lodash'

import { Button, SelectButton } from 'Components/input'
import { Spacer } from 'Components/layout'
import { GrowlContext } from 'Context'
import TabbedTwoColumn from 'Components/layout/tabbedTwoColumn'
import { ProgressSpinner } from 'primereact/progressspinner'
import PdfDisplayError from 'Components/readonly/pdfDisplayError'

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 70px;

  @media screen and (max-width: 1280px) {
    flex: 0 0 50px;
  }

  @media screen and (max-width: 640px) {
    display: block;
    flex: 0 0 1%;
  }

  ${SelectButton}.approve-select-button {
    margin-bottom: 10px;

    .p-button {
      @media screen and (max-width: 640px) {
        .p-button-text {
          font-size: 12px;
        }
      }

      :first-child {
        &.p-highlight {
          &,
          &:hover {
            background-color: #ffffff;
            border-color: #ffffff;
            color: #404040;
          }
        }
      }

      :nth-child(2) {
        &:not(.p-highlight):hover {
          background-color: #d20f57;
          border-color: #d20f57;
          color: #ffffff;
        }

        &.p-highlight {
          &,
          &:hover {
            background-color: #d20f57;
            border-color: #d20f57;
            color: #ffffff;
          }
        }
      }

      :last-child {
        &:not(.p-highlight):hover {
          background-color: #3eb579;
          border-color: #3eb579;
          color: #ffffff;
        }

        &.p-highlight {
          &:hover {
            background-color: #3eb579;
            border-color: #3eb579;
            color: #ffffff;
          }
        }
      }
    }
  }
`

const Version = styled.div`
  text-align: right;

  @media screen and (max-width: 1280px) and (min-width: 641px) {
    display: none;
  }

  @media screen and (max-width: 640px) {
    padding: 8px 0;
    text-align: left;
  }
`

const Sds = styled.div`
  > ${Button} {
    display: none;
  }

  height: 100%;
  display: flex;
  flex: 1 1 auto;

  object {
    height: auto;
    flex: 1 1 auto;
  }

  @media screen and (max-width: 640px) {
    object {
      display: none;
    }

    > ${Button} {
      display: block;
    }
  }
`

const CenterWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProductSdsRevisionSidebarContent = injectIntl(({ intl, productId, reviewState, setReviewState }) => {
  const { displayError } = React.useContext(GrowlContext)

  const [isLoading, setIsLoading] = React.useState(true)
  const [sdsForReview, setSdsForReview] = React.useState({})
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

  React.useEffect(() => {
    const fetchSdsForReview = async () => {
      setIsLoading(true)
      try {
        const result = await axios('/rapi/chemicals/' + productId + '/sdsForReview')
        const data = _.pickBy(result.data, (value) => value.PRELIMINARY)
        setSdsForReview(data)
        setReviewState(
          _.toArray(
            _.mapValues(data, (id, language) => {
              return {
                language: language,
                id: id.PRELIMINARY,
                status: 'none'
              }
            })
          )
        )
      } catch (error) {
        displayError(error)
      }
      setIsLoading(false)
    }
    setSdsForReview({})

    fetchSdsForReview()
  }, [productId, displayError, setReviewState])

  const tabOptions = React.useMemo(() => {
    let index = -1

    if (_.isEmpty(reviewState)) {
      return []
    }
    return _.toArray(
      _.mapValues(sdsForReview, (sds, language) => {
        index++
        return {
          language: language,
          text: intl.formatMessage({ id: 'lang.' + language }),
          icon:
            reviewState[index].status === 'approve'
              ? 'pi pi-check'
              : reviewState[index].status === 'inactive'
                ? 'pi pi-minus'
                : reviewState[index].status === 'reject'
                  ? 'pi pi-times'
                  : ''
        }
      })
    )
  }, [sdsForReview, reviewState, intl])

  const handleTabChange = (event) => {
    setSelectedTabIndex(event.index)
  }

  const viewOptions = [
    { label: intl.formatMessage({ id: 'chemicalsds.new_version' }), value: 'left' },
    { label: intl.formatMessage({ id: 'chemicalsds.current_version' }), value: 'right' }
  ]

  const reviewOptions = [
    { label: intl.formatMessage({ id: 'chemicalsds.review_not_reviewed' }), value: 'none' },
    { label: intl.formatMessage({ id: 'chemicalsds.review_reject' }), value: 'reject' },
    { label: intl.formatMessage({ id: 'chemicalsds.review_inactive' }), value: 'inactive' },
    { label: intl.formatMessage({ id: 'chemicalsds.review_active' }), value: 'approve' }
  ]

  const download = (id) => {
    window.open(`/rapi/chemicals/sds/${id}/download`)
  }

  return isLoading ? (
    <ProgressSpinner/>
  ) : reviewState.length === 0 ? (
    <Content>Produkten har inga nya SDB att godkänna, den borde inte ha denna status.</Content>
  ) : (
    <TabbedTwoColumn
      selectedTabIndex={selectedTabIndex}
      onTabChange={handleTabChange}
      tabOptions={tabOptions}
      viewOptions={viewOptions}
      leftContent={
        <Content>
          <ContentHeader>
            <div>
              <SelectButton
                className="approve-select-button"
                options={reviewOptions}
                value={reviewState[selectedTabIndex].status}
                onChange={(e) => {
                  if (e.value) {
                    const newItem = { ...reviewState[selectedTabIndex], status: e.value }
                    setReviewState([...reviewState.slice(0, selectedTabIndex), newItem, ...reviewState.slice(selectedTabIndex + 1)])
                  }
                }}
              />
            </div>
            <Version>
              <FormattedMessage id="chemicalsds.new_version"/>
            </Version>
          </ContentHeader>
          {sdsForReview[tabOptions[selectedTabIndex].language].PRELIMINARY && (
            <Sds>
              <object
                type="application/pdf"
                data={'/rapi/chemicals/sds/' + sdsForReview[tabOptions[selectedTabIndex].language].PRELIMINARY}>
                <PdfDisplayError
                  pdfUrl={'/rapi/chemicals/sds/' + sdsForReview[tabOptions[selectedTabIndex].language].PRELIMINARY}/>
              </object>
              <Button
                label={intl.formatMessage({ id: 'sds.download_sdb' })}
                icon="pi pi-download"
                onClick={() => download(sdsForReview[tabOptions[selectedTabIndex].language].PRELIMINARY)}
              />
            </Sds>
          )}
        </Content>
      }
      rightContent={
        <Content>
          <ContentHeader>
            <Spacer/>
            <Version>
              <FormattedMessage id="chemicalsds.current_version"/>
            </Version>
          </ContentHeader>
          {tabOptions.length > 0 && sdsForReview[tabOptions[selectedTabIndex].language].ACTIVE ? (
            <Sds>
              <object type="application/pdf"
                      data={'/rapi/chemicals/sds/' + sdsForReview[tabOptions[selectedTabIndex].language].ACTIVE}>
                <PdfDisplayError
                  pdfUrl={'/rapi/chemicals/sds/' + sdsForReview[tabOptions[selectedTabIndex].language].ACTIVE}/>
              </object>
              <Button
                label={intl.formatMessage({ id: 'sds.download_sdb' })}
                icon="pi pi-download"
                onClick={() => download(sdsForReview[tabOptions[selectedTabIndex].language].ACTIVE)}
              />
            </Sds>
          ) : (
            <CenterWrapper>
              <FormattedMessage id="chemicalsds.first_version_for_language"/>
            </CenterWrapper>
          )}
        </Content>
      }
    />
  )
})

export default ProductSdsRevisionSidebarContent
