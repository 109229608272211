import React from 'react'
import { FormattedMessage } from 'react-intl'

import { LineHeading, Section } from 'Components/layout'
import { InventoryDetailHeaderMain } from '.'

export const InventoryDoneDetailHeader = ({ inventory, setInventory, isSaving }) => {
  return (
    <Section>
      <InventoryDetailHeaderMain inventory={inventory}/>
      <div>
        <LineHeading>
          <FormattedMessage id="common.approved"/>:
        </LineHeading>
        <span id="approved">
          {' '}
          {inventory.approved_at} - {inventory.approved_by_user_name}
        </span>
      </div>
    </Section>
  )
}
