import React, { useState } from 'react'
import { Column, DataTableWithSearch, Modal } from 'Components/readonly'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, SelectButton } from 'Components/input'
import useModalController from 'Hooks/useModalController'
import EditClassificationFilterDialog from '../components/editCategoriesFilterDialog'
import useGetData from 'Hooks/useGetData'
import _ from 'lodash'
import axios from 'axios'
import useCachedGetData from '../../../Hooks/useCachedGetData'
import { LongerSection } from '../../../Components/layout'

const emptyfilter = {
  value: [],
  label_sv: '',
  label_en: '',
  listLabel: 'chemicals.form.classification_marking_short',
  nameLabel: 'common.name',
  hazards: {
    hazard_categories: [],
    hazard_statements: {
      en: [],
      sv: []
    }
  }
}

export default () => {
  const intl = useIntl()
  const [filterModalController] = useModalController()
  const [currentModel, setCurrentModel] = useState()
  const [, hazardStatements] = useCachedGetData('/rapi/chemicals/hazardstatements')
  const [filterLoading, filter, setFilter] = useGetData('/rapi/admin/filter/category')
  const [prioIngredientsLoading, prioIngredients] = useGetData('/rapi/ingredients/prio')

  const removeRow = async (row, column) => {
    try {
      let result = await axios.delete('/rapi/admin/filter/category/remove/' + row.id)
      setFilter(result.data)
    } catch (e) {
      console.error(e.message)
    }
  }

  const editRow = (row, column) => {
    let model = {
      value: row.hazard_categories.map((hs) => hs.id),
      label_sv: row.label_sv,
      label_en: row.label_en,
      id: row.id,
      listLabel: intl.formatMessage({ id: 'chemicals.form.classification_marking_short' }),
      placeholder: intl.formatMessage({ id: 'chemicals.form.classification_marking_placeholder' }),
      nameLabel: 'common.name',
      filter_type: row.filter_type,
      hazards: {
        hazard_categories: row.hazard_categories,
        hazard_statements: {
          en: row.hazard_categories
            .filter((hc) => !!hc.hazard_statement_code)
            .map((hc) => {
              const entry = hazardStatements[hc.hazard_statement_code].en
              return {
                code: hc.hazard_statement_code,
                description: entry,
                original_description: entry,
                phrase: entry
              }
            }),
          sv: row.hazard_categories
            .filter((hc) => !!hc.hazard_statement_code)
            .map((hc) => {
              const entry = hazardStatements[hc.hazard_statement_code].sv
              return {
                code: hc.hazard_statement_code,
                description: entry,
                original_description: entry,
                phrase: entry
              }
            })
        }
      }
    }
    setCurrentModel(model)
    filterModalController.open({ title: intl.formatMessage({ id: 'common.edit' }) })
  }

  const filterDialogSuccessHandler = (data) => {
    setFilter(data)
  }

  const edit = (row, column) => {
    return <Button link primary icon="pi pi-pencil" onClick={() => editRow(row, column)}/>
  }

  const remove = (row, column) => {
    return <Button link primary icon="pi pi-trash" onClick={() => removeRow(row, column)}/>
  }

  const add = (type) => {
    let model = { ...emptyfilter }
    model.filter_type = type
    setCurrentModel(model)
    filterModalController.open({ title: intl.formatMessage({ id: 'common.add' }) })
  }

  const buildTable = (type) => (
    <>
      <DataTableWithSearch
        value={_.filter(filter, ['filter_type', type])}
        isLoading={filterLoading}
        tools={<Button primary icon="pi pi-plus" onClick={() => add(type)}
                       label={intl.formatMessage({ id: 'common.add' })}/>}>
        <Column key="label_sv" field="label_sv" header={intl.formatMessage({ id: 'common.label_sv' })}/>
        <Column key="label_en" field="label_en" header={intl.formatMessage({ id: 'common.label_en' })}/>
        <Column key="hazard_categories" field="hazards" header={intl.formatMessage({ id: 'common.value' })}/>
        <Column body={edit} style={{ width: '80px' }} header={intl.formatMessage({ id: 'common.edit' })}
                excludeGlobalFilter={true}/>
        <Column body={remove} style={{ width: '80px' }} header={intl.formatMessage({ id: 'common.delete' })}
                excludeGlobalFilter={true}/>
      </DataTableWithSearch>
    </>
  )

  const ingredTable = (list, listloading) => (
    <DataTableWithSearch value={list} isLoading={listloading}>
      <Column
        key="cas_id"
        field="cas_id"
        header={intl.formatMessage({ id: 'chemicals.form.ingredient.cas_id:' })}
        style={{ width: '150px' }}
      />
      <Column key="name" field="user_language_name.0.name"
              header={intl.formatMessage({ id: 'chemicals.form.ingredient.name' })}/>
    </DataTableWithSearch>
  )

  const [hFilterState, setFilterState] = useState('prio')
  const updateHFilter = (e) => {
    if (e.value) {
      setFilterState(e.value)
    }
  }
  const hFilterOptions = [{ label: intl.formatMessage({ id: 'chemicals.form.ingredient.listed_prio' }), value: 'prio' }]

  return (
    <>
      <Modal
        modalController={filterModalController}
        ContentComponent={EditClassificationFilterDialog}
        onSuccess={filterDialogSuccessHandler}
        model={currentModel}
      />
      <LongerSection>
        <SelectButton options={hFilterOptions} value={hFilterState} onChange={updateHFilter}/>
      </LongerSection>
      {buildTable(hFilterState)}

      {hFilterState === 'prio' && (
        <>
          <h2>
            <FormattedMessage id="chemicals.form.ingredient.prio_ingredients"/>
          </h2>
          {ingredTable(prioIngredients, prioIngredientsLoading)}
        </>
      )}
    </>
  )
}
