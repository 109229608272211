import React, { useState } from 'react'

import styled from 'styled-components'
import colors from 'Components/colors.scss'

import { injectIntl } from 'react-intl'

import {
  AutoComplete,
  CheckboxGroup,
  Datepicker,
  Dropdown,
  FileUpload,
  Monthpicker,
  MultiSelect,
  PictogramButtonGroup,
  ProtectiveGearButtonGroup,
  RichTextArea,
  SearchInput,
  StepSlider,
  Switch,
  TextareaInput,
  TextInput,
  Tree,
  VolumeChanger
} from 'Components/input'

import { Field, FieldGroup, Form } from 'Components/form'

import {
  BackLinkButton,
  Badge,
  Button,
  DataTable,
  Expander,
  ExpanderTab,
  Modal,
  ModalActionsFooter,
  NavMenu,
  Panel,
  RichTextDisplay,
  RiskfactorVisualization,
  Sidebar,
  TabView
} from 'Components/readonly'
import { FlexRow, Spacer } from 'Components/layout'

import { Dialog } from 'primereact/dialog'
import { TabPanel } from 'primereact/tabview'
import { Growl } from 'primereact/growl'
import { Messages } from 'primereact/messages'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Column } from 'primereact/column'

import useModalController from 'Hooks/useModalController'

import { transformLocations } from 'Utils/location'

const Container = styled.div`
  padding: 10px;

  > div {
    padding: 20px;
    background-color: ${colors.shadow};
  }
`

const customValidationUppercase = (value) => {
  return value.localeCompare(value.toLocaleUpperCase())
}

const locationOptions = [
  {
    id: 38,
    parent_location_id: null,
    name: 'Avdelning A',
    count: '2',
    userCount: '2',
    children: [
      {
        id: 39,
        parent_location_id: '38',
        name: 'Hus A1',
        count: '1',
        userCount: '0',
        children: [
          { id: 40, parent_location_id: '39', name: 'Rum A1 m', count: '1', userCount: '0' },
          { id: 41, parent_location_id: '39', name: 'Rum A1 n', count: '1', userCount: '0' },
          { id: 42, parent_location_id: '39', name: 'Rum A1 o', count: '3', userCount: '0' }
        ]
      },
      { id: 43, parent_location_id: '38', name: 'Hus A2', count: '1', userCount: '0' },
      {
        id: 46,
        parent_location_id: '38',
        name: 'Hus A3',
        count: '0',
        userCount: '0',
        children: [
          { id: 47, parent_location_id: '46', name: 'Rum A3 r', count: '3', userCount: '0' },
          { id: 48, parent_location_id: '46', name: 'Rum A3 s', count: '0', userCount: '0' }
        ]
      }
    ]
  },
  {
    id: 49,
    parent_location_id: null,
    name: 'Avdelning B',
    count: '3',
    userCount: '2',
    children: [
      {
        id: 50,
        parent_location_id: '49',
        name: 'Hus B1',
        count: '4',
        userCount: '0',
        children: [
          { id: 51, parent_location_id: '50', name: 'Rum B1 t', count: '1', userCount: '0' },
          { id: 52, parent_location_id: '50', name: 'Rum B1 u', count: '1', userCount: '0' }
        ]
      },
      {
        id: 53,
        parent_location_id: '49',
        name: 'Hus B2',
        count: '2',
        userCount: '0',
        children: [
          { id: 54, parent_location_id: '53', name: 'Rum B2 v', count: '1', userCount: '0' },
          { id: 55, parent_location_id: '53', name: 'Rum B2 w', count: '2', userCount: '0' }
        ]
      }
    ]
  },
  { id: 37, parent_location_id: null, name: 'Avdelning C', count: '0', userCount: '0' }
]

const ComponentShowcase = ({ intl }) => {
  const [switch1, setSwitch1] = useState(false)
  const [switch2, setSwitch2] = useState(true)
  const [text, setText] = useState('')
  const [fruit, setFruit] = useState('')
  const [fruitSuggestions, setFruitSuggestions] = useState([])
  const [multiSelections, setMultiSelections] = useState([])
  const [date, setDate] = useState(undefined)
  const [sidePanelLeftVisible, setSidePanelLeftVisible] = useState(false)
  const [sidePanelRightVisible, setSidePanelRightVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [riskfaktor, setRiskfaktor] = useState(1)
  const [modalController] = useModalController({ title: 'This is a modal', data: 123 })
  const [selectedLocations, setSelectedLocations] = useState([])

  const fruits = [
    { label: 'Apple', value: 'Apple' },
    { label: 'Banana', value: 'Banana' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Pineapple Applepie', value: 'Pineapple' },
    { label: 'Pear', value: 'Pear' }
  ]

  const checkBoxArray = [
    { label: 'Apple', value: 'Apple' },
    { label: 'Banana', value: 'Banana' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Pineapple', value: 'Pineapple' },
    { label: 'Pear', value: 'Pear' }
  ]

  const citySelectItems = [
    { label: 'New York', value: 'NY' },
    { label: 'Rome', value: 'RM' },
    { label: 'London', value: 'LDN' },
    { label: 'Istanbul', value: 'IST' },
    { label: 'Paris', value: 'PRS' },
    { label: '...', value: '', type: 'input' }
  ]

  const multiSelectTemplate = (option) => {
    return option.type !== 'input' ? (
      <div>{option.label}</div>
    ) : (
      <TextInput value={text} onChange={(e) => setText(e.target.value)}></TextInput>
    )
  }

  const suggestFruits = (event) => {
    let suggestions = fruits
      .filter((fruit) => {
        return fruit.label.toLowerCase().startsWith(event.query.toLowerCase())
      })
      .map((fruit) => {
        return fruit.label
      })

    setFruitSuggestions(suggestions)
  }

  let growl = null
  let messages = null

  const showMessageSuccess = () => {
    messages.show({ severity: 'success', summary: 'Success Message', detail: 'Order submitted' })
  }

  const showSuccess = () => {
    growl.show({ severity: 'success', summary: 'Success Message', detail: 'Order submitted' })
  }

  const showInfo = () => {
    growl.show({ severity: 'info', summary: 'Info Message', detail: 'PrimeReact rocks' })
  }

  const showWarn = () => {
    growl.show({ severity: 'warn', summary: 'Warn Message', detail: 'There are unsaved changes' })
  }

  const showError = () => {
    growl.show({ severity: 'error', summary: 'Error Message', detail: 'Validation failed' })
  }

  const showMultiple = () => {
    /*    growl.show([
        {severity:'info', summary:'Message 1', detail:'PrimeReact rocks'},
        {severity:'info', summary:'Message 2', detail:'PrimeReact rocks'},
        {severity:'info', summary:'Message 3', detail:'PrimeFaces rocks'}
    ]);
*/
    //sticky
    growl.show({ sticky: true, severity: 'error', summary: 'Error Message', detail: 'Validation failed' })

    //automatically removed after 5 seconds
    //    growl.show({life: 5000, severity: 'error', summary: 'Error Message', detail: 'Validation failed'});
  }

  return (
    <Container style={{ overflow: 'auto', height: 'calc(var(--vh, 1vh) * 100 + 30px)' }}>
      <h1>Träd</h1>
      <div>
        <Tree
          Component={Tree}
          appendToBody={true}
          label={intl.formatMessage({ id: 'locations.choose_location' })}
          required={true}
          options={transformLocations(locationOptions)}
          onChange={(e) => {
            setSelectedLocations([...e.target.value])
          }}
          value={selectedLocations}
          multiselect={true}
          multiselectAll={true}
        />
      </div>

      <h1>Texter</h1>
      <div>
        <h1>Header nivå 1</h1>
        <h2>Header nivå 2</h2>
        <h3>Header nivå 3</h3>
        <h4>Header nivå 4</h4>
        <p>Brödtext</p>
      </div>

      <h1>Knappar</h1>
      <div>
        <h2>Basic</h2>
        <Button primary label="Primary"/>
        <Button accent label="Accent"/>
        <Button invert label="Ladda ned"/>
        <Button label="Button"/>

        <h2>Basic icon</h2>
        <Button primary icon="pi pi-download" label="Ladda ned"/>
        <Button accent icon="pi pi-download" label="Ladda ned"/>
        <Button invert icon="pi pi-download" label="Ladda ned"/>
        <Button icon="pi pi-download" label="Ladda ned"/>

        <h2>Icon only variant</h2>
        <Button primary icon="pi pi-download"/>
        <Button accent icon="pi pi-download"/>
        <Button icon="pi pi-download"/>

        <h2>Link variant</h2>
        <Button link primary label="Link"/>
        <Button link accent label="Link"/>
        <Button link label="Link"/>

        <h2>Link icon only variant</h2>
        <Button link primary icon="pi pi-download" label="Link with icon"/>
        <Button link accent icon="pi pi-download" label="Link with icon"/>
        <Button link icon="pi pi-download" label="Link with icon"/>

        <h2>Back link variant</h2>
        <BackLinkButton link icon="pi pi-arrow-left" label="Kemikalieförteckning"/>

        <h2>Spin and disabled states</h2>
        <Button primary label="Primary" isLoading={true}/>
        <Button primary label="Primary" disabled={true}/>
      </div>

      <h1>Meny</h1>
      <div>
        <NavMenu/>
      </div>

      <h1>Badge</h1>
      <div>
        <h3>Wrap content (attributes: none, accent, faded, danger)</h3>
        <div>
          <Badge label="Default"/>
          <Badge accent label="Accent"/>
          <Badge faded label="Faded"/>
          <Badge danger label="Danger"/>
        </div>
        <h3>Fill parent (attribute: fill)</h3>
        <div style={{ width: '500px' }}>
          <Badge fill label="Default"/>
        </div>
      </div>

      <h1>Paneler</h1>
      <div>
        <h3>Panel background (attributes: none, dark)</h3>
        <div>
          <Panel/>
          <Panel dark/>
        </div>
      </div>

      <h1>Flikar</h1>
      <div>
        <TabView>
          <TabPanel header="Test">content 1</TabPanel>
          <TabPanel header="Yxskaftbud, ge vår WC-zonmö IQ-hjälp">content 2</TabPanel>
          <TabPanel header="WWWWWWWWWWW WWWWWW WWWWW">content 3</TabPanel>
        </TabView>
      </div>

      <h1>Tabellheader</h1>
      <div>
        <DataTable
          value={[
            { a: 'a', b: 'b', c: 5 },
            { a: 'a', b: 'b', c: 2 },
            { a: 'aa', b: 'bb', c: 1 }
          ]}
          paginator={true}
          rows={10}
          loading={false}
          sortMode="multiple"
          rowGroupMode="rowspan"
          groupField="a">
          <Column key="a" field="a" header="Stuff" sortable={true}/>
          <Column key="b" field="b" header="Also Stuff" sortable={true}/>
          <Column key="c" field="c" header="Num" sortable={true}/>
        </DataTable>
      </div>

      <h1>Modaler</h1>
      <div>
        <Dialog
          header="This is a dialog"
          visible={modalVisible}
          style={{ width: '50vw' }}
          modal={true}
          appendTo={document.body}
          onHide={() => setModalVisible(false)}>
          Stuff
        </Dialog>

        <Modal
          modalController={modalController}
          ContentComponent={
            <>
              <FlexRow>{modalController.data}</FlexRow>
              <ModalActionsFooter>
                <Spacer/>
                <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
                <Button accent label={intl.formatMessage({ id: 'common.delete' })} onClick={modalController.close}/>
                <Button
                  primary
                  submit
                  icon="pi pi-save"
                  label={intl.formatMessage({ id: 'common.save' })}
                  onClick={modalController.close}
                />
              </ModalActionsFooter>
            </>
          }
        />

        <Button label="Show dialog" icon="pi pi-info-circle" onClick={(e) => setModalVisible(true)}/>
        <Button
          label="Show modal"
          icon="pi pi-info-circle"
          onClick={(e) => modalController.open({ data: 'this is data from outside the modal' })}
        />
      </div>

      <h1>Sidopanel</h1>
      <div>
        <Sidebar visible={sidePanelLeftVisible} position="left" onHide={(e) => setSidePanelLeftVisible(false)}>
          Content
        </Sidebar>
        <Sidebar visible={sidePanelRightVisible} position="right" onHide={(e) => setSidePanelRightVisible(false)}>
          Content
        </Sidebar>

        <Button icon="pi pi-arrow-right" onClick={(e) => setSidePanelLeftVisible(true)}/>
        <Button icon="pi pi-arrow-left" onClick={(e) => setSidePanelRightVisible(true)}/>
      </div>

      <h1>Donut chart</h1>
      <div>Chart</div>

      <h1>Riskfaktorvisualisering</h1>
      <div>
        <TextInput
          max={1}
          min={0}
          type="number"
          step={0.1}
          value={riskfaktor}
          onChange={(e) => {
            setRiskfaktor(e.target.value)
          }}
        />
        <RiskfactorVisualization value={riskfaktor}/>
      </div>

      <h1>Toast</h1>
      <div>
        <h2>Message</h2>
        <Messages ref={(el) => (messages = el)}/>
        <Button onClick={showMessageSuccess} label="Success" className="p-button-success"/>
      </div>
      <div>
        <h2>Growl</h2>
        <Growl ref={(el) => (growl = el)}/>
        <Button onClick={showSuccess} label="Success" className="p-button-success"/>
        <Button onClick={showInfo} label="Info" className="p-button-info"/>
        <Button onClick={showWarn} label="Warn" className="p-button-warning"/>
        <Button onClick={showError} label="Error" className="p-button-danger"/>
        <Button onClick={showMultiple} label="Multiple"/>
      </div>

      <h1>Spinners</h1>
      <div>
        <ProgressSpinner/>
        <i className="pi pi-spinner pi-spin"/>
      </div>

      <h1>Textinmatning</h1>
      <div>
        <TextInput placeholder="Placeholder" value={text} onChange={(e) => setText(e.target.value)}/>
        <br/>
        <br/>
        <TextareaInput
          placeholder="Placeholder"
          rows={5}
          cols={30}
          value={text}
          onChange={(e) => setText(e.target.value)}
          autoResize={true}
        />
        <br/>
        <br/>
        <SearchInput onChange={(e) => setText(e.target.value)} value={text}/>
        <br/>
        <br/>
        <RichTextArea onChange={(e) => setText(e.target.value)} value={text}/>
        <br/>
        <br/>
        <RichTextDisplay value={text}/>
      </div>

      <h1>Autocomplete</h1>
      <div>
        <AutoComplete
          value={fruit}
          onChange={(e) => setFruit(e.value)}
          suggestions={fruitSuggestions}
          completeMethod={suggestFruits.bind(this)}
        />
      </div>

      <h1>Dropdown</h1>
      <div>
        <Dropdown placeholder="Select fruit" options={fruits} onChange={(e) => setFruit(e.value)} value={fruit}/>
      </div>

      <h1>Checkbox</h1>
      <div>
        <CheckboxGroup options={checkBoxArray} value={[]} onChange={(value) => console.log(value)}/>
      </div>

      <h1>Switch</h1>
      <div>
        <Switch onLabel="JA" offLabel="NEJ" value={switch1} onChange={(e) => setSwitch1(e.value)}/>
        <Switch onLabel="JA" offLabel="NEJ" value={switch2} onChange={(e) => setSwitch2(e.value)}/>
      </div>

      <h1>Datum</h1>
      <div>
        <Datepicker value={date} onChange={(e) => setDate(e.value)}/>
        <br/>
        <br/>
        <Monthpicker value={date} onChange={(e) => setDate(e.value)}/>
      </div>

      <h1>Selected button group</h1>
      <div>
        <ProtectiveGearButtonGroup
          onChange={(data) => {
            console.log(data)
          }}
        />
        <br/>
        <PictogramButtonGroup
          onChange={(data) => {
            console.log(data)
          }}
        />
      </div>

      <h1>Step slider</h1>
      <div>
        <div style={{ width: '400px' }}>
          <StepSlider
            options={fruits}
            onChange={(value) => {
              console.log(value)
            }}
            nullable={true}
          />
        </div>
      </div>

      <h1>Expander</h1>
      <div>
        <div style={{ width: '400px' }}>
          <Expander>
            <ExpanderTab header="Första">En massa kontent</ExpanderTab>
            <ExpanderTab header="Andra">Lite mer kontent</ExpanderTab>
          </Expander>
        </div>
      </div>

      <h1>Mängdväljare</h1>
      <div>
        <VolumeChanger
          value={5}
          unit="Liter"
          onChange={(value) => {
            console.log(value)
          }}
        />
      </div>

      <h1>Filuppladdning</h1>
      <div>
        <FileUpload
          id="filuppladdning"
          text="Ladda upp en fil"
          type="button"
          onChange={(value) => {
            console.log(value)
          }}
        />
        <br/>
        <br/>
        <FileUpload
          id="filuppladdning2"
          text="Ladda upp en fil"
          onChange={(value) => {
            console.log(value)
          }}
        />
      </div>

      <h1>Multi select</h1>
      <div>
        <MultiSelect
          options={citySelectItems}
          itemTemplate={multiSelectTemplate}
          value={multiSelections}
          onChange={(event) => setMultiSelections(event.value)}
        />
      </div>

      <h1>Formulär</h1>
      <Form>
        <FieldGroup columns="2">
          <Field id="field1" label="Field 1" Component={TextInput} required={true}/>
          <Field
            id="field2"
            label="Field 2 (bara stora bokstäver)"
            Component={TextInput}
            required={true}
            validation={customValidationUppercase}
          />
          <Field id="field3" label="Field 3 (valfritt datum)" Component={Datepicker} dateFormat="yy-mm-dd"
                 validation="date"/>
          <Field id="field4" label="Field 4 (valfritt)" Component={TextInput}/>
          <Field id="field5" label="Field 5" onLabel="Ja" offLabel="Nej" Component={Switch} required={true}/>
          <Field id="field6" label="Field 6" Component={TextInput} required={true}/>
          <Field id="field7" label="Field 7" Component={TextInput} required={true}/>
        </FieldGroup>
        <Button submit label="Skicka formulär"/>
      </Form>
    </Container>
  )
}

export default injectIntl(ComponentShowcase)
