import React, { useContext, useMemo, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'

import { GrowlContext } from 'Context'
import { Field, FieldGroup, Form } from 'Components/form'
import { Button, Dropdown } from 'Components/input'
import { FlexColumn, FlexColumnCenter, FlexRow } from 'Components/layout'
import { Divider, ModalActionsFooter } from 'Components/readonly'
import useGetData from 'Hooks/useGetData'

const DialogWrapper = styled.div``

const DeleteSuperUserDialog = ({ modalController, onDelete }) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const { displayError, displaySuccess } = useContext(GrowlContext)

  const user = modalController.data

  const onSubmit = async (formData) => {
    setIsLoading(true)
    try {
      await axios.put('/rapi/inventorying/replacecreatedby', {
        old_user_id: user.id,
        new_user_id: formData.replacementSuperUser
      })
    } catch (error) {
      displayError(error)
      setIsLoading(false)
      return
    }
    await deleteUser()
  }

  const deleteUser = async () => {
    setIsLoading(true)
    try {
      const result = await axios.delete('/rapi/user/' + user.id)
      displaySuccess(result.data.message)
      onDelete(result.data.user)
      modalController.close()
    } catch (error) {
      displayError(error)
      setIsLoading(false)
      return
    }
    setIsLoading(false)
  }

  const [inventoryingListLoading, inventoryingList] = useGetData('/rapi/inventorying/createdby/' + user.id, [])
  const [superUserChoicesLoading, superUserChoicesList] = useGetData('/rapi/inventorying/createdbychoices', [])

  const superUserOptions = useMemo(() => {
    const superUserOptions = superUserChoicesList
      .filter((superUser) => superUser.id !== user.id)
      .map((choice) => {
        return { label: choice.name, value: choice.id }
      })
    superUserOptions.unshift({ label: intl.formatMessage({ id: 'inventory.select-other-super-user' }), value: null })
    return superUserOptions
  }, [intl, superUserChoicesList, user])

  return (
    <DialogWrapper>
      <p>
        <FormattedMessage id="user.delete_user_text"/>
      </p>
      {inventoryingListLoading ? (
        <FlexColumnCenter>
          <i className="pi pi-spinner pi-spin"/>
        </FlexColumnCenter>
      ) : inventoryingList.length === 0 ? (
        <FlexColumn>
          <ModalActionsFooter>
            <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
            <Button
              primary
              icon="pi pi-trash"
              label={intl.formatMessage({ id: 'common.delete' })}
              onClick={deleteUser}
              isLoading={isLoading}
            />
          </ModalActionsFooter>
        </FlexColumn>
      ) : (
        <>
          <Divider/>

          <Form onSubmit={onSubmit}>
            <FlexRow>
              <FieldGroup columns="1">
                <p>
                  <FormattedMessage id="inventory.super-user-has-inventoryings"/>
                </p>
                <Field
                  id="replacementSuperUser"
                  label={intl.formatMessage({ id: 'inventory.select-replacement-super-user' })}
                  focus="true"
                  Component={Dropdown}
                  options={superUserOptions}
                  initialValue={null}
                  required={true}
                  disabled={superUserChoicesLoading}
                  appendTo={document.body}
                />
              </FieldGroup>
            </FlexRow>

            <ModalActionsFooter>
              <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
              <Button primary submit icon="pi pi-trash" label={intl.formatMessage({ id: 'common.delete' })}
                      isLoading={isLoading}/>
            </ModalActionsFooter>
          </Form>
        </>
      )}
    </DialogWrapper>
  )
}

export default DeleteSuperUserDialog
