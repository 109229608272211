import React from 'react'
import { Route } from 'react-router-dom'

import ErrorBoundary from './errorBoundary'

const ErrorBoundaryRoute = ({ component: Component, componentProps, ...props }) => {
  return (
    <Route
      {...props}
      render={(routeProps) => {
        return (
          <ErrorBoundary key={props.path}>
            <Component {...routeProps} {...componentProps} />
          </ErrorBoundary>
        )
      }}
    />
  )
}

export default ErrorBoundaryRoute
