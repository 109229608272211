import React, { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import qs from 'qs'

import { AuthContext } from 'Context'
import { CheckboxGroup, LanguageDropdown } from 'Components/input'
import { Button, Divider, InfoPanel, Panel, TextWithCopyButton } from 'Components/readonly'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'
import { PageConstraint, PageHeader } from 'Components/layout'
import UiLanguageDropdown, { supportedUILanguages } from '../settings/components/uiLanguageDropdown'
import ThemeDropdown from 'Pages/settings/components/themeDropdown'

const Preview = styled.iframe`
  width: 100%;
  height: 600px;
`

const StyledSettingsPanel = styled(Panel)`
  &&& {
    label {
      white-space: nowrap;
    }

    .p-dropdown {
      min-width: auto;
    }
  }
`

const SdsIframePage = ({ intl }) => {
  const columnSelectOptions = [
    { label: intl.formatMessage({ id: 'chemicals.form.article_number' }), value: 'article-number' },
    { label: intl.formatMessage({ id: 'common.country' }), value: 'country' },
    { label: intl.formatMessage({ id: 'common.language' }), value: 'language' },
    { label: intl.formatMessage({ id: 'sds.issue-date' }), value: 'issue-date' },
    { label: intl.formatMessage({ id: 'sds.change-date' }), value: 'change-date' }
  ]

  const { user, userLanguage3, organisation } = useContext(AuthContext)
  const [selectedColumns, setSelectedColumns] = useState([...columnSelectOptions])

  let defaultLanguage = supportedUILanguages[1]

  supportedUILanguages.forEach((language) => {
    if (language.value === user.user.language) {
      defaultLanguage = language
    }
  })

  const [selectedIframeLanguage, setSelectedIframeLanguage] = useState(defaultLanguage)
  const [selectedLanguage, setSelectedLanguage] = useState({ value: userLanguage3 })
  const [selectedTheme, setSelectedTheme] = useState({ value: 'dark' })
  const [settingsChanged, setSettingsChanged] = useState(false)

  const iframeUrl = useMemo(() => {
    if (organisation) {
      const disabledColumns = columnSelectOptions
        .map((disable) => disable.value)
        .filter((columnName) => !selectedColumns.map((disable) => disable.value).some((v) => columnName === v))

      const options = {
        language: selectedIframeLanguage.value,
        sdslanguages: selectedLanguage.value,
        disable: disabledColumns,
        theme: selectedTheme.value
      }
      return organisation.weblink_iframe + qs.stringify(options, { addQueryPrefix: true })
    }
    return ''
  }, [organisation, selectedColumns, selectedIframeLanguage, selectedLanguage, selectedTheme, columnSelectOptions])

  const openPreviewExternal = () => {
    window.open(iframeUrl)
  }

  const downloadAllLinks = () => {
    window.open('/rapi/sds/downloadAllWeblinkExcel')
  }

  return (
    <PageConstraint>
      <PageHeader>
        <h1>
          <FormattedMessage id="menu.weblinking"/>
        </h1>
        <FormattedHTMLMessage id="linking.web-linking-text"/>
      </PageHeader>
      <StyledSettingsPanel>
        <h2>
          <FormattedMessage id="linking.settings"/>
        </h2>
        <div className="p-grid">
          <div className="p-col-12 p-md-2">
            <label htmlFor="iframeLanguageSelect">
              <FormattedMessage id="linking.iframe-language"/>
            </label>
            <br/>
            <UiLanguageDropdown
              id="languageSelect"
              value={selectedIframeLanguage.value}
              onChange={(value) => {
                setSettingsChanged(true)
                setSelectedIframeLanguage(value)
              }}
            />
          </div>
          <div className="p-col-12 p-md-3">
            <label htmlFor="languageSelect">
              <FormattedMessage id="linking.sdslanguages"/>
            </label>
            <br/>
            <LanguageDropdown
              id="iframeLanguageSelect"
              value={selectedLanguage.value}
              onChange={(value) => {
                setSettingsChanged(true)
                setSelectedLanguage(value)
              }}
              defaultLabel={intl.formatMessage({ id: 'common.all' })}
            />
          </div>
          <div className="p-col-12 p-md-2">
            <label htmlFor="themeSelect">
              <FormattedMessage id="common.theme"/>
            </label>
            <br/>
            <ThemeDropdown
              id="themeSelect"
              value={selectedTheme.value}
              onChange={(value) => {
                setSettingsChanged(true)
                setSelectedTheme(value)
              }}
            />
          </div>
          <div className="p-col-12 p-md-5">
            <label htmlFor="columnSelect">
              <FormattedMessage id="linking.columns"/>
            </label>
            <div>
              <CheckboxGroup
                horizontal
                id="columnSelect"
                options={columnSelectOptions}
                value={selectedColumns}
                onChange={(value) => {
                  setSettingsChanged(true)
                  setSelectedColumns(value)
                }}
              />
            </div>
          </div>
        </div>
        <Divider/>
        <h3>
          <FormattedMessage id="linking.iframe-code"/>
        </h3>
        <TextWithCopyButton value={iframeUrl}/>
        {settingsChanged && <InfoPanel messageid="linking.reminder"/>}
        <Button primary label={intl.formatMessage({ id: 'common.download_all_links' })} icon="pi pi-download"
                onClick={downloadAllLinks}/>
      </StyledSettingsPanel>

      <PageHeader>
        <h2>
          <FormattedMessage id="linking.live-preview"/>
        </h2>

        <div className="md-show">
          <Preview title={intl.formatMessage({ id: 'linking.live-preview' })} src={iframeUrl}/>
        </div>
        <div className="md-hide" style={{ marginTop: '10px', paddingBottom: '20px' }}>
          <Button label={intl.formatMessage({ id: 'linking.show-preview' })} onClick={openPreviewExternal}/>
        </div>
      </PageHeader>
    </PageConstraint>
  )
}

export default injectIntl(SdsIframePage)
