import ChemicalSdsView from 'Components/combined/chemicalSdsView'
import { FormContext } from 'Components/form/formContext'
import ValidationFailedMessage from 'Components/form/validationFailedMessage'
import TwoColumn from 'Components/layout/twoColumn'
import { TabView } from 'Components/readonly'
import { TabPanel } from 'primereact/tabview'
import { AuthContext } from 'Context'
import React, { useContext } from 'react'
import { IntlProvider, useIntl } from 'react-intl'
import styled from 'styled-components'
import flattenMessages from 'Utils/flattenMessages'
import ProductEditForm from './productEditForm'

const RightContent = styled.div`
  overflow-y: auto;

  @media screen and (max-width: 640px) {
    overflow-y: scroll;
  }
`

const ProductEditSidebarContent = ({
  rightContentId,
  formRefSv,
  formRefEn,
  formRefNo,
  formRefDk,
  productId,
  productStatus,
  isUploadSdsModalOpen,
  onUploadNewSds,
  onReplaceNewSds,
  messages,
  reviewState,
  showValidationMessages,
  setIsChemicalSdsValid,
  showClickOutsideError
}) => {
  const { anyValidationFailed } = useContext(FormContext)
  const { activeMarket } = useContext(AuthContext)

  const intl = useIntl()

  const viewOptions = [
    { label: intl.formatMessage({ id: 'sds.sds-title' }), value: 'left' },
    { label: intl.formatMessage({ id: 'chemicals.product' }), value: 'right' }
  ]

  const messagesEn = React.useMemo(() => flattenMessages(messages['en']), [messages])
  const messagesSv = React.useMemo(() => flattenMessages(messages['sv']), [messages])

  const reviewedLanguage = React.useMemo(() => {
    const acceptedSds = reviewState.find((sds) => sds.status === 'approve')
    return acceptedSds ? acceptedSds.language : null
  }, [reviewState])

  const validationFailedSV = formRefSv.current && anyValidationFailed(formRefSv.current.getChildren())
  const validationFailedEN = formRefEn.current && anyValidationFailed(formRefEn.current.getChildren())
  const validationFailedNO = formRefNo.current && anyValidationFailed(formRefNo.current.getChildren())
  const validationFailedDK = formRefDk.current && anyValidationFailed(formRefDk.current.getChildren())
  const validationFailed = validationFailedSV || validationFailedEN || validationFailedNO || validationFailedDK

  const activeIndex = () => {
    if (activeMarket === 'SE') {
      return 0
    } else if (activeMarket === 'NO') {
      return 1
    } else if (activeMarket === 'DK') {
      return 2
    }

    return 3
  }

  return (
    <TwoColumn
      viewOptions={viewOptions}
      leftContent={
        <ChemicalSdsView
          productId={productId}
          productStatus={productStatus}
          isUploadSdsModalOpen={isUploadSdsModalOpen}
          onUploadNewSds={onUploadNewSds}
          onReplaceNewSds={onReplaceNewSds}
          overrideDefaultLanguage={reviewedLanguage}
          showValidationMessages={showValidationMessages}
          setIsChemicalSdsValid={setIsChemicalSdsValid}
          editView={true}
        />
      }
      rightContent={
        <>
          {(showClickOutsideError || validationFailed) && <ValidationFailedMessage />}
          <RightContent id={rightContentId}>
            <TabView renderActiveOnly={false} activeIndex={activeIndex()}>
              <TabPanel
                header={intl.formatMessage({ id: 'lang.SWE' })}
                headerClassName={(validationFailedSV ? 'error' : '', activeMarket !== 'SE' ? 'hidden' : null)}>
                <IntlProvider key={'sv'} locale={'sv'} messages={messagesSv}>
                  <Formen formRef={formRefSv} productId={productId} lang="sv" />
                </IntlProvider>
              </TabPanel>
              <TabPanel
                header={intl.formatMessage({ id: 'lang.NOR' })}
                headerClassName={(validationFailedNO ? 'error' : '', activeMarket !== 'NO' ? 'hidden' : null)}>
                <IntlProvider key={'no'} locale={'no'} messages={messagesEn}>
                  <Formen formRef={formRefNo} productId={productId} lang="no" />
                </IntlProvider>
              </TabPanel>
              <TabPanel
                header={intl.formatMessage({ id: 'lang.DAN' })}
                headerClassName={(validationFailedDK ? 'error' : '', activeMarket !== 'DK' ? 'hidden' : null)}>
                <IntlProvider key={'dk'} locale={'dk'} messages={messagesEn}>
                  <Formen formRef={formRefDk} productId={productId} lang="dk" />
                </IntlProvider>
              </TabPanel>
              <TabPanel header={intl.formatMessage({ id: 'lang.ENG' })} headerClassName={validationFailedEN ? 'error' : ''}>
                <IntlProvider key={'en'} locale={'en'} messages={messagesEn}>
                  <Formen formRef={formRefEn} productId={productId} lang="en" />
                </IntlProvider>
              </TabPanel>
            </TabView>
          </RightContent>
        </>
      }
    />
  )
}

export default ProductEditSidebarContent

const Formen = ({ formRef, productId, lang }) => {
  return <ProductEditForm ref={formRef} productId={productId} lang={lang} />
}
