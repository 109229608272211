import React, { useContext, useMemo, useState } from 'react'
import axios from 'axios'
import { format, parse } from 'date-fns'

import { Field, FieldGroup, Form } from 'Components/form'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { AuthContext, GrowlContext } from 'Context'

import { injectIntl } from 'react-intl'
import { Datepicker, Tree } from 'Components/input'
import { transformLocations } from 'Utils/location'
import useGetData from 'Hooks/useGetData'
import { InventoryStartLockMessage } from './inventoryStartLockMessage'
import ResponsibleUserDropdown from './responsibleUserDropdown'

const InventoryDialog = ({ modalController, updateList, createList, intl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { displayError } = useContext(GrowlContext)

  const inventoryData = modalController.data

  const isEditMode = !!inventoryData

  // Responsible users
  const { organisation } = useContext(AuthContext)
  const [responsibleListLoading, responsibleList] = useGetData('/rapi/inventorying/responsibleusers', null, [organisation])

  const responsibleOptions = useMemo(() => {
    const responsibleOptions = [{ label: intl.formatMessage({ id: 'inventory.select_responsible' }), value: null }]
    responsibleList &&
    responsibleList.forEach((user) => {
      responsibleOptions.push({
        label: `${user.name} (${user.email})`,
        value: user.id,
        limitLocations: user.limitLocations
      })
    })
    return responsibleOptions
  }, [responsibleList, intl])

  // Locations
  // eslint-disable-next-line
  const [selectedLocations, setSelectedLocations] = useState(() => {
    return {
      selectedKeys:
        (inventoryData &&
          inventoryData.locations &&
          inventoryData.locations.map((location) => {
            return parseInt(location.location_id)
          })) ||
        [],
      expandedKeys: []
    }
  })

  const [locationsLoading, locations] = useGetData('/rapi/locations', null, [organisation])

  const onSubmit = async (formData) => {
    setIsLoading(true)
    try {
      if (isEditMode) {
        const response = await axios.put('/rapi/inventorying/' + inventoryData.id, {
          ...formData,
          start_at: format(parse(formData.start_at), 'YYYY-MM-DD')
        })
        updateList(response.data)
      } else {
        const response = await axios.post('/rapi/inventorying', {
          ...formData,
          start_at: format(parse(formData.start_at), 'YYYY-MM-DD')
        })
        createList(response.data)
      }

      setIsLoading(false)
      modalController.close()
    } catch (error) {
      displayError(error)
      setIsLoading(false)
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <InventoryStartLockMessage/>
      <FieldGroup>
        <Field
          id="start_at"
          Component={Datepicker}
          label={intl.formatMessage({ id: 'common.date' })}
          dateFormat="yy-mm-dd"
          minDate={new Date()}
          required={true}
          validation="date"
          initialValue={isEditMode ? new Date(inventoryData.start_date) : new Date()}
        />
        <Field
          id="location_ids"
          Component={Tree}
          appendToBody={true}
          label={intl.formatMessage({ id: 'inventory.choose_location' })}
          required={true}
          isLoading={locationsLoading}
          options={transformLocations(locationsLoading ? [] : locations)}
          initialValue={selectedLocations.selectedKeys}
          multiselectAll={true}
          expandedKeys={selectedLocations.expandedKeys}
          multiselect={true}
        />

        <Field
          id="responsible_user_id"
          label={intl.formatMessage({ id: 'common.responsible' })}
          Component={ResponsibleUserDropdown}
          options={responsibleOptions}
          isLoading={responsibleListLoading}
          disabled={responsibleListLoading}
          appendTo={document.body}
          initialValue={isEditMode ? parseInt(inventoryData.responsible_user_id) : null}
          required={true}
        />
      </FieldGroup>
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={() => modalController.close()}/>
        <Button
          id="inventorying-schedule-button"
          primary
          submit
          icon={isEditMode ? 'pi pi-save' : 'pi pi-arrow-circle-right'}
          label={isEditMode ? intl.formatMessage({ id: 'common.save' }) : intl.formatMessage({ id: 'common.schedule' })}
          isLoading={isLoading}
        />
      </ModalActionsFooter>
    </Form>
  )
}

export default injectIntl(InventoryDialog)
