import ChemicalFilter, { filterColors } from 'Components/combined/chemicalFilter'
import { SidebarWrapperMobile, SidebarWrapperPage } from 'Components/combined/sidebarWrapper'
import { Button, SearchInputClickable } from 'Components/input'
import { FilterTableContainer, PageConstraint, PageHeader, Section } from 'Components/layout'
import { BadgeList, Column, commaSeparatedBody, DataTable, Modal, TabView, ToolPanel } from 'Components/readonly'
import NewProductDialog from 'Pages/chemicalList/dialogs/newProductDialog'
import { TabPanel } from 'primereact/tabview'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ProductEditSidebar from './components/productEditSidebar'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import useModalController from 'Hooks/useModalController'
import axios from 'axios'
import { GrowlContext } from 'Context'
import { usePrevious } from '../../Utils/compareHook'
import { setLocked } from '../../Utils/functions'

export const ProductsPage = () => {
  const intl = useIntl()
  const { displaySuccess, displayError } = useContext(GrowlContext)
  const [inputFilter, setInputFilter] = useState('')
  const [he, setHe] = useState([])
  const [pd, setPd] = useState([])
  const [prio, setPrio] = useState([])
  const [candidate, setCandidate] = useState(false)
  const [remarkList, setRemarkList] = useState([])
  const [listingsList, setListingsList] = useState([])
  const [hazardCategories, setHazardCategories] = useState([])
  const [hazardStatements, setHazardStatements] = useState([])
  const [precautionaryStatements, setPrecautionaryStatements] = useState([])
  const [supplementalHazardInformation, setSupplementalHazardInformation] = useState('')
  const filter = {
    he,
    setHe,
    pd,
    setPd,
    prio,
    setPrio,
    candidate,
    setCandidate,
    remarkList,
    setRemarkList,
    listingsList,
    setListingsList,
    hazardCategories,
    setHazardCategories,
    hazardStatements,
    setHazardStatements,
    precautionaryStatements,
    setPrecautionaryStatements,
    supplementalHazardInformation,
    setSupplementalHazardInformation
  }
  const [isNewProductDlgOpen, setNewProductDlgOpen] = useState(false)
  const [first, setFirst] = useState(0)
  const [sort, setSort] = useState({ field: 'id', order: -1 })
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [prelChemicals, setPrelChemicals] = useState({ data: [], total: 0 })
  const [reviChemicals, setReviChemicals] = useState({ data: [], total: 0 })
  const [actiChemicals, setActiChemicals] = useState({ data: [], total: 0 })
  const [listStatusIndex, setListStatusIndex] = useState(0)
  const [editProduct, setEditProduct] = useState(null)
  const [hideProductModalController] = useModalController({ title: intl.formatMessage({ id: 'common.delete' }) })
  const [unlockingProductId, setUnlockingProductId] = useState(null)

  const prevFilter = usePrevious(filter)
  const prevListStatusIndex = usePrevious(listStatusIndex)
  const prevSort = usePrevious(sort)
  const prevPage = usePrevious(page)

  const createChemical = (product) => {
    fetchData()
  }

  const updateProduct = (updatedProduct) => {
    fetchData()
  }

  function handleSetCandidate(value) {
    typeof value == 'boolean' ? setCandidate(value) : setCandidate(false)
  }

  function fetchData() {

    if(!isNaN(parseInt(inputFilter)) || !(inputFilter.length === 1 || inputFilter.length === 2)) {
      setLoading(true)

      let filters = {
        candidate: filter.candidate
      }

      let possibleFilters = ['he', 'pd', 'prio', 'listingsList', 'tagList', 'remarkList', 'location']

      // eslint-disable-next-line
      _.each(possibleFilters, (filterProperty) => {
        if (filter[filterProperty] !== undefined && filter[filterProperty].length > 0) {
          // eslint-disable-next-line
          filters[filterProperty] = _.map(filter[filterProperty], 'value')
        }
      })

      if (filter.precautionaryStatements !== undefined && filter.precautionaryStatements.length > 0) {
        filters.precautionaryStatements = filter.precautionaryStatements
      }

      if (filter.hazardStatements !== undefined && filter.hazardStatements.length > 0) {
        filters.hazardStatements = filter.hazardStatements
      }

      if (filter.hazardCategories !== undefined && filter.hazardCategories.length > 0) {
        filters.hazardCategories = filter.hazardCategories
      }

      if (filter.supplementalHazardInformation !== undefined && filter.supplementalHazardInformation !== '') {
        filters.supplementalHazardInformation = filter.supplementalHazardInformation
      }

      let listStatus
      switch (listStatusIndex) {
        case 0:
          listStatus = 'PRELIMINARY'
          break
        case 1:
          listStatus = 'REVISION'
          break
        case 2:
          listStatus = 'ACTIVE'
          break
        default:
          listStatus = 'PRELIMINARY'
      }

      let urlParams = {
        status: listStatus,
        page: page,
        filters: filters,
        order_by: sort.field,
        order_direction: sort.order
      }

      if (inputFilter) {
        urlParams.search = inputFilter
      }

      if (filters.length > 0) {
        urlParams.filters = filters
      }
      axios.get('/rapi/chemicals?', { params: urlParams }).then((chemicals) => {
        if (chemicals) {
          if (listStatusIndex === 0) {
            setPrelChemicals(chemicals.data)
          } else if (listStatusIndex === 1) {
            setReviChemicals(chemicals.data)
          } else if (listStatusIndex === 2) {
            setActiChemicals(chemicals.data)
          }
        }
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    const filtersChanged = JSON.stringify(filter) !== JSON.stringify(prevFilter)

    if (listStatusIndex !== prevListStatusIndex) {
      if (prevPage === 1) fetchData()
      setFirst(0)
      setPage(1)
    } else if (page !== prevPage) {
      fetchData()
    } else if (sort !== prevSort || filtersChanged) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, listStatusIndex, sort, filter])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData()
    }, 400)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilter])

  const infoBody = (props) => {
    let iconType = unlockingProductId === props.rowData.id ? 'pi-spinner pi-spin unlock-loading-spinner' : 'pi-unlock'

    if (props.rowData.is_locked)
      return (
        <Button
          onClick={() => {
            setUnlockingProductId(props.rowData.id)
            setLocked(props.rowData.id, 0)
              .then(() => {
                displaySuccess(intl.formatMessage({ id: 'chemicals.unlocked' }))
                setTimeout(() => {
                  window.location.reload(false)
                }, 1500)
              })
              .catch((error) => {
                displayError(error)
              })
          }}
          icon={`pi ${iconType}`}
          style={{ zIndex: 999 }}
        />
      )

    return props.rowData.status === 'PRELIMINARY' ? (
      <Button
        icon="pi pi-trash"
        onClick={(e) => {
          e.stopPropagation()
          hideProductModalController.open({ data: props.rowData })
        }}
        style={{ zIndex: 999 }}
      />
    ) : null
  }

  const rowClass = (data) => {
    return {
      'p-datatable-row-locked': data.is_locked
    }
  }

  const setChemicalAsHidden = async (chemical) => {
    try {
      await axios.post('/rapi/chemicals/' + chemical.id + '/setHidden')
      const chemicalName = chemical.name
      displaySuccess(intl.formatMessage({ id: 'chemicals.product_hidden' }, { chemicalName }))
      fetchData()
    } catch (error) {
      displayError(`Product could not be removed: ${error}`)
    }
  }

  const ProductList = ({ isLoading, list }) => {
    return (
      <div>
        <Modal
          modalController={hideProductModalController}
          ContentComponent={modalContentConfirm}
          contentTextId="chemicals.hide_product_confirm_text"
          confirmTextId="common.delete"
          onConfirm={setChemicalAsHidden}
        />
        <BadgeList
          sources={[
            { value: filter.he, color: filterColors.he, update: filter.setHe },
            { value: filter.pd, color: filterColors.pd, update: filter.setPd },
            { value: filter.prio, color: filterColors.prio, update: filter.setPrio },
            {
              value: filter.candidate ? [{ label: intl.formatMessage({ id: 'chemicals.form.ingredient.listed_candidate' }) }] : [],
              color: filterColors.candidate,
              update: handleSetCandidate
            },
            { value: filter.listingsList, color: filterColors.listingsList, update: filter.setListingsList },
            { value: filter.remarkList, color: filterColors.remarkList, update: filter.setRemarkList }
          ]}
        />

        <DataTable
          className="clickable-rows"
          rowClassName={rowClass}
          value={list.data}
          paginator={true}
          rows={10}
          first={first}
          lazy={true}
          totalRecords={list.total}
          onPage={(e) => {
            setFirst(e.first)
            setPage(e.page + 1)
          }}
          loading={isLoading || loading}
          onRowClick={(event) => {
            if (!event.data['is_locked']) {
              setEditProduct(event.data)
            }
          }}
          sortField={sort.field}
          sortOrder={sort.order}
          onSort={(e) => {
            setSort({ field: e.sortField, order: e.sortOrder })
          }}
        >
          <Column key="id" field="id" header={"ID"} sortable={true} style={{ width: '70px' }}/>
          <Column key="name" field="name" header={intl.formatMessage({ id: 'common.name' })} sortable={true} />
          <Column key="supplier" field="supplier" header={intl.formatMessage({ id: 'common.supplier' })} sortable={true} />
          <Column
            key="sds_languages"
            field="sds_languages"
            header={intl.formatMessage({ id: 'sds.language' })}
            sortable={true}
            body={commaSeparatedBody}
          />
          <Column
            key="customer"
            field="created_by_organisation_name"
            header={intl.formatMessage({ id: 'admin.registered-by' })}
            sortable={true}
          />
          <Column key="created" field="created_at" header={intl.formatMessage({ id: 'admin.registered' })} sortable={true} />
          <Column key="updated_at" field="updated_at" header={intl.formatMessage({ id: 'common.updated' })} sortable={true} />
          <Column key="info" className="info-product-icon" body={(data, props) => infoBody(props)} style={{ width: '70px' }} />
        </DataTable>
      </div>
    )
  }

  return (
    <PageConstraint>
      <NewProductDialog isOpen={isNewProductDlgOpen} setIsOpen={setNewProductDlgOpen} createChemical={createChemical} />
      <ProductEditSidebar
        product={editProduct}
        updateProduct={updateProduct}
        isOpen={editProduct !== null}
        close={() => {
          setEditProduct(null)
        }}
      />
      <PageHeader>
        <h1>
          <FormattedMessage id="menu.products" />
        </h1>
        <ToolPanel>
          <SearchInputClickable
            value={inputFilter}
            onChange={(e) => {
              setPage(1);
              setFirst(0);
              let val = e.target.value
              setInputFilter(val)
            }}
            onClick={fetchData}
          />
          <Button
            id="create-product-button"
            primary
            label={intl.formatMessage({ id: 'chemicals.add_product' })}
            icon="pi pi-plus"
            onClick={() => setNewProductDlgOpen(true)}
          />
        </ToolPanel>

        <Section>
          <SidebarWrapperMobile icon="pi pi-filter" buttonText={intl.formatMessage({ id: 'common.filter' })}>
            <ChemicalFilter filter={filter} setFirst={setFirst} />
          </SidebarWrapperMobile>
        </Section>
      </PageHeader>{' '}
      <FilterTableContainer>
        <TabView className="tabs" activeIndex={listStatusIndex} onTabChange={(e) => setListStatusIndex(e.index)}>
          <TabPanel
            header={
              intl.formatMessage({ id: 'chemicals.preliminary-list' }) + (prelChemicals.total ? ' (' + prelChemicals.total + ')' : '')
            }
          >
            <ProductList isLoading={loading} list={prelChemicals} />
          </TabPanel>
          <TabPanel
            header={intl.formatMessage({ id: 'chemicals.under_revision' }) + (reviChemicals.total ? ' (' + reviChemicals.total + ')' : '')}
          >
            <ProductList isLoading={loading} list={reviChemicals} />
          </TabPanel>
          <TabPanel header={intl.formatMessage({ id: 'common.active' }) + (actiChemicals.total ? ' (' + actiChemicals.total + ')' : '')}>
            <ProductList isLoading={loading} list={actiChemicals} />
          </TabPanel>
        </TabView>
        <SidebarWrapperPage>
          <ChemicalFilter filter={filter} setFirst={setFirst} />
        </SidebarWrapperPage>
      </FilterTableContainer>
    </PageConstraint>
  )
}
