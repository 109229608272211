import { Dropdown, TextInput } from 'Components/input'
import { LoadingSpinner } from 'Components/readonly'
import useGetData from 'Hooks/useGetData'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import colors from 'Components/colors.scss'

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  td {
    padding: 5px;
    border: 1px solid ${colors.lightBorder};
  }

  th {
    padding: 0 5px 5px 5px;
  }

  .input_measured {
    width: 220px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  max-width: 220px;

  ${TextInput}${TextInput}${TextInput} {
  }

  > :not(:last-child) {
    margin-right: 10px;
  }
`

export default ({ product, value, onChange }) => {
  const intl = useIntl()
  const [hygienethresholdsLoading, hygienethresholds] = useGetData('/rapi/chemicals/' + product.id + '/hygienethresholds', null)

  React.useEffect(() => {
    if (hygienethresholds) {
      // Create rows for all hygienethresholds, empty rows should be saved.
      // Copy 'Measured or calculated value' and its unit from value for each matching row.
      // Skip value rows if no longer present in hygienethresholds.
      const newValue = []

      hygienethresholds.forEach((ingredient) => {
        ingredient.hygienethresholds.forEach((ht) => {
          const idProp = ingredient.type === 'ingredient' ? 'ingredient_hygiene_threshold_id' : 'general_hygiene_threshold_value_id'
          const row = value?.find((row) => parseInt(row[idProp]) === parseInt(ht.id))

          newValue.push({
            [idProp]: ht.id,
            name: ingredient.name,
            type: ht.type,
            exposure_path: ht.exposure_path,
            threshold_value: ht.value,
            threshold_unit: ht.unit,
            unit: row?.unit || ht.unit,
            value: row?.value || ''
          })
        })
      })

      onChange({ target: { value: newValue } })
    }
    // Reprocessing of value when hygienethresholds is loaded, should not run when value changes for any reason
    // eslint-disable-next-line
  }, [hygienethresholds])

  const unitOptions = [
    { value: 'ppm', label: intl.formatMessage({ id: 'hygiene-thresholds.ppm' }) },
    { value: 'mg_m3', label: intl.formatMessage({ id: 'hygiene-thresholds.mg_m3' }) },
    { value: 'gu_m3', label: intl.formatMessage({ id: 'hygiene-thresholds.gu_m3' }) },
    { value: 'fibers_m3', label: intl.formatMessage({ id: 'hygiene-thresholds.fibers_m3' }) }
  ]

  const onChangeInternal = (type, id, prop, event) => {
    const newValue = value ? [...value] : []
    const row =
      type === 'ingredient'
        ? newValue.find((row) => parseInt(row.ingredient_hygiene_threshold_id) === parseInt(id))
        : newValue.find((row) => parseInt(row.general_hygiene_threshold_value_id) === parseInt(id))
    if (row) {
      row[prop] = event.target.value
    }
    onChange({ target: { value: newValue } })
  }

  const getRow = (type, id) => {
    return type === 'ingredient'
      ? value?.find((row) => parseInt(row.ingredient_hygiene_threshold_id) === parseInt(id))
      : value?.find((row) => parseInt(row.general_hygiene_threshold_value_id) === parseInt(id))
  }

  return (
    <LoadingSpinner isLoading={hygienethresholdsLoading}>
      {hygienethresholds && hygienethresholds.some((ingredient) => ingredient.hygienethresholds.length > 0) ? (
        <Table>
          <thead>
          <tr>
            <th>
              <FormattedMessage id={'hygiene-thresholds.type'}/>
            </th>
            <th>
              <FormattedMessage id={'hygiene-thresholds.exposure_path'}/>
            </th>
            <th>
              <FormattedMessage id={'hygiene-thresholds.value'}/>
            </th>
            <th className='input_measured'>
              <FormattedMessage id={'riskassessment.measured_or_calculated_value'}/>
            </th>
          </tr>
          </thead>
          <tbody>
          {hygienethresholds.map(
            (ingredient) =>
              ingredient.hygienethresholds.length > 0 && (
                <React.Fragment key={ingredient.type + '-' + ingredient.id}>
                  <tr>
                    <td colSpan="4">
                      <strong>{ingredient.name}</strong>
                    </td>
                  </tr>
                  {ingredient.hygienethresholds.map((ht) => {
                    const row = getRow(ingredient.type, ht.id)
                    return (
                      <tr key={ht.id}>
                        <td>
                          <FormattedMessage id={'hygiene-thresholds.' + ht.type}/>
                        </td>
                        <td>{ht.exposure_path ?
                          <FormattedMessage id={'hygiene-thresholds.' + ht.exposure_path}/> : '-'}</td>
                        <td>
                          {ht.value} <FormattedMessage id={'hygiene-thresholds.' + ht.unit}/>
                        </td>
                        <td className='input_measured'>
                          <InputWrapper>
                            <TextInput value={row?.value || ''}
                                       onChange={(e) => onChangeInternal(ingredient.type, ht.id, 'value', e)}/>
                            <Dropdown
                              value={row?.unit || ht.unit}
                              onChange={(e) => onChangeInternal(ingredient.type, ht.id, 'unit', e)}
                              options={unitOptions}
                              appendTo={document.body}
                            />
                          </InputWrapper>
                        </td>
                      </tr>
                    )
                  })}
                </React.Fragment>
              )
          )}
          </tbody>
        </Table>
      ) : (
        <FormattedMessage id="hygiene-thresholds.no_ingredients"/>
      )}
    </LoadingSpinner>
  )
}
