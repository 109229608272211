import React, { useContext } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'

import { DataTable, RenderList, RichTextDisplay } from 'Components/readonly'
import { FlexColumn, FlexRow, FlexRowPadded, Section, TextContainer } from 'Components/layout'
import { Column } from 'primereact/column'
import { Markings } from 'Components/readonly/markings'
import { AuthContext } from 'Context'

const ClassificationPanelWrapper = styled.div`
  &&& {
    table {
      margin: 0;
    }
  }
`

const StyledFlexRowPadded = styled(FlexRowPadded)`
  > div {
    flex: 1 1 33%;
    padding-right: 40px;

    &:last-of-type {
      flex: 1 1 25%;
      padding-right: 0;
    }
  }
`

const ClassificationPanel = ({ product }) => {
  const intl = useIntl()
  const { activeMarket } = useContext(AuthContext)

  // Don't show info for preliminary products
  if (product.status === 'PRELIMINARY') {
    return (
      <ClassificationPanelWrapper>
        <Section>
          <FormattedMessage id="chemicals.no-preliminary-info" />
        </Section>
      </ClassificationPanelWrapper>
    )
  }

  const listingsListOptions = {
    authorization: intl.formatMessage({ id: 'chemicals.listing-authorization' }),
    restriction: intl.formatMessage({ id: 'chemicals.listing-restriction' }),
    waterdirective: intl.formatMessage({ id: 'chemicals.listing-waterdirective' }),
    sin: intl.formatMessage({ id: 'chemicals.listing-sin' }),
    cmr: intl.formatMessage({ id: 'chemicals.listing-cmr' })
  }

  const ingredientBody = ({ ingredient_variant }) => (
    <>
      {ingredient_variant.ingredient.user_language_name[0].name}
      <br />
      <FormattedMessage id="chemicals.form.ingredient.cas_id" />: {ingredient_variant.ingredient.cas_id}
      <br />
      <FormattedMessage id="chemicals.form.ingredient.eg_id" />: {ingredient_variant.ingredient.eg_id}
      <br />
      <FormattedMessage id="chemicals.form.ingredient.index_id" />: {ingredient_variant.ingredient.index_id}
      <br />
      <FormattedMessage id="chemicals.form.ingredient.reach_id" />: {ingredient_variant.ingredient.reach_id}
    </>
  )

  const commentBody = (row) => (
    <>{row.ingredient_variant.classification_incorrect && <FormattedMessage id="chemicals.incorrect_classification" />}</>
  )

  const boolBody = (boolValue) => <>{boolValue ? <i className="pi pi-check"></i> : ''}</>

  const prioBody = (row) => {
    const listedPrio = activeMarket == "NO" ? row.ingredient_variant.listed_prio_no : row.ingredient_variant.listed_prio;
    return boolBody(
      listedPrio ||
        (row.ingredient_variant.hazard_statements &&
          row.ingredient_variant.hazard_statements.some((hs) => hs.filters && hs.filters.some((filter) => filter.filter_type === 'prio')))
    )
  }

  const candidateBody = (row) => {
    return boolBody(
      row.ingredient_variant.flagged_for_ingredient_filters.includes('candidate') ||
        row.ingredient_variant.ingredient.matched_ingredients_filters.includes('candidate')
    )
  }

  const otherListingsBody = (row) => {
    let listings = []

    Object.keys(listingsListOptions).forEach((key) => {
      if (
        row.ingredient_variant.flagged_for_ingredient_filters.includes(key) ||
        row.ingredient_variant.ingredient.matched_ingredients_filters.includes(key)
      ) {
        listings.push(<div>{listingsListOptions[key]}</div>)
      }
    })

    return listings
  }

  return (
    <ClassificationPanelWrapper>
      <Section>
        {product.has_classification && product.signal_word && product.signal_word !== 'not_applicable' && (
          <StyledFlexRowPadded>
            <FlexColumn>
              <h3>
                <FormattedMessage id="chemicals.form.signal_word" />
              </h3>
              <span id="signal_word">
                <FormattedMessage id={'chemicals.form.' + product.signal_word} />
              </span>
            </FlexColumn>
            <FlexColumn>
              <h3>
                <FormattedMessage id="chemicals.form.hazard_pictograms" />
              </h3>
              <FlexRow style={{ paddingTop: '5px' }}>{Markings(product)}</FlexRow>
            </FlexColumn>
          </StyledFlexRowPadded>
        )}
        {product.has_classification ? (
          <StyledFlexRowPadded>
            <FlexColumn>
              <h3>
                <FormattedMessage id="chemicals.form.ingredient.classification" />
              </h3>
              <RenderList
                id="classification"
                list={product.hazard_categories}
                itemTemplate={(category) => (
                  <>
                    {category.user_language_name[0].name}
                    {category.m_factor && ', M=' + category.m_factor}
                  </>
                )}
              />
            </FlexColumn>
          </StyledFlexRowPadded>
        ) : (
          <p style={{ fontStyle: 'italic' }}>
            <FormattedMessage id="chemicals.not_classified" />
          </p>
        )}

        <TextContainer>
          <h3>
            <FormattedMessage id="chemicals.form.danger_markings" />
          </h3>
          <RenderList
            id="hazard_statement"
            list={product.hazard_statements}
            itemTemplate={(hcode) => (
              <>
                {hcode.phrase + ' '}({hcode.code})
              </>
            )}
          />

          <h3>
            <FormattedMessage id="chemicals.protection_markings" />
          </h3>
          <RenderList
            id="precautionary-statement"
            list={product.precautionary_statements}
            itemTemplate={(p) => (
              <>
                {p.phrase + ' '}({p.code})
              </>
            )}
          />

          <h3>
            <FormattedMessage id="chemicals.form.supplemental_hazard_information" />
          </h3>
          <p id="supplemental_hazard_information">
            <RichTextDisplay value={product.supplemental_hazard_information} />
          </p>
          <h3>
            <FormattedMessage id="chemicals.view.other_hazards" />
          </h3>
          <p id="other_hazards">
            <RichTextDisplay value={product.other_hazards} />
          </p>
        </TextContainer>
      </Section>

      <DataTable id="chemical_ingredients" className="flextable" value={product.chemical_ingredients} paginator={true} rows={10}>
        <Column
          header={intl.formatMessage({ id: 'chemicals.ingredient_single' })}
          key="ingredient"
          body={ingredientBody}
          sortField="ingredient_variant.ingredient.user_language_name[0].name"
        />
        <Column
          header={intl.formatMessage({ id: 'chemicals.form.ingredient.classification' })}
          key="classification"
          body={(row) => {
            return row.ingredient_variant.hazard_categories.map((category, index) => (
              <p key={'hazard_category-' + index}>
                {category.user_language_name[0].name} ({category.hazard_statement_code}){category.m_factor && ', M=' + category.m_factor}
              </p>
            ))
          }}
          sortfield="ingredient_variant.category.user_language_name[0].name"
        />
        <Column
          header={intl.formatMessage({ id: 'chemicals.concentration_percent' })}
          key="concentration"
          field="concentration"
          sortable={true}
        />
        <Column
          header={intl.formatMessage({ id: 'common.comment' })}
          key="comment"
          body={commentBody}
          sortable={true}
          sortField="ingredient_variant.classification_incorrect"
        />
        {activeMarket === 'SE' ? (
          <Column
            header={intl.formatMessage({ id: 'chemicals.form.ingredient.listed_prio' })}
            key="prio"
            body={prioBody}
            sortable={true}
            sortField="ingredient_variant.listed_prio"
          />
        ) : (
          <Column
            header={intl.formatMessage({ id: 'chemicals.form.ingredient.listed_prio_no' })}
            key="prio_no"
            body={prioBody}
            sortable={true}
            sortField="ingredient_variant.listed_prio_no"
          />
        )}
        <Column header={intl.formatMessage({ id: 'chemicals.form.ingredient.listed_candidate' })} key="candidate" body={candidateBody} />
        <Column header={intl.formatMessage({ id: 'chemicals.other-listings' })} key="other-listings" body={otherListingsBody} />
      </DataTable>
    </ClassificationPanelWrapper>
  )
}

export default ClassificationPanel
