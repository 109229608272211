import React from 'react'
import { PageConstraint, PageHeader } from 'Components/layout'
import { FormattedMessage, injectIntl } from 'react-intl'
import { format, parse } from 'date-fns'

import { Column } from 'primereact/column'
import { Button, DataTable } from 'Components/readonly'
import useGetData from 'Hooks/useGetData'
import { preserveNewlinesColumnBody } from 'Utils/newLines'

export const StartPage = injectIntl(({ intl }) => {
  const [isLoading, data] = useGetData('/rapi/admin/start', { sdsToApprove: [], sdsLockedForRevision: [] })

  const downloadRevisionData = () => {
    window.open('/rapi/admin/revision-excel')
  }

  const actionBody = (row) => (row['revision_action'] && row['revision_action']['action']) || 'default'

  const dateBody = (row, column) => row[column.field] && <span>{format(parse(row[column.field]), 'YYYY-MM-DD')}</span>

  return (
    <PageConstraint>
      <PageHeader>
        <h1>
          <FormattedMessage id="welcome.my-page"/>
        </h1>
        <h2>
          <FormattedMessage id="admin.#_sds_to_approve"/>
        </h2>
      </PageHeader>
      <DataTable
        value={data.sdsToApprove}
        paginator={true}
        rows={10}
        loading={isLoading}
        rowsPerPageOptions={[5, 10, 20]}
        emptyMessage={intl.formatMessage({ id: 'admin.no_sds_to_approve' })}>
        <Column field="organisation.name" header={intl.formatMessage({ id: 'admin.customer' })} sortable={true}/>
        <Column field="count" header={intl.formatMessage({ id: 'admin.count' })} sortable={true}/>
      </DataTable>
      <PageHeader>
        <h2>
          <FormattedMessage id="admin.sds_locked_for_revision"/>
        </h2>
        <Button label={intl.formatMessage({ id: 'admin.download_excel' })} icon="pi pi-download"
                onClick={downloadRevisionData}/>
      </PageHeader>
      <DataTable
        value={data.sdsLockedForRevision}
        rowGroupMode="rowspan"
        sortField="organisation.name"
        sortOrder={1}
        groupField="organisation.name"
        paginator={true}
        loading={isLoading}
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        emptyMessage={intl.formatMessage({ id: 'admin.no_sds_locked_for_revision' })}>
        <Column field="organisation.name" header={intl.formatMessage({ id: 'admin.customer' })} sortable={true}/>
        <Column
          field="productName"
          header={intl.formatMessage({ id: 'sds.product-name' })}
          body={preserveNewlinesColumnBody}
          sortable={true}
        />
        <Column field="langName" header={intl.formatMessage({ id: 'sds.language' })} sortable={true}/>
        <Column
          field="revision_action.action"
          header={intl.formatMessage({ id: 'admin.revision_action' })}
          body={actionBody}
          sortable={true}
        />
        <Column field="expireDate" header={intl.formatMessage({ id: 'sds.date' })} body={dateBody} sortable={true}/>
      </DataTable>
    </PageConstraint>
  )
})
