import React from 'react'

import { Button } from './'
import { injectIntl } from 'react-intl'

export const SdsDownloadButton = injectIntl(({ kind, sds, intl, ...props }) => {
  const download = () => {
    window.open('/rapi/sds/pdfDownload?' + kind + '[' + sds.id + ']=1')
  }
  return <Button label={intl.formatMessage({ id: 'common.download' })} icon="pi pi-download"
                 onClick={download} {...props} />
})
