import React, { useContext, useState } from 'react'
import axios from 'axios'

import { Dialog } from 'primereact/dialog'
import { injectIntl } from 'react-intl'

import { Field, FieldGroup, Form } from 'Components/form'
import { Button, Datepicker, FileUpload, SdsLanguageDropdown, Switch, TextInput } from 'Components/input'
import { ModalActionsFooter } from 'Components/readonly'
import SdsCountryDropdown from './sdsCountryDropdown'
import { AuthContext, GrowlContext } from 'Context'
import styled from 'styled-components'

const SubmitButton = styled(Button)`
  align-self: flex-end;
`

const UploadExternalSdsDialog = ({ isOpen, setIsOpen, createSds, intl }) => {
  const { userLanguage3 } = useContext(AuthContext)
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const [isLoading, setIsLoading] = useState(false)

  if (!isOpen) {
    return null
  }

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      const result = await axios.post('/rapi/sds/upload', data)
      setIsOpen(false)
      createSds(result.data)
      displaySuccess(intl.formatMessage({ id: 'chemicalsds.external_sds_sent' }))
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  const validateFile = (value) => {
    if (value.some((file) => !file.deleted && file.file.type !== 'application/pdf')) {
      return { id: 'validation.upload_requires_pdf' }
    }
  }

  return (
    <Dialog
      header={intl.formatMessage({ id: 'sds.upload-new' })}
      modal={true}
      visible={isOpen}
      onHide={() => setIsOpen(false)}
      appendTo={document.body}>
      <Form onSubmit={onSubmit} useFormData={true}>
        <FieldGroup columns="1">
          <Field id="product-name" label={intl.formatMessage({ id: 'sds.product-name' })} Component={TextInput}
                 required={true}/>
          <Field
            id="issueDate"
            label={intl.formatMessage({ id: 'sds.issued-date' })}
            dateFormat="yy-mm-dd"
            Component={Datepicker}
            required={true}
            validation="date"
          />
          <Field
            id="public"
            label={intl.formatMessage({ id: 'common.public' })}
            onLabel="Ja"
            offLabel="Nej"
            initialValue={false}
            Component={Switch}
          />
          <Field
            id="language"
            label={intl.formatMessage({ id: 'common.language' })}
            Component={SdsLanguageDropdown}
            initialValue={userLanguage3}
            required={true}
          />
          <Field
            id="country"
            label={intl.formatMessage({ id: 'common.country' })}
            Component={SdsCountryDropdown}
            initialValue="SE"
            required={true}
          />
          <Field id="comment" label={intl.formatMessage({ id: 'sds.information' })} Component={TextInput}/>
          <Field
            id="file"
            label={intl.formatMessage({ id: 'sds.sds-title' })}
            Component={FileUpload}
            required={true}
            text={intl.formatMessage({ id: 'common.select-file' })}
            validation={validateFile}
          />
        </FieldGroup>
        <ModalActionsFooter>
          <SubmitButton submit primary icon="pi pi-upload" label={intl.formatMessage({ id: 'sds.upload_sdb' })}
                        isLoading={isLoading}/>
        </ModalActionsFooter>
      </Form>
    </Dialog>
  )
}

export default injectIntl(UploadExternalSdsDialog)
