// Polyfills for IE11
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'intl-pluralrules'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/sv' // en is included so we only need sv
import 'core-js/modules/es7.string.match-all'

import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import 'primeflex/primeflex.scss'
import 'primereact/resources/themes/luna-green/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import './index.scss'
import App from './App'
import { AuthContextProvider, CacheContextProvider, GrowlComponent, GrowlContextProvider } from 'Context'
import * as serviceWorker from './serviceWorker'
import WebFont from 'webfontloader'

// Render root
ReactDOM.render(
  <GrowlContextProvider>
    <GrowlComponent/>
    <CacheContextProvider>
      <AuthContextProvider>
        <HashRouter>
          <App/>
        </HashRouter>
      </AuthContextProvider>
    </CacheContextProvider>
  </GrowlContextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

WebFont.load({
  google: {
    families: ['Exo 2:300,400,700', 'Roboto: 300,400,700', 'sans-serif']
  }
})

// Ios autoscroll window fix.
const isIosDevice =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.platform &&
  (/iP(ad|hone|od)/.test(window.navigator.platform) || /Android/.test(navigator.userAgent))
let innerHeight = window.innerHeight

const forceWindowScrollBackMobile = () => {
  setTimeout(() => {
    if (
      window.scrollY !== 0 &&
      window.scrollX === 0 &&
      window.visualViewport &&
      window.visualViewport.scale === 1 &&
      innerHeight === window.visualViewport.height
    ) {
      window.scrollTo(0, 0)
    }
  }, 200)
}

const setVh = () => {
  setTimeout(() => {
    innerHeight = window.innerHeight
    document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px')
  }, 300)
}

if (isIosDevice) {
  document.addEventListener('scroll', forceWindowScrollBackMobile)
  window.addEventListener('orientationchange', setVh)
  window.addEventListener('resize', setVh)
  document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px')
}
