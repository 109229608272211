import React from 'react'
import axios from 'axios'
import { PageConstraint, PageHeader } from 'Components/layout'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Column } from 'primereact/column'

import useGetData from 'Hooks/useGetData'
import { Button, DataTable, Modal } from 'Components/readonly'
import useModalController from 'Hooks/useModalController'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import { GrowlContext } from 'Context'

export const StrayUsersPage = injectIntl(({ intl }) => {
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [isLoading, strayUsers, setStrayUsers] = useGetData('/rapi/admin/stray-users')
  const [deleteUserModalController] = useModalController({ title: intl.formatMessage({ id: 'user.delete_user' }) })

  const deleteUser = async (user) => {
    try {
      const result = await axios.delete('/rapi/user/' + user.id)
      displaySuccess(result.data.message)
      setStrayUsers(strayUsers.filter((u) => u.id !== user.id))
    } catch (error) {
      displayError(error)
    }
  }

  const deleteBody = (row, column) => {
    return <Button link primary icon="pi pi-trash" onClick={() => deleteUserModalController.open({ data: row })}/>
  }

  return (
    <PageConstraint>
      <Modal
        modalController={deleteUserModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="user.delete_user_text"
        confirmTextId="common.delete"
        onConfirm={deleteUser}
      />
      <PageHeader>
        <h1>
          <FormattedMessage id="admin.stray_users"/>
        </h1>
      </PageHeader>
      <DataTable
        className="flextable"
        value={strayUsers}
        paginator={true}
        rows={10}
        loading={isLoading}
        rowsPerPageOptions={[5, 10, 20]}
        emptyMessage={intl.formatMessage({ id: 'admin.no_stray_users' })}>
        <Column field="name" header={intl.formatMessage({ id: 'user.name' })} sortable={true}/>
        <Column field="email" header={intl.formatMessage({ id: 'user.email' })} sortable={true}/>
        <Column field="phone" header={intl.formatMessage({ id: 'user.phone' })} sortable={true}/>
        <Column header={intl.formatMessage({ id: 'common.delete' })} body={deleteBody}/>
      </DataTable>
    </PageConstraint>
  )
})
