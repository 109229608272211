import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'Components/readonly'
import { SidebarHeader, Spacer } from 'Components/layout'
import { setLocked } from "../../../Utils/functions";

const ProductSdsPreliminarySidebarHeader = ({ close, skip, productId, isLocked, setShowClickOutsideError }) => {
  return (
    <SidebarHeader>
      <h2>
        <FormattedMessage id="chemicals.register_product_heading"/>
      </h2>
      <Spacer/>
      <div>
        <Button label="Avbryt" onClick={close} icon="pi pi-times" className="button-text-md-hide"/>
        {isLocked ? (
          <Button id="proceed-button" disabled label="Produkt låst för redigering" icon="pi pi-lock"
                  className="button-text-md-hide"/>
        ) : (
          <Button
            id="proceed-button"
            primary
            label="Gå vidare"
            icon="pi pi-check"
            onClick={() => {
              skip()
              setLocked(productId, 1)
              setShowClickOutsideError(false);
            }}
            className="button-text-md-hide"
          />
        )}
      </div>
    </SidebarHeader>
  )
}

export default ProductSdsPreliminarySidebarHeader
