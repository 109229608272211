import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Column } from 'primereact/column'

import useGetData from 'Hooks/useGetData'
import { Button, SearchInput } from 'Components/input'
import { ModalActionsFooter, Panel, ToolPanel } from 'Components/readonly'
import { LineHeading, Section, Spacer, ThinHeaderDataTable } from 'Components/layout'
import { chemicalSearch } from 'Components/combined/chemicalFilter'
import { TableMarkings } from 'Components/readonly/markings'

export default ({ modalController, onAddProduct, onRegisterNewProduct }) => {
  const intl = useIntl()
  const location = modalController.data
  const [isLoading, chemicals] = useGetData('/rapi/chemicals', [])
  const [first, setFirst] = useState(0)
  const [searchText, setSearchText] = useState('')

  const filteredList = useMemo(() => {
    setFirst(0)
    return chemicalSearch(chemicals, searchText)
  }, [chemicals, searchText])

  const addBody = (row) => (
    <Button
      primary
      icon="pi pi-plus-circle"
      onClick={() => {
        modalController.close()
        onAddProduct(location, row)
      }}
    />
  )

  const handleRegisterNewProduct = () => {
    modalController.close()
    onRegisterNewProduct(location)
  }

  return (
    <div>
      <Section>
        <LineHeading>
          <FormattedMessage id="common.location"/>
        </LineHeading>{' '}
        {location.location}
      </Section>

      <Section>
        <FormattedMessage id="inventory.add-product-text"/>
      </Section>

      <Section>
        <Panel>
          <h3>
            <FormattedMessage id="inventory.search-product-title"/>
          </h3>
          <ToolPanel>
            <SearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
          </ToolPanel>
          <ThinHeaderDataTable
            className="flextable"
            value={filteredList}
            paginator={true}
            rows={5}
            loading={isLoading}
            first={first}
            onPage={(e) => setFirst(e.first)}>
            <Column
              key="article_number"
              field="article_number"
              header={intl.formatMessage({ id: 'chemicals.article_id' })}
              sortable={true}
            />
            <Column key="name" field="name" header={intl.formatMessage({ id: 'chemicals.product' })} sortable={true}/>
            <Column key="supplier" field="supplier" header={intl.formatMessage({ id: 'common.supplier' })}
                    sortable={true}/>
            <Column
              style={{ width: '100px !important' }}
              body={TableMarkings}
              header={intl.formatMessage({ id: 'common.marking' })}
              sortable={true}
            />
            <Column header={intl.formatMessage({ id: 'common.add' })} sortable={false} body={addBody}/>
          </ThinHeaderDataTable>
        </Panel>
      </Section>

      <Section>
        <Panel>
          <h3>
            <FormattedMessage id="inventory.register-new-product-title"/>
          </h3>
          <p>
            <FormattedMessage id="inventory.register-new-product-text"/>
          </p>
          <Button primary label={intl.formatMessage({ id: 'inventory.register-new-product-button' })}
                  onClick={handleRegisterNewProduct}/>
        </Panel>
      </Section>

      <ModalActionsFooter>
        <Spacer/>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
      </ModalActionsFooter>
    </div>
  )
}
