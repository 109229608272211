import React, { useContext, useState } from 'react'
import { injectIntl } from 'react-intl'
import Axios from 'axios'

import { GrowlContext } from 'Context'

import { Column } from 'primereact/column'

import { Section, Spacer } from 'Components/layout'
import { SearchInput, SelectButton } from 'Components/input'
import { Modal } from 'Components/readonly'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import useModalController from 'Hooks/useModalController'

import RiskRow from './riskRow'
import RiskAssessmentSidebar from './riskAssessmentSidebar'
import { EmptyRiskAssessment } from './riskAssessmentForm'
import { FilterContainer, RiskDataTable, TableControls } from './chemicalRiskPageStyle'

import { RiskAssessmentContext } from '../context/riskAssessmentContext'
import useToggleItems from 'Hooks/useToggleItems'
import { globalSearch } from 'Utils/search'

const CurrentRiskAssessmentList = ({
                                     selectedRiskAssessment,
                                     setSelectedRiskAssessment,
                                     setRiskSidebarVisible,
                                     intl
                                   }) => {
  // List
  const [inputFilter, setInputFilter] = useState('')
  const [toggleState, toggleView, setToggleRowState] = useToggleItems()

  const {
    riskListViewModel,
    restRiskListIsLoading,
    updateRiskAssessments,
    selectedProduct,
    setSelectedProduct,
    first,
    setFirst,
    filterState,
    setFilterState
  } = useContext(RiskAssessmentContext)

  const filterOptions = [
    { label: intl.formatMessage({ id: 'common.all' }), value: 'ALL' },
    { label: intl.formatMessage({ id: 'common.active' }), value: 'ACTIVE' },
    { label: intl.formatMessage({ id: 'common.draft' }), value: 'DRAFT' },
    { label: intl.formatMessage({ id: 'common.missing' }), value: 'MISSING' }
  ]

  const handleInputFilter = (event) => {
    setInputFilter(event.target.value)
    setFirst(0)
  }

  // Edit sidebar
  const [editSidebarVisible, setEditSidebarVisible] = useState(false)

  // Archive assessment
  const { displaySuccess } = React.useContext(GrowlContext)
  const [archiveAssessmentModalController] = useModalController({ title: intl.formatMessage({ id: 'chemicals.archive_assessment_title' }) })

  const onArchiveAssessmentClick = (assessment) => archiveAssessmentModalController.open({ data: { assessment } })
  const onArchiveAssessment = async (modalData) => {
    const result = await Axios.post('/rapi/riskassessments/archive/' + modalData.assessment.original.id)
    updateRiskAssessments(result.data.riskAssessments)
    if (result.data.riskAssessments.length === 0) {
      setToggleRowState(selectedProduct.id, false)
    }
    displaySuccess(result.data.message)
  }

  // Row content
  const riskBody = (row, e, v, t) => {
    return (
      <RiskRow
        row={row}
        toggleState={toggleState}
        toggleView={toggleView}
        setSelectedProduct={setSelectedProduct}
        setSelectedRiskAssessment={setSelectedRiskAssessment}
        setRiskSidebarVisible={setRiskSidebarVisible}
        setEditSidebarVisible={setEditSidebarVisible}
        onArchiveRiskAssessment={onArchiveAssessmentClick}
      />
    )
  }

  const updateFilter = (e) => {
    if (e.value) {
      setFilterState(e.value)
      setFirst(0)
    }
  }

  const onRowClick = (event) => {
    if (event.data.riskAssessments.length > 0) {
      toggleView(event.data.id)
    }
  }

  const filteredRiskList = React.useMemo(() => {
    return globalSearch([...riskListViewModel], inputFilter, ['name'])
  }, [inputFilter, riskListViewModel])
  return (
    <>
      <RiskAssessmentSidebar
        product={selectedProduct}
        selectedRiskAssessment={selectedRiskAssessment}
        updateRiskAssessments={updateRiskAssessments}
        isOpen={editSidebarVisible}
        close={() => {
          setSelectedRiskAssessment(EmptyRiskAssessment)
          setSelectedProduct(null)
          setEditSidebarVisible(false)
        }}
      />
      <Modal
        modalController={archiveAssessmentModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="chemicals.archive_assessment_text"
        confirmTextId="common.archive"
        onConfirm={onArchiveAssessment}
      />

      <Section>
        <TableControls>
          <SearchInput value={inputFilter} onChange={handleInputFilter}/>
          <Spacer/>
          <FilterContainer>
            <span style={{ paddingRight: '10px' }} className="lg-show">
              {intl.formatMessage({ id: 'common.show' })}
            </span>
            <SelectButton options={filterOptions} value={filterState} onChange={updateFilter}/>
          </FilterContainer>
        </TableControls>
      </Section>
      <RiskDataTable
        className="clickable-rows"
        responsive={false}
        value={filteredRiskList}
        paginator={true}
        rows={10}
        first={first}
        onPage={(e) => setFirst(e.first)}
        onRowClick={onRowClick}
        loading={restRiskListIsLoading}>
        <Column key="name" field="name" body={riskBody} header={intl.formatMessage({ id: 'chemicals.product' })}
                sortable={true}/>
      </RiskDataTable>
    </>
  )
}

export default injectIntl(CurrentRiskAssessmentList)
