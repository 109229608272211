import React from 'react'
import styled from 'styled-components'
import colors from '../colors.scss'
import { Accordion as PrimeAccordion, AccordionTab as PrimeAccordionTab } from 'primereact/accordion'

export const Accordion = styled(({ children, ...props }) => <PrimeAccordion {...props}>{children}</PrimeAccordion>)`
  &&& {
    .p-accordion-header {
      outline: none;
    }

    .p-accordion-header a {
      padding: 10px;
      border-radius: 20px;
      border: none;
      background-color: ${colors.darkElement};
      color: ${colors.text};
      display: flex;
      text-decoration: none;
    }

    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover a {
      background-color: ${colors.darkestPanel};
      color: ${colors.primary};
      border: none;
    }

    .p-accordion-header:not(.p-disabled).p-highlight a {
      background-color: ${colors.primary};
      color: ${colors.text};
      border: none;
    }

    .p-accordion-header:not(.p-disabled) a:focus {
      box-shadow: 0 0 0 2px ${colors.primary};
    }

    .p-accordion-content {
      padding: 20px;
      background-color: ${colors.lightBackground};
      color: ${colors.text};
      border: none;
      border-radius: 0 0 20px 20px;
    }
  }
`

export const AccordionTab = styled(PrimeAccordionTab)``
