import styled from 'styled-components'
import { AutoComplete as AC } from 'primereact/autocomplete'
import colors from '../colors.scss'

export const AutoComplete = styled(AC)`
  &.newChemicalIngredientAC .p-autocomplete-panel.p-input-overlay.p-input-overlay-visible {
    max-height: 800px !important;
  }
  &&& {
    flex: 1 1 1px;

    .p-autocomplete-loader {
      color: ${colors.darkGrey};
      top: 19px;
    }

    input {
      background-color: ${colors.white};
      color: ${colors.black};
      border-radius: 20px;
      border: 2px solid ${colors.white};
      padding: 7px 15px;
      width: 100%;

      &:hover:not(.p-disabled) {
        border-color: ${colors.white};
      }

      &:focus {
        box-shadow: none;
        border-color: ${colors.primary};
      }
    }

    .p-autocomplete-panel {
      border-radius: 20px;
      border: none;
      background-color: ${colors.white};

      .p-autocomplete-items .p-autocomplete-list-item {
        color: ${colors.textDark};

        &:hover {
          background-color: ${colors.primary};
          color: ${colors.text};
        }
      }
    }
  }
`
