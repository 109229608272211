import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { AuthContext } from 'Context'

import { FlexColumn } from 'Components/layout'
import { Divider } from 'Components/readonly'
import SdsStatsChart from 'Pages/sds/components/sdsStatsChart'
import useGetData from 'Hooks/useGetData'
import { AbsoluteAnchor, ChartPanel, Service, ServiceIcon, ServicePanel, StartChartContainer } from './startPageStyle'

import IconSDSManagement from './assets/ico-sdsm.svg'

const Wrapper = styled(FlexColumn)`
  height: 100%;
`

const StartSDSModule = () => {
  const { organisation } = useContext(AuthContext)
  const [isSdsStatsLoading, stats] = useGetData('/rapi/sds/stats', {}, [organisation])

  return (
    <Wrapper>
      <Service>
        <a href="/#/sds">
          <ServicePanel>
            <AbsoluteAnchor>
              <ServiceIcon>
                <img src={IconSDSManagement} alt="SDS management"/>
              </ServiceIcon>
              <span className="service-type">SOFT</span>
              <h2>
                <FormattedMessage id="sds.module"/>
              </h2>
              <span className="service-author">by KemRisk</span>
            </AbsoluteAnchor>
          </ServicePanel>
        </a>
      </Service>
      <ChartPanel>
        <h3>
          <FormattedMessage id="sds.our-sds"/>
        </h3>
        <Divider/>
        <StartChartContainer>
          <SdsStatsChart stats={stats} isLoading={isSdsStatsLoading} animate size="160"/>
        </StartChartContainer>
      </ChartPanel>
    </Wrapper>
  )
}

export default StartSDSModule
