import { useState } from 'react'

const useToggleItems = (initalToggleState) => {
  const [toggleItemState, setToggleItemState] = useState(initalToggleState || {})

  const setToggleState = (itemId, state) => {
    let toggleStateCopy = Object.assign({}, toggleItemState)
    toggleStateCopy[itemId] = state
    setToggleItemState(toggleStateCopy)
  }

  const toggleItem = (itemId) => {
    let toggleStateCopy = Object.assign({}, toggleItemState)
    toggleStateCopy[itemId] = !toggleStateCopy[itemId]
    setToggleItemState(toggleStateCopy)
  }

  return [toggleItemState, toggleItem, setToggleState]
}

export default useToggleItems
