import styled from 'styled-components'

import { FlexRowCenter } from 'Components/layout'
import { DataTable, Panel } from 'Components/readonly'
import { SelectButton } from 'Components/input'

export const RiskDataTable = styled(DataTable)`
  &&& {
    tr > td {
      padding: 0 !important;
    }

    ${Panel} {
      padding: 0;
    }
  }
`

export const TableControls = styled(FlexRowCenter)`
  @media screen and (max-width: 640px) {
    .search-container {
      width: 100%;
    }

    ${SelectButton}.p-selectbutton {
      padding-top: 10px;
      display: flex;
      flex: 1 0 auto;
    }
  }

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }
`

export const FilterContainer = styled(FlexRowCenter)`
  &&& {
    @media screen and (max-width: 640px) {
      ${SelectButton}.p-selectbutton {
        .p-button {
          padding: 2px 0;
          min-width: auto;
        }
      }
    }

    @media screen and (max-width: 992px) {
      flex: 1 0 100%;
      display: flex;

      > div {
        padding-top: 10px;
        flex: 1 0 100%;
      }

      ${SelectButton}.p-selectbutton {
        .p-button {
          flex: 1 0 auto;
        }
      }
    }

    @media screen and (max-width: 360px) {
      .p-button-text {
        padding: 7px 0;
      }
    }
  }
`
