import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { format, lastDayOfMonth, parse, startOfMonth, subMonths } from 'date-fns'
import { Chart } from 'primereact/chart'

import useGetData from 'Hooks/useGetData'
import { Section, Spacer } from 'Components/layout'
import { Button, Column, DataTable, ToolPanel } from 'Components/readonly'
import colors from 'Components/colors.scss'
import { Monthpicker } from 'Components/input'

export const ReportRoles = () => {
  const intl = useIntl()

  const dataTableRef = useRef()
  const [date, setDate] = useState(startOfMonth(subMonths(new Date(), 1)))
  const [isLoading, customersProducts] = useGetData(
    '/rapi/admin/report-organisation-roles?start=' +
    format(parse(date), 'YYYY-MM-DD') +
    '&end=' +
    format(lastDayOfMonth(date), 'YYYY-MM-DD'),
    [],
    [date]
  )
  const [chartData, setChartData] = useState(null)

  const chartOptions = {
    legend: {
      display: true,
      labels: {
        fontColor: colors.white
      }
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            displayFormats: {
              millisecond: 'MMM DD HH:mm:ss',
              second: 'MMM DD HH:mm:ss',
              minute: 'MMM DD HH:mm:ss',
              hour: 'MMM DD HH:mm',
              day: 'MMM DD',
              week: 'YYYY-MM-DD',
              month: 'YYYY-MM-DD',
              quarter: 'YYYY-MM-DD',
              year: 'YYYY-MM-DD'
            }
          },
          ticks: {
            fontColor: colors.white
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: colors.white,
            precision: 0
          }
        }
      ]
    }
  }

  const buildDataSet = (history, field, label, color) => {
    return {
      label: label,
      data: history
        .filter((data, index) => {
          // filter duplicate values, but keep first and last
          return index === 0 || index === history.length - 1 || history[index - 1][field] !== history[index][field]
        })
        .map((data) => {
          return {
            x: parse(data.timestamp),
            y: data[field]
          }
        }),
      fill: false,
      backgroundColor: color,
      borderColor: color,
      lineTension: 0
    }
  }

  const handleRowClick = (e) => {
    const data = e.data.history
    setChartData({
      datasets: [
        buildDataSet(data, 'super_user_count', intl.formatMessage({ id: 'capabilities.admin' }), colors.filterColor12),
        buildDataSet(data, 'admin_count', intl.formatMessage({ id: 'capabilities.admin_light' }), colors.filterColor22),
        buildDataSet(data, 'reader_count', intl.formatMessage({ id: 'capabilities.user' }), colors.filterColor32),
        buildDataSet(data, 'other_count', intl.formatMessage({ id: 'capabilities.custom' }), colors.filterColor42)
      ]
    })
  }

  const exportCSV = () => {
    if (dataTableRef.current) {
      dataTableRef.current.exportCSV()
    }
  }

  return (
    <>
      <ToolPanel>
        <Monthpicker value={date} onChange={(e) => setDate(e.value)} readOnlyInput={true}/>
        <Spacer/>
        <Button icon="pi pi-file-excel" label={intl.formatMessage({ id: 'admin.export_csv' })} onClick={exportCSV}/>
      </ToolPanel>
      <Section>
        <DataTable
          ref={dataTableRef}
          className="flextable clickable-rows"
          value={customersProducts}
          paginator={true}
          rows={10}
          loading={isLoading}
          onRowClick={handleRowClick}>
          <Column field="organisation.name" header={intl.formatMessage({ id: 'common.name' })} sortable={true}
                  exportable={true}/>
          <Column field="organisation.address.Org_No" header={intl.formatMessage({ id: 'organisation.org-id' })}
                  sortable={true} exportable={true}/>
          <Column
            field="current.super_user_count"
            header={intl.formatMessage({ id: 'capabilities.admin' })}
            sortable={true}
            exportable={true}
          />
          <Column
            field="current.admin_count"
            header={intl.formatMessage({ id: 'capabilities.admin_light' })}
            sortable={true}
            exportable={true}
          />
          <Column field="current.reader_count" header={intl.formatMessage({ id: 'capabilities.user' })} sortable={true}
                  exportable={true}/>
          <Column
            field="current.other_count"
            header={intl.formatMessage({ id: 'capabilities.custom' })}
            sortable={true}
            exportable={true}
          />
        </DataTable>
      </Section>
      {chartData && (
        <Section>
          <Chart type="line" data={chartData} options={chartOptions}/>
        </Section>
      )}
    </>
  )
}
