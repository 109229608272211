import React, { useContext, useState } from 'react'
import { Field, Form } from 'Components/form'
import { Datepicker, Dropdown, Switch, TextInput } from 'Components/input'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { AuthContext, GrowlContext } from 'Context'
import useCachedGetData from 'Hooks/useCachedGetData'
import { format, parse } from 'date-fns'

const url = '/rapi/admin/chemicalsdsfilter'

export default ({ modalController, onSuccess }) => {
  const intl = useIntl()
  const { displayError, displaySuccess } = useContext(GrowlContext)
  const [, types] = useCachedGetData(url + '/types', [])
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useContext(AuthContext)
  const [reasonNotTrustworthyPhrasesLoading, reasonNotTrustworthyPhrases] = useCachedGetData('/rapi/admin/reasonnottrustworthyphrases/' + user.user.language, [])
  const filter = modalController.data && {
    ...modalController.data,
    binding_from: modalController.data.binding_from && parse(modalController.data.binding_from)
  }
  const initalValue = filter || {
    name: '',
    type: null,
    binding_from: null,
    reason_not_trustworthy_phrase_id: null,
    enabled: true
  }

  const options = React.useMemo(() => {
    return types.map((type) => ({ value: type, label: intl.formatMessage({ id: 'chemicals.sds-filter-' + type }) }))
  }, [intl, types])

  const onSubmit = async (formData) => {
    setIsLoading(true)
    try {
      let result
      const sendData = { ...formData }
      sendData.binding_from = formData.binding_from && format(formData.binding_from, 'YYYY-MM-DD')
      if (!filter) {
        result = await axios.post(url, sendData)
      } else {
        result = await axios.put(url + '/' + filter.id, sendData)
      }
      onSuccess(result.data.filter)
      displaySuccess(result.data.message)
      setIsLoading(false)
      modalController.close()
    } catch (e) {
      console.error(e)
      displayError(e)
      setIsLoading(false)
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <Field
        id="name"
        label={intl.formatMessage({ id: 'common.name' })}
        Component={TextInput}
        required={true}
        initialValue={initalValue.name}
      />
      <Field
        id="type"
        label={intl.formatMessage({ id: 'common.type' })}
        Component={Dropdown}
        options={options}
        required={true}
        initialValue={initalValue.type}
      />
      <Field
        id="binding_from"
        label={intl.formatMessage({ id: 'common.binding-from' })}
        Component={Datepicker}
        required={false}
        initialValue={initalValue.binding_from}
      />
      <Field
        id="enabled"
        label={intl.formatMessage({ id: 'common.enabled' })}
        Component={Switch}
        required={false}
        initialValue={initalValue.enabled}
      />
      <Field
        id="reason_not_trustworthy_phrase_id"
        label={intl.formatMessage({ id: 'chemicals.form.reason_not_trustworthy' })}
        Component={Dropdown}
        required={false}
        initialValue={initalValue.reason_not_trustworthy_phrase_id}
        options={reasonNotTrustworthyPhrases}
        disabled={reasonNotTrustworthyPhrasesLoading}
        appendTo={document.body}
      />
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </Form>
  )
}
