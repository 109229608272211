import React from 'react'
import { ToggleButton } from 'primereact/togglebutton'
import styled from 'styled-components'
import { Button } from 'Components/input'
import { FlexRow } from 'Components/layout'
import colors from 'Components/colors.scss'

const SplitButtonWrapper = styled(FlexRow)`
  &&& {
    border-radius: 20px 0 0 20px;
    overflow: hidden;

    ${Button} {
      margin-bottom: 0;
    }
  }
`

const SplitButtonLeft = styled(ToggleButton)`
  &&& {
    background-color: ${colors.defaultInteract};
    color: ${colors.textDark};
    white-space: nowrap;
    padding: 0.24em 15px;
    border: none;
    border-radius: 0;

    &:not(.p-disabled):not(.p-highlight):hover {
      background-color: ${colors.primaryFaded};
      color: ${colors.text};
    }

    &.p-highlight {
      background-color: ${colors.primary};
      color: ${colors.text};
    }

    &.p-focus {
      outline: none;
      box-shadow: none;
    }
  }
`

const SplitButtonRight = styled.div`
  &&& {
    background-color: ${colors.primary};
    border-radius: 0 20px 20px 0;
    color: ${colors.text};
    display: flex;
    border-left: 1px solid ${colors.white};

    &:hover:not(.p-disabled) {
      filter: brightness(110%);
    }

    ${Button} {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .pi {
      font-size: 20px;
    }
  }
`

export const SplitToggleButton = styled(({ modalTitle, contentText, icon, onClickRight, ...props }) => {
  return (
    <SplitButtonWrapper>
      <SplitButtonLeft {...props} />
      <SplitButtonRight>
        <Button icon={icon} onClick={onClickRight}/>
      </SplitButtonRight>
    </SplitButtonWrapper>
  )
})``
