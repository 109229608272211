import React from 'react'
import styled from 'styled-components'
import { Button, Switch, TextInput } from '../input'
import { useIntl } from 'react-intl'
import LocationUnitDropdown from 'Components/input/locationUnitDropdown'
import { FieldError } from 'Components/layout'

const Field = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > div {
    width: 20%;
    margin-right: 10px;
    max-width: 100px;

    > label {
      display: inline-block;
      margin-bottom: 2px;
    }

    .p-dropdown.p-dropdown.p-dropdown {
      min-width: 100px;
      height: 39px;
    }
  }

  .switch-container {
    width: 90px;
    margin-right: 0;
  }

  .trash {
    width: 50px;
    margin-right: 0;
    margin-top: 29px;

    button {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;

    > div {
      width: 100%;
      margin-bottom: 10px;
    }

    .trash {
      margin-top: 10px;
    }
  }

  ${FieldError} {
    width: auto;
    margin: 0;
  }
`

const PackageCenterInputContainer = styled.div`
  text-align: center;
`

const NewPackageRow = ({ id, value, onChange, validationError, remove, disableRemove }) => {
  const intl = useIntl()

  const setAmount = (input) => {
    let v = { ...value }
    v.amount = input
    onChange({ target: { value: v } })
  }

  const setOpened = (input) => {
    let v = { ...value }
    v.amount_opened = input
    onChange({ target: { value: v } })
  }

  const onBulkChange = (input) => {
    let v = { ...value }
    v.is_bulk = input
    onChange({ target: { value: v } })
  }

  const setPackageSize = (input) => {
    let v = { ...value }
    v.packaging_size = input
    onChange({ target: { value: v } })
  }

  const updateUnit = (input, unit) => {
    let v = { ...value }
    v.unit_id = input
    v.unit = unit
    v.unit.id = input
    onChange({ target: { value: v } })
  }

  return (
    <Field>
      {validationError && <FieldError/>}
      {
        <>
          <PackageCenterInputContainer className="switch-container">
            <label>{intl.formatMessage({ id: 'chemicals.bulk' })}</label>
            <Switch
              value={value.is_bulk}
              onChange={(e) => onBulkChange(e.target.value)}
              onLabel={intl.formatMessage({ id: 'common.yes' })}
              offLabel={intl.formatMessage({ id: 'common.no' })}
            />
          </PackageCenterInputContainer>
          {value.is_bulk ? (
            <PackageCenterInputContainer>
              <label>{intl.formatMessage({ id: 'chemicals.amount' })}</label>
              <TextInput
                id={id + '-amount'}
                keyfilter="pnum"
                type="number"
                value={value.amount}
                onChange={(e) => {
                  setAmount(e.target.value)
                }}
                className={validationError && validationError.amount ? 'ui-state-error' : ''}
              />
            </PackageCenterInputContainer>
          ) : (
            <PackageCenterInputContainer>
              <label>{intl.formatMessage({ id: 'chemicals.size' })}</label>
              <TextInput
                id={id + '-packaging_size'}
                keyfilter="pint"
                type="number"
                value={value.packaging_size}
                onChange={(e) => {
                  setPackageSize(e.target.value)
                }}
                className={validationError && validationError.packaging_size ? 'ui-state-error' : ''}
              />
            </PackageCenterInputContainer>
          )}
          <PackageCenterInputContainer>
            <label>{intl.formatMessage({ id: 'chemicals.unit' })}</label>
            <LocationUnitDropdown
              id={id + '-unit_id'}
              value={value.unit_id}
              onChange={(e, unit) => updateUnit(e.target.value, unit)}
              appendTo={document.body}
              className={validationError && validationError.unit_id ? 'ui-state-error' : ''}
            />
          </PackageCenterInputContainer>
          {!value.is_bulk && (
            <>
              <PackageCenterInputContainer>
                <label>{intl.formatMessage({ id: 'chemicals.opened' })}</label>
                <TextInput
                  id={id + '-amount_opened'}
                  keyfilter="pnum"
                  type="number"
                  value={value.amount_opened}
                  onChange={(e) => {
                    setOpened(e.target.value)
                  }}
                  className={validationError && validationError.amount_opened ? 'ui-state-error' : ''}
                />
              </PackageCenterInputContainer>
              <PackageCenterInputContainer>
                <label>{intl.formatMessage({ id: 'chemicals.unopened' })}</label>
                <TextInput
                  id={id + '-amount'}
                  keyfilter="pnum"
                  type="number"
                  value={value.amount}
                  onChange={(e) => {
                    setAmount(e.target.value)
                  }}
                  className={validationError && validationError.amount ? 'ui-state-error' : ''}
                />
              </PackageCenterInputContainer>
            </>
          )}
          <div className="trash">
            <Button primary onClick={remove} icon="pi pi-trash" disabled={disableRemove}/>
          </div>
        </>
      }
    </Field>
  )
}

export default NewPackageRow
