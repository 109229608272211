import * as React from 'react'
import axios from 'axios'
import { injectIntl } from 'react-intl'
import { ProgressSpinner } from 'primereact/progressspinner'
import _ from 'lodash'

import useGetData from 'Hooks/useGetData'
import { FormContext, FormContextProvider } from 'Components/form/formContext'
import { GrowlContext } from 'Context'
import { Modal, ResponsiveSidebar } from 'Components/readonly'
import useModalController from 'Hooks/useModalController'
import UploadProductSDBDialog from '../../chemicalList/dialogs/uploadProductSDBDialog'
import ReplaceProductSDBDialog from '../../chemicalList/dialogs/replaceProductSDBDialog'

import ProductEditSidebarHeader from './productEditSidebarHeader'
import ProductEditSidebarContent from './productEditSidebarContent'
import ProductSdsRevisionSidebarHeader from './productSdsRevisionSidebarHeader'
import ProductSdsRevisionSidebarContent from './productSdsRevisionSidebarContent'
import ProductSdsPreliminiarySidebarHeader from './productSdsPreliminarySidebarHeader'
import ProductSdsPreliminiarySidebarContent from './productSdsPreliminarySidebarContent'
import { setLocked } from '../../../Utils/functions'

const ProductEditSidebarInContext = injectIntl(({ productId, productStatus, updateProduct, isOpen, close, intl, ...props }) => {
  const formRefSv = React.useRef()
  const formRefEn = React.useRef()
  const formRefNo = React.useRef()
  const formRefDk = React.useRef()
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [isLoading, setIsLoading] = React.useState(true)
  const { formData, setFormData, validateForm } = React.useContext(FormContext)
  const [reviewState, setReviewState] = React.useState([])
  const [uploadModalController] = useModalController({ title: intl.formatMessage({ id: 'sds.upload_sdb' }) })
  const [replaceModalController] = useModalController({ title: intl.formatMessage({ id: 'sds.replace_sdb' }) })
  const [isSaving, setIsSaving] = React.useState(false)
  const [showValidationMessages, setShowValidationMessages] = React.useState(false)
  const [isChemicalSdsValid, setIsChemicalSdsValid] = React.useState(false)
  const [skipSimilarSearchView, setSkipSimilarSearchView] = React.useState(false)
  const [showClickOutsideError, setShowClickOutsideError] = React.useState(false)

  const onClose = () => {
    close();
    setShowClickOutsideError(false);
  }
  

  React.useEffect(() => {
    setShowValidationMessages(false)
    setSkipSimilarSearchView(false)
    const source = axios.CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const result = await axios('/rapi/chemicals/' + productId + '/edit', { cancelToken: source.token })
        setFormData(result.data)
      } catch (error) {
        if (axios.isCancel(error)) {
          return
        }
        setFormData(null)
        displayError(error)
      }
      setIsLoading(false)
    }

    if (productId) {
      fetchData()
    } else if (productId === null) {
      setFormData(null)
      setIsLoading(true)
    }

    return function () {
      source.cancel('Cancelling in cleanup')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId])

  const updateEditProduct = (updatedProduct) => {
    updateProduct(updatedProduct)
    setFormData(updatedProduct)
  }

  const [loadingTranslations, messages] = useGetData('/rapi/translations')

  const save = async (preliminary) => {
    setShowClickOutsideError(false);
    setShowValidationMessages(true)
    let errors = preliminary
      ? validateForm(
          formRefSv.current
            .getChildren(['chemical_ingredients'])
            .concat(formRefEn.current.getChildren(['chemical_ingredients']))
            .concat(formRefNo.current.getChildren(['chemical_ingredients']))
            .concat(formRefDk.current.getChildren(['chemical_ingredients']))
        )
      : validateForm(
          formRefSv.current
            .getChildren()
            .concat(formRefEn.current.getChildren())
            .concat(formRefNo.current.getChildren())
            .concat(formRefDk.current.getChildren())
        )
    if (!_.isEmpty(errors) || !isChemicalSdsValid) {
      return
    }
    setIsSaving(true)
    try {
      const result = await axios.put('/rapi/chemicals/' + productId + (preliminary ? '/preliminary' : ''), formData)
      setIsSaving(false)
      updateProduct(result.data.chemical)
      setLocked(productId, 0)
      displaySuccess(result.data.message)
      onClose()
    } catch (error) {
      setIsSaving(false)
      displayError(error, intl)
      setLocked(productId, 0)
    }
  }

  const showSimilarSearchView = formData && formData.status === 'PRELIMINARY' && !skipSimilarSearchView

  const header =
    !isLoading &&
    productId &&
    formData &&
    (showSimilarSearchView ? (
      <ProductSdsPreliminiarySidebarHeader
        isLocked={formData['is_locked'] ? true : false}
        productId={productId}
        productStatus={productStatus}
        close={onClose}
        skip={() => setSkipSimilarSearchView(true)}
        setShowClickOutsideError={setShowClickOutsideError}
      />
    ) : formData.status === 'REVISION' ? (
      <ProductSdsRevisionSidebarHeader
        isLocked={formData['is_locked'] ? true : false}
        productId={productId}
        productStatus={productStatus}
        updateProduct={updateEditProduct}
        reviewState={reviewState}
        close={onClose}
      />
    ) : (
      <ProductEditSidebarHeader 
        close={onClose}
        save={save}
        isSaving={isSaving} 
        isLocked={formData['is_locked'] ? true : false}
      />
    ))

  const content =
    isLoading || loadingTranslations || !productId || !formData ? (
      <ProgressSpinner />
    ) : showSimilarSearchView ? (
      <ProductSdsPreliminiarySidebarContent productId={productId} productStatus={productStatus} close={onClose} updateProduct={updateEditProduct} />
    ) : formData.status === 'REVISION' ? (
      <ProductSdsRevisionSidebarContent productId={productId} productStatus={productStatus} reviewState={reviewState} setReviewState={setReviewState} />
    ) : (
      <ProductEditSidebarContent
        productId={productId}
        productStatus={productStatus}
        formRefSv={formRefSv}
        formRefEn={formRefEn}
        formRefNo={formRefNo}
        formRefDk={formRefDk}
        messages={messages}
        isUploadSdsModalOpen={uploadModalController.isOpen}
        isReplaceSdsModalOpen={replaceModalController.isOpen}
        reviewState={reviewState}
        onUploadNewSds={(updateSdsList) =>
          uploadModalController.open({
            data: {
              productId: productId,
              isAutoApproveSds: false,
              updateSdsList: updateSdsList
            }
          })
        }
        onReplaceNewSds={(updateSdsList) =>
          replaceModalController.open({
            data: {
              productId: productId,
              isAutoApproveSds: false,
              updateSdsList: updateSdsList
            }
          })
        }
        showValidationMessages={showValidationMessages}
        setIsChemicalSdsValid={setIsChemicalSdsValid}
        showClickOutsideError={showClickOutsideError}
      />
    )

  return (
    <>
      <Modal modalController={uploadModalController} ContentComponent={UploadProductSDBDialog} />
      <Modal modalController={replaceModalController} ContentComponent={ReplaceProductSDBDialog} />
      <ResponsiveSidebar
        blockScroll={true}
        position="right"
        visible={isOpen}
        onHide={() => {
          if (!showClickOutsideError) {
            setShowClickOutsideError(true)
          }
        }}
        showCloseIcon={false}
        iconsTemplate={() => header}>
        {content}
      </ResponsiveSidebar>
    </>
  )
})

const ProductEditSidebar = ({ product, updateProduct, close, ...props }) => {
  return (
    <FormContextProvider initialFormData={null}>
      <ProductEditSidebarInContext
        productId={product ? product.id : null}
        productStatus={product ? product.status : null}
        updateProduct={updateProduct}
        close={close}
        {...props}
      />
    </FormContextProvider>
  )
}

export default ProductEditSidebar
