import colors from 'Components/colors.scss'
import { Button } from 'Components/input'
import { FlexColumn, FlexColumnCenter, FlexRow } from 'Components/layout'
import { Avatar, Divider, Panel, Sidebar } from 'Components/readonly'
import styled from 'styled-components'
import DisabledServiceBackground from './assets/bg-product-inactive.png'
import bg from './assets/bg.jpg'
import { ReactComponent as InfoIcon } from './assets/ico-info.svg'

export const InfoButton = styled(Button)`
  &&& {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: auto;

    &:focus {
      box-shadow: none;
    }

    .p-button-text {
      padding: 0;
    }
  }
`

export const DisabledInfoIcon = styled(InfoIcon)`
  fill: black;
  opacity: 0.5;

  width: 30px;
  height: 30px;
`

export const Wrapper = styled(FlexRow)`
  display: flex;
  flex: 1 1 1px;
  background-color: #2c3036;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;

  @media screen and (max-width: 1600px) {
    display: block;
  }
`

export const SidebarWrapper = styled.div`
  ${Panel} {
    margin-bottom: 20px;
  }

  h2 {
    padding: 20px 0 10px 0;
  }
`

export const SmallLeftSidebar = styled(Sidebar)`
  &&& {
    margin-top: 66px;
    width: 19%;
    min-width: 300px;
    background-color: ${colors.darkerPanel};

    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }
`

export const ServicesContainer = styled(FlexColumnCenter)`
  padding-bottom: 30px;
  flex: 3 1 1px;

  @media screen and (max-width: 1600px) {
    display: block;
  }
`

export const Services = styled(FlexRow)`
  padding: 100px 2% 0 2%;

  > * {
    margin: 0 10px;
  }

  @media screen and (max-width: 1280px) {
    flex-direction: column;

    > * {
      margin: 0 0 20px 0;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 100px 0 0 0;
  }
`

export const ActionCardContainer = styled.div`
  min-width: 300px;
  max-width: 350px;
  display: flex;
  flex-direction: column;

  > * {
    margin: 0 0 10px 0;
  }

  @media screen and (max-width: 1280px) {
    max-width: none;
  }
`

export const ServiceContainer = styled.div`
  min-width: 420px;

  > :not(:last-of-type) {
    margin: 0 20px 0 0;
  }

  @media screen and (max-width: 1280px) {
    min-width: 300px;
  }
`

export const ActionCard = styled.div`
  background-color: ${colors.white};
  color: ${colors.lightBlack};
  padding: 30px 30px 20px 30px;
  flex: 1 1 auto;

  h1 {
    color: ${colors.lightBlack};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  ${Button} {
    position: relative;
  }

  ${Avatar} {
    margin: -100px auto 15px auto;
    border: 5px solid white;
  }
`

export const NotificationButtonWrapper = styled(FlexColumn)`
  padding-top: 40px;
`

export const AbsoluteAnchor = styled.div`
  position: relative;
`

export const Bell = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: linear-gradient(90deg, ${colors.moduleGradient1}, ${colors.moduleGradient2});
    padding: 6px;
    border-radius: 51px;
    width: 40px;
    height: 40px;
    top: -20px;
    left: 20px;
    z-index: 2;
    color: ${colors.white};

    > .pi {
      font-size: 26px;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
`

export const SupportCard = styled.div`
  padding: 30px;
  background-color: ${colors.lightBackgroundColor};
  margin: 0;
  flex: 0 0 auto;

  > div > div:first-child {
    margin-bottom: 10px;
  }

  .pi {
    font-size: 2.5em;
    vertical-align: middle;
    margin-right: 10px;
  }

  a {
    color: ${colors.lightHover};
  }

  img {
    width: 26px;
    margin-right: 15px;
  }
`

export const Service = styled.div`
  & a:hover {
    text-decoration: none;
  }
`

export const ServiceIcon = styled.div`
  position: absolute;
  top: 5px;
  left: -60px;

  > img {
    width: 50px;
    height: 50px;
  }

  @media screen and (max-width: 340px) {
    left: -30px;
    > img {
      width: 20px;
      height: 20px;
    }
  }
`

export const ServicePanel = styled.div`
  position: relative;

  background: ${(props) =>
  props.disabled
    ? `rgba(255, 255, 255, 0.1) url(${DisabledServiceBackground})`
    : `linear-gradient(90deg, ${colors.moduleGradient1}, ${colors.moduleGradient2})`};
  background-size: cover;

  cursor: ${(props) => (props.disabled || props.not_authorized ? 'auto' : 'pointer')};

  padding: 60px 20px 60px 100px;
  margin-bottom: 10px;
  transition: box-shadow 0.3s;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.349019607843137);

  &:hover {
    box-shadow: ${(props) =>
  props.disabled || props.not_authorized
    ? 'box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.349019607843137)'
    : `15px 15px 10px rgba(0, 0, 0, 0.349019607843137)`};
  }

  h2 {
    font-family: 'Exo 2';
    font-weight: normal;
    font-size: 26px;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    color: ${(props) => (props.disabled ? colors.disabledWhite : colors.text)};
  }

  .service-type {
    font-size: 18px;
    color: ${(props) => (props.disabled ? colors.disabledWhite : colors.black)};
  }

  .service-author {
    font-size: 14px;
    font-weight: 0;
    color: ${(props) => (props.disabled ? colors.disabledWhite : colors.white)};
  }

  @media screen and (max-width: 640px) {
    padding: 20px 20px 20px 80px;

    .service-type {
      font-size: 14px;
    }

    .service-author {
      font-size: 12px;
    }

    h2 {
      font-size: 23px;
    }
  }

  @media screen and (max-width: 340px) {
    padding: 20px 20px 20px 40px;

    .service-type {
      font-size: 14px;
    }

    .service-author {
      font-size: 12px;
    }

    h2 {
      font-size: 23px;
    }
  }
`

export const DemoPanel = styled(Panel)`
  background-color: ${colors.darkestPanel};
  padding: 20px;
  width: 420px;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h3 {
    margin: 0 0 20px 0;
  }
`

export const StartPageButton = styled(Button)`
  &&& {
    margin: 0;
    background: linear-gradient(to right, #ea7726, #ce622a);
    border: 1px solid transparent;
    color: ${colors.white};

    &:hover:not(.p-disabled) {
      box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.349019607843137);
      border: 1px solid transparent;
      color: ${colors.white};
    }
  }
`

export const ChartPanel = styled(Panel)`
  background-color: ${colors.darkestPanel};
  padding: 0;
  flex: 1 1 auto;

  h3 {
    text-align: center;
    padding: 20px 0 15px 0;
  }

  ${Divider} {
    margin-top: 5px;
  }
`

export const StartChartContainer = styled.div`
  padding: 10px 5px 20px 20px;
`

export const News = styled(FlexRow)`
  display: flex;
  flex: 0.7 1 1px;

  @media screen and (max-width: 1600px) {
    display: block;
  }
`

export const NewsContainer = styled(FlexColumn)`
  flex: 1 1 1px;
  background-color: ${colors.darkerPanel};
  padding-top: 95px;
  padding-bottom: 60px;

  > h2 {
    padding-top: 0;
    padding-left: 20px;
  }

  > p {
    padding: 0 20px 0 20px;
    color: ${colors.lightHover};
  }

  .moreNewsButton {
    display: flex;
    padding: 20px 20px 50px 20px;
    justify-content: center;
    background-color: #212025;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  @media screen and (max-width: 1600px) {
    flex: 1 1 auto;
    padding-top: 20px;
  }
`
