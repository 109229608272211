import React from 'react'
import styled from 'styled-components'

import { Panel } from 'Components/readonly'
import { FormattedMessage } from 'react-intl'
import { HasService } from 'Components/capabilities'
import { TextContainer } from 'Components/layout'

const helpData = {
  superUser: {
    chemical_management: ['tags', 'own-artno-synonym', 'manage-inventory']
  },
  adminUser: {
    chemical_management: [
      'manage-products',
      'manage-product-amounts',
      'upload-sds',
      'manage-locations',
      'use-tags',
      'use-artno-synonyms',
      'manage-riskassessments',
      'do-inventory'
    ],
    sds_management: ['order', 'manage-orders', 'approve-sds', 'manage-weblinking']
  },
  reader: {
    chemical_management: ['reading-rights', 'download-sds'],
    sds_management: ['reading-rights', 'download-sds-marking']
  }
}

export const listCaps = (userType, capModule) => (
  <ul>
    {helpData[userType][capModule].map((cap) => (
      <li key={`capabilities.${capModule}.${cap}`}>
        <FormattedMessage id={`capabilities.${capModule}.${cap}`}/>
      </li>
    ))}
  </ul>
)

const RoleHelpPanel = (props) => {
  return (
    <Panel className={props.className}>
      <TextContainer>
        <h2>
          <FormattedMessage id="capabilities.admin"/>
        </h2>
        <p>
          <FormattedMessage id="capabilities.admin_light"/> +
        </p>
        <p>
          <FormattedMessage id="capabilities.add_user_desc"/>
        </p>
        <HasService service="chemical_list">
          <h3>
            <FormattedMessage id="chemicals.module"/>
          </h3>
          {listCaps('superUser', 'chemical_management')}
        </HasService>
        <h2>
          <FormattedMessage id="capabilities.admin_light"/>
        </h2>
        <p>
          <FormattedMessage id="capabilities.user"/> +
        </p>
        <HasService service="sds">
          <h3>
            <FormattedMessage id="sds.module"/>
          </h3>
          {listCaps('adminUser', 'sds_management')}
        </HasService>
        <HasService service="chemical_list">
          <h3>
            <FormattedMessage id="chemicals.module"/>
          </h3>
          {listCaps('adminUser', 'chemical_management')}
        </HasService>
        <h2>
          <FormattedMessage id="capabilities.user"/>
        </h2>
        <p>
          <FormattedMessage id="capabilities.reader"/>
        </p>
        <HasService service="sds">
          <h3>
            <FormattedMessage id="sds.module"/>
          </h3>
          {listCaps('reader', 'sds_management')}
        </HasService>
        <HasService service="chemical_list">
          <h3>
            <FormattedMessage id="chemicals.module"/>
          </h3>
          {listCaps('reader', 'chemical_management')}
        </HasService>
      </TextContainer>
    </Panel>
  )
}

export default styled(RoleHelpPanel)``
