import React, { useContext, useEffect } from 'react'
import useModalController from 'Hooks/useModalController'
import { Modal } from 'Components/readonly'
import { FormattedMessage } from 'react-intl'
import { TermsModalContent } from './termsModalContent'
import { AuthContext } from 'Context'

export const TermsModal = () => {
  const [modalController] = useModalController({ title: <FormattedMessage id='auth.terms-modal-title'/> })

  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (!modalController.isOpen) {
      const modalData = {}

      // Skip user consents for root users
      if (user.isSuperAdmin) {
        return
      }

      if (!user.user_consents.integrity_policy || user.latest_consents.integrity_policy.version > user.user_consents.integrity_policy) {
        modalData.policyUrl = user.latest_consents.integrity_policy.url
      }
      if (!user.user_consents.user_terms || user.latest_consents.user_terms.version > user.user_consents.user_terms) {
        modalData.termsUrl = user.latest_consents.user_terms.url
      }

      if (modalData.policyUrl || modalData.termsUrl) {
        modalController.open(modalData)
      }
    }
  }, [user]) // eslint-disable-line

  return (
    <>
      <Modal modalController={modalController} ContentComponent={TermsModalContent} showCloseIcon={false}
             allowEscClose={false}/>
    </>
  )
}
