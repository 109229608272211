import React, { useContext, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { animated, Spring } from 'react-spring/renderprops'

import { FlexColumnCenter } from 'Components/layout'
import { Button } from 'Components/input'
import { Panel, RichTextDisplay } from 'Components/readonly'
import { GrowlContext } from 'Context'

const WrapperPanel = styled(Panel)`
  &&& {
    padding: 10px 20px 0 20px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;

    h3 {
      padding-right: 30px;
    }
  }
`

const ToggleButton = styled(Button)`
  & {
    margin: 0 !important;
  }
`

const ToggleText = styled.p`
  margin: 0;
  min-height: 50px;
`

const ToggleTextContainer = styled.div`
  .item {
    overflow: hidden;
  }
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 3px;
  right: 0;
`

export const DynamicMessage = ({ message, onDelete }) => {
  const { displayError } = useContext(GrowlContext)
  const [isOpen, setIsOpen] = useState(false)
  const [deleted, setDeleted] = useState(false)

  return (
    <CSSTransition in={!deleted} timeout={200} classNames="fade" unmountOnExit={true} appear={true}>
      <WrapperPanel
        dark
        onClick={(e) => {
          setIsOpen(!isOpen)
          e.preventDefault()
          e.stopPropagation()
        }}>
        <CloseButton
          className="deleteMessage"
          onClick={async (e) => {
            setDeleted(true)
            e.preventDefault()
            e.stopPropagation()
            try {
              await axios.delete('/rapi/organisationmessages/' + message.id)
              onDelete(message)
            } catch (error) {
              displayError(error)
              setDeleted(false)
            }
          }}
          icon="pi pi-times"
        />
        <h3 style={{ paddingTop: '0' }}>{message.heading}</h3>
        <Spring
          native
          force
          config={{ tension: 1500, friction: 100, precision: 1 }}
          from={{ height: isOpen ? 50 : 'auto' }}
          to={{ height: isOpen ? 'auto' : 50 }}>
          {(props) => (
            <ToggleTextContainer>
              <animated.div className="item" style={props}>
                <ToggleText>
                  <RichTextDisplay value={message.message}/>
                </ToggleText>
              </animated.div>
            </ToggleTextContainer>
          )}
        </Spring>
        <FlexColumnCenter>
          <ToggleButton className="toggleMore" icon={'pi pi-chevron-' + (isOpen ? 'up' : 'down')} fill/>
        </FlexColumnCenter>
      </WrapperPanel>
    </CSSTransition>
  )
}
