import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { FlexColumn } from 'Components/layout'
import { Tooltip } from 'Components/readonly'
import { AbsoluteAnchor, DisabledInfoIcon, InfoButton, Service, ServiceIcon, ServicePanel } from './startPageStyle'

import IconChemicalManagement from './assets/ico-cm.svg'

const Wrapper = styled(FlexColumn)`
  height: 100%;
`

export default () => {
  const intl = useIntl()

  return (
    <Wrapper>
      <Service>
        <ServicePanel not_authorized>
          <Tooltip content={intl.formatMessage({ id: 'capabilities.not_authorized' })}>
            <InfoButton link label={<DisabledInfoIcon/>}/>
          </Tooltip>
          <AbsoluteAnchor>
            <ServiceIcon>
              <img src={IconChemicalManagement} alt="Chemical management"/>
            </ServiceIcon>
            <span className="service-type">EASY</span>
            <h2>
              <FormattedMessage id="chemicals.module"/>
            </h2>
            <span className="service-author">by KemRisk</span>
          </AbsoluteAnchor>
        </ServicePanel>
      </Service>
    </Wrapper>
  )
}
