import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import colors from '../colors.scss'
import styled from 'styled-components'
import classNames from 'classnames'

const Dropdown = styled.div`
  &&& {
    background-color: ${colors.white};
    border-radius: 20px;
    border: 2px solid #fff;
    padding: 0 20px;
    min-width: auto;
    width: 100%;

    &:hover {
      border-color: #fff;
    }

    &.p-focus {
      box-shadow: none;
      border-color: ${colors.primary};
    }

    .p-dropdown-trigger {
      background-color: transparent;
    }

    .p-dropdown-panel {
      border-radius: 4px;
    }

    .p-dropdown-label {
      background-color: ${colors.white};
      color: #000;
      margin: 0;
      min-height: 32px;
      text-overflow: ellipsis;
      padding-left: 0;
      padding-right: 5px;
    }

    &.ui-state-error {
      border-color: ${colors.validationFailedFieldMarker};
    }
  }
`

const DropDownPanelContainer = styled.div`
  position: relative;

  > label {
    display: block;
    margin-bottom: 5px;
  }
`

const Panel = styled.div`
  position: absolute;
  left: 0;
  z-index: 10000;
  display: none;
  overflow-y: auto;
  overflow-x: hidden;

  > div {
    &.upwards {
      position: absolute;
      bottom: 0;
    }

    width: 100%;
  }

  @media screen and (max-width: 640px) {
    overflow-y: scroll;
  }
`

export const DropDownPanel = forwardRef(({
                                           children,
                                           value,
                                           isLoading,
                                           label,
                                           appendToBody,
                                           width,
                                           validationError
                                         }, ref) => {
  const [open, setOpen] = useState(false)
  const panel = useRef()
  const container = useRef()
  const dropdown = useRef()

  const toggle = (e) => {
    setOpen(!open)
  }

  useImperativeHandle(ref, () => ({
    hide() {
      setOpen(false)
    },
    show() {
      setOpen(true)
    }
  }))

  const listener = (event) => {
    if (!panel.current || panel.current.contains(event.target) || !dropdown.current || dropdown.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const updateMaxHeight = () => {
    panel.current.style.maxHeight = window.innerHeight - container.current.getBoundingClientRect().bottom - 10 + 'px'
  }

  useEffect(() => {
    if (open) {
      const containerPos = container.current.getBoundingClientRect()
      let uppwards = window.innerHeight - containerPos.bottom < 100 && containerPos.top > 100

      if (uppwards) {
        panel.current.children[0].className = 'upwards'
        if (appendToBody) {
          panel.current.style.top = 10 + 'px'
          panel.current.style.left = containerPos.left + 'px'
          panel.current.style.height = containerPos.top - 10 + 'px'
        } else {
          panel.current.style.top = -containerPos.top + 'px'
          panel.current.style.height = containerPos.top + 'px'
        }
      } else {
        panel.current.children[0].className = ''
        if (appendToBody) {
          panel.current.style.top = containerPos.top + containerPos.height + 'px'
          panel.current.style.left = containerPos.left + 'px'
          panel.current.style.maxHeight = window.innerHeight - containerPos.bottom - 10 + 'px'
          window.addEventListener('resize', updateMaxHeight)
        } else {
          panel.current.style.top = containerPos.height + 'px'
        }
      }

      panel.current.style.width = containerPos.width + 'px'
      panel.current.style.display = 'block'
      document.addEventListener('mousedown', listener)
    } else {
      panel.current.style.display = 'none'
      document.removeEventListener('mousedown', listener)
      window.removeEventListener('resize', updateMaxHeight)
    }
    return () => {
      document.removeEventListener('mousedown', listener)
      window.removeEventListener('resize', updateMaxHeight)
    }
  })

  const renderPanel = () => {
    return (
      <Panel ref={panel}>
        <div>{children}</div>
      </Panel>
    )
  }

  return (
    <DropDownPanelContainer ref={container}>
      {label && <label>{label}</label>}
      <Dropdown
        ref={dropdown}
        onClick={(e) => toggle(e)}
        className={classNames('p-dropdown p-component', { 'ui-state-error': validationError })}>
        <div className="p-hidden-accessible">
          <input type="text" role="listbox"/>
        </div>
        <label style={{ width: width }} className="p-dropdown-label p-inputtext">
          {isLoading ? <i className="pi pi-spinner pi-spin"/> : value}
        </label>
        <div className="p-dropdown-trigger">
          <span className="p-dropdown-trigger-icon pi pi-chevron-down p-clickable"></span>
        </div>
      </Dropdown>
      {open && appendToBody ? ReactDOM.createPortal(renderPanel(), document.body) : renderPanel()}
    </DropDownPanelContainer>
  )
})
