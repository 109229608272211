import React from 'react'
import { FormattedMessage } from 'react-intl'
import { H3Line, Section, TextContainer } from 'Components/layout'
import { RichTextDisplay } from 'Components/readonly'

export default ({ product }) => {
  // Don't show info for preliminary products
  if (product.status === 'PRELIMINARY') {
    return (
      <Section>
        <FormattedMessage id="chemicals.no-preliminary-info"/>
      </Section>
    )
  }

  return (
    <TextContainer>
      <H3Line>
        <FormattedMessage id="chemicals.measures"/>
      </H3Line>
      <h4>
        <FormattedMessage id="chemicals.generic"/>
      </h4>
      <p>
        <RichTextDisplay id="first_aid_general" value={product.first_aid_general}/>
      </p>
      <h4>
        <FormattedMessage id="chemicals.at_inhalation"/>
      </h4>
      <p>
        <RichTextDisplay id="first_aid_inhalation" value={product.first_aid_inhalation}/>
      </p>
      <h4>
        <FormattedMessage id="chemicals.at_eyecontact"/>
      </h4>
      <p>
        <RichTextDisplay id="first_aid_eye_contact" value={product.first_aid_eye_contact}/>
      </p>
      <h4>
        <FormattedMessage id="chemicals.at_skincontact"/>
      </h4>
      <p>
        <RichTextDisplay id="first_aid_skin_contact" value={product.first_aid_skin_contact}/>
      </p>
      <h4>
        <FormattedMessage id="chemicals.at_ingestion"/>
      </h4>
      <p>
        <RichTextDisplay id="first_aid_ingestion" value={product.first_aid_ingestion}/>
      </p>

      <H3Line>
        <FormattedMessage id="chemicals.view.symptom"/>
      </H3Line>
      <h4>
        <FormattedMessage id="chemicals.generic"/>
      </h4>
      <p>
        <RichTextDisplay id="symptom_general" value={product.symptom_general}/>
      </p>
      <h4>
        <FormattedMessage id="chemicals.at_inhalation"/>
      </h4>
      <p>
        <RichTextDisplay id="symptom_inhalation" value={product.symptom_inhalation}/>
      </p>
      <h4>
        <FormattedMessage id="chemicals.at_eyecontact"/>
      </h4>
      <p>
        <RichTextDisplay id="symptom_eye_contact" value={product.symptom_eye_contact}/>
      </p>
      <h4>
        <FormattedMessage id="chemicals.at_skincontact"/>
      </h4>
      <p>
        <RichTextDisplay id="symptom_skin_contact" value={product.symptom_skin_contact}/>
      </p>
      <h4>
        <FormattedMessage id="chemicals.at_ingestion"/>
      </h4>
      <p>
        <RichTextDisplay id="symptom_consumption" value={product.symptom_consumption}/>
      </p>

      <H3Line>
        <FormattedMessage id="chemicals.view.immediate_medical_attention"/>
      </H3Line>
      <p id="immediate_medical_attention">
        <RichTextDisplay value={product.immediate_medical_attention}/>
      </p>
    </TextContainer>
  )
}
