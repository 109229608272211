import axios from 'axios'
import { withRouter } from 'react-router-dom'

import { AuthContext } from 'Context'
import { useContext } from 'react'

const AxiosInteceptor = ({ history }) => {
  const { setUnauthenticated } = useContext(AuthContext)

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.config && error.config.url.startsWith('https://www.kemrisk.se/wp-json')) {
        console.debug('interceptor ignoring error from www.kemrisk.se')
        return
      }

      if (error.response && error.response.status === 401) {
        console.log('intercepted 401')
        if (error.response.config.url !== '/rapi/login') {
          setUnauthenticated()
          history.push('/sessionEnded')
        }
        if (error.response.data.message === 'Unauthenticated.') {
          error.response.data.message = null
        }
        console.log(error.response.data)
      } else if (error.response && error.response.status === 419) {
        console.log('intercepted 419')
        setUnauthenticated()
        history.push('/sessionEnded')
      } else if (error.response && error.response.status === 503) {
        console.log('intercepted 503')
        setUnauthenticated()
        history.push('/maintenance')
      }
      return Promise.reject(error)
    }
  )

  return null
}

export default withRouter(AxiosInteceptor)
