import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Column } from 'primereact/column'
import styled from 'styled-components'

import { Field, FormContext } from 'Components/form'
import { Switch, TextInput } from 'Components/input'
import { FlexColumn, Section } from 'Components/layout'
import { DataTable } from 'Components/readonly'
import colors from 'Components/colors.scss'
import { LocationInventoryHeader } from './locationInventoryHeader'

const NumberInputWrapper = styled.div`
  max-width: 80px;

  .ui-state-error input {
    border-color: ${colors.validationFailedFieldMarker} !important;
  }
`

export const isNewProduct = (row) => {
  return row.org_amount === null || row.org_amount === undefined
}

export default ({ chemical, inventoryingChange, done, inventoryProp, showValidationErrors, updateValidation }) => {
  const intl = useIntl()
  const { getFieldValue } = useContext(FormContext)

  const createFormName = (index, field) => inventoryProp + '[' + index + '].' + field

  // Packaging table bodies
  const packingBody = (row) =>
    row.is_bulk ? intl.formatMessage({ id: 'chemicals.bulk' }) + ', ' + row.unit.label : row.packaging_size + ' ' + row.unit.label

  const showAmountsAsText = (is_bulk, unit, amount, amount_opened) => {
    if (is_bulk) {
      return amount + ' ' + unit
    }

    return (
      <>
        <div style={{ display: 'flex' }}>
          <FlexColumn style={{ flex: '0 0 auto', marginRight: '10px' }}>
            <Section>
              <FormattedMessage id="chemicals.opened"/>
            </Section>
            {amount_opened}
          </FlexColumn>
          <FlexColumn>
            <Section>
              <FormattedMessage id="chemicals.unopened"/>
            </Section>
            {amount}
          </FlexColumn>
        </div>
      </>
    )
  }

  const currentAmountBody = (row) =>
    isNewProduct(row)
      ? intl.formatMessage({ id: 'inventory.new-package' })
      : showAmountsAsText(row.is_bulk, row.unit.label, row.org_amount, row.org_amount_opened)

  const updateAmountBody = (data, row) => {
    const rowArchived = getFieldValue(createFormName(row.rowIndex, 'to_be_archived'))

    if (inventoryingChange === 'archive_all' || rowArchived) {
      return (
        <span>
          <FormattedMessage id="inventory.will-be-archived"/>
        </span>
      )
    }

    if (inventoryingChange === 'without_change') {
      return currentAmountBody(data)
    }

    const amountId = createFormName(row.rowIndex, 'amount')
    const amountOpenedId = createFormName(row.rowIndex, 'amount_opened')

    const isEmpty = (v) => v === null || v === undefined || v === ''
    const amount = getFieldValue(amountId)
    const amountOpened = getFieldValue(amountOpenedId)
    const amountValidationError = showValidationErrors && isEmpty(amount)
    const amountOpenedValidationError = showValidationErrors && isEmpty(amountOpened)

    if (done) {
      return showAmountsAsText(data.is_bulk, data.unit.label, amount, amountOpened)
    }

    return data.is_bulk ? (
      <FlexColumn>
        <Section>
          <FormattedMessage id="chemicals.amount"/>
        </Section>
        <NumberInputWrapper>
          <Field
            id={amountId}
            Component={TextInput}
            keyfilter="pnum"
            type="number"
            className={amountValidationError && 'ui-state-error'}
            onChange={updateValidation}
          />
        </NumberInputWrapper>
      </FlexColumn>
    ) : (
      <div style={{ display: 'flex' }}>
        <FlexColumn style={{ flex: '0 0 auto', marginRight: '10px' }}>
          <Section>
            <FormattedMessage id="chemicals.opened"/>
          </Section>
          <NumberInputWrapper>
            <Field
              id={amountOpenedId}
              Component={TextInput}
              keyfilter="pnum"
              type="number"
              className={amountOpenedValidationError && 'ui-state-error'}
              onChange={updateValidation}
            />
          </NumberInputWrapper>
        </FlexColumn>
        <FlexColumn>
          <Section>
            <FormattedMessage id="chemicals.unopened"/>
          </Section>
          <NumberInputWrapper>
            <Field
              id={amountId}
              Component={TextInput}
              keyfilter="pnum"
              type="number"
              className={amountValidationError && 'ui-state-error'}
              onChange={updateValidation}
            />
          </NumberInputWrapper>
        </FlexColumn>
      </div>
    )
  }

  const archiveBody = (data, row) => {
    if (inventoryingChange === 'archive_all') {
      return (
        <Switch
          value={true}
          disabled={true}
          onLabel={intl.formatMessage({ id: 'common.yes' })}
          offLabel={intl.formatMessage({ id: 'common.no' })}
        />
      )
    }

    return (
      <Field
        id={createFormName(row.rowIndex, 'to_be_archived')}
        Component={Switch}
        disabled={done}
        onLabel={intl.formatMessage({ id: 'common.yes' })}
        offLabel={intl.formatMessage({ id: 'common.no' })}
      />
    )
  }

  return (
    <Section key={chemical.id}>
      <LocationInventoryHeader chemical={chemical}/>
      <DataTable
        className="flextable"
        value={chemical.inventory}
        paginator={false}
        rowClassName={(row) => {
          return {
            disabled: inventoryingChange === 'archive_all' || row.to_be_archived || done
          }
        }}>
        <Column header={intl.formatMessage({ id: 'inventory.packaging' })} body={packingBody}/>
        <Column header={intl.formatMessage({ id: 'inventory.current-amount' })} body={currentAmountBody}
                style={{ width: '250px' }}/>
        <Column header={intl.formatMessage({ id: 'inventory.update-amount' })} body={updateAmountBody}
                style={{ width: '250px' }}/>
        <Column header={intl.formatMessage({ id: 'common.archive' })} body={archiveBody} style={{ width: '100px' }}/>
      </DataTable>
    </Section>
  )
}
