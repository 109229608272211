import { Field, FieldToggler, FormContext, FormInContext } from 'Components/form'
import {
  Checkbox,
  Datepicker,
  Dropdown,
  LocationTree,
  ProtectiveGearButtonGroup,
  RichTextArea,
  StepSlider,
  Switch,
  TextInput
} from 'Components/input'
import { SidebarHeading } from 'Components/layout'
import { Panel, RiskfactorVisualization } from 'Components/readonly'
import { CalculateRiskfactor } from 'Components/readonly/riskfactor_visualization'
import useGetData from 'Hooks/useGetData'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import AirPollution from './airPollution'
import WasteCodeInput, { wasteCodeValidation } from './wasteCodeInput'

export const StyledPanel = styled(Panel)`
  padding: 0 20px 20px 20px;

  ${Field} {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  > label {
    display: inline-block;
    color: #fefefe;
  }
`

const RiskfactorVisualizationWrapper = styled.div`
  display: block;

  ${RiskfactorVisualization} {
    margin-left: auto;
    margin-right: auto;
  }

  > label {
    margin-top: 25px;
    margin-bottom: 10px;
    display: inline-block;
    color: #fefefe;
  }
`

const Contact = styled.div`
  ${Field} {
    margin-top: 0px;
  }

  > label {
    margin-top: 25px;
    margin-bottom: 10px;
    display: inline-block;
    color: #fefefe;
  }
`

const RiskAssessmentForm = React.forwardRef(({ intl, product }, ref) => {
  const { formData } = React.useContext(FormContext)

  const [actionPlanResponsibleUserOptionsLoading, actionPlanResponsibleUserOptions] = useGetData(
    '/rapi/riskassessments/responsibleuseroptions',
    null
  )

  const formOptions = [
    { value: 'solid', label: intl.formatMessage({ id: 'riskassessment.form_options.solid' }) },
    { value: 'paste', label: intl.formatMessage({ id: 'riskassessment.form_options.paste' }) },
    { value: 'gas', label: intl.formatMessage({ id: 'riskassessment.form_options.gas' }) },
    { value: 'liquid', label: intl.formatMessage({ id: 'riskassessment.form_options.liquid' }) },
    { value: 'aerosol', label: intl.formatMessage({ id: 'riskassessment.form_options.aerosol' }) }
  ]

  const usageFrequencyOptions = [
    { value: null, label: intl.formatMessage({ id: 'common.not_relevant' }) },
    { value: 0, label: intl.formatMessage({ id: 'riskassessment.usage_frequency_options.daily' }) },
    { value: 1, label: intl.formatMessage({ id: 'riskassessment.usage_frequency_options.weekly' }) },
    { value: 2, label: intl.formatMessage({ id: 'riskassessment.usage_frequency_options.monthly' }) },
    { value: 3, label: intl.formatMessage({ id: 'riskassessment.usage_frequency_options.yearly' }) }
  ]
  const usageDurationOptions = [
    { value: null, label: intl.formatMessage({ id: 'common.not_relevant' }) },
    { value: 0, label: '<' + intl.formatMessage({ id: 'riskassessment.usage_duration_options.1m' }) },
    { value: 1, label: intl.formatMessage({ id: 'riskassessment.usage_duration_options.5-10m' }) },
    { value: 2, label: intl.formatMessage({ id: 'riskassessment.usage_duration_options.30-60m' }) },
    { value: 3, label: intl.formatMessage({ id: 'riskassessment.usage_duration_options.1-2h' }) },
    { value: 4, label: intl.formatMessage({ id: 'riskassessment.usage_duration_options.24h' }) }
  ]
  const harmfulProbabilityOptions = [
    { value: 0, label: intl.formatMessage({ id: 'riskassessment.harmful_probability_options.very_low' }) },
    { value: 1, label: intl.formatMessage({ id: 'riskassessment.harmful_probability_options.low' }) },
    { value: 2, label: intl.formatMessage({ id: 'riskassessment.harmful_probability_options.medium' }) },
    { value: 3, label: intl.formatMessage({ id: 'riskassessment.harmful_probability_options.high' }) },
    { value: 4, label: intl.formatMessage({ id: 'riskassessment.harmful_probability_options.very_high' }) }
  ]
  const harmfulSeverityOptions = [
    { value: 0, label: intl.formatMessage({ id: 'riskassessment.harmful_severity_options.none' }) },
    { value: 1, label: intl.formatMessage({ id: 'riskassessment.harmful_severity_options.temporary' }) },
    { value: 2, label: intl.formatMessage({ id: 'riskassessment.harmful_severity_options.light_permanent' }) },
    { value: 3, label: intl.formatMessage({ id: 'riskassessment.harmful_severity_options.permanent' }) },
    { value: 4, label: intl.formatMessage({ id: 'riskassessment.harmful_severity_options.death' }) }
  ]

  const riskFactor = React.useMemo(() => {
    return CalculateRiskfactor(formData.harmful_probability, formData.harmful_severity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  const formRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    getChildren() {
      return formRef.current.getChildren()
    }
  }))

  const { getFieldValue, setFieldValue } = React.useContext(FormContext)
  const actionPlanResponsibleUserId = getFieldValue('action_plan_responsible_user_id')
  React.useEffect(() => {
    const user = actionPlanResponsibleUserId && actionPlanResponsibleUserOptions?.find((u) => u.value === actionPlanResponsibleUserId)
    setFieldValue('action_plan_responsible_user', user && { id: actionPlanResponsibleUserId, name: user?.label })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionPlanResponsibleUserId, actionPlanResponsibleUserOptions])

  const RiskAssementTextField = (id) => {
    return (
      <Field
        id={id}
        label={intl.formatMessage({ id: 'riskassessment.' + id })}
        placeholder={intl.formatMessage({ id: 'riskassessment.' + id + '_placeholder', defaultMessage: ' ' })}
        Component={TextInput}
        required={true}
      />
    )
  }

  return (
    <FormInContext ref={formRef}>
      {
        <SidebarHeading>{intl.formatMessage({ id: 'riskassessment.new_riskassessment_heading' }, { name: product.name })}</SidebarHeading>}
      <StyledPanel lighter>
        <Field id="location_id" label={intl.formatMessage({ id: 'common.location' })} Component={LocationTree}/>
        {RiskAssementTextField('applies_to')}
        {RiskAssementTextField('participants')}
        <Field
          id="education"
          label={intl.formatMessage({ id: 'riskassessment.education' })}
          Component={TextInput}
          toggle="switch"
          placeholder={intl.formatMessage({ id: 'riskassessment.education_placeholder' })}
        />
      </StyledPanel>
      <StyledPanel lighter>
        {<h2>{intl.formatMessage({ id: 'riskassessment.chemical_use_heading' })}</h2>}
        <Field
          id="form"
          label={intl.formatMessage({ id: 'riskassessment.form' })}
          Component={Dropdown}
          options={formOptions}
          required={true}
        />
        <Field
          id="work_methods"
          label={intl.formatMessage({ id: 'riskassessment.work_methods' })}
          Component={RichTextArea}
          required={true}
        />
        {RiskAssementTextField('environment', true)}
        {RiskAssementTextField('risk_factors', true)}
        <Field
          id="usage_frequency"
          label={intl.formatMessage({ id: 'riskassessment.usage_frequency' })}
          Component={Dropdown}
          options={usageFrequencyOptions}
        />
        <Field
          id="usage_duration"
          label={intl.formatMessage({ id: 'riskassessment.usage_duration' })}
          Component={Dropdown}
          options={usageDurationOptions}
        />
        {RiskAssementTextField('yearly_consumption', true)}
        <Contact>
          <label>
            <FormattedMessage id="riskassessment.contact_edit"/>
          </label>
          <Field
            id="contact_inhalation"
            label={intl.formatMessage({ id: 'riskassessment.contact_inhalation' })}
            showLabel={false}
            Component={Checkbox}
          />
          <Field
            id="contact_eyes"
            label={intl.formatMessage({ id: 'riskassessment.contact_eyes' })}
            showLabel={false}
            Component={Checkbox}
          />
          <Field
            id="contact_skin"
            label={intl.formatMessage({ id: 'riskassessment.contact_skin' })}
            showLabel={false}
            Component={Checkbox}
          />
          <Field
            id="contact_ingestion"
            label={intl.formatMessage({ id: 'riskassessment.contact_ingestion' })}
            showLabel={false}
            Component={Checkbox}
          />
        </Contact>
        <Field id="protection" label={intl.formatMessage({ id: 'riskassessment.protection' })}
               Component={ProtectiveGearButtonGroup}/>
      </StyledPanel>
      <StyledPanel lighter>
        <h2>
          <FormattedMessage id="riskassessment.air_pollution"/>
        </h2>
        <Field id="air_pollution" Component={AirPollution} product={product} showLabel={false}/>
      </StyledPanel>
      <StyledPanel lighter>
        {<h2>{intl.formatMessage({ id: 'riskassessment.assess_risks_heading' })}</h2>}
        <Field
          id="harmful_probability"
          label={intl.formatMessage({ id: 'riskassessment.harmful_probability' })}
          Component={StepSlider}
          options={harmfulProbabilityOptions}
        />
        <Field
          id="harmful_severity"
          label={intl.formatMessage({ id: 'riskassessment.harmful_severity' })}
          Component={StepSlider}
          options={harmfulSeverityOptions}
        />
        <RiskfactorVisualizationWrapper>
          <label>
            <FormattedMessage id="chemicals.calculated_risk"/>
          </label>
          <Field id="risk_value" Component={RiskfactorVisualization} value={riskFactor}/>
        </RiskfactorVisualizationWrapper>
      </StyledPanel>
      <StyledPanel lighter>
        {<h2>{intl.formatMessage({ id: 'riskassessment.waste_disposal' })}</h2>}
        <Field
          id="waste_code"
          label={intl.formatMessage({ id: 'riskassessment.waste_code' })}
          Component={WasteCodeInput}
          validation={wasteCodeValidation}
        />
        <Field id="waste_comment" label={intl.formatMessage({ id: 'common.comment' })} useCharacterCounter counterPositionRightBottom Component={RichTextArea} maxLength={255}/>
        <Field
          id="waste_dangerous"
          label={intl.formatMessage({ id: 'riskassessment.waste_dangerous' })}
          Component={Switch}
          onLabel={intl.formatMessage({ id: 'common.yes' })}
          offLabel={intl.formatMessage({ id: 'common.no' })}
        />
        <Field
          id="waste_disposal"
          label={intl.formatMessage({ id: 'riskassessment.waste_disposal_description' })}
          Component={RichTextArea}
        />
      </StyledPanel>
      <StyledPanel lighter>
        <Field
          id="action_plan_required"
          label={intl.formatMessage({ id: 'riskassessment.action_plan_required' })}
          Component={Switch}
          onLabel={intl.formatMessage({ id: 'common.yes' })}
          offLabel={intl.formatMessage({ id: 'common.no' })}
        />
        <FieldToggler show="action_plan_required">
          <Field
            id="action_plan_description"
            label={intl.formatMessage({ id: 'riskassessment.action_plan_description' })}
            Component={RichTextArea}
          />
        </FieldToggler>
        <Field id="action_plan_date" label={intl.formatMessage({ id: 'riskassessment.action_plan_date' })}
               Component={Datepicker}/>
        <Field
          id="action_plan_responsible_user_id"
          label={intl.formatMessage({ id: 'riskassessment.action_plan_responsible_user' })}
          Component={Dropdown}
          options={actionPlanResponsibleUserOptions}
          isLoading={actionPlanResponsibleUserOptionsLoading}
          disabled={actionPlanResponsibleUserOptionsLoading}
          appendTo={document.body}
        />
      </StyledPanel>
      <StyledPanel lighter>
        <Field id="comment" label={intl.formatMessage({ id: 'riskassessment.comment' })} useCharacterCounter counterPositionRightBottom Component={RichTextArea} maxLength={255}/>
        {RiskAssementTextField('approved_by_name')}
        <Field
          id="approved_by_role"
          placeholder={intl.formatMessage({ id: 'riskassessment.approved_by_role_placeholder', defaultMessage: ' ' })}
          Component={TextInput}
          required={true}
        />
      </StyledPanel>
    </FormInContext>
  )
})

export const EmptyRiskAssessment = {
  applies_to: '',
  participants: '',
  education: null,
  work_methods: '',
  environment: '',
  risk_factors: '',
  usage_frequency: null,
  usage_duration: null,
  yearly_consumption: '',
  contact_inhalation: false,
  contact_eyes: false,
  contact_skin: false,
  contact_ingestion: false,
  protection: [],
  harmful_probability: '0',
  harmful_severity: '0',
  comment: '',
  risk_value: '',
  approved_by_name: '',
  approved_by_role: '',
  waste_code: [''],
  waste_comment: '',
  waste_dangerous: false,
  waste_disposal: '',
  air_pollution: [],
  action_plan_required: false,
  action_plan_description: null,
  action_plan_date: null,
  action_plan_responsible_user_id: null,
  action_plan_responsible_user: null
}

export default RiskAssessmentForm
