import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { Dropdown } from '.'
import useGetData from 'Hooks/useGetData.js'

export const MarketLanguageDropdown = (props) => {
  const intl = useIntl()
  const [isLoading, langList] = useGetData(
    props.productExists ? '/rapi/sds/marketLangList' : '/rapi/sds/newProductMarketLangList',
    [],
    [intl.locale]
  )
  const [options, setOptions] = useState([])
  const defaultLabel = props.defaultLabel ? props.defaultLabel : intl.formatMessage({ id: 'sds.select_language' })

  useEffect(() => {
    setOptions([{ label: defaultLabel, value: null }, ...langList])
  }, [langList, intl, defaultLabel])

  return <Dropdown style={{ maxWidth: '200px' }} options={options} disabled={isLoading}
                   appendTo={document.body} {...props} />
}
