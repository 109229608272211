import { HasCapability, HasService, useCapability, useService } from 'Components/capabilities'
import { Field, FieldGroup, Form } from 'Components/form'
import { isValidEmail } from 'Components/form/formContext'
import { AvatarEditor, Button, Datepicker, LocationTree, Switch, TextInput } from 'Components/input'
import { FlexColumn, FlexRow } from 'Components/layout'
import { ModalActionsFooter } from 'Components/readonly'
import { AuthContext } from 'Context'
import { parse } from 'date-fns'
import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { ReplaceSuperUserDropdown } from './replaceSuperUser'
import RoleDropdown from './roleDropdown'
import RoleHelpPanel from './roleHelpPanel'
import RoleHelpPanelShowAll from './roleHelpPanelShowAll'
import UiLanguageDropdown from './uiLanguageDropdown'

const RoleHelpWrapper = styled.div`
  flex: 1 1 auto;

  ${RoleHelpPanel}, ${RoleHelpPanelShowAll} {
    width: auto;
  }
`

export const UserDialogFooter = ({ modalController, onClickSave, isLoading }) => {
  const intl = useIntl()

  return (
    <FlexColumn>
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
        <Button primary icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })} isLoading={isLoading}
                onClick={onClickSave}/>
      </ModalActionsFooter>
    </FlexColumn>
  )
}

export const UserDialogContent = ({ modalController, formRef, onSubmit, isLoading, setIsLoading }) => {
  const intl = useIntl()

  const { user: selectedUser, organisation } = modalController.data
  const [userMarkets, setUserMarkets] = useState(selectedUser ? selectedUser.userMarkets : [])

  // If editing logged in user show and save profile only
  const loggedIn = useContext(AuthContext).user
  const isProfileMode = selectedUser && selectedUser.id === loggedIn.user.id
  const isNewUser = selectedUser === null

  const defaultRoles = {}
  if (organisation.has_sds && organisation.has_chemical_list) {
    defaultRoles['sds'] = 'not_authorized'
    defaultRoles['chemical_list'] = 'not_authorized'
  } else if (organisation.has_sds) {
    defaultRoles['sds'] = 'user'
    defaultRoles['chemical_list'] = 'not_authorized'
  } else if (organisation.has_chemical_list) {
    defaultRoles['sds'] = 'not_authorized'
    defaultRoles['chemical_list'] = 'user'
  } else {
    defaultRoles['sds'] = 'not_authorized'
    defaultRoles['chemical_list'] = 'not_authorized'
  }

  const initialFormData = {
    name: (selectedUser && selectedUser.name) || '',
    phone: (selectedUser && selectedUser.phone) || '',
    email: (selectedUser && selectedUser.email) || '',
    starts_at: !selectedUser ? new Date() : selectedUser.starts_at && parse(selectedUser.starts_at),
    expires_at: (selectedUser && selectedUser.expires_at && parse(selectedUser.expires_at)) || '',
    roles: (selectedUser && selectedUser.roleValues) || defaultRoles,
    language: (selectedUser && selectedUser.language) || 'sv',
    limit_locations: (selectedUser && selectedUser.limit_locations) || [],
    avatar: (selectedUser && selectedUser.avatar) || null
  }

  const [currentRoles, setCurrentRoles] = useState(initialFormData.roles)

  const validateSdsRoles = () => {
    if (!organisation.has_sds) {
      return null
    }

    if (!Object.values(currentRoles).some((role) => role !== 'not_authorized')) {
      return { id: 'admin.validation_all_roles_not_authorized' }
    }
  }

  const validateChemicalRoles = () => {
    if (!organisation.has_chemical_list) {
      return null
    }

    if (!Object.values(currentRoles).some((role) => role !== 'not_authorized')) {
      return { id: 'admin.validation_all_roles_not_authorized' }
    }

    if (currentRoles.chemical_list !== 'not_authorized') {
      if (!Object.values(userMarkets).some((market) => market !== false)) {
        return { id: 'admin.validation_no_market_added' }
      }
    }
  }

  const hasServiceChemicalList = useService('chemical_list')
  const hasServiceSds = useService('sds')
  const hasCapabilityManageRolesChemicalList = useCapability('MANAGE_ROLES_CHEMICAL_LIST')
  const hasCapabilityManageRolesSds = useCapability('MANAGE_ROLES_SDS')
  const mayEditRolesChemicalList = hasServiceChemicalList && hasCapabilityManageRolesChemicalList
  const mayEditRolesSds = hasServiceSds && hasCapabilityManageRolesSds

  const emailValidation = (value) => {
    if (!isValidEmail(value)) {
      return { id: 'validation.email', values: { attribute: intl.formatMessage({ id: 'common.email' }) } }
    }
  }

  const SetUserMarket = (market, e) => {
    if (e.target.value) {
      if (userMarkets && !userMarkets.includes(market)) {
        setUserMarkets((prev) => [...prev, market])
      }
    } else {
      let markets = userMarkets.filter((item) => item !== market)
      setUserMarkets(markets)
    }
  }

  return (
    <div>
      <Form ref={formRef}
            onSubmit={(formData) => onSubmit(formData, userMarkets, selectedUser, isNewUser, isProfileMode)}>
        <FlexRow>
          <FieldGroup columns="1">
            <Field
              id="name"
              label={intl.formatMessage({ id: 'common.name' })}
              focus="true"
              Component={TextInput}
              initialValue={initialFormData.name}
              required={true}
            />
            <Field
              id="phone"
              label={intl.formatMessage({ id: 'common.phone' })}
              Component={TextInput}
              initialValue={initialFormData.phone}
            />
            {isNewUser ? (
              <Field
                id="email"
                label={intl.formatMessage({ id: 'common.email' })}
                Component={TextInput}
                initialValue={initialFormData.email}
                required={true}
                validation={emailValidation}
              />
            ) : null}
            {!isProfileMode && (
              <>
                <Field
                  id="starts_at"
                  label={intl.formatMessage({ id: 'common.start_date' })}
                  dateFormat="yy-mm-dd"
                  Component={Datepicker}
                  initialValue={initialFormData.starts_at}
                  validation="date"
                  appendTo={document.body}
                />
                <Field
                  id="expires_at"
                  label={intl.formatMessage({ id: 'common.expire_date' })}
                  dateFormat="yy-mm-dd"
                  Component={Datepicker}
                  initialValue={initialFormData.expires_at}
                  validation="date"
                  appendTo={document.body}
                />
                <HasService service="sds">
                  <HasCapability capability="MANAGE_ROLES_SDS">
                    <Field
                      id="roles.sds"
                      label={intl.formatMessage({ id: 'user.role' }) + ' ' + intl.formatMessage({ id: 'sds.module' })}
                      Component={RoleDropdown}
                      initialValue={initialFormData.roles.sds}
                      required={mayEditRolesSds}
                      onChange={(e) => setCurrentRoles({ ...currentRoles, sds: e.target.value })}
                      validation={validateSdsRoles}
                    />
                  </HasCapability>
                </HasService>
                <HasService service="chemical_list">
                  <HasCapability capability="MANAGE_ROLES_CHEMICAL_LIST">
                    <Field
                      id="roles.chemical_list"
                      label={intl.formatMessage({ id: 'user.role' }) + ' ' + intl.formatMessage({ id: 'chemicals.module' })}
                      Component={RoleDropdown}
                      initialValue={initialFormData.roles.chemical_list}
                      required={mayEditRolesChemicalList}
                      onChange={(e) => {
                        setCurrentRoles({ ...currentRoles, chemical_list: e.target.value })
                      }}
                      validation={validateChemicalRoles}
                    />
                    {organisation.organisationMarkets &&
                      currentRoles.chemical_list !== 'not_authorized' &&
                      organisation.organisationMarkets.map((market) => (
                        <Field
                          id={'enable_user_market_' + market}
                          key={market}
                          Component={Switch}
                          label={
                            intl.formatMessage({ id: 'common.activate_market_' + market }) +
                            ' (' +
                            intl.formatMessage({ id: 'chemicals.module' }) +
                            ')'
                          }
                          onLabel={intl.formatMessage({ id: 'common.yes' })}
                          offLabel={intl.formatMessage({ id: 'common.no' })}
                          onChange={(e) => SetUserMarket(market, e)}
                          disabled={currentRoles.chemical_list === 'not_authorized'}
                          value={userMarkets ? userMarkets.includes(market) : false}
                          required={currentRoles.chemical_list !== 'not_authorized' && mayEditRolesChemicalList}
                        />
                      ))}
                    {currentRoles.chemical_list !== 'admin' && (
                      <>
                        {currentRoles.chemical_list !== 'not_authorized' && (
                          <Field
                            id="limit_locations"
                            label={intl.formatMessage({ id: 'user.limit_locations' })}
                            Component={LocationTree}
                            appendToBody={true}
                            multiselect={true}
                            childrenincluded={true}
                            initialValue={initialFormData.limit_locations}
                            width="200px"
                          />
                        )}

                        {selectedUser !== null && (
                          <Field
                            id="replace_super_user"
                            Component={ReplaceSuperUserDropdown}
                            user={selectedUser}
                            required={mayEditRolesChemicalList && currentRoles.chemical_list !== 'admin'}
                          />
                        )}
                      </>
                    )}
                  </HasCapability>
                </HasService>
              </>
            )}

            <Field
              id="language"
              label={intl.formatMessage({ id: 'common.language' })}
              Component={UiLanguageDropdown}
              initialValue={initialFormData.language}
              required={true}
            />

            <Field
              id="avatar"
              Component={AvatarEditor}
              label={intl.formatMessage({ id: 'user.avatar' })}
              initialValue={initialFormData.avatar}
              validation={(value) => {
                return value === '__editing_ongoing__' ? { id: 'user.avatar-validation-confirm-first' } : null
              }}
            />
          </FieldGroup>
          <RoleHelpWrapper className="md-show">
            {organisation.has_chemical_list || organisation.has_sds ? <RoleHelpPanel/> : <RoleHelpPanelShowAll/>}
          </RoleHelpWrapper>
        </FlexRow>
      </Form>
    </div>
  )
}
