import React from 'react'

import { Dropdown } from 'Components/input'
import useGetData from 'Hooks/useGetData.js'

const SdsCountryDropdown = (props) => {
  const [isLoading, countryList] = useGetData('/rapi/sds/sdsCountryList', [])

  return <Dropdown options={countryList} disabled={isLoading} {...props} />
}

export default SdsCountryDropdown
