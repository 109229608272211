import axios from "axios";

export const setLocked = async (productId, lockedValue) => {
  // TODO: Implementera notifikation vid upplåsning
  try {
    await axios.put('/rapi/chemicals/' + productId + '/setLocked', { isLocked: lockedValue });
  } catch (error) {
    if (!axios.isCancel(error)) {
      // TODO: Implementera felhantering
      return
    }
    throw error;
  }
}
