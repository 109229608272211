import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import ReactAvatarEditor from 'react-avatar-editor'
import { Slider as PrimeSlider } from 'primereact/slider'

import { FileUpload } from 'Components/input'
import { FlexRowCenter, Section } from 'Components/layout'
import { Avatar, Button, InfoPanel } from 'Components/readonly'
import { useIntl } from 'react-intl'

const Slider = styled(PrimeSlider)`
  flex: 1 1 auto;
  margin: 0 20px 0 10px;
`

const Wrapper = styled.div``

const EditToolbar = styled(FlexRowCenter)`
  &&& {
    > *:not(:last-child) {
      margin-right: 10px;
    }

    ${Button} {
      margin-bottom: 0;
    }

    .rotate-180 {
      transform: scaleX(-1);
    }
  }
`

export const AvatarEditor = ({ value, onChange }) => {
  const intl = useIntl()
  const editorRef = useRef()
  const [rotate, setRotate] = useState(0)
  const [scale, setScale] = useState(100)
  const [file, setFile] = useState(value ? [{ file: value }] : [])
  const [editing, setEditing] = useState(value ? false : true)
  const [preview, setPreview] = useState(value)

  const discard = () => {
    setEditing(false)
    // If no image has been accepted discard selected file
    if (!preview) {
      deleteAvatar()
    }
  }

  const apply = () => {
    setEditing(false)
    if (editorRef.current) {
      const img = editorRef.current.getImageScaledToCanvas()
      setPreview(img.toDataURL())
      onChange({ target: { value: img.toDataURL() } })
    } else {
      console.error('editorRef missing')
    }
  }

  const deleteAvatar = () => {
    setEditing(false)
    setFile([])
    setPreview(null)
    onChange({ target: { value: null } })
  }

  if (file.length === 0) {
    if (!editing) {
      setEditing(true)
    }
    return (
      <>
        <Section>
          <FileUpload
            id="avatar-upload"
            type="button"
            text={intl.formatMessage({ id: 'user.browse-image' })}
            value={file}
            onChange={(e) => {
              setFile(e.target.value)
              onChange({ target: { value: '__editing_ongoing__' } })
            }}
            accept="image/jpeg, image/png"
          />
        </Section>
        <InfoPanel messageid="auth.approve-upload"></InfoPanel>
      </>
    )
  }

  if (!editing) {
    return (
      <FlexRowCenter>
        <Avatar src={preview}/>
        <Button icon="pi pi-pencil" onClick={() => setEditing(true)}/>
        <Button icon="pi pi-trash" onClick={deleteAvatar}/>
      </FlexRowCenter>
    )
  }

  return (
    <Wrapper>
      <ReactAvatarEditor
        ref={editorRef}
        image={file[0].file}
        width={250}
        height={250}
        border={20}
        borderRadius={125}
        color={[0, 0, 0, 0.6]} // RGBA
        scale={scale / 100}
        rotate={rotate}
      />
      <EditToolbar>
        <Button icon="pi pi-undo" onClick={() => setRotate((r) => r - 90)}/>
        <Button icon="pi pi-undo rotate-180" onClick={() => setRotate((r) => r + 90)}/>
        <Slider value={scale} onChange={(e) => setScale(e.value)} min={100} max={200}/>
        <Button icon="pi pi-times" onClick={discard}/>
        <Button icon="pi pi-check" onClick={apply}/>
      </EditToolbar>
    </Wrapper>
  )
}
