import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

import { Button, Panel } from '../readonly'
import { RadioButtonGroup, TextInput } from '../input'

const Row = styled(Panel)`
  display: flex;
  margin-top: 10px;
  padding: 5px;

  > * {
    flex: 1 0 1%;
  }

  ${RadioButtonGroup} {
    font-size: 12px;
  }

  ${TextInput} {
    flex: 0 0 100px;
  }

  > ${Button} {
    flex: 0 0 30px;
  }

  > div {
    div:first-of-type {
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
    position: relative;
    padding-right: 10px;
    > div {
      flex: 0 0 50%;
      margin-bottom: 8px;
    }

    ${Button} {
      position: absolute;
      top: 5px;
      right: 2px;
    }
  }
`

export const ChemicalDnelRow = injectIntl(({ id, value, update, remove, intl }) => {
  const targetOptions = [
    { value: 'consumer', label: intl.formatMessage({ id: 'chemicals.form.dnel.consumer' }) },
    { value: 'employee', label: intl.formatMessage({ id: 'chemicals.form.dnel.employee' }) }
  ]
  const typeOptions = [
    { value: 'systemic', label: intl.formatMessage({ id: 'chemicals.form.dnel.systemic' }) },
    { value: 'local', label: intl.formatMessage({ id: 'chemicals.form.dnel.local' }) }
  ]
  const frequencyOptions = [
    { value: 'chronic', label: intl.formatMessage({ id: 'chemicals.form.dnel.chronic' }) },
    { value: 'acute', label: intl.formatMessage({ id: 'chemicals.form.dnel.acute' }) }
  ]
  const pathOptions = [
    { value: 'inhalation', label: intl.formatMessage({ id: 'chemicals.form.dnel.inhalation' }) },
    { value: 'dermal', label: intl.formatMessage({ id: 'chemicals.form.dnel.dermal' }) },
    { value: 'oral', label: intl.formatMessage({ id: 'chemicals.form.dnel.oral' }) }
  ]

  return (
    <Row id={id}>
      <div>
        <div>
          <FormattedMessage id="chemicals.form.dnel.target_audience"/>
        </div>
        <RadioButtonGroup id={id + '-target'} value={value.target} onChange={(e) => update('target', e)}
                          options={targetOptions}/>
      </div>
      <div>
        <div>
          <FormattedMessage id="chemicals.form.dnel.type"/>
        </div>
        <RadioButtonGroup id={id + '-type'} value={value.type} onChange={(e) => update('type', e)}
                          options={typeOptions}/>
      </div>
      <div>
        <div>
          <FormattedMessage id="chemicals.form.dnel.frequency"/>
        </div>
        <RadioButtonGroup
          id={id + '-frequency'}
          value={value.frequency}
          onChange={(e) => update('frequency', e)}
          options={frequencyOptions}
        />
      </div>
      <div>
        <div>
          <FormattedMessage id="chemicals.form.dnel.path"/>
        </div>
        <RadioButtonGroup id={id + '-path'} value={value.path} onChange={(e) => update('path', e)}
                          options={pathOptions}/>
      </div>
      <div>
        <div>
          <FormattedMessage id="chemicals.form.dnel.value"/>
        </div>
        <TextInput id={id + '-value'} value={value.value} onChange={(e) => update('value', e)}/>
      </div>
      <Button icon="pi pi-trash" onClick={remove}/>
    </Row>
  )
})
