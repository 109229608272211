import React from 'react'
import axios from 'axios'
import { FlexRow, PageConstraint, PageHeader } from 'Components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import { Column } from 'primereact/column'

import useCrudData from 'Hooks/useCrudData'
import { Button, DataTable, Modal, richTextBody } from 'Components/readonly'
import useModalController from 'Hooks/useModalController'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import EditFaqContentDialog from './components/editFaqContentDialog'
import { GrowlContext } from 'Context'

const url = '/rapi/admin/faq'

export const EditFaqPage = () => {
  const intl = useIntl()
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  // eslint-disable-next-line no-unused-vars
  const [isLoading, faqs, createFaq, readFaq, updateFaq, deleteFaq, setFaqs] = useCrudData(url, [])
  const [deleteFaqModalController] = useModalController({ title: intl.formatMessage({ id: 'common.delete' }) })
  const [editFaqModalController] = useModalController({ title: intl.formatMessage({ id: 'common.edit' }) })

  const deleteAction = async (faq) => {
    try {
      const result = await axios.delete(url + '/' + faq.id)
      displaySuccess(result.data.message)
      deleteFaq(faq)
    } catch (error) {
      displayError(error)
    }
  }

  const changeBody = (row) => (
    <FlexRow key={row}>
      <Button
        primary
        icon="pi pi-pencil"
        onClick={() => {
          editFaqModalController.open({ data: row })
        }}
      />
      <Button
        primary
        icon="pi pi-trash"
        onClick={() => {
          deleteFaqModalController.open({ data: row })
        }}
      />
    </FlexRow>
  )

  const moduleOptions = {
    sds: intl.formatMessage({ id: 'sds.module' }),
    chemical_list: intl.formatMessage({ id: 'chemicals.module' })
  }

  const moduleBody = (row, column) => {
    const value = row[column.field]
    return value && value.length > 0 ? value.map((item) => moduleOptions[item]).join(', ') : moduleOptions[value]
  }

  return (
    <PageConstraint>
      <Modal modalController={editFaqModalController} ContentComponent={EditFaqContentDialog} onCreate={createFaq}
             onUpdate={updateFaq}/>
      <Modal
        modalController={deleteFaqModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="admin.delete_content_text"
        confirmTextId="common.delete"
        onConfirm={deleteAction}
      />
      <PageHeader>
        <h1>
          <FormattedMessage id="faq.title"/>
        </h1>
        <Button
          primary
          label={intl.formatMessage({ id: 'faq.create_new' })}
          icon="pi pi-plus"
          onClick={() => {
            editFaqModalController.open({ title: intl.formatMessage({ id: 'common.create' }) })
          }}
        />
      </PageHeader>
      <DataTable
        className="flextable"
        value={faqs}
        loading={isLoading}
        reorderableRows={true}
        onRowReorder={async (e) => {
          setFaqs(e.value)
          try {
            const result = await axios.post(url + '/order', { order: e.value.map((row) => row.id) })
            displaySuccess(result.data.message)
          } catch (error) {
            displayError(error)
          }
        }}>
        <Column rowReorder={true} style={{ width: '3em' }}/>
        <Column field="modules" header={intl.formatMessage({ id: 'common.module' })} body={moduleBody}/>
        <Column field="language_variants.sv.question" header={intl.formatMessage({ id: 'admin.faq.question_sv' })}
                body={richTextBody}/>
        <Column field="language_variants.sv.answer" header={intl.formatMessage({ id: 'admin.faq.answer_sv' })}
                body={richTextBody}/>
        <Column field="language_variants.en.question" header={intl.formatMessage({ id: 'admin.faq.question_en' })}
                body={richTextBody}/>
        <Column field="language_variants.en.answer" header={intl.formatMessage({ id: 'admin.faq.answer_en' })}
                body={richTextBody}/>
        <Column field="language_variants.no.question" header={intl.formatMessage({ id: 'admin.faq.question_no' })}
                body={richTextBody}/>
        <Column field="language_variants.no.answer" header={intl.formatMessage({ id: 'admin.faq.answer_no' })}
                body={richTextBody}/>
        <Column field="language_variants.dk.question" header={intl.formatMessage({ id: 'admin.faq.question_dk' })}
                body={richTextBody}/>
        <Column field="language_variants.dk.answer" header={intl.formatMessage({ id: 'admin.faq.answer_dk' })}
                body={richTextBody}/>
        <Column
          header={intl.formatMessage({ id: 'common.edit' }) + '/' + intl.formatMessage({ id: 'common.delete' })}
          body={changeBody}
          style={{ width: '1%' }}
        />
      </DataTable>
    </PageConstraint>
  )
}
