import axios from 'axios'
import colors from 'Components/colors.scss'
import { MultiSelect, SearchInputClickable } from 'Components/input'
import { FlexRow, PageHeader } from 'Components/layout'
import { Button, DataTable, Modal, ToolPanel } from 'Components/readonly'
import useCachedGetData from 'Hooks/useCachedGetData'
import useCrudData from 'Hooks/useCrudData'
import useGetData from 'Hooks/useGetData'
import useModalController from 'Hooks/useModalController'
import _ from 'lodash'
import { Column } from 'primereact/column'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'
import { YesNoString } from 'Utils/strings'
import DeleteIngredientDialog from './components/deleteIngredientDialog'
import DeleteIngredientVariantDialog from './components/deleteIngredientVariantDialog'
import EditIngredientDialog from './components/editIngredientDialog'
import EditIngredientVariantDialog from './components/editIngredientVariantDialog'
import { AuthContext } from 'Context'

const HighlightSpan = styled.span`
  color: ${colors.accent};
`

const MainDataTable = styled(DataTable)`
  &&&& {
    tr:not(.p-datatable-row) > td {
      padding: 0;
    }
  }
`

const SubDataTable = styled(DataTable)`
  &&&& {
    width: 100%;

    .p-datatable-wrapper {
      min-height: auto;
    }

    .p-datatable-thead {
      display: none;
    }

    tr,
    tr:nth-child(even) {
      background-color: ${colors.darkerPanel};
    }

    ${FlexRow} {
      div {
        flex: 1 1 0px;
      }

      div:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`

export const IngredientsPage = injectIntl(({ intl }) => {
  // eslint-disable-next-line
  const [isLoading, ingredients, createIngredient, readIngredient, updateIngredient, deleteIngredient, setIngredients] = useCrudData(
    '/rapi/ingredients?page=0',
    []
  )
  const [page, setPage] = useState(1)
  const [, ingredientsCount, setIngredientsCount] = useGetData('/rapi/ingredients/count', 10)
  const [isLoadingFilterTypes, filterTypes] = useGetData('/rapi/admin/ingredientfilter/types', {})
  const [isLoadingFilterPrioTypes, filterPrioTypes] = useGetData('/rapi/ingredients/prio-types', {})
  const [globalFilter, setGlobalFilter] = useState('')
  const [filterHazardCategories, setFilterHazardCategories] = useState([])
  const [filterHazardStatements, setFilterHazardStatements] = useState([])
  const [first, setFirst] = useState(0)
  const [expandedRows, setExpandedRows] = useState([])
  const [isHazardCategoriesLoading, hazardCategories] = useCachedGetData('/rapi/chemicals/hazardcategories')
  const [isHazardStatementsLoading, hazardStatements] = useCachedGetData('/rapi/chemicals/hazardstatements')
  const { activeMarket } = useContext(AuthContext)

  const hazardCategoryOptions = React.useMemo(() => {
    if (!hazardCategories) return []
    return _.uniq(hazardCategories.map((hc) => hc.abbreviation))
      .sort()
      .map((abbreviation) => ({
        value: abbreviation,
        label: abbreviation
      }))
  }, [hazardCategories])

  const hazardStatementOptions = React.useMemo(() => {
    if (!hazardStatements) return []
    return Object.keys(hazardStatements).map((code) => ({
      value: code,
      label: code
    }))
  }, [hazardStatements])

  const [deleteIngredientModalController] = useModalController({ title: intl.formatMessage({ id: 'admin.ingredients.delete_ingredient' }) })
  const [deleteVariantModalController] = useModalController({ title: intl.formatMessage({ id: 'admin.ingredients.delete_variant' }) })
  const [editIngredientModalController] = useModalController({ title: intl.formatMessage({ id: 'admin.ingredients.edit_ingredient' }) })
  const [editVariantModalController] = useModalController({ title: intl.formatMessage({ id: 'admin.ingredients.edit_variant' }) })

  const NamesBody = (row) => (
    <>
      {row.names_sv}
      <br />
      {row.names_en}
      <br />
      {row.names_no}
      <br />
      {row.names_dk}
    </>
  )

  const NumbersBody = (row) => (
    <>
      {intl.formatMessage({ id: 'chemicals.form.ingredient.cas_id' })}: {row.cas_id}
      <br />
      {intl.formatMessage({ id: 'chemicals.form.ingredient.eg_id' })}: {row.eg_id}
      <br />
      {intl.formatMessage({ id: 'chemicals.form.ingredient.index_id' })}: {row.index_id}
      <br />
      {intl.formatMessage({ id: 'chemicals.form.ingredient.reach_id' })}: {row.reach_id}
      <br />
    </>
  )

  const calcUnusedVariants = (ingredient) =>
    ingredient.variants.reduce((result, variant) => result + (variant.usage_count === 0 ? 1 : 0), 0)
  const calcUsed = (ingredient) => ingredient.variants.reduce((result, variant) => result + variant.usage_count, 0)

  const VariantsBody = (row) => {
    const unusedVariants = calcUnusedVariants(row)
    return (
      <>
        {row.variants.length}
        {unusedVariants > 0 && (
          <>
            <span>, </span>
            <HighlightSpan>
              <FormattedMessage id="admin.ingredients.unused_variants" values={{ count: unusedVariants }} />
            </HighlightSpan>
          </>
        )}
      </>
    )
  }

  const UsageBody = (row) => {
    const usageCount = calcUsed(row)
    if (usageCount === 0) {
      return (
        <HighlightSpan>
          <FormattedMessage id="admin.ingredients.not_used" />
        </HighlightSpan>
      )
    }
    return usageCount
  }

  const addIngredientVariant = (ingredient) => {
    editVariantModalController.open({
      title: intl.formatMessage({ id: 'admin.ingredients.add_variant' }),
      data: { ingredient, variant: null, filterTypes, isLoadingFilterTypes }
    })
  }

  const ActionsBody = (row) => (
    <FlexRow>
      <Button
        primary
        icon="pi pi-plus"
        onClick={() => addIngredientVariant(row)}
        tooltip={intl.formatMessage({ id: 'admin.ingredients.add_variant' })}
      />
      <Button primary icon="pi pi-pencil" onClick={() => editIngredientModalController.open({ data: { ingredient: row } })} />
      {calcUsed(row) === 0 && (
        <Button primary icon="pi pi-trash" onClick={() => deleteIngredientModalController.open({ data: { ingredient: row } })} />
      )}
    </FlexRow>
  )

  const VariantsBodyExpanded = (row) => (
    <FlexRow>
      <div style={{maxWidth: "200px"}}>
        ID: {row.id}
      </div>
      <div>
        <div>{row.hazard_categories.map((c) => c.abbreviation).join(', ')}</div>
        <div>{row.hazard_statements.map((s) => s.code).join(', ')}</div>
      </div>
      <div>
        <div>
          <FormattedMessage id="admin.ingredients.incorrect" />: <YesNoString value={row.classification_incorrect} />
        </div>
        {row.classification_incorrect_from_date && (
          <div>
            <FormattedMessage id="chemicals.form.ingredient.classification_incorrect_from_date" />:{' '}
            {row.classification_incorrect_from_date.substring(0, 10)}
          </div>
        )}
        <div>
          {(activeMarket === 'SE' ||
            activeMarket === 'SV') && (
              <>
                <FormattedMessage id="chemicals.form.ingredient.listed_prio" />: <YesNoString value={row.listed_prio} />
              </>
            )}
          {activeMarket === 'NO' && (
            <>
              <FormattedMessage id="chemicals.form.ingredient.listed_prio" />: <YesNoString value={row.listed_prio_no} />
            </>
          )}
        </div>
        {row.flagged_for_ingredient_filters.length > 0 && (
          <div>
            <FormattedMessage id="admin.ingredients.manually_flagged_for" />:{' '}
            {row.flagged_for_ingredient_filters.map((filter) => filterTypes[filter]).join(', ')}
          </div>
        )}
      </div>
    </FlexRow>
  )

  const UsageBodyExpanded = (row) =>
    row.usage_count > 0 ? (
      row.usage_count
    ) : (
      <HighlightSpan>
        <FormattedMessage id="admin.ingredients.not_used" />
      </HighlightSpan>
    )

  const ActionsBodyExpanded = (ingredient, variant) => {
    return (
      <FlexRow>
        <Button
          primary
          icon="pi pi-pencil"
          onClick={() =>
            editVariantModalController.open({
              data: {
                ingredient,
                variant,
                filterTypes,
                filterPrioTypes,
                isLoadingFilterTypes
              }
            })
          }
        />
        {variant.usage_count === 0 && (
          <Button primary icon="pi pi-trash" onClick={() => deleteVariantModalController.open({ data: { ingredient, variant } })} />
        )}
      </FlexRow>
    )
  }

  const ingredientVariantTemplate = (row) => {
    return (
      <SubDataTable value={row.variants} paginator={false} className="flextable">
        <Column className="md-show" />
        <Column body={VariantsBodyExpanded} header={intl.formatMessage({ id: 'admin.ingredients.variant' })} />
        <Column style={{ width: '180px' }} body={UsageBodyExpanded} header={intl.formatMessage({ id: 'admin.ingredients.usage' })} />
        <Column
          style={{ width: '100px' }}
          body={(variant) => ActionsBodyExpanded(row, variant)}
          header={intl.formatMessage({ id: 'common.edit' }) + '/' + intl.formatMessage({ id: 'common.delete' })}
        />
      </SubDataTable>
    )
  }

  const fetchData = () => {
    const query = encodeURI(
      '?page=' +
        page +
        (globalFilter ? '&search=' + globalFilter : '') +
        (filterHazardCategories.length > 0 ? '&filterHazardCategories=' + filterHazardCategories.join(',') : '') +
        (filterHazardStatements.length > 0 ? '&filterHazardStatements=' + filterHazardStatements.join(',') : '')
    )

    const getIngredients = axios.get('/rapi/ingredients' + query)
    const getIngredientsCount = axios.get('/rapi/ingredients/count' + query)

    Promise.all([getIngredients, getIngredientsCount]).then((data) => {
      const [ingredientList, ingredientCount] = data
      setIngredients(ingredientList.data)
      setIngredientsCount(ingredientCount.data)
    })
  }

  const deleteIngredientVariant = (ingredient, variant) => {
    const updatedIngredient = { ...ingredient, variants: ingredient.variants.filter((v) => v.id !== variant.id) }
    updateIngredient(updatedIngredient)

    refreshExpandedRows(updatedIngredient)
  }

  const updateIngredientVariant = (ingredient, variant) => {
    const updatedVariantIndex = ingredient.variants.findIndex((v) => v.id === variant.id)
    const updatedVariants = [...ingredient.variants]
    if (updatedVariantIndex === -1) {
      updatedVariants.push(variant)
    } else {
      updatedVariants.splice(updatedVariantIndex, 1, variant)
    }
    const updatedIngredient = { ...ingredient, variants: updatedVariants }
    updateIngredient(updatedIngredient)

    refreshExpandedRows(updatedIngredient)
  }

  const refreshExpandedRows = (ingredient) => {
    const expandedRowIndex = expandedRows.findIndex((r) => r.id === ingredient.id)
    const updatedExpandedRows = [...expandedRows]
    updatedExpandedRows.splice(expandedRowIndex, 1, ingredient)
    setExpandedRows(updatedExpandedRows)
  }

  const handleSearchSubmit = () => {
    fetchData()
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterHazardStatements, filterHazardCategories])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData()
    }, 400)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter])

  return (
    <div>
      <Modal modalController={deleteIngredientModalController} ContentComponent={DeleteIngredientDialog} onSuccess={deleteIngredient} />
      <Modal
        modalController={deleteVariantModalController}
        ContentComponent={DeleteIngredientVariantDialog}
        onSuccess={deleteIngredientVariant}
      />
      <Modal
        modalController={editIngredientModalController}
        ContentComponent={EditIngredientDialog}
        onSuccess={(i) => {
          updateIngredient(i)
          refreshExpandedRows(i)
        }}
      />
      <Modal
        modalController={editVariantModalController}
        ContentComponent={EditIngredientVariantDialog}
        onSuccess={updateIngredientVariant}
        activeMarket={activeMarket}
      />
      <PageHeader>
        <h1>
          <FormattedMessage id="chemicals.form.ingredients" />
        </h1>
        <ToolPanel>
          <SearchInputClickable
            onClick={handleSearchSubmit}
            onChange={(event) => {
              if(!isNaN(parseInt(event.target.value)) || !(event.target.value?.length === 1 || event.target.value?.length === 2)) {
                let val = event.target.value.replace(/[^0-9a-zA-ZåäöÅÄÖ, \-\<\>\≤\≥\[\]\(\)\%\'\"\:]/gi, '');
                setGlobalFilter(val)
                setFirst(0);
                setPage(1);
              }
            }}
          />
          <label id="filter_hazard_categories">
            <FormattedMessage id="chemicals.form.classification_marking_short" />
          </label>
          <MultiSelect
            id="filter_hazard_categories"
            isLoading={isHazardCategoriesLoading}
            options={hazardCategoryOptions}
            onChange={(e) => {
              setFilterHazardCategories(e.target.value)
              setFirst(0)
            }}
            value={filterHazardCategories}
          />
          <label id="filter_hazard_statements">
            <FormattedMessage id="chemicals.form.hazard_statements" />
          </label>
          <MultiSelect
            id="filter_hazard_statements"
            isLoading={isHazardStatementsLoading}
            options={hazardStatementOptions}
            onChange={(e) => {
              setFilterHazardStatements(e.target.value)
              setFirst(0)
            }}
            value={filterHazardStatements}
          />
          <div style={{ paddingBottom: '16px' }} />
        </ToolPanel>
      </PageHeader>
      <MainDataTable
        className="flextable"
        value={ingredients}
        paginator={true}
        rows={10}
        totalRecords={ingredientsCount}
        lazy={true}
        loading={isLoading}
        first={first}
        onPage={(e) => {
          setFirst(e.first)
          setPage(e.page + 1)
        }}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={ingredientVariantTemplate}>
        <Column expander={true} style={{ width: '2em' }} className="sm-show" />
        <Column field="names_sv" body={NamesBody} header={intl.formatMessage({ id: 'admin.ingredients.name' })} />
        <Column style={{ width: '240px' }} body={NumbersBody} header={intl.formatMessage({ id: 'admin.ingredients.number' })} />
        <Column body={VariantsBody} header={intl.formatMessage({ id: 'admin.ingredients.variants' })} />
        <Column style={{ width: '180px' }} body={UsageBody} header={intl.formatMessage({ id: 'admin.ingredients.usage' })} />
        <Column
          style={{ width: '100px' }}
          header={intl.formatMessage({ id: 'common.edit' }) + '/' + intl.formatMessage({ id: 'common.delete' })}
          body={ActionsBody}
        />
        <Column expander={true} className="sm-hide" />
      </MainDataTable>
    </div>
  )
})
