import _ from 'lodash'
import { Growl } from 'primereact/growl'
import React, { createContext, useContext, useRef } from 'react'

export const GrowlContext = createContext({
  growl: undefined
})

export const GrowlContextProvider = (props) => {
  const { children } = props
  const growl = useRef(null)
  const [messages, setMessages] = React.useState([])
  const [translationsLoaded, setTranslationsLoaded] = React.useState(false)
  const [translations, setTranslations] = React.useState({})
  const [serverErrorText, setServerErrorText] = React.useState({
    // stores translated server error message
    title: 'A server error occurred.', // default text in english
    message: 'See the consolelog for details.'
  })

  const hasMessage = (message) => {
    return messages.find((m) => m.severity === message.severity && m.summary === message.summary && m.detail === message.detail)
  }

  const displayError = (error, intl) => {
    if (!growl.current) {
      console.error('Growl not available. Error:', error)
    }

    let title = null,
      message = ''
    if (_.isString(error)) {
      title = error
    } else if (error && error.response && error.response.data) {
      const data = error.response.data
      if (data.errors) {
        // error.message contains unuseful http status message
        //title = error.message
        message = _.join(_.values(data.errors), '\n')
      } else {
        if (intl && error.response.status === 422) {
          title = intl.formatMessage({ id: 'validation.error' })
          message = data.message.replaceAll('\\n', '\n')
        } else {
          title = data.message
          //message = error.message
        }

        if (!title && !message) {
          // 401 and 419 indicates laravel session expired
          if (error.response.status > 401 && error.response.status !== 419) {
            console.error(error)
            title = serverErrorText.title
            message = serverErrorText.message
          } else {
            // Dont show empty red error growl, axiosintercepter sets this for HTTP 401
            return
          }
        }
      }
    } else {
      title = 'An error occurred'
    }

    const newMessage = { life: 10000, severity: 'error', summary: title ? title : message, detail: title ? message : null }
    if (!hasMessage(newMessage)) {
      growl.current.show(newMessage)
      setMessages([...messages].concat([newMessage]))
    }
  }

  const displaySuccess = (title, message) => {
    if (!growl.current) {
      console.log('Growl not available. Success:', title, message)
    }
    const newMessage = { severity: 'success', summary: title, detail: message }
    if (!hasMessage(newMessage)) {
      growl.current.show(newMessage)
      setMessages([...messages].concat([newMessage]))
    }
  }

  const handleMessageRemove = (removed) => {
    const newMessages = messages.filter((g) => g.id !== removed.id)
    setMessages(newMessages)
  }

  const growlContext = {
    growl,
    handleMessageRemove,
    displayError,
    displaySuccess,
    setServerErrorText,
    translationsLoaded,
    setTranslationsLoaded,
    translations,
    setTranslations
  }

  return <GrowlContext.Provider value={growlContext}>{children}</GrowlContext.Provider>
}

export const GrowlComponent = () => {
  const { growl, handleMessageRemove } = useContext(GrowlContext)

  return <Growl className="kemrisk-growl" ref={growl} onRemove={handleMessageRemove}/>
}
