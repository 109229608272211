import React from 'react'
import qs from 'qs'
import { injectIntl } from 'react-intl'

import { CenterInfoPanel } from 'Components/layout'
import { Panel } from 'Components/readonly'

const ErrorPage = ({ location }) => {
  const error = qs.parse(location.search, { ignoreQueryPrefix: true }).error

  return (
    <CenterInfoPanel>
      <Panel>
        <span className="pi pi-exclamation-triangle"></span> {error}
      </Panel>
    </CenterInfoPanel>
  )
}

export default injectIntl(ErrorPage)
