import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { LocationTree } from '../input'
import { Button, Divider } from '../readonly'
import { FormattedMessage, useIntl } from 'react-intl'
import Packages from 'Components/combined/packages'
import validateInventory from 'Utils/validateInventory'
import { FieldError, FlexFill, FlexRow, Section } from 'Components/layout'

export const emptyLocationRow = {
  location_id: null,
  inventory: [
    {
      is_bulk: false,
      unit_id: '',
      packaging_size: '',
      amount: '',
      amount_opened: '',
      new: true
    }
  ]
}

const ButtonContainer = styled.div`
  width: 50px;
  margin-left: 10px;

  ${Button}${Button}${Button} {
    margin: 0;
  }
`

export const validateLocationsWithPackages = (value) => {
  if (_.isEmpty(value)) {
    return true
  }

  const errors = []
  value.forEach((location, index) => {
    const error = {
      index: index
    }
    let hasErrors = false
    if (!location.location_id) {
      error.location_id = true
      hasErrors = true
    }
    const inventoryErrors = validateInventory(location.inventory)
    if (inventoryErrors === true || (inventoryErrors && inventoryErrors.length > 0)) {
      error.inventory = inventoryErrors
      hasErrors = true
    }
    if (hasErrors) {
      errors.push(error)
    }
  })

  if (errors.length > 0) {
    return errors
  }
}

const LocationsWithPackages = ({ id, value, onChange, validationError }) => {
  const intl = useIntl()

  const addLocation = () => {
    let v = [...value]
    v.push({ ...emptyLocationRow })
    onChange({ target: { value: v } })
  }

  const removeLocation = (index) => {
    let v = [...value]
    v.splice(index, 1)
    onChange({ target: { value: v } })
  }

  const updateLocation = (i, event) => {
    const newValue = [...value]
    newValue[i].location_id = event.target.value
    onChange({ target: { value: newValue } })
  }

  const updateInventory = (i, event) => {
    const newValue = [...value]
    newValue[i].inventory = event.target.value
    onChange({ target: { value: newValue } })
  }

  const getValidationErrors = (i, field) => {
    if (_.isArray(validationError)) {
      const errors = _.find(validationError, { index: i })
      if (errors) {
        return errors[field]
      }
    }
  }

  return (
    <div id={id}>
      {value &&
        value.map((row, i) => (
          <React.Fragment key={i}>
            <Divider/>
            <Section>
              <label style={{ marginBottom: '2px', display: 'inline-block' }}>
                <FormattedMessage id="locations.choose_location"/>
              </label>
              <FlexRow>
                {getValidationErrors(i, 'location_id') && <FieldError/>}
                <FlexFill>
                  <LocationTree
                    id={id + '-' + i + '-location'}
                    value={row.location_id}
                    onChange={(e) => updateLocation(i, e)}
                    validationError={getValidationErrors(i, 'location_id')}
                  />
                </FlexFill>
                <ButtonContainer>
                  <Button primary onClick={() => removeLocation(i)} icon="pi pi-trash" disabled={value.length === 1}/>
                </ButtonContainer>
              </FlexRow>
            </Section>
            <label style={{ display: 'block', marginTop: '6px' }}>
              <FormattedMessage id="chemicals.packages"/>
            </label>
            <Packages
              id={id + '-' + i + '-inventory'}
              value={row.inventory}
              onChange={(e) => updateInventory(i, e)}
              validationError={getValidationErrors(i, 'inventory')}
            />
          </React.Fragment>
        ))}
      <Divider/>
      <div>
        <Button link primary label={intl.formatMessage({ id: 'chemicals.add_location' })} onClick={addLocation}
                icon="pi pi-plus-circle"/>
      </div>
    </div>
  )
}

export default LocationsWithPackages
