import { Button, checkCircleBody, Column, DataTableWithSearch, dateBody, LoadingSpinner, Modal } from 'Components/readonly'
import { AuthContext } from 'Context'
import useCachedGetData from 'Hooks/useCachedGetData'
import useModalController from 'Hooks/useModalController'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import AddChemicalSDSFilterDialog from './addChemicalSDSFilterDialog'

const url = '/rapi/admin/chemicalsdsfilter'

export default () => {
  const intl = useIntl()
  const { user } = useContext(AuthContext)
  const [addChemicalSDSFilterModalController] = useModalController({ title: intl.formatMessage({ id: 'common.add' }) })
  const [isLoadingFilters, chemicalSDSFilters, setChemicalSDSFilters] = useCachedGetData(url, [])
  const [, reasonNotTrustworthyPhrases] = useCachedGetData(
    '/rapi/admin/reasonnottrustworthyphrases/' + user.user.language,
    []
  )

  const addIngredientFilterId = () => {
    addChemicalSDSFilterModalController.open()
  }

  const addSuccessHandler = (data) => {
    const updatedFilters = [...chemicalSDSFilters]
    const updateFilterIndex = updatedFilters.findIndex((filter) => filter.id === data.id)
    if (updateFilterIndex === -1) {
      updatedFilters.push(data)
    } else {
      updatedFilters[updateFilterIndex] = data
    }
    setChemicalSDSFilters(updatedFilters)
  }

  const typeBody = (row) => {
    return intl.formatMessage({ id: 'chemicals.sds-filter-' + row.type })
  }

  const phraseBody = (row) => {
    if (reasonNotTrustworthyPhrases.length > 0) {
      console.debug(
        reasonNotTrustworthyPhrases,
        row,
        reasonNotTrustworthyPhrases[0].value,
        row.reason_not_trustworthy_phrase_id,
        reasonNotTrustworthyPhrases.find((phrase) => phrase.value === row.reason_not_trustworthy_phrase_id)
      )
    }
    return reasonNotTrustworthyPhrases.find((phrase) => phrase.value === parseInt(row.reason_not_trustworthy_phrase_id))?.label
  }

  const actionsBody = (row) => {
    return <Button primary icon="pi pi-pencil" onClick={() => addChemicalSDSFilterModalController.open({ data: row })}/>
  }

  return (
    <>
      <Modal
        modalController={addChemicalSDSFilterModalController}
        ContentComponent={AddChemicalSDSFilterDialog}
        onSuccess={addSuccessHandler}
      />
      <LoadingSpinner isLoading={isLoadingFilters}>
        <DataTableWithSearch
          value={chemicalSDSFilters}
          paginator={true}
          rows={15}
          tools={<Button primary icon="pi pi-plus" onClick={addIngredientFilterId}
                         label={intl.formatMessage({ id: 'common.add' })}/>}>
          <Column field="name" header={intl.formatMessage({ id: 'common.name' })} sortable={true}/>
          <Column field="type" header={intl.formatMessage({ id: 'common.type' })} sortable={true} body={typeBody} style={{ width: '80px' }}/>
          <Column
            field="binding_from"
            header={intl.formatMessage({ id: 'common.binding-from' })}
            sortable={true}
            body={dateBody}
            validation="date"
            style={{ width: '180px' }}
          />
          <Column
            field="enabled"
            header={intl.formatMessage({ id: 'common.enabled' })}
            sortable={true}
            body={checkCircleBody}
            validation="date"
            style={{ width: '80px' }}
          />
          <Column
            field="reason_not_trustworthy_phrase_id"
            header={intl.formatMessage({ id: 'chemicals.form.reason_not_trustworthy' })}
            sortable={true}
            body={phraseBody}
          />
          <Column style={{ width: '100px' }} body={actionsBody} header={intl.formatMessage({ id: 'common.edit' })}/>
        </DataTableWithSearch>
      </LoadingSpinner>
    </>
  )
}
