import React from 'react'

export const preserveNewlinesColumnBody = (row, column) => <PreserveNewlines text={row[column.field]}/>

export const PreserveNewlines = ({ text }) =>
  text
    .replace(/<br(\s*)?\/?>/gi, '\\n')
    .split('\\n')
    .map((t, i) => (
      <React.Fragment key={i}>
        {t}
        <br/>
      </React.Fragment>
    ))
