import React, { useContext, useState } from 'react'
import { TabPanel } from 'primereact/tabview'

import UploadExternalSDSDialog from './components/uploadExternalSdsDialog'
import SdsStatsChart from './components/sdsStatsChart'
import SdsTable, { SDS_COLUMNS, SDS_TABLE_TYPE } from './sdsTable'
import { AuthContext } from 'Context'
import { Button } from 'Components/input'
import { TabView } from 'Components/readonly'
import useGetData from 'Hooks/useGetData'
import { ChartPageContainer, PageConstraint, PageHeader } from 'Components/layout'
import { FormattedMessage, injectIntl } from 'react-intl'

const SdsPage = injectIntl(({ intl }) => {
  const [update, forceUpdate] = useState()
  // Need to refetch and rerender when organisation changes
  const { organisation } = useContext(AuthContext)
  const [isLoadingStats, stats] = useGetData('/rapi/sds/stats', {}, [organisation, update])
  const [uploadExternalSdsDialogIsOpen, setUploadExternalSdsDialogIsOpen] = useState(false)

  const makeTitle = (title, count) => {
    return count === undefined ? title : title + ' (' + count + ')'
  }

  return (
    <PageConstraint>
      <PageHeader>
        <h1>
          <FormattedMessage id="sds.our-sds"/>
        </h1>
        <ChartPageContainer>
          <SdsStatsChart stats={stats} isLoading={isLoadingStats}/>
        </ChartPageContainer>
      </PageHeader>
      <TabView>
        <TabPanel header={makeTitle(intl.formatMessage({ id: 'sds.ours' }), stats.activeCount)}>
          <p>
            <FormattedMessage id="sds.our_sds_text"/>
          </p>
          <SdsTable type={SDS_TABLE_TYPE.INTERNAL} forceUpdate={forceUpdate}/>
        </TabPanel>
        <TabPanel header={makeTitle(intl.formatMessage({ id: 'sds.external' }), stats.externalCount)}>
          <p>
            <FormattedMessage id="sds.external_sds_text"/>
          </p>
          <SdsTable
            type={SDS_TABLE_TYPE.EXTERNAL}
            hideColumns={[SDS_COLUMNS.articleNumber, SDS_COLUMNS.status, SDS_COLUMNS.id, SDS_COLUMNS.labeling]}
            forceUpdate={forceUpdate}>
            {({ createSds }) => (
              <>
                <UploadExternalSDSDialog
                  isOpen={uploadExternalSdsDialogIsOpen}
                  setIsOpen={setUploadExternalSdsDialogIsOpen}
                  createSds={createSds}
                />
                <Button
                  icon="pi pi-upload"
                  label={intl.formatMessage({ id: 'sds.upload_sdb' })}
                  onClick={() => setUploadExternalSdsDialogIsOpen(true)}
                />
              </>
            )}
          </SdsTable>
        </TabPanel>
        <TabPanel header={makeTitle(intl.formatMessage({ id: 'sds.archived' }), stats.inactiveCount)}>
          <p>
            <FormattedMessage id="sds.archived-sds-text"/>
          </p>
          <SdsTable type={SDS_TABLE_TYPE.INACTIVE} forceUpdate={forceUpdate}/>
        </TabPanel>
      </TabView>
    </PageConstraint>
  )
})

export default SdsPage
