import React from 'react'

import { Button } from '../readonly'
import styled from 'styled-components'

const Add = styled.div`
  display: flex;
  align-items: baseline;
  height: 30px;
  margin-top: 10px;
`

export const ChemicalPropList = ({ id, onChange, value, RowComponent, componentProp, lang }) => {

  const add = (componentIndex) => {
    const newRow = {}
    const newComponents = [...value]
    newComponents[componentIndex][componentProp].push(newRow)
    onChange({ target: { value: newComponents } })
  }

  const remove = (componentIndex, rowIndex) => {
    const newComponents = [...value]
    newComponents[componentIndex][componentProp].splice(rowIndex, 1)
    onChange({ target: { value: newComponents } })
  }

  const update = (componentIndex, rowIndex, prop, e) => {
    const newValue = e.target.value ? e.target.value : null;
    const newItem = { ...value[componentIndex][componentProp][rowIndex], [prop]: newValue }
    const newComponents = [...value]
    newComponents[componentIndex][componentProp].splice(rowIndex, 1, newItem)
    onChange({ target: { value: newComponents } })
  }

  return !value ? null :
    value.map((component, componentIndex) => {
      return <div key={componentIndex} id={id + '-' + componentIndex}>
        <Add>
          <span>{component.ingredient_variant.ingredient.names.filter(name => name.language === lang)[0].name}, {component.ingredient_variant.ingredient.cas_id}</span>
          <Button id={id + '-' + componentIndex + '-add'} icon="pi pi-plus-circle" onClick={() => add(componentIndex)}/>
        </Add>
        {component && component[componentProp] && component[componentProp].map((row, rowIndex) => {
          return <RowComponent
            id={id + '-' + componentIndex + '-' + rowIndex}
            key={rowIndex}
            value={row}
            update={(prop, e) => update(componentIndex, rowIndex, prop, e)}
            remove={() => remove(componentIndex, rowIndex)}
          />
        })}
      </div>
    })
}
