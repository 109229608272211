import React from 'react'
import axios from 'axios'
import styled from 'styled-components'
import qs from 'qs'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { format, parse } from 'date-fns'
import { Column } from 'primereact/column'
import colors from 'Components/colors.scss'
import { SearchInput, SelectButton } from 'Components/input'
import { Button, DataTable, Panel, ToolPanel } from 'Components/readonly'
import { preserveNewlinesColumnBody } from 'Utils/newLines'

const Wrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;

  @media screen and (max-width: 640px) {
    overflow-y: scroll;
  }

  ${ToolPanel} {
    padding: 20px 20px 10px 20px;
  }

  background-color: ${colors.darkerBackgroundColor};

  &.light {
    background-color: ${colors.white};
  }
`

const ErrorWrapper = styled.div`
  display: flex;
  height: calc(var(--vh, 1vh) * 100 - 40px);
  align-items: center;
  justify-content: center;

  ${Panel} {
    flex: 0 0 400px;
    height: 100px;
    padding: 20px;
  }
`

const ErrorDisplay = ({ error, children }) => {
  return error ? (
    <ErrorWrapper>
      <Panel>
        <span className="pi pi-exclamation-triangle"></span> {error}
      </Panel>
    </ErrorWrapper>
  ) : (
    children
  )
}

const PublicSdsListPage = ({ intl, match, location }) => {
  const token = match.params.token

  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [sdsList, setSdsList] = React.useState([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [rowCount, setRowCount] = React.useState(10)
  const [first, setFirst] = React.useState(0)

  const rowCountOptions = [10, 25, 50, 100].map((rowCount) => ({ label: rowCount + '', value: rowCount }))

  React.useEffect(() => {
    const language = qs.parse(location.search, { ignoreQueryPrefix: true }).language
    const sdslanguages = qs.parse(location.search, { ignoreQueryPrefix: true }).sdslanguages

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const result = await axios(
          '/rapi/wl/' + token + '/list' + qs.stringify({
            language: language,
            sdslanguages: sdslanguages
          }, { addQueryPrefix: true })
        )
        setError(null)
        setSdsList(result.data)
      } catch (error) {
        setError(error.response.data.message)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [token, location])

  const columns = React.useMemo(() => {
    const dateBody = (row, column) => row[column.field] && <span>{format(parse(row[column.field]), 'YYYY-MM-DD')}</span>

    const getSdsUrl = (row) => '/rapi/wl/' + token + '/iframe/sds/' + row.kind + (row.kind === 'internal' ? '/specific/' : '/') + row.id

    const downloadBody = (row) => <Button primary icon="pi pi-download" onClick={() => window.open(getSdsUrl(row))}/>

    const disabled = qs.parse(location.search, { ignoreQueryPrefix: true }).disable

    const columns = []
    columns.push(
      <Column
        key="productName"
        field="productName"
        header={intl.formatMessage({ id: 'sds.product-name' })}
        body={preserveNewlinesColumnBody}
        sortable={true}
        style={{ width: '25%' }}
      />
    )
    if (!_.includes(disabled, 'article-number'))
      columns.push(
        <Column
          key="articleNumber"
          field="articleNumber"
          header={intl.formatMessage({ id: 'sds.article-number' })}
          sortable={true}
          style={{ width: '15%' }}
        />
      )
    if (!_.includes(disabled, 'country'))
      columns.push(
        <Column
          key="countryName"
          field="countryName"
          header={intl.formatMessage({ id: 'sds.country' })}
          sortable={true}
          style={{ width: '15%' }}
        />
      )
    if (!_.includes(disabled, 'language'))
      columns.push(
        <Column
          key="langName"
          field="langName"
          header={intl.formatMessage({ id: 'sds.language' })}
          sortable={true}
          style={{ width: '15%' }}
        />
      )
    if (!_.includes(disabled, 'issue-date'))
      columns.push(
        <Column
          key="issueDate"
          field="issueDate"
          header={intl.formatMessage({ id: 'sds.issue-date' })}
          sortable={true}
          body={dateBody}
          style={{ width: '10%' }}
        />
      )
    if (!_.includes(disabled, 'change-date'))
      columns.push(
        <Column
          key="changeDate"
          field="changed"
          header={intl.formatMessage({ id: 'sds.change-date' })}
          sortable={true}
          body={dateBody}
          style={{ width: '10%' }}
        />
      )
    columns.push(<Column key="download" header={intl.formatMessage({ id: 'sds.download' })} body={downloadBody}
                         style={{ width: '10%' }}/>)
    return columns
  }, [token, location, intl])

  const theme = qs.parse(location.search, { ignoreQueryPrefix: true }).theme
  return (
    <Wrapper id="Wrapper" className={theme}>
      <ErrorDisplay error={error}>
        <ToolPanel id="ToolPanel">
          <SearchInput
            theme={theme}
            value={globalFilter}
            onChange={(event) => {
              setGlobalFilter(event.target.value)
            }}
          />
          <SelectButton
            theme={theme}
            options={rowCountOptions}
            value={rowCount}
            onChange={(e) => {
              setRowCount(e.value)
              setFirst(0)
            }}
          />
        </ToolPanel>

        <DataTable
          theme={theme}
          className="flextable"
          value={sdsList}
          first={first}
          paginator={true}
          rows={rowCount}
          loading={isLoading}
          globalFilter={globalFilter}
          onPage={(e) => setFirst(e.first)}>
          {columns}
        </DataTable>
      </ErrorDisplay>
    </Wrapper>
  )
}

export default injectIntl(PublicSdsListPage)
