import React, { useContext, useMemo } from 'react'
import { AuthContext } from 'Context'
import { transformLocations } from 'Utils/location'
import useGetData from 'Hooks/useGetData'
import { Tree } from 'Components/input'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { globalSearch, includes } from 'Utils/search'

const searchProducts = (item, search) => {
  return item.products.some((p) => includes(p, search))
}

const searchHygieneThreshold = (item, search) => {
  return item.hygiene_thresholds.some((h) => includes(h.exposure_path, search) || includes(h.type, search))
}

export const filterList = (list, search, filter) => {
  list = globalSearch([...list], search, ['name', 'cas_id'], [searchHygieneThreshold, searchProducts])
  if (filter && !_.isEmpty(filter.selectedKeys)) {
    list = list.filter(
      (p) => !_.isEmpty(filter.selectedKeys) && p.locations.some((l) => filter.selectedKeys.some((fl) => fl + '' === l.id + ''))
    )
  }

  return list
}

const HygieneThresholdsFilter = ({ filter, setFirst }) => {
  const intl = useIntl()
  const { organisation } = useContext(AuthContext)
  const [locationsLoading, locations] = useGetData('/rapi/locations', null, [organisation])

  const locationOptions = useMemo(() => !locationsLoading && filter.locations && transformLocations(locations), [
    locations,
    locationsLoading,
    filter.locations
  ])

  const handleLocationFilter = (value) => {
    let locationNames = []
    const getLocationName = (o, k) => {
      o.forEach((l) => {
        if (k + '' === l.key + '') {
          locationNames.push(l.label)
        }
        if (l.children && l.children.length > 0) {
          return getLocationName(l.children, k)
        }
      })
    }

    value.forEach((k) => {
      getLocationName(locationOptions, k)
    })
    filter.setLocations({ ...filter.locations, selectedKeys: [...value], locationNames: locationNames })
  }
  return (
    <div className="filter">
      {filter.locations && (
        <>
          <h2 style={{ paddingTop: 0 }}>
            <i className="pi pi-filter" style={{ verticalAlign: 'bottom' }}/>
            {intl.formatMessage({ id: 'common.locations' })}
          </h2>

          {locationOptions && (
            <Tree
              options={locationOptions}
              onChange={(e) => {
                handleLocationFilter(e.target.value)
                setFirst(0)
              }}
              value={filter.locations.selectedKeys}
              multiselect={true}
              expandedKeys={filter.locations.expandedKeys}
            />
          )}
        </>
      )}
    </div>
  )
}

export default HygieneThresholdsFilter
