import React, { useState } from 'react'
import { Column, DataTableWithSearch, Modal, TabView } from 'Components/readonly'
import { TabPanel } from 'primereact/tabview'
import { LongerSection, PageConstraint, PageHeader } from 'Components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, SelectButton } from 'Components/input'
import useModalController from 'Hooks/useModalController'
import EditFilterDialog from '../components/editFilterDialog'
import useGetData from 'Hooks/useGetData'
import _ from 'lodash'
import axios from 'axios'
import IngredientFilterAdmin from './ingredientFilterAdmin'
import ChemicalSDSFilterAdmin from './chemicalSDSFilterAdmin'
import CategoriesFilterAdmin from './categoriesFilterAdmin'

const emptyfilter = {
  value: [],
  label_sv: '',
  label_en: '',
  label_no: '',
  label_dk: '',
  listLabel: 'chemicals.form.hazard_statements',
  nameLabel: 'common.name'
}

export const FilterPage = () => {
  const intl = useIntl()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [filterModalController] = useModalController()
  const [currentModel, setCurrentModel] = useState()
  const [hazardStatementsLoading, hazardStatements] = useGetData('/rapi/chemicals/hazardstatements')
  const [filterLoading, filter, setFilter] = useGetData('/rapi/admin/filter')

  const removeRow = async (row, column) => {
    try {
      let result = await axios.delete('/rapi/admin/filter/remove/' + row.id)
      setFilter(result.data)
    } catch (e) {
      console.error(e.message)
    }
  }

  const editRow = (row, column) => {
    let model = {
      value: row.hazard_statements.map((hs) => hs.code),
      label_sv: row.label_sv,
      label_en: row.label_en,
      label_no: row.label_no,
      label_dk: row.label_dk,
      id: row.id,
      listLabel: 'chemicals.form.hazard_statements',
      nameLabel: 'common.name',
      filter_type: row.filter_type,
      list: hazardStatements,
      listLoading: hazardStatementsLoading
    }
    setCurrentModel(model)
    filterModalController.open({ title: intl.formatMessage({ id: 'common.edit' }) })
  }

  const filterDialogSuccessHandler = (data) => {
    setFilter(data)
  }

  const valueBody = (row) => {
    return row.hazard_statements.map((hs) => hs.code).join(', ')
  }

  const edit = (row, column) => {
    return <Button link primary icon="pi pi-pencil" onClick={() => editRow(row, column)}/>
  }

  const remove = (row, column) => {
    return <Button link primary icon="pi pi-trash" onClick={() => removeRow(row, column)}/>
  }

  const add = (type) => {
    let model = { ...emptyfilter }
    model.list = hazardStatements
    model.listLoading = hazardStatementsLoading
    model.filter_type = type
    setCurrentModel(model)
    filterModalController.open({ title: intl.formatMessage({ id: 'common.add' }) })
  }

  const buildTable = (type) => (
    <>
      <DataTableWithSearch
        value={_.filter(filter, ['filter_type', type])}
        isLoading={filterLoading}
        tools={<Button primary icon="pi pi-plus" onClick={() => add(type)}
                       label={intl.formatMessage({ id: 'common.add' })}/>}>
        <Column key="label_sv" field="label_sv" header={intl.formatMessage({ id: 'common.label_sv' })}/>
        <Column key="label_en" field="label_en" header={intl.formatMessage({ id: 'common.label_en' })}/>
        <Column key="label_no" field="label_no" header={intl.formatMessage({ id: 'common.label_no' })}/>
        <Column key="label_dk" field="label_dk" header={intl.formatMessage({ id: 'common.label_dk' })}/>
        <Column key="hazard_statements" field="hazard_statements" header={intl.formatMessage({ id: 'common.value' })}
                body={valueBody}/>
        <Column body={edit} style={{ width: '80px' }} header={intl.formatMessage({ id: 'common.edit' })}
                excludeGlobalFilter={true}/>
        <Column body={remove} style={{ width: '80px' }} header={intl.formatMessage({ id: 'common.delete' })}
                excludeGlobalFilter={true}/>
      </DataTableWithSearch>
    </>
  )

  const [hFilterState, setFilterState] = useState('health')
  const updateHFilter = (e) => {
    if (e.value) {
      setFilterState(e.value)
    }
  }
  const hFilterOptions = [
    { label: intl.formatMessage({ id: 'chemicals.clp_class.health' }), value: 'health' },
    { label: intl.formatMessage({ id: 'chemicals.clp_class.physical' }), value: 'physical' }
  ]

  return (
    <PageConstraint>
      <Modal
        modalController={filterModalController}
        ContentComponent={EditFilterDialog}
        onSuccess={filterDialogSuccessHandler}
        model={currentModel}
      />
      <PageHeader>
        <h1>
          <FormattedMessage id="common.filter"/>
        </h1>
      </PageHeader>
      <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
        <TabPanel header={intl.formatMessage({ id: 'chemicals.h-filter' })}>
          <LongerSection>
            <SelectButton options={hFilterOptions} value={hFilterState} onChange={updateHFilter}/>
          </LongerSection>
          {buildTable(hFilterState)}
        </TabPanel>
        <TabPanel header={intl.formatMessage({ id: 'chemicals.categories' })}>
          <CategoriesFilterAdmin/>
        </TabPanel>
        <TabPanel header={intl.formatMessage({ id: 'chemicals.listings' })}>
          <IngredientFilterAdmin/>
        </TabPanel>
        <TabPanel header={intl.formatMessage({ id: 'chemicals.sds-filter' })}>
          <ChemicalSDSFilterAdmin/>
        </TabPanel>
      </TabView>
    </PageConstraint>
  )
}
