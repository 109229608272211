import React, { useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import styled from 'styled-components'

// Default dialog actions footer
export const ModalActionsFooter = styled.div`
  &&& {
    display: flex;
    align-self: flex-end;
    margin: 0;

    > button {
      margin: 0;
      margin-left: 10px;
    }
  }
`

export const Modal = ({
                        className,
                        modalController,
                        ContentComponent,
                        FooterComponent,
                        showCloseIcon,
                        allowEscClose,
                        ...props
                      }) => {
  const ref = useRef()

  const closable = showCloseIcon === false ? false : true
  const closeOnEscape = allowEscClose === false ? false : true

  const onShow = () => {
    if (ref.current && ref.current.dialog) {
      const shouldFocus = ref.current.dialog.getElementsByClassName('modal-onshow-focus')
      if (shouldFocus && shouldFocus.length > 0) {
        let focusInput =
          shouldFocus[0].tagName === 'INPUT' || shouldFocus[0].tagName === 'TEXTAREA' || shouldFocus[0].contenteditable
            ? shouldFocus
            : shouldFocus[0].querySelectorAll('[contenteditable=true], INPUT, TEXTAREA')
        if (focusInput && focusInput.length > 0) {
          focusInput[0].focus()
        }
      }
    }
  }

  const renderFooter = () => {
    if (!FooterComponent) {
      return null
    }

    return <FooterComponent modalController={modalController} {...props} />
  }

  return (
    <>
      {modalController && (
        <Dialog
          focusOnShow={false}
          onShow={onShow}
          className={className}
          header={modalController.title}
          modal={true}
          visible={modalController.isOpen}
          onHide={modalController.close}
          appendTo={document.body}
          footer={renderFooter()}
          ref={ref}
          closable={closable}
          closeOnEscape={closeOnEscape}>
          {modalController.isOpen && <ContentComponent modalController={modalController} {...props} />}
        </Dialog>
      )}
    </>
  )
}
