import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import colors from '../colors.scss'

const StyledDiv = styled.div`
  background-color: ${colors.accentFaded};
  color: ${colors.validationFailedColor};
  padding: 10px;
  margin-top: 10px;
`

export const FieldValidationMessages = ({ messages }) => {
  const intl = useIntl()

  const display = (m) => {
    return _.isObject(m)
      ? intl.formatMessage({ id: m.id }, m.values ? _.mapValues(m.values, (value) => intl.formatMessage({ id: value })) : null)
      : m
  }

  if (_.isEmpty(messages)) {
    return null
  }

  if (!_.isArray(messages)) {
    return <StyledDiv>{display(messages)}</StyledDiv>
  }

  return (
    <StyledDiv>
      {messages.map((m, i) => (
        <div key={i}>{display(m)}</div>
      ))}
    </StyledDiv>
  )
}
