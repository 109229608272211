import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import colors from '../colors.scss'

const StyledDiv = styled.div`
  background-color: ${colors.accentFaded};
  color: ${colors.validationFailedColor};
  padding: 10px;
  text-align: center;
`

const ValidationFailedMessage = () => {
  return (
    <StyledDiv>
      <FormattedMessage id="common.formvalidation_failed"/>
    </StyledDiv>
  )
}

export default ValidationFailedMessage
