import React from 'react'

import useGetData from 'Hooks/useGetData.js'

import { InventoryList } from './inventoryList.js'

const DoneInventoryListTab = () => {
  const [doneInventoryListLoading, doneInventoryList] = useGetData('/rapi/inventorying?filter=DONE')

  return <InventoryList isLoading={doneInventoryListLoading} list={doneInventoryList} detailUrl="/inventory/done/"/>
}

export default DoneInventoryListTab
