import axios from 'axios'
import { HasCapability } from 'Components/capabilities'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import { Button } from 'Components/input'
import { ButtonBar } from 'Components/layout'
import { Modal } from 'Components/readonly'
import linkConfig from 'Config/app-external-links'
import { GrowlContext } from 'Context'
import * as React from 'react'
import { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import uploadLabelingDialog from './uploadLabelingDialog'

export default ({ sds, deleteLabelingModalController, uploadLabelingModalController, onSdsUpdated }) => {
  const intl = useIntl()
  const { displayError, displaySuccess } = useContext(GrowlContext)

  const updateLabeling = (labeling) => {
    onSdsUpdated({ ...sds, labeling: labeling })
  }

  const openUploadLabelingModal = (labeling) => {
    uploadLabelingModalController.open({
      data: {
        sdsId: sds.id,
        replaceLabeling: labeling,
        updateLabeling: updateLabeling
      }
    })
  }

  const openDeleteLabelingModal = (kind, id) => {
    deleteLabelingModalController.open({ data: { kind, id } })
  }

  const deleteLabeling = async ({ kind, id }) => {
    try {
      if (kind === 'internal') {
        // id is a sds id
        const response = await axios.delete('/rapi/labeling/deleteInternal/' + id)
        onSdsUpdated({ ...sds, labelActive: false })
        displaySuccess(response.data.message)
      } else if (kind === 'external') {
        // id is a labeling id
        const response = await axios.delete('/rapi/labeling/deleteExternal/' + id)
        onSdsUpdated({ ...sds, labeling: sds.labeling.filter((l) => l.id !== id) })
        displaySuccess(response.data.message)
      }
    } catch (error) {
      displayError(error)
    }
  }

  const renderLabeling = () => {
    // Prioritizes viewing external labeling before internal

    if (sds.labeling && sds.labeling.length > 0) {
      return sds.labeling.map((labeling, i) => (
        <ButtonBar key={i}>
          <Button
            primary
            label={intl.formatMessage({ id: 'labeling.download' })}
            icon="pi pi-download"
            onClick={(event) => {
              window.open('/rapi/labeling/downloadExternal/' + labeling.id)
            }}
          />
          <Button
            label={intl.formatMessage({ id: 'labeling.delete' })}
            icon="pi pi-trash"
            onClick={() => openDeleteLabelingModal('external', labeling.id)}
          />
          <Button
            label={intl.formatMessage({ id: 'labeling.upload-replace' })}
            icon="pi pi-plus"
            onClick={() => openUploadLabelingModal(labeling)}
          />
        </ButtonBar>
      ))
    }

    if (sds.labelActive) {
      return (
        <ButtonBar>
          <Button
            primary
            label={intl.formatMessage({ id: 'labeling.download' })}
            icon="pi pi-download"
            onClick={(event) => {
              window.open('/rapi/labeling/downloadInternal/' + sds.id)
            }}
          />
          <Button
            label={intl.formatMessage({ id: 'labeling.delete' })}
            icon="pi pi-trash"
            onClick={() => openDeleteLabelingModal('internal', sds.id)}
          />
        </ButtonBar>
      )
    }

    return (
      <>
        <p>
          <FormattedMessage id="labeling.no-labeling"/>
        </p>
        <ButtonBar>
          <HasCapability capability="PLACE_ORDER">
            <div className="marginBottom">
              <i className="pi pi-envelope" style={{ fontSize: '28px', verticalAlign: 'middle' }}></i>{' '}
              <a href={`mailto:${linkConfig.emailOrder}`} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="labeling.order-product-labeling"/>
              </a>
            </div>
          </HasCapability>
          <HasCapability capability="UPLOAD_DOCUMENT">
            <>
              <Button
                primary
                label={intl.formatMessage({
                  id: 'labeling.upload-new'
                })}
                icon="pi pi-plus"
                onClick={() => openUploadLabelingModal()}
              />
            </>
          </HasCapability>
        </ButtonBar>
      </>
    )
  }

  return (
    <>
      <Modal modalController={uploadLabelingModalController} ContentComponent={uploadLabelingDialog}/>
      <Modal
        modalController={deleteLabelingModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="labeling.confirm-delete"
        confirmTextId="common.delete"
        onConfirm={deleteLabeling}
      />
      {renderLabeling()}
    </>
  )
}
