import React, { useMemo } from 'react'
import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Field, FieldGroup, FieldToggler, Form } from 'Components/form'
import { Datepicker, Switch, SwitchGroup, Dropdown } from 'Components/input'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'
import { CategoryPickListHazard } from 'Components/combined/categoryPickListHazard'
import useCachedGetData from 'Hooks/useCachedGetData'
import { format, parse } from 'date-fns'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  #listed_prio {
    width: 254px;
  }
`

const Content = styled.div`
  display: flex;
  padding: 0 30px;

  > div {
    flex: 1 1 auto;
  }

  > div:not(:last-child) {
    margin: 0 10px 0 0;
  }
`

const DropDownGroup = styled.div`
  width: 230px;
  .p-dropdown-panel {
    right: 0;
  }
`

const EditIngredientVariantDialog = ({ modalController, onSuccess, activeMarket }) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = React.useState(false)
  const { displayError, displaySuccess } = React.useContext(GrowlContext)

  const [hazardCategoriesIsLoading, hazardCategoriesList] = useCachedGetData('/rapi/chemicals/hazardcategories')
  const [hazardStatementsIsLoading, hazardStatementsList] = useCachedGetData('/rapi/chemicals/hazardstatements')

  const ingredient = modalController.data.ingredient
  // Only allow edit for new variants
  // const mayEditClassification = !modalController.data.variant || modalController.data.variant.usage_count === 0


  // Allow editing for ALL variants - for now. They wanted me to keep the old code in case they want to revert it
  const mayEditClassification = true
  const variant = (modalController.data.variant && {
    ...modalController.data.variant,
    classification_incorrect_from_date:
      modalController.data.variant.classification_incorrect_from_date &&
      parse(modalController.data.variant.classification_incorrect_from_date, 'YYYY-MM-DD')
  }) || {
    hazard_categories: [],
    hazard_statements: [],
    hazard_category_filter: [],
    classification_incorrect: false,
    classification_incorrect_from_date: null,
    flagged_for_ingredient_filters: [],
    listed_prio: false
  }
  const filterTypes = modalController.data.filterTypes
  const isLoadingFilterTypes = modalController.data.isLoadingFilterTypes

  const ingredientFilterOptions = useMemo(() => {
    return Object.entries(filterTypes).map(([value, label]) => {
      return {
        value,
        label
      }
    })
  }, [filterTypes])

  const getPrioTypeValue = (key) => {
    if (key === "SV") key = "SE";
    let value = 0;
    if(variant.hazard_category_filter) {
      variant.hazard_category_filter.forEach(function (item) {
        if (item.country_id === key) {
          value = item.id;
        }
      });
    }
    // Will polyfill old functionality and make select option in dropdown
    if(!value && ((variant.listed_prio && key === "SE") ||
      (variant.listed_prio_no && key === "NO")) && modalController?.data?.filterPrioTypes?.[key]) {
      const getNewItem = [...modalController.data.filterPrioTypes[key]].shift();
      return getNewItem.id;
    }

    return value;
  }

  const prioDropdownOptions = (key) => {
    if (key === "SV") key = "SE";
    let items = [{
      label: (key === "SE" ? "NEJ" : "NO"),
      value: 0
    }];
    if (modalController?.data?.filterPrioTypes?.[key]) {
      modalController.data.filterPrioTypes[key].forEach(function (item) {
        items.push({
          label: ((key !== "SE") ? item.label_en : item.label_sv),
          value: item.id
        })
      })
    }
    return items;
  }

  const submit = async (formData) => {
    try {
      setIsLoading(true)

      // Clone before manipulation
      formData = { ...formData }

      if (formData.hazards) {
        delete formData.hazards.ingredient
      }

      formData.classification_incorrect_from_date =
        formData.classification_incorrect_from_date && format(formData.classification_incorrect_from_date, 'YYYY-MM-DD')

      if (!variant.id) {
        formData.ingredient_id = ingredient.id
        const result = await axios.post('/rapi/ingredients/variant', formData)
        displaySuccess(intl.formatMessage({ id: 'admin.ingredients.variant_created' }))
        onSuccess(ingredient, result.data)
      } else {
        const result = await axios.put('/rapi/ingredients/variant/' + variant.id, formData)
        displaySuccess(intl.formatMessage({ id: 'admin.ingredients.variant_updated' }))
        onSuccess(ingredient, result.data)
      }
      modalController.close()
    } catch (e) {
      console.error(e)
      displayError(e)
    }
    setIsLoading(false)
  }


  return (
    <Wrapper>
      <Form onSubmit={submit}>
        <Content>
          <div>
            <h3>
              <FormattedMessage id="chemicals.ingredient_single" />
            </h3>
            <div>ID: {variant.id}</div>
            <div>{ingredient.names_sv}</div>
            <div>{ingredient.names_en}</div>
            <div>{ingredient.names_sv}</div>
            <div>{ingredient.names_dk}</div>
            <div>
              <FormattedMessage id="chemicals.form.ingredient.cas_id" />: {ingredient.cas_id}
            </div>
            <div>
              <FormattedMessage id="chemicals.form.ingredient.eg_id" />: {ingredient.eg_id}
            </div>
            <div>
              <FormattedMessage id="chemicals.form.ingredient.index_id" />: {ingredient.index_id}
            </div>
            <div>
              <FormattedMessage id="chemicals.form.ingredient.reach_id" />: {ingredient.reach_id}
            </div>
          </div>
          <div>
            <h3>
              <FormattedMessage id="chemicals.form.ingredient.classification" />
            </h3>
            {mayEditClassification ? (
              <>
                <Field
                  id="hazards"
                  Component={CategoryPickListHazard}
                  initialValue={{
                    hazard_categories: variant.hazard_categories,
                    hazard_statements: variant.hazard_statements,
                    ingredient: ingredient
                  }}
                  categoryList={hazardCategoriesList}
                  isLoading={hazardCategoriesIsLoading || hazardStatementsIsLoading}
                  codeList={hazardStatementsList}
                />
              </>
            ) : (
              <div>
                <div>{variant.hazard_categories.map((c) => c.abbreviation).join(', ')}</div>
                <div>{variant.hazard_statements.map((s) => s.code).join(', ')}</div>
              </div>
            )}
          </div>
        </Content>

        <Content>
          <div style={{ width: '195px' }}>
            <FieldToggler hide="classification_incorrect_from_date">
              <Field
                id="classification_incorrect"
                Component={Switch}
                onLabel={intl.formatMessage({ id: 'common.yes' })}
                offLabel={intl.formatMessage({ id: 'common.no' })}
                label={intl.formatMessage({ id: 'chemicals.form.ingredient.classification_incorrect' })}
                initialValue={variant.classification_incorrect}
              />
            </FieldToggler>
          </div>
          <div>
            <Field
              id="classification_incorrect_from_date"
              Component={Datepicker}
              label={intl.formatMessage({ id: 'chemicals.form.ingredient.classification_incorrect_from_date' })}
              initialValue={variant.classification_incorrect_from_date}
            />
          </div>
        </Content>

        <FieldGroup className="padded-fields">
          <h3>
            <FormattedMessage id="admin.ingredients.manually_flagged_for" />
          </h3>
          {isLoadingFilterTypes && <i className="pi pi-spinner pi-spin" />}
          <Field
            id="flagged_for_ingredient_filters"
            Component={SwitchGroup}
            onLabel={intl.formatMessage({ id: 'common.yes' })}
            offLabel={intl.formatMessage({ id: 'common.no' })}
            initialValue={variant.flagged_for_ingredient_filters}
            options={ingredientFilterOptions}
          />
          <DropDownGroup>
            {(activeMarket === 'SE' || activeMarket === 'SV') && (
              <Field
                id="listed_prio"
                Component={Dropdown}
                options={prioDropdownOptions(activeMarket)}
                label={intl.formatMessage({id: 'chemicals.form.ingredient.listed_prio'})}
                initialValue={getPrioTypeValue(activeMarket)}
              />
            )}
            {activeMarket === 'NO' && (
              <Field
                id="listed_prio_no"
                Component={Dropdown}
                options={prioDropdownOptions(activeMarket)}
                label={intl.formatMessage({id: 'chemicals.form.ingredient.listed_prio'})}
                initialValue={getPrioTypeValue(activeMarket)}
              />
            )}
          </DropDownGroup>
        </FieldGroup>
        <ModalActionsFooter>
          <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close} />
          <Button submit primary label={intl.formatMessage({ id: 'common.save' })} isLoading={isLoading} />
        </ModalActionsFooter>
      </Form>
    </Wrapper>
  )
}

export default EditIngredientVariantDialog
