import React from 'react'
import styled from 'styled-components'
import k from './k.png'
import ServicePanel from 'Components/readonly/servicePanel'
import { ContentContainer, MainContainer } from 'Components/layout'
import colors from 'Components/colors.scss'

const ColoredMainContainer = styled(MainContainer)`
  background-color: ${colors.darkerPanel};
`

const LoginContainer = styled.div`
  margin: auto;
`

const FormContainer = styled(ServicePanel)`
  margin: auto;
  max-width: 592px;
`

const LoginContentContainer = styled(ContentContainer)`
  @media screen and (max-width: 640px) {
    > * {
      padding: 25px 0 0;
    }
  }
`

export default ({ children }) => {
  return (
    <ColoredMainContainer>
      <LoginContentContainer>
        <LoginContainer>
          <FormContainer>
            <div className="panel-badge">
              <img src={k} alt="KemRisk"/>
            </div>
            {children}
          </FormContainer>
        </LoginContainer>
      </LoginContentContainer>
    </ColoredMainContainer>
  )
}
