import React, { useRef, useState } from 'react'
import { InputSwitch } from 'primereact/inputswitch'

import styled from 'styled-components'
import colors from '../colors.scss'

const StyledSwitch = styled(InputSwitch)`
  &&&& {
    width: 60px;
    height: 24px;
    cursor: pointer;
    font-size: 14px;
    vertical-align: middle;

    &.p-inputswitch-focus .p-inputswitch-slider {
      box-shadow: 0 0 0 0.2em ${colors.primary};
    }

    /* NEJ */

    &:not(.ui-inputswitch-checked) {
      .p-inputswitch-slider {
        background-color: ${(props) => (props.light ? colors.darkElement : colors.lightElement)};

        &:hover:not(.p-disabled) {
          background-color: ${(props) => {
  return props.light ? colors.darkElement : colors.lightElement
}};
        }

        /* Ball */

        &::before {
          background-color: ${colors.lightestElement};
          left: 0.3em;
          bottom: 0.25em;
          height: 1.15em;
          width: 1.15em;
        }
      }

      &::after {
        content: '${(props) => props.offLabel}';
        color: ${colors.lightestElement};
        position: absolute;
        left: 24px;
        top: 4px;
      }
    }

    /* JA */

    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background-color: ${colors.lighterElement};

        &:hover:not(.p-disabled) {
          background-color: ${colors.lighterElement};
          filter: brightness(110%);
        }

        /* Ball */

        &::before {
          background-color: ${colors.primaryLight};
          left: 1.5em;
          bottom: 0.25em;
        }
      }

      &::after {
        content: '${(props) => props.onLabel}';
        color: ${colors.textDark};
        position: absolute;
        left: 10px; /* leave some space for longer intl words */
      }
    }
  }`

export const Switch = ({ value, className, onChange, onLabel, offLabel, ...props }) => {
  const internalOnChange = (event) => {
    window.scrollTo(0, 0) // IOS Hack: When going from input element the window doesn't scroll back to top.
    onChange(event)
  }

  return (
    <StyledSwitch className={className} onLabel={onLabel} offLabel={offLabel} checked={!!value} {...props}
                  onChange={internalOnChange}/>
  )
}

export const SwitchGroup = ({ id, className, options, value, onChange, ...props }) => {
  const handleChange = (option, e) => {
    if (e.target.value !== value.includes(option.value)) {
      let newValue = [...value]
      if (e.target.value) {
        newValue.push(option.value)
      } else {
        newValue = newValue.filter((value) => value !== option.value)
      }
      onChange({ target: { value: newValue } })
    }
  }

  return (
    <div className={className}>
      {options.map((option) => (
        <div key={option.value}>
          <label>{option.label}</label>{' '}
          <Switch id={id + '-' + option.value} value={value.includes(option.value)}
                  onChange={(e) => handleChange(option, e)} {...props} />
        </div>
      ))}
    </div>
  )
}

export const UncontrolledSwitch = ({ register, ...props }) => {
  const el = useRef()
  const [checked, setChecked] = useState(props.defaultValue)

  React.useEffect(() => {
    if (register) {
      register(el.current.input)
    }
  }, [el, register])

  return <StyledSwitch ref={el} checked={checked} onChange={(e) => setChecked(e.target.value)} {...props} />
}
