import axios from 'axios'
import { Field, Form } from 'Components/form'
import { Datepicker, Dropdown } from 'Components/input'
import { Spacer } from 'Components/layout'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'
import { format, parse } from 'date-fns'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

const InputWrapper = styled.div`
  width: 223px;
`

const EditSdsDateModalContent = ({ modalController, updateSds }) => {
  const intl = useIntl()
  const sds = modalController.data
  const [isLoading, setIsLoading] = React.useState(false)
  const { displayError, displaySuccess } = React.useContext(GrowlContext)

  const sdsReasonOptions = [
    { value: 'ISSUED', label: intl.formatMessage({ id: 'chemicalsds.ISSUED' }) },
    { value: 'REVISED', label: intl.formatMessage({ id: 'chemicalsds.REVISED' }) },
    { value: 'CHANGED', label: intl.formatMessage({ id: 'chemicalsds.CHANGED' }) }
  ]

  const handleSubmit = async (data) => {
    setIsLoading(true)
    try {
      data.sds_date = format(data.sds_date, 'YYYY-MM-DD')
      const result = await axios.put('/rapi/chemicals/sds/' + sds.id, data)
      displaySuccess(result.data.message)
      updateSds(result.data.data)
      modalController.close()
    } catch (error) {
      displayError(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <Field
            id="reason"
            label={intl.formatMessage({ id: 'chemicalsds.reason' })}
            Component={Dropdown}
            options={sdsReasonOptions}
            initialValue={sds.reason}
          />
        </InputWrapper>
        <InputWrapper>
          <Field
            id="sds_date"
            label={intl.formatMessage({ id: 'common.date' })}
            Component={Datepicker}
            initialValue={sds.sds_date && parse(sds.sds_date, 'YYYY-MM-DD')}
            required={true}
          />
        </InputWrapper>
        <ModalActionsFooter>
          <Spacer/>
          <Button
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={() => {
              modalController.close()
            }}
          />
          <Button
            id="save-sds-date-button"
            submit
            primary
            icon="pi pi-save"
            label={intl.formatMessage({ id: 'common.save' })}
            onClick={() => {
            }}
            isLoading={isLoading}
          />
        </ModalActionsFooter>
      </Form>
    </>
  )
}

export default EditSdsDateModalContent
