import React from 'react'
import { useIntl } from 'react-intl'

import { Button, ModalActionsFooter } from 'Components/readonly'
import ChemicalPreviousSdsView from '../chemicalPreviousSdsView'
import { Section, Spacer } from 'Components/layout'

const SdsVersionListModalContent = ({ modalController, selectSds }) => {
  const intl = useIntl()
  const productId = modalController.data.productId

  return (
    <div>
      <Section>
        <ChemicalPreviousSdsView productId={productId} onSelect={selectSds} showDeleteButtons={true}/>
      </Section>
      <ModalActionsFooter>
        <Spacer/>
        <Button
          label={intl.formatMessage({ id: 'common.close' })}
          onClick={() => {
            modalController.close()
          }}
        />
      </ModalActionsFooter>
    </div>
  )
}

export default SdsVersionListModalContent
