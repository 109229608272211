import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ReactDOM from 'react-dom';
import QrCodeGenerator from './QrCodeGenerator';
import QrCodesPrintablePage from '../QrCodesPrintablePage';
import { Button, TextInput } from "Components/input";
import { Field, FieldGroup, Form } from 'Components/form'
import styled from 'styled-components';
import {InlineTextInput} from "../../settings/components/inlineTextInput";

const Aside = styled.aside`
  &&& {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    gap: 0 10px;
  }

  #button-print-cancel {
    margin-left: auto;
  }
`

const Container = styled.div`
  &&  {
    max-width: 400px;
    margin: 20px auto 40px;
    text-align: center;

    #qr-code {
      margin-bottom: 20px;
    }

    h1, h2 {
      font-size: 1.4rem;
      font-weight: bold;
      padding: 0;
      margin-bottom: 20px;
    }
    h1 span, h2 span {
      font-weight: normal;
    }
  }

  &&, #field-container  {
    align-items: center;
    gap: 0 20px;
  }

  #field-container {
    gap: 0 10px;
    display: flex;
    justify-content: center;

    label {
      margin-bottom: 10px;
    }
  }

  .qr-width {
    width: 80px;
  }
`

const ReadQrModal = ({modalController, modalData}) => {
  const intl = useIntl()
  const [inputWidthVal, setInputWidthVal] = useState(localStorage.getItem("qrCodeWidth") ?? "60");
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  const qrUrl = (publicUrl.origin ?? publicUrl.href) + '#/user-area/qr/' + modalData.id;

  /**
   * Store/update QR code from the active select choices
   * @returns {Promise<void>}
   */
  const handlePrint = () => {
    const title = (modalData.location ?? "");
    const newWindow = window.open('', '', 'width=800,height=600');
    newWindow.document.write('<div id="printable"></div>');
    newWindow.document.close();
    ReactDOM.render(<QrCodesPrintablePage
        title={title} url={qrUrl}
        window={newWindow}
        buttonTitle={intl.formatMessage({id: 'common.print'})} />,
      newWindow.document.getElementById('printable'));
  };

  /**
   * Change QR width
   * @param event
   */
  const onChange = (event) => {
    localStorage.setItem("qrCodeWidth", event.target.value);
    setInputWidthVal(event.target.value);
  }

  return (
    <>
      <Form>
        <Container>
          <h2>
            {modalData.location_id ? intl.formatMessage({id: 'common.location'}) + ": " : intl.formatMessage({id: 'chemicals.product'}) + ": "}
            <span>{modalData.location_id ? modalData.location_name : modalData.productSearch}</span>
          </h2>
          <QrCodeGenerator url={qrUrl}/>
          <div>
            <label>Storlek bredd och höjd:</label>
            <div id="field-container">
              <Field
                id="qr-width"
                type="number"
                className="qr-width"
                Component={InlineTextInput}
                maxLength="4"
                required={true}
                initialValue={inputWidthVal}
                onChange={onChange}
              />
              <span>mm</span>
            </div>
          </div>
        </Container>
        <Aside>
          <Button id="button-print-cancel" label={intl.formatMessage({id: 'common.cancel'})} onClick={() => modalController.close()}/>
          <Button id="button-print" primary label={intl.formatMessage({id: 'common.print'})} onClick={handlePrint}/>
        </Aside>
      </Form>
    </>
  );
};

export default ReadQrModal;
