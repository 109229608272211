import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, DropDownPanel } from '../readonly'
import styled from 'styled-components'
import { transformLocations } from 'Utils/location'
import { TextInput } from './textInput'
import _ from 'lodash'
import colors from '../colors.scss'
import useGetData from 'Hooks/useGetData'

const DisplayContainer = styled.div`
  white-space: nowrap;

  h3 {
    display: inline;
  }
`

const EditContainer = styled.div`
  display: flex;

  > div {
    flex: 1 0 auto;
  }

  > button {
    flex: 0 0 auto;
  }
`

const TreePanel = styled.div`
  padding: 8px;
  border-radius: 3px;
  border: 1px solid ${colors.invert};
  background-color: ${colors.darkPanel};
`

export const LocationTree = ({
                               id,
                               value,
                               onChange,
                               displayMode,
                               readOnly,
                               locationName,
                               appendToBody,
                               width,
                               validationError,
                               ...props
                             }) => {
  const [editMode, setEditMode] = useState(false)
  const dropdown = useRef()
  const [loadingLocations, locations] = useGetData('/rapi/locations', [])
  const transformedLocations = useMemo(() => locations && transformLocations(locations), [locations])
  const expandedKeys = useMemo(() => {
    const findLocation = (findLocationId, current) => {
      for (let i = 0; i < current.length; i++) {
        if (current[i].key === findLocationId) {
          return []
        } else if (current[i].children) {
          const result = findLocation(findLocationId, current[i].children)
          if (result) {
            result.push(current[i].key)
            return result
          }
        }
      }
      return null
    }

    const expandedKeys = []
    if (value && transformedLocations) {
      if (_.isArray(value)) {
        value.forEach((l) => {
          const result = findLocation(l, transformedLocations)
          if (result) {
            expandedKeys.push(...result)
          }
        })
      } else {
        const result = findLocation(value, transformedLocations)
        if (result) {
          expandedKeys.push(...result)
        }
      }
    }

    return expandedKeys
  }, [value, transformedLocations])

  const getSelectedLocationNames = () => {
    if (Array.isArray(value)) {
      return value.reduce((result, current) => {
        if (result !== '') {
          result += ', '
        }
        result += getLocationName(current, transformedLocations)
        return result
      }, '')
    } else {
      return getLocationName(value, transformedLocations)
    }
  }

  const getLocationName = (location, locations) => {
    if (location && loadingLocations) {
      return '...'
    }
    let name = ''
    if (location) {
      for (let i = 0; i < locations.length; i++) {
        if (locations[i].key === location) {
          return locations[i].label
        } else if (locations[i].children) {
          name = getLocationName(location, locations[i].children)
          if (name) {
            return name
          }
        }
      }
    }
    return name
  }

  const internalOnChange = (e) => {
    onChange({ target: { value: e.target.value } })
    if (!props.multiselect) {
      dropdown.current.hide()
      setEditMode(false)
    }
  }

  const edit = () => {
    setEditMode(true)
  }

  return displayMode && displayMode === 'view' && !editMode ? (
    <DisplayContainer>
      <h3 id={id}>{locationName}</h3>
      {!readOnly && <Button link primary icon="pi pi-pencil" onClick={(e) => edit()}/>}
    </DisplayContainer>
  ) : (
    <EditContainer id={id + '-dropdown'}>
      <DropDownPanel
        width={width}
        value={getSelectedLocationNames()}
        ref={dropdown}
        appendToBody={appendToBody}
        validationError={validationError}>
        <TreePanel>
          <Tree
            id={id}
            options={transformedLocations}
            onChange={(e) => internalOnChange(e)}
            value={value}
            expandedKeys={expandedKeys}
            isLoading={loadingLocations}
            {...props}
          />
        </TreePanel>
      </DropDownPanel>
    </EditContainer>
  )
}

const TreeContainer = styled.div`
  background-color: ${(props) => (props.theme === 'light' ? colors.white : colors.darkPanel)};
  color: ${(props) => (props.theme === 'light' ? colors.invert : '#dedede')};
  border-radius: 3px;
  padding: 8px 4px;
  font-size: 14px;
  position: relative;

  .tree-multiselect-all {
    display: flex;
    margin: 2px;

    > label {
      height: auto;
      flex: 1 0 1%;
      padding: 0px 8px;
    }
  }

  &.multi-select-all {
    .child {
      padding-left: 20px;
    }

    .has-children {
      .child {
        padding-left: 40px;
      }
    }
  }

  .has-children {
    .has-children {
      padding-left: 20px;
    }

    .child {
      padding-left: 20px;
    }
  }

  .leaf {
    margin: 2px 0 3px;
    display: flex;
    border: 2px solid transparent;

    width: 100%;
    align-items: center;
    cursor: pointer;

    > i {
      flex: 0 0 25px;
      height: auto;
      padding: 4px;
      border-radius: 3px;
      margin-right: 2px;

      &:hover {
        background-color: ${colors.disabledInteract};
      }
    }

    .select-label {
      display: flex;
    }
    .select-label::before {
      content: "\\e912";
      font-size: 19px;
      line-height: 0;
      margin: 0 3px 0 -4px;
      position: relative;
      top: 10px;
      display: block;
      font-family: 'primeicons';

    }

    &.selected .select-label::before {
      content: "\\e90a";
    }

    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop,
      > i {
        flex: 0 0 16px;
      }

      /* IE11 */
    }

    > label {
      cursor: pointer;
      height: auto;
      flex: 1 0 1%;
      padding: 4px 8px;
      border-radius: 3px;

      &:hover {
        background-color: ${colors.disabledInteract};
      }
    }

    &.selected {
      > label {
        background-color: ${colors.primary};
        color: ${colors.white};

        &:hover {
          background-color: ${colors.primaryFaded};
        }
      }
    }

    &:focus {
      border-color: ${colors.primary};
    }
  }

  .child .leaf {
    cursor: default;

    i {
      color: #777;
      text-align: center;
      font-size: 8px;
      padding-top: 8px;

      &:hover {
        color: #777;
        background-color: transparent;
        cursor: default;
      }
    }
  }

  ${TextInput}${TextInput}${TextInput} {
    border-color: ${(props) => (props.theme === 'light' ? colors.invert : colors.lightBackgroundColor)};
    background-color: ${colors.lighterBackgroundColor};
    color: ${colors.text};
    margin-bottom: 8px;
  }

  > i {
    position: absolute;
    right: 12px;
    top: 16px;
    font-size: 1.75em;
    color: ${colors.darkPanel};
  }
`

const Spinner = styled.div`
  text-align: center;
`

export const Tree = ({
                       id,
                       options,
                       isLoading,
                       value,
                       expandedKeys,
                       multiselect,
                       childrenincluded,
                       multiselectAll,
                       toggleItem,
                       addSelectIcon,
                       onChange
                     }) => {
  const [internalExpandedKeys, setInternalExpandedKeys] = useState(_.isArray(expandedKeys) ? expandedKeys : [])
  const [filterInput, setFilterInput] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)

  const isOpen = (key) => {
    return internalExpandedKeys.indexOf(key) > -1
  }

  const toggle = (key) => {
    let newKeys = [...internalExpandedKeys]
    if (internalExpandedKeys.indexOf(key) > -1) {
      newKeys.splice(internalExpandedKeys.indexOf(key), 1)
    } else {
      newKeys.push(key)
    }
    setInternalExpandedKeys(newKeys)
  }

  const collectChildIds = (option) => {
    if (option.children) {
      return option.children.reduce(
        (result, current) => {
          result.push(...collectChildIds(current))
          return result
        },
        [option.key]
      )
    }
    return [option.key]
  }

  const toggleSelectChildren = (n) => {
    let internalSelectedKeys = _.isArray(value) ? value : value ? [value] : []
    let childIds = n ? collectChildIds(n) : options.map(collectChildIds).flat()

    if ((n && isAllChildrenSelected(n)) || isAllSelected()) {
      // Deselect children
      let newKeys = internalSelectedKeys.filter((selectedKey) => !childIds.includes(selectedKey))
      onChange({ target: { value: newKeys } })
    } else {
      // Expand all children
      // newKeys = union of previously expanded and the target children
      let newExpandedKeys = [...new Set([...internalExpandedKeys, ...childIds])]
      setInternalExpandedKeys(newExpandedKeys)

      // Select all children
      // newKeys = union of previously selected and the target children
      let newKeys = [...new Set([...internalSelectedKeys, ...childIds])]
      onChange({ target: { value: newKeys } })
    }
  }

  const select = (key) => {
    let internalSelectedKeys = _.isArray(value) ? value : value ? [value] : []
    let newKeys = [...internalSelectedKeys]

    let findToggleIndex = newKeys.indexOf(key);
    if(toggleItem && findToggleIndex >= 0){
      newKeys.splice(findToggleIndex, 1);
      onChange({target: {value: multiselect ? newKeys : key}})
    }

    if (internalSelectedKeys.indexOf(key) === -1) {
      if (multiselect) {
        newKeys.push(key)
      } else {
        newKeys = [key]
      }
    } else if (multiselect) {
      newKeys.splice(internalSelectedKeys.indexOf(key), 1)
    } else {
      return
    }

    if (multiselect && childrenincluded) {
      // filter out selected children

      const findOption = (key, options) => {
        const option = options.find((option) => option.key === key)
        if (option) {
          return option
        }
        for (let i = 0; i < options.length; i++) {
          if (options[i].children) {
            const option = findOption(key, options[i].children)
            if (option) {
              return option
            }
          }
        }
        return null
      }

      const removeValues = []
      newKeys.forEach((key) => {
        const option = findOption(key, options)
        if (option) {
          const newRemoveValues = collectChildIds(option)
          newRemoveValues.shift()
          removeValues.push(...newRemoveValues)
        }
      })

      newKeys = newKeys.filter((key) => !removeValues.includes(key))
    }
    onChange({ target: { value: multiselect ? newKeys : key } })
  }

  const isSelected = (key) => {
    let internalSelectedKeys = _.isArray(value) ? value : value ? [value] : []
    return internalSelectedKeys.indexOf(key) > -1
  }

  useEffect(() => {
    if (filterInput) {
      let matchingKeys = []
      const filter = (o) => {
        let m = {}
        if (o.children) {
          m.children = o.children.map(filter).filter((b) => b !== undefined)
        }

        if (o.label.toLowerCase().indexOf(filterInput.toLowerCase()) > -1 || (m.children && m.children.length > 0)) {
          m.label = o.label
          m.key = o.key
          matchingKeys.push(o.key)
          return m
        }
        return undefined
      }
      let filtered = options.map(filter).filter((b) => b !== undefined)
      setFilteredOptions(filtered)
      setInternalExpandedKeys(matchingKeys)
    } else {
      setFilteredOptions(options)
    }
  }, [options, filterInput])

  const updateFilter = (e) => {
    if (e.target.value === '') {
      setInternalExpandedKeys([])
    }
    setFilterInput(e.target.value)
  }

  const isAllSelected = () => {
    return options.every(isAllChildrenSelected)
  }

  const isAllChildrenSelected = (n) => {
    const thisSelected = isSelected(n.key)
    if (!thisSelected) {
      return false
    }
    if (n.children) {
      return n.children.every(isAllChildrenSelected)
    }
    return thisSelected
  }

  const renderLeafs = (n, i, isParentSelected) => {
    const isShowSelected = isSelected(n.key) || (childrenincluded && isParentSelected)
    if (n.children && n.children.length > 0) {
      return (
        <div className={'has-children '} key={n.key}>
          <div className={'leaf ' + (isShowSelected ? 'selected' : '')}>
            {multiselectAll && (
              <MultiSelectButton
                id={'location-multiselect-button-' + n.key}
                value={isAllChildrenSelected(n)}
                onChange={() => toggleSelectChildren(n)}
              />
            )}
            <i
              id={'location-toggle-' + n.key}
              className={'pi' + (isOpen(n.key) ? ' pi-caret-down' : ' pi-caret-right')}
              onClick={() => toggle(n.key)}></i>
            <label id={id && id + '-' + n.key} className={addSelectIcon ? "select-label" : ""} onClick={() => select(n.key)}>
              {n.label}
            </label>
          </div>
          {isOpen(n.key) && n.children.map((n, i) => renderLeafs(n, i, isShowSelected))}
        </div>
      )
    }
    return (
      <div className={'child '} key={n.key}>
        <div className={'leaf ' + (isShowSelected ? 'selected' : '')}>
          <i className={'pi pi-circle-on'}></i>
          <label id={id && id + '-' + n.key} className={addSelectIcon ? "select-label" : ""} onClick={() => select(n.key)}>
            {n.label}
          </label>
        </div>
      </div>
    )
  }

  return (
    <TreeContainer className={multiselectAll ? 'multi-select-all' : ''}>
      <TextInput value={filterInput} onChange={(e) => updateFilter(e)}/>
      <i className="pi pi-search"/>
      {isLoading ? (
        <Spinner>
          <i className="pi pi-spinner pi-spin"/>
        </Spinner>
      ) : (
        <>
          {multiselectAll && (
            <div className="tree-multiselect-all">
              <MultiSelectButton value={isAllSelected()} onChange={() => toggleSelectChildren(null)}/>
              <label>
                <FormattedMessage id="common.select_all"/>
              </label>
            </div>
          )}
          {filteredOptions.map((n, i) => renderLeafs(n, i))}
        </>
      )}
    </TreeContainer>
  )
}

const MultiSelectButton = styled(({ value, onChange, ...props }) => {
  const icon = value ? 'pi-check' : ''
  return <Button {...props} icon={'pi ' + icon} onClick={onChange}/>
})`
  &&& {
    height: 17px;
    width: 17px;
    margin: 0;
    margin-right: 3px;
    background-color: ${colors.white};
    border: 1px solid ${colors.white};
    border-radius: 3px;

    .pi {
      color: ${colors.primary};
      font-size: 15px;
    }

    &:enabled:focus {
    }

    &:hover:not(.p-disabled) {
      background-color: ${colors.white};
      filter: none;
    }

    .p-button-text {
      display: none;
    }
  }
`
