import React, { useContext, useEffect, useState } from 'react'
import { Column } from 'primereact/column'
import { Button, SearchInput } from 'Components/input'
import { AuthContext } from 'Context'
import useCrudData from 'Hooks/useCrudData'

import { Avatar, DataTable, Modal, ToolPanel } from 'Components/readonly'
import { UserDialog } from './userDialog'
import DeleteUserDialog from './passwordDialog'
import useModalController from 'Hooks/useModalController'
import { FormattedHTMLMessage, useIntl } from 'react-intl'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import DeleteSuperUserDialog from './deleteSuperUserDialog'

import axios from 'axios'
import { GrowlContext } from 'Context/growlContext'
import { HasCapability, useCapability } from 'Components/capabilities'
import { globalSearch } from 'Utils/search'
import RoleHelpPanelShowAll from './roleHelpPanelShowAll'
import RoleHelpPanel from './roleHelpPanel'

export const SettingsUsersPanel = () => {
  const intl = useIntl()
  const { organisation, user } = useContext(AuthContext)
  const [globalFilter, setGlobalFilter] = useState('')
  const { displaySuccess } = useContext(GrowlContext)
  const hasAddUserCapability = useCapability('ADD_USER')
  const [filtered, setFiltered] = useState()
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(localStorage.hasOwnProperty('numUserRows') ? parseInt(localStorage.getItem('numUserRows')) : 10)

  // eslint-disable-next-line
  const [isLoading, orgUsers, createData, readData, updateDatas, deleteData] = useCrudData('/rapi/admin/users', [], [organisation])

  const [userModalController] = useModalController({ title: intl.formatMessage({ id: 'user.edit_user' }) })
  const [passwordModalController] = useModalController({ title: intl.formatMessage({ id: 'user.edit_password' }) })
  const [deleteUserModalController] = useModalController({ title: intl.formatMessage({ id: 'user.delete_user' }) })
  const [deleteSuperUserModalController] = useModalController({ title: intl.formatMessage({ id: 'user.delete_user' }) })

  useEffect(() => {
    setFiltered(
      globalSearch([...orgUsers], globalFilter, ['name', 'email', 'phone', 'roles.sds', 'roles.chemical_list', 'langName', 'userMarkets'])
    )
  }, [orgUsers, globalFilter])

  useEffect(() => {
    localStorage.setItem("numUserRows", rows);
  }, [rows]);

  const avatarBody = (row) => <Avatar size="sm" src={row.avatar} name={row.name}/>

  const personalBody = (row, column) => (
    <div style={{ display: 'inline-block' }}>
      {row.name}
      <br/>
      {row.email}
      <br/>
      {row.phone}
    </div>
  )
  const dateBody = (row, column) => <span>{row[column.field]}</span> // format(parse(row[column.field], 'YYYY-MM-DD'))
  const changeBody = (row) =>
    !row.is_root_user && (
      <Button
        primary
        icon="pi pi-pencil"
        onClick={(event) => {
          userModalController.open({ data: { user: row, organisation } })
        }}
      />
    )

  const deleteUser = async (user) => {
    let result = null
    result = await axios.delete('/rapi/user/' + user.id)
    displaySuccess(result.data.message)
    deleteData(result.data.user)
  }

  const deleteBody = (deleteUser) => {
    return (
      deleteUser &&
      deleteUser.id !== user.user.id && (
        <Button
          primary
          icon="pi pi-trash"
          onClick={() => {
            if (organisation.has_chemical_list && deleteUser.roleValues.chemical_list === 'admin') {
              deleteSuperUserModalController.open({ data: deleteUser })
            } else {
              deleteUserModalController.open({ data: deleteUser })
            }
          }}
        />
      )
    )
  }

  const marketBody = (row, column) => {
    let markets = []
    if (row.userMarkets) {
      for (let i = 0; i < row.userMarkets.length; i++) {
        markets.push(intl.formatMessage({ id: 'lang.' + row.userMarkets[i] }))
      }
      return <div>{markets.sort().reverse().join(', ')}</div>
    }
  }

  const userColumns = [
    <Column key="avatar" body={avatarBody} style={{ width: '1%' }}/>,
    <Column key="name" sortField="name" header={intl.formatMessage({ id: 'user.name' })} sortable={true}
            body={personalBody}/>
  ]
  if (organisation.has_sds) {
    userColumns.push(
      <Column key="roles.sds" field="roles.sds" header={intl.formatMessage({ id: 'sds.module' })} sortable={true}
              style={{ width: '1%' }}/>
    )
  }
  if (organisation.has_chemical_list) {
    userColumns.push(
      <Column
        key="roles.chemical_list"
        field="roles.chemical_list"
        header={intl.formatMessage({ id: 'chemicals.module' })}
        sortable={true}
        style={{ width: '1%' }}
      />
    )
  }
  userColumns.push(
    <Column
      key="starts_at"
      field="starts_at"
      header={intl.formatMessage({ id: 'common.start_date' })}
      body={dateBody}
      sortable={true}
      style={{ width: '1%' }}
    />
  )
  userColumns.push(
    <Column
      key="expires_at"
      field="expires_at"
      header={intl.formatMessage({ id: 'user.expire_date' })}
      body={dateBody}
      sortable={true}
      style={{ width: '1%' }}
    />
  )
  userColumns.push(
    <Column key="langName" field="langName" header={intl.formatMessage({ id: 'user.language' })} sortable={true}
            style={{ width: '1%' }}/>
  )
  userColumns.push(
    <Column
      field="userMarkets"
      body={marketBody}
      header={intl.formatMessage({ id: 'common.markets' })}
      sortable={true}
      style={{ width: '1%' }}
    />
  )
  if (hasAddUserCapability) {
    userColumns.push(
      <Column key="edit" header={intl.formatMessage({ id: 'common.edit' })} sortable={false} body={changeBody}
              style={{ width: '1%' }}/>
    )
    userColumns.push(<Column key="delete" header={intl.formatMessage({ id: 'common.delete' })} body={deleteBody}
                             style={{ width: '1%' }}/>)
  }

  return (
    <>
      <UserDialog modalController={userModalController} onUpdate={updateDatas} onCreate={createData}/>
      <Modal modalController={passwordModalController} ContentComponent={DeleteUserDialog}/>
      <Modal
        modalController={deleteUserModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="user.delete_user_text"
        confirmTextId="common.delete"
        onConfirm={deleteUser}
      />

      <Modal modalController={deleteSuperUserModalController} ContentComponent={DeleteSuperUserDialog}
             onDelete={deleteData}/>

      <ToolPanel>
        <span>
          <FormattedHTMLMessage id="user.has_number_of_users_in_org"
                                values={{ users: orgUsers.length, organisation: organisation.name }}/>
        </span>
        <SearchInput
          value={globalFilter}
          onChange={(event) => {
            setGlobalFilter(event.target.value)
          }}
        />
        <HasCapability capability="ADD_USER">
          <Button
            primary
            label={intl.formatMessage({ id: 'user.new_user' })}
            icon="pi pi-plus"
            onClick={() =>
              userModalController.open({
                title: intl.formatMessage({ id: 'user.new_user' }),
                data: { user: null, organisation }
              })
            }
          />
        </HasCapability>
      </ToolPanel>
      <div className="p-grid">
        <div className="p-col-12">
          <DataTable
            className="flextable"
            value={filtered}
            paginator={true}
            rows={rows}
            sortField="name"
            sortOrder={1}
            rowsPerPageOptions={[10, 20, 50, 100]}
            loading={isLoading}
            onRowClick={(event) => {
            }}
            first={first}
            onPage={(e) => {
              setFirst(e.first)
              setRows(e.rows)
            }}>
            {userColumns}
          </DataTable>
        </div>
        <div className="p-col-12">
          {organisation.has_chemical_list || organisation.has_sds ? <RoleHelpPanel/> : <RoleHelpPanelShowAll/>}
        </div>
      </div>
    </>
  )
}
