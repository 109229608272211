import * as React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'

import { Ansiktsmask, Handskar, Klader, Mask, Ogon, Skor } from '../assets'

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ItemWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  font-size: 14px;
  padding: 18px;

  img {
    width: 70px;
    height: 70px;
    margin-bottom: 8px;
  }
`

export const ProtectiveGearDisplay = injectIntl(({ intl, value }) => {
  const list = {
    eyes: { src: Ogon, label: intl.formatMessage({ id: 'riskassessment.protection_options.eyes' }) },
    mask: { src: Ansiktsmask, label: intl.formatMessage({ id: 'riskassessment.protection_options.mask' }) },
    gloves: { src: Handskar, label: intl.formatMessage({ id: 'riskassessment.protection_options.gloves' }) },
    shoes: { src: Skor, label: intl.formatMessage({ id: 'riskassessment.protection_options.shoes' }) },
    respiratory: { src: Mask, label: intl.formatMessage({ id: 'riskassessment.protection_options.respiratory' }) },
    clothes: { src: Klader, label: intl.formatMessage({ id: 'riskassessment.protection_options.clothes' }) }
  }

  return (
    <ListWrapper>
      {value &&
        value.map((item) => (
          <ItemWrapper key={item}>
            <img src={list[item].src} alt={item}/>
            <span>{list[item].label}</span>
          </ItemWrapper>
        ))}
    </ListWrapper>
  )

  //  return value ? value.map((picto, index) => <Picto picto={picto.code} key={index} />) : ''
})
